import React, { memo } from 'react';

import Sign from '../../assets/icons/Maintenance.svg';
import Logo from '../../assets/WAE_logo_Purple_60.svg';

const MaintenancePage = memo(() => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100vw',
      height: '100vh',
      backgroundColor: '#1a1a47',
    }}
  >
    <img src={Logo} alt="WAE" height={150} width={250} />
    <img src={Sign} alt="WAE" height={150} width={125} />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <h1 style={{ color: '#FFF', fontFamily: 'Barlow-800' }}>We&apos;re working on it.</h1>
      <p style={{ color: '#E6E6EB', margin: 0, padding: 0, fontFamily: 'Barlow-500' }}>
        The site is down for maintenance.
      </p>
      <p style={{ color: '#E6E6EB', margin: 0, padding: 0, fontFamily: 'Barlow-500' }}>
        Check back soon for updates!
      </p>
    </div>
  </div>
));

export default MaintenancePage;
