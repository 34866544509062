import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../theme';
import IconButton from '../IconButton';

const FormTypographyBox = ({ editable, input, mode, sx, value, xs }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  let textFieldBackground;
  let textFieldBackgroundHover;
  let textFieldFocusedBorder;
  let textFieldErrorBorder;
  let textFieldPrimaryLabel;
  const MODE_KEYS = {
    DARK: 'dark',
    EXTRADARK: 'extraDark',
    LIGHT: 'light', // Same as default
  };

  switch (mode) {
    case MODE_KEYS.DARK:
      textFieldBackground = theme.textField.darkBkColor;
      textFieldBackgroundHover = theme.palette.secondary.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.light;
      break;
    case MODE_KEYS.EXTRADARK:
      textFieldBackground = theme.textField.darkBkColor;
      textFieldBackgroundHover = theme.palette.secondary.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.light;
      break;
    default: // By default, use light
      textFieldBackground = theme.textField.inputLabel.primary.light;
      textFieldBackgroundHover = theme.textField.background.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.focused;
      break;
  }

  const styleForTextField = {
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiOutlinedInput-root': {
      backgroundColor: textFieldBackground,
      borderRadius: '40px',
      fontFamily: 'Barlow',
      '&:hover': {
        backgroundColor: textFieldBackgroundHover,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      outline: `1px solid ${textFieldFocusedBorder}`,
      backgroundColor: textFieldBackground,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      outline: `1px solid ${textFieldErrorBorder}`,
    },
    '& .MuiInputLabel-root': {
      top: '-8px',
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 7, 1, 2),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root.Mui-focused': {
      fontWeight: 500,
    },
    ...sx,
  };

  return (
    <Grid container item xs={xs}>
      {editable && isEditMode ? (
        <TextField sx={styleForTextField} value={input.value || ''} onChange={input.onChange} />
      ) : (
        <Box value={value}>
          <Typography sx={sx}>{get('value', input)}</Typography>
        </Box>
      )}
      {editable && (
        <Box sx={{ marginLeft: '10px' }}>
          <IconButton
            onClick={() => setIsEditMode(!isEditMode)}
            iconElement={<EditIcon sx={{ color: 'white' }} />}
          />
        </Box>
      )}
    </Grid>
  );
};
FormTypographyBox.propTypes = {
  editable: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  mode: PropTypes.string,
  sx: PropTypes.shape({}),
  value: PropTypes.string,
  xs: PropTypes.number,
};

export default FormTypographyBox;
