export const getTotalHours = (totalTimeInMs) =>
  typeof totalTimeInMs === 'number' ? (totalTimeInMs / 60 / 60 / 1000).toFixed(2) : '--';

export const getTotalHoursInNumberType = (totalTimeInMs) =>
  typeof totalTimeInMs === 'number' ? totalTimeInMs / 60 / 60 / 1000 : 0;

export const getHours = (startEpoch, endEpoch) => {
  if (!startEpoch || !endEpoch) return '--';

  const diff = endEpoch - startEpoch;
  return typeof diff === 'number' ? (diff / 60 / 60 / 1000).toFixed(2) : '--';
};

export const getTotalJobHours = (placements) =>
  placements?.reduce((acc, curr) => {
    const totalHours = getTotalHoursInNumberType(curr?.timecard?.current?.totalTime);
    return totalHours + acc;
  }, 0);

export const getNumWorkedShifts = (placements) =>
  placements?.reduce((acc, curr) => {
    const isWorkedShift =
      !!curr.calcAttendance.present.generatedBySystem ||
      !!curr.calcAttendance.late.generatedBySystem ||
      !!curr.calcAttendance.present.leftEarly;
    return isWorkedShift ? acc + 1 : acc;
  }, 0);

export const limitFillRatioTo100Percent = (fillRatio) => {
  if (typeof fillRatio === 'number') {
    return Math.round(Math.min(100, fillRatio));
  }
  return 0;
};

export const getFillSuccessRate = (numWorkedShifts, numOpenings) => {
  if (typeof numWorkedShifts === 'number' && typeof numOpenings === 'number') {
    return (numWorkedShifts / numOpenings) * 100;
  }
  return 0;
};
