import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const OrangeTrashIcon = () => (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7805 10.1008C14.3327 10.1008 14.7805 10.5485 14.7805 11.1008V16.5014C14.7805 17.0536 14.3327 17.5014 13.7805 17.5014C13.2282 17.5014 12.7805 17.0536 12.7805 16.5014V11.1008C12.7805 10.5485 13.2282 10.1008 13.7805 10.1008Z"
        fill="#F64E29"
      />
      <path
        d="M11.2239 11.1008C11.2239 10.5485 10.7762 10.1008 10.2239 10.1008C9.67165 10.1008 9.22394 10.5485 9.22394 11.1008V16.5014C9.22394 17.0536 9.67165 17.5014 10.2239 17.5014C10.7762 17.5014 11.2239 17.0536 11.2239 16.5014V11.1008Z"
        fill="#F64E29"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5587 4.80018V5.60036H18.2261L18.2287 5.60036H20.0044C20.5567 5.60036 21.0044 6.04808 21.0044 6.60036C21.0044 7.15265 20.5567 7.60036 20.0044 7.60036H19.2261V19.2016C19.2261 19.9403 18.9363 20.6513 18.4167 21.1773C17.8966 21.7038 17.1887 22.0018 16.4479 22.0018H7.55653C6.8157 22.0018 6.10773 21.7038 5.58768 21.1773C5.06803 20.6513 4.77826 19.9403 4.77826 19.2016V7.60036H4C3.44772 7.60036 3 7.15265 3 6.60036C3 6.04808 3.44772 5.60036 4 5.60036H5.77565L5.77826 5.60036H7.44566V4.80018C7.44566 4.06145 7.73543 3.35055 8.25508 2.8245C8.77513 2.29803 9.4831 2 10.2239 2H13.7805C14.5213 2 15.2292 2.29803 15.7493 2.8245C16.2689 3.35055 16.5587 4.06145 16.5587 4.80018ZM9.67792 4.23002C9.82485 4.08129 10.0215 4 10.2239 4H13.7805C13.9829 4 14.1795 4.08129 14.3265 4.23002C14.4738 4.37917 14.5587 4.58403 14.5587 4.80018V5.60036H9.44566V4.80018C9.44566 4.58403 9.53059 4.37917 9.67792 4.23002ZM6.77826 7.60036V19.2016C6.77826 19.4178 6.86319 19.6226 7.01052 19.7718C7.15745 19.9205 7.3541 20.0018 7.55653 20.0018H16.4479C16.6503 20.0018 16.8469 19.9205 16.9939 19.7718C17.1412 19.6226 17.2261 19.4178 17.2261 19.2016V7.60036L6.77826 7.60036Z"
        fill="#F64E29"
      />
    </svg>
  </SvgIcon>
);

export default OrangeTrashIcon;
