import React, { useEffect } from 'react';
import get from 'lodash/fp/get';
import { useDispatch, useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';

import selectUser from '../../../../store/selectors/appSelector';
import {
  selectMyTimeCard,
  selectTotalsData,
} from '../../../../store/selectors/Candidate/dashboardSelector';
import theme from '../../../../theme';
import { currWeekDayDate, weekDays } from '../../../../utils/timeConverter';
import filters from '../filters';
import { getMyTimeThisWeek } from '../reducer';

import DailyEarningsBox from './DailyEarningsBox';

const timeTheme = get(['candidate', 'components', 'dashboard', 'time'], theme);
const sxForTimeTotalBoxes = {
  backgroundColor: get(['totals', 'backgroundColor', 'default'], timeTheme),
  width: '49%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
};

const sxForHeader = {
  color: get(['totals', 'headerColor'], timeTheme),
  fontFamily: get(['totals', 'headerFont'], timeTheme),
  fontSize: '20px',
};

const sxForSubtext = {
  color: get(['totals', 'subtextColor'], timeTheme),
  fontFamily: get(['totals', 'subtextFont'], timeTheme),
  fontSize: '16px',
};

const sxForWeekWrapperDesktop = {
  display: 'flex',
  maxWidth: '100%',
  justifyContent: 'space-between',
  margin: theme.spacing(2, 0, 2, 0),
};

const sxForWeekWrapperMobile = {
  display: 'flex',
  overflowX: 'scroll',
  justifyContent: 'space-between',
  margin: theme.spacing(2, 0, 2, 0),
};

const MyTime = () => {
  const dispatch = useDispatch();
  const timeCardData = useSelector(selectMyTimeCard);
  const totalsData = useSelector(selectTotalsData);
  const user = useSelector(selectUser);
  const mediumScreen = useMediaQuery('(min-width:1375px)');

  const mergeSameDays = (timeCards) =>
    weekDays.map((day, index) => {
      const filteredTimeCard = timeCards.filter((i) => i.day === day);
      const sumSameDayTimeCardData = filteredTimeCard.reduce(
        (acc, currVal) => ({
          earnings: acc.earnings + currVal.earnings,
          hours: acc.hours + currVal.hours,
        }),
        { earnings: 0, hours: 0 }
      );

      return {
        date: currWeekDayDate(index),
        day,
        hours: sumSameDayTimeCardData.hours,
        earnings: sumSameDayTimeCardData.earnings,
      };
    });

  useEffect(() => {
    dispatch(
      getMyTimeThisWeek({
        filters: [
          { field: 'candidate', operation: 'equalsID', value: `${user.id}` },
          filters.MY_TIME_START,
          filters.MY_TIME_END,
        ],
      })
    );
  }, [dispatch, user.id]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={sxForTimeTotalBoxes}>
          <Typography sx={sxForHeader}>{`${get(['totalHours'], totalsData).toFixed(
            2
          )}`}</Typography>
          <Typography sx={sxForSubtext}>Total Hours</Typography>
        </Box>
        <Box sx={sxForTimeTotalBoxes}>
          <Typography sx={sxForHeader}>{`$${get(['totalEarnings'], totalsData).toFixed(
            2
          )}`}</Typography>
          <Typography sx={sxForSubtext}>Total Earnings</Typography>
        </Box>
      </Box>
      <Box sx={mediumScreen ? sxForWeekWrapperDesktop : sxForWeekWrapperMobile}>
        {timeCardData &&
          mergeSameDays(timeCardData).map((item) => (
            <DailyEarningsBox
              timeCardInfo={item}
              key={item.day}
              sx={
                mediumScreen
                  ? { maxWidth: '13%', marginRight: theme.spacing(1) }
                  : { minWidth: '130px', marginRight: theme.spacing(1) }
              }
            />
          ))}
      </Box>
    </Box>
  );
};

export default MyTime;
