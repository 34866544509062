export const getColors = ({ theme }) => ({
  selectedBackgroundColor: theme?.jobOrders?.createPage?.cadence?.selectedBackgroundColor,
  unSelectedBackgroundColor: theme?.jobOrders?.createPage?.cadence?.unSelectedBackgroundColor,
  typographyColor: theme?.jobOrders?.createPage?.cadence?.typography?.color,
  wrapperBackgroundColor: theme?.jobOrders?.createPage?.cadence?.wrapperBackgroundColor,
  errorColor: theme?.jobOrders?.createPage?.cadence?.errorColor,
});

export const getStyles = ({ theme, error }) => {
  const {
    selectedBackgroundColor,
    unSelectedBackgroundColor,
    typographyColor,
    wrapperBackgroundColor,
    errorColor,
  } = getColors({ theme });

  const wrapperSx = {
    backgroundColor: wrapperBackgroundColor,
    border: error && '1px solid red',
    justifyContent: 'space-around',
    borderRadius: theme.spacing(1),
    maxWidth: '480px',
  };

  const boxSx = {
    margin: theme.spacing(1.5, 0),
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
  };

  const selectedBoxSx = {
    ...boxSx,
    backgroundColor: selectedBackgroundColor,
  };

  const unSelectedBoxSx = {
    ...boxSx,
    backgroundColor: unSelectedBackgroundColor,
  };

  const labelSx = {
    color: typographyColor,
    font: theme?.jobOrders?.createPage?.cadence?.typography?.font,
    fontSize: theme?.jobOrders?.createPage?.cadence?.typography?.fontSize,
  };

  const helperTextSx = {
    color: errorColor,
    font: theme?.jobOrders?.createPage?.cadence?.typography?.font,
    fontSize: theme?.jobOrders?.createPage?.cadence?.typography?.fontSize,
  };
  const textWrapper = {
    justifyContent: 'center',
    alignItems: 'center',
    height: '35px',
  };

  return {
    wrapperSx,
    selectedBoxSx,
    unSelectedBoxSx,
    labelSx,
    helperTextSx,
    textWrapper,
  };
};
