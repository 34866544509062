import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import selectUser from '../../../../store/selectors/appSelector';
import theme from '../../../../theme';
import { epochToTimeInReadableFormat } from '../../../../utils';
import { STATUS_UPDATE_ACTION } from '../../../../views/jobOrders/jobOrderConstants';
import TimekeepingEditDrawer from '../../../TimeKeepingEditDrawer-deprecated/TimeKeepingEditDrawer';
import {
  showApproveBtn,
  showFinalizeBtn,
  showResolveBtn,
  showUnFinalizeBtn,
} from '../../ActiveRosterView/SubComponents/statusUpdateButtons';
import showTimecardEditButton from '../../ActiveRosterView/SubComponents/timecardEditButton';
import { getHours, getTotalHours } from '../../helperFunctions';

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    boxShadow: 'none',
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      padding: '0',
      justifyContent: 'space-between',
      '& .header': {
        display: 'flex',
        '& .avatar': {
          marginRight: '10px',
          width: '34px',
          height: '34px',
        },
        '& .employee': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& .name': {
            display: 'flex',
            fontSize: '16px',
            lineHeight: '16px',
            ...theme.jobOrders.detail.roster.employeeRosterCard.employeeName,
          },
          '& .checkInStatus': {
            display: 'flex',
            fontWeight: '300',
            fontSize: '14px',
            lineHeight: '16px',
            ...theme.jobOrders.detail.roster.employeeRosterCard.checkInStatus,
          },
        },
      },
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    paddingTop: '8px',
    '&:last-child': {
      paddingBottom: 0,
    },
    '& .header': {
      display: 'flex',
      justifyContent: 'space-between',
      '& .text': {
        flex: 1,
        textAlign: 'center',
        fontSize: '10px',
        lineHeight: '24px',
        textTransform: 'uppercase',
        ...theme.jobOrders.detail.roster.employeeRosterCard.headerText,
      },
    },
    '& .punches': {
      display: 'flex',
      '& .time': {
        flex: 1,
        textAlign: 'center',
        fontSize: '12px',
        lineHeight: '24px',
        ...theme.jobOrders.detail.roster.employeeRosterCard.punchTime,
      },
    },
    '& .total': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .text': {
        textAlign: 'center',
        fontFamily: 'Barlow',
        fontSize: '12px',
        fontWeight: '700',
        lineHeight: '24px',
      },
    },
    '& .actionButtons': {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '4px',
    },
  },
});

const EmployeeRosterCard = ({
  corporationName,
  placement,
  clearTimeCardError,
  handleTimecardEditSubmit,
  onStatusUpdate,
  timecardIsUpdating,
  timecardUpdateError,
  shiftName,
}) => {
  const classes = useStyles();
  const user = useSelector(selectUser);

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  const userRole = user?.role;

  const currentPunches = placement?.timecard?.current?.punches;

  const CheckInStatuses = {
    PRESENT: 'present',
    SENT_HOME: 'sent-home',
    NO_SHOW: 'no-show',
  };
  const checkInStatus = placement?.timecard?.checkIn?.status;
  const checkInStatusText =
    (checkInStatus === CheckInStatuses.NO_SHOW && LanguageConverter('jobOrder.markedAsNoShow')) ||
    (checkInStatus === CheckInStatuses.SENT_HOME && LanguageConverter('jobOrder.markedAsSentHome'));

  const handleOnEdit = () => {
    setIsEditDrawerOpen(true);
  };
  const editDrawerInitData = {
    corporation: corporationName,
    shiftName,
    start: placement?.start,
    end: placement?.end,
    punches: currentPunches || [],
  };

  const handleOnEditClose = useCallback(() => {
    clearTimeCardError();
    setIsEditDrawerOpen(false);
  }, [clearTimeCardError]);

  const handleOnEditSubmit = useCallback(
    ({ punches }) => {
      const data = {
        placement: placement?._id,
        punches: punches.map((punch) => ({
          in: punch?.in?.stamp,
          out: punch?.out?.stamp,
        })),
      };
      handleTimecardEditSubmit(data);
      return true;
    },
    [handleTimecardEditSubmit, placement?._id]
  );

  const handleOnStatusUpdate = (status) => () =>
    onStatusUpdate({ placementId: placement?._id, status });

  return (
    <Card className={classes.card}>
      <CardContent className="content">
        <div className="header">
          <Avatar src={placement?.profilePic} alt={placement?.candidateName} className="avatar" />
          <div className="employee">
            <Typography variant="h6" className="name">
              {placement?.candidateName}
            </Typography>
            <Typography variant="body2" className="checkInStatus">
              {checkInStatusText}
            </Typography>
          </div>
        </div>
      </CardContent>
      <CardContent className={classes.details}>
        <Box sx={{ border: 1, borderRadius: '5px' }}>
          <Box className="header" sx={{ borderBottom: 1 }}>
            <Typography className="text" sx={{ textAlign: 'center' }}>
              {LanguageConverter('buttonText.clockIn')}{' '}
            </Typography>
            <Typography className="text">{LanguageConverter('buttonText.clockOut')}</Typography>
            <Typography className="text"> {LanguageConverter('jobOrder.hours')} </Typography>
            <Typography className="text"> {LanguageConverter('jobOrder.totalHours')} </Typography>
          </Box>
          <Box sx={{ display: 'flex', direction: 'row' }}>
            <Box sx={{ width: '250px' }}>
              {currentPunches?.length > 0 ? (
                currentPunches?.map((punch) => (
                  <Box className="punches">
                    <Typography className="time">
                      {punch?.in?.stamp ? epochToTimeInReadableFormat(punch?.in?.stamp) : '--'}
                    </Typography>
                    <Typography className="time" sx={{ borderLeft: 1 }}>
                      {punch?.out?.stamp ? epochToTimeInReadableFormat(punch?.out?.stamp) : '--'}
                    </Typography>
                    <Typography className="time" sx={{ borderLeft: 1, borderRight: 1 }}>
                      {punch?.out?.stamp ? getHours(punch?.in?.stamp, punch?.out?.stamp) : '0.00'}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Box className="punches">
                  <Typography className="time">--</Typography>
                  <Typography className="time" sx={{ borderLeft: 1 }}>
                    --
                  </Typography>
                  <Typography className="time" sx={{ borderLeft: 1, borderRight: 1 }}>
                    --
                  </Typography>
                </Box>
              )}
            </Box>
            <Box className="total" sx={{ width: '85px' }}>
              <Typography className="text">
                {getTotalHours(placement?.timecard?.current?.totalTime)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="actionButtons">
          {showTimecardEditButton(
            handleOnEdit,
            placement?.start,
            placement?.timecard?.status,
            userRole
          )}
          {showApproveBtn(
            placement?.timecard?.status,
            placement?.end,
            userRole,
            handleOnStatusUpdate(STATUS_UPDATE_ACTION.APPROVE)
          )}
          {showResolveBtn(
            placement?.timecard?.status,
            placement?.end,
            userRole,
            handleOnStatusUpdate(STATUS_UPDATE_ACTION.RESOLVE)
          )}
          {showFinalizeBtn(
            placement?.timecard?.status,
            placement?.end,
            userRole,
            handleOnStatusUpdate(STATUS_UPDATE_ACTION.FINALIZE)
          )}
          {showUnFinalizeBtn(
            placement?.timecard?.status,
            placement?.end,
            userRole,
            handleOnStatusUpdate(STATUS_UPDATE_ACTION.UNFINALIZE)
          )}
        </Box>
        <TimekeepingEditDrawer
          isDrawerOpen={isEditDrawerOpen}
          initialData={editDrawerInitData}
          onSubmit={handleOnEditSubmit}
          onClose={handleOnEditClose}
          timecardIsUpdating={timecardIsUpdating}
          errorMessage={timecardUpdateError}
        />
      </CardContent>
    </Card>
  );
};

EmployeeRosterCard.propTypes = {
  placement: PropTypes.shape({
    _id: PropTypes.string,
    checkInStatus: PropTypes.string,
    candidateName: PropTypes.string,
    timecard: PropTypes.shape({
      current: PropTypes.shape({
        punches: PropTypes.arrayOf(PropTypes.shape({})),
        totalTime: PropTypes.number,
      }),
      checkIn: PropTypes.shape({ status: PropTypes.string }),
      status: PropTypes.string,
    }),
    start: PropTypes.number,
    end: PropTypes.number,
    profilePic: PropTypes.string,
    state: PropTypes.shape({ userName: PropTypes.string }),
  }),
  corporationName: PropTypes.string,
  clearTimeCardError: PropTypes.func,
  shiftName: PropTypes.string,
  handleTimecardEditSubmit: PropTypes.func,
  onStatusUpdate: PropTypes.func,
  timecardIsUpdating: PropTypes.bool,
  timecardUpdateError: PropTypes.string,
};

export default EmployeeRosterCard;
