import { getNextMonday, getSunday } from '../../../utils/timeConverter';
import { FOURTEEN_DAYS_IN_MS, ONE_SEC_IN_MS, SEVEN_DAYS_IN_MS } from '../constants';

const FILTERS = {
  THIS_WEEK_JOB: {
    end: getNextMonday(new Date()) * ONE_SEC_IN_MS,
  },
  FEATURE: [{ field: 'start', operation: 'before', value: +new Date() + FOURTEEN_DAYS_IN_MS }],
  MY_TIME_START: {
    operation: 'after',
    field: 'start',
    value: getSunday(new Date()),
  },
  MY_TIME_END: {
    operation: 'before',
    field: 'end',
    value: getSunday(new Date()) + SEVEN_DAYS_IN_MS,
  },
};
export default FILTERS;
