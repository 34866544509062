import React from 'react';
import PropTypes from 'prop-types';

import BackdropCircularProgress from '../../../../components/BackdropCircularProgress';
import Form from '../../../../components/Form';

import FormFieldDataOverview from './FormData/FormFieldDataOverview';

const Overview = ({ onUpdate, initialValues, container }) => {
  const formFieldData = FormFieldDataOverview();

  return (
    (
      <Form
        formFieldData={formFieldData}
        container={container}
        initialValues={{
          ...initialValues,
          otherLocationsHeader: 'Other Locations',
          additionalDetailsHeader: 'Additional Details',
          externalIdHeader: 'External ID',
        }}
        onSubmit={(values) => {
          onUpdate(values, initialValues);
        }}
        buttonData={[]}
      />
    ) || <BackdropCircularProgress />
  );
};

export default Overview;

Overview.propTypes = {
  onUpdate: PropTypes.func,
  initialValues: PropTypes.shape({}),
  container: PropTypes.shape({}),
};
