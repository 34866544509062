import React from 'react';
import { get, getOr } from 'lodash/fp';
import PropTypes from 'prop-types';

import DNRReasons from '@careerstart/wae-common/src/main/constants/DNR-reasons';
import { Accordion, AccordionActions, FormControlLabel, RadioGroup } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import OrangeTrashIcon from '../../../../../assets/icons/OrangeTrashIcon';
import { BLACK } from '../../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';
import theme from '../../../../../theme/theme';
import LinearExpandMore from '../../../../corporations/corporationList/detail/icons/LinearExpandMore';

import BlueBackgroundRadio from './BlueBackgroundRadio';

const DNRorDNAAccordion = ({ accordionInfo, handleDeleteDNAorDNR }) => {
  const dnrReasonOptions = [];
  Object.keys(DNRReasons).forEach((key) => {
    dnrReasonOptions.push({ id: key, label: DNRReasons[key] });
  });
  const corpID = get('corpId', accordionInfo);
  const [note, setNote] = React.useState(accordionInfo.note);
  const handleChange = (event) => {
    setNote(event.target.value);
  };

  const styleForAccordion = {
    backgroundColor: `${theme.palette.background.dark}`,
    borderRadius: '25px',
    padding: theme.spacing(1, 0, 0, 0),
    justifyContent: 'space-between',
    margin: '5px 0',
    boxShadow: 0,
    width: '100%',
  };
  const styleForIconButtons = {
    color: 'blue',
    fontSize: 30,
    cursor: 'pointer',
  };

  const detailViewTypographyCorpHeader = ['components', 'detailView', 'typography', 'corpHeader'];
  const detailViewTypographyReasonText = [
    'components',
    'detailView',
    'typography',
    'reasonTextWhite',
  ];

  return (
    <Accordion sx={styleForAccordion} square>
      <AccordionSummary
        expandIcon={<LinearExpandMore sx={styleForIconButtons} />}
        aria-controls="panel1a-content"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingRight: '5px',
          }}
        >
          <Box sx={{ color: 'white' }}>
            <Typography
              sx={{
                color: get([...detailViewTypographyCorpHeader, 'fontColor'], theme),
                fontSize: get([...detailViewTypographyCorpHeader, 'fontSize'], theme),
                fontFamily: get([...detailViewTypographyCorpHeader, 'fontFamily'], theme),
              }}
            >
              {' '}
              {accordionInfo.corpName}
            </Typography>

            <Typography sx={{ fontSize: '12px', fontFamily: 'Barlow-600' }}>
              {accordionInfo.reason}{' '}
            </Typography>
            <Typography sx={{ fontSize: '12px', fontFamily: PRIMARY_FONT[400] }}>
              {' '}
              {accordionInfo.note}
            </Typography>
            <Typography
              sx={{
                fontSize: '12px',
                fontFamily: PRIMARY_FONT[400],
                color: `${BLACK[50]}`,
              }}
            >
              Submitted by: {getOr('unknown', ['requester'], accordionInfo)}
            </Typography>
          </Box>
          <Button
            sx={{
              borderRadius: '50%',
              minWidth: '15px',
              height: '30px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteDNAorDNR(corpID);
            }}
          >
            <OrangeTrashIcon />
          </Button>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ margin: 0, padding: 0 }}>
        <Box sx={{ columnCount: 2, columnGap: '12px', padding: '0 16px' }}>
          <RadioGroup name="user-detail-radio-buttons-group">
            {dnrReasonOptions.map((reason) => (
              <FormControlLabel
                key={reason.id}
                value={reason.id}
                checked={reason.label === accordionInfo.reason}
                control={<BlueBackgroundRadio />}
                label={reason.label}
                sx={{
                  color: get([...detailViewTypographyReasonText, 'fontColor'], theme),
                  '& .MuiTypography-root': {
                    fontSize: get([...detailViewTypographyReasonText, 'fontSize'], theme),
                    fontFamily: get([...detailViewTypographyReasonText, 'fontFamily'], theme),
                  },
                }}
              />
            ))}
          </RadioGroup>
        </Box>
        <Box>
          <TextField
            value={note}
            onChange={handleChange}
            placeholder="Note"
            variant="outlined"
            inputProps={{ style: { color: 'white' }, readOnly: true }}
            size="small"
            sx={{
              width: '100%',
              height: 40,
              marginTop: '10px',
              color: 'white',
              backgroundColor: theme.textField.darkBkColor,
              borderRadius: '40px',
              fontFamily: 'Barlow-500',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: theme.palette.secondary.light,
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                borderRadius: '40px',
              },
            }}
          />
        </Box>
      </AccordionDetails>
      <AccordionActions />
    </Accordion>
  );
};

DNRorDNAAccordion.propTypes = {
  accordionInfo: PropTypes.shape({
    reason: PropTypes.string,
    note: PropTypes.string,
    corpName: PropTypes.string,
    requester: PropTypes.string,
    date: PropTypes.string,
  }),
  handleDeleteDNAorDNR: PropTypes.func,
};

export default DNRorDNAAccordion;
