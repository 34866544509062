import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import AndroidQR from '../../../assets/Android_Store_QR.svg';
import AppleQR from '../../../assets/Apple_Store_QR.svg';
import DownloadAppOnAppleStore from '../../../assets/AppleStore.svg';
import DownloadAppOnAndroidStore from '../../../assets/google-play-badge.svg';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import theme, { navigationBarHeight } from '../../../theme';
import { androidStoreLink, appleStoreLink } from '../../../utils';
import { navigator } from '../../../utils/navigator';

const BodyGrid = styled(Grid)(() => ({
  height: `calc(100vh - ${navigationBarHeight})`,
  position: 'relative',
  top: navigationBarHeight,
  padding: theme.spacing(2, 0),
}));

const mobileDirectTheme = theme?.views?.mobileDirect;

const MobileDirect = () => {
  const intl = useIntl();
  return (
    <Grid container>
      <NavigationBar />
      <BodyGrid container item flexDirection="column">
        <Typography sx={{ ...mobileDirectTheme?.typography?.mobileDirectSubTitle }}>
          {intl.formatMessage({ id: 'mobileDirect.title' })}
        </Typography>
        <Grid
          container
          item
          justifyContent="center"
          flexDirection="row"
          sx={{ padding: theme.spacing(5, 0, 3, 0) }}
        >
          <Box
            component="img"
            sx={{
              height: '105px',
              width: '105px',
              marginRight: '25px',
            }}
            alt="Android QR"
            src={AndroidQR}
          />

          <Box
            component="img"
            sx={{
              height: '105px',
              width: '105px',
              marginLeft: '25px',
            }}
            alt="Apple QR"
            src={AppleQR}
          />
        </Grid>
        <Grid container item justifyContent="center" flexDirection="row">
          <Box
            component="img"
            sx={{
              height: '48px',
              width: '167px',
              cursor: 'pointer',
            }}
            alt="Android"
            src={DownloadAppOnAndroidStore}
            onClick={() => navigator(androidStoreLink)}
          />
          <Box
            component="img"
            sx={{
              height: '48px',
              width: '167px',
              cursor: 'pointer',
            }}
            alt="Apple"
            src={DownloadAppOnAppleStore}
            onClick={() => navigator(appleStoreLink)}
          />
        </Grid>
      </BodyGrid>
    </Grid>
  );
};

export default MobileDirect;
