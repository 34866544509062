import React from 'react';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const Reporting = () => (
  <Box>
    <Typography>Reporting Page Placeholder</Typography>
  </Box>
);

export default Reporting;
