import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import WhenFieldChanges from '../../../../components/Form/WhenFieldChanges';
import theme from '../../../../theme';
import FormSectionDescription from '../CustomComponents/FormSectionDescription';
import FormSectionHeader from '../CustomComponents/FormSectionHeader';
import { overStaffOpeningsField } from '../FormFieldData/CreateJobFields';

const Overstaff = ({ wrapperSx }) => {
  const formState = useFormState();
  const currentFormValues = formState.values;
  const overstaffPercent = currentFormValues?.positionTemplate?.overstaffingPercent || 0;
  const openings = parseInt(currentFormValues?.numOpenings, 10);

  const defaultOverstaffOpenings = Math.floor(openings * overstaffPercent);
  return (
    <Box
      sx={{
        width: '100%',
        ...wrapperSx,
      }}
    >
      <WhenFieldChanges
        field="numOpenings"
        changeShouldOccur
        set="numOverstaff"
        to={defaultOverstaffOpenings}
      />

      <FormSectionHeader messageKey="job.create.overstaff.header" />
      <FormSectionDescription
        messageKey="job.create.overstaff.desc"
        messageValue={`${overstaffPercent * 100}%`}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box>
          {buildFormField(overStaffOpeningsField({ initialValue: defaultOverstaffOpenings }))}
        </Box>
        <Typography
          sx={{
            color: theme?.textField?.endLabel?.typography?.color,
            fontFamily: theme?.textField?.endLabel?.typography?.font,
            fontSize: theme?.textField?.endLabel?.typography?.fontSize,
          }}
        >
          {LanguageConverter('jobOrder.overStaff.openings')}
        </Typography>
      </Box>
    </Box>
  );
};

Overstaff.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    employer: PropTypes.shape({
      corporation: PropTypes.string,
    }),
  }),
  wrapperSx: PropTypes.shape({}),
};

export default Overstaff;
