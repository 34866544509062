import { apiPost } from '../../../../../datahub/axios';

const CHUNK_SIZE = 4;
let maxPages = CHUNK_SIZE;

export const getPageData = async ({ apiPath, filters, page, pageSize, token }) => {
  const rowData = [];
  await apiPost(
    apiPath,
    {
      page,
      limit: pageSize,
      filters,
    },
    token
  ).then((webResponse) => {
    maxPages = Math.ceil((webResponse?.data?.count || 0) / pageSize);
    const gridRows = webResponse?.data?.grid || [];
    rowData.push(...gridRows);
  });
  return rowData;
};

export const getSetData = async ({ apiPath, filters, pageSize, token, setProgress }) => {
  const returnArray = [];

  let currentChunk = 0;

  do {
    let currentMax = CHUNK_SIZE * currentChunk + CHUNK_SIZE;
    if (currentMax > maxPages) {
      currentMax = maxPages;
    }
    const promises = [];
    const rows = [];
    let currentPage = 0 + currentChunk * CHUNK_SIZE;
    do {
      const pageData = getPageData({
        page: currentPage,
        apiPath,
        filters,
        pageSize,
        token,
      });
      promises.push(pageData);
      currentPage += 1;
    } while (currentPage < currentMax);

    const response = Promise.all(promises).then((values) => {
      rows.push(...values);
    });

    // eslint-disable-next-line no-await-in-loop
    await response;
    returnArray.push(...rows);
    if (response) {
      currentChunk += 1;
      const progress = (currentChunk * CHUNK_SIZE) / maxPages;
      setProgress(progress);
    }
  } while (currentChunk <= maxPages / CHUNK_SIZE);
  return returnArray.flat(1);
};
