import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Cell, Pie, PieChart as Chart, ResponsiveContainer } from 'recharts';

import CircleIcon from '@mui/icons-material/Circle';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import theme from '../../theme/theme';

const pieChartTypographyPath = ['components', 'pieChart', 'typography'];

const Header = styled(Grid)(() => ({
  justifyContent: 'center',
  height: '24px',
  width: '100%',
  lineHeight: '24px',
  fontWeight: get([...pieChartTypographyPath, 'header', 'fontWeight'], theme),
  fontSize: get([...pieChartTypographyPath, 'header', 'fontSize'], theme),
  fontFamily: get([...pieChartTypographyPath, 'header', 'fontFamily'], theme),
  fontColor: get([...pieChartTypographyPath, 'header', 'fontColor'], theme),
}));

const PieChartDetailHeaderGrid = styled(Grid)(() => ({
  lineHeight: '34px',
  fontWeight: get([...pieChartTypographyPath, 'pieChartDetailHeader', 'fontWeight'], theme),
  fontSize: get([...pieChartTypographyPath, 'pieChartDetailHeader', 'fontSize'], theme),
  fontFamily: get([...pieChartTypographyPath, 'pieChartDetailHeader', 'fontFamily'], theme),
  fontColor: get([...pieChartTypographyPath, 'pieChartDetailHeader', 'fontColor'], theme),
}));

const PieChartDetailTextGrid = styled(Grid)(() => ({
  lineHeight: '28px',
  fontWeight: get([...pieChartTypographyPath, 'pieChartDetailText', 'fontWeight'], theme),
  fontSize: get([...pieChartTypographyPath, 'pieChartDetailText', 'fontSize'], theme),
  fontFamily: get([...pieChartTypographyPath, 'pieChartDetailText', 'fontFamily'], theme),
  fontColor: get([...pieChartTypographyPath, 'pieChartDetailText', 'fontColor'], theme),
}));

const LegendTextGrid = styled(Grid)(() => ({
  lineHeight: '24px',
  fontWeight: get([...pieChartTypographyPath, 'pieChartDetailText', 'fontWeight'], theme),
  fontSize: get([...pieChartTypographyPath, 'pieChartDetailText', 'fontSize'], theme),
  fontFamily: get([...pieChartTypographyPath, 'pieChartDetailText', 'fontFamily'], theme),
  fontColor: get([...pieChartTypographyPath, 'pieChartDetailText', 'fontColor'], theme),
}));

const DirectionRowPieChart = ({ data = [], titleText, totalValueText = '' }) => {
  const initVal = 0;
  const totalVal = data.reduce((acc, curr) => acc + curr.value, initVal);

  return (
    <Grid container height="321px">
      <Header container>{titleText}</Header>
      <Grid item xs={6}>
        <ResponsiveContainer height={150} width="100%">
          <Chart width={400} height={400}>
            <Pie data={data} labelLine={false} outerRadius={72} dataKey="value">
              {data.map((entry) => (
                <Cell key={entry.label} fill={entry.color} />
              ))}
            </Pie>
          </Chart>
        </ResponsiveContainer>
        <Grid
          container
          height="100px"
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <PieChartDetailHeaderGrid item>{totalVal}</PieChartDetailHeaderGrid>
          <PieChartDetailTextGrid item>{totalValueText}</PieChartDetailTextGrid>
        </Grid>
      </Grid>
      <Grid container item height="233px" justifyContent="center" alignItems="center" xs={6}>
        {data.map((item) => (
          <Grid key={`${item.label}${item.color}`} item container justifyContent="space-between">
            <LegendTextGrid item alignItems="center">
              <CircleIcon
                sx={{ height: '8px', width: '8px', marginRight: '10px', color: item.color }}
              />
              {item.label}
            </LegendTextGrid>
            <LegendTextGrid item>{item.value}</LegendTextGrid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

DirectionRowPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  titleText: PropTypes.string,
  totalValueText: PropTypes.string,
};

export default DirectionRowPieChart;
