import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import theme from '../../../theme';

const activeColor = `${theme?.components?.navigationBar?.palette?.activeColor}`;
const activeFont = `${theme?.components?.navigationBar?.typography?.active}`;

const RoleMenu = ({ anchorEl, open, handleProfileMenuClose, roleMenus }) => {
  const navigate = useNavigate();
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleProfileMenuClose}
      onClick={handleProfileMenuClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {roleMenus?.map((menu) => (
        <MenuItem
          key={menu?.id}
          sx={{
            ':hover': {
              color: activeColor,
              '& .MuiSvgIcon-root': {
                color: activeColor,
              },
              '& .MuiTypography-root': {
                color: activeColor,
                fontFamily: activeFont,
              },
            },
          }}
          onClick={() => {
            navigate(menu?.url);
          }}
        >
          <menu.Icon
            sx={{
              marginRight: 1,
            }}
          />
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontFamily: `${theme?.components?.roleMenu?.typography?.titleFont}`,
              color: `${theme?.components?.roleMenu?.palette?.text}`,
            }}
          >
            {menu.label}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

RoleMenu.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    id: PropTypes.string,
  }),

  anchorEl: PropTypes.bool,
  open: PropTypes.bool,
  handleProfileMenuClose: PropTypes.func,
  roleMenus: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RoleMenu;
