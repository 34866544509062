const TWENTY_HOURS_IN_MS = 72000000;
const TWENTYFOUR_HOURS_IN_MS = 86400000;
const shiftValidation = (shiftInputDataName, shiftInputDataStart, listData, shiftInputDataEnd) => {
  const isShiftNameEmpty = shiftInputDataName.length < 1;
  const isShiftNameDuplicated =
    listData.filter((row) => row.name.toLowerCase() === shiftInputDataName.toLowerCase()).length >
    0;
  const isStartDateFormatCorrect = Number.isNaN(Date.parse(shiftInputDataStart));
  const isEndDateFormatCorrect = Number.isNaN(Date.parse(shiftInputDataEnd));
  const calculatedEndTime =
    Date.parse(shiftInputDataEnd) - Date.parse(shiftInputDataStart) < 0
      ? Date.parse(shiftInputDataEnd) + TWENTYFOUR_HOURS_IN_MS // for overnight
      : Date.parse(shiftInputDataEnd); //
  const isMoreThan20Hours =
    calculatedEndTime - Date.parse(shiftInputDataStart) > TWENTY_HOURS_IN_MS;
  const isSameValue = Date.parse(shiftInputDataEnd) === Date.parse(shiftInputDataStart);
  const isNotValidHours = isMoreThan20Hours || isSameValue;

  if (isShiftNameEmpty) {
    return { name: 'Shift name must be at least 1 character long.' };
  }
  if (isShiftNameDuplicated) {
    return { name: 'Cannot add shift with a duplicate shift name.' };
  }
  if (isStartDateFormatCorrect) {
    return { start: 'Incorrect start date format' };
  }
  if (isEndDateFormatCorrect) {
    return { end: 'Incorrect end date format' };
  }
  if (isNotValidHours) {
    return { end: 'Shift length cannot be greater than 20 hours' };
  }

  return null;
};

export default shiftValidation;
