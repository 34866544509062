import { get } from 'lodash/fp';

import FormReadOnlyListField from '../../../../components/Form/FormReadOnlyListField';
import FormTypographyBox from '../../../../components/Form/FormTypographyBox';
import theme from '../../../../theme';

const componentWorkFlowFont = ['components', 'workflow', 'typography'];
const componentWorkFlowPaletteFontDefault = [
  'components',
  'workflow',
  'palette',
  'font',
  'default',
];
const workFlowHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  color: get([...componentWorkFlowPaletteFontDefault, 'transitionPageFontColor'], theme),
};
const workFlowSubHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'typography', 'defaultSubtitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'typography', 'defaultFont'], theme),
  color: get([...componentWorkFlowPaletteFontDefault, 'subHeaderFontColor'], theme),
  marginTop: '-20px',
  marginBottom: '20px',
};

export const formFieldData = (adjustDisplayValues, calculateTotalHours) => [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'batchApprovalHeader',
      name: 'batchApprovalHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'batchApprovalDescription',
      name: 'batchApprovalDescription',
      sx: workFlowSubHeaderStyling,
    },
    {
      ComponentType: FormReadOnlyListField,
      data: [{ name: 'Name', value: 'Hours' }],
      key: 'ColumnData',
      name: 'ColumnData',
      mode: 'dark',
    },
    {
      ComponentType: FormReadOnlyListField,
      mode: 'dark',
      key: 'NameAndHours',
      name: 'NameAndHours',
      data: adjustDisplayValues(),
    },
    {
      ComponentType: FormReadOnlyListField,
      mode: 'dark',
      key: 'TotalHours',
      name: 'TotalHours',
      data: calculateTotalHours(),
    },
  ],
];

export const readOnlyValues = {
  batchApprovalHeader: 'Batch Approval',
  batchApprovalDescription:
    'Verify the shifts below before approving. Batch approval will not include any time that has been marked with a conflict or a flag. ',
};
