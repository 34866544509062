import { get, map } from 'lodash/fp';

import FormMapDisplayWrapper from '../../../../components/Form/FormMapWrapper';
import FormSelectPillPicker from '../../../../components/Form/FormSelectPillPicker';
import FormShiftPicker from '../../../../components/Form/FormShiftPicker';
import FormSwitch from '../../../../components/Form/FormSwitch';
import FormTextField from '../../../../components/Form/FormTextField';
import FormTypographyBox from '../../../../components/Form/FormTypographyBox';
import FormTypographyDisplayList from '../../../../components/Form/FormTypographyDisplayList';
import { composeValidators, isPTShiftEmpty } from '../../../../components/Form/validations';
import SearchBox from '../../../../components/SearchBar';
import { SEARCHBAR_BACKGROUND, SearchBoxV2 } from '../../../../components/SearchBarV2/SearchBarV2';
import theme from '../../../../theme';
import { BLACK } from '../../../../theme/colorConstants';
import { minutesFromMidnightToTimeOnlyHrAndMins } from '../../../../utils';

const componentWorkFlowFont = ['components', 'workflow', 'typography'];
const workFlowHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  color: get([...componentWorkFlowFont, 'defaultLastPageFontColor', 'default'], theme),
};
const workFlowSubHeaderStyling = {
  fontSize: '16px',
  fontFamily: 'Barlow',
  color: '#333357',
  margin: theme.spacing(-1, 0, 0),
};

const FormHeaderStyle = {
  fontSize: '14px',
  fontFamily: 'Barlow-500',
  color: '#333357',
  margin: theme.spacing(-1, 0, 0),
};

const FormValueStyle = {
  fontSize: '16px',
  fontFamily: 'Barlow-500',
  color: '#000033',
  margin: theme.spacing(-1, 0, 0),
  wordBreak: 'break-all',
};

const HeaderStyling = {
  fontSize: '18px',
  fontFamily: 'Barlow-700',
  color: '#000033',
};

const predefinedCertsOptions = (certifications) =>
  certifications.map((cert) => ({
    name: cert.name,
    _id: cert.id,
  }));

const shiftPageHeader = {
  ComponentType: FormTypographyBox,
  key: 'shiftPageHeader',
  name: 'shiftPageHeader',
  sx: workFlowHeaderStyling,
};

const corporationChoice = {
  ComponentType: FormTypographyBox,
  key: 'corporationChoice',
  name: 'corporationChoice',
  sx: workFlowHeaderStyling,
};

const corporationHeader = {
  ComponentType: FormTypographyBox,
  key: 'corporationHeader',
  name: 'corporationHeader',
  sx: HeaderStyling,
};

const corporationField = {
  ComponentType: SearchBox,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  preDefinedOptions: [],
  data: null,
  key: 'id',
  name: 'corporation',
  multiSelect: false,
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Enter Corporation',
  fullWidth: true,
  onSubmitApiCallData: {
    httpMethod: 'POST',
    route: 'corporations/read',
  },
  optionDisplayField: 'name',
};

const industryChoice = {
  ComponentType: FormTypographyBox,
  key: 'industryChoice',
  name: 'industryChoice',
  sx: HeaderStyling,
};

const industry = {
  ComponentType: FormSelectPillPicker,
  name: 'industry',
  required: true,
  data: [
    {
      label: 'Healthcare',
      value: 'healthcare',
    },
    {
      label: 'Manufacturing',
      value: 'manufacturing',
    },
    {
      label: 'Hospitality',
      value: 'hospitality',
    },
  ],
  sx: { fontSize: '18px', fontFamily: 'Barlow-600', margin: theme.spacing(0, 0, 0, 1.5) },
};

const positionInfoHeader = {
  ComponentType: FormTypographyBox,
  key: 'positionInfoHeader',
  name: 'positionInfoHeader',
  sx: workFlowHeaderStyling,
};

const positionName = {
  ComponentType: FormTextField,
  label: 'Position Name',
  name: 'positionName',
  required: true,
  fullWidth: true,
  sx: {
    input: {
      color: 'black',
      fontFamily: 'Barlow-600',
    },
  },
};
const positionMeaning = {
  ComponentType: FormTypographyBox,
  key: 'positionMeaning',
  name: 'positionMeaning',
  sx: workFlowSubHeaderStyling,
};
const description = {
  ComponentType: FormTextField,
  label: 'Position Description',
  name: 'description',
  required: true,
  fullWidth: true,
  multiline: true,
  sx: {
    input: {
      color: 'black',
      fontFamily: 'Barlow-600',
    },
  },
};
const positionDescriptionMeaning = {
  ComponentType: FormTypographyBox,
  key: 'positionDescriptionMeaning',
  name: 'positionDescriptionMeaning',
  sx: workFlowSubHeaderStyling,
};
const allowEmployerApprovalHeader = {
  ComponentType: FormTypographyBox,
  key: 'allowEmployerApprovalHeader',
  name: 'allowEmployerApprovalHeader',
  sx: HeaderStyling,
  xs: 10,
};
const allowEmployerApproval = {
  ComponentType: FormSwitch,
  name: 'allowEmployerApproval',
  xs: 2,
};
const allowEmployerApprovalSubheader = {
  ComponentType: FormTypographyBox,
  key: 'allowEmployerApprovalSubheader',
  name: 'allowEmployerApprovalSubheader',
  sx: workFlowSubHeaderStyling,
  xs: 10,
};
const positionLocationHeader = {
  ComponentType: FormTypographyBox,
  key: 'positionLocationHeader',
  name: 'positionLocationHeader',
  sx: workFlowHeaderStyling,
};

const locationField = (corpID) => ({
  ComponentType: SearchBoxV2,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  data: { filters: [{ field: 'corporation._id', operation: 'equalsID', value: corpID }] },
  selectedSectionTitle: 'Selected Location(s)',
  onSubmitApiCallData: {
    httpMethod: 'POST',
    route: 'locations/read',
  },
  name: 'location',
  label: 'Location',
  multiSelect: true,
  description: 'Select an existing location for WAE or enter in a new location below and continue.',
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Search Location',
  fullWidth: true,
  optionDisplayField: 'description',
});

const departmentField = (corpID) => ({
  ComponentType: SearchBoxV2,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  data: {
    corporation: corpID,
  },
  selectedSectionTitle: 'Selected Department(s)',
  onSubmitApiCallData: {
    httpMethod: 'POST',
    route: 'corporations/departments/read',
  },
  autoCompleteTestId: 'departmentAutoComplete',
  name: 'department',
  label: 'Department',
  multiSelect: true,
  description:
    'Select an existing department for WAE or enter in a new department below and continue.',
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Search Department',
  fullWidth: true,
  optionDisplayField: 'name',
});

const gpsHeader = {
  ComponentType: FormTypographyBox,
  key: 'gpsHeader',
  name: 'gpsHeader',
  sx: workFlowHeaderStyling,
};

const gpsSubHeader = {
  ComponentType: FormTypographyBox,
  key: 'gpsSubHeader',
  name: 'gpsSubHeader',
  sx: workFlowSubHeaderStyling,
};

const gpsStrategyHeader = {
  ComponentType: FormTypographyBox,
  key: 'gpsStrategyHeader',
  name: 'gpsStrategyHeader',
  sx: HeaderStyling,
};

const gpsStrategy = {
  ComponentType: FormSelectPillPicker,
  name: 'gpsStrategy',
  required: true,
  data: [
    { id: 'enforced', label: 'Enforced', value: 'enforced' },
    { id: 'warning', label: 'Warning', value: 'warning' },
    { id: 'unenforced', label: 'Not Required', value: 'unenforced' },
  ],
  sx: { justifyContent: 'space-between' },
};

const gpsDescription = {
  ComponentType: FormTypographyBox,
  key: 'gpsDescription',
  name: 'gpsDescription',
  sx: workFlowSubHeaderStyling,
};

const geoHeader = {
  ComponentType: FormTypographyBox,
  key: 'geoHeader',
  name: 'geoHeader',
  sx: HeaderStyling,
};

const corporationLocationValue = {
  ComponentType: FormTypographyDisplayList,
  key: 'corporationLocationValue',
  name: 'corporationLocationValue',
  sx: workFlowSubHeaderStyling,
};

const geoLoc = {
  ComponentType: FormMapDisplayWrapper,
  key: 'companyMap',
  name: 'geoLoc',
  sx: {
    fontSize: '16px',
    fontColor: `${BLACK[0]}`,
    fontFamily: 'Barlow-600',
    width: '100%',
    borderRadius: '15px',
    height: '200px',
  },
};

const punchDistance = {
  ComponentType: FormTextField,
  label: 'Max Punch Distance(m)',
  name: 'punchDistance',
  fullWidth: true,
  required: true,
};

const distanceDescription = {
  ComponentType: FormTypographyBox,
  key: 'distanceDescription',
  name: 'distanceDescription',
  sx: workFlowSubHeaderStyling,
};

const certificationHeader = {
  ComponentType: FormTypographyBox,
  key: 'certificationHeader',
  name: 'certificationHeader',
  sx: workFlowHeaderStyling,
};

const certificationDescription = {
  ComponentType: FormTypographyBox,
  key: 'certificationDescription',
  name: 'certificationDescription',
  sx: workFlowSubHeaderStyling,
};

const selectedCertificationHeader = {
  ComponentType: FormTypographyBox,
  key: 'selectedCertificationHeader',
  name: 'selectedCertificationHeader',
  sx: HeaderStyling,
};

const certificationsField = (options) => ({
  ComponentType: SearchBox,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  preDefinedOptions: [],
  data: null,
  dropDownOptions: options,
  key: 'id',
  name: 'certifications',
  multiSelect: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Enter Certifications',
  fullWidth: true,
  onSubmitApiCallData: {
    httpMethod: 'GET',
    route: 'certifications',
  },
  optionDisplayField: 'name',
});

const skillsHeader = {
  ComponentType: FormTypographyBox,
  key: 'skillsHeader',
  name: 'skillsHeader',
  sx: workFlowHeaderStyling,
};

const skillDescription = {
  ComponentType: FormTypographyBox,
  key: 'skillDescription',
  name: 'skillDescription',
  sx: workFlowSubHeaderStyling,
};

const selectedSkillHeader = {
  ComponentType: FormTypographyBox,
  key: 'selectedSkillHeader',
  name: 'selectedSkillHeader',
  sx: HeaderStyling,
};

const skills = {
  ComponentType: SearchBox,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  preDefinedOptions: [],
  data: null,
  key: 'id',
  name: 'skills',
  multiSelect: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Search Skills',
  fullWidth: true,
  onSubmitApiCallData: {
    httpMethod: 'POST',
    route: 'skills/read',
  },
  optionDisplayField: 'name',
};

const shiftPageDescription = {
  ComponentType: FormTypographyBox,
  key: 'shiftPageDescription',
  name: 'shiftPageDescription',
  sx: workFlowSubHeaderStyling,
};

const shifts = (readOnlyFormat, validate) => ({
  ComponentType: FormShiftPicker,
  label: 'Shifts',
  name: 'shifts',
  shiftTitle: 'Assigned Shifts',
  readOnlyFormat,
  validate,
});

const confirmationPageHeader = {
  ComponentType: FormTypographyBox,
  key: 'confirmationPageHeader',
  name: 'confirmationPageHeader',
  sx: workFlowHeaderStyling,
};

const confirmationPageSubHeader = {
  ComponentType: FormTypographyBox,
  key: 'confirmationPageSubHeader',
  name: 'confirmationPageSubHeader',
  sx: FormHeaderStyle,
};

const positionNameTypography = {
  ComponentType: FormTypographyBox,
  key: 'positionName',
  name: 'positionName',
  sx: FormValueStyle,
};

const positionDescriptionTitle = {
  ComponentType: FormTypographyBox,
  key: 'positionDescriptionTitle',
  name: 'positionDescriptionTitle',
  sx: FormHeaderStyle,
};

const positionDescriptionValue = {
  ComponentType: FormTypographyBox,
  key: 'positionDescriptionValue',
  name: 'positionDescriptionValue',
  sx: FormValueStyle,
};

const corporationTitle = {
  ComponentType: FormTypographyBox,
  key: 'corporationTitle',
  name: 'corporationTitle',
  sx: FormHeaderStyle,
};

const corporationValue = {
  ComponentType: FormTypographyBox,
  key: 'corporationValue',
  name: 'corporationValue',
  sx: FormValueStyle,
};

const allowEmployerApprovalTitle = {
  ComponentType: FormTypographyBox,
  key: 'allowEmployerApprovalTitle',
  name: 'allowEmployerApprovalTitle',
  sx: FormHeaderStyle,
  xs: 10,
};

const allowEmployerApprovalValue = {
  ComponentType: FormTypographyBox,
  key: 'allowEmployerApprovalValue',
  name: 'allowEmployerApprovalValue',
  sx: FormValueStyle,
  xs: 10,
};

const corporationLocationTitle = {
  ComponentType: FormTypographyBox,
  key: 'corporationLocationTitle',
  name: 'corporationLocationTitle',
  sx: FormHeaderStyle,
};

const corporationDepartmentTitle = {
  ComponentType: FormTypographyBox,
  key: 'corporationDepartmentTitle',
  name: 'corporationDepartmentTitle',
  sx: FormHeaderStyle,
};

const corporationDepartmentValue = {
  ComponentType: FormTypographyDisplayList,
  key: 'corporationDepartmentValue',
  name: 'corporationDepartmentValue',
  sx: FormValueStyle,
};

const punchDistanceHeader = {
  ComponentType: FormTypographyBox,
  key: 'punchDistanceHeader',
  name: 'punchDistanceHeader',
  sx: FormHeaderStyle,
  xs: 6,
};

const gpsStrategyValue = {
  ComponentType: FormTypographyBox,
  key: 'gpsStrategyValue',
  name: 'gpsStrategyValue',
  sx: FormValueStyle,
  xs: 6,
};

const punchDistanceMeter = {
  ComponentType: FormTypographyBox,
  key: 'punchDistanceMeter',
  name: 'punchDistanceMeter',
  sx: FormValueStyle,
  xs: 6,
};

const certificationTitle = {
  ComponentType: FormTypographyBox,
  key: 'certificationTitle',
  name: 'certificationTitle',
  sx: FormHeaderStyle,
};

const certificationsValue = {
  ComponentType: FormTypographyDisplayList,
  key: 'certificationsValue',
  name: 'certificationsValue',
  sx: FormValueStyle,
};

const skillTitle = {
  ComponentType: FormTypographyBox,
  key: 'skillTitle',
  name: 'skillTitle',
  sx: FormHeaderStyle,
};

const skillsValue = {
  ComponentType: FormTypographyDisplayList,
  key: 'skillsValue',
  name: 'skillsValue',
  sx: FormValueStyle,
};

const shiftsHeader = {
  ComponentType: FormTypographyBox,
  key: 'shiftsHeader',
  name: 'shiftsHeader',
  sx: FormHeaderStyle,
};

const shiftsValue = {
  ComponentType: FormTypographyDisplayList,
  key: 'shiftsValue',
  name: 'shiftsValue',
  sx: FormValueStyle,
};

const employerCorporationNameField = {
  ComponentType: FormTypographyBox,
  key: 'employerCorporationName',
  name: 'employerCorporationName',
  sx: FormValueStyle,
};

const gpsStep = [
  gpsHeader,
  gpsSubHeader,
  gpsStrategyHeader,
  gpsStrategy,
  gpsDescription,
  geoHeader,
  corporationLocationValue,
  geoLoc,
  punchDistance,
  distanceDescription,
];

const approvalFields = [
  allowEmployerApprovalHeader,
  allowEmployerApproval,
  allowEmployerApprovalSubheader,
];

export const PositionTemplateFormFieldDataForAdminAndRecruiter = ({
  createPositionTemplateValues,
  certifications,
  gpsEnforcement,
  applications,
}) =>
  [
    [corporationChoice, corporationHeader, corporationField, industryChoice, industry],
    [
      positionInfoHeader,
      positionName,
      positionMeaning,
      description,
      positionDescriptionMeaning,
      ...(applications ? approvalFields : []),
    ].filter((field) => !!field),
    [
      positionLocationHeader,
      locationField(get(['corporation', '_id'], createPositionTemplateValues)),
      departmentField(get(['corporation', '_id'], createPositionTemplateValues)),
    ],
    gpsEnforcement ? gpsStep : false,
    [
      certificationHeader,
      certificationDescription,
      selectedCertificationHeader,
      certificationsField(predefinedCertsOptions(certifications)),
    ],
    [skillsHeader, skillDescription, selectedSkillHeader, skills],
    [
      shiftPageHeader,
      shiftPageDescription,
      shifts(
        (value) => (Array.isArray(value) ? value.map((e) => get('name', e)) : value),
        composeValidators(isPTShiftEmpty)
      ),
    ],
    [
      confirmationPageHeader,
      confirmationPageSubHeader,
      positionNameTypography,
      positionDescriptionTitle,
      positionDescriptionValue,
      corporationTitle,
      corporationValue,
      allowEmployerApprovalTitle,
      allowEmployerApprovalValue,
      corporationLocationTitle,
      corporationLocationValue,
      corporationDepartmentTitle,
      corporationDepartmentValue,
      gpsStrategyHeader,
      punchDistanceHeader,
      gpsStrategyValue,
      punchDistanceMeter,
      certificationTitle,
      certificationsValue,
      skillTitle,
      skillsValue,
      shiftsHeader,
      shiftsValue,
    ],
  ].filter((step) => step !== false);

export const PositionTemplateFormFieldDataForEmployer = ({
  corporationID,
  gpsEnforcement,
  applications,
}) =>
  [
    [
      positionInfoHeader,
      positionName,
      positionMeaning,
      description,
      positionDescriptionMeaning,
      industryChoice,
      industry,

      ...(applications ? approvalFields : []),
    ].filter((field) => !!field),
    [positionLocationHeader, locationField(corporationID), departmentField(corporationID)],
    gpsEnforcement ? gpsStep : false,
    [
      shiftPageHeader,
      shiftPageDescription,

      shifts(
        (value) => (Array.isArray(value) ? value.map((e) => get('name', e)) : value),
        composeValidators(isPTShiftEmpty)
      ),
    ],
    [
      confirmationPageHeader,
      confirmationPageSubHeader,
      positionNameTypography,
      positionDescriptionTitle,
      positionDescriptionValue,
      corporationTitle,
      employerCorporationNameField,
      allowEmployerApprovalTitle,
      allowEmployerApprovalValue,
      corporationLocationTitle,
      corporationLocationValue,
      corporationDepartmentTitle,
      corporationDepartmentValue,
      gpsStrategyHeader,
      punchDistanceHeader,
      gpsStrategyValue,
      punchDistanceMeter,
      shiftsHeader,
      shiftsValue,
    ],
  ].filter((step) => step !== false);

const getDisplayLocation = (location) => {
  let displayLoc;
  if (location) {
    displayLoc = location.map((loc) => ({ label: loc.description }));
  }
  return displayLoc;
};

const getDisplayDepartment = (department) => {
  let displayDep;
  if (department) {
    displayDep = department.map((dep) => ({ label: dep.name }));
  }
  return displayDep;
};

const getGPSValue = (values) => {
  let dispValue;
  if (values) {
    Object.keys(values).forEach((displayValue) => {
      if (values[displayValue]) dispValue = displayValue;
    });
  }
  return dispValue;
};

const certificationOrSkillLabel = (certificationsArrayValues) => {
  const res = [];
  map((cert) => {
    res.push({
      id: cert.id,
      label: cert.name,
    });
  }, certificationsArrayValues);
  return res;
};

const shiftsLabel = (shiftsArrayValues) => {
  const res = [];
  map((shift) => {
    res.push({
      id: `${shift.name}-${shift.start}-${shift.end}`,
      label: `${get('name', shift)} - ${minutesFromMidnightToTimeOnlyHrAndMins(
        get('start', shift)
      )} -${minutesFromMidnightToTimeOnlyHrAndMins(get('end', shift))}`,
    });
  }, shiftsArrayValues);
  return res;
};

export const readOnlyValues = (createPositionTemplateValues, employerCorporationName) => ({
  corporationChoice: 'What corporation and industry is this position for?',
  corporationHeader: 'Corporation',
  industryChoice: 'Industry',
  positionInfoHeader: 'Tell us more about the position you want to create.',
  positionLocationHeader:
    'Let us know which location(s) and department(s) this position applies to.',
  corporationValue: `${get(['corporation', 'name'], createPositionTemplateValues)}`,
  gpsStrategyHeader: 'GPS Strategy',
  gpsDescription: 'Employees will be prohibited from clocking in outside of the set Geofence.',
  geoHeader: 'Geofence Configuration',
  geoLoc: {
    longitude: Math.random() * 180,
    latitude: Math.random() * 85,
  },
  positionLocationSubHeader: `Select an existing location for ${createPositionTemplateValues?.corporation?.name} or enter in a new location below and continue.`,

  employerPositionLocationSubHeader: `Select an existing location for ${employerCorporationName}
 or enter in a new location below and continue.`,
  shiftPageHeader: 'How many shifts does this position include?',
  shiftPageDescription:
    'Create at least one shift to apply to this new position. Multiple shifts can be added to a single position.',
  shiftNameDescription:
    'A quick description of the shift to identify it while selecting throughout the system.',
  industry: {
    healthcare: false,
    manufacturing: false,
    hospitality: false,
  },
  gpsStrategy: {
    enforced: false,
    warning: false,
    unenforced: false,
  },
  positionMeaning: 'This will be used to reference this position in other parts of the system.',
  positionDescriptionMeaning:
    'A brief description detailing the specifics of the position you would like to create.',
  allowEmployerApprovalHeader: 'Allow Approvals for employee placements',
  allowEmployerApprovalSubheader:
    'Enabling this setting gives you the option to require placement approvals when you are posting a job',
  allowEmployerApproval: false,
  gpsHeader: 'Would you like to enable GPS enforcement for employees while punching in?',
  gpsSubHeader:
    'Create a digital geofence around your new positions location to ensure employees are on location when clocking in to their shift.',
  corporationDepartmentTitle: 'Department',
  allowEmployerApprovalTitle: 'Allow approvals',
  allowEmployerApprovalValue: createPositionTemplateValues?.allowEmployerApproval ? 'Yes' : 'No',
  corporationDepartmentValue: getDisplayDepartment(createPositionTemplateValues?.department),
  corporationLocationValue: getDisplayLocation(createPositionTemplateValues?.location),
  distanceDescription:
    'The maximum distance away from a location an employee is allowed to clock-in.',
  certificationHeader: 'Are any certifications required for this position?',
  certificationDescription: `Select an existing certification for  ${createPositionTemplateValues?.corporation?.name} or enter in a new certification below and continue.`,
  skillsHeader: 'Are any skills required for this position?',
  skillDescription: `Select an existing skill for  ${createPositionTemplateValues?.corporation?.name} or enter in a new skill below and continue.`,
  selectedSkillHeader: 'Selected Skills',
  shiftsHeader: 'Shifts',
  startTimeDescription: 'When the shift begins.',
  endTimeDescription: 'When the shift ends.',
  confirmationPageHeader:
    'Please verify that all details of your position are correct before posting.',
  confirmationPageSubHeader: 'Position Name',
  positionDescriptionTitle: 'Position Description',
  positionDescriptionValue: `${createPositionTemplateValues?.description}`,
  corporationTitle: 'Corporation',
  corporationLocationTitle: 'Corporation Location',
  punchDistanceHeader: 'Max Punch-in Distance',
  punchDistanceValue: `${createPositionTemplateValues?.punchDistance}`,
  punchDistanceMeter: createPositionTemplateValues?.punchDistance
    ? `${createPositionTemplateValues?.punchDistance} m`
    : '',
  gpsStrategyValue: getGPSValue(createPositionTemplateValues.gpsStrategy),
  certificationTitle: 'Required Certification',
  skillTitle: 'Required Skill',
  certificationsValue: certificationOrSkillLabel(createPositionTemplateValues.certifications),
  skillsValue: certificationOrSkillLabel(createPositionTemplateValues.skills),
  shiftsValue: shiftsLabel(createPositionTemplateValues.shifts),
  shiftValue: `${
    createPositionTemplateValues?.shift?.name
  } - ${minutesFromMidnightToTimeOnlyHrAndMins(
    createPositionTemplateValues?.shift?.start
  )} -${minutesFromMidnightToTimeOnlyHrAndMins(createPositionTemplateValues?.shift?.end)}`,
  positionTitle: 'Position',
  positionValue: `${createPositionTemplateValues?.positionTemplate?.name}`,
  selectedCertificationHeader: 'Selected Certifications',
  employerCorporationName,
});
