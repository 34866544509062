import { batch } from 'react-redux';

import { showSnackbar } from '../main/views/app';
import { logout, updateToken } from '../main/views/app/reducer';

export const logOutOn401 = (dispatch) => {
  batch(() => {
    dispatch(logout());
    dispatch(
      showSnackbar({
        message: 'Session expired, please login again!',
      })
    );
  });
};

export const updateUserToken = (dispatch, newToken) => {
  dispatch && dispatch(updateToken(newToken));
};
