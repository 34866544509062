import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const YellowAlert = () => (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#F9D247" />
      <path
        d="M11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8947 7.48043 13 7.73478 13 8V12C13 12.2652 12.8947 12.5196 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13C11.7348 13 11.4804 12.8946 11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12V8C11 7.73478 11.1054 7.48043 11.2929 7.29289Z"
        fill="#66561D"
      />
      <path
        d="M12.83 15.44C12.8679 15.4957 12.8981 15.5563 12.92 15.62C12.9558 15.6755 12.9828 15.7362 13 15.8C13.0049 15.8666 13.0049 15.9334 13 16C12.9984 16.1307 12.9713 16.2598 12.92 16.38C12.8751 16.5041 12.8035 16.6168 12.7101 16.7101C12.6168 16.8034 12.5041 16.8751 12.38 16.92C12.2603 16.9729 12.1309 17.0002 12 17.0002C11.8691 17.0002 11.7397 16.9729 11.62 16.92C11.4959 16.8751 11.3832 16.8034 11.2899 16.7101C11.1966 16.6168 11.1249 16.5041 11.08 16.38C11.0288 16.2598 11.0016 16.1307 11 16C10.9993 15.8684 11.0245 15.7379 11.0742 15.6161C11.124 15.4943 11.1973 15.3834 11.29 15.29C11.3872 15.2017 11.4988 15.1306 11.62 15.08C11.8021 15.0034 12.0028 14.9825 12.1968 15.0199C12.3908 15.0572 12.5694 15.1512 12.71 15.29L12.83 15.44Z"
        fill="#66561D"
      />
    </svg>
  </SvgIcon>
);

export default YellowAlert;
