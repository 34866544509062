import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const FormSectionHeader = ({ messageKey, messageValue }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        color: theme?.jobOrders?.createPage?.sectionHeader?.fontColor,
        fontSize: theme?.jobOrders?.createPage?.sectionHeader?.fontSize,
        fontFamily: theme?.jobOrders?.createPage?.sectionHeader?.font,
      }}
    >
      {LanguageConverter(messageKey, messageValue)}
    </Typography>
  );
};

FormSectionHeader.propTypes = {
  messageKey: PropTypes.string,
  messageValue: PropTypes.string,
};

export default FormSectionHeader;
