import React from 'react';
import PropTypes from 'prop-types';

import TablePagination from '@mui/material/TablePagination';

const CustomTablePagination = ({
  isLoading,
  page,
  pageSize,
  rowCount,
  setPage,
  setPageSize,
  rowsLength,
}) => (
  <TablePagination
    backIconButtonProps={
      isLoading
        ? {
            disabled: true,
          }
        : undefined
    }
    component="div"
    count={rowCount}
    page={page}
    onPageChange={(event, newPage) => {
      setPage(newPage);
    }}
    nextIconButtonProps={
      isLoading
        ? {
            disabled: true,
          }
        : undefined
    }
    onRowsPerPageChange={(event) => {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    }}
    rowsPerPage={pageSize}
    rowsPerPageOptions={[10, 15, 25]}
    SelectProps={{
      disabled: isLoading,
    }}
    labelDisplayedRows={({ from, to, count }) =>
      `${from}-${rowsLength ? from + rowsLength - 1 : to} of ${count}`
    }
  />
);

CustomTablePagination.propTypes = {
  isLoading: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  rowCount: PropTypes.number,
  setPage: PropTypes.func,
  setPageSize: PropTypes.func,
  rowsLength: PropTypes.number,
};

export default CustomTablePagination;
