import { get } from 'lodash/fp';

import theme from '../../theme';

const componentsSettingsMainHeaders = ['components', 'settings', 'typography', 'mainHeader'];
const componentsSettingsSectionHeaders = ['components', 'settings', 'typography', 'sectionHeader'];
const componentsSettingsSectionSubText = ['components', 'settings', 'typography', 'sectionSubText'];
const componentsSettingsSubSectionHeaders = [
  'components',
  'settings',
  'typography',
  'subSectionHeader',
];
const componentsSettingsSubSectionSubText = [
  'components',
  'settings',
  'typography',
  'subSectionSubText',
];
const componentsSettingsFieldHeaders = ['components', 'settings', 'typography', 'fieldHeader'];
const componentsSettingsFieldText = ['components', 'settings', 'typography', 'fieldText'];

export const mainHeaderStyling = {
  fontSize: get([...componentsSettingsMainHeaders, 'fontSize'], theme),
  fontWeight: get([...componentsSettingsMainHeaders, 'fontWeight'], theme),
  fontFamily: get([...componentsSettingsMainHeaders, 'fontFamily'], theme),
  color: get(['components', 'settings', 'palette', 'mainHeader', 'fontColor'], theme),
};
export const sectionHeaderStyling = {
  fontSize: get([...componentsSettingsSectionHeaders, 'fontSize'], theme),
  fontWeight: get([...componentsSettingsSectionHeaders, 'fontWeight'], theme),
  fontFamily: get([...componentsSettingsSectionHeaders, 'fontFamily'], theme),
  color: get(['components', 'settings', 'palette', 'sectionHeader', 'fontColor'], theme),
};
export const sectionSubTextStyling = {
  fontSize: get([...componentsSettingsSectionSubText, 'fontSize'], theme),
  fontWeight: get([...componentsSettingsSectionSubText, 'fontWeight'], theme),
  fontFamily: get([...componentsSettingsSectionSubText, 'fontFamily'], theme),
  color: get(['components', 'settings', 'palette', 'sectionSubText', 'fontColor'], theme),
  margin: get([...componentsSettingsSectionSubText, 'margin'], theme),
};
export const sectionSubHeaderStyling = {
  fontSize: get([...componentsSettingsSubSectionHeaders, 'fontSize'], theme),
  fontWeight: get([...componentsSettingsSubSectionHeaders, 'fontWeight'], theme),
  fontFamily: get([...componentsSettingsSubSectionHeaders, 'fontFamily'], theme),
  color: get(['components', 'settings', 'palette', 'subSectionHeader', 'fontColor'], theme),
};
export const sectionSubSubTextStyling = {
  fontSize: get([...componentsSettingsSubSectionSubText, 'fontSize'], theme),
  fontWeight: get([...componentsSettingsSubSectionSubText, 'fontWeight'], theme),
  fontFamily: get([...componentsSettingsSubSectionSubText, 'fontFamily'], theme),
  color: get(['components', 'settings', 'palette', 'subSectionSubText', 'fontColor'], theme),
  margin: get([...componentsSettingsSubSectionSubText, 'margin'], theme),
};
export const fieldHeaderStyling = {
  fontSize: get([...componentsSettingsFieldHeaders, 'fontSize'], theme),
  fontWeight: get([...componentsSettingsFieldHeaders, 'fontWeight'], theme),
  fontFamily: get([...componentsSettingsFieldHeaders, 'fontFamily'], theme),
  color: get(['components', 'settings', 'palette', 'fieldHeader', 'fontColor'], theme),
  lineHeight: get([...componentsSettingsFieldText, 'lineHeight'], theme),
};
export const fieldTextStyling = {
  fontSize: get([...componentsSettingsFieldText, 'fontSize'], theme),
  fontWeight: get([...componentsSettingsFieldText, 'fontWeight'], theme),
  fontFamily: get([...componentsSettingsFieldText, 'fontFamily'], theme),
  color: get(['components', 'settings', 'palette', 'fieldText', 'fontColor'], theme),
  lineHeight: get([...componentsSettingsFieldText, 'lineHeight'], theme),
};
export const fieldBlockStyling = {
  margin: theme.spacing(4, 0, 8, 0),
};
