import React from 'react';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import theme from '../../../../theme';
import { isShiftCompleted } from '../../../../utils';
import { TIMEKEEPING_STATUSES } from '../../../../views/jobOrders/jobOrderConstants';
import WaeButton, { BUTTON_VARIANT } from '../../../Button';

const cardTheme = theme.jobOrders.detail.roster.employeeRosterCard;
const btnStyles = {
  borderRadius: '5%',
  height: '25px',
  width: '100px',
  marginLeft: '10px',
};

export const showApproveBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  status === TIMEKEEPING_STATUSES.PENDING &&
  userRole === UserRole.EMPLOYER ? (
    <WaeButton
      actionColor={cardTheme.actionButton.actionColor}
      variant={BUTTON_VARIANT.OUTLINED}
      sx={btnStyles}
      borderColor={cardTheme.actionButton.borderColor}
      hoverColor={cardTheme.actionButton.hoverColor}
      dataTestId="update-status-approve-btn"
      onClick={onClick}
    >
      {LanguageConverter('buttonText.approve')}
    </WaeButton>
  ) : null;

export const showResolveBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  (status === TIMEKEEPING_STATUSES.PENDING || status === TIMEKEEPING_STATUSES.DISPUTED) &&
  userRole === UserRole.ADMIN ? (
    <WaeButton
      actionColor={cardTheme.actionButton.actionColor}
      variant={BUTTON_VARIANT.OUTLINED}
      sx={btnStyles}
      borderColor={cardTheme.actionButton.borderColor}
      hoverColor={cardTheme.actionButton.hoverColor}
      dataTestId="update-status-resolve-btn"
      onClick={onClick}
    >
      {LanguageConverter('buttonText.resolve')}
    </WaeButton>
  ) : null;

export const showFinalizeBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  (status === TIMEKEEPING_STATUSES.APPROVED || status === TIMEKEEPING_STATUSES.RESOLVED) &&
  userRole === UserRole.ADMIN ? (
    <WaeButton
      actionColor={cardTheme.actionButton.actionColor}
      variant={BUTTON_VARIANT.OUTLINED}
      sx={btnStyles}
      borderColor={cardTheme.actionButton.borderColor}
      hoverColor={cardTheme.actionButton.hoverColor}
      dataTestId="update-status-finalize-btn"
      onClick={onClick}
    >
      {LanguageConverter('buttonText.finalize')}
    </WaeButton>
  ) : null;

export const showUnFinalizeBtn = (status, shiftEnd, userRole, onClick) =>
  isShiftCompleted(shiftEnd) &&
  status === TIMEKEEPING_STATUSES.FINALIZED &&
  userRole === UserRole.ADMIN ? (
    <WaeButton
      actionColor={cardTheme.actionButton.actionColor}
      variant={BUTTON_VARIANT.OUTLINED}
      sx={btnStyles}
      borderColor={cardTheme.actionButton.borderColor}
      hoverColor={cardTheme.actionButton.hoverColor}
      dataTestId="update-status-unfinalize-btn"
      onClick={onClick}
    >
      {LanguageConverter('buttonText.unFinalize')}
    </WaeButton>
  ) : null;
