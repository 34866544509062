/*
 * Validates form values on submit if date1 is after date2
 * props format: ("field1", "field2", {fieldNameErrorShouldDisplay: "error message"} )
 */

export const isGreaterThanDate = (field1, field2, errorResponse) => (values) => {
  if (values[field1] > values[field2]) {
    return errorResponse;
  }
  return undefined;
};

/*
 * Validates form values on submit if both fields match
 * props format: ("field1", "field2", {fieldNameErrorShouldDisplay: "error message"} )
 */

export const doFieldsMatch = (field1, field2, errorResponse) => (values) => {
  if (values[field1] !== values[field2]) {
    return errorResponse;
  }
  return undefined;
};

/*
 * Validates form values on submit if field is empty
 * props format: ('fieldName', {fieldName: 'Error message'})
 */
export const isEmpty = (fieldName, errorResponse) => (values) => {
  if (!values[fieldName] || values[fieldName].length === 0) {
    return errorResponse;
  }
  return undefined;
};
