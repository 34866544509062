import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import Form from '../../../components/Form';
import FormHeader from '../../../components/FormHeader';
import {
  selectErrors,
  selectIsProcessingPostCorp,
} from '../../../store/selectors/createCorporationSelectors';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const CreateCorporationForm = memo(({ formData, onSubmit }) => {
  const navigate = useNavigate();
  const isProcessingPostCorp = useSelector(selectIsProcessingPostCorp);
  const errorData = useSelector(selectErrors);

  return (
    <RootGrid container>
      <FormGrid item xs={11} sm={8} md={6} lg={6}>
        <FormHeader headerText="Onboard Corporation" />
        <Form
          buttonData={[
            {
              onClick: () => navigate('/corporations/corporationList'),
              text: 'Cancel',
            },
          ]}
          confirmationModal={{
            buttonText: 'Confirm',
            submitting: isProcessingPostCorp,
          }}
          formFieldData={formData}
          onSubmit={onSubmit}
          errorData={errorData}
        />
      </FormGrid>
    </RootGrid>
  );
});

CreateCorporationForm.propTypes = {
  formData: PropTypes.arrayOf(PropTypes.shape({})),
  onSubmit: PropTypes.func,
};

export default CreateCorporationForm;
