export const convertTextToCSV = ({ csvRowsAsText, fileName }) => {
  const blob = new Blob([csvRowsAsText], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const handleCSVConversion = ({ rows, fileName }) => {
  const processRow = (row) => {
    let rowVal = '';
    for (let j = 0; j < row.length; j += 1) {
      let innnerVal = row[j] === null ? '' : row[j]?.toString() || 'Error';
      if (row[j] instanceof Date) {
        innnerVal = row[j].toLocaleString();
      }
      let result = innnerVal.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
      if (j > 0) rowVal += ',';
      rowVal += result;
    }
    return `${rowVal}\n`;
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i += 1) {
    csvFile += processRow(rows[i]);
  }

  convertTextToCSV({ csvRowsAsText: csvFile, fileName });
};
