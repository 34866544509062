import React, { useCallback, useEffect, useRef, useState } from 'react';
import { get, uniqBy } from 'lodash/fp';
import PropTypes from 'prop-types';

import { useDims } from '../../../utils/customHooks';

import Filter from './Filter';
import ModelFilter from './ModelFilter';

const ResponsiveFilter = ({ items, onFilterChange, containerDims, forceCollapse }) => {
  const filterContainerRef = useRef();

  const filterDims = useDims(filterContainerRef || <div />);

  const [filtersWidth, setFiltersWidth] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (filterDims.width > filtersWidth) {
      setFiltersWidth(filterDims.width);
    }
  }, [filterDims, filtersWidth]);

  useEffect(() => {
    setCollapsed(filtersWidth > containerDims.width);
  }, [containerDims.width, filtersWidth]);

  const [filterState, setFilterState] = useState([]);
  const [itemState, setItemState] = useState(items);

  const buildNewFilterState = useCallback(
    (filters) => {
      let newFilterState = filterState;
      filters.forEach((filter) => {
        const { value, field } = filter;

        newFilterState =
          value !== null &&
          (!Array.isArray(value) ||
            (Array.isArray(value) && value.length > 0) ||
            items.find((i) => i.field === field).allowEmptyArray)
            ? uniqBy('field', [filter, ...filterState])
            : filterState.filter((item) => item.field !== field);
      });

      return newFilterState;
    },
    [filterState, items]
  );

  const updateItemState = useCallback(
    (newFilterState) => {
      setItemState(
        itemState.map((item) => {
          const itemValue = newFilterState.filter(
            (selected) => get('field', selected) === get('field', item)
          )[0];

          const initialValueObject = {
            name: get('value', itemValue),
            value: get('value', itemValue),
          };

          const updatedFilterObject =
            itemValue || item.allowEmptyArray
              ? { ...item, initialValue: initialValueObject || {} }
              : item;
          return updatedFilterObject;
        })
      );
    },
    [itemState]
  );

  const handleValueChange = useCallback(
    (filters) => {
      const newFilterState = buildNewFilterState(filters);

      if (
        // If filters that "changed" are exactly equal current filters, don't do anything
        filterState.length !== 0 &&
        filterState.length === newFilterState.length &&
        filterState.every((e1) =>
          newFilterState.some((e2) => e1.field === e2.field && e1.value === e2.value)
        )
      ) {
        return;
      }

      setFilterState(newFilterState);
      onFilterChange({ items: newFilterState });
      updateItemState(newFilterState);
    },
    [filterState, onFilterChange, updateItemState, buildNewFilterState]
  );

  const customValueChangeHandler = (newVal) => {
    const newItemState = itemState.map((item) => {
      const newItem = { ...item };
      if (get('field', item) === get('field', newVal[0])) {
        const initialValueObject = {
          name: get('value', newVal[0]),
          value: get('value', newVal[0]),
        };
        newItem.initialValue = initialValueObject;
      }
      return newItem;
    });
    setItemState(newItemState);
  };

  return collapsed || forceCollapse ? (
    <ModelFilter
      items={itemState}
      handleValueChange={handleValueChange}
      customValueChangeHandler={customValueChangeHandler}
    />
  ) : (
    <Filter
      items={itemState}
      handleValueChange={handleValueChange}
      ref={filterContainerRef}
      customValueChangeHandler={customValueChangeHandler}
    />
  );
};

ResponsiveFilter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onFilterChange: PropTypes.func,
  containerDims: PropTypes.shape({
    width: PropTypes.number,
  }),
  forceCollapse: PropTypes.bool,
};

export default ResponsiveFilter;
