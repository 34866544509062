export const LOCAL_STORAGE_KEYS = {
  userDataKey: 'userDataKey',
  pathname: 'pathname',
};

export const setItemToLocalStorage = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);
    window.localStorage.setItem(key, serializedValue);
  } catch (e) {
    // FIXME: HB-959 Handle error case (when access to localStorage is denied, ...)
  }
};

export const getItemFromLocalStorage = (key) => {
  try {
    const serializedValue = window.localStorage.getItem(key);
    if (serializedValue === null) {
      return undefined;
    }
    return JSON.parse(serializedValue);
  } catch (e) {
    return undefined;
  }
};

export const clearLocalStorageItem = (key) => {
  window.localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  window.localStorage.clear();
};
