import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';

import {
  GEOLOCATION_STATUS_DENIED,
  GEOLOCATION_STATUS_GRANTED,
  GEOLOCATION_STATUS_PROMPT,
} from '@careerstart/wae-common/src/main/constants/constants';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import LocationIcon from '../../../assets/icons/LocationIcon';
import theme from '../../../theme';
import AlertBanner from '../AlertBanner';

const GeoLocationBanner = () => {
  const alertBannerTheme = get(['candidate', 'components', 'alertBanner'], theme);

  const [showGeoBanner, setShowGeoBanner] = useState(false);

  const updateUserLocation = (permissionStatusState) => {
    if (permissionStatusState === null || permissionStatusState === GEOLOCATION_STATUS_DENIED) {
      setShowGeoBanner(true);
    } else if (
      permissionStatusState === GEOLOCATION_STATUS_GRANTED ||
      permissionStatusState === GEOLOCATION_STATUS_PROMPT
    ) {
      setShowGeoBanner(false);
      navigator.geolocation.getCurrentPosition(
        () => {},
        () => {
          setShowGeoBanner(true);
        }
      );
    }
  };

  useEffect(() => {
    if (!navigator?.permissions) {
      updateUserLocation(null);
    } else {
      navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        // Check initial status
        updateUserLocation(permissionStatus.state);
        const permissionData = permissionStatus;
        permissionData.onchange = () => {
          // When permission status updates, re-check status
          updateUserLocation(permissionStatus.state);
        };
      });
    }
  }, []);

  return (
    showGeoBanner && (
      <AlertBanner
        LeftIcon={LocationIcon}
        message={LanguageConverter('candidate.geoLocation.banner')}
        backgroundColor={get(['backgroundColor'], alertBannerTheme)}
        messageSx={{
          color: get(['fontColor'], alertBannerTheme),
          fontFamily: get(['font'], alertBannerTheme),
        }}
      />
    )
  );
};
export default GeoLocationBanner;
