export const TIMEKEEPING_STATUSES = {
  APPROVED: 'approved',
  CONFLICT: 'conflict',
  PENDING: 'pending',
  DISPUTED: 'disputed',
  RESOLVED: 'resolved',
  FINALIZED: 'finalized',
  OVERTIME: 'overtime',
};

export const ACT_BTN_TEXT = {
  APPROVE: 'Approve',
  RESOLVE: 'Resolve',
  FINALIZE: 'Finalize',
  UNFINALIZE: 'Send for Review',
};

export const FIFTEEN_MINS_IN_EPOCH = 900000;

export const API_PATH = 'timecards';
