import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import CancelReasons from '@careerstart/wae-common/src/main/constants/Cancel-reasons';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Box, FormControlLabel, RadioGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import JOB_TYPE from '../../../../../../main/views/jobOrders/jobOrderDetail/constants';
import BlueBackgroundRaido from '../../../../../assets/icons/BlueBackgroundRadio';
import Delete from '../../../../../assets/icons/Delete.svg';
import Button, { BUTTON_VARIANT } from '../../../../../components/Button';
import IconButton from '../../../../../components/IconButton';
import theme from '../../../../../theme';

import BasicDrawer from './BasicDrawer';

const detailViewTypographyReasonText = [
  'components',
  'detailView',
  'typography',
  'reasonTextBlack',
];
const FOUR_HOURS_IN_MS = 14400000;

const CancelJobDrawer = ({
  jobId,
  numOpenings,
  start,
  jobType,
  user,
  isOpen,
  onClose,
  handleCancelJob,
}) => {
  const [cancelReason, setCancelReason] = useState('');
  const [showMustSelectAReason, setShowMustSelectAReason] = useState(false);
  const [cancelJobStep, setCancelJobStep] = useState(() => {
    const timeDifference = start - new Date().getTime();
    const allowedCancellationTimeWindow =
      user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER ? 0 : FOUR_HOURS_IN_MS;
    // Checks if the difference between job's start time and current time is greater than 4 hours.
    if (jobType === JOB_TYPE.THIS_SHIFT && timeDifference <= allowedCancellationTimeWindow) {
      return 2;
    }
    return 1;
  });

  const handleGoBackButton = () => {
    if (cancelJobStep === 1 || cancelJobStep === 2) {
      onClose();
      return;
    }
    setCancelJobStep(1);
  };

  const handleContinueButton = () => {
    setCancelJobStep(3);
  };

  const handleCancelJobButton = () => {
    if (!CancelReasons[cancelReason]) {
      setShowMustSelectAReason(true);
      return;
    }
    handleCancelJob({
      id: jobId,
      reason: CancelReasons[cancelReason],
    });
  };

  const headerText = (step) => {
    switch (step) {
      case 1:
        return `${
          jobType === JOB_TYPE.THIS_SHIFT
            ? LanguageConverter('jobDetails.cancelThisShift')
            : LanguageConverter('jobDetails.cancelEntireSeries')
        } `;
      case 2:
        return `${LanguageConverter('jobDetails.cancelConfirm')}`;
      case 3:
        return `${LanguageConverter('jobDetails.cancelReason')}`;
      default:
        return '';
    }
  };

  const subHeaderText = (step) => {
    switch (step) {
      case 1:
        return jobType === JOB_TYPE.THIS_SHIFT
          ? `${LanguageConverter('jobDetails.cancelThisShiftConfirm', numOpenings)}`
          : `${LanguageConverter('jobDetails.cancelSeriesConfirm')}`;
      case 2:
        return `${LanguageConverter('jobDetails.cancelWithin4Hr')} `;
      case 3:
        return `${LanguageConverter('jobDetails.cancelSelectReason')}`;
      default:
        return '';
    }
  };

  const HeaderIcon = () => (
    <Box
      component="img"
      sx={{
        height: 51.52,
        width: 51.52,
      }}
      alt="Delete"
      src={Delete}
    />
  );

  const Content = () => {
    if (cancelJobStep === 1 || cancelJobStep === 2) return null;
    const cancelReasonOptions = [];
    Object.keys(CancelReasons).forEach((key) => {
      cancelReasonOptions.push({ id: key, label: CancelReasons[key] });
    });
    const handleRadioButtonChange = (event, val) => {
      setShowMustSelectAReason(false);
      setCancelReason(val);
    };
    return (
      <Box>
        <FormControl>
          <RadioGroup name="controlled-radio-buttons-group" onChange={handleRadioButtonChange}>
            {cancelReasonOptions.map((reason) => (
              <FormControlLabel
                key={reason.id}
                value={reason.id}
                control={<BlueBackgroundRaido />}
                label={reason.label}
                checked={cancelReason === reason.id}
                sx={{
                  color: get([...detailViewTypographyReasonText, 'fontColor'], theme),
                  '& .MuiTypography-root': {
                    fontSize: get([...detailViewTypographyReasonText, 'fontSize'], theme),
                    fontFamily: get([...detailViewTypographyReasonText, 'fontFamily'], theme),
                  },
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {showMustSelectAReason && (
          <Box
            sx={{
              display: 'flex',
              backgroundColor: 'orange',
              borderRadius: '10px',
              marginTop: '20px',
            }}
          >
            <Box sx={{ paddingRight: '5px' }}>
              <ErrorOutlineRoundedIcon />
            </Box>
            <Box>{LanguageConverter('jobDetails.cancelSelectReason')}</Box>
            <Box>
              <IconButton
                iconElement={<CloseIcon />}
                onClick={() => {
                  setShowMustSelectAReason(false);
                }}
                size="small"
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const GoBackButton = () => (
    <Button
      variant={BUTTON_VARIANT.OUTLINED}
      onClick={handleGoBackButton}
      text={LanguageConverter('button.goBack')}
    />
  );

  const ActionButton = () => {
    if (cancelJobStep === 1) {
      // continue
      return (
        <Button
          onClick={handleContinueButton}
          sx={{
            marginLeft: '5px',
          }}
          text={LanguageConverter('button.continue')}
        />
      );
    }

    if (cancelJobStep === 2) {
      // do not show anything
      return null;
    }

    return (
      <Button
        variant={BUTTON_VARIANT.DELETE}
        onClick={handleCancelJobButton}
        sx={{ marginLeft: '5px' }}
        text={LanguageConverter('button.job.cancel')}
      />
    );
  };

  return (
    <BasicDrawer
      goBackButton={<GoBackButton />}
      actionButton={<ActionButton />}
      type={jobType}
      headerIcon={(cancelJobStep !== 3 && <HeaderIcon />) || null}
      header={headerText(cancelJobStep)}
      subHeader={subHeaderText(cancelJobStep)}
      content={<Content cancelJobStep={cancelJobStep} />}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

CancelJobDrawer.propTypes = {
  start: PropTypes.number,
  jobId: PropTypes.string,
  jobType: PropTypes.string,
  numOpenings: PropTypes.number,
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  handleCancelJob: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CancelJobDrawer;
