import get from 'lodash/fp/get';
import has from 'lodash/fp/has';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';
import unionBy from 'lodash/fp/unionBy';

import { createSlice } from '@reduxjs/toolkit';

import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../utils';
import { calculateTotalJobPay } from '../../../utils/Candidate/payHelpers';

const reformatGroupResponse = (group) => {
  const groupData = {
    ...group,
    _id: group._id,
    payRateReg: group.payRateReg,
    title: group.name,
    totalPay: group.totalPay,
    corporationName: group.corporation.name,
    corporationId: group.corporation._id,
  };
  return groupData;
};

const reformatJobEntry = (jobEntry) => {
  const rest = omit(['_id'], jobEntry);
  const shiftGroup = get('shiftGroup', jobEntry);

  const shiftPayData = {
    payRateReg: get('payRateReg', jobEntry),
    start: get('start', jobEntry),
    end: get('end', jobEntry),
  };

  return {
    id: get('_id', jobEntry),
    count: 1,
    groupId: shiftGroup ? shiftGroup.id : null,
    path: shiftGroup ? null : [get('_id', jobEntry)],
    title: get('name', jobEntry),
    name: get('name', jobEntry),
    corporation: get(['corporation', 'name'], jobEntry),
    jobPlacements: get('placementsCount', jobEntry),
    address: get(['location', 'address'], jobEntry),
    startDate: epochToDateInReadableFormat(get('start', jobEntry)),
    startTime: epochToTimeInReadableFormat(get('start', jobEntry)),
    endDate: epochToDateInReadableFormat(get('end', jobEntry)),
    endTime: epochToTimeInReadableFormat(get('end', jobEntry)),
    fillRate: Math.floor((100 * get('placementsCount', jobEntry)) / get('numOpenings', jobEntry)),
    shiftPay: calculateTotalJobPay(shiftPayData),
    cancelledPlacement: {},
    ...rest,
  };
};

const initialState = {
  certifications: [],
  corporations: [],
  files: {},
  jobGroupsList: [],
  jobGroupsCount: 0,
  dayGroupsCount: 0,
  longGroupsCount: 0,
  filteredJobGroupsList: [],
  myJobCount: 0,
  isLoadingCertifications: false,
  isLoadingCorporations: false,
  isLoadingJobOrderList: false,
  isLoadingJobOrder: false,
  isLoadingSelectedGroupsJobs: false,
  isLoadingPlacements: false,
  isLoadingPathSelectedJob: false,
  isLoadingPathSelectedPlacement: false,
  isLoadingAllJobsList: false,
  isLoadingDayJobsList: false,
  isLoadingLongJobsList: false,
  isProcessingCreatePlacement: false,
  isLoadingCancel: false,
  jobOrders: [],
  locations: [],
  placements: [],
  workGroupCount: null,
  jobOrdersMaxPage: 0,
  selectedGroup: {},
  selectedPlacement: {},
  allJobsPage: 0,
  dayJobsPage: 0,
  longJobsPage: 0,
  myJobsPage: 0,
  selectedGroupsJobs: [],
  selectedGroupsJobsPage: 0,
  isLoadingSkills: false,
  skills: [],
  hasResetList: false,
  isLoadingPlacementList: false,
};

export const candidateJobsListSlice = createSlice({
  name: 'candidateJobsListSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    // get work groups actions for all jobs
    resetCandidateJobsList: (state) => {
      state.allJobsPage = 0;
      state.dayJobsPage = 0;
      state.longJobsPage = 0;
      state.jobGroupsCount = 0;
      state.dayGroupsCount = 0;
      state.longGroupsCount = 0;
      state.jobGroupsList = [];
      state.hasResetList = true;
    },
    getCandidateJobsList: (state) => {
      state.isLoadingAllJobsList = true;
    },
    getCandidateJobsListProcessed: (state, action) => {
      state.isLoadingAllJobsList = false;
      state.allJobsPage += 1;
      const { groups, count } = action.payload.data;
      const updatedData = groups.map((group) => reformatGroupResponse(group));
      state.jobGroupsCount = count;
      state.jobGroupsList.push(...updatedData);
      state.hasResetList = false;
    },
    getCandidateJobsListError: (state) => {
      state.isLoadingAllJobsList = false;
    },
    getCandidateDayJobsList: (state) => {
      state.isLoadingDayJobsList = true;
    },
    getCandidateDayJobsListProcessed: (state, action) => {
      state.isLoadingDayJobsList = false;
      state.dayJobsPage += 1;
      const { groups, count } = action.payload.data;
      const updatedData = groups.map((group) => reformatGroupResponse(group));
      state.dayGroupsCount = count;
      state.jobGroupsList.push(...updatedData);
      state.hasResetList = false;
    },
    getCandidateDayJobsListError: (state) => {
      state.isLoadingDayJobsList = false;
    },

    getCandidateLongJobsList: (state) => {
      state.isLoadingLongJobsList = true;
    },
    getCandidateLongJobsListProcessed: (state, action) => {
      state.isLoadingLongJobsList = false;
      state.longJobsPage += 1;
      const { groups, count } = action.payload.data;
      const updatedData = groups.map((group) => reformatGroupResponse(group));
      state.longGroupsCount = count;
      state.jobGroupsList.push(...updatedData);
      state.hasResetList = false;
    },
    getCandidateLongJobsListError: (state) => {
      state.isLoadingLongDayJobsList = false;
    },
    getCandidatePathSelectedJob: (state) => {
      state.isLoadingPathSelectedJob = true;
    },
    getCandidatePathSelectedJobProcessed: (state, action) => {
      state.isLoadingPathSelectedJob = false;
      state.allJobsPage += 1;
      const { groups } = action.payload.data;
      const reformattedJob = reformatGroupResponse(groups[0]);

      state.jobGroupsList.unshift(reformattedJob);

      state.selectedGroup = reformattedJob;
      state.selectedGroupsJobs = [];
      state.selectedGroupsJobsPage = 0;
    },
    getCandidatePathSelectedJobError: (state) => {
      state.isLoadingPathSelectedJob = false;
    },

    // get filtered results for all jobs
    getFilteredJobsList: (state) => {
      state.isLoadingFilteredJobsList = true;
    },
    getFilteredJobsListProcessed: (state, action) => {
      state.isLoadingFilteredJobsList = false;
      state.allJobsPage += 1;
      const { groups, count } = action.payload.data;
      const updatedData = groups.map((group) => reformatGroupResponse(group));

      state.jobGroupsCount = count;
      state.jobGroupsList = updatedData;
    },
    getFilteredJobsListError: (state) => {
      state.isLoadingFilteredJobsList = false;
    },

    // get files actions
    getFilesForCandidate: (state, action) => {
      const imageID = get(['payload', 'id'], action);

      // in case we have called for this file more than once, don't overwrite it with null.
      if (!has(imageID, state.files)) {
        state.files[imageID] = null;
      }
    },
    getFilesForCandidateProcessed: (state, action) => {
      const data = get(['payload', 'data'], action);
      const imageID = get(['payload', 'headers', 'id'], action);

      const blobToURL = URL.createObjectURL(data);

      state.files[imageID] = blobToURL;
    },

    getFilesForCandidateError: () => {},

    // get corporations actions
    getCorporationsForCandidate: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsForCandidateProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);
      state.corporations = documents;

      state.isLoadingCorporations = false;
    },
    getCorporationsForCandidateError: (state) => {
      state.isLoadingCorporations = false;
    },

    // create placement actions
    createPlacementsForCandidate: (state) => {
      state.isProcessingCreatePlacement = true;
      state.createPlacementErrorMessage = undefined;
    },
    createPlacementsForCandidateProcessed: (state, action) => {
      const data = get(['payload', 'data', 'documents'], action);
      state.createdPlacements = data;
      state.isProcessingCreatePlacement = false;
    },
    createPlacementsForCandidateError: (state, action) => {
      const errorMsg = get(['payload', 'GLOBAL', 'messageKey'], action);
      state.isProcessingCreatePlacement = false;
      state.createPlacementErrorMessage = errorMsg;
    },

    setPlacementsInitState: () => initialState,

    removeErrorMessage: (state) => {
      state.createPlacementErrorMessage = undefined;
    },

    // list of certification actions
    getCertificationsForCandidate: (state) => {
      state.isLoadingCertifications = true;
    },

    getCertificationsForCandidateProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);
      const certifications = map((certification) => {
        const { _id, ...rest } = certification;
        return {
          id: _id,
          ...rest,
        };
      }, documents);
      state.certifications = certifications;
      state.isLoadingCertifications = false;
    },

    getCertificationsForCandidateError: (state) => {
      state.isLoadingCertifications = false;
    },

    // list of placements actions
    getPlacements: (state) => {
      state.isLoadingPlacements = true;
    },
    getPlacementsProcessed: (state, action) => {
      state.isLoadingPlacements = false;
      const placementsFromApi = get(['payload', 'data', 'documents'], action);
      state.placements = placementsFromApi;
    },
    getPlacementsError: (state) => {
      state.isLoadingPlacements = false;
    },

    // Get job orders for group actions
    getGroupsJobOrders: (state) => {
      state.isLoadingSelectedGroupsJobs = true;
    },
    getGroupsJobOrdersProcessed: (state, action) => {
      state.isLoadingSelectedGroupsJobs = false;
      const responseDocuments = get(['payload', 'data', 'documents'], action);
      state.selectedGroupsJobs = [...state.selectedGroupsJobs, ...responseDocuments];
      state.selectedGroupsJobsCount = get(['payload', 'data', 'count'], action);
      state.selectedGroupsJobsPage += 1;
    },
    getGroupsJobOrdersError: (state) => {
      state.isLoadingSelectedGroupsJobs = false;
    },

    // list of job order for my jobs view
    resetCandidateMyJobsList: (state) => {
      state.myJobsPage = 0;
      state.myJobCount = 0;
      state.placements = [];
    },
    getPlacementsForCandidate: (state, action) => {
      // if (action.payload.page < state.jobOrdersMaxPage) {
      //   state.jobOrders = [];
      // }
      state.isLoadingPlacementList = true;
      state.jobOrdersMaxPage = action.payload.page;
    },

    getPlacementsForCandidateProcessed: (state, action) => {
      const { documents, count } = action.payload.data;
      state.myJobCount = count;
      const updatedData = documents.map((placement) => ({
        ...placement,
        jobOrder: reformatJobEntry(placement.jobOrder),
      }));
      state.isLoadingPlacementList = false;
      state.myJobsPage += 1;
      state.placements.push(...updatedData);
    },

    getPlacementsForCandidateError: (state) => {
      state.isLoadingPlacementList = false;
    },
    getPathSelectedPlacementForCandidate: (state) => {
      state.isLoadingPathSelectedPlacement = true;
    },

    getPathSelectedPlacementForCandidateProcessed: (state, action) => {
      const { documents } = action.payload.data;
      const updatedData = {
        ...documents[0],
        jobOrder: reformatJobEntry(documents[0].jobOrder),
      };

      state.selectedPlacement = updatedData;
      state.placements.unshift(updatedData);
      state.isLoadingPathSelectedPlacement = false;
    },

    getPathSelectedPlacementForCandidateError: (state) => {
      state.isLoadingPathSelectedPlacement = false;
    },

    setJobOrders: (state, action) => {
      state.jobOrders = action.payload;
    },

    // singular job order actions
    getJobOrderForCandidate: (state) => {
      state.isLoadingJobOrder = true;
    },

    getJobOrderForCandidateProcessed: (state, action) => {
      state.isLoadingJobOrder = false;

      // add the job order to the list of job orders if it's not already there
      // update the job order if it is already there
      const jobOrder = get(['payload', 'data', 'document'], action);
      state.jobOrders = unionBy('_id', [jobOrder], state.jobOrders);

      // add the location of the job order to the list of locations if it's not already there
      // update the location if it is already there
      const { location } = jobOrder;
      state.locations = unionBy('_id', [location], state.locations);
    },

    getJobOrderForCandidateError: (state) => {
      state.isLoadingJobOrder = false;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
      state.selectedGroupsJobs = [];
      state.selectedGroupsJobsPage = 0;
    },
    setSelectedPlacement: (state, action) => {
      state.selectedPlacement = action.payload;
    },

    cancelPlacementForCandidate: (state) => {
      state.isLoadingCancel = true;
    },
    cancelPlacementForCandidateProcessed: (state, action) => {
      state.isLoadingCancel = false;
      state.cancelledPlacement = get(['payload', 'data', 'document'], action);
    },
    cancelPlacementForCandidateError: (state) => {
      state.isLoadingCancel = false;
    },

    rescindPlacementForCandidate: (state, action) => {
      state.isLoadingCancel = true;
      const match = state.placements.find((p) => p._id === action.payload.placement);
      state.rescindingApplication = match;
    },
    rescindPlacementForCandidateProcessed: (state) => {
      state.isLoadingCancel = false;
      state.cancelledPlacement = state.rescindingApplication;
      state.rescindingApplication = undefined;
    },
    rescindPlacementForCandidateError: (state) => {
      state.isLoadingCancel = false;
    },

    clearCancelledPlacement: (state) => {
      const newPlacements = state.placements.filter((p) => p._id !== state.cancelledPlacement.id);
      state.cancelledPlacement = undefined;
      state.selectedPlacement = {};
      state.placements = newPlacements;
    },
    getSkills: (state) => {
      state.isLoadingSkills = true;
    },
    getSkillsProcessed: (state, action) => {
      state.isLoadingSkills = false;
      const skillNames = get(['payload', 'data', 'documents'], action).map((i) => get(['name'], i));
      state.skills = skillNames;
    },
    getSkillsError: (state) => {
      state.isLoadingSkills = false;
    },
    setSkills: (state, action) => {
      state.skills = action.payload;
    },

    /* eslint-disable no-param-reassign */
  },
});

export const candidateJobsListReducer = candidateJobsListSlice.reducer;

export const {
  getCertificationsForCandidate,
  getCertificationsForCandidateError,
  getCertificationsForCandidateProcessed,

  getCorporationsForCandidate,
  getCorporationsForCandidateError,
  getCorporationsForCandidateProcessed,

  getFilesForCandidate,
  getFilesForCandidateError,
  getFilesForCandidateProcessed,

  resetCandidateMyJobsList,
  getPlacementsForCandidate,
  setJobOrders,
  getPlacementsForCandidateError,
  getPlacementsForCandidateProcessed,

  getPathSelectedPlacementForCandidate,
  getPathSelectedPlacementForCandidateError,
  getPathSelectedPlacementForCandidateProcessed,

  getJobOrderForCandidate,
  getJobOrderForCandidateError,
  getJobOrderForCandidateProcessed,

  getPlacements,
  getPlacementsError,
  getPlacementsProcessed,

  myJobSearchHandler,

  getGroupsJobOrders,
  getGroupsJobOrdersProcessed,
  getGroupsJobOrdersError,

  resetCandidateJobsList,
  getCandidateJobsList, // for LazyLoad
  getCandidateJobsListError,
  getCandidateJobsListProcessed,

  getCandidateDayJobsList, // for LazyLoad
  getCandidateDayJobsListError,
  getCandidateDayJobsListProcessed,

  getCandidateLongJobsList, // for LazyLoad
  getCandidateLongJobsListError,
  getCandidateLongJobsListProcessed,

  getCandidatePathSelectedJob,
  getCandidatePathSelectedJobProcessed,
  getCandidatePathSelectedJobError,

  getFilteredJobsList,
  getFilteredJobsListProcessed,
  getFilteredJobsListError,

  postPlacement,
  postPlacementError,
  postPlacementProcessed,

  createPlacementsForCandidate,
  createPlacementsForCandidateError,
  createPlacementsForCandidateProcessed,

  setPlacementsInitState,
  removeErrorMessage,

  setSelectedGroup,
  setSelectedPlacement,

  cancelPlacementForCandidate,
  cancelPlacementForCandidateProcessed,
  cancelPlacementForCandidateError,

  rescindPlacementForCandidate,
  rescindPlacementForCandidateProcessed,
  rescindPlacementForCandidateError,

  clearCancelledPlacement,
  getSkills,
  getSkillsProcessed,
  getSkillsError,
  setSkills,
} = candidateJobsListSlice.actions;
