// eslint-disable-next-line import/prefer-default-export
export const setLanguage = (setDictData) => {
  const userLocale =
    navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  let baseLocale;
  if (userLocale.indexOf('-') !== -1) {
    baseLocale = userLocale.substring(0, userLocale.indexOf('-'));
  } else {
    baseLocale = userLocale;
  }

  import(`../assets/languages/${baseLocale}.json`)
    .then((output) => {
      setDictData(output);
    })
    .catch((err) => {
      // Error catch: if file doesn't exist, pull it from the backend
      // eslint-disable-next-line no-console
      console.log(err);

      // If it is any other type of error, handle accordingly and default to english
    });
};
