import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import WaeButton, { BUTTON_VARIANT } from '../../../components/Button';
import theme from '../../../theme';
import { sendNewSkillToBackend } from '../reducer';

const AddNewSkillOrCertModal = ({ isOpen, type, setIsOpen }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [isSkillEntered, setIsSkillEntered] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
    setName('');
  };

  const handleInputChange = (event) => {
    setName(event.target.value);
    if (name && name.length >= 0) {
      setIsSkillEntered(true);
    } else setIsSkillEntered(false);
  };

  const handleDirectAddSkill = () => {
    if (name && name.length >= 0) {
      dispatch(
        sendNewSkillToBackend({
          name: name.trim(),
          duration: 864000000,
          description: 'skill',
        })
      );
      setIsOpen(false);
      setName('');
    } else {
      setIsSkillEntered(false);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: theme.skillsAndCerts.palette.modalBgColor,
    border: `2px solid ${theme.skillsAndCerts.palette.modalBorder}`,
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
    padding: theme.spacing(5),
  };

  const primaryButtonText = LanguageConverter(
    type === 'skill' ? 'button.skill.add' : 'button.cert.add'
  );

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontSize: theme.skillsAndCerts.typography.modalHeader.fontSize,
              fontFamily: theme.skillsAndCerts.typography.modalHeader.fontFamily,
              color: theme.skillsAndCerts.palette.modalHeader,
            }}
          >
            New Skill
          </Typography>
          <TextField
            sx={{
              backgroundColor: theme.skillsAndCerts.palette.newTextBgColor,
              borderRadius: '40px',
              display: 'flex',
              padding: '8px 16px',
              margin: theme.spacing(2, 0, 5, 0),
              alignItems: 'flex-start',
            }}
            value={name}
            onChange={handleInputChange}
            placeholder={`Enter New ${type}...`}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              sx: {
                width: '100%',
                '&::placeholder': {
                  fontFamily: theme.skillsAndCerts.typography.placeholder.fontFamily,
                  fontSize: theme.skillsAndCerts.typography.placeholder.fontSize,
                },
              },
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <WaeButton
              text="Go Back"
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={() => handleClose()}
              sx={{ marginRight: theme.spacing(1) }}
            />
            <WaeButton
              text={primaryButtonText}
              onClick={handleDirectAddSkill}
              disabled={!isSkillEntered}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

AddNewSkillOrCertModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  type: PropTypes.string,
};

export default AddNewSkillOrCertModal;
