import { flatten, get } from 'lodash/fp';

import { createSlice } from '@reduxjs/toolkit';

import {
  epochToDateInReadableFormat,
  epochToTimeInReadableFormat,
} from '../../../utils/timeConverter';
import { CARD_MODE, DISPUTE_STATUSES, FIFTEEN_MIN_IN_EPOCH, NEXT_SHIFT_INDEX } from '../constants';
import { getMomentInEpoch, isActive, setPunchErrorMessage } from '../utils';

const setMode = (startTimeInEpoch, endTimeInEpoch, punches) => {
  if (
    startTimeInEpoch - FIFTEEN_MIN_IN_EPOCH < getMomentInEpoch() &&
    endTimeInEpoch + FIFTEEN_MIN_IN_EPOCH > getMomentInEpoch()
  ) {
    return CARD_MODE.active;
  }
  if (punches.length === 0 && endTimeInEpoch + FIFTEEN_MIN_IN_EPOCH < getMomentInEpoch()) {
    return CARD_MODE.missedPunch;
  }

  return CARD_MODE.notActive;
};

const isCompleted = (endTime) => endTime + FIFTEEN_MIN_IN_EPOCH < getMomentInEpoch();

const disablePunch = (nextShifts, index) =>
  nextShifts.length === 2
    ? (index === NEXT_SHIFT_INDEX.firstShift &&
        get(['placementHasPunches'], nextShifts[NEXT_SHIFT_INDEX.secondShift])) ||
      (index === NEXT_SHIFT_INDEX.secondShift &&
        get(['candidateIsPunchedIn'], nextShifts[NEXT_SHIFT_INDEX.firstShift])) ||
      false
    : false;

const initialState = {
  timeCard: [],
  nextShifts: [],
  totalsData: {
    shiftsThisWeek: 0,
    hoursThisWeek: 0,
    earningsThisWeek: 0,
  },
  punchError: null,
  isLoadingTimeCard: false,
  isLoadingNextShift: false,
  isProcessingPunch: false,
  isProcessingDispute: false,
};

export const employeeTimeCardSlice = createSlice({
  name: 'employeeTimeCardSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    getTimeCards: (state) => {
      state.isLoadingTimeCard = true;
    },
    getTimeCardsProcessed: (state, action) => {
      state.isLoadingTimeCard = false;
      const documents = get(['payload', 'data', 'documents'], action);

      if (documents.length > 0) {
        const { days } = documents[0];

        const timeCardData = flatten(
          Object.keys(days).map((day) => {
            if (days[day].length > 0) {
              const updatedData = days[day].map((item) => ({
                key: `${get(['placement'], item)}`,
                placementId: `${get(['placement'], item)}`,
                date: day,
                startTime: epochToTimeInReadableFormat(get(['start'], item)),
                endTime: epochToTimeInReadableFormat(get(['end'], item)),
                totalEarnings: get(['timecard', 'totalEarnings'], item) || 0,
                totalHours: get(['timecard', 'totalHours'], item),
                name: get(['order'], item),
                corporation: get(['corporation'], item),
                punches: get(['timecard', 'punches'], item).map((punch) => ({
                  key: `${get(['in', 'stamp'], punch)}`,
                  hours: get(['hours'], punch),
                  in: {
                    name: 'Clock In',
                    time: epochToTimeInReadableFormat(get(['in', 'stamp'], punch)),
                  },
                  out: get(['out', 'stamp'], punch) && {
                    name: 'Clock Out',
                    time: epochToTimeInReadableFormat(get(['out', 'stamp'], punch)),
                  },
                })),
                mode: setMode(
                  get(['start'], item),
                  get(['end'], item),
                  get(['timecard', 'punches'], item)
                ),
                isCompleted: isCompleted(get(['end'], item)),
                status: get(['timecard', 'status'], item) || DISPUTE_STATUSES.pending,
              }));

              return updatedData;
            }
            return [];
          })
        );

        const totalsData = {
          shiftsThisWeek: timeCardData.length,
          hoursThisWeek: timeCardData.reduce((acc, currVal) => acc + currVal.totalHours, 0),
          earningsThisWeek: timeCardData.reduce((acc, currVal) => acc + currVal.totalEarnings, 0),
        };

        state.timeCard = timeCardData;
        state.totalsData = totalsData;
      } else {
        state.timeCard = [];
        state.totalsData = {
          shiftsThisWeek: 0,
          hoursThisWeek: 0,
          earningsThisWeek: 0,
        };
      }
    },
    getTimeCardsError: (state) => {
      state.isLoadingTimeCard = false;
    },
    getNextShift: (state) => {
      state.isLoadingNextShift = true;
    },
    getNextShiftProcessed: (state, action) => {
      state.isLoadingNextShift = false;

      const nextShifts = get(['payload', 'data', 'documents'], action);

      state.nextShifts = nextShifts.map((shift, index) => ({
        placementId: get(['placement', '_id'], shift),
        disablePunch: disablePunch(nextShifts, index),
        candidateIsPunchedIn: get(['candidateIsPunchedIn'], shift),
        isActive: isActive(
          get(['placement', 'jobOrder', 'start'], shift),
          get(['placement', 'jobOrder', 'end'], shift)
        ),
        placementHasPunches: get(['placementHasPunches'], shift),
        corporationName: get(['corporation', 'name'], shift),
        endTime: epochToTimeInReadableFormat(get(['placement', 'jobOrder', 'end'], shift)),
        shiftName: get(['placement', 'jobOrder', 'name'], shift),
        startDate: epochToDateInReadableFormat(get(['placement', 'jobOrder', 'start'], shift)),
        startTime: epochToTimeInReadableFormat(get(['placement', 'jobOrder', 'start'], shift)),
        gpsStrategy: get(['placement', 'jobOrder', 'gps', 'strategy'], shift),
      }));
    },

    getNextShiftError: (state) => {
      state.isLoadingNextShift = false;
    },
    postPunch: (state) => {
      state.isProcessingPunch = true;
    },
    postPunchProcessed: (state, action) => {
      const punchedTimecard = action?.payload?.data?.placement?.timecard;
      const punchedTimecardId = action?.payload?.data?.placement?._id;
      const candidatePunches = punchedTimecard?.current?.punches;
      state.isProcessingPunch = false;
      const isCurrentWeek = !!state.timeCard.find((item) => item.placementId === punchedTimecardId);
      if (isCurrentWeek) {
        const updatedTimeCard = state.timeCard.map((item) =>
          item.placementId === punchedTimecardId
            ? {
                ...item,
                totalEarnings: punchedTimecard?.totalPay || 0,
                totalHours: punchedTimecard?.totalHours || 0,
                punches: candidatePunches.map((punch) => ({
                  key: `${punch?.in?.stamp}`,
                  hours: punch?.hours || 0,
                  in: {
                    name: 'Clock In',
                    time: epochToTimeInReadableFormat(punch?.in?.stamp),
                  },
                  out: punch?.out?.stamp && {
                    name: 'Clock Out',
                    time: epochToTimeInReadableFormat(punch?.out?.stamp),
                  },
                })),
              }
            : item
        );
        state.timeCard = updatedTimeCard;

        const updatedTotalsData = {
          shiftsThisWeek: state.timeCard.length,
          hoursThisWeek: state.timeCard.reduce((acc, currVal) => acc + currVal.totalHours, 0),
          earningsThisWeek: state.timeCard.reduce((acc, currVal) => acc + currVal.totalEarnings, 0),
        };
        state.totalsData = updatedTotalsData;
      }
      const nextShifts = state.nextShifts.map((item) =>
        item.placementId === punchedTimecardId
          ? {
              ...item,
              placementHasPunches: true,
              candidateIsPunchedIn: !candidatePunches?.[candidatePunches.length - 1]?.out?.stamp,
            }
          : item
      );

      const updatedNextShift = nextShifts.map((item, index) => ({
        ...item,
        disablePunch: disablePunch(nextShifts, index),
      }));

      state.nextShifts = updatedNextShift;
      state.punchError = null;
    },
    postPunchError: (state, action) => {
      state.isProcessingPunch = false;
      state.punchError = setPunchErrorMessage(action?.payload);
    },
    setPunchError: (state, action) => {
      state.punchError = action.payload || null;
    },
    postDisputeTimeCard: (state) => {
      state.isProcessingDispute = true;
    },
    postDisputeTimeCardProcessed: (state, action) => {
      state.isProcessingDispute = false;
      const placement = get(['payload', 'data', 'placement'], action);
      const updatePlacement = state.timeCard.map((item) =>
        item.placementId === placement ? { ...item, status: DISPUTE_STATUSES.disputed } : item
      );
      state.timeCard = updatePlacement;
    },
    postDisputeTimeCardError: (state) => {
      state.isProcessingDispute = false;
    },

    /* eslint-disable no-param-reassign */
  },
});

export const {
  getTimeCards,
  getTimeCardsProcessed,
  getTimeCardsError,
  getNextShift,
  getNextShiftProcessed,
  getNextShiftError,
  postPunch,
  postPunchProcessed,
  postPunchError,
  setPunchError,
  postDisputeTimeCard,
  postDisputeTimeCardProcessed,
  postDisputeTimeCardError,
} = employeeTimeCardSlice.actions;

export const employeeTimeCardReducer = employeeTimeCardSlice.reducer;
