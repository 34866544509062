import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Circle, GoogleMap, InfoWindowF, Marker, useJsApiLoader } from '@react-google-maps/api';

import pin from '../../../../assets/icons/MapPin.svg';
import { defaultCenter, defaultZoomLevel } from '../constants';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const GpsMap = ({ markers = [] }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const coordinates = {
    lat: [],
    lng: [],
  };

  markers.map((l) => {
    if (l?.checked) {
      coordinates.lat.push(Number(l.lat));
      coordinates.lng.push(Number(l.lng));
    }
    return null;
  });

  const averageLng = (Math.max(...coordinates.lng) + Math.min(...coordinates.lng)) / 2;
  const averageLat = (Math.max(...coordinates.lat) + Math.min(...coordinates.lat)) / 2;

  const center = averageLat ? { lat: averageLat, lng: averageLng } : defaultCenter;

  const onLoad = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((location) => {
        bounds.extend({ lat: Number(location.lat), lng: Number(location.lng) });
      });
      if (markers.length !== 0) {
        map.fitBounds(bounds);
      }
    },
    [markers]
  );

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center || defaultCenter}
      zoom={defaultZoomLevel}
      onLoad={onLoad}
      data-testid="google-map"
    >
      {markers.map(
        (marker) =>
          marker?.checked && (
            <React.Fragment key={marker?._id}>
              <Marker
                icon={marker?.icon || pin}
                position={{ lat: marker?.lat, lng: marker?.lng }}
                onClick={() => handleActiveMarker(marker?._id)}
                onUnmount={() => setActiveMarker(null)}
              >
                {marker?._id === activeMarker ? (
                  <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                    <div>{marker?.name}</div>
                  </InfoWindowF>
                ) : null}
              </Marker>
              {marker?.radius && (
                <Circle
                  center={{ lat: marker?.lat, lng: marker?.lng }}
                  radius={marker?.radius} // Meters TODO: verify if meters is correct for both sides
                  options={{
                    strokeColor: marker?.strokeColor || 'transparent',
                    fillColor: marker?.fillColor || 'blue',
                    fillOpacity: marker?.fillOpacity || 0.35,
                  }}
                />
              )}
            </React.Fragment>
          )
      )}
    </GoogleMap>
  ) : (
    <>CONTENT</>
  );
};

GpsMap.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      radius: PropTypes.number,
    })
  ),
};

export default GpsMap;
