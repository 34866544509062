import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Attendance from '@careerstart/wae-common/src/main/constants/attendance';
import DNRReasons from '@careerstart/wae-common/src/main/constants/DNR-reasons';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import Button, { BUTTON_VARIANT } from '../../../../../components/Button';
import AlertBanner from '../../../../../components/Candidate/AlertBanner';
import selectUser from '../../../../../store/selectors/appSelector';
import theme from '../../../../../theme';
import LinearExpandMore from '../../../../corporations/corporationList/detail/icons/LinearExpandMore';

const headerText = 'Manage User'; // TODO support multiple language
const dnrDescription =
  'Applying a DNR for the employee means they will not be considered for future job opportunities';
const dnrReason = 'DNR Reason';
const favoriteAndDNRAlert = "A user can't be both a favorite and  DNR at the same time";

const heartStyle = { color: `${theme.components.detailView.palette.lightHeartColor}` };

const userNameStyle = {
  color: theme.components.detailView.palette.employeesNameColor,
  fontSize: `${theme.components.detailView.typography.manageUserName.fontSize}`,
  fontFamily: `${theme.components.detailView.typography.manageUserName.fontFamily}`,
  margin: theme.spacing(0, 0, 0, 1),
};

const sectionHeaderStyle = {
  fontColor: get(['components', 'detailView', 'palette', ' defaultTextColor'], theme),
  fontSize: get(['components', 'detailView', 'typography', 'sectionHeader', 'fontSize'], theme),
  fontFamily: get(['components', 'detailView', 'typography', 'sectionHeader', 'fontFamily'], theme),
  margin: theme.spacing(4, 0, 2, 0),
};

const dropdownBackgroundColor = get(
  ['components', 'selectDropdown', 'palette', 'grey', 'backgroundColor'],
  theme
);

const styleForSelect = {
  backgroundColor: dropdownBackgroundColor,
  borderRadius: '30px',
  minWidth: '130px',
  width: '80%',
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};
const alertBannerTheme = get(['candidate', 'components', 'alertBanner'], theme);

const dnrTextStyle = {
  fontColor: get(['components', 'detailView', 'palette', '  dnrTextColor'], theme),
  fontSize: get(['components', 'detailView', 'typography', 'general', 'fontSize'], theme),
  fontFamily: get(['components', 'detailView', 'typography', 'general', 'fontFamily'], theme),
  margin: theme.spacing(-1, 0, 2, 0),
};

const RootBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(2, 8),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  gap: '20px',
}));

const HeaderBoxTypography = styled(Box)(() => ({
  fontSize: `${theme.components.workflow.typography.defaultTitleFontSize}`,
  fontFamily: `${theme.components.workflow.typography.boldFont}`,
  color: `${theme.components.workflow.palette.font.default.transitionPageFontColor}`,
}));

const isDNR = (dnr) =>
  Object.keys(dnr).length !== 0 && dnr.label !== DNRReasons.I_Dont_Want_To_DNR_This_Employee;
const ManageUserDrawer = ({
  isOpen,
  onClose,
  candidateName,
  originalEmployerNote,
  handleOnSave,
  placementId,
  fav,
  isRecruiterBlocked,
}) => {
  const user = useSelector(selectUser);
  const [dnr, setDnr] = useState({});
  const [favorite, setFavorite] = useState(fav || false);
  const [employerNote, setEmployerNote] = useState(originalEmployerNote || '');
  const employerNoteEditable = user.role === UserRole.EMPLOYER;
  const isEmployer = user.role === UserRole.EMPLOYER;
  const isAdmin = user.role === UserRole.ADMIN;

  const attendanceOptions = [];

  Object.keys(Attendance).forEach((key) => {
    attendanceOptions.push({ id: key, label: Attendance[key] });
  });

  const dnrOptions = [];

  Object.keys(DNRReasons).forEach((key) => {
    dnrOptions.push({ id: key, label: DNRReasons[key] });
  });

  const handleUpdateRoster = () => {
    if (!isDNR(dnr) || !favorite) {
      const baseReturn = {
        id: placementId,
        DNR: isDNR(dnr) ? { reason: get(get('id', dnr), DNRReasons) } : { remove: true },
        fields: {},
        favorite,
      };

      handleOnSave(
        ((isEmployer || isAdmin) && {
          ...baseReturn,
          fields: { ...baseReturn.fields, employerNote },
        }) ||
          baseReturn
      );
    }
  };

  const handleFavoriteClick = () => {
    (user.role !== UserRole.RECRUITER || !isRecruiterBlocked) && setFavorite(!favorite);
  };

  const handleDNRChange = (e, value) => {
    setDnr({ id: value.id, label: value.label });
  };

  const handleEmployerNoteChange = (event) => {
    setEmployerNote(event.target.value);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} PaperProps={{ sx: { width: '576px' } }}>
      <RootBox>
        <Box>
          <HeaderBoxTypography>{`${headerText}`}</HeaderBoxTypography>
          <Box>
            {isDNR(dnr) && favorite && (
              <AlertBanner
                LeftIcon={WarningAmberIcon}
                message={favoriteAndDNRAlert}
                backgroundColor={get(['backgroundColor'], alertBannerTheme)}
                messageSx={{
                  color: get(['fontColor'], alertBannerTheme),
                  fontFamily: get(['font'], alertBannerTheme),
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(2.5, 0, 0, 0),
            }}
          >
            <Box
              onClick={handleFavoriteClick}
              sx={{
                display: 'flex',
              }}
            >
              {favorite ? <FavoriteIcon sx={heartStyle} /> : <FavoriteBorderIcon sx={heartStyle} />}
            </Box>
            <Typography sx={userNameStyle}>{candidateName}</Typography>
          </Box>
          <Box>
            <Typography sx={sectionHeaderStyle}> Employer Note</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={employerNote}
              onChange={handleEmployerNoteChange}
              multiline
              placeholder="Enter text here..."
              rows={3}
              InputProps={{
                readOnly: !employerNoteEditable,
              }}
              sx={{
                backgroundColor: get(['select', 'main'], theme),
                borderRadius: '40px',
                '& .MuiInputBase-root': {
                  color: `${theme.palette.secondary.light}`,
                },
                '& .MuiOutlinedInput-root': {
                  backgroundColor: `${theme.textField.background.grey}`,
                  borderRadius: '15px',
                  fontFamily: 'Barlow-600',
                  fontSize: '16px',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            />
          </Box>
          <Box>
            <Typography sx={sectionHeaderStyle}> DNR</Typography>
            <Typography sx={dnrTextStyle}>{dnrDescription}</Typography>
            <Typography sx={dnrTextStyle}>{dnrReason}</Typography>
            <Autocomplete
              isOptionEqualToValue={(option, val) => {
                if (!val.id) return true;
                return option.id === val.id;
              }}
              disableClearable
              size="small"
              id="Job-Roster-DNR"
              options={dnrOptions}
              sx={styleForSelect}
              value={dnr}
              popupIcon={<LinearExpandMore />}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              PaperComponent={({ children }) => (
                <Paper
                  sx={{
                    backgroundColor: dropdownBackgroundColor,
                    fontFamily: get(
                      ['components', 'selectDropdown', 'typography', 'fontFamily'],
                      theme
                    ),
                    fontSize: get(['components', 'selectDropdown', 'typography', 'fonSize'], theme),
                  }}
                >
                  {children}
                </Paper>
              )}
              onChange={handleDNRChange}
              renderInput={(inputParams) => <TextField {...inputParams} />}
              readOnly={user.role === UserRole.RECRUITER && isRecruiterBlocked}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignSelf: 'flex-end',
            marginTop: '50px',
          }}
        >
          <Box>
            <Button
              onClick={onClose}
              variant={BUTTON_VARIANT.OUTLINED}
              text={LanguageConverter('button.goBack')}
            />
          </Box>

          {(user.role !== UserRole.RECRUITER || !isRecruiterBlocked) && (
            <Box>
              {' '}
              <Button
                disabled={isDNR(dnr) && favorite}
                onClick={handleUpdateRoster}
                sx={{ marginLeft: '5px' }}
                text={LanguageConverter('button.employee.update')}
              />
            </Box>
          )}
        </Box>
      </RootBox>
    </Drawer>
  );
};

ManageUserDrawer.propTypes = {
  selectedPlacementId: PropTypes.string,
  candidateName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  totalShifts: PropTypes.string,
  lastShiftDate: PropTypes.string,
  accordionType: PropTypes.string,
  empId: PropTypes.string,
  handleOnSave: PropTypes.func,
  placementId: PropTypes.string,
  mode: PropTypes.string,
  canceled: PropTypes.bool,
  fav: PropTypes.bool,
  name: PropTypes.string,
  originalRating: PropTypes.number,
  reason: PropTypes.string,
  originalDnrBl: PropTypes.bool,
  originalEmployerNote: PropTypes.string,
  currUserRole: PropTypes.string,
  isRecruiterBlocked: PropTypes.bool,
};

export default ManageUserDrawer;
