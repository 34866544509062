import get from 'lodash/fp/get';

import theme from '../../../theme/theme';

export const PT_STATUSES = {
  APPROVED: 'approved',
  NOT_APPROVED: 'not_approved',
};

export const handleLabelProps = (params) => {
  const MESSAGES = {
    APPROVED: 'PT.status.approved',
    NOT_APPROVED: 'PT.status.notApproved',
  };

  const status = params ? PT_STATUSES.APPROVED : PT_STATUSES.NOT_APPROVED;
  let backgroundColor;
  let color;
  let text;
  switch (status) {
    case PT_STATUSES.APPROVED:
      backgroundColor = get(['label', 'positionTemplate', 'bgColor', 'approved'], theme);
      color = get(['label', 'positionTemplate', 'color', 'approved'], theme);
      text = MESSAGES.APPROVED;
      break;
    case PT_STATUSES.NOT_APPROVED:
      backgroundColor = get(['label', 'positionTemplate', 'bgColor', 'not_approved'], theme);
      color = get(['label', 'positionTemplate', 'color', 'not_approved'], theme);
      text = MESSAGES.NOT_APPROVED;
      break;
    default:
      throw new Error('Valid status option required');
  }

  return {
    text,
    sx: {
      minWidth: '100%',
      margin: theme.spacing(1),
      backgroundColor,
      color,
    },
    sxForText: {
      fontFamily: get(['label', 'positionTemplate', 'font'], theme),
    },
  };
};
