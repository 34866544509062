import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import theme from '../../../theme';

const AlertBanner = (props) => {
  const { LeftIcon, message, messageSx, backgroundColor, CloseIcon } = props;

  const [isOpen, setIsOpen] = useState(true);

  const sxForCard = {
    backgroundColor,
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    width: '100%',
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Collapse in={isOpen} sx={{ width: '100%', margin: theme.spacing(1, 0) }}>
      <Box sx={sxForCard}>
        <Box sx={{ '&:first-of-type': { display: 'flex' } }}>
          <LeftIcon />
        </Box>
        <Box>
          <Typography sx={{ ...messageSx, margin: theme.spacing(0, 1, 0, 1) }}>
            {message}
          </Typography>
        </Box>

        {CloseIcon && (
          <Box
            sx={{
              marginLeft: 'auto',
              '&:first-of-type': { display: 'flex' },
            }}
            onClick={() => close()}
          >
            <CloseIcon sx={{ display: 'flex' }} />
          </Box>
        )}
      </Box>
    </Collapse>
  );
};
AlertBanner.propTypes = {
  LeftIcon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  message: PropTypes.string,
  messageSx: PropTypes.shape({}),
  backgroundColor: PropTypes.string,
  CloseIcon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
};

export default AlertBanner;
