import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, InputAdornment, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import Search from '../../assets/icons/SearchPrimaryColor.svg';
import theme from '../../theme';
import { PRIMARY_FONT } from '../../theme/fontConstants';

const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const FreeTextSearchFilter = ({
  disabled,
  placeholder,
  onValueChange,
  field,
  operation,
  wrapperSx,
  initialValue,
}) => {
  const [searchTerm, setSearchTerm] = useState(initialValue?.value || '');
  const [open, setOpen] = useState(false);
  const [hasHadValue, setHasHadValue] = useState(false);

  const styleForFormControl = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '& .MuiAutocomplete-input': {
      color: theme?.button?.palette?.primary,
      fontFamily: PRIMARY_FONT['400'],
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme?.button?.palette?.primary,
      fontFamily: PRIMARY_FONT['400'],
      opacity: 1,
    },

    border: `1px solid ${theme?.searchBoxForFilter?.borderColor?.focus}`,
    borderRadius: '5px',
    justifyContent: 'center',
    height: '28px',
    width: '200px',
  };

  useEffect(() => {
    // TODO: this component is calling onValueChange func twice on every search, most probably because of this useEffect
    const delayedSearch = setTimeout(() => {
      if (searchTerm || hasHadValue) {
        const newVal = [
          {
            value: searchTerm || '',
            field,
            operation,
          },
        ];
        onValueChange(newVal);

        if (!hasHadValue) {
          setHasHadValue(true);
        }
      }
    }, 750);
    return () => clearTimeout(delayedSearch);
  }, [searchTerm, operation, field, onValueChange, hasHadValue]);

  return (
    <FormControl sx={{ ...styleForFormControl, ...wrapperSx }}>
      <Autocomplete
        disabled={disabled}
        freeSolo
        inputValue={searchTerm}
        disableClearable
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
            }}
            placement="auto-start"
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 16,
                    }}
                    alt="Search"
                    src={Search}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {searchTerm ? (
                    <ClearIcon
                      onClick={() => setSearchTerm('')}
                      style={{ cursor: 'pointer', width: '12px', marginLeft: '8px' }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        )}
        isOptionEqualToValue={(option, val) => option._id === val._id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={[]}
      />
    </FormControl>
  );
};

FreeTextSearchFilter.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  field: PropTypes.string,
  operation: PropTypes.string,
  wrapperSx: PropTypes.shape({}),
  initialValue: PropTypes.shape({ value: PropTypes.string }),
};

export { FreeTextSearchFilter, SEARCHBAR_BACKGROUND };
