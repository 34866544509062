import React, { useCallback, useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { map } from 'lodash/fp';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { batch, useDispatch, useSelector } from 'react-redux';

import adminUpdateUserRequest from '@careerstart/wae-common/schema/users/patch/admin.req.json';
import userReadSchema from '@careerstart/wae-common/schema/users/read/post/req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import WaePaginatedDataGrid from '../../../components/DataGrid';
import DetailView from '../../../components/DetailView/DetailView2';
import FreeSearchFilter from '../../../components/Filters/FreeSearchFilter';
import SelectDropdownFilter from '../../../components/Filters/SelectDropdownFilter';
import { validateSchema } from '../../../components/Form/validations';
import ListHeader from '../../../components/ListHeader';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectCertifications,
  selectDNAData,
  selectDNRData,
  selectedUpcomingCount,
  selectIsLoadingRecruiterCorporations,
  selectIsLoadingUsers,
  selectIsLoadingUserUpcomingJobs,
  selectIsProcessingDeactivateUser,
  selectIsUpdateProcessing,
  selectSkills,
  selectTotalRowCount,
  selectUsers,
} from '../../../store/selectors/userSelectors';
import theme, { dataGridFiltersHeight, listHeaderHeight, topBarHeight } from '../../../theme';
import launchDarklyToggles from '../../../utils/launchDarklyToggles';
import {
  getCertificationsForUsers,
  getCorporations,
  getUserHistory,
  getUsers,
  updateCandidateDetails,
  updateUser,
} from '../reducer';

import UserListDrawer from './UserListDrawer/UserListDrawer';
import {
  CertSkills,
  JobActivity,
  Overview,
  RecruiterCorporationTabForAdmins,
  RecruiterCorporationTabForRecruiters,
} from './detail';
import userListColumnData from './userListColumData';

const RootGrid = styled(Grid)(() => ({
  height: `calc(100vh - ${topBarHeight})`,
  padding: theme.spacing(2),
}));

const UserList = ({ flags }) => {
  const dispatch = useDispatch();

  const muiTheme = useTheme();
  const certifications = useSelector(selectCertifications);
  const skills = useSelector(selectSkills);
  const isLoadingUsers = useSelector(selectIsLoadingUsers);
  const isLoadingRecruiterCorporations = useSelector(selectIsLoadingRecruiterCorporations);
  const IsLoadingSelectUserUpcomingJobs = useSelector(selectIsLoadingUserUpcomingJobs);
  const isUpdateProcessing = useSelector(selectIsUpdateProcessing);
  const users = useSelector(selectUsers);
  const loggedInUser = useSelector(selectUser);
  const DNR = useSelector(selectDNRData);
  const DNA = useSelector(selectDNAData);
  const isProcessingDeactivateUser = useSelector(selectIsProcessingDeactivateUser);

  const mediumScreen = useMediaQuery(muiTheme.breakpoints.up('md'));
  const [selectedUser, setSelectedUser] = useState(null);
  const totalRowCount = useSelector(selectTotalRowCount);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(10);
  const apiPath = 'users/read';
  const [addUserAnchorEl, setAddUserAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isMenuOpen = Boolean(addUserAnchorEl);
  const count = useSelector(selectedUpcomingCount);
  const candidateCreationToggle = launchDarklyToggles(
    flags,
    'isCandidateCreationViaWorkflowEnabled'
  );

  const employerCreationToggle = launchDarklyToggles(flags, 'isEmployerCreationViaWorkflowEnabled');

  const usersListPaginationQuery = React.useCallback(
    (params) => {
      if (Object.keys(validateSchema(params, userReadSchema)).length === 0) {
        dispatch(getUsers(params));
      }
    },
    [dispatch]
  );

  const handleUserUpdate = useCallback(
    (formData) => {
      const fields = {
        email: get(['email'], formData),
        recruiter: {
          corporations: get(['recruiterCorporations'], formData),
        },
      };

      const phoneNumber = get(['phoneNumber'], formData)?.replace(/\D/g, '') || undefined;

      if (phoneNumber) {
        fields.candidate = { phoneNumber };
      }

      const data = { id: selectedUser.id, fields };
      if (Object.keys(validateSchema(data, adminUpdateUserRequest)).length === 0) {
        dispatch(updateUser(data));
      }
    },
    [dispatch, selectedUser]
  );

  const onSelectionModelChange = (selectedUserObject) => {
    setSelectedUser(selectedUserObject || null);

    const userHistoryFilter = {
      page: 0,
      limit: 3,
      users: [get('id', selectedUserObject)],
    };

    dispatch(getUserHistory(userHistoryFilter));
  };

  const handleAddUserMenuOpen = (event) => {
    setAddUserAnchorEl(event.currentTarget);
  };
  const handleAddUserMenuClose = () => {
    setAddUserAnchorEl(null);
  };

  const handleUserAndSkillsUpdate = useCallback(
    (formData, initialValues) => {
      const industry = get('industry', formData);
      const candidatePreferenceData = {
        id: get('id', initialValues),
        fields: {
          candidate: {
            industry,
            distance: formData.distance.value,
            certifications: map((cert) => cert._id, get('certifications', formData)),
            skills: map((skill) => skill._id, get('skills', formData)),
          },
        },
      };

      dispatch(updateCandidateDetails(candidatePreferenceData));
    },
    [dispatch]
  );

  const addUserMenu = (
    <Menu
      anchorEl={addUserAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleAddUserMenuClose}
    >
      <MenuItem
        onClick={() => {
          setIsDrawerOpen('bullhorn-import');
          setAddUserAnchorEl(false);
        }}
      >
        <Typography>Onboard from Bullhorn</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setIsDrawerOpen(UserRole.RECRUITER);
          setAddUserAnchorEl(false);
        }}
      >
        <Typography>Onboard Recruiter</Typography>
      </MenuItem>
      {employerCreationToggle && (
        <MenuItem
          onClick={() => {
            setIsDrawerOpen(UserRole.EMPLOYER);
            setAddUserAnchorEl(false);
          }}
        >
          <Typography>Onboard Employer</Typography>
        </MenuItem>
      )}
      {candidateCreationToggle && (
        <MenuItem
          onClick={() => {
            setIsDrawerOpen(UserRole.CANDIDATE);
            setAddUserAnchorEl(false);
          }}
        >
          <Typography>Onboard Candidate</Typography>
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          setIsDrawerOpen(UserRole.ADMIN);
          setAddUserAnchorEl(false);
        }}
      >
        <Typography>Onboard Admin</Typography>
      </MenuItem>
    </Menu>
  );
  useEffect(() => {
    setSelectedUser(null);
  }, [users]);

  useEffect(() => {
    batch(() => {
      dispatch(getCorporations());
      dispatch(getCertificationsForUsers());
    });
  }, [dispatch]);

  useEffect(() => {
    batch(() => {
      dispatch(getCorporations());
      dispatch(getCertificationsForUsers());
    });
  }, [dispatch]);

  const tabData = () =>
    [
      {
        tabContent: (
          <Overview
            isLoading={IsLoadingSelectUserUpcomingJobs}
            initialValues={selectedUser}
            onUpdate={handleUserUpdate}
            count={count}
            DNR={DNR}
            DNA={DNA}
          />
        ),
        tabLabel: 'Overview',
      },
      get('role', selectedUser) === UserRole.CANDIDATE && {
        tabContent: <JobActivity initialValues={selectedUser} onUpdate={handleUserUpdate} />,
        tabLabel: 'Job Activity',
      },
      get('role', selectedUser) === UserRole.CANDIDATE && {
        tabContent: (
          <CertSkills
            initialValues={selectedUser}
            onUpdate={handleUserAndSkillsUpdate}
            certifications={certifications}
            skills={skills || []}
          />
        ),
        tabLabel: 'Certs&Skills',
      },
      get('role', selectedUser) === UserRole.RECRUITER &&
        get('role', loggedInUser) === UserRole.ADMIN && {
          tabContent: (
            <RecruiterCorporationTabForAdmins
              initialValues={selectedUser}
              onUpdate={handleUserUpdate}
            />
          ),
          tabLabel: 'Corporations',
        },
      get('role', selectedUser) === UserRole.RECRUITER &&
        get('role', loggedInUser) === UserRole.RECRUITER && {
          tabContent: <RecruiterCorporationTabForRecruiters initialValues={selectedUser} />,
          tabLabel: 'Corporations',
        },
    ].filter(Boolean);

  const additiveFilters = [
    {
      customFilter: {
        field: 'role',
        operation: 'equals',
        getOptionLabel: (option) => get(['name'], option),
        options: [
          { name: 'admin', value: 'admin' },
          { name: 'recruiter', value: 'recruiter' },
          { name: 'candidate', value: 'candidate' },
          { name: 'employer', value: 'employer' },
        ],
        placeholder: 'Role',
        type: SelectDropdownFilter,
      },
    },
    {
      customFilter: {
        field: 'name',
        operation: 'icontains',
        placeholder: 'Name',
        type: FreeSearchFilter,
      },
    },
  ];

  return (
    <RootGrid container>
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && selectedUser)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight})`,
            marginRight: selectedUser && 2,
          }}
        >
          {get('role', loggedInUser) === UserRole.ADMIN ? (
            <ListHeader
              headerText="Users"
              buttonText="New User"
              handleOnClick={handleAddUserMenuOpen}
            >
              {addUserMenu}
            </ListHeader>
          ) : (
            <ListHeader headerText="Users" />
          )}
          <Grid
            container
            sx={{
              height: `calc(100% - ${listHeaderHeight})`,
              width: '100%',
            }}
          >
            {users && (
              <>
                <WaePaginatedDataGrid
                  additiveFilters={additiveFilters}
                  apiPath={apiPath}
                  columnData={userListColumnData}
                  disableMultipleSelection
                  disableVirtualization
                  loading={
                    isLoadingUsers ||
                    isLoadingRecruiterCorporations ||
                    IsLoadingSelectUserUpcomingJobs
                  }
                  onSelectionModelChange={onSelectionModelChange}
                  pageSize={pageSize}
                  page={page}
                  pagination
                  paginatedData={users.map((user) => ({
                    ...user,
                    userInfo: {
                      name: get('name', user),
                      role: get('role', user),
                    },
                  }))}
                  paginationQuery={usersListPaginationQuery}
                  setPageSize={setPageSize}
                  setPage={setPage}
                  setRowCount={setRowCount}
                  rowCount={rowCount}
                  totalRowCount={totalRowCount}
                  sx={{
                    border: 'none',
                    height: '100%',
                    width: '100%',
                  }}
                />
                {selectedUser && (
                  <DetailView
                    close={() => setSelectedUser(null)}
                    tabData={tabData}
                    onUpdate={handleUserUpdate}
                    sx={{ marginLeft: theme.spacing(1.5) }}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Slide>

      {!mediumScreen && selectedUser && (
        <DetailView
          close={() => setSelectedUser(null)}
          tabData={tabData}
          onUpdate={handleUserUpdate}
        />
      )}

      <UserListDrawer
        anchor="right"
        isOpen={!!isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        userCreateStrategy={isDrawerOpen}
      />
      {(isUpdateProcessing || isProcessingDeactivateUser) && <BackdropCircularProgress />}
    </RootGrid>
  );
};

UserList.propTypes = {
  count: PropTypes.shape({}),
  flags: PropTypes.shape({}),
  jobActivities: PropTypes.shape({}),
  userUpcomingJobs: PropTypes.shape({}),
  userCompletedJobs: PropTypes.shape({}),
};

export default withLDConsumer()(UserList);
