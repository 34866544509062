import { createTheme } from '@mui/material/styles';

// import { ACTION_BLUE, BLACK, FEED_BACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';
import {
  ACTION_BLUE,
  BLACK,
  FEED_BACK,
  PRIMARY_COLOR,
  PRIMARY_PURPLE,
} from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';

const candidateTheme = createTheme({
  components: {
    alertBanner: {
      backgroundColor: FEED_BACK.YELLOW_50,
      font: PRIMARY_FONT[500],
      fontColor: BLACK[100],
    },
    infoBanner: {
      backgroundColor: PRIMARY_COLOR[40],
      font: PRIMARY_FONT[500],
      fontColor: BLACK[100],
    },
    shiftGroupCard: {
      backgroundColor: {
        selected: BLACK[90],
        default: BLACK[10],
      },
      headers: {
        font: PRIMARY_FONT[600],
        fontColor: BLACK[100],
        selectedFontColor: BLACK[0],
        contrastColor: PRIMARY_COLOR[70],
        selectedContrastColor: PRIMARY_COLOR[40],
      },
      subtext: {
        font: PRIMARY_FONT[500],
        fontColor: BLACK[70],
        selectedFontColor: BLACK[30],
      },
      timeConflictWarningTooltip: {
        bgColor: BLACK[80],
      },
    },
    jobCard: {
      backgroundColor: {
        default: BLACK[10],
      },
      headers: {
        font: PRIMARY_FONT[600],
        fontColor: BLACK[100],
      },
      subtext: {
        font: PRIMARY_FONT[500],
        fontColor: BLACK[70],
      },
      pill: {
        backgroundColor: {
          default: BLACK[30],
        },
        typography: {
          color: BLACK[100],
          font: PRIMARY_FONT[600],
        },
      },
    },
    dashboard: {
      headers: {
        font: PRIMARY_FONT[800],
        fontColor: BLACK[80],
        fontSize: '38px',
        timeFontSize: '16px',
        timeFontWeight: PRIMARY_FONT[600],
      },
      subHeaders: {
        font: PRIMARY_FONT[700],
        fontColor: BLACK[70],
      },
      time: {
        totals: {
          backgroundColor: {
            default: PRIMARY_COLOR[10],
          },
          headerColor: PRIMARY_COLOR[70],
          headerFont: PRIMARY_FONT[700],
          subtextFont: PRIMARY_FONT[500],
          subtextColor: BLACK[100],
        },

        dayCard: {
          date: {
            font: PRIMARY_FONT[500],
          },
          earnings: {
            font: PRIMARY_FONT[700],
          },

          hasEarnings: {
            fontColor: BLACK[100],
            backgroundColor: BLACK[20],
          },
          noEarnings: {
            fontColor: BLACK[60],
            backgroundColor: BLACK[10],
          },
        },
      },
      alertBanner: {
        backgroundColor: FEED_BACK.YELLOW_50,
        font: PRIMARY_FONT[500],
        fontColor: BLACK[100],
      },
      emptyJob: {
        font: PRIMARY_FONT[500],
        fontColor: BLACK[70],
      },
    },
    nav: {
      footer: {
        backgroundColor: PRIMARY_COLOR[80],
        inactive: PRIMARY_COLOR[40],
        active: BLACK[0],
        constrastColor: PRIMARY_COLOR[70],
      },
      header: {
        backgroundColor: BLACK[0],
        inactive: BLACK[60],
        active: BLACK[100],
        iconColor: PRIMARY_COLOR[80],
        dashboard: {
          font: PRIMARY_FONT[700],
          fontSize: '18px',
        },
      },
    },
    jobInvitation: {
      typography: {
        title: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '30px',
          fontWeight: 800,
          color: BLACK[80],
        },
        description: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 400,
          color: BLACK[80],
        },
        jobName: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '30px',
          fontWeight: 800,
          color: PRIMARY_PURPLE[70],
        },
        corpName: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 500,
          color: BLACK[80],
        },
        subTitle: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 500,
          color: BLACK[80],
        },
        subContent: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '22px',
          fontWeight: 700,
          color: BLACK[100],
        },
        shiftChecklist: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '18px',
          fontWeight: 700,
          color: BLACK[100],
        },
        shiftChecklistDesc: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 400,
          color: BLACK[80],
        },
        skills: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 400,
          color: BLACK[80],
        },
      },
      palette: {
        bodyBgColor: PRIMARY_PURPLE[10],
      },
    },
    timeCards: {
      typography: {
        shiftDate: { fontFamily: PRIMARY_FONT[400], fontSize: '16px', fontWeight: 500 },
        shiftTimeAndEarnings: { fontFamily: PRIMARY_FONT[400], fontSize: '18px', fontWeight: 700 },
        positionName: { fontFamily: PRIMARY_FONT[400], fontSize: '18px', fontWeight: 600 },
        companyName: { fontFamily: PRIMARY_FONT[400], fontSize: '16px', fontWeight: 500 },
        punches: { fontFamily: PRIMARY_FONT[400], fontSize: '16px', fontWeight: 500 },
        disputeButtonText: { textTransform: 'none' },
        noPunchMessageText: { fontFamily: PRIMARY_FONT[400], fontSize: '22px', fontWeight: 700 },
        totalsContent: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '22px',
          fontWeight: 700,
          color: BLACK[100],
        },
        totalsLabel: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '14px',
          fontWeight: 500,
          color: BLACK[70],
        },
        nextShiftTitle: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          fontWeight: 500,
          color: PRIMARY_PURPLE[100],
        },
        nextShiftDateTime: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '24px',
          fontWeight: 800,
          color: PRIMARY_PURPLE[70],
          lineHeight: '28px',
        },
        nextShiftNameCorp: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '14px',
          fontWeight: 500,
          color: BLACK[80],
        },
        weeksFilter: {
          fontFamily: 'Barlow-500',
          fontSize: '16px',
          fontWeight: 500,
          color: BLACK[100],
        },
        disputeWarningText: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '14px',
          fontWeight: 400,
          color: BLACK[100],
        },
        disputeWarningStatusText: {
          fontFamily: 'Barlow-600',
          fontSize: '16px',
          fontWeight: 600,
          color: BLACK[100],
        },
        clockInOut: {
          header: {
            hasPunches: {
              fontFamily: PRIMARY_FONT[400],
              fontSize: '22px',
              fontWeight: 700,
              color: PRIMARY_PURPLE[70],
            },
            noPunches: {
              fontFamily: PRIMARY_FONT[400],
              fontSize: '22px',
              fontWeight: 700,
              color: BLACK[100],
            },
          },
          details: {
            fontFamily: PRIMARY_FONT[400],
            fontSize: '14px',
            fontWeight: 500,
            color: BLACK[100],
          },
          shiftName: {
            fontFamily: PRIMARY_FONT[400],
            fontSize: '14px',
            fontWeight: 700,
            color: BLACK[100],
          },
        },
        punchAlertDrawerHeader: {
          fontFamily: 'Barlow-800',
          fontSize: '30px',
          color: BLACK[100],
        },
        punchAlertDrawerDescription: {
          fontFamily: PRIMARY_FONT[400],
          fontSize: '16px',
          color: BLACK[80],
        },
      },
      card: { borderRadius: '16px' },
      disputeButton: { borderRadius: '40px' },
      palette: {
        backgroundColor: {
          active: BLACK[100],
          notActive: BLACK[10],
          missedPunch: `${FEED_BACK.RED_10}`,
        },
        shiftDateTextClr: {
          active: BLACK[0],
          notActive: BLACK[100],
          missedPunch: BLACK[100],
        },
        shiftTimeAndEarningsTextClr: {
          active: BLACK[0],
          notActive: PRIMARY_PURPLE[70],
          missedPunch: BLACK[100],
        },
        positionNameClr: {
          active: BLACK[0],
          notActive: BLACK[100],
          missedPunch: BLACK[100],
        },
        companyNameClr: {
          active: BLACK[40],
          notActive: BLACK[100],
          missedPunch: BLACK[100],
        },
        punchesClr: {
          active: PRIMARY_PURPLE[40],
          notActive: BLACK[80],
          missedPunch: BLACK[100],
        },
        disputeBtnTextClr: {
          active: BLACK[0],
          notActive: `${ACTION_BLUE.ACTION_BLUE}`,
          missedPunch: BLACK[0],
        },
        disputeBtnClr: {
          active: BLACK[0],
          notActive: BLACK[0],
          missedPunch: `${ACTION_BLUE.ACTION_BLUE}`,
        },
        noPunchMessageClr: {
          active: BLACK[0],
          notActive: BLACK[100],
          missedPunch: BLACK[100],
        },
        totalsCard: {
          backgroundColor: BLACK[10],
        },
        nextShiftCard: {
          backgroundColor: BLACK[10],
        },
        clockInOutBackgroundClr: {
          clockIn: FEED_BACK.ORANGE,
          clockOut: PRIMARY_PURPLE[10],
        },
        disputeWarningCardBgClr: {
          backgroundColor: PRIMARY_PURPLE[30],
        },
      },
    },
    groupDetails: {
      header: {
        fontSize: '30px',
        fontColor: BLACK[0],
        font: PRIMARY_FONT[800],
      },
      address: {
        fontSize: '14px',
        fontColor: `${ACTION_BLUE.ACTION_CONTRAST_BLUE}`,
        font: PRIMARY_FONT[700],
      },
      values: {
        fontSize: '20px',
        fontColor: BLACK[0],
        font: PRIMARY_FONT[700],
      },
      valueLabels: {
        fontSize: '14px',
        fontColor: PRIMARY_COLOR[40],
        font: PRIMARY_FONT[500],
      },
      signUpText: {
        fontSize: '16px',
        fontColor: BLACK[0],
        font: PRIMARY_FONT[700],
      },
      description: {
        fontSize: '16px',
        fontColor: BLACK[20],
        font: PRIMARY_FONT[400],
      },
      certifictions: {
        hasCert: {
          backgroundColor: FEED_BACK.GREEN_10,
          color: FEED_BACK.GREEN_100,
        },
        notCert: {
          backgroundColor: FEED_BACK.RED_10,
          color: FEED_BACK.RED_100,
        },
      },
      availableShiftCard: {
        backgroundColor: BLACK[100],
        iconColor: `${ACTION_BLUE.ACTION_CONTRAST_BLUE}`,
        closeIconColor: BLACK[0],
        iconSize: '35px',
        primaryText: {
          color: PRIMARY_COLOR[40],
          font: PRIMARY_FONT[700],
          fontSize: '18px',
        },
        secondaryText: {
          color: BLACK[20],
          font: PRIMARY_FONT[500],
          fontSize: '14px',
        },
      },
      signUpDrawer: {
        header: {
          fontSize: '30px',
          fontColor: BLACK[100],
          font: PRIMARY_FONT[800],
        },
        subHeader: {
          fontSize: '16px',
          fontColor: BLACK[80],
          font: PRIMARY_FONT[500],
        },
        shiftsCard: {
          header: {
            fontSize: '30px',
            fontColor: PRIMARY_COLOR[70],
            font: PRIMARY_FONT[800],
          },
          label: {
            fontSize: '16px',
            fontColor: BLACK[80],
            font: PRIMARY_FONT[500],
          },
          value: {
            fontSize: '22px',
            fontColor: BLACK[100],
            font: PRIMARY_FONT[700],
          },
          backgroundColor: PRIMARY_COLOR[20],
          alert: {
            fontSize: '16px',
            fontColor: BLACK[70],
            font: PRIMARY_FONT[500],
          },
        },
        form: {
          header: {
            fontSize: '18px',
            fontColor: BLACK[100],
            font: PRIMARY_FONT[700],
          },
          subHeader: {
            fontSize: '16px',
            fontColor: BLACK[80],
            font: PRIMARY_FONT[400],
          },
          checkboxColor: ACTION_BLUE.ACTION_CONTRAST_BLUE,
          labels: {
            fontSize: '16px',
            fontColor: BLACK[100],
            font: PRIMARY_FONT[500],
          },
        },
      },
    },
    notReadyToWorkPage: {
      palette: {
        textPaperBgColor: BLACK[20],
      },
      typography: {
        headerText: {
          fontSize: '24px',
          fontColor: BLACK[100],
          font: PRIMARY_FONT[800],
          fontFamily: 'Barlow-800',
        },
        subHeaderText: {
          fontSize: '18px',
          fontColor: BLACK[70],
          font: PRIMARY_FONT[700],
          fontFamily: 'Barlow-700',
        },
        body: {
          fontSize: '16px',
          fontColor: BLACK[50],
          font: PRIMARY_FONT[500],
          fontFamily: 'Barlow-500',
        },
      },
    },
    readyToWorkUnConfirmModal: {
      typography: {
        body: {
          color: BLACK[70],
        },
      },
    },
  },
});

export default candidateTheme;
