import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const LocationIcon = () => (
  <SvgIcon>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6666 23C13.6666 23 21.6666 16 21.6666 10C21.6666 5.02944 17.6372 1 12.6666 1C7.69606 1 3.66663 5.02944 3.66663 10C3.66663 16 11.6666 23 12.6666 23ZM12.6666 14C14.8758 14 16.6666 12.2091 16.6666 10C16.6666 7.79086 14.8758 6 12.6666 6C10.4575 6 8.66663 7.79086 8.66663 10C8.66663 12.2091 10.4575 14 12.6666 14Z"
        fill="#000033"
      />
    </svg>
  </SvgIcon>
);

export default LocationIcon;
