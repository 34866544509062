import React from 'react';
import PropTypes from 'prop-types';

import { Box, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../../theme';

const JobActivityCard = ({ item }) => {
  const { name, corporation, jobDate, jobShift } = item;

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        backgroundColor: theme.components.detailView.palette.sectionBackgroundColor,
        color: theme.components.detailView.typography.detailText.fontColor,
        marginBottom: '6px',
      }}
    >
      <Typography
        sx={{
          fontSize: theme.components.detailView.typography.semiBoldText.fontSize,
          margin: '3px',
          color: theme.components.detailView.palette.sectionTextColor,
          fontFamily: theme.components.detailView.typography.semiBoldText.fontFamily,
        }}
        gutterBottom
      >
        {name}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '3px' }}>
        <Typography
          sx={{
            fontFamily: theme.components.detailView.typography.detailText.fontFamily,
            fontSize: theme.components.detailView.typography.detailText.fontSize,
          }}
        >
          {corporation}
        </Typography>
        <Typography
          sx={{
            fontFamily: theme.components.detailView.typography.sectionTextColor,
            fontSize: theme.components.detailView.typography.detailText.fontSize,
          }}
        >
          {jobDate}
        </Typography>
        <Typography
          sx={{
            fontFamily: theme.components.detailView.typography.sectionTextColor,
            fontSize: theme.components.detailView.typography.detailText.fontSize,
          }}
        >
          {jobShift}
        </Typography>
      </Box>
    </Paper>
  );
};

JobActivityCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    corporation: PropTypes.string,
    jobDate: PropTypes.string,
    jobShift: PropTypes.string,
  }),
};

export default JobActivityCard;
