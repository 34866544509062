import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import firstTraining from '../../../../assets/firstTraining.svg';
import TrainingTextDescription from '../trainingComponents/TrainingTextDescription';

const AutomatedAttendanceEnterpriseTraining = () => [
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.enterprise.automatedAttendance.title'),
      text: LanguageConverter('training.enterprise.automatedAttendance.content'),
    },
    ContentType: TrainingTextDescription,
  },
];

export default AutomatedAttendanceEnterpriseTraining;
