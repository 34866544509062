import * as React from 'react';
import PropTypes from 'prop-types';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BackdropCircularProgress = ({ backdropSx, circularProgressSx }) => (
  <Backdrop sx={{ zIndex: (theme) => theme.zIndex.modal + 1, ...backdropSx }} open>
    <CircularProgress sx={circularProgressSx} />
  </Backdrop>
);

BackdropCircularProgress.propTypes = {
  backdropSx: PropTypes.shape({}),
  circularProgressSx: PropTypes.shape({}),
};

export default BackdropCircularProgress;
