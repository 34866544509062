import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { BLACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';

const ExpandableCard = ({ faqItem = {} }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Grid item width="100%">
      <Grid
        container
        direction="column"
        sx={{ backgroundColor: BLACK[10], padding: theme.spacing(2), borderRadius: '3px' }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Grid item>
              {isExpanded ? (
                <ArrowDropUpIcon
                  sx={{ color: PRIMARY_PURPLE[60], height: '24px', lineHeight: 0 }}
                />
              ) : (
                <ArrowDropDownIcon
                  sx={{ color: PRIMARY_PURPLE[60], height: '24px', lineHeight: 0 }}
                />
              )}
            </Grid>
            <Grid item xs>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: PRIMARY_FONT[500],
                  paddingLeft: '8px',
                  lineHeight: '22px',
                }}
              >
                {faqItem?.header}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {isExpanded &&
          faqItem?.bodyElements?.map((body) => (
            <Grid key={body} item sx={{ padding: theme.spacing(2, 0, 0, 0) }}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: PRIMARY_FONT[400],
                  letterSpacing: '0.02em',
                  lineHeight: '22px',
                }}
              >
                {body}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

ExpandableCard.propTypes = {
  faqItem: PropTypes.shape({
    header: PropTypes.string,
    bodyElements: PropTypes.arrayOf(PropTypes.string),
  }),
};
export default ExpandableCard;
