import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../../../components/SearchBar';

// eslint-disable-next-line no-unused-vars
const FormFieldDataCorporation = (availableCorporations) => ({
  corporations: {
    ComponentType: SearchBox,
    background: SEARCHBAR_BACKGROUND.DARK,
    preDefinedOptions: [],
    data: null,
    key: 'id',
    name: 'corporations',
    multiSelect: true,
    required: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Enter Corporation',
    fullWidth: true,
    onSubmitApiCallData: {
      httpMethod: 'POST',
      route: 'corporations/read',
    },
    optionDisplayField: 'name',
  },
});
export default FormFieldDataCorporation;
