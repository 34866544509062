import React from 'react';

import {
  WAE_SUPPORTED_FILTER_FIELD_TYPES,
  WAE_SUPPORTED_STRING_FILTER_OPERATORS,
} from '@careerstart/wae-common/src/main/constants/filterQueries';
import Box from '@mui/material/Box';

import Label from '../../../components/Label';
import theme from '../../../theme';
import { handleLabelProps } from '../helpers';

const status = {
  field: 'status',
  headerClassName: 'theme-header',
  headerName: 'Status',
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.STRING,
  minWidth: 120,
  isExportable: true,
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
  align: 'center',
  headerAlign: 'center',
  renderCell: (params) => <Label {...handleLabelProps(params.value)} />,
};

const name = {
  field: 'userInfo',
  cellClassName: 'info-cell',
  headerClassName: 'theme-header',
  headerName: 'Name',
  flex: 1,
  renderCell: (params) =>
    params.value ? (
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            color: `${theme.dataGrid.userInfo.nameColor}`,
            fontFamily: `${theme.dataGrid.userInfo.fontFamily}`,
          }}
        >
          {params.value.name}
        </Box>
        <Box
          sx={{
            color: `${theme.dataGrid.userInfo.roleColor}`,
          }}
        >
          {params.value.role}
        </Box>
      </Box>
    ) : (
      ''
    ),
  type: WAE_SUPPORTED_FILTER_FIELD_TYPES.STRING,
  filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
  headerAlign: 'left',
  align: 'left',
};

const externalId = {
  field: 'externalId',
  headerClassName: 'theme-header',
  headerName: 'External ID',
  valueFormatter: ({ value }) => value || 'N/A',
  flex: 1,
};

const email = {
  field: 'email',
  headerClassName: 'theme-header',
  headerName: 'Email',
  flex: 1,
};

const userListColumnData = [status, name, externalId, email];

export default userListColumnData;
