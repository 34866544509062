import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Paper as MuiPaper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import theme from '../../../theme/theme';

const borderRadius = theme.spacing(1);

const StyledPaper = styled(MuiPaper)(() => ({
  height: '100%',
  borderRadius,
  backgroundColor: get(
    ['views', 'reporting', 'palette', 'paper', 'backgroundColor', 'default'],
    theme
  ),
}));

const ChartCard = ({ children, loading, sx }) => (
  <Grid item xs>
    <StyledPaper sx={sx}>
      {loading ? (
        <Skeleton height="inherit" variant="rectangular" sx={{ borderRadius }} />
      ) : (
        <Grid container height="inherit" justifyContent="center" alignItems="center">
          {children}
        </Grid>
      )}
    </StyledPaper>
  </Grid>
);

ChartCard.propTypes = {
  children: PropTypes.element,
  loading: PropTypes.bool,
  sx: PropTypes.shape({}),
};

export default ChartCard;
