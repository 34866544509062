import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SHIFT_TIME_ALERT_TYPES } from '../../constDicts';

const ShiftTimeAlert = ({ alertType }) => {
  const theme = useTheme();

  const boldWeight = theme?.jobOrders?.createPage?.overtimeAlert?.boldWeight;
  const iconColor = theme?.jobOrders?.createPage?.overtimeAlert?.iconColor;

  const alertTypeText =
    alertType === SHIFT_TIME_ALERT_TYPES.OT
      ? LanguageConverter('job.create.ot.alert')
      : LanguageConverter('job.create.max.alert');

  const alertBodyText =
    alertType === SHIFT_TIME_ALERT_TYPES.OT
      ? LanguageConverter('job.create.ot.body')
      : LanguageConverter('job.create.max.body');

  return (
    <Box sx={{ display: 'flex', gap: theme.spacing(0.5) }}>
      <ErrorOutlineOutlinedIcon sx={{ color: iconColor }} />
      <Typography
        sx={{
          color: theme?.jobOrders?.createPage?.overtimeAlert?.fontColor,
          fontSize: theme?.jobOrders?.createPage?.overtimeAlert?.fontSize,
          fontFamily: theme?.jobOrders?.createPage?.overtimeAlert?.font,
        }}
      >
        <span style={{ fontWeight: boldWeight }}>{alertTypeText}</span>
        {alertBodyText}
      </Typography>
    </Box>
  );
};

ShiftTimeAlert.propTypes = {
  alertType: PropTypes.string,
};

export default ShiftTimeAlert;
