import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import theme from '../../../../../theme';

const styleForCard = {
  backgroundColor: `${theme.palette.neutral.contrastText}`,
  borderRadius: '16px',
  padding: theme.spacing(1, 0, 1, 0),
  justifyContent: 'space-between',
  margin: 0,
  boxShadow: 0,
  width: '100%',
};

const styleForName = {
  color: `${theme.components.detailView.palette.employeesNameColor}`,
  fontFamily: `${theme.components.detailView.typography.cancelPlacement.fontFamily}`,
  fontSize: `${theme.components.detailView.typography.cancelPlacement.fontFamily}`,
};

const DeletedPlacementCard = ({ name }) => (
  <Box sx={styleForCard} square>
    <Box sx={{ padding: theme.spacing(0, 2) }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={styleForName}>{name}</Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);

DeletedPlacementCard.propTypes = {
  actionButton: PropTypes.shape({}),
  goBackButton: PropTypes.shape({}),
  headerIcon: PropTypes.shape({}),
  name: PropTypes.string,
  content: PropTypes.shape({}),
};

export default DeletedPlacementCard;
