import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectSettings = (state) => get('settings', state);

export const selectCertifications = createSelector(
  selectSettings,
  (settings) => settings.certifications
);
export const selectIsLoadingCertifications = createSelector(
  selectSettings,
  (settings) => settings.isLoadingCertifications
);

export const selectSkills = createSelector(selectSettings, (settings) => settings.userSkills);

export const selectErrors = createSelector(selectSettings, (settings) => settings.errors);
