import React, { useCallback, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import CheckboxChecked from '../../../assets/icons/CheckboxChecked.svg';
import CheckboxUnchecked from '../../../assets/icons/CheckboxUnchecked.svg';
import theme from '../../../theme/theme';
import { updateTraining } from '../../../views/app';
import { WaeButton } from '../../Button/Button';
import { TRAINING_NAME } from '../constants';

import { termsAndConditionsText } from './subComponents/TermsAndConditionsText';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '80vh',
  maxWidth: '600px',
  minHeight: '600px',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  p: 4,
  pr: 2,
};

const TermsAndConditions = ({ user }) => {
  const themePath = ['components', 'termsAndConditions'];
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleOnAccept = useCallback(() => {
    const userID = get(['role'], user) === UserRole.ADMIN ? { userID: get(['_id'], user) } : {};

    dispatch(
      updateTraining({
        ...userID,
        trainingName: TRAINING_NAME.TERMS_AND_CONDITIONS,
      })
    );
  }, [dispatch, user]);

  const scrollBarStyles = {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.components.scrollBar.trackColor,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.components.scrollBar.thumbColor,
      borderRadius: '50px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.components.scrollBar.thumbHover,
    },
  };

  return (
    <Modal open aria-label="termsAndConditionsModal" aria-describedby="modal-modal-description">
      <Grid sx={style}>
        <Grid
          sx={{
            overflow: 'auto',
            maxHeight: '500px',
            paddingRight: '16px',
            ...scrollBarStyles,
          }}
        >
          <Typography sx={{ mt: 2, ...get([...themePath, 'typography', 'description'], theme) }}>
            {termsAndConditionsText}
          </Typography>

          <FormGroup onChange={handleChange} sx={{ mt: 3 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={
                    <Box
                      component="img"
                      sx={{
                        height: 24,
                        width: 24,
                      }}
                      alt="Stars"
                      src={CheckboxChecked}
                    />
                  }
                  icon={
                    <Box
                      component="img"
                      sx={{
                        height: 24,
                        width: 24,
                      }}
                      alt="Stars"
                      src={CheckboxUnchecked}
                    />
                  }
                  sx={{ pt: '4px', ml: '4px' }}
                />
              }
              name="termsAndConditionsAcceptCheckbox"
              label={LanguageConverter('generic.agree.terms.and.conditions')}
              sx={get([...themePath, 'typography', 'checkboxLabel'], theme)}
            />
          </FormGroup>
        </Grid>
        <Grid container justifyContent="right" alignItems="center" paddingTop={4}>
          <WaeButton
            disabled={!checked}
            text={LanguageConverter('buttonText.accept')}
            onClick={handleOnAccept}
            sx={{ ml: '16px', mr: '20px' }}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

TermsAndConditions.propTypes = {
  user: PropTypes.shape({}),
};

export default TermsAndConditions;
