import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../components/SearchBar';

const CertificationsSkillsSettingsData = {
  certSetting: {
    ComponentType: SearchBox,
    background: SEARCHBAR_BACKGROUND.LIGHT,
    onSubmitApiCallData: {
      httpMethod: 'GET',
      route: 'certifications',
    },
    description: `
        Search for additional certifications or add certifications by clicking on the chips below.
      `,
    key: 'certifications',
    name: 'certifications',
    multiSelect: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Search certifications...',
    fullWidth: true,
    optionDisplayField: 'name',
  },
  skillsSetting: {
    ComponentType: SearchBox,
    background: SEARCHBAR_BACKGROUND.LIGHT,
    onSubmitApiCallData: {
      httpMethod: 'POST',
      route: 'skills/read',
    },
    description: `
            Search for additional skills or add additional skills by clicking on the chips below.
          `,
    key: '_id',
    name: 'skills',
    multiSelect: true,
    required: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Search skills...',
    fullWidth: true,
    optionDisplayField: 'name',
    data: null,
    sx: { justifyContent: 'center' },
  },
};

export default CertificationsSkillsSettingsData;
