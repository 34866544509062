import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import firstTraining from '../../../../assets/firstTraining.svg';
import TrainingTextDescription from '../trainingComponents/TrainingTextDescription';

const FirstTimeEmployeeTraining = () => [
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.employee.firstTraining.welcome.title'),
      text: LanguageConverter('training.employee.firstTraining.welcome.content'),
    },
    ContentType: TrainingTextDescription, // The type of the react component
  },
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.employee.firstTraining.viewJob.title'),
      text: LanguageConverter('training.employee.firstTraining.viewJob.content'),
    },
    ContentType: TrainingTextDescription, // The type of the react component
  },
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.employee.firstTraining.cancelShift.title'),
      text: LanguageConverter('training.employee.firstTraining.cancelShift.content'),
    },
    ContentType: TrainingTextDescription,
  },
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.employee.firstTraining.punch.title'),
      text: LanguageConverter('training.employee.firstTraining.punch.content'),
    },
    ContentType: TrainingTextDescription,
  },
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.firstTraining.support.title'),
      text: LanguageConverter('training.firstTraining.support.content'),
    },
    ContentType: TrainingTextDescription,
  },
];

export default FirstTimeEmployeeTraining;
