import React, { useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import WaeButton, { BUTTON_VARIANT } from '../../../../../components/Button';
import ModalComponent from '../../../../../components/ModalComponent/ModalComponent';
import { selectSendVerificationEmailButtonDisabled } from '../../../../../store/selectors/accountVerificationSelector';
import { reqVerifyAccount } from '../../../../PasswordVerificationOrReset/reducer';

/**
 * SendAccountVerificationEmailBtn is a functional component that displays a
 * button to allow users to resend an account verification email
 * and a modal to confirm the action.
 *
 * @param {Object}   props
 * @param {string}   props.userID       - The user's ID
 * @param {boolean}  props.isDisabled   - If true, the button appears disabled
 */
const SendAccountVerificationEmailBtn = ({ userID, isDisabled, userEmail }) => {
  // State for handling modal opening
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Redux-related hooks
  const dispatch = useDispatch();
  const sendVerificationEmailButtonDisabled = useSelector(
    selectSendVerificationEmailButtonDisabled
  );

  // Callback for handling email verification submit
  const handleEmailVerifySubmit = useCallback(
    (userid) => {
      const verifyAccountBody = { userID: userid };
      dispatch(reqVerifyAccount(verifyAccountBody));
    },
    [dispatch]
  );

  // Callback for opening the modal
  const handleOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <div>
      {/* Button which opens modal on click */}
      <WaeButton
        disabled={sendVerificationEmailButtonDisabled || isDisabled}
        text="Resend Verification Email"
        variant={BUTTON_VARIANT.OUTLINED}
        onClick={handleOpen}
      />

      {/* Modal is displayed only if isModalOpen is true */}
      {isModalOpen && (
        <ModalComponent
          isOpen={isModalOpen}
          handleOnClose={() => setIsModalOpen(false)}
          titleText="Resend Notification Email"
          bodyText={`Send a verification email to ${userEmail} ?`}
          subText={
            " If they don't receive the email, you can conveniently request another one in just 5 minutes."
          }
          submitText="Send"
          cancelText="Cancel"
          onSubmit={() => {
            handleEmailVerifySubmit(userID);
            setIsModalOpen(false);
          }}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

// PropTypes checking
SendAccountVerificationEmailBtn.propTypes = {
  userID: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  userEmail: PropTypes.string.isRequired,
};

export default SendAccountVerificationEmailBtn;
