import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PRIMARY_FONT } from '../../../../theme/fontConstants';
import { epochToDateInReadableFormat } from '../../../../utils';

const useStyles = makeStyles({
  fillRatioWrapper: { width: '250px', display: 'flex', alignItems: 'center' },
  fillRatioInner: { minWidth: 35 },
  fillRatioText: {
    fontFamily: PRIMARY_FONT[300],
    fontSize: '18px',
    lineHeight: '16px',
    textAlign: 'left',
    marginRight: '5px',
  },
  fillRatioProgressWrap: { width: '100%' },

  jobInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& .block': {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
  },
  cancelledBlock: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    gap: '4px',
  },
  blockLabel: {
    color: 'gray',
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '8px',
  },
  cancelledByData: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'left',
  },
});

const CancelledJobMetrics = ({ jobOrder }) => {
  const classes = useStyles();

  return (
    <div className={classes.jobInfo}>
      <Box className="block">
        <Box className={classes.blockLabel}> {LanguageConverter('roster.form.openings')} </Box>
        <Box> {jobOrder.numOpenings} </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box className={classes.cancelledBlock}>
          <Typography className={classes.blockLabel}>
            {LanguageConverter('roster.form.shiftCancelledOn')}
          </Typography>
          <Typography className={classes.cancelledByData}>
            {epochToDateInReadableFormat(jobOrder?.cancellation?.time)}
          </Typography>
        </Box>
        <Box className={classes.cancelledBlock}>
          <Typography className={classes.blockLabel}>
            {LanguageConverter('roster.form.shiftCancelledBy')}
          </Typography>
          <Typography className={classes.cancelledByData}>
            {jobOrder?.cancellation?.user?.name}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

CancelledJobMetrics.propTypes = {
  jobOrder: PropTypes.shape({
    fillRatio: PropTypes.number,
    numOpenings: PropTypes.number,
    placementsCount: PropTypes.number,
    placements: PropTypes.arrayOf(PropTypes.shape({})),
    cancellation: PropTypes.shape({
      time: PropTypes.number,
      user: PropTypes.shape({ name: PropTypes.string }),
    }),
  }),
};

export default CancelledJobMetrics;
