import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import theme from '../../theme';

const Label = (props) => {
  const { sx, sxForText, collapsed, Icon, text } = props;
  const intl = useIntl();
  const labelSx = {
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    width: '100px',
    ...sx,
  };

  const wrapperSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  if (collapsed) {
    labelSx.height = '32px';
    labelSx.width = '32px';
  }

  return (
    <Box sx={wrapperSx}>
      {(collapsed && (
        <Box
          component="img"
          alt="CircularStatus"
          src={Icon}
          sx={{ width: '28px', height: '28px' }}
        />
      )) || (
        <Box sx={labelSx}>
          <Typography sx={sxForText}>{intl.formatMessage({ id: text })}</Typography>
        </Box>
      )}
    </Box>
  );
};

Label.propTypes = {
  sx: PropTypes.shape({}),
  sxForText: PropTypes.shape({}),
  collapsed: PropTypes.bool,
  Icon: PropTypes.string,
  text: PropTypes.string,
};

export default Label;
