import React from 'react';

import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

import RadioButtons from '../../../../../assets/icons/RadioButtons.svg';
import SelectedRadioButtons from '../../../../../assets/icons/SelectedRadioButtons.svg';

const BlueBackgroundRadio = (props) => {
  const BpIcon = styled('span')(() => ({
    borderRadius: '50%',
    width: 24,
    height: 24,
    backgroundImage: `url(${RadioButtons})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    'input:hover ~ &': {
      backgroundImage: `url(${SelectedRadioButtons})`,
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundImage: `url(${SelectedRadioButtons})`,
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};
export default BlueBackgroundRadio;
