import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from './axios';
import { logOutOn401, updateUserToken } from './helpers';

const getAxiosQuery = (http) => {
  switch (http.toUpperCase()) {
    case 'GET':
      return apiGet;
    case 'PUT':
      return apiPut;
    case 'POST':
      return apiPost;

    case 'PATCH':
      return apiPatch;

    case 'DELETE':
      return apiDelete;

    default:
      throw Error(`API Request Type '${http}' is invalid or not implemented yet.`);
  }
};

const asyncService = ({
  httpMethod,
  route,
  data,
  token,
  onSuccess,
  onError,
  dispatch,
  refreshToken,
}) => {
  const axiosQuery = getAxiosQuery(httpMethod);

  const apiQuery = (accessToken) => axiosQuery(route, data, accessToken);

  return apiQuery(token)
    .then((webResponse) => onSuccess(webResponse))
    .catch((err) => {
      const code = err?.response?.status;

      if (code !== 401) {
        onError(err);
        return;
      }
      apiPost('users/refresh-token', { refreshToken })
        .then((refreshResponse) => {
          const newToken = refreshResponse.data.accessToken;
          updateUserToken(dispatch, newToken);

          apiQuery(newToken)
            .then((repeatWebResponse) => onSuccess(repeatWebResponse))
            .catch((repeatErr) => {
              onError(repeatErr);
            });

          return newToken;
        })
        .catch(() => {
          logOutOn401(dispatch);
        });
    });
};

export default asyncService;
