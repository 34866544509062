import FILTERS from './filterConstants';

export const JOB_ORDER_STATUSES = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  COMPLETE: 'completed',
  OPEN: 'open',
};

export const JOB_ORDER_TIME_STATUSES = {
  UPCOMING: 'upcoming',
  ACTIVE: 'active',
  COMPLETED: 'completed',
};

export const SORT_VALUES = {
  ASC: 'asc',
  DESC: 'desc',
};

export const INITIAL_FILTERS = {
  filters: FILTERS.UPCOMING_JOBS,
  rightFilters: [],
  pageSize: 10,
  page: 0,
  sort: [{ field: 'start', sort: SORT_VALUES.ASC }],
};

export const TIMEKEEPING_STATUSES = {
  APPROVED: 'approved',
  CONFLICT: 'conflict',
  PENDING: 'pending',
  DISPUTED: 'disputed',
  RESOLVED: 'resolved',
  FINALIZED: 'finalized',
  OVERTIME: 'overtime',
};

export const STATUS_UPDATE_ACTION = {
  APPROVE: 'approve',
  RESOLVE: 'resolve',
  FINALIZE: 'finalize',
  UNFINALIZE: 'unfinalize',
};

export const ACT_BTN_TEXT = {
  APPROVE: 'Approve',
  RESOLVE: 'Resolve',
  FINALIZE: 'Finalize',
  UNFINALIZE: 'Send for Review',
};

export const FIFTEEN_MINS_IN_EPOCH = 900000;

export const FIVE_AM_IN_MIN = 300;
export const ONE_THIRTY_PM_IN_MIN = 810;
export const NINE_THIRTY_PM_IN_MIN = 1290;
