import { get } from 'lodash/fp';

import FormRadioChips from '../../../../../components/Form/FormRadioChips';
import FormSelectDropdownField from '../../../../../components/Form/FormSelectDropdownField';
import FormTypographyBox from '../../../../../components/Form/FormTypographyBox';
import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../../../components/SearchBar';
import theme from '../../../../../theme';

const componentsDetailViewSectionHeader = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];

const detailViewTypographyCertText = ['components', 'detailView', 'typography', 'certText'];
export const isRadioChipEmpty = (value) =>
  value && value.length > 0 ? undefined : 'One industry must be selected before you can update.';

const predefinedCertsOptions = (certifications) =>
  certifications.map((cert) => ({
    name: cert.name,
    _id: cert.id,
  }));

const FormFieldDataCertSkillsForEmployee = (certifications) =>
  [
    {
      ComponentType: FormTypographyBox,
      key: 'industryPreferenceHeader',
      name: 'industryPreferenceHeader',
      sx: {
        fontSize: get([...componentsDetailViewSectionHeader, 'fontSize'], theme),
        color: get([...componentsDetailViewSectionHeader, 'fontColor'], theme),
        fontFamily: get([...componentsDetailViewSectionHeader, 'fontFamily'], theme),
      },
    },
    {
      ComponentType: FormRadioChips,
      name: 'industry',
      multiSelect: true,
      data: ['healthcare', 'manufacturing', 'hospitality'],
      validate: isRadioChipEmpty,
      required: true,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'JobDistanceHeader',
      name: 'JobDistanceHeader',
      sx: {
        fontSize: get([...componentsDetailViewSectionHeader, 'fontSize'], theme),
        color: get([...componentsDetailViewSectionHeader, 'fontColor'], theme),
        fontFamily: get([...componentsDetailViewSectionHeader, 'fontFamily'], theme),
      },
    },
    {
      ComponentType: FormSelectDropdownField,
      name: 'distance',
      mode: 'dark',
      readOnlyFormat: (value) => get('name', value),
      sx: { width: '240px', height: '48px' },
      options: [
        { id: '5 Miles', name: '5 Miles', value: 5 },
        { id: '10 Miles', name: '10 Miles', value: 10 },
        { id: '15 Miles', name: '15 Miles', value: 15 },
        { id: '20 Miles', name: '20 Miles', value: 20 },
        { id: '25 Miles', name: '25 Miles', value: 25 },
        { id: '30 Miles', name: '30 Miles', value: 30 },
        { id: '35 Miles', name: '35 Miles', value: 35 },
        { id: '40 Miles', name: '40 Miles', value: 40 },
        { id: '45 Miles', name: '45 Miles', value: 45 },
        { id: '50 Miles', name: '50 Miles', value: 50 },
      ],
      fullWidth: true,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'certificationsHeader',
      name: 'certificationsHeader',
      sx: {
        fontSize: get([...componentsDetailViewSectionHeader, 'fontSize'], theme),
        color: get([...componentsDetailViewSectionHeader, 'fontColor'], theme),
        fontFamily: get([...componentsDetailViewSectionHeader, 'fontFamily'], theme),
      },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'certificationsText',
      name: 'certificationsText',
      sx: {
        fontSize: get([...detailViewTypographyCertText, 'fontSize'], theme),
        color: get([...detailViewTypographyCertText, 'fontColor'], theme),
        fontFamily: get([...detailViewTypographyCertText, 'fontFamily'], theme),
      },
    },

    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.DARK,
      preDefinedOptions: [],
      data: null,
      dropDownOptions: predefinedCertsOptions(certifications),
      key: 'id',
      name: 'certifications',
      multiSelect: true,
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Certifications',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'GET',
        route: 'certifications',
      },
      optionDisplayField: 'name',
    },
    {
      ComponentType: FormTypographyBox,
      key: 'skillsHeader',
      name: 'skillsHeader',
      sx: {
        fontSize: get([...componentsDetailViewSectionHeader, 'fontSize'], theme),
        color: get([...componentsDetailViewSectionHeader, 'fontColor'], theme),
        fontFamily: get([...componentsDetailViewSectionHeader, 'fontFamily'], theme),
      },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'skillsText',
      name: 'skillsText',
      sx: {
        fontSize: get([...detailViewTypographyCertText, 'fontSize'], theme),
        color: get([...detailViewTypographyCertText, 'fontColor'], theme),
        fontFamily: get([...detailViewTypographyCertText, 'fontFamily'], theme),
      },
    },
    {
      ComponentType: SearchBox,

      background: SEARCHBAR_BACKGROUND.DARK,
      preDefinedOptions: [],
      data: null,
      key: 'id',
      name: 'skills',
      multiSelect: true,
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Skills',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'skills/read',
      },
      optionDisplayField: 'name',
    },
  ].filter(Boolean);

export default FormFieldDataCertSkillsForEmployee;
