import findIndex from 'lodash/fp/findIndex';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobsList: [],
  positionTemplateData: [],
  corporations: [],
  selectedCorporation: null,
  confirmationData: null,
  isPostingJobs: false,
  isLoadingPt: false,
  isLoadingCorporations: false,
  validateList: [],
  clearJobsList: false,
  allDataValid: false,
};

export const createJobsSlice = createSlice({
  name: 'createJobsSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setJobCreateInitState: () => initialState,

    resetJobList: (state, action) => {
      state.jobsList = action.payload;
    },

    // payload contains field, id, status
    updateValidateList: (state, action) => {
      const { field, id, status } = action.payload;

      const selectedValidateIndex = findIndex(
        (v) => (v.id ? v.id === id : false),
        state.validateList
      );

      if (selectedValidateIndex >= 0) {
        if (!state.validateList[selectedValidateIndex][field]) {
          state.validateList[selectedValidateIndex][field] = {};
        }
        state.validateList[selectedValidateIndex][field] = { isValid: status };
      } else {
        state.validateList.push({
          id,
          [field]: { isValid: status },
        });
      }
    },
    clearValidateList: (state) => {
      state.validateList = [];
    },
    updateValidation: (state, action) => {
      state.allDataValid = action.payload;
    },
    postJobs: (state) => {
      state.isPostingJobs = true;
    },
    postJobsProcessed: (state) => {
      state.isPostingJobs = false;
      state.clearJobsList = true;
    },

    resetClearJobList: (state) => {
      state.clearJobsList = false;
    },
    postJobsError: (state) => {
      state.isPostingJobs = false;
    },
    getPositionTemplate: (state) => {
      state.isLoadingPt = true;
    },
    getPositionTemplateProcessedForJobs: (state, action) => {
      state.isLoadingPt = false;
      const positionTemplateSelection = (action.payload.data || {}).documents || [];
      state.positionTemplateData = [...positionTemplateSelection];
    },
    getPositionTemplateErrorForJobs: (state) => {
      state.isLoadingPt = false;
    },

    getCorporations: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsProcessed: (state, action) => {
      state.isLoadingCorporations = false;
      const documents = get(['payload', 'data', 'documents'], action);

      state.corporations = map((corp) => ({ ...corp, id: corp._id, label: corp.name }), documents);
    },
    getCorporationsError: (state) => {
      state.isLoadingCorporations = false;
    },
    updateCorporationSelection: (state, action) => {
      state.selectedCorporation = action.payload;
      state.clearJobsList = true;
    },

    /* eslint-disable no-param-reassign */
  },
});

export const createJobsReducer = createJobsSlice.reducer;

export const {
  setJobCreateInitState,
  updateCorporationSelection,
  getCorporations,
  getCorporationsProcessed,
  getCorporationsError,
  getPositionTemplate,
  getPositionTemplateErrorForJobs,
  getPositionTemplateProcessedForJobs,
  postJobs,
  postJobsError,
  postJobsProcessed,
  resetClearJobList,
  resetJobList,
  updateValidateList,
  clearValidateList,
  updateValidation,
} = createJobsSlice.actions;
