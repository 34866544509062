import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import theme from '../../theme';

const CheckBox = ({
  checked,
  disabled,
  error,
  label,
  labelPlacement,
  mode,
  name,
  onChange,
  size,
  required,
  sx,
  variant,
}) => {
  let checkBoxFontSize;
  let checkBoxFontColor;
  let checkBoxFontFamily;
  const MODE_KEYS = {
    DARK: 'dark',
  };
  const componentsCheckBoxTypographyDark = ['components', 'checkbox', 'typography', 'dark'];
  switch (mode) {
    case MODE_KEYS.DARK:
      checkBoxFontSize = get([...componentsCheckBoxTypographyDark, 'fontSize'], theme);
      checkBoxFontColor = get([...componentsCheckBoxTypographyDark, 'fontCOlor'], theme);
      checkBoxFontFamily = get([...componentsCheckBoxTypographyDark, 'fontFamily'], theme);
      break;
    default: // By default, use dark
      checkBoxFontSize = get([...componentsCheckBoxTypographyDark, 'fontSize'], theme);
      checkBoxFontColor = get([...componentsCheckBoxTypographyDark, 'fontColor'], theme);
      checkBoxFontFamily = get([...componentsCheckBoxTypographyDark, 'fontFamily'], theme);

      break;
  }

  const styleForCheckbox = {
    '& .MuiFormControlLabel-label': {
      color: checkBoxFontColor,
      fontSize: checkBoxFontSize,
      fontFamily: checkBoxFontFamily,
    },

    ...sx,
  };
  return (
    <FormControl required={required} error={!!error} disabled={disabled} variant={variant}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            name={name}
            onChange={onChange}
            size={size}

            // defaultChecked commented for future use
          />
        }
        label={label}
        sx={styleForCheckbox}
        labelPlacement={labelPlacement}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  onChange: PropTypes.func,
  mode: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string, // default size is medium
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default CheckBox;
