import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ReactComponent as FlipOverIcon } from '../../../assets/icons/FlipOverIcon.svg';
import theme from '../../../theme';
import { JOB_ORDER_TIME_STATUSES } from '../../../views/jobOrders/jobOrderConstants';
import DraggableHandle from '../../DraggableHandle/DraggableHandle';

const useStyles = makeStyles({
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dragIcon: {
    cursor: 'grab',
  },
  flipIcon: {
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
  },
});

const HeaderBar = ({ onFlipClick, onDrag, isFlipped, timeStatus, isCancelled }) => {
  const classes = useStyles();
  const headerTheme = theme.jobOrders.detail.header.titleText;
  const jobViewTheme = headerTheme.jobView;

  const TitleTypography = () =>
    (isFlipped && (
      <Typography style={headerTheme.rosterView}>
        {LanguageConverter('jobOrder.viewJob')}
      </Typography>
    )) ||
    (isCancelled && (
      <Typography style={jobViewTheme.cancelled} sx={{ paddingRight: '8px' }}>
        {LanguageConverter('jobOrder.shiftCancelled')}
      </Typography>
    )) ||
    (timeStatus === JOB_ORDER_TIME_STATUSES.UPCOMING && (
      <Typography style={jobViewTheme.upcoming}>
        {LanguageConverter('jobOrder.viewRoster')}
      </Typography>
    )) ||
    (timeStatus === JOB_ORDER_TIME_STATUSES.ACTIVE && (
      <Typography style={jobViewTheme.active}>
        {LanguageConverter('jobOrder.startCheckIn')}
      </Typography>
    )) ||
    (timeStatus === JOB_ORDER_TIME_STATUSES.COMPLETED && (
      <Typography style={jobViewTheme.completed}>
        {LanguageConverter('jobOrder.completed')}
      </Typography>
    ));

  return (
    <Box className={classes.headerBar}>
      <DraggableHandle drag={onDrag} />
      <Box className={classes.flipIcon} onClick={isCancelled ? null : onFlipClick}>
        <TitleTypography />
        <Box sx={{ margin: 1 }}>
          {!isCancelled ? <FlipOverIcon width={24} height={24} /> : null}
        </Box>
      </Box>
    </Box>
  );
};

HeaderBar.propTypes = {
  onFlipClick: PropTypes.func,
  onDrag: PropTypes.func,
  isFlipped: PropTypes.bool,
  isCancelled: PropTypes.bool,
  timeStatus: PropTypes.string,
};

export default HeaderBar;
