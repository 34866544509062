import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import filter from 'lodash/fp/filter';
import findIndex from 'lodash/fp/findIndex';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SearchSharp } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import asyncService from '../../../datahub/asyncService';
import selectUser from '../../store/selectors/appSelector';
import theme from '../../theme';
import WaeChip from '../Chip';

const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const SearchBoxV2 = ({
  autoCompleteTestId,
  background,
  data,
  description,
  disabled,
  dropDownOptions,
  input,
  label,
  multiSelect,
  onSubmitApiCallData,
  optionDisplayField,
  placeholder,
  preDefinedOptionText,
  preDefinedOptions,
  selectedSectionTitle,
  sx,
}) => {
  const onChange = get('onChange', input);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);

  const [selectedTerms, setSelectedTerms] = useState(input.value || (multiSelect && []) || null);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const token = get('token', user);
  const refreshToken = get('refreshToken', user);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(open && options.length === 0);

  useEffect(() => {
    setSelectedTerms(input.value || (multiSelect && []) || null);
  }, [input, multiSelect]);

  let searchBackgroundColor;

  let textFieldBackground;
  let textFieldPrimaryLabel;
  let textFieldFocusedBorder;
  let textFieldBackgroundHover;
  let textFieldErrorBorder;
  let iconColor;
  let iconSize;
  let preDefinedOptionsTextFontFamily;
  let preDefinedOptionsTextFontSize;
  let preDefinedOptionsTextFontColor;

  switch (background) {
    case SEARCHBAR_BACKGROUND.DARK:
      searchBackgroundColor = theme.V2.bgColor.darkBkColor;

      textFieldBackground = theme.searchBox.textBox.darkBkColor;
      textFieldBackgroundHover = theme.palette.secondary.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.light;
      iconColor = theme.textField.inputLabel.primary.light;
      iconSize = theme.chip.default.iconSize;
      preDefinedOptionsTextFontFamily = theme.searchBox.font.preDefinedTextFontFamily;
      preDefinedOptionsTextFontSize = theme.searchBox.font.preDefinedTextFontSize;
      preDefinedOptionsTextFontColor = theme.searchBox.font.preDefinedTextFontColor;
      break;
    case SEARCHBAR_BACKGROUND.LIGHT:
      searchBackgroundColor = theme.searchBox.bgColor.lightBkColor;

      textFieldBackground = theme.textField.inputLabel.primary.light;
      textFieldBackgroundHover = theme.textField.background.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.focused;
      iconColor = theme.textField.inputLabel.primary.focused;
      iconSize = theme.chip.default.iconSize;
      preDefinedOptionsTextFontFamily = theme.searchBox.font.preDefinedTextFontFamily;
      preDefinedOptionsTextFontSize = theme.searchBox.font.preDefinedTextFontSize;
      preDefinedOptionsTextFontColor = theme.searchBox.font.preDefinedTextFontColor;
      break;
    default:
      searchBackgroundColor = theme.searchBox.bgColor.lightBkColor;

      textFieldBackground = theme.textfield.inputLabel.primary.light;
      textFieldBackgroundHover = theme.textField.background.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.focused;
      iconColor = theme.textField.inputLabel.primary.focused;
      iconSize = theme.chip.default.iconSize;
      preDefinedOptionsTextFontFamily = theme.searchBox.font.preDefinedTextFontFamily;
      preDefinedOptionsTextFontSize = theme.searchBox.font.preDefinedTextFontSize;
      preDefinedOptionsTextFontColor = theme.searchBox.font.preDefinedTextFontColor;
      break;
  }

  const searchBoxFont = ['searchBox', 'font'];
  const sectionHeaderStyle = {
    fontColor: get([...searchBoxFont, 'sectionHeaderFontColor'], theme),
    fontSize: get([...searchBoxFont, 'sectionHeaderFontSize'], theme),
    fontFamily: get([...searchBoxFont, 'sectionHeaderFontFamily'], theme),
    margin: theme.spacing(3, 0, 2, 0),
  };

  const styleForTextField = {
    ...sx,
    '& .MuiOutlinedInput-root': {
      height: '40px',
      background: textFieldBackground,
      borderRadius: '40px',
      padding: theme.spacing(0, 1),
      fontFamily: 'Barlow',
      '&:hover': {
        background: textFieldBackgroundHover,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      border: `2px solid ${textFieldFocusedBorder}`,
      background: textFieldBackground,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      border: `2px solid ${textFieldErrorBorder}`,
    },
    '& .MuiInputLabel-root': {
      top: '-8px',
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 7, 1, 0),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root.Mui-focused': {
      fontWeight: 500,
    },
    paddingBottom: '16px',
  };

  const styleForGrid = {
    backgroundColor: searchBackgroundColor,
    borderRadius: '16px',
  };

  const styleForIcon = {
    color: iconColor,
  };

  const styleForDropDownPaper = {
    padding: theme.spacing(2),
    backgroundColor: searchBackgroundColor,
  };

  const styleForPreDefinedOptions = {
    justifyContent: 'flex-start',

    display: 'flex',

    flexDirection: 'row',

    paddingBottom: '16px',
  };

  const styleForTypography = {
    fontSize: '16px',
    fontFamily: 'Barlow',
    color: '#333357',
    paddingBottom: '16px',
  };

  const styleForHeader = {
    fontColor: get([...searchBoxFont, 'sectionHeaderFontColor'], theme),
    fontSize: get([...searchBoxFont, 'sectionHeaderFontSize'], theme),
    fontFamily: get([...searchBoxFont, 'sectionHeaderFontFamily'], theme),
    margin: theme.spacing(0, 0, 1, 0),
  };

  const styleForPreDefinedOptionsText = {
    fontFamily: preDefinedOptionsTextFontFamily,
    fontSize: preDefinedOptionsTextFontSize,
    fontColor: preDefinedOptionsTextFontColor,
    margin: theme.spacing(5, 0, 2.5, 0),
  };

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      if (!disabled && open) {
        const filters = get('filters', data) || [];

        const fieldsFilter = filters.find((f) => f.field === optionDisplayField);
        if (fieldsFilter) {
          filters.pop();
        }

        if (searchTerm) {
          filters.push({
            field: optionDisplayField,
            operation: 'icontains',
            value: searchTerm,
          });
        }

        const searchData = {
          ...data,
          filters,
        };

        setLoading(true);
        if (dropDownOptions) {
          setOptions(dropDownOptions);
          setLoading(false);
        } else {
          asyncService({
            ...onSubmitApiCallData,
            data: searchData,
            onSuccess: (d) => {
              const rawApiQueryResult = get(['data', 'documents'], d);
              const apiResultDocuments = rawApiQueryResult;

              setOptions(apiResultDocuments);
              setLoading(false);
            },
            onError: () => {
              setOptions([]);
            },
            dispatch,
            token,
            refreshToken,
          });
        }
      }
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [
    open,
    disabled,
    searchTerm,
    dispatch,
    token,
    onSubmitApiCallData,
    data,
    optionDisplayField,
    dropDownOptions,
    refreshToken,
  ]);

  const handleSelectionClick = (option) => {
    // Dev Note: when single select, we set the option, when multiselect, we set the array of selected options.
    const foundTerm = findIndex((st) => st._id === option._id, selectedTerms) < 0;

    const newSelectedValue =
      (foundTerm && !multiSelect && option) ||
      (foundTerm && multiSelect && [...selectedTerms, option]) ||
      selectedTerms;

    setSelectedTerms(newSelectedValue);
    onChange(newSelectedValue);
  };

  const handleDeselectionClick = (option) => {
    // Dev Note: When single select, we deselect by choosing null. When multiselect, we remove the value we deselected.
    const remainingTerms =
      (multiSelect && filter((term) => term._id !== option._id, selectedTerms)) || null;
    setSelectedTerms(remainingTerms);
    onChange(remainingTerms);
  };

  const selectedTermsList =
    (multiSelect && selectedTerms) || (selectedTerms && [selectedTerms]) || [];

  return (
    <Grid container direction="column">
      <Typography sx={styleForHeader} aria-label="description">
        {label}
      </Typography>
      <Grid sx={styleForGrid} item>
        <Typography sx={styleForTypography} aria-label="description">
          {description}
        </Typography>
        <Autocomplete
          data-testid={autoCompleteTestId}
          disabled={disabled}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          multiple={multiSelect}
          options={options || []}
          getOptionLabel={(option) => option[optionDisplayField] || ''}
          PaperComponent={({ children }) => <Paper sx={styleForDropDownPaper}>{children}</Paper>}
          renderOption={(props, option) => (
            <li key={option._id || option.id || option.name}>
              <WaeChip
                sx={{ marginBottom: '2px' }}
                background={background}
                key={option._id || option.id || option.name}
                label={
                  <Box>
                    {option[optionDisplayField]}
                    <span> </span>
                    <AddIcon fontSize={iconSize} />
                  </Box>
                }
                onClick={() => handleSelectionClick(option)}
              />
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={styleForTextField}
              placeholder={placeholder}
              type="text"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchSharp sx={styleForIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <Box>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Box>
                ),
              }}
              onChange={(e) => {
                setOptions([]);
                setSearchTerm(e.target.value);
              }}
            />
          )}
        />
        {preDefinedOptionText && (
          <Typography sx={styleForPreDefinedOptionsText}>{preDefinedOptionText}</Typography>
        )}
        {preDefinedOptions && preDefinedOptions.length > 0 && (
          <Grid sx={styleForPreDefinedOptions} container item spacing={0.5} aria-label="options">
            {preDefinedOptions.map((e) => (
              <Grid key={e._id} container item xs="auto">
                <WaeChip
                  background={background}
                  key={e._id || e.id || e.name}
                  label={e[optionDisplayField]}
                  onClick={() => handleSelectionClick(e)}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Typography sx={sectionHeaderStyle}>{selectedSectionTitle}</Typography>
        <Grid sx={styleForPreDefinedOptions} container item spacing={1} aria-label="options">
          {selectedTermsList.map((term) => (
            <WaeChip
              sx={{ marginBottom: '5px', marginLeft: '10px' }}
              background={background}
              key={term._id || term.id || term.name}
              label={
                <Box>
                  {term[optionDisplayField]}
                  <span> </span>
                  <ClearIcon fontSize={iconSize} />
                </Box>
              }
              onClick={() => handleDeselectionClick(term)}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

SearchBoxV2.propTypes = {
  autoCompleteTestId: PropTypes.string,
  background: PropTypes.string,
  data: PropTypes.shape({}),
  description: PropTypes.string,
  disabled: PropTypes.bool,
  dropDownOptions: PropTypes.arrayOf(PropTypes.shape([])),
  fullWidth: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
  }),
  label: PropTypes.string,
  multiSelect: PropTypes.bool,
  onSubmitApiCallData: PropTypes.shape({}),
  optionLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  optionDisplayField: PropTypes.string,
  placeholder: PropTypes.string,
  preDefinedOptions: PropTypes.arrayOf(PropTypes.shape({})),
  preDefinedOptionText: PropTypes.string,
  selectedSectionTitle: PropTypes.string,
  sx: PropTypes.shape({}),
};

export { SEARCHBAR_BACKGROUND, SearchBoxV2 };
