import { createSlice } from '@reduxjs/toolkit';

const initialState = { isLoading: false };

export const passwordUpdateSlice = createSlice({
  name: 'passwordUpdateSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    updatePassword: (state) => {
      state.isLoading = true;
    },
    updatePasswordProcessed: (state) => {
      state.isLoading = false;
    },
    updatePasswordError: (state) => {
      state.isLoading = false;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const { updatePassword, updatePasswordProcessed, updatePasswordError } =
  passwordUpdateSlice.actions;

export const passwordUpdateReducer = passwordUpdateSlice.reducer;
