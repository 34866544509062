import { useEffect, useState } from 'react';

// Disabling because more hooks will exist soon. Going to move intersecting hook here.
// eslint-disable-next-line import/prefer-default-export
export const useDims = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: ref?.current?.offsetWidth || 0,
      height: ref?.current?.offsetHeight || 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (ref.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return dimensions;
};
