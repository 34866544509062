import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

export const selectDashboard = (state) => get('candidateDashboard', state);

export const selectIsLoadingCandidatesJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.isLoadingCandidatesJobs
);
export const selectIsLoadingFeaturedJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.isLoadingFeaturedJobs
);
export const selectIsProcessingPunch = createSelector(
  selectDashboard,
  (dashboard) => dashboard.isProcessingPunch
);
export const selectIsLoadingIndustryJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.isLoadingIndustryJobs
);
export const selectIsLoadingInterestJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.isLoadingInterestJobs
);
export const selectHasLoadedFeaturedJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.hasLoadedFeaturedJobs
);
export const selectHasLoadedIndustryJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.hasLoadedIndustryJobs
);
export const selectHasLoadedInterestJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.hasLoadedInterestJobs
);

export const selectCandidatesJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.candidatesJobs
);
export const selectFeaturedJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.featuredJobs
);
export const selectIndustryJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.industryJobs
);
export const selectInterestJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.interestJobs
);

export const selectMyTimeCard = createSelector(
  selectDashboard,
  (dashboard) => dashboard.myTimeCard
);

export const selectTotalsData = createSelector(
  selectDashboard,
  (dashboard) => dashboard.totalsData
);
export const selectActiveJobs = createSelector(
  selectDashboard,
  (dashboard) => dashboard.activeJobs
);
export const selectPunchError = createSelector(
  selectDashboard,
  (dashboard) => dashboard.punchError
);
