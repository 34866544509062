import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { convertPayrollDataToCSV } from '@careerstart/wae-common/helpers';

import ExportView from '../../../../../main/components/ExportViewV2/ExportView';

import { getSetData } from './timekeepingExportHelpers';

const TimekeepingExport = ({ filters, user, isOpen, onClose }) => {
  const [progress, setProgress] = useState(0);

  return (
    <ExportView
      isOpen={isOpen}
      onClose={onClose}
      getSetData={() =>
        getSetData({
          apiPath: 'v2/payroll-data',
          filters,
          pageSize: 25,
          token: user?.token,
          setProgress,
        })
      }
      resultToCSVTextConverter={convertPayrollDataToCSV}
      fileName="Payroll Export"
      setProgress={(newProgress) => setProgress(newProgress)}
      progress={progress}
    />
  );
};

TimekeepingExport.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  user: PropTypes.shape({
    role: PropTypes.string,
    token: PropTypes.string,
  }),
  filters: PropTypes.arrayOf(PropTypes.shape({})),
};
export default TimekeepingExport;
