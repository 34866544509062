import { createSlice } from '@reduxjs/toolkit';

import { clearLocalStorage, LOCAL_STORAGE_KEYS, setItemToLocalStorage } from '../../store/utils';

const initialState = {
  session: { user: null },
  snackbar: { isOpen: false, message: undefined },
  isLoadingCertCredit: false,
  isProcessingAppTraining: false,
};

export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    hideSnackbar: (state) => {
      state.snackbar = {};
    },
    creditLoggedInCandidateSkills: (state) => {
      state.isLoadingCertCredit = true;
    },
    creditLoggedInCandidateSkillsProcessed: (state, action) => {
      const skillsAdded = action.payload.data.skills.map((skill) => ({
        skill: skill.id,
        expires: Date.now() + skill.duration,
        _id: skill._id,
        name: skill.name,
      }));
      state.session.user.candidate.skills = [
        ...state.session.user.candidate.skills,
        ...skillsAdded,
      ];
      state.isLoadingCertCredit = false;
    },
    creditCandidateSkills: (state) => {
      state.isLoadingCertCredit = true;
    },
    creditCandidateSkillsProcessed: (state) => {
      state.isLoadingCertCredit = false;
    },
    loginProcessed: (state, action) => {
      state.session.user = action.payload.data;
      setItemToLocalStorage(LOCAL_STORAGE_KEYS.userDataKey, action.payload.data);
    },
    updateToken: (state, action) => {
      state.session.user.token = action.payload;
      setItemToLocalStorage(LOCAL_STORAGE_KEYS.userDataKey, state.session.user);
    },
    logout: () => {
      clearLocalStorage();
    },
    showSnackbar: (state, action) => {
      state.snackbar.isOpen = true;
      state.snackbar.type = action.payload.type;
      state.snackbar.message = action.payload.message; // TODO: HB-200 Format for error keys passed by backend
    },
    updateUserData: (state, action) => {
      state.session.user = action.payload;
      clearLocalStorage();
      setItemToLocalStorage(LOCAL_STORAGE_KEYS.userDataKey, action.payload);
    },
    updateSkillsForSession: (state, action) => {
      const newSkills = action.payload.data.skills.map((skill) => ({
        skill: skill.id,
        expires: Date.now() + skill.duration,
        _id: skill._id,
        name: skill.name,
      }));
      state.session.user.candidate.skills = newSkills;
    },
    emailVerificationOrPWResetUpdateUserData: (state, action) => {
      clearLocalStorage();
      state.session.user = action.payload.data;
      setItemToLocalStorage(LOCAL_STORAGE_KEYS.userDataKey, action.payload.data);
    },

    updateTraining: (state) => {
      state.isProcessingAppTraining = true;
    },
    updateTrainingProcessed: (state, action) => {
      state.isProcessingAppTraining = false;
      state.session.user.training = action.payload.data.training;
      clearLocalStorage();
      setItemToLocalStorage(LOCAL_STORAGE_KEYS.userDataKey, state.session.user);
    },
    updateTrainingError: (state) => {
      state.isProcessingAppTraining = false;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const {
  creditLoggedInCandidateSkills,
  creditLoggedInCandidateSkillsProcessed,
  creditCandidateSkills,
  creditCandidateSkillsProcessed,
  emailVerificationOrPWResetUpdateUserData,
  hideSnackbar,
  loginProcessed,
  updateToken,
  logout,
  readCandidateProcessedUpdateApp,
  showSnackbar,
  updateUserData,
  updateSkillsForSession,
  updateTraining,
  updateTrainingProcessed,
  updateTrainingError,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
