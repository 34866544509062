import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Paper as MuiPaper, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import theme from '../../../theme';

const borderRadius = theme.spacing(1);

const StyledPaper = styled(MuiPaper)(() => ({
  height: '100%',
  borderRadius,
  backgroundColor: get(
    ['views', 'reporting', 'palette', 'paper', 'backgroundColor', 'default'],
    theme
  ),
}));

const themePath = ['views', 'reporting', 'typography', 'card'];

const IconContentLabelCard = ({
  data,
  direction = 'column',
  iconContentGap,
  iconSize,
  loading,
  staticData,
  sx,
}) => (
  <Grid item xs>
    <StyledPaper sx={sx}>
      {loading ? (
        <Skeleton height="inherit" variant="rectangular" sx={{ borderRadius }} />
      ) : (
        <Grid
          container
          height="inherit"
          direction={direction}
          justifyContent="center"
          alignItems="center"
          gap={iconContentGap}
        >
          <Box
            component="img"
            sx={{
              height: iconSize,
              width: iconSize,
            }}
            alt="Stars"
            src={get(['icon'], staticData)}
          />
          <Grid
            container
            direction="column"
            alignItems={direction !== 'row' && 'center'}
            width={direction === 'row' ? 'fit-content' : null}
          >
            <Typography
              sx={{
                color: get([...themePath, 'content', 'fontColor'], theme),
                fontFamily: get([...themePath, 'content', 'fontFamily'], theme),
                fontSize: get([...themePath, 'content', 'fontSize'], theme),
                fontWeight: get([...themePath, 'content', 'fontWeight'], theme),
                lineHeight: '34px',
              }}
            >
              {data}
            </Typography>
            <Typography
              sx={{
                color: get([...themePath, 'label', 'fontColor'], theme),
                fontFamily: get([...themePath, 'label', 'fontFamily'], theme),
                fontSize: get([...themePath, 'label', 'fontSize'], theme),
                fontWeight: get([...themePath, 'label', 'fontWeight'], theme),
                lineHeight: '18px',
              }}
            >
              {get(['label'], staticData)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </StyledPaper>
  </Grid>
);

IconContentLabelCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  direction: PropTypes.string,
  iconContentGap: PropTypes.number,
  iconSize: PropTypes.number,
  loading: PropTypes.bool,
  staticData: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

export default IconContentLabelCard;
