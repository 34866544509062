import * as React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';

const TrashcanIcon = ({ width = 19, height = 21, color = '#B23E3E' }) => (
  <SvgIcon sx={{ width, height, color }}>
    <svg width="19" height="21" viewBox="0 0 19 21" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7805 8.10081C11.3327 8.10081 11.7805 8.54852 11.7805 9.10081V14.5014C11.7805 15.0536 11.3327 15.5014 10.7805 15.5014C10.2282 15.5014 9.78046 15.0536 9.78046 14.5014V9.10081C9.78046 8.54852 10.2282 8.10081 10.7805 8.10081Z"
        fill={color}
      />
      <path
        d="M8.22394 9.10081C8.22394 8.54852 7.77622 8.10081 7.22394 8.10081C6.67165 8.10081 6.22394 8.54852 6.22394 9.10081V14.5014C6.22394 15.0536 6.67165 15.5014 7.22394 15.5014C7.77622 15.5014 8.22394 15.0536 8.22394 14.5014V9.10081Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5587 2.80018V3.60036H15.2261L15.2287 3.60036H17.0044C17.5567 3.60036 18.0044 4.04808 18.0044 4.60036C18.0044 5.15265 17.5567 5.60036 17.0044 5.60036H16.2261V17.2016C16.2261 17.9403 15.9363 18.6513 15.4167 19.1773C14.8966 19.7038 14.1887 20.0018 13.4479 20.0018H4.55653C3.8157 20.0018 3.10773 19.7038 2.58768 19.1773C2.06803 18.6513 1.77826 17.9403 1.77826 17.2016V5.60036H1C0.447715 5.60036 0 5.15265 0 4.60036C0 4.04808 0.447715 3.60036 1 3.60036H2.77565L2.77826 3.60036H4.44566V2.80018C4.44566 2.06145 4.73543 1.35055 5.25508 0.824497C5.77513 0.298032 6.4831 0 7.22392 0H10.7805C11.5213 0 12.2292 0.298032 12.7493 0.824497C13.2689 1.35055 13.5587 2.06145 13.5587 2.80018ZM6.67792 2.23002C6.82485 2.08129 7.02149 2 7.22392 2H10.7805C10.9829 2 11.1795 2.08129 11.3265 2.23002C11.4738 2.37917 11.5587 2.58403 11.5587 2.80018V3.60036H6.44566V2.80018C6.44566 2.58403 6.53059 2.37917 6.67792 2.23002ZM3.77826 5.60036V17.2016C3.77826 17.4178 3.86319 17.6226 4.01052 17.7718C4.15745 17.9205 4.3541 18.0018 4.55653 18.0018H13.4479C13.6503 18.0018 13.8469 17.9205 13.9939 17.7718C14.1412 17.6226 14.2261 17.4178 14.2261 17.2016V5.60036L3.77826 5.60036Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear_3772_506"
          x1="6.19716"
          y1="15.0014"
          x2="18.6066"
          y2="13.306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0073E5" />
          <stop offset="1" stopColor="#44A1FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3772_506"
          x1="6.19716"
          y1="15.0014"
          x2="18.6066"
          y2="13.306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0073E5" />
          <stop offset="1" stopColor="#44A1FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3772_506"
          x1="6.19716"
          y1="15.0014"
          x2="18.6066"
          y2="13.306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0073E5" />
          <stop offset="1" stopColor="#44A1FF" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

TrashcanIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default TrashcanIcon;
