import FormTextField from '../../../../../components/Form/FormTextField';

const FormFieldData = {
  title: {
    ComponentType: FormTextField,
    key: 'departmentTitle',
    name: 'name',
    multiline: false,
    label: 'Department Name',
    fullWidth: true,
    required: true,
  },
  description: {
    ComponentType: FormTextField,
    key: 'departmentDescription',
    name: 'description',
    multiline: true,
    label: 'Department Description',
    fullWidth: true,
    required: true,
  },
};
export default FormFieldData;
