import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import Button from '../Button';
import Dialog from '../Dialog';

const ConfirmationModal = ({
  body,
  cancelBtnText,
  confirmationBtnText,
  isOpen,
  onConfirm,
  onClose,
  title,
}) => {
  const handleOnConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const deleteDialogButtons = [
    <Button
      text={cancelBtnText || LanguageConverter('buttonText.cancel')}
      key="handle-cancel-button"
      onClick={onClose}
    />,

    <Button
      text={confirmationBtnText || LanguageConverter('buttonText.confirm')}
      key="handle-confirm-button"
      onClick={handleOnConfirm}
    />,
  ];

  return (
    <Dialog
      open={isOpen}
      dialogTitle={title}
      dialogContentText={body}
      dialogActions={deleteDialogButtons}
      component="span"
      onBackdropClick={onClose}
    />
  );
};

ConfirmationModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  cancelBtnText: PropTypes.string,
  confirmationBtnText: PropTypes.string,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
};

export default ConfirmationModal;
