import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const TickIcon = () => (
  <SvgIcon>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2498_42129)">
        <path
          d="M10.9144 14.963C10.1332 15.7434 8.8674 15.7431 8.08661 14.9624L6.03516 12.9109C5.64539 12.5211 5.01381 12.52 4.62267 12.9084C4.22957 13.2987 4.22844 13.9342 4.62016 14.3259L8.08594 17.7917C8.86699 18.5727 10.1333 18.5727 10.9144 17.7917L20.795 7.91106C21.1844 7.52161 21.1844 6.89018 20.795 6.50073C20.4057 6.11141 19.7745 6.11126 19.385 6.5004L10.9144 14.963Z"
          fill="black"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2498_42129">
          <rect width="24" height="24" fill="white" transform="translate(0.5 0.206055)" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default TickIcon;
