import * as React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import theme from '../../../theme';

const typography = get(['candidate', 'components', 'timeCards', 'typography'], theme);
const palette = get(['candidate', 'components', 'timeCards', 'palette'], theme);

const NextShiftCard = ({ corporationName, endTime, shiftName, startDate, startTime }) => (
  <Grid item xs={12} margin={theme.spacing(0, 0, 1)}>
    <Card
      sx={{
        borderRadius: '16px',
        backgroundColor: get(['nextShiftCard', `backgroundColor`], palette),
      }}
    >
      <CardContent>
        <Grid container direction="column" justifyContent="center">
          <Typography sx={get(['nextShiftTitle'], typography)}>Your Next Shift</Typography>
          <Typography sx={get(['nextShiftDateTime'], typography)}>
            {startDate ? `${startDate} @ ${startTime} - ${endTime}` : ' No Upcoming Shift'}
          </Typography>
          <Typography sx={get(['nextShiftNameCorp'], typography)}>
            {startDate && `${shiftName} • ${corporationName}`}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

NextShiftCard.propTypes = {
  corporationName: PropTypes.string,
  endTime: PropTypes.string,
  shiftName: PropTypes.string,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
};

export default NextShiftCard;
