import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box, Checkbox, Typography } from '@mui/material';

const SwitchFilter = ({ field, operation, onValueChange, fixedValue, allowEmptyArray }) => {
  const [active, setActive] = React.useState(!!fixedValue);

  const onChange = useCallback(() => {
    onValueChange([
      {
        value: !active ? fixedValue : null,
        field,
        operation,
        allowEmptyArray,
      },
    ]);
    setActive(!active);
  }, [active, allowEmptyArray, field, onValueChange, operation, fixedValue]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 'max-content',
      }}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: 'Barlow-600',
            fontSize: '14px',
            color: 'black',
          }}
        >
          Match My Certifications
        </Typography>
      </Box>
      <Box>
        <Checkbox color="primary" checked={active} onChange={onChange} />
      </Box>
    </Box>
  );
};

SwitchFilter.propTypes = {
  field: PropTypes.string,
  operation: PropTypes.string,
  onValueChange: PropTypes.func,
  fixedValue: PropTypes.arrayOf(PropTypes.string),
  allowEmptyArray: PropTypes.bool,
};

export default SwitchFilter;
