const primary = 'Roboto';
// const secondary = 'Barlow';

const PRIMARY_FONT = {
  default: `${primary}-300`,
  100: `${primary}-100`,
  300: `${primary}-300`,
  400: `${primary}-400`,
  500: `${primary}-500`,
  700: `${primary}-700`,
  900: `${primary}-900`,
};

// eslint-disable-next-line import/prefer-default-export
export { PRIMARY_FONT };
