import { createSelector } from 'reselect';

const selectAppSession = (state) => state?.app?.session;
const selectApp = (state) => state?.app;

const selectUser = createSelector(selectAppSession, (session) => session.user);
export const selectIsProcessingAppTraining = createSelector(
  selectApp,
  (app) => app.isProcessingAppTraining
);

export default selectUser;
