import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import theme, { listHeaderHeight } from '../../theme';
import Button from '../Button';

const RootGrid = styled(Grid)(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  height: listHeaderHeight,
}));

const ListHeader = ({ headerText, buttonText, handleOnClick, children }) => (
  <RootGrid item container>
    <Typography
      sx={{ paddingLeft: 1, fontFamily: `${theme.dataGrid.default.boldFont}` }}
      variant="h5"
    >
      {headerText}
    </Typography>
    {buttonText && (
      <Button
        style={{ marginLeft: 1 }}
        color="primary"
        text={buttonText}
        key="list-view-new-button"
        onClick={handleOnClick}
      />
    )}
    {children}
  </RootGrid>
);

ListHeader.propTypes = {
  headerText: PropTypes.string,
  buttonText: PropTypes.string,
  handleOnClick: PropTypes.func,
  children: PropTypes.element,
};

export default ListHeader;
