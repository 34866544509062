/**
 * @description builds error helper text for textfield component
 * @param {String} value input value of textfield
 * @param {Number} charLimit character limit for textfield component, default is 25
 * @param {Boolean} required boolean for if textfield is required
 * @returns {String} a string to display error message
 */

export const getTextFieldError = ({ value, charLimit, required }) => {
  if (required && !value) {
    return 'error.field.required';
  }

  if (value?.length > charLimit) {
    return 'error.field.charLimit';
  }

  return undefined;
};

export default getTextFieldError;
