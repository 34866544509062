import * as React from 'react';
import get from 'lodash/fp/get';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import bagLogo from '../../../../../assets/Jobs.png';
import theme from '../../../../../theme';

const NoShiftsCard = () => {
  const cardTheme = get(['candidate', 'components', 'groupDetails', 'availableShiftCard'], theme);

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: '100%',
        padding: theme.spacing(4),
        backgroundColor: get('backgroundColor', cardTheme),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <CardContent>
        <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
          <img src={bagLogo} alt="workbag" />
        </Grid>
        <Typography
          variant="h4"
          sx={{
            color: get(['primaryText', 'color'], cardTheme),
            fontFamily: get(['primaryText', 'font'], cardTheme),
          }}
          align="center"
        >
          Oh,no!
        </Typography>
        <Typography
          variant="body2"
          align="center"
          sx={{
            color: get(['primaryText', 'color'], cardTheme),
            fontFamily: get(['secondaryText', 'font'], cardTheme),
          }}
        >
          There are no available shifts!
        </Typography>
      </CardContent>
    </Card>
  );
};

export default NoShiftsCard;
