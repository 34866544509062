const name = {
  field: 'name',
  headerClassName: 'theme-header',
  headerName: 'Name',
  minWidth: 250,
};
const externalId = {
  field: 'externalId',
  filterable: false,
  headerClassName: 'theme-header',
  headerName: 'External ID',
  sortable: false,
  flex: 1,
};

const quickBooksCompanyName = {
  field: 'quickBooksCompanyName',
  headerClassName: 'theme-header',
  headerName: 'QuickBooks Company Name',
  flex: 1,
};

const mailingAddress = {
  field: 'mailingAddress',
  filterable: false,
  headerClassName: 'theme-header',
  headerName: 'Mailing Address',
  sortable: false,
  flex: 1,
};

const corporationListColumnData = [name, externalId, quickBooksCompanyName, mailingAddress];

export default corporationListColumnData;
