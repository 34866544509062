import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const SwitchComponent = ({ disabled, handleOnChange, initialValue, label, xs }) => {
  const IOSSwitch = styled((props, onChange) => (
    <Switch
      value={initialValue}
      onChange={onChange}
      datatestid="Switch-Component"
      checked={initialValue}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  return (
    <Grid item xs={xs}>
      <FormControlLabel
        control={
          <IOSSwitch
            value={initialValue}
            disabled={disabled}
            onChange={() => handleOnChange()}
            sx={{ m: 1 }}
          />
        }
        label={label}
      />
    </Grid>
  );
};
SwitchComponent.propTypes = {
  disabled: PropTypes.bool,
  handleOnChange: PropTypes.func,
  initialValue: PropTypes.bool,
  label: PropTypes.string,

  xs: PropTypes.number,
};

export default SwitchComponent;
