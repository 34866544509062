import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import NavigationBar from '../../../components/NavigationBar/NavigationBar';

const Navigation = ({ variant }) =>
  variant === 'TODO' ? null : (
    <Grid>
      <NavigationBar />
      <Outlet />
    </Grid>
  );

Navigation.propTypes = {
  variant: PropTypes.string,
};

export default Navigation;
