import { get } from 'lodash/fp';

import FormTypographyBox from '../../../../../components/Form/FormTypographyBox';
import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../../../components/SearchBar';
import theme from '../../../../../theme';
import launchDarklyToggles from '../../../../../utils/launchDarklyToggles';

const componentsDetailViewSectionHeader = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];

const predefinedCertsOptions = (certifications) =>
  certifications.map((cert) => ({
    name: cert.name,
    _id: cert.id,
  }));

const detailViewTypographyCertText = ['components', 'detailView', 'typography', 'certText'];

const FormFieldDataCertSkills = (certifications, flags) =>
  [
    {
      ComponentType: FormTypographyBox,
      key: 'certificationsHeader',
      name: 'certificationsHeader',
      sx: {
        fontSize: get([...componentsDetailViewSectionHeader, 'fontSize'], theme),
        color: get([...componentsDetailViewSectionHeader, 'fontColor'], theme),
        fontFamily: get([...componentsDetailViewSectionHeader, 'fontFamily'], theme),
      },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'certificationsText',
      name: 'certificationsText',
      sx: {
        fontSize: get([...detailViewTypographyCertText, 'fontSize'], theme),
        color: get([...detailViewTypographyCertText, 'fontColor'], theme),
        fontFamily: get([...detailViewTypographyCertText, 'fontFamily'], theme),
      },
    },

    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.DARK,
      preDefinedOptions: [],
      data: null,
      dropDownOptions: predefinedCertsOptions(certifications),
      key: 'id',
      name: 'certifications',
      multiSelect: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Certifications',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'GET',
        route: 'certifications',
      },
      optionDisplayField: 'name',
    },
    launchDarklyToggles(flags, 'isSkillSectionForCandidateUserDetailEnabled') && {
      ComponentType: FormTypographyBox,
      key: 'skillsHeader',
      name: 'skillsHeader',
      sx: {
        fontSize: get([...componentsDetailViewSectionHeader, 'fontSize'], theme),
        color: get([...componentsDetailViewSectionHeader, 'fontColor'], theme),
        fontFamily: get([...componentsDetailViewSectionHeader, 'fontFamily'], theme),
      },
    },
    launchDarklyToggles(flags, 'isSkillSectionForCandidateUserDetailEnabled') && {
      ComponentType: FormTypographyBox,
      key: 'skillsText',
      name: 'skillsText',
      sx: {
        fontSize: get([...detailViewTypographyCertText, 'fontSize'], theme),
        color: get([...detailViewTypographyCertText, 'fontColor'], theme),
        fontFamily: get([...detailViewTypographyCertText, 'fontFamily'], theme),
      },
    },
    launchDarklyToggles(flags, 'isSkillSectionForCandidateUserDetailEnabled') && {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.DARK,
      preDefinedOptions: [],
      data: null,
      key: 'id',
      name: 'skills',
      multiSelect: true,
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Search Skills',
      disabled: launchDarklyToggles(flags, 'isSkillSectionForCandidateUserDetailEnabled'),
      fullWidth: true,
      optionDisplayField: 'name',
    },
  ].filter(Boolean);

export default FormFieldDataCertSkills;
