import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { SearchSharp } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Autocomplete,
  Chip,
  CircularProgress,
  FormControl,
  InputAdornment,
  Paper,
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import asyncService from '../../../../../datahub/asyncService';
import IconButton from '../../../../components/IconButton';
import selectUser from '../../../../store/selectors/appSelector';
import theme from '../../../../theme';

const JobInviteDropdownEditCell = ({ params, dispatch, apiRef }) => {
  const { id, value = [], field } = params;
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const user = useSelector(selectUser);

  const token = user?.token;
  const refreshToken = user?.refreshToken;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(open && searchTerm === '' && options.length === 0);

  const styleForFormControl = {
    justifyContent: 'center',
    height: '40px',
    width: '100%',
  };

  const radioSx = {
    height: 22,
    width: 22,
    color: theme.button.palette.primary,
  };

  const handleSelect = async (option) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: [...value, option],
    });
  };
  const handleOnDeselect = async (option) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: value?.filter((val) => val?._id !== option?._id),
    });
  };
  const handleOnClear = useCallback(async () => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: [],
    });
  }, [field, id, apiRef]);

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      const requestBody = {
        filters: [
          { operation: 'icontains', field: 'name', value: searchTerm },
          { operation: 'equals', field: 'role', value: 'candidate' },
        ].filter((i) => i),
      };

      asyncService({
        httpMethod: 'POST',
        route: 'users/read',
        data: requestBody,
        onSuccess: (d) => {
          const apiResultDocuments = d?.data?.documents;
          setOptions(apiResultDocuments);
          setLoading(false);
        },
        onError: () => {
          setOptions([]);
          setLoading(false);
        },
        dispatch,
        token,
        refreshToken,
      });
    }, 750);
    return () => clearTimeout(delayedSearch);
  }, [searchTerm, dispatch, token, loading, refreshToken]);

  return (
    <FormControl sx={styleForFormControl}>
      <Autocomplete
        multiple
        isOptionEqualToValue={(option, val) => option._id === val._id}
        open={open}
        onOpen={() => {
          setOpen(true);
          setLoading(options.length <= 0);
        }}
        onClose={() => {
          setOpen(false);
          setLoading(false);
          apiRef.current.stopCellEditMode({ id, field });
        }}
        openOnFocus
        loading={loading}
        options={
          options?.map((opt) =>
            value.find((val) => val?._id === opt?._id) ? { ...opt, selected: true } : opt
          ) || []
        }
        loadingText={
          <Grid container justifyContent="space-between">
            <div> {LanguageConverter('generic.loadingResults')}</div>
            <CircularProgress size={22} />
          </Grid>
        }
        getOptionLabel={(option) => option?.name}
        PaperComponent={({ children }) => <Paper>{children}</Paper>}
        renderOption={(prop, option) => (
          <Box
            {...prop}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            onClick={() => (option?.selected ? handleOnDeselect(option) : handleSelect(option))}
          >
            {option?.name}
            {option?.selected ? (
              <RadioButtonCheckedIcon sx={radioSx} />
            ) : (
              <RadioButtonUncheckedIcon sx={radioSx} />
            )}
          </Box>
        )}
        renderInput={(prop) => (
          <TextField
            {...prop}
            placeholder={LanguageConverter('generic.search.employees')}
            type="text"
            InputProps={{
              ...prop.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchSharp />
                </InputAdornment>
              ),
              endAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center', direction: 'row' }}>
                  <Chip
                    label={value.length}
                    sx={{
                      height: '22px',
                      width: 'auto',
                      backgroundColor: '#D7BDFF',
                    }}
                  />
                  <IconButton
                    iconElement={<CloseIcon sx={{ height: 18, width: 18 }} />}
                    onClick={handleOnClear}
                    size="small"
                  />
                  {prop.InputProps.endAdornment}
                </Box>
              ),
            }}
            onChange={(e) => {
              setOptions([]);
              setLoading(true);
              setSearchTerm(e.target.value);
            }}
          />
        )}
      />
    </FormControl>
  );
};

JobInviteDropdownEditCell.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string, name: PropTypes.string })),
    field: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  apiRef: PropTypes.shape({
    current: PropTypes.shape({
      setEditCellValue: PropTypes.func,
      stopCellEditMode: PropTypes.func,
    }),
  }),
};
export default JobInviteDropdownEditCell;
