import { createSlice } from '@reduxjs/toolkit';

const initialState = { isLoading: false };

export const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    login: (state) => {
      state.isLoading = true;
    },
    loginProcessed: (state) => {
      state.isLoading = false;
    },
    loginError: (state) => {
      state.isLoading = false;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const { loginError, login, loginProcessed } = loginSlice.actions;

export const loginReducer = loginSlice.reducer;
