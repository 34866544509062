import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

export const selectCandidateJobInvitation = (state) => get('candidateJobInvitation', state);

export const selectLoadingInvitedJob = createSelector(
  selectCandidateJobInvitation,
  (dashboard) => dashboard.loadingInvitedJob
);
export const selectInvitedJob = createSelector(
  selectCandidateJobInvitation,
  (dashboard) => dashboard.invitedJob
);
export const selectSkills = createSelector(
  selectCandidateJobInvitation,
  (dashboard) => dashboard.skills
);
export const selectLoadingSkills = createSelector(
  selectCandidateJobInvitation,
  (dashboard) => dashboard.loadingSkills
);
export const selectPostInviteRespondSuccessful = createSelector(
  selectCandidateJobInvitation,
  (dashboard) => dashboard.postInviteRespondSuccessful
);
export const selectPostingInviteRespond = createSelector(
  selectCandidateJobInvitation,
  (dashboard) => dashboard.postingInviteRespond
);
