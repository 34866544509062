import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import Grid from '@mui/material/Grid';

import FormCheckBox from './FormCheckBox';
import FormRadioButtons from './FormRadioButtons';

const buildFormField = (fieldData, errorData, clearServerError) => {
  const { ComponentType, format, name, parse, type, validate } = fieldData;
  const errorObject = errorData === undefined ? {} : errorData;
  const fieldErrorData = errorObject[name] ? errorObject[name] : null;
  const rffFieldProps =
    (ComponentType === FormRadioButtons && { type }) ||
    (ComponentType === FormCheckBox && {
      type: true,
    });

  return (
    <>
      <Field
        format={format}
        formatOnBlur
        name={name}
        parse={parse}
        validate={validate}
        {...rffFieldProps}
      >
        {(props) => <ComponentType {...fieldData} {...props} fieldErrorData={fieldErrorData} />}
      </Field>
      {clearServerError && (
        <OnChange name={name}>
          {(value, previous) => {
            if (fieldErrorData && value !== previous) {
              clearServerError(name);
            }
          }}
        </OnChange>
      )}
    </>
  );
};

const formFieldFactory = (errorData, clearServerError, formFieldData) => (
  // FIXME: HB-1575 SPACING TO BE REMOVED FROM GRID CONTAINER. ALL COMPONENTS USING FORMFIELDFACTORY PADDING NEEDS TO BE ADJUSTED.
  <Grid container item spacing={3}>
    {formFieldData.map((fieldData) => (
      <Grid item key={fieldData.name} xs={fieldData.xs || 12}>
        {buildFormField(fieldData, errorData, clearServerError)}
      </Grid>
    ))}
  </Grid>
);

export { buildFormField, formFieldFactory };
