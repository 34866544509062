import React from 'react';
import PropTypes from 'prop-types';

import DragIndicatorIcon from '../../assets/icons/DragIndicatorIcon';

const DraggableHandle = ({ drag }) => (
  <div ref={drag}>
    <DragIndicatorIcon sx={{ cursor: 'move' }} />
  </div>
);

DraggableHandle.propTypes = {
  drag: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default DraggableHandle;
