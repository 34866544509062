import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';

import Delete from '../../../../../assets/icons/Delete.svg';
import Button, { BUTTON_VARIANT } from '../../../../../components/Button';

import BasicDrawer from './BasicDrawer';
import DeletedPlacementCard from './DeletedPlacementCard';

const DeletePlacementDrawer = ({
  isOpen,
  onClose,
  candidateName,
  handleCancelPlacement,
  isOneOpening = true,
}) => {
  const headerText = LanguageConverter('jobOrder.roster.headerTextForRemoveEmployee');
  const subHeaderText = LanguageConverter(
    'jobOrder.roster.subHeaderTextForRemoveEmployee',
    isOneOpening ? '1 opening' : 'the entire series'
  );
  const HeaderIcon = () => (
    <Box
      component="img"
      sx={{
        height: 51.52,
        width: 51.52,
      }}
      alt="Delete"
      src={Delete}
    />
  );

  const GoBackButton = () => (
    <Button
      variant={BUTTON_VARIANT.OUTLINED}
      onClick={onClose}
      text={LanguageConverter('button.goBack')}
    />
  );

  const ActionButton = () => (
    <Button
      variant={BUTTON_VARIANT.DELETE}
      onClick={handleCancelPlacement}
      sx={{ marginLeft: '5px' }}
      text={LanguageConverter('button.employee.remove')}
    />
  );

  return (
    <BasicDrawer
      goBackButton={<GoBackButton />}
      actionButton={<ActionButton />}
      headerIcon={<HeaderIcon /> || null}
      header={headerText}
      subHeader={subHeaderText}
      content={<DeletedPlacementCard name={candidateName} />}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

DeletePlacementDrawer.propTypes = {
  candidateName: PropTypes.string,
  handleCancelPlacement: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isOneOpening: PropTypes.bool,
};

export default DeletePlacementDrawer;
