import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme from '../../theme';

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [ref]);

  return isIntersecting;
};

const ScrollToTopButton = (props) => {
  const { headerRef } = props;
  const phoneScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isHeaderVisible = useOnScreen(headerRef || <div />);

  const handleClick = () => {
    headerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box
      sx={{
        visibility: isHeaderVisible ? 'hidden' : 'visible',
        position: 'absolute',
        bottom: phoneScreen ? theme.spacing(3) : '10vh',
        color: theme.components.nav.textColor.default,
        backgroundColor: theme.components.nav.backgroundColor.default,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        padding: theme.spacing(1),
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <ArrowUpwardIcon />
    </Box>
  );
};

ScrollToTopButton.propTypes = {
  headerRef: PropTypes.shape({ current: PropTypes.shape({ scrollIntoView: PropTypes.func }) }),
};

export default ScrollToTopButton;
