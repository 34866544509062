import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectPositionTemplateList = (state) => get('positionTemplateList', state);

export const selectIsLoading = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.isLoading
);

export const selectPositionTemplateListRowData = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.positionTemplateListRowData
);

export const selectTotalRowCount = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.totalRowCount
);

export const selectedPositionTemplatesList = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.selectedPositionTemplates
);

export const selectLocations = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.locations
);

export const selectIsLoadingLocations = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.isLoadingLocations
);

export const selectCertifications = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.certifications
);

export const selectIsLoadingCertifications = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.isLoadingCertifications
);

export const selectCorporations = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.corporations
);

export const selectIsLoadingCorporations = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.isLoadingCorporations
);

export const selectIsDeleteProcessing = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.isDeleteProcessing
);

export const selectIsUpdateProcessing = createSelector(
  selectPositionTemplateList,
  (positionTemplateList) => positionTemplateList.isUpdateProcessing
);
