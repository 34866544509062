import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import Delete from '../../../../assets/icons/Delete.svg';
import Button, { BUTTON_VARIANT } from '../../../../components/Button';
import IconButton from '../../../../components/IconButton';
import theme from '../../../../theme';

const RootBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(2, 8),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
}));

const HeaderBox = styled(Box)(() => ({
  width: '100%',
}));
const componentWorkFlowFont = ['components', 'workflow', 'font'];
const HeaderBoxTypography = styled(Box)(() => ({
  fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  color: get([...componentWorkFlowFont, 'transitionPageFontColor', 'light'], theme),
}));
const DescriptionBoxTypgraphy = styled(Box)(() => ({
  fontSize: '16px',
  fontFamily: get([...componentWorkFlowFont, 'defaultFont'], theme),
  color: '#333357',
}));

const DeleteDrawer = ({ handlePositionTemplateDelete, isDeleteProcessing, isOpen, onClose }) => {
  const handlePTDelete = () => {
    handlePositionTemplateDelete();
    onClose();
  };
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} PaperProps={{ sx: { width: '576px' } }}>
      <HeaderBox>
        <IconButton
          iconElement={<CloseIcon />}
          onClick={() => onClose()}
          size="small"
          sx={{ marginLeft: 0.5, marginTop: 0.5 }}
        />
      </HeaderBox>
      <RootBox>
        <Box
          component="img"
          sx={{
            height: 51.52,
            width: 51.52,
          }}
          alt="Delete"
          src={Delete}
        />
        <HeaderBoxTypography>Are you sure you want to delete this position?</HeaderBoxTypography>
        <DescriptionBoxTypgraphy>
          This action can not be undone, if you would like to proceed please do so by clicking
          ‘Delete Position’ below.
        </DescriptionBoxTypgraphy>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              onClick={onClose}
              variant={BUTTON_VARIANT.OUTLINED}
              text={LanguageConverter('button.goBack')}
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => handlePTDelete()}
              variant={BUTTON_VARIANT.DELETE}
              showIndeterminateProgress={isDeleteProcessing}
            >
              Delete Position
            </Button>
          </Grid>
        </Grid>
      </RootBox>
    </Drawer>
  );
};

DeleteDrawer.propTypes = {
  handlePositionTemplateDelete: PropTypes.func,
  isDeleteProcessing: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DeleteDrawer;
