import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCreateJobs = (state) => get('createJobs', state);

export const selectIsPostingJobs = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.isPostingJobs
);

export const jobsList = createSelector(selectCreateJobs, (createJobs) => createJobs.jobsList);
export const clearJobsList = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.clearJobsList
);
export const allDataValid = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.allDataValid
);

export const validateList = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.validateList
);

export const selectPositionTemplateData = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.positionTemplateData
);

export const selectIsLoadingPt = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.isLoadingPt
);
export const selectIsLoadingCorporations = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.isLoadingCorporations
);
export const corporationOptions = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.corporations
);

export const selectedCorporation = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.selectedCorporation
);

export const selectConfirmationData = createSelector(
  selectCreateJobs,
  (createJobs) => createJobs.confirmationData
);
