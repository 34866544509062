import numeral from 'numeral';

import { minutesFromMidnightToTime } from './timeConverter';

export const dateToYearMonthDayMinutes = (date, minutes) => {
  const newObject = {
    year: date.getYear() + 1900,
    day: date.getDate(),
    month: date.getMonth() + 1,
    minutes,
  };
  return newObject;
};

export const formatCurrency = (value) =>
  value === undefined ? '' : numeral(value).format('$0,0.00');

export const formatDecimalAsPercent = (value) =>
  value === undefined ? '' : numeral(parseFloat(value) / 100).format('0,0.00%');

export const percentageStringToDecimal = (percentageString) =>
  (percentageString &&
    typeof percentageString === 'string' &&
    Number(percentageString.replace(/[^0-9.-]+/g, ''))) / 100 || 0;

export const decimalToPercentageString = (decimal) =>
  decimal === undefined ? '' : numeral(parseFloat(decimal)).format('0,0.00%');

export const nameAndTimeToReadable = (name, start, end) => {
  const readable = `${name} (${minutesFromMidnightToTime(start).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })} - ${minutesFromMidnightToTime(end).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })})`;

  return readable;
};

// capitalize the first letter of a string
export const setFirstLetterOfStringToCapital = (word) =>
  word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : word;

export const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);
export const isSearchParamValid = (value) =>
  value && value !== 'null' && value !== 'undefined' && value !== 'false' && value !== 'NaN';

export const getAllSearchParams = (searchParams) => {
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

/**
 * @description Bulds a query string for http request using an array of objects
 * @param {Object[]} searchParamData An array of objects with properites paramOption and Param value
 * @returns {String} a string that is passable as a search pram '?x=...&y=...'
 */
export const buildSearchTerm = (searchParamData) => {
  if (searchParamData.length > 0) {
    const data = searchParamData.map((param) => `${param?.paramOption}=${param?.paramValue}`);
    return data.join('&');
  }
  return '';
};
