import React from 'react';
import PropTypes from 'prop-types';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Box, Checkbox } from '@mui/material';
import Typography from '@mui/material/Typography';

import { ACTION_BLUE, BLACK } from '../../theme/colorConstants';

const checkedStyles = {
  color: '#FFF',
  width: '100%',
  borderWidth: '1px',
  borderImage: 'linear-gradient(45deg, #0073E5 0%, #44A1FF 100%)',
  borderStyle: 'solid',
  borderImageSlice: 1,
  paddingLeft: 0,
};

const inactiveStyles = (isDisabled) => ({
  paddingLeft: 0,
  color: isDisabled ? BLACK[50] : ACTION_BLUE.ACTION_CONTRAST_REST,
});

const headerLabelStyles = {
  color: 'white',
  fontWeight: 'bold',
};

const rowLabelStyles = { color: 'white', marginLeft: '12px' };

/**
 * A custom reusable styled checkbox component based on our design.
 * @param {object} props - The component props.
 * @param {boolean} [props.disabled] - Whether the checkbox is disabled.
 * @param {Function} [props.handleOnChange] - The function to be called when the checkbox state changes.
 * @param {boolean} [props.checked] - Whether the checkbox is checked.
 * @param {string} [props.headerLabel] - The label for the checkbox header. It appears on the TOP of the checkbox.
 * @param {string} [props.rowLabel] - The label for the checkbox row. It appears at the RIGHT SIDE of the checkbox.
 * @returns {JSX.Element} The styled checkbox component.
 */
const StyledCheckbox = ({ disabled, handleOnChange, checked, headerLabel, rowLabel }) => (
  <Box direction="column">
    {headerLabel && <Typography sx={headerLabelStyles}> {headerLabel} </Typography>}
    <Box display="flex" direction="row" alignItems="center">
      <Checkbox
        checked={checked}
        disabled={disabled}
        onChange={handleOnChange}
        checkedIcon={<CheckOutlinedIcon sx={checkedStyles} />}
        icon={<CheckBoxOutlineBlankIcon sx={inactiveStyles(disabled)} />}
        style={{ paddingLeft: 0 }}
      />
      {rowLabel && <Typography sx={rowLabelStyles}>{rowLabel}</Typography>}
    </Box>
  </Box>
);
StyledCheckbox.propTypes = {
  disabled: PropTypes.bool,
  handleOnChange: PropTypes.func,
  checked: PropTypes.bool,
  headerLabel: PropTypes.string,
  rowLabel: PropTypes.string,
};

export default StyledCheckbox;
