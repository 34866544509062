import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import CircleIcon from '@mui/icons-material/Circle';
import { LinearProgress as LinearProgressBar } from '@mui/material';
import Grid from '@mui/material/Grid';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import theme from '../../theme/theme';

const legendTypographyPath = ['views', 'reporting', 'typography', 'chartLegendText'];
const titleTypographyPath = ['views', 'reporting', 'typography', 'metricsTitle'];

const Title = styled(Grid)(() => ({
  justifyContent: 'center',
  height: '10%',
  width: '100%',
  lineHeight: '24px',
  fontWeight: get([...titleTypographyPath, 'fontWeight'], theme),
  fontSize: get([...titleTypographyPath, 'fontSize'], theme),
  fontFamily: get([...titleTypographyPath, 'fontFamily'], theme),
  fontColor: get([...titleTypographyPath, 'fontColor'], theme),
}));

const StyledLinearProgress = styled(LinearProgressBar)(() => ({
  height: 32,

  borderRadius: 50,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: get(['views', 'reporting', 'linearProgress', 'notFilled'], theme),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: get(['views', 'reporting', 'linearProgress', 'filled'], theme),
  },
}));

const LegendTextGrid = styled(Grid)(() => ({
  lineHeight: '24px',
  fontWeight: get([...legendTypographyPath, 'fontWeight'], theme),
  fontSize: get([...legendTypographyPath, 'fontSize'], theme),
  fontFamily: get([...legendTypographyPath, 'fontFamily'], theme),
  fontColor: get([...legendTypographyPath, 'fontColor'], theme),
}));

const LinearProgress = ({ fillRatio = {}, staticData = [], titleText }) => (
  <>
    <Title container>{titleText}</Title>

    <Stack sx={{ height: '190px' }} justifyContent="center">
      <StyledLinearProgress variant="determinate" value={fillRatio.ratio} />
    </Stack>

    <Grid container height="25%" justifyContent="center">
      {staticData.map((item) => (
        <Grid key={item.label} item container justifyContent="space-between">
          <LegendTextGrid item alignItems="center">
            <CircleIcon
              sx={{ height: '8px', width: '8px', marginRight: '10px', color: item.color }}
            />
            {item.label}
          </LegendTextGrid>
          <LegendTextGrid item>
            {(item.label === 'Filled' && fillRatio.filled) ||
              (item.label === 'Open' && fillRatio.open) ||
              ''}
          </LegendTextGrid>
        </Grid>
      ))}
    </Grid>
  </>
);
LinearProgress.propTypes = {
  fillRatio: PropTypes.shape({}),
  staticData: PropTypes.arrayOf(PropTypes.shape({})),
  titleText: PropTypes.string,
};

export default LinearProgress;
