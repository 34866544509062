import React from 'react';
import PropTypes from 'prop-types';

import CheckBox from '../CheckBox/CheckBox';

import { errorHandler } from './formUtils';

const FormCheckBox = ({
  disabled,
  fieldErrorData,
  input,
  label,
  labelPlacement,
  meta,
  size,
  required,
  sx,
  variant,
}) => {
  const error = errorHandler(input, meta, fieldErrorData);
  return (
    <CheckBox
      checked={input.value || false}
      name={input.name}
      onChange={input.onChange}
      size={size}
      required={required}
      error={error}
      disabled={disabled}
      variant={variant}
      label={label}
      labelPlacement={labelPlacement}
      sx={sx}
      // defaultChecked commented for future use
    />
  );
};

FormCheckBox.propTypes = {
  disabled: PropTypes.bool,
  fieldErrorData: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
  }),
  label: PropTypes.string,
  meta: PropTypes.shape(),
  required: PropTypes.bool,
  labelPlacement: PropTypes.string,
  size: PropTypes.string, // default size is medium
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default FormCheckBox;
