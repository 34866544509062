import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { Box } from '@mui/material';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import { isWeekOT } from '../../helpers';
import FormSectionHeader from '../CustomComponents/FormSectionHeader';
import { daysOfWeekField } from '../FormFieldData/CreateJobFields';

const Cadence = ({ wrapperSx }) => {
  const formState = useFormState();
  const shouldErrorColor = isWeekOT(formState?.values);
  return (
    <Box sx={wrapperSx}>
      <FormSectionHeader messageKey="job.create.cadence.header" />
      <Box>{buildFormField(daysOfWeekField({ shouldErrorColor }))}</Box>
    </Box>
  );
};

Cadence.propTypes = { wrapperSx: PropTypes.shape({}) };

export default Cadence;
