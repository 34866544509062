const EMPTY_JOB = {
  position: {},
  location: {},
  shift: {},
  startInfo: {},
  numOpenings: '',
  locationOptions: [],
  shiftOptions: [],
  clockInTime: '',
  clockOutTime: '',
  jobInvites: [],
  isPosted: false,
  isNew: true,
};
export default EMPTY_JOB;
