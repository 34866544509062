import {
  epochToDateInReadableFormat,
  epochToDayOfWeek,
  isShiftCompleted,
  isShiftStarted,
} from '../../utils';

import {
  FIVE_AM_IN_MIN,
  JOB_ORDER_TIME_STATUSES,
  NINE_THIRTY_PM_IN_MIN,
  ONE_THIRTY_PM_IN_MIN,
} from './jobOrderConstants';

export const groupJobOrdersByStartDate = (jobOrders) =>
  jobOrders.reduce((acc, curr) => {
    const weekDay = epochToDayOfWeek(curr.start);
    const date = epochToDateInReadableFormat(curr.start);
    if (acc[date]) {
      acc[date].cards.push(curr);
    } else {
      acc[date] = { weekDay, cards: [curr] };
    }
    return acc;
  }, {});

export const getTimeStatus = (start, end) => {
  if (!isShiftStarted(start)) {
    return JOB_ORDER_TIME_STATUSES.UPCOMING;
  }
  if (isShiftCompleted(end)) {
    return JOB_ORDER_TIME_STATUSES.COMPLETED;
  }
  return JOB_ORDER_TIME_STATUSES.ACTIVE;
};

export const getUniqObjectsByKey = (array) => [
  ...new Map(array.map((item) => [item?.key, item])).values(),
];

export const getNewShiftFilters = (filters) => {
  if (filters.length === 1) {
    return filters;
  }
  if (filters.length === 2) {
    const startSum = filters[0].value.start + filters[1].value.start;

    const getValue = () => {
      if (startSum === FIVE_AM_IN_MIN + ONE_THIRTY_PM_IN_MIN) {
        return {
          start: FIVE_AM_IN_MIN,
          end: NINE_THIRTY_PM_IN_MIN,
        };
      }
      if (startSum === FIVE_AM_IN_MIN + NINE_THIRTY_PM_IN_MIN) {
        return {
          start: NINE_THIRTY_PM_IN_MIN,
          end: ONE_THIRTY_PM_IN_MIN,
        };
      }
      if (startSum === NINE_THIRTY_PM_IN_MIN + ONE_THIRTY_PM_IN_MIN) {
        return {
          start: ONE_THIRTY_PM_IN_MIN,
          end: FIVE_AM_IN_MIN,
        };
      }
      return {};
    };

    return [
      {
        field: filters[0].field,
        operation: filters[0].operation,
        value: { ...getValue(), zone: filters[0].value.zone },
      },
    ];
  }
  return [];
};
