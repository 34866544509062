import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';

import { createSlice } from '@reduxjs/toolkit';

import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../utils';
import { weekDays } from '../../../utils/timeConverter';
import { disablePunch, isActive, setPunchErrorMessage } from '../utils';

const reformatJobEntry = (jobEntry) => {
  const rest = omit(['_id'], jobEntry);
  return {
    id: get('_id', jobEntry),
    title: get('name', jobEntry),
    name: get('name', jobEntry),
    jobPlacements: get('placementsCount', jobEntry),
    address: get(['location', 'address'], jobEntry),
    startDate: epochToDateInReadableFormat(get('start', jobEntry)),
    startTime: epochToTimeInReadableFormat(get('start', jobEntry)),
    endDate: epochToDateInReadableFormat(get('end', jobEntry)),
    endTime: epochToTimeInReadableFormat(get('end', jobEntry)),
    fillRate: Math.floor((100 * get('placementsCount', jobEntry)) / get('numOpenings', jobEntry)),
    ...rest,
  };
};

const reformatGroupEntry = (groupEntry) => {
  const groupData = {
    ...groupEntry,
    _id: groupEntry._id,
    payRateReg: groupEntry.payRateReg,
    title: groupEntry.name,
    totalPay: groupEntry.totalPay,
    corporationName: groupEntry.corporation.name,
    corporationId: groupEntry.corporation._id,
  };
  return groupData;
};

const initialState = {
  candidatesJobs: [],
  activeJobs: [],
  isLoadingCandidatesJobs: false,
  featuredJobs: [],
  isLoadingFeaturedJobs: false,
  hasLoadedFeaturedJobs: false,
  industryJobs: [],
  isLoadingIndustryJobs: false,
  hasLoadedIndustryJobs: false,
  interestJobs: [],
  isLoadingInterestJobs: false,
  hasLoadedInterestJobs: false,
  isProcessingPunch: false,
  punchError: null,
  myTimeCard: [],
  totalsData: {
    totalHours: 0,
    totalEarnings: 0,
  },
};
export const candidateDashboardSlice = createSlice({
  name: 'candidateDashboardSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getCandidatesJobs: (state) => {
      state.isLoadingCandidatesJobs = true;
    },
    getCandidatesJobsProcessed: (state, action) => {
      state.isLoadingCandidatesJobs = false;
      const documents = get(['payload', 'data', 'documents'], action);
      const updatedData = documents.map((d) => {
        d.placement.jobOrder = reformatJobEntry(d.placement.jobOrder);
        return d;
      });
      state.candidatesJobs = updatedData;

      state.activeJobs = documents.slice(0, 2).map((shift, index) => ({
        placementId: get(['placement', '_id'], shift),
        disablePunch: disablePunch(documents, index),
        candidateIsPunchedIn: get(['candidateIsPunchedIn'], shift),
        isActive: isActive(
          get(['placement', 'jobOrder', 'start'], shift),
          get(['placement', 'jobOrder', 'end'], shift)
        ),
        placementHasPunches: get(['placementHasPunches'], shift),
        corporationName: get(['corporation', 'name'], shift),
        endTime: epochToTimeInReadableFormat(get(['placement', 'jobOrder', 'end'], shift)),
        shiftName: get(['placement', 'jobOrder', 'name'], shift),
        startDate: epochToDateInReadableFormat(get(['placement', 'jobOrder', 'start'], shift)),
        startTime: epochToTimeInReadableFormat(get(['placement', 'jobOrder', 'start'], shift)),
        gpsStrategy: get(['placement', 'jobOrder', 'gps', 'strategy'], shift),
      }));
    },

    getCandidatesJobsError: (state) => {
      state.isLoadingCandidatesJobs = false;
      state.candidatesJobs = [];
    },

    getFeaturedJobs: (state) => {
      state.isLoadingFeaturedJobs = true;
    },
    getFeaturedJobsProcessed: (state, action) => {
      state.isLoadingFeaturedJobs = false;
      state.hasLoadedFeaturedJobs = true;

      const { groups } = action.payload.data;
      const updatedData = groups.map((group) => reformatGroupEntry(group));
      state.featuredJobs = updatedData;
    },

    getFeaturedJobsError: (state) => {
      state.isLoadingFeaturedJobs = false;
      state.hasLoadedFeaturedJobs = true;
      state.featuredJobs = [];
    },

    getIndustryJobs: (state) => {
      state.isLoadingIndustryJobs = true;
    },
    getIndustryJobsProcessed: (state, action) => {
      state.isLoadingIndustryJobs = false;
      state.hasLoadedIndustryJobs = true;
      const { groups } = action.payload.data;
      const updatedData = groups.map((group) => reformatGroupEntry(group));
      state.industryJobs = updatedData;
    },

    getIndustryJobsError: (state) => {
      state.isLoadingIndustryJobs = false;
      state.hasLoadedIndustryJobs = true;
      state.industryJobs = [];
    },

    getInterestJobs: (state) => {
      state.isLoadingInterestJobs = true;
    },
    getInterestJobsProcessed: (state, action) => {
      state.isLoadingInterestJobs = false;
      state.hasLoadedInterestJobs = true;
      const { groups } = action.payload.data;
      const updatedData = groups.map((group) => reformatGroupEntry(group));
      state.interestJobs = updatedData;
    },

    getInterestJobsError: (state) => {
      state.hasLoadedInterestJobs = true;
      state.interestJobs = [];
    },

    postPunchForDashboard: (state) => {
      state.isProcessingPunch = true;
    },
    postPunchForDashboardProcessed: (state, action) => {
      state.isProcessingPunch = false;
      const punchedTimecard = action?.payload?.data?.placement?.timecard;
      const punchedTimecardId = action?.payload?.data?.placement?._id;
      const candidatePunches = punchedTimecard?.current?.punches;

      const nextShifts = state.activeJobs.map((item) =>
        item.placementId === punchedTimecardId
          ? {
              ...item,
              placementHasPunches: true,
              candidateIsPunchedIn: !candidatePunches?.[candidatePunches.length - 1]?.out?.stamp,
            }
          : item
      );

      const updatedNextShift = nextShifts.map((item, index) => ({
        ...item,
        disablePunch: disablePunch(nextShifts, index),
      }));

      state.activeJobs = updatedNextShift;
      state.punchError = null;
    },
    postPunchForDashboardError: (state, action) => {
      state.isProcessingPunch = false;
      state.punchError = setPunchErrorMessage(action?.payload);
    },
    setPunchError: (state, action) => {
      state.punchError = action.payload || null;
    },
    getMyTimeThisWeek: (state) => {
      state.isLoadingMyTimeCard = true;
    },
    getMyTimeThisWeekProcessed: (state, action) => {
      state.isLoadingMyTimeCard = false;
      const payload = get(['payload', 'data', 'documents'], action);
      if (payload && payload.length > 0) {
        const timeDataResp = payload[0];
        const timeCardData = [];
        const { days } = timeDataResp;

        const weekTotalHours = timeDataResp.totalHours;
        const weekTotalEarnings = timeDataResp.totalEarnings;
        if (days) {
          Object.keys(days).forEach((day) => {
            if (days[day].length > 0) {
              const d = new Date(day);
              const dayName = weekDays[d.getDay()];
              const updatedData = days[day].map((item) => ({
                placementId: `${get(['placement'], item)}`,
                date: day,
                day: `${dayName}`,
                earnings: get(['timecard', 'totalEarnings'], item) || 0.0,
                hours: get(['timecard', 'totalHours'], item) || 0.0,
              }));
              timeCardData.push(...updatedData);
            }
          });
        }
        state.myTimeCard = timeCardData;
        state.totalsData = {
          totalHours: weekTotalHours || 0.0,
          totalEarnings: weekTotalEarnings || 0.0,
        };
      }
    },
    getMyTimeThisWeekError: (state) => {
      state.isLoadingMyTimeCard = false;
    },
  },
});

export const candidateDashboardReducer = candidateDashboardSlice.reducer;

export const {
  getCandidatesJobs,
  getCandidatesJobsError,
  getCandidatesJobsProcessed,
  getFeaturedJobs,
  getFeaturedJobsError,
  getFeaturedJobsProcessed,
  getIndustryJobs,
  getIndustryJobsError,
  getIndustryJobsProcessed,
  getInterestJobs,
  getInterestJobsProcessed,
  getInterestJobsError,
  getMyTimeThisWeek,
  getMyTimeThisWeekProcessed,
  getMyTimeThisWeekError,
  postPunchForDashboard,
  postPunchForDashboardError,
  postPunchForDashboardProcessed,
  setPunchError,
} = candidateDashboardSlice.actions;
