import get from 'lodash/fp/get';

import theme from '../../theme';

const timeCardsPalette = get(['candidate', 'components', 'timeCards', 'palette'], theme);
const timeCardsTypography = get(['candidate', 'components', 'timeCards', 'typography'], theme);

const getActiveTimeCardStyles = (mode) => ({
  sxForShiftDate: {
    color: get(['shiftDateTextClr', `${mode}`], timeCardsPalette),
    ...get(['shiftDate'], timeCardsTypography),
  },
  sxForShiftTimeAndEarnings: {
    color: get(['shiftTimeAndEarningsTextClr', `${mode}`], timeCardsPalette),
    ...get(['shiftTimeAndEarnings'], timeCardsTypography),
  },
  sxForPositionName: {
    color: get(['positionNameClr', `${mode}`], timeCardsPalette),
    ...get(['positionName'], timeCardsTypography),
  },
  sxForCompanyName: {
    color: get(['companyNameClr', `${mode}`], timeCardsPalette),
    ...get(['companyName'], timeCardsTypography),
  },
  sxForPunches: {
    color: get(['punchesClr', `${mode}`], timeCardsPalette),
    ...get(['punches'], timeCardsTypography),
  },
  sxForCard: {
    backgroundColor: get(['backgroundColor', `${mode}`], timeCardsPalette),
    ...get(['candidate', 'components', 'timeCards', 'card'], theme),
  },
  sxForDisputeButtonText: {
    color: get(['disputeBtnTextClr', `${mode}`], timeCardsPalette),
    ...get(['disputeButtonText'], timeCardsTypography),
  },
  sxForNoPunchMessage: {
    color: get(['noPunchMessageClr', `${mode}`], timeCardsPalette),
    ...get(['noPunchMessageText'], timeCardsTypography),
  },
  sxForDisputeButton: {
    backgroundColor: get(['disputeBtnClr', `${mode}`], timeCardsPalette),
    '&:hover': {
      backgroundColor: get(['disputeBtnClr', `${mode}`], timeCardsPalette),
    },
    ...get(['candidate', 'components', 'timeCards', 'disputeButton'], theme),
  },
  sxForDisputeWarningCard: get(['disputeWarningCardBgClr'], timeCardsPalette),
  sxForDisputeWarningText: get(['disputeWarningText'], timeCardsTypography),
  sxForDisputeWarningStatusText: get(['disputeWarningStatusText'], timeCardsTypography),
});
export default getActiveTimeCardStyles;
