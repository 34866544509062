import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';

import { createSlice } from '@reduxjs/toolkit';

const reformatNotifications = (notifications) => {
  let hasUnreadNotifications = false;
  const processedNotifications = notifications.map((n) => {
    // TODO order by created date
    if (n.flags.read === false && !hasUnreadNotifications) {
      hasUnreadNotifications = true;
    }
    return { ...n, frontendSoftDeleted: false, unread: !n.flags.read };
  });

  return {
    notifications: sortBy((n) => n.metadata.created, processedNotifications).reverse() || [],
    hasUnreadNotifications,
  };
};

const initialState = {
  previousNotifications: [],
  notifications: [],
  isLoadingNotifications: false,
  hasUnreadNotifications: false,
};

export const notificationsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    // list of certification actions
    getNotifications: (state) => {
      state.isLoadingNotifications = true;
    },

    getNotificationsProcessed: (state, action) => {
      const notiResp = get(['payload', 'data', 'documents'], action);
      state.notifications = reformatNotifications(notiResp).notifications;
      state.hasUnreadNotifications = reformatNotifications(notiResp).hasUnreadNotifications;
      state.isLoadingNotifications = false;
    },

    getNotificationsError: (state) => {
      state.isLoadingNotifications = false;
    },

    setNotificationsToRead: (state, action) => {
      let hasUnread = false;
      state.notifications.forEach((n) => {
        if (action.payload.fields.notifications.includes(n._id)) {
          n.unread = false;
        }
      });
      state.notifications.every((n) => {
        if (n.unread) {
          hasUnread = true;
          return false;
        }
        return true;
      });

      state.hasUnreadNotifications = hasUnread;
    },

    setNotificationsToReadProcessed: (state) => {
      state.isSettingNotificationsToRead = false;
    },

    setNotificationsToReadError: (state) => {
      state.isSettingNotificationsToRead = false;
    },

    setNotificationFrontendSoftDelete: (state, action) => {
      state.notifications.forEach((n) => {
        if (action.payload === n._id) {
          n.frontendSoftDeleted = true;
        }
      });
    },

    setNotificationUndoSoftDelete: (state, action) => {
      state.notifications.forEach((n) => {
        if (action.payload === n._id) {
          n.frontendSoftDeleted = false;
        }
      });
    },
  },
});

export const notificationsReducer = notificationsSlice.reducer;

export const {
  getNotifications,
  getNotificationsProcessed,
  getNotificationsError,
  setNotificationsToRead,
  setNotificationsToReadProcessed,
  setNotificationsToReadError,
  setNotificationFrontendSoftDelete,
  setNotificationUndoSoftDelete,
} = notificationsSlice.actions;
