import theme from '../../theme';

const snackBarTheme = theme?.components?.snackBar;
export const backGroundStyling = {
  width: '100%',
  backgroundColor: snackBarTheme?.palette?.backGroundColor,
  justifyContent: 'center',
  alignItems: 'center',
  height: '61px',
  borderRadius: 2,
  padding: theme.spacing(1),
  margin: 'auto',
};

export const textStyling = {
  fontFamily: snackBarTheme?.typography?.fontFamily,
  fontSize: snackBarTheme?.typography?.fontSize,
  lineHeight: snackBarTheme?.typography?.lineHeight,
  color: snackBarTheme?.palette?.fontColor,
  margin: theme.spacing(0, 0, 0, 1),
};
