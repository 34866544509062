import React, { memo } from 'react';
import { get } from 'lodash/fp';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import CheckList from '../../../assets/icons/CheckList.svg';
import Work from '../../../assets/icons/Work.svg';
import BarChart from '../../../components/BarChart';
import LinearProgress from '../../../components/LinearProgress';
import {
  selectJobOrderMetricsLoadingState,
  selectJobOrdersMetrics,
} from '../../../store/selectors/reportingSelectors';
import theme from '../../../theme';
import { IconContentLabelCard } from '../metricsCards';
import ChartCard from '../metricsCards/ChartCard';
import MetricsHeader from '../metricsHeader';

const ChartWrapGrid = styled(Grid)(() => ({
  height: '100%',
  padding: '20px',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const roleColorPath = ['views', 'reporting', 'palette', 'role'];
const adminPrimaryColor = get([...roleColorPath, 'admin', 'primary'], theme);
const recruiterPrimaryColor = get([...roleColorPath, 'recruiter', 'primary'], theme);
const customerPrimaryColor = get([...roleColorPath, 'customer', 'primary'], theme);

const barsData = [
  { dataKey: 'admin', name: 'Admin', color: adminPrimaryColor },
  { dataKey: 'recruiter', name: 'Recruiter', color: recruiterPrimaryColor },
  { dataKey: 'employer', name: 'Employer', color: customerPrimaryColor },
];

const linearProgressStaticData = [
  { color: get(['views', 'reporting', 'linearProgress', 'filled']), label: 'Filled' },
  { color: get(['views', 'reporting', 'linearProgress', 'notFilled']), label: 'Open' },
];

const JobOrderMetrics = memo(() => {
  const { jobsEntered, jobsCompleted, fillRatio, timeBasedJobOrderNumbers } =
    useSelector(selectJobOrdersMetrics);
  const {
    loadingJobsEntered,
    loadingJobsCompleted,
    loadingFillRatio,
    loadingTimeBasedJobOrderNumbers,
  } = useSelector(selectJobOrderMetricsLoadingState);

  return (
    <Grid container item spacing={2} paddingBottom="16px">
      <MetricsHeader item text="Job Orders" />
      <Grid container item direction="column" xs={4} spacing={2}>
        {jobsEntered !== null && (
          <IconContentLabelCard
            data={jobsEntered}
            iconContentGap={2}
            iconSize={50}
            loading={loadingJobsEntered}
            staticData={{ icon: Work, label: 'Jobs Entered' }}
            sx={{ height: '160px' }}
          />
        )}
        {jobsCompleted !== null && (
          <IconContentLabelCard
            data={jobsCompleted}
            iconContentGap={2}
            iconSize={50}
            loading={loadingJobsCompleted}
            staticData={{ icon: CheckList, label: 'Jobs Completed' }}
            sx={{ height: '160px' }}
          />
        )}
      </Grid>
      <Grid container item xs={8} spacing={2}>
        <Grid item xs={12}>
          <ChartCard sx={{ height: '334px' }} loading={loadingFillRatio}>
            <ChartWrapGrid item xs>
              <LinearProgress
                fillRatio={fillRatio}
                staticData={linearProgressStaticData}
                titleText="Fill Ratio"
              />
            </ChartWrapGrid>
          </ChartCard>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ChartCard sx={{ height: '334px' }} loading={loadingTimeBasedJobOrderNumbers}>
          <ChartWrapGrid item xs>
            <BarChart
              barsData={barsData}
              data={timeBasedJobOrderNumbers}
              legend
              titleText="Job Orders Entered"
              xAxisDataKey="date"
              xAxisTickFontSize={14}
            />
          </ChartWrapGrid>
        </ChartCard>
      </Grid>
    </Grid>
  );
});

export default JobOrderMetrics;
