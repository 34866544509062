import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import FormCheckBox from '../../../../../components/Form/FormCheckBox';

const JobRangeActionBar = ({ input }) => {
  const theme = useTheme();
  const intl = useIntl();

  const themeObject = theme?.jobOrders?.createPage?.ongoingCheckBox;

  const customSx = {
    backgroundColor: themeObject?.backgroundColor,
    '& .MuiFormControlLabel-label': {
      color: themeObject?.typography.color,
      fontSize: themeObject?.typography.fontSize,
      fontFamily: themeObject?.typography.font,
    },
    '& .MuiCheckbox-root.Mui-checked': {
      color: themeObject?.checkboxColor,
    },
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(3),
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <FormCheckBox
        input={input}
        sx={customSx}
        label={intl.formatMessage({ id: 'job.create.ongoing' })}
        labelPlacement="start"
      />
    </Box>
  );
};
JobRangeActionBar.propTypes = {
  input: PropTypes.shape({}),
};

export default JobRangeActionBar;
