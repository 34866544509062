const rowValidator = (rows) => {
  if (rows && rows.length > 0) {
    for (let i = 0; i < rows.length; i += 1) {
      const job = rows[i];
      if (
        !job.startInfo.year ||
        !job.startInfo.month ||
        !job.startInfo.day ||
        Number.isNaN(job.numOpenings) ||
        job.numOpenings < 1 ||
        !job.location ||
        !job.location.id ||
        !job.shift ||
        !job.shift.id
      ) {
        return false;
      }
    }
    return true;
  }
  return false;
};
export default rowValidator;
