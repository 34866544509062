import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const MuiIconButton = ({
  color,
  dataTestId,
  disabled,
  label,
  iconElement,
  onClick,
  size,
  sx,
  labelSx,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}
  >
    <IconButton
      disabled={disabled}
      color={color}
      onClick={onClick}
      size={size}
      sx={sx}
      data-testid={dataTestId}
    >
      {iconElement}
    </IconButton>
    {label && <Typography sx={labelSx}>{label}</Typography>}
  </Box>
);

MuiIconButton.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  iconElement: PropTypes.element,
  onClick: PropTypes.func,
  size: PropTypes.string,
  sx: PropTypes.shape({}),
  labelSx: PropTypes.shape({
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    fontFamily: PropTypes.string,
    color: PropTypes.string,
  }),
};

export default MuiIconButton;
