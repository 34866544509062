import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

// eslint-disable-next-line react/prop-types
const WhenFieldChanges = ({ field, changeShouldOccur, set, to }) => (
  <Field name={set} subscription={{}}>
    {({ input: { onChange } }) => (
      <OnChange name={field}>
        {() => {
          if (changeShouldOccur) {
            onChange(to);
          }
        }}
      </OnChange>
    )}
  </Field>
);

WhenFieldChanges.propTypes = {
  field: PropTypes.string,
  changeShouldOccur: PropTypes.bool,
  set: PropTypes.string,
};

export default WhenFieldChanges;
