import { getOr } from 'lodash/fp';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  certifications: [],
  isLoadingCertifications: false,
  isLoadingUpdateSkills: false,
  userSkills: [],
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getUserInfo: (state) => {
      state.isLoadingGetUserInfo = true;
    },

    getUserInfoProcessed: (state, action) => {
      state.isLoadingGetUserInfo = false;
      state.userSkills = map(
        (sk) => ({
          id: get('skill', sk),
          ...sk,
        }),
        getOr([], ['data', 'documents', 0, 'candidate', 'skills'], action.payload)
      );
    },

    getUserInfoError: (state) => {
      state.isLoadingGetUserInfo = false;
    },

    // list of certification actions
    getCertificationsForSettings: (state) => {
      state.isLoadingCertifications = true;
    },

    getCertificationsForSettingsProcessed: (state, action) => {
      state.certifications = flow(
        get(['payload', 'data', 'documents']),
        map((cert) => ({
          id: get('_id', cert),
          ...cert,
        }))
      )(action);
      state.isLoadingCertifications = false;
    },

    getCertificationsForSettingsError: (state) => {
      state.isLoadingCertifications = false;
    },

    updateSkillsForSettings: (state) => {
      state.isLoadingUpdateSkills = true;
    },

    updateSkillsForSettingsProcessed: (state, action) => {
      state.userSkills = getOr([], 'skills', action.payload.data);
      state.isLoadingUpdateSkills = false;
    },

    updateSkillsForSettingsError: (state) => {
      state.isLoadingUpdateSkills = false;
    },

    /* eslint-disable no-param-reassign */
  },
});

export const settingsReducer = settings.reducer;

export const {
  getUserInfo,
  getUserInfoProcessed,
  getUserInfoError,
  getCertificationsForSettings,
  getCertificationsForSettingsError,
  getCertificationsForSettingsProcessed,
  updateSkillsForSettings,
  updateSkillsForSettingsProcessed,
  updateSkillsForSettingsError,
} = settings.actions;
