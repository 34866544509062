import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Drawer, LinearProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import theme from '../../theme';
import { convertTextToCSV } from '../../utils/exportHelpers';

const ExportView = ({
  isOpen,
  onClose,
  getSetData,
  resultToCSVTextConverter,
  fileName,
  progress,
  setProgress,
}) => {
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    if (isOpen && !exporting && progress <= 0) {
      setExporting(true);
      setProgress(0);

      getSetData().then((data) => {
        convertTextToCSV({
          csvRowsAsText: resultToCSVTextConverter(data.filter(Boolean)),
          fileName,
        });
        setExporting(false);
      });
    }
  }, [exporting, fileName, getSetData, isOpen, progress, resultToCSVTextConverter, setProgress]);

  const styleForHeader = {
    fontFamily: theme.export.typography.boldFont,
    marginBottom: theme.spacing(6),
    fontSize: 32,
  };

  const styleForWorkingWrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  };

  const styleForWorkingTextBox = {
    display: 'flex',
    width: '80%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  };

  const styleForWorkingText = {
    fontFamily: theme.export.typography.boldFont,
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={exporting ? null : onClose}
      sx={{ zIndex: '2000' }}
      PaperProps={{ sx: { maxWidth: '576px', minWidth: '300px' } }}
    >
      <Box
        aria-label="close"
        onClick={exporting ? null : onClose}
        sx={{
          width: '100%',
          justifyContent: 'end',
          display: 'flex',
          cursor: 'pointer',
          padding: theme.spacing(1),
        }}
      >
        <CloseRoundedIcon />
      </Box>
      <Box md={4} sx={{ height: '100%', width: '100%' }}>
        <Box sx={styleForWorkingWrapper}>
          <Typography sx={styleForHeader}>
            {LanguageConverter(progress < 1 ? 'export.exporting' : 'export.complete')}
          </Typography>

          <Box sx={styleForWorkingTextBox}>
            <Typography sx={styleForWorkingText}>{LanguageConverter('export.progress')}</Typography>
            <Typography sx={styleForWorkingText}>
              {progress < 1 ? `${(progress * 100).toFixed(2)}%` : '100%'}
            </Typography>
          </Box>
          <Box sx={{ width: '80%' }}>
            <LinearProgress
              variant="determinate"
              value={progress < 1 ? (progress * 100).toFixed(2) : 100}
            />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
ExportView.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  getSetData: PropTypes.func,
  resultToCSVTextConverter: PropTypes.func,
  fileName: PropTypes.string,
  progress: PropTypes.number,
  setProgress: PropTypes.func,
};
export default ExportView;
