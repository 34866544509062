import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Drawer from '@mui/material/Drawer';

import { epochToTimeInReadableFormat } from '../../utils';
import WorkFlow from '../WorkFlow';

import { formFieldData, readOnlyValues } from './FormFieldData';

const TimekeepingEditDrawer = ({
  isDrawerOpen,
  shift,
  onSubmit,
  onClose,
  timecardIsUpdating,
  errorMessage,
}) => {
  const punches = shift?.timecard?.punches || [];

  const initialData = {
    ...readOnlyValues,
    corporation: shift?.corporation,
    order: shift?.order,
    shiftName: shift?.shiftName,
    shiftTime: `${epochToTimeInReadableFormat(shift?.start)} - ${epochToTimeInReadableFormat(
      shift?.end
    )}`,
    punches:
      punches.length > 0 ? punches : [{ in: { stamp: shift?.start }, out: { stamp: shift?.end } }],
  };
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '576px',
        },
      }}
    >
      <WorkFlow
        confirmationPageButtonText={LanguageConverter('button.return.timekeeping')}
        formSpec={formFieldData}
        attemptToHandleAPICall={onSubmit}
        onClose={onClose}
        readOnlyValues={initialData}
        isApiCallProcessing={timecardIsUpdating}
        processingMessage="message.generic.updating"
        successMessage="success.timecard.edit"
        errorMessage={errorMessage}
      />
    </Drawer>
  );
};

TimekeepingEditDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool,
  shift: PropTypes.shape({
    placement: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    timecard: PropTypes.shape({
      punches: PropTypes.shape([]),
    }),
    corporation: PropTypes.string,
    order: PropTypes.string,
    shiftName: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  timecardIsUpdating: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default TimekeepingEditDrawer;
