import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { BUTTON_TYPE } from '../../components/Button';
import Form from '../../components/Form';
import FormPasswordField from '../../components/Form/FormPasswordField';
import {
  composeValidators,
  containAtLeastOneCapitalLetter,
  containAtMinimumTwoLowerCaseLetters,
  containAtMinimumTwoNumbers,
  containsAtMinimumOneSpecialCharacter,
  isAtMinimumEightCharactersLong,
} from '../../components/Form/validations';
import selectIsLoading from '../../store/selectors/passwordUpdateSelectors';
import { showSnackbar } from '../app/reducer';

import { updatePassword } from './reducer';

const PasswordUpdate = ({ cancelFunction }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const handleOnSubmitLogUpdatePassword = (values) => {
    const { oldPassword, newPassword, confirmNewPassword } = values;
    if (newPassword === confirmNewPassword) {
      const OldAndNewPassword = { oldPassword, newPassword };
      dispatch(updatePassword(OldAndNewPassword));
    } else {
      dispatch(showSnackbar({ message: 'Confirmation password does not match' }));
    }
  };

  const buttonData = [
    {
      onClick: () => cancelFunction(),
      fullWidth: true,
      text: 'Cancel',
      showIndeterminateProgress: !!isLoading,
      disabled: !!isLoading,
    },
    {
      fullWidth: true,
      text: 'Save Password',
      showIndeterminateProgress: !!isLoading,
      type: BUTTON_TYPE.SUBMIT,
      disabled: !!isLoading,
    },
  ];

  const formFieldData = [
    {
      ComponentType: FormPasswordField,
      label: 'Current Password',
      name: 'oldPassword',
      required: true,
      type: 'password',
      fullWidth: true,
    },
    {
      ComponentType: FormPasswordField,

      label: 'New Password',
      name: 'newPassword',
      required: true,
      type: 'password',
      fullWidth: true,
      validate: composeValidators(
        isAtMinimumEightCharactersLong,
        containAtLeastOneCapitalLetter,
        containAtMinimumTwoNumbers,
        containsAtMinimumOneSpecialCharacter,
        containAtMinimumTwoLowerCaseLetters
      ),
    },
    {
      ComponentType: FormPasswordField,
      label: 'Confirm New Password',
      name: 'confirmNewPassword',
      required: true,
      type: 'password',
      fullWidth: true,
    },
  ];

  return (
    <Form
      buttonData={buttonData}
      formFieldData={formFieldData}
      onSubmit={(values) => handleOnSubmitLogUpdatePassword(values)}
    />
  );
};

PasswordUpdate.propTypes = {
  cancelFunction: PropTypes.func,
};
export default PasswordUpdate;
