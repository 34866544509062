import React, { useEffect, useState } from 'react';
import { get, map } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import List from '@mui/material/List';

import { apiPost } from '../../../../../datahub/axios';
import LazyList from '../../../../components/LazyList/LazyList';
import selectUser from '../../../../store/selectors/appSelector';
import {
  selectedCompletedCount,
  selectedUpcomingCount,
} from '../../../../store/selectors/userSelectors';
import theme from '../../../../theme';
import { epochToDateInReadableFormat, epochToTimeInReadableFormat } from '../../../../utils';

import JobActivityCard from './JobActivityCard';
import JobActivityNoDataCard from './JobActivityNoDataCard';

const buildPlacementFilters = (candidateID) => [
  {
    field: 'state.status',
    operation: 'equals',
    value: 'active', // TODO: HB-2653 - Move to constants file from WAE Common.
  },
  {
    field: 'candidate._id',
    operation: 'equalsID',
    value: candidateID,
  },
];

const JobActivity = ({ initialValues }) => {
  const user = useSelector(selectUser);
  const token = get('token', user);

  const [upcomingPlacements, setUpcomingPlacements] = useState([]);
  const [completedPlacements, setCompletedPlacements] = useState([]);

  const [upcomingPage, setUpcomingPage] = useState(0);
  const [completedPage, setCompletedPage] = useState(0);

  const [numTotalUpcoming, setNumTotalUpcoming] = useState(useSelector(selectedUpcomingCount));
  const [numTotalCompleted, setNumTotalCompleted] = useState(useSelector(selectedCompletedCount));

  const upcomingHeight = completedPlacements && completedPlacements.length ? '35vh' : '65vh';
  const completedHeight = upcomingPlacements && upcomingPlacements.length ? '35vh' : '65vh';

  const getNextPlacements = async (
    currentPlacements,
    filters,
    nextPage,
    setNextPage,
    setPlacements,
    setNumTotalPlacements,
    sortBy
  ) => {
    const payloadData = {
      page: nextPage,
      limit: 10,
      filters,
      sortBy,
    };

    const { data } = await apiPost('placements/read', payloadData, token);

    const { count, documents } = data;
    const returnedPlacements = map((placement) => {
      const { corporation, _id, id, jobOrder } = placement;
      const { start, end } = jobOrder || {};

      const jobDate = `${epochToDateInReadableFormat(start)}-${epochToDateInReadableFormat(end)}`;
      const jobShift = `${epochToTimeInReadableFormat(start)}-${epochToTimeInReadableFormat(end)}`;
      return {
        id: id || _id,
        name: get(['name'], jobOrder),
        corporation: get(['name'], corporation),
        jobDate,
        jobShift,
      };
    }, documents);

    const newUpdatedPlacementSet = [...currentPlacements, ...returnedPlacements];

    setPlacements(newUpdatedPlacementSet);
    setNextPage(nextPage + 1);

    setNumTotalPlacements(count);
  };

  const getNextUpcoming = async () => {
    getNextPlacements(
      upcomingPlacements,
      [
        ...buildPlacementFilters(initialValues.id),
        {
          operation: 'after',
          field: 'end',
          value: +new Date(),
        },
      ],
      upcomingPage,
      setUpcomingPage,
      setUpcomingPlacements,
      setNumTotalUpcoming,
      [{ field: 'start', descending: false }]
    );
  };

  const getNextCompleted = async () => {
    getNextPlacements(
      completedPlacements,
      [
        ...buildPlacementFilters(initialValues.id),
        {
          operation: 'before',
          field: 'end',
          value: +new Date(),
        },
      ],
      completedPage,
      setCompletedPage,
      setCompletedPlacements,
      setNumTotalCompleted,
      [{ field: 'start', descending: true }]
    );
  };

  useEffect(() => {
    getNextUpcoming();
    getNextCompleted();
    // FIXME: Find a way to do this w/out disable linter - HB-2032
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          color: theme.components.detailView.typography.sectionHeader.fontColor,
          fontFamily: theme.components.detailView.typography.sectionHeader.fontFamily,
          fontSize: '18px',
        }}
      >
        {' '}
        Upcoming Jobs
      </Box>
      <List
        component="nav"
        aria-label="secondary mailbox folder"
        sx={{
          maxHeight: upcomingHeight,
          overflowY: 'scroll',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <LazyList
          Card={JobActivityCard}
          getNextData={getNextUpcoming}
          items={upcomingPlacements}
          itemCount={numTotalUpcoming || 1}
          skeletonVariant="Text"
          skeletonBackgroundColor={theme.components.detailView.palette.sectionBackgroundColor}
          backgroundColor={theme.components.detailView.palette.backgroundColor}
          NoDataCard={JobActivityNoDataCard}
        />
      </List>

      <Box
        sx={{
          color: theme.components.detailView.typography.sectionHeader.fontColor,
          fontFamily: theme.components.detailView.typography.sectionHeader.fontFamily,
          fontSize: '18px',
          marginTop: '2vh',
        }}
      >
        {' '}
        Completed Jobs
      </Box>
      <List
        component="nav"
        aria-label="secondary mailbox folder"
        sx={{
          maxHeight: completedHeight,
          overflowY: 'scroll',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <LazyList
          Card={JobActivityCard}
          getNextData={getNextCompleted}
          items={completedPlacements}
          itemCount={numTotalCompleted || 1}
          skeletonVariant="Text"
          skeletonBackgroundColor={theme.components.detailView.palette.sectionBackgroundColor}
          backgroundColor={theme.components.detailView.palette.backgroundColor}
          NoDataCard={JobActivityNoDataCard}
        />
      </List>
    </Box>
  );
};

JobActivity.propTypes = {
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    id: PropTypes.string,
  }),

  jobActivities: PropTypes.shape({
    userUpcomingJobs: PropTypes.arrayOf(PropTypes.shape({})),
    userCompletedJobs: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default JobActivity;
