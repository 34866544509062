import React from 'react';
import getOr from 'lodash/fp/getOr';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getStyles } from './styles';

const CadenceField = ({ background, data, input, meta, size, testId, shouldErrorColor }) => {
  const { onChange } = input;
  const theme = useTheme();
  const intl = useIntl();

  const value = input?.value || {};

  const handleOnClick = (val) => {
    onChange({ ...value, [val]: !value[val] });
  };

  const isBoxSelected = (box) => !!value[box?.value];

  const shouldShowError = meta?.error && (meta.submitFailed || meta.modified || meta.touched);

  const { wrapperSx, selectedBoxSx, unSelectedBoxSx, labelSx, helperTextSx, textWrapper } =
    getStyles({
      theme,
      error: shouldErrorColor || shouldShowError,
      isMobile: true,
    });

  return (
    <>
      <Grid data-testid={testId} container style={wrapperSx}>
        {data &&
          data.map((d) => (
            <Grid
              item
              background={background}
              key={d.label}
              xs={1.5}
              onClick={() => handleOnClick(d.value)}
              value={getOr(false, d.value, input.value)}
              size={size}
              sx={isBoxSelected(d) ? selectedBoxSx : unSelectedBoxSx}
              data-testid={d.label}
            >
              <Grid container sx={textWrapper}>
                <Typography sx={labelSx}>{d?.label}</Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
      {shouldShowError && (
        <Typography sx={helperTextSx}>{intl.formatMessage({ id: meta?.error })}</Typography>
      )}
    </>
  );
};
CadenceField.propTypes = {
  background: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.shape({}),
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool,
    modified: PropTypes.bool,
    touched: PropTypes.bool,
  }),
  size: PropTypes.string,
  sx: PropTypes.shape({}),
  testId: PropTypes.string,
  shouldErrorColor: PropTypes.bool,
};

export default CadenceField;
