import { get } from 'lodash/fp';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';

import theme from '../../../theme/theme';

export const textFieldBackground = get(['textField', 'inputLabel', 'primary', 'light'], theme);
export const textFieldBackgroundHover = get(['textField', 'background', 'light'], theme);
export const textFieldFocusedBorder = get(['textField', 'borderColor', 'focused'], theme);
export const textFieldErrorBorder = get(['textField', 'borderColor', 'error'], theme);
export const textFieldPrimaryLabel = get(['textField', 'inputLabel', 'primary', 'focused'], theme);
export const styleForTextField = {
  '& legend': { display: 'none' },
  '& fieldset': { top: 0 },
  '& .MuiOutlinedInput-root': {
    backgroundColor: textFieldBackground,
    borderRadius: '40px',
    fontSize: '14px',
    fontFamily: 'Barlow',
    '&:hover': {
      backgroundColor: textFieldBackgroundHover,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    outline: `1px solid ${textFieldFocusedBorder}`,
    backgroundColor: textFieldBackground,
  },
  '& .MuiOutlinedInput-root.Mui-error': {
    outline: `1px solid ${textFieldErrorBorder}`,
  },
  '& .MuiInputLabel-root': {
    top: '-8px',
    color: textFieldPrimaryLabel,
  },
  '& .MuiInputLabel-root.Mui-error': {
    color: textFieldPrimaryLabel,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiFormLabel-asterisk.Mui-error': {
    color: textFieldPrimaryLabel,
  },
  '& .MuiInputBase-root': {
    color: textFieldPrimaryLabel,
  },
  '& .MuiInputBase-root.Mui-focused': {
    fontWeight: 500,
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1),
  },
};

export const PunchInputGrid = styled(Grid)(() => ({
  justifyContent: 'flex-start',
  width: '100%',
}));

export const StyledList = styled(List)(() => ({
  display: 'flex',
  borderRadius: '16px',
  padding: '5px',
}));
