import * as React from 'react';

import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';

const FormRating = () => (
  <Box sx={{ marginTop: '-20px' }}>
    <Rating name="read-only" value={4} disabled />
  </Box>
);

export default FormRating;
