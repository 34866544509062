import { get } from 'lodash/fp';

const branchOptionNameList = {
  albanyGeneralStaffing: 'Albany General Staffing',
  buffaloGeneralStaffing: 'Buffalo General Staffing',
  proflex: 'Proflex',
  rochesterGeneralStaffing: 'Rochester General Staffing',
  rochesterMedical: 'Rochester Medical',
  syracuseGeneralStaffing: 'Syracuse General Staffing',
};

const corporationNames = {
  careerStart: 'Career Start',
  tallavera: 'Tallavera',
};

const payGroupNameList = {
  W1: 'W1',
  W2: 'W2',
  W3: 'W3',
};

export const branchOptions = (intl) => [
  {
    id: get('albanyGeneralStaffing', branchOptionNameList),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.albanyGeneralStaffing',
    }),
    value: get('albanyGeneralStaffing', branchOptionNameList),
  },
  {
    id: get('buffaloGeneralStaffing', branchOptionNameList),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.buffaloGeneralStaffing',
    }),
    value: get('buffaloGeneralStaffing', branchOptionNameList),
  },
  {
    id: get('proflex', branchOptionNameList),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.proflex',
    }),
    value: get('proflex', branchOptionNameList),
  },
  {
    id: get('rochesterGeneralStaffing', branchOptionNameList),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.rochesterGeneralStaffing',
    }),
    value: get('rochesterGeneralStaffing', branchOptionNameList),
  },
  {
    id: get('rochesterMedical', branchOptionNameList),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.rochesterMedical',
    }),
    value: get('rochesterMedical', branchOptionNameList),
  },
  {
    id: get('syracuseGeneralStaffing', branchOptionNameList),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.syracuseGeneralStaffing',
    }),
    value: get('syracuseGeneralStaffing', branchOptionNameList),
  },
];

export const legalBusEntryOptions = (intl) => [
  {
    id: get('careerStart', corporationNames),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.careerStart',
    }),
    value: get('careerStart', corporationNames),
  },
  {
    id: get('tallavera', corporationNames),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.tallavera',
    }),
    value: get('tallavera', corporationNames),
  },
];

export const benefitsPackOptions = (intl) => [
  {
    id: get('careerStart', corporationNames),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.careerStart',
    }),
    value: 1,
  },
  {
    id: get('tallavera', corporationNames),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.tallavera',
    }),
    value: 3,
  },
];

export const payGroupOptions = (intl) => [
  {
    id: get('W1', payGroupNameList),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.W1',
    }),
    value: get('W1', payGroupNameList),
  },
  {
    id: get('W2', payGroupNameList),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.W2',
    }),
    value: get('W2', payGroupNameList),
  },
  {
    id: get('W3', payGroupNameList),
    name: intl.formatMessage({
      id: 'page.positionTemplateDetail.form.field.options.W3',
    }),
    value: get('W3', payGroupNameList),
  },
];
