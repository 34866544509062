import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import Calendar from '../../../assets/icons/Calendar.svg';
import KeyboardArrowDownIcon from '../../../assets/icons/KeyboardArrowDownIcon.svg';
import theme from '../../../theme';

const DateDisplayBox = ({ data }) => (
  <Grid
    item
    container
    justifyContent="space-between"
    alignItems="center"
    sx={{
      height: '40px',
      width: 'auto',
      backgroundColor: get(['timeRangePickerFilter', 'dateDisplayBox', 'bgColor'], theme),
      borderRadius: '40px',
      margin: '16px',
      marginBottom: 0,
      paddingRight: '16px',
      paddingLeft: '16px',
    }}
  >
    <Typography sx={get(['timeRangePickerFilter', 'dateDisplayBox', 'typography'], theme)}>
      {data}
    </Typography>
    <Box
      component="img"
      sx={{
        height: 24,
        width: 24,
      }}
      alt="Calendar"
      src={Calendar}
    />
  </Grid>
);

DateDisplayBox.propTypes = {
  data: PropTypes.string,
};

const TimeRangePickerFilter = ({ onValueChange, field, operation, placeholder, wrapperSx }) => {
  const initValues = { start: null, end: null };
  const [timeRange, setTimeRange] = useState(initValues);
  const [anchorEl, setAnchorEl] = useState(null);

  const readableEndTime = (val) =>
    val
      ? new Date(val).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        })
      : null;
  const dateRangeDisplayValue =
    (timeRange.start &&
      timeRange.end &&
      `${readableEndTime(timeRange.start)} to ${readableEndTime(timeRange.end)}`) ||
    (timeRange.start && `${readableEndTime(timeRange.start)}`) ||
    '';
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleTimeChange = (time) => {
    const updatedTimeRange = { ...timeRange, ...time };
    setTimeRange(updatedTimeRange);

    const start = new Date(updatedTimeRange.start);
    const end = new Date(updatedTimeRange.end);

    if (
      (updatedTimeRange.start && updatedTimeRange.end) ||
      (!updatedTimeRange.start && !updatedTimeRange.end)
    ) {
      // if is to handle case for filter add/removal
      const newValue = [
        {
          value: updatedTimeRange.start
            ? { hour: start.getHours(), minute: start.getMinutes() }
            : null,
          field: field.start,
          operation: operation.start,
        },
        {
          value: updatedTimeRange.end ? { hour: end.getHours(), minute: end.getMinutes() } : null,
          field: field.end,
          operation: operation.end,
        },
      ];

      onValueChange(newValue);
    }
  };

  const handleClick = (event) => {
    const currentTarget = get(['currentTarget'], event);
    setAnchorEl(anchorEl ? null : currentTarget);
  };

  const styleForFormControl = (val) => ({
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '&:hover': {
      backgroundColor: !val && get(['timeRangePickerFilter', 'bgColor', 'hover'], theme),
    },
    '&:focus-within': {
      backgroundColor: !val && get(['timeRangePickerFilter', 'bgColor', 'focus'], theme),
      border: `1px solid ${get(['timeRangePickerFilter', 'borderColor', 'focus'], theme)}`,
    },
    backgroundColor: val && get(['timeRangePickerFilter', 'bgColor', 'complete'], theme),

    borderRadius: '40px',
    justifyContent: 'center',
    height: '40px',
    width: '300px',
    marginLeft: '8px',
  });

  return (
    <>
      <TextField
        autoComplete="off"
        aria-describedby={id}
        sx={{ ...styleForFormControl(timeRange.start || timeRange.end), ...wrapperSx }}
        value={dateRangeDisplayValue}
        InputProps={{
          onClick: handleClick,
          endAdornment: (
            <Box sx={{ display: 'flex', alignItems: 'center', direction: 'row' }}>
              <>
                {timeRange.start && (
                  <Chip
                    label={(timeRange.start && timeRange.end && 2) || (timeRange.start && 1) || ''}
                    sx={{
                      height: '22px',
                      width: '35px',
                      backgroundColor: get(['timeRangePickerFilter', 'chipBgColor'], theme),
                    }}
                  />
                )}
                {dateRangeDisplayValue && (
                  <IconButton size="small" onClick={() => handleTimeChange(initValues)}>
                    <CloseIcon sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
                )}
                <Box
                  component="img"
                  sx={{
                    height: 22,
                    width: 22,
                  }}
                  alt="Stars"
                  src={KeyboardArrowDownIcon}
                />
              </>
            </Box>
          ),
        }}
        placeholder={placeholder}
      />

      <Popper open={open} placement="bottom-end" anchorEl={anchorEl} id={id}>
        <ClickAwayListener onClickAway={handleClick}>
          <Paper sx={{ borderRadius: '16px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: 'Check-in' }}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                sx={{ height: '200px', width: '300px', padding: '8px' }}
              >
                <Grid item container margin="8px" direction="column">
                  <Typography sx={{ paddingLeft: '22px', fontSize: '14px' }}>Start time</Typography>
                  <DesktopTimePicker
                    value={timeRange.start}
                    onChange={(val) => handleTimeChange({ start: val })}
                    renderInput={(startProps) => (
                      <TextField
                        {...startProps}
                        sx={{ ...styleForFormControl(timeRange.start), width: '250px' }}
                      />
                    )}
                  />
                </Grid>
                <Grid item container margin="8px" direction="column">
                  <Typography sx={{ paddingLeft: '22px', fontSize: '14px' }}>End time</Typography>
                  <DesktopTimePicker
                    value={timeRange.end}
                    onChange={(val) => handleTimeChange({ end: val })}
                    renderInput={(endProps) => (
                      <TextField
                        {...endProps}
                        sx={{ ...styleForFormControl(timeRange.end), width: '250px' }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default TimeRangePickerFilter;
TimeRangePickerFilter.propTypes = {
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  field: PropTypes.shape(),
  operation: PropTypes.shape(),
  initialValue: PropTypes.arrayOf(PropTypes.number),
  wrapperSx: PropTypes.shape({}),
};
