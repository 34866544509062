import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import { Box, Modal } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import asyncService from '../../../datahub/asyncService';
import selectUser from '../../store/selectors/appSelector';
import theme from '../../theme';
import { BLACK } from '../../theme/colorConstants';
import Button from '../Button';
import CandidatePickerCard from '../CandidatPickerCard/CandidatePickerCard';
import { getUniqueFiltersV2 } from '../Filters/helpers';
import EmployeeInviteSelectionFilter from '../Form/FormEmployeeInviteSelection/EmployeeInviteSelectionFilter';
import { generateCandidateSelectionListOnUserRead } from '../Form/FormEmployeeInviteSelection/helpers';
import { selectionTextStyling } from '../Form/FormEmployeeInviteSelection/styling';

const InviteCandidateModal = ({
  isOpen,
  onClose,
  onSubmit,
  header,
  subHeader,
  submitButtonText,
  text,
  isMultiSelect,
}) => {
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [candidatesLists, setCandidatesList] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const user = useSelector(selectUser);
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = user?.token;
  const refreshToken = user?.refreshToken;

  const handleCardOnClick = useCallback(
    (candidate) => {
      const isSelected = selectedCandidates.some((val) => val._id === candidate._id);
      if (!isMultiSelect) {
        setSelectedCandidates(isSelected ? [] : [candidate]);
      } else {
        setSelectedCandidates(
          isSelected
            ? selectedCandidates.filter((val) => val._id !== candidate._id)
            : [...selectedCandidates, candidate]
        );
      }
    },
    [selectedCandidates, isMultiSelect]
  );

  const handleOnRightFiltersChange = useCallback((newFilters) => {
    setSearchFilters((prevVal) => ({
      ...prevVal,
      page: 0,
      rightFilters: getUniqueFiltersV2(prevVal.rightFilters, newFilters),
    }));
  }, []);

  const handleAddEmployees = () => {
    onSubmit(selectedCandidates[0]?._id);
    setSearchFilters([]);
    onClose();
  };

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      const candidateRoleFilter = {
        field: 'role',
        operation: 'equals',
        value: UserRole.CANDIDATE,
      };

      const searchData = {
        filters: [candidateRoleFilter, ...(searchFilters.rightFilters || [])], // candidate filter + any custom filters
        sortBy: [
          {
            field: 'name',
            descending: false,
          },
        ],
      };
      setCandidatesList([]);
      asyncService({
        httpMethod: 'POST',
        route: 'users/read',
        data: searchData,
        onSuccess: (d) => {
          const rawApiQueryResult = d?.data?.documents;
          const apiResultDocuments = rawApiQueryResult;
          setCandidatesList(apiResultDocuments);
        },
        onError: () => {
          setCandidatesList([]);
        },
        dispatch,
        token,
        refreshToken,
      });
    }, 750);

    return () => clearTimeout(delayedSearch);
  }, [dispatch, token, refreshToken, searchFilters]);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          width: { xs: '90vw', sm: '80vw', md: '50vw' }, // Responsive width
          height: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 3,
          boxShadow: 24,
          p: 1,
          maxHeight: '90vh',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Typography
          sx={{
            color: theme?.jobOrders?.createPage?.header?.fontColor,
            fontSize: theme?.jobOrders?.createPage?.header?.fontSize,
            fontFamily: theme?.jobOrders?.createPage?.header?.fontFamily,
            lineHeight: theme?.jobOrders?.createPage?.header?.lineHeight,
            padding: theme.spacing(3, 2, 0, 2),
            textAlign: 'left',
            overflowWrap: 'break-word',
          }}
        >
          {header}
        </Typography>
        <Typography
          sx={{
            color: theme?.jobOrders?.createPage?.subHeader?.fontColor,
            fontSize: theme?.jobOrders?.createPage?.subHeader?.fontSize,
            fontFamily: theme?.jobOrders?.createPage?.subHeader?.font,
            padding: theme.spacing(3, 2, 0, 2),
            textAlign: 'left',
            overflowWrap: 'break-word',
          }}
        >
          {subHeader}
        </Typography>
        <Box sx={{ padding: theme.spacing(0, 0, 0, 2) }}>
          <EmployeeInviteSelectionFilter onRightFilterChange={handleOnRightFiltersChange} />
        </Box>
        {candidatesLists && candidatesLists.length > 0 ? (
          <Box
            sx={{
              border: `1px solid ${BLACK[40]}`,
              borderRadius: 3,
              overflow: 'hidden',
              mx: 2,
            }}
          >
            <Box
              sx={{
                padding: '20px',

                boxSizing: 'border-box',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
                grid: '10px',
                gap: '5px',
                height: '58vh',
                overflow: 'scroll',
                overflowX: 'hidden',
              }}
            >
              {selectedCandidates.map((candidate) => (
                <Box key={candidate?._id || candidate?.id}>
                  <CandidatePickerCard
                    isSelected
                    user={{
                      name: candidate?.name,
                      favorite: candidate?.favorite,
                      _id: candidate?._id || candidate?.id,
                      reliabilityScore: candidate?.reliabilityScore,
                    }}
                    sx={{
                      margin: theme.spacing(0, 0, 1, 1.25),
                    }}
                    onClick={handleCardOnClick}
                    key={candidate?._id || candidate?.id}
                  />
                </Box>
              ))}
              {generateCandidateSelectionListOnUserRead(selectedCandidates, candidatesLists).map(
                (candidate) => (
                  <Box key={candidate?._id || candidate?.id}>
                    <CandidatePickerCard
                      user={{
                        name: candidate?.name,
                        favorite: candidate?.favorite,
                        _id: candidate?._id || candidate?.id,
                        reliabilityScore:
                          (candidate?.candidate?.metrics?.reliabilityScore &&
                            `${Math.round(candidate.candidate.metrics.reliabilityScore * 100)}%`) ||
                          `0%`,
                      }}
                      sx={{
                        margin: theme.spacing(0, 0, 1, 1.25),
                      }}
                      onClick={handleCardOnClick}
                      key={candidate?._id || candidate?.id}
                      selectedCandidateNum={selectedCandidates?.length}
                    />
                  </Box>
                )
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              border: `1px solid ${BLACK[40]}`,
              padding: '40px',
              margin: '0 20px',
              mx: 2,
              boxSizing: 'border-box',
              overflow: 'auto',
              display: 'flex',
              borderRadius: 3,
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(2),
          }}
        >
          <Typography
            sx={selectionTextStyling}
          >{`${selectedCandidates.length}  ${text}`}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: 2,
            marginTop: '2vh',
          }}
        >
          <Button
            text={intl.formatMessage({ id: 'button.goBack' })}
            onClick={onClose}
            sx={{
              color: theme?.button?.palette?.purpleText,
              backgroundColor: theme?.button?.palette?.greyBgColor,
              border: `1px solid ${BLACK[40]}`,
            }}
          />
          <Button
            disabled={selectedCandidates?.length === 0}
            text={submitButtonText}
            onClick={handleAddEmployees}
            sx={{
              backgroundColor:
                theme?.components?.createJobConfirmationModal?.palette?.postButton?.color,
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

InviteCandidateModal.propTypes = {
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  submitButtonText: PropTypes.string,
  text: PropTypes.string,
  isMultiSelect: PropTypes.bool,
};

export default InviteCandidateModal;
