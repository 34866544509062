import * as React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../../../theme';

const detailTheme = get(['candidate', 'components', 'groupDetails'], theme);
const sxForWrapper = {
  display: 'flex',
  width: '100%',
};

const sxForIsCertIcon = {
  marginRight: theme.spacing(1),
  color: get(['certifictions', 'hasCert', 'color'], detailTheme),
  backgroundColor: get(['certifictions', 'hasCert', 'backgroundColor'], detailTheme),
  borderRadius: '9999px',
};

const sxForNotCertIcon = {
  marginRight: theme.spacing(1),
  color: get(['certifictions', 'notCert', 'color'], detailTheme),
  backgroundColor: get(['certifictions', 'notCert', 'backgroundColor'], detailTheme),
  borderRadius: '9999px',
};

const sxForDescription = {
  color: get(['description', 'fontColor'], detailTheme),
  fontFamily: get(['description', 'font'], detailTheme),
  fontSize: get(['description', 'fontSize'], detailTheme),
};
const RequiredSection = ({ certsOrSkillsHeld, certsOrSkillsNotHeld }) => (
  <Box sx={{ ...sxForWrapper, justifyContent: 'space-between' }}>
    {certsOrSkillsHeld.length > 0 && (
      <Box>
        {certsOrSkillsHeld.map((certOrSkill) => (
          <Box sx={{ ...sxForWrapper, marginBottom: theme.spacing(1) }}>
            <CheckIcon sx={sxForIsCertIcon} />
            <Typography sx={sxForDescription}>{certOrSkill.name}</Typography>
          </Box>
        ))}
      </Box>
    )}

    {certsOrSkillsNotHeld.length > 0 && (
      <Box>
        {certsOrSkillsNotHeld.map((certOrSkill) => (
          <Box sx={{ ...sxForWrapper, marginBottom: theme.spacing(1) }}>
            <CloseIcon sx={sxForNotCertIcon} />
            <Typography sx={sxForDescription}>{certOrSkill.name}</Typography>
          </Box>
        ))}
      </Box>
    )}
  </Box>
);

RequiredSection.propTypes = {
  certsOrSkillsHeld: PropTypes.arrayOf(PropTypes.shape({})),
  certsOrSkillsNotHeld: PropTypes.arrayOf(PropTypes.shape({})),
};

export default RequiredSection;
