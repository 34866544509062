import { createSlice } from '@reduxjs/toolkit';

import { TIMEKEEPING_STATUSES } from './jobOrderConstants';

const updatePlacement = (jobOrders, placementId, status) =>
  jobOrders.map((job) => ({
    ...job,
    placements: job.placements.map((pl) => {
      if (pl._id === placementId) {
        return {
          ...pl,
          timecard: {
            ...pl.timecard,
            status,
          },
        };
      }
      return pl;
    }),
  }));
const initialState = {
  jobOrders: [],
  jobOrderDetail: {},
  totalRowCount: 0,
  timecardUpdateError: null,
  timeCardIsUpdating: false,
  isJobOrdersLoading: false,
  isJobOrderDetailLoading: false,
  errorPostGroupOrders: null,
  postGroupOrdersResponse: null,
  isProcessingPostGroupOrders: false,
  isCancelProcessing: false,
  isCancelSeriesProcessing: false,
  isCancelPlacementProcessing: false,
  isJobOrderDetailUpdated: false, // Note: use this flag to trigger get up-to-date jobOrderDetail
  isRevokingJobInvite: false,
  isLoadingApproveOrRejectPlacement: false,
  isLoadingApproveOrRejectEGApplication: false,
  isEmployeeInviteProcessing: false,
  isUpdateProcessing: false,
  isSendingJobOfferProcessing: false,
  isLoadingCancelledPlacement: false,
  isCancelledPlacementUpdating: false,
  cancelledPlacement: {},
};

export const jobOrdersSlice = createSlice({
  name: 'jobOrdersSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    getJobOrders: (state, action) => {
      if (action?.payload?.page === 0) {
        state.jobOrders = [];
      }
      state.isJobOrdersLoading = true;
    },
    getJobOrdersProcessed: (state, action) => {
      state.isJobOrdersLoading = false;
      const { count, documents } = action.payload.data;
      state.jobOrders.push(...documents);
      state.totalRowCount = count;
    },

    getJobOrdersError: (state) => {
      state.isJobOrdersLoading = false;
      state.jobOrders = [];
    },

    getJobOrderDetail: (state) => {
      state.isJobOrderDetailLoading = true;
    },
    getJobOrderDetailProcessed: (state, action) => {
      state.isJobOrderDetailLoading = false;
      state.jobOrderDetail = action.payload.data?.jobOrder;
      state.isJobOrderDetailUpdated = false;
    },
    getJobOrderDetailError: (state) => {
      state.isJobOrderDetailLoading = false;
      state.jobOrderDetail = {};
    },
    getCancelledPlacement: (state) => {
      state.isLoadingCancelledPlacement = true;
    },
    getCancelledPlacementProcessed: (state, action) => {
      state.isLoadingCancelledPlacement = false;
      const cancelledPlacement = action?.payload?.data?.documents[0];
      state.cancelledPlacement = cancelledPlacement || {};
    },
    getCancelledPlacementError: (state) => {
      state.isLoadingCancelledPlacement = false;
    },
    clearCancelledPlacementData: (state) => {
      state.cancelledPlacement = {};
    },
    updateCancelledPlacement: (state) => {
      state.isCancelledPlacementUpdating = true;
    },
    updateCancelledPlacementProcessed: (state, action) => {
      state.isCancelledPlacementUpdating = false;

      const cancelledPlacement = action?.payload?.data?.document;
      state.cancelledPlacement = cancelledPlacement || {};
    },
    updateCancelledPlacementError: (state) => {
      state.isCancelledPlacementUpdating = false;
    },
    updateJobRosterEmployee: (state) => {
      state.isPostingJobRoster = true;
    },
    updateJobRosterEmployeeProcessed: (state) => {
      // TODO: need to get proper data then update the state accordingly!
      state.isPostingJobRoster = false;
      state.isJobOrderDetailUpdated = true;
    },
    updateJobRosterEmployeeError: (state) => {
      state.isPostingJobRoster = false;
    },
    revokeJobInvitations: (state) => {
      state.isRevokingJobInvite = true;
    },
    revokeJobInvitationsProcessed: (state) => {
      state.isJobOrderDetailUpdated = true;
      state.isRevokingJobInvite = false;
    },
    revokeJobInvitationsError: (state) => {
      state.isRevokingJobInvite = false;
    },
    revokeJobOffer: (state) => {
      state.isRevokingJobInvite = true;
    },
    revokeJobOfferProcessed: (state) => {
      state.isJobOrderDetailUpdated = true;
      state.isRevokingJobInvite = false;
    },
    revokeJobOfferError: (state) => {
      state.isRevokingJobInvite = false;
    },
    approveOrRejectPlacement: (state) => {
      state.isLoadingApproveOrRejectPlacement = true;
    },
    approveOrRejectPlacementProcessed: (state) => {
      state.isLoadingApproveOrRejectPlacement = false;
      state.isJobOrderDetailUpdated = true;
    },
    approveOrRejectPlacementError: (state) => {
      state.isLoadingApproveOrRejectPlacement = false;
    },
    approveOrRejectEgApplication: (state) => {
      state.isLoadingApproveOrRejectEGApplication = true;
    },
    approveOrRejectEgApplicationProcessed: (state) => {
      state.isLoadingApproveOrRejectEGApplication = false;
      state.isJobOrderDetailUpdated = true;
    },
    approveOrRejectEgApplicationError: (state) => {
      state.isLoadingApproveOrRejectEGApplication = false;
    },
    inviteEmployee: (state) => {
      state.isEmployeeInviteProcessing = true;
    },
    inviteEmployeeProcessed: (state) => {
      state.isEmployeeInviteProcessing = false;
      state.isJobOrderDetailUpdated = true;
    },
    inviteEmployeeError: (state) => {
      state.isEmployeeInviteProcessing = false;
    },
    cancelPlacement: (state) => {
      state.isCancelPlacementProcessing = true;
    },
    cancelPlacementProcessed: (state) => {
      state.isCancelPlacementProcessing = false;
      state.isJobOrderDetailUpdated = true;
    },

    cancelPlacementError: (state) => {
      state.isCancelPlacementProcessing = false;
    },

    removeUserFromGroup: (state) => {
      state.isCancelPlacementProcessing = true;
    },
    removeUserFromGroupProcessed: (state) => {
      state.isCancelPlacementProcessing = false;
      state.isJobOrderDetailUpdated = true;
    },

    removeUserFromGroupError: (state) => {
      state.isCancelPlacementProcessing = false;
    },

    setJobOrderDetail: (state, action) => {
      state.jobOrderDetail = action.payload;
    },

    updateJobOrderCards: (state, action) => {
      const updatedGroups = action.payload;
      state.jobOrders = Object.values(updatedGroups).flatMap((group) => group.cards);
    },
    postGroupOrders: (state) => {
      state.isProcessingPostGroupOrders = true;
    },
    postGroupOrdersProcessed: (state, action) => {
      state.isProcessingPostGroupOrders = false;
      state.postGroupOrdersResponse = action.payload;
    },
    postGroupOrdersError: (state, action) => {
      state.isProcessingPostGroupOrders = false;
      const errors = action?.payload?.errors?.GLOBAL?.errors;
      const messageData =
        (errors && errors[0] && errors[0].messageKey) ||
        action?.payload?.errors?.GLOBAL?.messageKey ||
        'error.generic.something.went.wrong';
      state.errorPostGroupOrders = messageData;
    },
    clearGroupError: (state) => {
      state.errorPostGroupOrders = null;
    },
    clearPostGroupOrdersResponse: (state) => {
      state.postGroupOrdersResponse = null;
    },
    updateExtensionGroup: (state) => {
      state.isUpdatingExtensionGroup = true;
    },
    updateExtensionGroupProcessed: (state) => {
      state.isUpdatingExtensionGroup = false;
      state.isJobOrderDetailUpdated = true;
    },
    updateExtensionGroupError: (state) => {
      state.isUpdatingExtensionGroup = false;
    },
    updateJobOrder: (state) => {
      state.isUpdateProcessing = true;
    },
    updateJobOrderProcessed: (state, action) => {
      state.isUpdateProcessing = false;
      state.jobOrderDetail = {
        ...state.jobOrderDetail,
        numOpenings: action?.payload?.data?.document?.numOpenings,
      };
    },

    updateJobOrderError: (state) => {
      state.isUpdateProcessing = false;
    },

    updateTimeCard: (state) => {
      state.timeCardIsUpdating = true;
    },
    updateTimeCardProcessed: (state, action) => {
      const placement = action?.payload?.data?.placement;
      const totalHours = placement?.timecard?.totalHours;

      state.jobOrders = state.jobOrders.map((job) => ({
        ...job,
        placements: job.placements.map((pl) => {
          if (pl._id === placement?.placement) {
            return {
              ...pl,
              timecard: {
                ...pl.timecard,
                current: {
                  ...pl.timecard.current,
                  punches: placement?.timecard?.punches,
                  totalTime: totalHours ? totalHours * 60 * 60 * 1000 : '--',
                },
              },
            };
          }
          return pl;
        }),
      }));

      state.timeCardIsUpdating = false;
    },
    updateTimeCardError: (state, action) => {
      state.timeCardIsUpdating = false;
      const messageData =
        action?.payload?.errors?.GLOBAL?.messageKey || 'error.generic.something.went.wrong';
      state.timecardUpdateError = messageData;
    },
    clearTimeCardError: (state) => {
      state.timecardUpdateError = null;
    },
    cancelSingleJob: (state) => {
      state.isCancelProcessing = true;
    },
    cancelSingleJobProcessed: (state) => {
      state.isCancelProcessing = false;
    },
    cancelSingleJobError: (state) => {
      state.isCancelProcessing = false;
    },
    cancelSeriesJob: (state) => {
      state.isCancelSeriesProcessing = true;
    },
    cancelSeriesJobProcessed: (state) => {
      state.isCancelSeriesProcessing = false;
    },
    cancelSeriesJobError: (state) => {
      state.isCancelSeriesProcessing = false;
    },
    updatePlacementCheckInStatus: (state) => {
      state.isPostingJobRoster = true;
    },
    updatePlacementCheckInStatusProcessed: (state, action) => {
      state.isPostingJobRoster = false;
      const document = action?.payload?.data?.document;

      state.jobOrders = state.jobOrders.map((job) => {
        if (job._id === document?.jobOrder?._id) {
          return {
            ...job,
            placements: job.placements.map((placement) => {
              if (placement._id === document?._id) {
                return {
                  ...placement,
                  timecard: {
                    ...placement.timecard,
                    checkIn: document?.timecard?.checkIn,
                  },
                };
              }
              return placement;
            }),
          };
        }
        return job;
      });
    },
    updatePlacementCheckInStatusError: (state) => {
      state.isPostingJobRoster = false;
    },
    postPlacementApproval: (state) => {
      state.timecardActionIsProcessing = true;
    },
    postPlacementApprovalProcessed: (state, action) => {
      state.timecardActionIsProcessing = false;
      const placementId = action?.payload?.data?.placements[0];
      state.jobOrders = updatePlacement(
        state.jobOrders,
        placementId,
        TIMEKEEPING_STATUSES.APPROVED
      );
    },
    postPlacementApprovalError: (state) => {
      state.timecardActionIsProcessing = false;
    },
    postPlacementResolve: (state) => {
      state.timecardActionIsProcessing = true;
    },
    postPlacementResolveProcessed: (state, action) => {
      state.timecardActionIsProcessing = false;
      const placementId = action?.payload?.data?.placements[0];
      state.jobOrders = updatePlacement(
        state.jobOrders,
        placementId,
        TIMEKEEPING_STATUSES.RESOLVED
      );
    },
    postPlacementResolveError: (state) => {
      state.timecardActionIsProcessing = false;
    },
    postPlacementFinalize: (state) => {
      state.timecardActionIsProcessing = false;
    },
    postPlacementFinalizeProcessed: (state, action) => {
      state.timecardActionIsProcessing = false;
      const placementId = action?.payload?.data?.placements[0];
      state.jobOrders = updatePlacement(
        state.jobOrders,
        placementId,
        TIMEKEEPING_STATUSES.FINALIZED
      );
    },
    postPlacementFinalizeError: (state) => {
      state.timecardActionIsProcessing = false;
    },
    postPlacementUnFinalize: (state) => {
      state.timecardActionIsProcessing = false;
    },
    postPlacementUnFinalizeProcessed: (state, action) => {
      state.timecardActionIsProcessing = false;
      const placementId = action?.payload?.data?.placements[0];
      state.jobOrders = updatePlacement(
        state.jobOrders,
        placementId,
        TIMEKEEPING_STATUSES.RESOLVED
      );
    },
    addEmployeeToPlacement: (state) => {
      state.isUpdateProcessing = true;
    },
    addEmployeeToPlacementProcessed: (state) => {
      state.isUpdateProcessing = false;
      state.isJobOrderDetailUpdated = true;
    },
    addEmployeeToPlacementError: (state) => {
      state.isUpdateProcessing = false;
    },
    sendInviteOrJobOffer: (state) => {
      state.isSendingJobOfferProcessing = true;
    },
    sendInviteOrJobOfferProcessed: (state) => {
      state.isSendingJobOfferProcessing = false;
      state.isJobOrderDetailUpdated = true;
    },
    sendInviteOrJobOfferError: (state) => {
      state.isSendingJobOfferProcessing = false;
    },
  },
});

export const jobOrdersReducer = jobOrdersSlice.reducer;

export const {
  setSelectedJob,
  setSelectedJobType,
  getJobOrders,
  getJobOrdersError,
  getJobOrdersProcessed,
  getJobOrderDetail,
  getJobOrderDetailProcessed,
  getJobOrderDetailError,
  getCancelledPlacement,
  getCancelledPlacementProcessed,
  getCancelledPlacementError,
  clearCancelledPlacementData,
  updateJobRosterEmployee,
  updateJobRosterEmployeeProcessed,
  updateJobRosterEmployeeError,
  revokeJobInvitations,
  revokeJobInvitationsProcessed,
  revokeJobInvitationsError,
  revokeJobOffer,
  revokeJobOfferProcessed,
  revokeJobOfferError,
  approveOrRejectPlacement,
  approveOrRejectEgApplication,
  approveOrRejectPlacementError,
  approveOrRejectEgApplicationError,
  approveOrRejectPlacementProcessed,
  approveOrRejectEgApplicationProcessed,
  removeUserFromGroup,
  removeUserFromGroupProcessed,
  removeUserFromGroupError,
  inviteEmployee,
  inviteEmployeeError,
  inviteEmployeeProcessed,
  cancelPlacement,
  cancelPlacementProcessed,
  cancelPlacementError,
  setJobOrderDetail,
  updateJobOrderCards,
  postGroupOrders,
  postGroupOrdersProcessed,
  postGroupOrdersError,
  clearGroupError,
  clearPostGroupOrdersResponse,
  clearTimeCardError,
  updateExtensionGroup,
  updateExtensionGroupProcessed,
  updateExtensionGroupError,
  updateJobOrder,
  updateJobOrderProcessed,
  updateJobOrderError,
  updateTimeCard,
  updateTimeCardProcessed,
  updateTimeCardError,
  updateCancelledPlacement,
  updateCancelledPlacementProcessed,
  updateCancelledPlacementError,
  cancelSingleJob,
  cancelSingleJobProcessed,
  cancelSingleJobError,
  cancelSeriesJob,
  cancelSeriesJobProcessed,
  cancelSeriesJobError,
  updatePlacementCheckInStatus,
  updatePlacementCheckInStatusProcessed,
  updatePlacementCheckInStatusError,
  postPlacementApproval,
  postPlacementApprovalError,
  postPlacementApprovalProcessed,
  postPlacementResolve,
  postPlacementResolveProcessed,
  postPlacementResolveError,
  postPlacementFinalize,
  postPlacementFinalizeProcessed,
  postPlacementFinalizeError,
  postPlacementUnFinalize,
  postPlacementUnFinalizeProcessed,
  postPlacementUnFinalizeError,
  addEmployeeToPlacement,
  addEmployeeToPlacementProcessed,
  addEmployeeToPlacementError,
  sendInviteOrJobOffer,
  sendInviteOrJobOfferProcessed,
  sendInviteOrJobOfferError,
} = jobOrdersSlice.actions;
