import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import theme from '../../theme';

const CHIP_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
  DANGER: 'danger',
  SUCCESS: 'success',
};
const CHIP_SIZE = {
  SMALL: 'Small',
  LARGE: 'Large',
};

const WaeChip = (props) => {
  const { sx, background, size, label } = props;
  let fontSize;

  const backgroundGenerator = {
    [CHIP_BACKGROUND.DARK]: {
      chipBgColor: theme.chip.bgColor.darkBgColor,
      chipHoverColorl: theme.chip.hover.darkHoverColor,
      chipColor: theme.chip.color.default,
    },
    [CHIP_BACKGROUND.LIGHT]: {
      chipBgColor: theme.chip.bgColor.defaultBgColor,
      chipHoverColor: theme.chip.hover.lightHoverColor,
      chipColor: theme.chip.color.default,
    },
    [CHIP_BACKGROUND.DANGER]: {
      chipBgColor: theme.chip.bgColor.dangerBgColor,
      chipHoverColor: theme.chip.hover.dangerHoverColor,
      chipColor: theme.chip.color.danger,
    },
    [CHIP_BACKGROUND.SUCCESS]: {
      chipBgColor: theme.chip.bgColor.successBgColor,
      chipHoverColor: theme.chip.hover.successHoverColor,
      chipColor: theme.chip.color.success,
    },
    default: {
      chipBgColor: theme.chip.bgColor.defaultBgColor,
      chipHoverColor: theme.chip.hover.defaultHoverColor,
      chipColor: theme.chip.color.default,
    },
  };
  switch (size) {
    case CHIP_SIZE.LARGE:
      fontSize = theme.chip.default.largeFontSize;

      break;

    default:
      fontSize = theme.chip.default.fontSize;
  }

  return (
    <Chip
      {...props}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize,
            fontFamily: 'Barlow-600',
          }}
        >
          {label}

          <span> </span>
        </Box>
      }
      sx={{
        color: backgroundGenerator[background]?.chipColor || backgroundGenerator.default.chipColor,
        backgroundColor:
          backgroundGenerator[background]?.chipBgColor || backgroundGenerator.default.chipBgColor,

        '&.MuiChip-root': {
          ':hover': {
            backgroundColor:
              backgroundGenerator[background]?.chipHoverColor ||
              backgroundGenerator.default.chipHoverColor,
          },
        },
        ...sx,
      }}
    />
  );
};
WaeChip.propTypes = {
  sx: PropTypes.shape({}),
  brightness: PropTypes.shape({}),
  background: PropTypes.string,
  size: PropTypes.string,
  selected: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  icon: PropTypes.element,
};

export { CHIP_BACKGROUND, CHIP_SIZE, WaeChip };
