import React from 'react';
import PropTypes from 'prop-types';
import { Form as RFForm } from 'react-final-form';

import CreateJobFormBody from './CreateJobFormBody';

const JobOrdersCreate = ({ onClose, onSubmit, user, isProcessing }) => (
  <RFForm
    onSubmit={onSubmit}
    initialValues={{}}
    destroyOnUnregister
    render={({ handleSubmit, pristine }) => (
      <CreateJobFormBody
        handleSubmit={handleSubmit}
        pristine={pristine}
        onClose={onClose}
        user={user}
        isProcessing={isProcessing}
      />
    )}
  />
);

JobOrdersCreate.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isProcessing: PropTypes.bool,
  user: PropTypes.shape({
    role: PropTypes.string,
    employer: PropTypes.shape({
      corporation: PropTypes.string,
    }),
  }),
};

export default JobOrdersCreate;
