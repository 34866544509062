import { validate } from 'jsonschema';

import Bugsnag from '@bugsnag/js';
import BUGSNAG_CONSTANTS from '@careerstart/wae-common/src/main/constants/BUGSNAG_CONSTANTS';

import { combineDateAndTime, minutesFromMidnightToTime } from '../../utils';

/*
 * Runs series of validators against the input value. In the case of an error, the first error found is returned.
 */
export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

/*
 * Validates the value is a number and greater than the provided minimum value
 */
export const isGreaterThan = (min) => (value) =>
  !(/^[0-9]*\.?[0-9]*$/.test(value) && Number(value) > min)
    ? `Value must be greater than ${min}`
    : undefined;

/*
 * Validates the value is a number and greater than or equal to the provided minimum value
 */
export const isGreaterThanOrEqual = (min) => (value) =>
  !(/^[0-9]*\.?[0-9]*$/.test(value) && Number(value) >= min)
    ? `Value must be greater than or equal to ${min}`
    : undefined;

/*
 * Validates the value is greater than a provided percent value
 */
export const isPercentGreaterThanOrEqual = (min) => (value) => {
  const x = value && /^[0-9]*\.?[0-9]*$/.test(value.toString().replace(/[^0-9.-]+/g, ''));
  const y = parseFloat(value) >= min;
  return !(x && y) ? `Value must be greater than or equal to ${min}%` : undefined;
};

/*
 * Validates the value is less than a provided percent value
 */
export const isPercentLessThanOrEqual = (max) => (value) => {
  const x = value && /^[0-9]*\.?[0-9]*$/.test(value.toString().replace(/[^0-9.-]+/g, ''));
  const y = parseFloat(value) <= max;
  return !(x && y) ? `Value must be less than or equal to ${max}%` : undefined;
};

/*
 * Validates the currency value greater than the provided minimum value
 */
export const isCurrencyGreaterThan =
  (min) =>
  (value = '0') =>
    !(Number(value.toString().replace(/[^0-9.-]+/g, '')) > min)
      ? `Value must be greater than ${min}`
      : undefined;

/*
 * Validates the value is an integer
 */
export const isInteger = (value) => (!/^-?\d+$/.test(value) ? 'error.field.notInteger' : undefined);

/*
 * Validates the value is a float
 */
export const isFloat = (value) =>
  !/([+-]?[0-9]*\.[0-9]*)/.test(value) ? 'error.field.notFloat' : undefined;

/*
 * Validates the value is a valid date
 */
export const isValidDate = (date) =>
  Number.isNaN(Date.parse(date)) ? 'Not a valid date' : undefined;

/*
 * Validates the date is today or later
 */
export const isDateEqualOrGreaterThanToday = (date) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const isBeforeToday = new Date(date) < today;
  return isBeforeToday ? 'Date must not be in the past' : undefined;
};

/*
 * TO DO: make this validator more general
 * Validates the date is equal or greater than current date
 */
export const isDateEqualOrGreaterThanTodayDateObject = (dateObject) => {
  const inputDate = new Date(dateObject.year, dateObject.month - 1, dateObject.day).setHours(
    0,
    0,
    0,
    0
  );
  const today = new Date().setHours(0, 0, 0, 0);

  return inputDate < today ? 'Date must not be in the past' : undefined;
};

/*
 * Validates the date is in the proper object format(year, month, day) - used in bulk job creates
 */
export const isValidDateObject = (dateObject) => {
  const incomplete = !dateObject.year || !dateObject.month || !dateObject.day;
  return incomplete ? 'Invalid date object' : undefined;
};

/*
 * Validates the date is before today
 */
export const isDateSmallerThanToday = (date) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const isTodayOrLater = new Date(date) >= today;
  return isTodayOrLater ? 'Date must be before today' : undefined;
};

/*
 * Validates the time is a valid time
 */
export const isValidTime = (time) =>
  Number.isNaN(Date.parse(time)) ? 'Not a valid time' : undefined;

/*
 * Custom Validation for shift component. Validates if shift is empty
 */
export const isPTShiftEmpty = (value) => {
  if (value) {
    return value.length <= 0 ? 'At least one shift must be created' : undefined;
  }
  return 'At least one shift must be created';
};

/*
 * Custom Validation for shift component. Validates if shift is selected
 */
export const isShiftSelectorEmpty = (value) => {
  if (value) {
    return value ? undefined : 'A shift must be selected';
  }
  return 'A shift must be selected';
};

/*
 * Validates field input as a valid phone number
 */
export const isValidPhoneNumber = (value) =>
  !/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
    ? 'Must be a 10 digit phone number'
    : undefined;

/*
 * Validates field input as a valid email address
 */
export const isValidEmail = (value) =>
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? 'Field must be in a valid email format'
    : undefined;
/*
 * Validates field input does not have a space
 */
export const containsASpace = (value) =>
  /\s/.test(value) ? 'Field must not contain a space' : undefined;

/*
 * Custom Validation for forgot my pass textfield component. Validates if textfield is in either valid email or phone number format
 */
export const isValidPhoneOrEmail = (value) =>
  isValidPhoneNumber(value) && isValidEmail(value)
    ? 'Field must be in a valid email or phone number format'
    : undefined;

/*
 * Validates field input as a valid phone number  Returns a boolean.
 */
export const isValidPhoneNumberBoolean = (value) =>
  !!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(value);

/*
 * Validates field input as a valid email address. Returns a boolean.
 */
export const isValidEmailBoolean = (value) =>
  !!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );

/*
 * Validates field input as a valid name. Checks for any special symbols and numbers.
 */
export const isValidPersonName = (value) =>
  !/^[a-z ,.'-]+$/i.test(value) ? 'This field may only contain alphabetic characters' : undefined;

/*
 * Validates field input if field is at minimum 8 character long.
 */
export const isAtMinimumEightCharactersLong = (value) =>
  value && value.length < 8 ? 'This field must contain at minimum 8 characters.' : undefined;

/*
 * Validates field input if field contains at least one capital letter.
 */
export const containAtLeastOneCapitalLetter = (value) =>
  !/[A-Z]/.test(value) ? 'This field must contain 1 capital letter.' : undefined;

/*
 * Validates field input if field contains at least two numbers.
 */
export const containAtMinimumTwoNumbers = (value) =>
  !/(\D*\d){1,}/.test(value) ? 'This field must contain at minimum 1 number.' : undefined;

/*
 * Validates field input if field contains at least one lower case letter.
 */
export const containAtMinimumTwoLowerCaseLetters = (value) =>
  !/(?:[^a-z]*[a-z]){1}/.test(value) ? 'This field must contain a lower case letter.' : undefined;

/*
 * Validates field input if field contains a special character -+_!@#$%^&*.,?.
 */
export const containsAtMinimumOneSpecialCharacter = (value) =>
  !/(?=.*[-+_!@#$%^&*.,?])/.test(value)
    ? 'This field must contain a special character.'
    : undefined;

export const validateRequiredFields = (values, formFieldData) =>
  Object.values(formFieldData).reduce(
    (accumulator, formFieldDef) =>
      // Verify required fields have values.
      (formFieldDef.required &&
        !values[formFieldDef.name] && {
          ...accumulator,
          [formFieldDef.name]: 'This value is required',
        }) ||
      // Else, this field has no errors.
      accumulator,
    {}
  );

export const validateSchema = (jsonInstance, jsonSchema) => {
  const schemaValidation = validate(jsonInstance, jsonSchema);
  if (!schemaValidation.valid) {
    Bugsnag.notify(new Error(), (e) => {
      e.severity = BUGSNAG_CONSTANTS.SEVERITY.ERROR;
      e.context = `FORM_ERROR Invalid Schema: ${schemaValidation.errors}`;
      e.addMetadata('FORM_ERROR', schemaValidation.errors);
    });
    return {
      FORM_ERROR: schemaValidation.errors,
    };
  }
  return {};
};

/*
 * Validates if date and time is greater than current time.
 */
export const isDateAndTimeEqualOrGreaterThanNow = (time, errorResponse) => (values) => {
  const combinedDateAndTime = combineDateAndTime(values, minutesFromMidnightToTime(time));
  return combinedDateAndTime < Date.now() ? errorResponse : undefined;
};

/*
 * Validates if value is an object with at least one property and value
 */
export const isAnObject = () => (value) =>
  !value || !(Object.keys(value).length > 0) || !(Object.values(value).length > 0)
    ? 'error.field.empty'
    : undefined;

/*
 * Validates if an array of non-empty objects
 */
export const isAnArrayOfObjects = () => (value) => {
  if (!Array.isArray(value)) return 'Input must be an array';
  if (
    !value.every(
      (e) =>
        typeof e === 'object' &&
        e !== null &&
        Object.keys(e).length > 0 &&
        Object.values(e).every((v) => v != null)
    )
  )
    return 'Invalid array elements';
  return undefined;
};

/* returns a boolean to check if a field is required and empty
 */
export const isRequiredFieldEmptyBoolean = (value, required) => !!(!value && required);

/* returns a string to check if a field is empty
 */

export const isRequiredFieldEmpty = (value) => (value ? undefined : 'error.field.required');

/* returns a validation text if invite employee drawer is more than 25 selection
 */
export const max25InvitedEmployeeValidation = (value) =>
  value?.length <= 25 || !value ? undefined : 'error.jobCreate.inviteSelectionMax25Candidates';
