import { get } from 'lodash/fp';

import theme from '../../../../../theme/theme';

const componentsModalTypographyTitle = ['components', 'modal', 'typography', 'title'];
const componentsModalTypographyBodyText = ['components', 'modal', 'typography', 'bodyText'];

export const backButtonSx = { margin: theme.spacing(0, 1.6, 0, 0), minWidth: '124px' };

export const backgroundSx = (isModalWidthGreaterThanWindowWidth) => ({
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: (isModalWidthGreaterThanWindowWidth && '95%') || '517px',
  height: '278px',
  borderRadius: '16px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
});

export const bodyTextSx = {
  fontColor: get([...componentsModalTypographyBodyText, 'fontColor'], theme),
  fontFamily: get([...componentsModalTypographyBodyText, 'fontFamily'], theme),
  fontSize: get([...componentsModalTypographyBodyText, 'fontSize'], theme),
  lineHeight: get([...componentsModalTypographyBodyText, 'lineHeight'], theme),
};

export const subTextSx = {
  fontColor: get([...componentsModalTypographyBodyText, 'fontColor'], theme),
  fontFamily: get([...componentsModalTypographyBodyText, 'fontFamily'], theme),
  fontSize: get([...componentsModalTypographyBodyText, 'fontSize'], theme),
  lineHeight: get([...componentsModalTypographyBodyText, 'lineHeight'], theme),
  margin: theme.spacing(0, 0, 2, 0),
};

export const buttonGridSx = {
  height: '40px',
};

export const forwardButtonSx = {
  minWidth: '129px',
};

export const titleSx = {
  fontColor: get([...componentsModalTypographyTitle, 'fontColor'], theme),
  fontFamily: get([...componentsModalTypographyTitle, 'fontFamily'], theme),
  fontSize: get([...componentsModalTypographyTitle, 'fontSize'], theme),
};
