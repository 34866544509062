import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import theme from '../../../theme';
import { logout } from '../../../views/app';

import { emailStyling, PaperPropStyling, roleStyling, selectionStyling } from './MenuStyling';
import stringAvatar from './stringAvatar';

// TODO i80n to Admin/settings/logout
const ProfileMenu = ({ anchorEl, open, handleProfileMenuClose, user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleProfileMenuClose}
      onClick={handleProfileMenuClose}
      PaperProps={{
        elevation: 0,
        sx: PaperPropStyling,
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem sx={{ backgroundColor: `${theme.components.profileMenu.palette.border}` }}>
        <Box sx={{ display: 'flex', padding: '4px' }}>
          <Box>
            <Avatar {...stringAvatar(user)} />
          </Box>

          <Box>
            <Typography sx={emailStyling}>{user.email}</Typography>
            <Box>
              <Typography sx={roleStyling}>{user.role}</Typography>
            </Box>
          </Box>
        </Box>
      </MenuItem>

      <MenuItem onClick={() => navigate('settings')} sx={selectionStyling}>
        Settings
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(logout());
        }}
        sx={selectionStyling}
      >
        Log out
      </MenuItem>
    </Menu>
  );
};

ProfileMenu.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    id: PropTypes.string,
  }),

  anchorEl: PropTypes.bool,
  open: PropTypes.bool,
  handleProfileMenuClose: PropTypes.func,
  backendDeleteNotifications: PropTypes.func,
};

export default ProfileMenu;
