import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import {
  Bar,
  BarChart as Chart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import theme from '../../theme/theme';

const barChartTypographyPath = ['components', 'barChart', 'typography'];

const Header = styled(Grid)(() => ({
  justifyContent: 'center',
  height: '24px',
  lineHeight: '24px',
  fontWeight: get([...barChartTypographyPath, 'header', 'fontWeight'], theme),
  fontSize: get([...barChartTypographyPath, 'header', 'fontSize'], theme),
  fontFamily: get([...barChartTypographyPath, 'header', 'fontFamily'], theme),
  fontColor: get([...barChartTypographyPath, 'header', 'fontColor'], theme),
}));

const BarChart = ({
  barsData = [],
  data = [],
  legend,
  titleText,
  xAxisDataKey,
  xAxisTickFontSize,
  yAxisLabel,
}) => (
  <>
    <Header container sx={{ height: '10', width: '100%' }}>
      {titleText}
    </Header>

    <ResponsiveContainer height="85%" width="100%">
      <Chart data={data}>
        <XAxis
          dataKey={xAxisDataKey}
          tick={{ fontSize: xAxisTickFontSize }}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          label={{
            value: yAxisLabel,
            angle: -90,
            offset: 9,
            position: 'insideBottomLeft',
          }}
          axisLine={false}
          tickCount={6}
          tickLine={false}
        />
        <Tooltip />
        {legend && (
          <Legend
            iconType="circle"
            iconSize={8}
            formatter={(value) => (
              <span
                style={{
                  height: '18px',
                  lineHeight: '18px',
                  fontWeight: get([...barChartTypographyPath, 'legend', 'fontWeight'], theme),
                  fontSize: get([...barChartTypographyPath, 'legend', 'fontSize'], theme),
                  fontFamily: get([...barChartTypographyPath, 'legend', 'fontFamily'], theme),
                  color: get([...barChartTypographyPath, 'legend', 'fontColor'], theme),
                }}
              >
                {value}
              </span>
            )}
          />
        )}
        {barsData.map((barData) => (
          <Bar
            key={get(['dataKey'], barData)}
            dataKey={get(['dataKey'], barData)}
            fill={get(['color'], barData)}
            radius={30}
            maxBarSize={12}
          />
        ))}
      </Chart>
    </ResponsiveContainer>
  </>
);
BarChart.propTypes = {
  barsData: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  legend: PropTypes.bool,
  titleText: PropTypes.string,
  xAxisDataKey: PropTypes.string,
  xAxisTickFontSize: PropTypes.number,
  yAxisLabel: PropTypes.string,
};

export default BarChart;
