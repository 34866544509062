import React, { memo } from 'react';
import { get } from 'lodash/fp';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import Accounts from '../../../assets/icons/Accounts.svg';
import NoAccounts from '../../../assets/icons/NoAccounts.svg';
import BarChart from '../../../components/BarChart';
import DirectionRowPieChart from '../../../components/DirectionRowPieChart';
import LineChart from '../../../components/LineChart';
import {
  selectEmployeeMetricsLoadingState,
  selectEmployeesMetrics,
} from '../../../store/selectors/reportingSelectors';
import theme from '../../../theme';
import { IconContentLabelCard } from '../metricsCards';
import ChartCard from '../metricsCards/ChartCard';
import MetricsHeader from '../metricsHeader';

const {
  attendanceColor,
  noCallNoShowColor,
  insubordinationColor,
  misconductColor,
  breachOfCompanyPolicyColor,
  walkedOffColor,
  unauthorizedPossessionColor,
  failureToFollowSupervisorColor,
  failureToFollowProceduresColor,
} = get(['views', 'reporting', 'attendance'], theme);

const overallAttendanceBarsData = [
  { dataKey: 'count', color: get(['views', 'reporting', 'palette', 'primary'], theme) },
];

const ChartWrapGrid = styled(Grid)(() => ({
  height: '100%',
  padding: '20px',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const EmployeesMetrics = memo(() => {
  const { employeesHired, dnrs, dnas, doNotReturnBreakdown, overallAttendance, employeeRetention } =
    useSelector(selectEmployeesMetrics);
  const {
    loadingEmployeeRetention,
    loadingEmployeesHired,
    loadingDnrs,
    loadingDnas,
    loadingOverallAttendance,
    loadingDoNotReturnBreakdown,
  } = useSelector(selectEmployeeMetricsLoadingState);

  const {
    attendance,
    noCallNoShow,
    insubordination,
    misconduct,
    breachOfCompanyPolicy,
    walkedOff,
    unauthorizedPossession,
    failureToFollowSupervisor,
    failureToFollowProcedures,
  } = doNotReturnBreakdown;

  const pieChartData = [
    {
      label: 'Attendance',
      value: attendance,
      color: attendanceColor,
    },
    {
      label: 'No Call No Show',
      value: noCallNoShow,
      color: noCallNoShowColor,
    },
    {
      label: 'Insubordination',
      value: insubordination,
      color: insubordinationColor,
    },
    {
      label: 'Misconduct',
      value: misconduct,
      color: misconductColor,
    },
    {
      label: 'Breach of Company Policy',
      value: breachOfCompanyPolicy,
      color: breachOfCompanyPolicyColor,
    },
    {
      label: 'Walked Off',
      value: walkedOff,
      color: walkedOffColor,
    },
    {
      label: 'Unauthorized Possession',
      value: unauthorizedPossession,
      color: unauthorizedPossessionColor,
    },
    {
      label: 'Failure to Follow Supervisor',
      value: failureToFollowSupervisor,
      color: failureToFollowSupervisorColor,
    },
    {
      label: 'Failure to Follow Procedures',
      value: failureToFollowProcedures,
      color: failureToFollowProceduresColor,
    },
  ];

  return (
    <Grid container item spacing={2} paddingBottom="16px" direction="row">
      <MetricsHeader item text="Employees" />

      {employeesHired !== null && (
        <IconContentLabelCard
          data={employeesHired}
          iconContentGap={2}
          direction="row"
          iconSize={50}
          loading={loadingEmployeesHired}
          staticData={{ icon: Accounts, label: 'TBD' }}
          sx={{ height: '144px' }}
        />
      )}
      {dnrs !== null && (
        <IconContentLabelCard
          data={dnrs}
          iconContentGap={2}
          direction="row"
          iconSize={50}
          loading={loadingDnrs}
          staticData={{ icon: NoAccounts, label: 'DNRs' }}
          sx={{ height: '144px' }}
        />
      )}
      {dnas !== null && (
        <IconContentLabelCard
          data={dnas}
          iconContentGap={2}
          direction="row"
          iconSize={50}
          loading={loadingDnas}
          staticData={{ icon: NoAccounts, label: 'DNAs' }}
          sx={{ height: '144px' }}
        />
      )}
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={5}>
          <ChartCard sx={{ height: '334px' }} loading={loadingOverallAttendance}>
            <ChartWrapGrid item xs>
              <BarChart
                barsData={overallAttendanceBarsData}
                data={overallAttendance}
                titleText="Overall Attendance"
                xAxisDataKey="_id"
                xAxisTickFontSize={14}
                yAxisLabel="Number of People"
              />
            </ChartWrapGrid>
          </ChartCard>
        </Grid>
        <Grid item xs={7}>
          <ChartCard sx={{ height: '334px' }} loading={loadingDoNotReturnBreakdown}>
            <ChartWrapGrid item xs>
              <DirectionRowPieChart
                data={pieChartData}
                titleText="Do Not Return Breakdown"
                totalValueText="Total DNRs"
              />
            </ChartWrapGrid>
          </ChartCard>
        </Grid>
        <Grid item xs={12}>
          <ChartCard sx={{ height: '334px' }} loading={loadingEmployeeRetention}>
            <ChartWrapGrid item xs>
              <LineChart
                data={employeeRetention}
                xAxisTickFontSize={14}
                titleText="Employee Retention"
                xAxisDataKey="shifts"
                yAxisDataKey="candidates"
                xAxisLabel="Number of Shifts"
                yAxisLabel="Number of People"
              />
            </ChartWrapGrid>
          </ChartCard>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default EmployeesMetrics;
