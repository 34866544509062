const FILTERS = {
  UPCOMING: [
    // { field: 'cancelled', operation: 'equals', value: false },
    // upcoming jobs
    { field: 'end', operation: 'after', value: +new Date() },
  ],
  COMPLETED: [
    // TODO deal with cancelled Jobs
    // completed
    { field: 'end', operation: 'before', value: +new Date() },
  ],

  NOT_CANCELLED: [{ field: 'cancelled', operation: 'equals', value: false }],

  LONG_TERM_JOBS: [
    // TODO deal with cancelled Jobs
    // longtermJobs
    { field: 'shiftGroup', operation: 'notEqual', value: null },
  ],
  DAY: [
    // TODO deal with cancelled Jobs
    // { field: 'shiftGroup', operation: 'equals', value: null },
  ],

  get UPCOMING_JOBS() {
    return [{ field: 'end', operation: 'after', value: +new Date() }];
  },

  get COMPLETED_JOBS() {
    return [{ field: 'end', operation: 'before', value: +new Date() }];
  },
};
export default FILTERS;
