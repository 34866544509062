import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import MoreOptionsIcon from '../../../assets/icons/MoreOptions.svg';
import trashCanIcon from '../../../assets/icons/orangeDeleteIcon.svg';
import theme from '../../../theme';
import { WaeChip } from '../../Chip/Chip';

import generateContent from './generateContent';
import NotificationButton from './NotificationButton';

const NotificationCardStyle = {
  borderBottom: `${theme.palette.border.grey} solid`,
  padding: theme.spacing(2, 4),
};

const NotificationCard = ({
  unread,
  notification,
  handleOnDelete,
  handleOnUndoDelete,
  handleSetNotificationRead,
  closeNotificationCenter,
}) => {
  const id = get('_id', notification);
  const notificationType = get('notificationType', notification);
  const frontendSoftDeleted = get('frontendSoftDeleted', notification);
  const intl = useIntl();
  const { logo, content, time, multipleActions, chipData } = generateContent(
    notification,
    intl.locale
  );
  return (
    notificationType && (
      <Box
        collapsedsize={40}
        className={frontendSoftDeleted && 'collapsed-notification'}
        sx={{
          '&.collapsed-notification': {
            borderBottom: `${theme.palette.border.grey} solid`,
          },
        }}
      >
        <Collapse in={!frontendSoftDeleted}>
          <Box
            onClick={() => handleSetNotificationRead(id)}
            sx={{
              ...NotificationCardStyle,
              backgroundColor:
                (unread && `${theme.components.notification.palette.light}`) || 'transparent',
              display: 'flex',
              '&:hover': {
                background:
                  (unread && `${theme.components.notification.palette.middle}`) ||
                  `${theme.components.notification.palette.middle}`,
              },
            }}
          >
            <Box
              component="img"
              sx={{
                height: 22,
                width: 22,
              }}
              alt="jobs"
              src={logo}
            />
            <Box sx={{ flexGrow: 2, padding: theme.spacing(0, 3, 0, 1) }}>
              <Box
                sx={{
                  fontFamily: theme.components.notification.mainText.fontFamily,
                  fontSize: theme.components.notification.mainText.fontSize,
                }}
              >
                {content}
              </Box>
              <Box
                sx={{
                  fontFamily: theme.components.notification.boldText.fontFamily,
                  fontSize: theme.components.notification.boldText.fontSize,
                  color: theme.palette.text.notificationTime,
                }}
              >
                {time}
              </Box>
              {NotificationButton && (
                <NotificationButton
                  notification={notification}
                  closeNotificationCenter={closeNotificationCenter}
                />
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 22,
                  width: 22,
                }}
                alt="moreOptions"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnDelete(id);
                }}
                src={multipleActions ? MoreOptionsIcon : trashCanIcon}
              />
              {chipData?.label && (
                <WaeChip
                  background={chipData?.background}
                  label={chipData?.label}
                  sx={{
                    textTransform: 'capitalize',
                  }}
                />
              )}
            </Box>
          </Box>
        </Collapse>

        <Collapse in={frontendSoftDeleted}>
          <Box
            sx={{
              padding: theme.spacing(2, 4, 2),
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: theme.components.notification.deleteText.fontSize,
              fontFamily: theme.components.notification.deleteText.fontFamily,
            }}
          >
            <Box>Notification Deleted</Box>

            <Box onClick={() => handleOnUndoDelete(id)}>Undo</Box>
          </Box>
        </Collapse>
      </Box>
    )
  );
};

NotificationCard.propTypes = {
  notification: PropTypes.shape({}),
  handleOnDelete: PropTypes.func,
  handleOnUndoDelete: PropTypes.func,
  handleSetNotificationRead: PropTypes.func,
  unread: PropTypes.bool,
  closeNotificationCenter: PropTypes.func,
};
export default NotificationCard;
