import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Avatar, Box, IconButton, Typography } from '@mui/material';

import InviteCandidateModal from '../../../../../components/InviteCandidateModal/InviteCandidateModal';
import { BLACK } from '../../../../../theme/colorConstants';

const InviteEmployeeRosterCard = ({ onSubmitInvite, userRole, isInPast }) => {
  const intl = useIntl();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        justifyContent: 'space-between',
        padding: '10px',
        border: '1px dashed #D7BDFF',
      }}
    >
      <Box display="flex" alignItems="center">
        <Avatar />
        <Typography style={{ marginLeft: '10px', fontSize: '24px', color: BLACK[40] }}>
          {LanguageConverter('jobOrder.roster.inviteEmployee')}
        </Typography>
      </Box>
      {userRole === UserRole.EMPLOYER && !isInPast && (
        <Box
          sx={{ display: 'flex', padding: '0 10px' }}
          onClick={() => setIsInviteModalOpen(!isInviteModalOpen)}
          data-testId="inviteButton"
        >
          <IconButton color="#7A23FF">
            <PersonAddAltIcon color="primary" />
          </IconButton>
        </Box>
      )}

      {isInviteModalOpen && (
        <InviteCandidateModal
          isOpen={isInviteModalOpen}
          onClose={() => setIsInviteModalOpen(false)}
          onSubmit={onSubmitInvite}
          header={intl.formatMessage({ id: 'job.create.inviteModal.header.singleInvite' })}
          submitButtonText={intl.formatMessage({ id: 'button.sendInvite.single' })}
          text={intl.formatMessage({ id: 'job.create.invitesEmployeeSelected' })}
          isMultiSelect={false}
        />
      )}
    </Box>
  );
};
InviteEmployeeRosterCard.propTypes = {
  onSubmitInvite: PropTypes.func,
  userRole: PropTypes.string,
  isInPast: PropTypes.bool,
};

export default InviteEmployeeRosterCard;
