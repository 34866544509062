import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import bagLogo from '../../../assets/Jobs.png';
import theme from '../../../theme';

const CandidateEmptyCard = () => (
  <Card
    sx={{
      width: '100%',
      maxWidth: 510,
      height: 370,
      margin: '15% auto',
      backgroundColor: `${theme.emptyCard.bgColor}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <CardContent>
      <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
        <img src={bagLogo} alt="workbag" />
      </Grid>
      <Typography
        variant="h4"
        sx={{
          color: `${theme.emptyCard.headerColor}`,
          fontFamily: 'Barlow-800',
          fontSize: '30',
        }}
        align="center"
      >
        Oh,no!
      </Typography>
      <Typography
        variant="body2"
        align="center"
        sx={{ color: `${theme.emptyCard.textColor}`, fontFamily: 'Barlow-500' }}
      >
        There are no jobs listed at this time.
        <br />
        Check back soon to look for upcoming jobs.
      </Typography>
    </CardContent>
  </Card>
);

export default CandidateEmptyCard;
