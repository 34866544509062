import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import Button, { BUTTON_VARIANT } from '../../../../../components/Button';
import IconButton from '../../../../../components/IconButton';
import theme, { navigationBarHeight } from '../../../../../theme';

const RootBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(2, 8),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
}));

const HeaderBox = styled(Box)(() => ({
  width: '100%',
}));

const HeaderBoxTypography = styled(Box)(() => ({
  fontSize: `${theme.components.workflow.typography.defaultTitleFontSize}`,
  fontFamily: `${theme.components.workflow.typography.boldFont}`,
  color: `${theme.components.workflow.palette.font.default.transitionPageFontColor}`,
}));
const DescriptionBoxTypgraphy = styled(Box)(() => ({
  fontSize: '16px',
  fontFamily: 'Barlow',
  color: '#333357',
}));

const BasicDrawer = ({
  headerIcon,
  header,
  subHeader,
  content,
  isOpen,
  onClose,
  actionButton,
  goBackButton,
}) => (
  <Drawer
    anchor="right"
    open={isOpen}
    onClose={onClose}
    PaperProps={{ sx: { maxWidth: '576px', paddingTop: navigationBarHeight, height: '100vh' } }}
  >
    <HeaderBox>
      <IconButton
        iconElement={<CloseIcon />}
        onClick={() => onClose()}
        size="small"
        sx={{ marginLeft: 0.5, marginTop: 0.5, position: 'fixed' }}
      />
    </HeaderBox>
    <RootBox>
      {headerIcon}
      <HeaderBoxTypography>{`${header}`}</HeaderBoxTypography>
      <DescriptionBoxTypgraphy>{`${subHeader}`}</DescriptionBoxTypgraphy>
      {content}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          alignSelf: 'flex-end',
        }}
      >
        {goBackButton || (
          <Box>
            <Button
              onClick={onClose}
              variant={BUTTON_VARIANT.OUTLINED}
              text={LanguageConverter('button.goBack')}
            />
          </Box>
        )}

        <Box>{actionButton}</Box>
      </Box>
    </RootBox>
  </Drawer>
);

BasicDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  actionButton: PropTypes.shape({}),
  goBackButton: PropTypes.shape({}),
  headerIcon: PropTypes.shape({}),
  header: PropTypes.string,
  subHeader: PropTypes.string,
  selectedJob: PropTypes.shape({}),
  content: PropTypes.shape({}),
};

export default BasicDrawer;
