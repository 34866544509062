import { get } from 'lodash/fp';

import getDepartmentsRequestSchema from '@careerstart/wae-common/schema/corporation/department/read.post.req.json';

import SearchableSelectDropdownFilter, {
  SEARCHBAR_BACKGROUND,
} from '../../../components/Filters/SearchableSelectDropdownFilter';
import SelectDropdownFilter, {
  SELECTDROPDOWN_BACKGROUND,
} from '../../../components/Filters/SelectDropdownFilter';
import TimeRangePickerFilter from '../../../components/Filters/TimeRangePickerFilter';

const statusFilter = {
  customFilter: {
    background: SELECTDROPDOWN_BACKGROUND.LIGHT,
    field: 'status',
    operation: 'equals',
    getOptionLabel: (option) => get(['name'], option),
    options: [
      { name: 'Approved', value: 'approved' },
      { name: 'Not Approved', value: 'pending' },
      { name: 'Disputed', value: 'disputed' },
      { name: 'Resolved', value: 'resolved' },
      { name: 'Finalized', value: 'finalized' },
    ],
    placeholder: 'Status',
    type: SelectDropdownFilter,
  },
};

const timeRangeFilter = {
  customFilter: {
    field: { start: 'startTime', end: 'startTime' },
    operation: { start: 'after', end: 'before' },
    placeholder: 'Start Time Range',
    type: TimeRangePickerFilter,
  },
};
const candidateFilter = {
  customFilter: {
    background: SEARCHBAR_BACKGROUND.LIGHT,
    field: 'candidate',
    operation: 'equalsID',
    optionsAPICallback: {
      httpMethod: 'POST',
      route: 'users/read',
      generateBody: (searchTerm) => ({
        filters: [
          { operation: 'icontains', field: 'name', value: searchTerm },
          { operation: 'equals', field: 'role', value: 'candidate' },
        ].filter((i) => i),
      }),
    },
    getOptionLabel: (option) => get(['name'], option),
    placeholder: 'Employee',
    type: SearchableSelectDropdownFilter,
  },
};
export const adminRecruiterAdditionalFilters = (corpId) => [
  statusFilter,
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'corporation',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'corporations/read',
        generateBody: (searchTerm) => ({
          filters: [{ operation: 'icontains', field: 'name', value: searchTerm }],
        }),
      },
      getOptionLabel: (option) => get(['name'], option),
      placeholder: 'Corporation',
      type: SearchableSelectDropdownFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'department',
      disabled: !corpId,
      conditionalSearchTerm: corpId,
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'corporations/departments/read',
        generateBody: (searchTerm) => ({
          ...(corpId && { corporation: corpId }),
          filters: [
            searchTerm && { operation: 'icontains', field: 'name', value: searchTerm },
          ].filter((i) => i),
        }),
        contract: getDepartmentsRequestSchema,
      },
      getOptionLabel: (option) => get(['name'], option),
      placeholder: 'Department',
      type: SearchableSelectDropdownFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      conditionalSearchTerm: corpId,
      disabled: !corpId,
      field: 'location',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'locations/read',
        generateBody: (searchTerm) => ({
          filters: [
            searchTerm && { operation: 'icontains', field: 'address', value: searchTerm },
            corpId && {
              operation: 'equalsID',
              field: 'corporation._id',
              value: corpId,
            },
          ].filter((i) => i),
        }),
      },
      getOptionLabel: (option) => `${get(['address'], option)} - ${get(['description'], option)}`,
      placeholder: 'Location',
      type: SearchableSelectDropdownFilter,
    },
  },
  timeRangeFilter,
  candidateFilter,
];

export const employerAdditionalFilters = (employerCorpId) => [
  statusFilter,
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'department',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'corporations/departments/read',
        generateBody: (searchTerm) => ({
          corporation: employerCorpId,
          filters: [
            searchTerm && { operation: 'icontains', field: 'name', value: searchTerm },
          ].filter((i) => i),
        }),
        contract: getDepartmentsRequestSchema,
      },
      getOptionLabel: (option) => get(['name'], option),
      placeholder: 'Department',
      type: SearchableSelectDropdownFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'location',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'locations/read',
        generateBody: (searchTerm) => ({
          filters: [
            searchTerm && { operation: 'icontains', field: 'address', value: searchTerm },
            {
              operation: 'equalsID',
              field: 'corporation._id',
              value: employerCorpId,
            },
          ].filter((i) => i),
        }),
      },
      getOptionLabel: (option) => `${get(['address'], option)} - ${get(['description'], option)}`,
      placeholder: 'Location',
      type: SearchableSelectDropdownFilter,
    },
  },
  timeRangeFilter,
  candidateFilter,
];
