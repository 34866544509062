import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import WhenFieldChanges from '../../../../components/Form/WhenFieldChanges';
import { getUserTimeZone } from '../../../../utils';
import { SHIFT_TIME_ALERT_TYPES } from '../../constDicts';
import { customShift, isWeekOT, shiftTimeValidation } from '../../helpers';
import FormSectionDescription from '../CustomComponents/FormSectionDescription';
import FormSectionHeader from '../CustomComponents/FormSectionHeader';
import ShiftTimeAlert from '../CustomComponents/ShiftTimeAlert';
import { endTimeField2, shiftField, startTimeField2 } from '../FormFieldData/CreateJobFields';

const ShiftTime = ({ wrapperSx }) => {
  const formState = useFormState();
  const isCustomShift = formState?.values?.shift?.name === customShift?.name;
  const shouldShowShiftDropdown = formState?.values?.positionTemplate?.shifts.length > 0;
  const shouldShowTimeFields = !shouldShowShiftDropdown || isCustomShift;

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const shouldShowMaxTimeAlert =
    shiftTimeValidation(formState?.values?.endTime, formState?.values) ===
    'error.jobCreate.shiftLength';

  const shouldShowOverTimeAlert = isWeekOT(formState?.values);

  const alertType =
    (shouldShowMaxTimeAlert && SHIFT_TIME_ALERT_TYPES.MAX) ||
    (shouldShowOverTimeAlert && SHIFT_TIME_ALERT_TYPES.OT) ||
    null;

  return (
    <Box
      sx={{
        width: '100%',
        ...wrapperSx,
      }}
    >
      <WhenFieldChanges
        field="shift"
        changeShouldOccur
        set="startTime"
        to={{ value: formState?.values?.shift?.start }}
      />
      <WhenFieldChanges
        field="shift"
        changeShouldOccur
        set="endTime"
        to={{ value: formState?.values?.shift?.end }}
      />
      <FormSectionHeader messageKey="job.create.shift.header" />
      <FormSectionDescription
        messageKey="job.create.timeZone.desc"
        customText={getUserTimeZone()}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: smallScreen ? 'column' : 'row',
          gap: theme.spacing(1),
        }}
      >
        {shouldShowShiftDropdown && (
          <>
            {buildFormField(shiftField({ positionTemplate: formState?.values?.positionTemplate }))}
          </>
        )}

        {shouldShowTimeFields && (
          <Box
            sx={{
              display: 'flex',
              gap: theme.spacing(1),
              width: smallScreen && '100%',
            }}
          >
            {buildFormField(startTimeField2({ shouldErrorColor: alertType }))}
            {buildFormField(endTimeField2({ shouldErrorColor: alertType }))}
          </Box>
        )}
      </Box>
      {alertType && <ShiftTimeAlert alertType={alertType} />}
    </Box>
  );
};

ShiftTime.propTypes = { wrapperSx: PropTypes.shape({}) };

export default ShiftTime;
