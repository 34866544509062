import theme from '../../../theme';
import FormPunchPicker from '../../Form/FormPunchPicker';
import FormTypographyBox from '../../Form/FormTypographyBox';
import { composeValidators } from '../../Form/validations';

import {
  allOutsAfterIn,
  allPunchInExist,
  allPunchOutExistButLast,
  hasNoOverlaps,
} from './FormFieldValidations';

const workflow = theme?.components?.workflow;
const workflowTypography = workflow?.typography;
const timecardEdit = workflow?.timecardEdit;
const timecardEditPalette = timecardEdit?.palette;
const timecardEditTypography = timecardEdit?.typography;

const workFlowHeaderStyling = {
  fontSize: workflowTypography?.defaultTitleFontSize,
  fontFamily: workflowTypography?.boldFont,
  color: workflowTypography?.defaultLastPageFontColor?.default, // TODO: there's no such path, ask for design and update!
};
const workFlowSubHeaderStyling = {
  fontSize: workflowTypography?.typography?.defaultSubtitleFontSize, // TODO: there's no such path, ask for design and update!
  fontFamily: workflowTypography?.typography?.defaultFont,
  color: '#333357',
  margin: theme.spacing(-1, 0, 0, 0),
};

const dayAndDateHeaderStyling = {
  fontSize: timecardEditTypography?.dayAndDateFontSize,
  fontFamily: timecardEditTypography?.dayAndDatefontFamily,
  color: timecardEditPalette?.dayAndDatefontColor,
};
const descriptionHeaderStyling = {
  fontSize: timecardEditTypography?.descriptionFontSize,
  fontFamily: timecardEditTypography?.descriptionFontFamily,
  color: timecardEditPalette?.descriptionNameFontColor,
};

const shiftTextStyling = {
  fontSize: timecardEditTypography?.shiftTImeFontSize,
  fontFamily: timecardEditTypography?.shiftTimeFontFamily,
  color: timecardEditPalette?.shiftTimeFontColor,
  margin: theme.spacing(-2.5, 0, 0),
};

const FormHeaderStyle = {
  fontSize: '18px',
  fontFamily: 'Barlow-700',
  color: '#000033',
};
const formSubHeaderStyle = {
  fontSize: '16px',
  fontFamily: 'Barlow',
  color: '#333357',
  margin: theme.spacing(-1, 0, 0, 0),
};

export const formFieldData = [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'editTimeWorkFlowHeader',
      name: 'editTimeWorkFlowHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'editTimeWorkFlowSubHeader',
      name: 'editTimeWorkFlowSubHeader',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'date',
      name: 'date',
      sx: dayAndDateHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'order',
      name: 'order',
      sx: { ...descriptionHeaderStyling, margin: theme.spacing(-2.5, 0, 0, 0) },
    },

    {
      ComponentType: FormTypographyBox,
      key: 'corporation',
      name: 'corporation',
      sx: { ...descriptionHeaderStyling, margin: theme.spacing(-3, 0, 0, 0) },
    },

    {
      ComponentType: FormTypographyBox,
      key: 'shiftName',
      name: 'shiftName',
      sx: descriptionHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'shiftTime',
      name: 'shiftTime',
      sx: shiftTextStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'fullNameHeader',
      name: 'fullNameHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'fullNameSubHeader',
      name: 'fullNameSubHeader',
      sx: formSubHeaderStyle,
    },

    {
      ComponentType: FormPunchPicker,
      label: 'Punches',
      name: 'punches',
      validate: composeValidators(
        allPunchInExist,
        allPunchOutExistButLast,
        allOutsAfterIn,
        hasNoOverlaps
      ),
    },
  ],
];

export const readOnlyValues = {
  editTimeWorkFlowHeader: 'Edit Time',
  editTimeWorkFlowSubHeader:
    'Edit existing punches or add additional time to an employee’s punch card.',
};
