import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import FormEmployeeInviteSelection from '../../../../components/Form/FormEmployeeInviteSelection/FormEmployeeInviteSelection';
import FormRadioButtons from '../../../../components/Form/FormRadioButtons/FormRadioButtons';
import FormSwitch from '../../../../components/Form/FormSwitch';
import FormTextField from '../../../../components/Form/FormTextField/FormTextField';
import {
  composeValidators,
  isAnObject,
  isGreaterThan,
  isGreaterThanOrEqual,
  isInteger,
  isRequiredFieldEmpty,
  max25InvitedEmployeeValidation,
} from '../../../../components/Form/validations';
import SearchBarSingle from '../../../../components/SearchBarV3/SearchBarSingle';
import { SEARCHBAR_BACKGROUND } from '../../../../components/SearchBarV3/styles';
import { BLACK } from '../../../../theme/colorConstants';
import { minutesFromMidnightToReadableTime } from '../../../../utils';
import { APPROVAL_VALUES, BACKFILL_VALUES, TIME_FIELDS_ERRORS } from '../../constDicts';
import {
  customShift,
  getAllValidShiftTimes,
  isValidDateRange,
  shiftTimeValidation,
} from '../../helpers';
import CadenceField from '../CustomComponents/CadenceField';
import JobRangeForForm from '../CustomComponents/DateRangeField/JobRangeForForm';

const OverstaffOpeningsValidator = (value) => {
  // when user does not enter anything.
  // we consider this is a valid input cause Overstaff Openings is not required
  if (value === null || value === undefined || value === '') {
    // if the value is empty this is good consider this is a valid input
    // because this input area accept empty
    return undefined;
  }
  // when user input something, we check this input is an int or >=0
  return composeValidators(isInteger, isGreaterThanOrEqual(0))(value);
};

const radioButtonHasValue = (value) => {
  if (value === null || value === undefined) {
    return 'error.field.empty';
  }
  return undefined;
};

export const doesWorkDayHaveAnyTruthy = (value) =>
  value && Object.values(value).some((val) => val === true)
    ? undefined
    : 'job.create.cadence.empty';

export const corporationField = (value) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  preDefinedOptions: [],
  data: null,
  key: 'id',
  name: 'corporation',
  testId: 'corporation',
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Corporation',
  fullWidth: true,
  onSubmitApiCallData: {
    httpMethod: 'POST',
    route: 'corporations/read',
  },
  optionDisplayField: 'name',
  validate: isAnObject(value),
});

export const positionTemplateField = ({ corporationId, value }) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  preDefinedOptions: [],
  data: {
    filters: [
      {
        field: 'corporation._id',
        value: corporationId,
        operation: 'equalsID',
      },
      {
        field: 'approved',
        value: true,
        operation: 'equals',
      },
    ],
  },
  name: 'positionTemplate',
  testId: 'positionTemplate',
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Position',
  fullWidth: true,
  onSubmitApiCallData: {
    httpMethod: 'POST',
    route: 'position-templates/read',
  },
  optionDisplayField: 'name',
  disabled: !corporationId,
  validate: isAnObject(value),
});

export const locationField = ({ positionTemplate, value }) => {
  const locations = positionTemplate?.locations.map((location) => ({
    address: location.address,
    _id: location._id,
    description: location.description,
  }));

  return {
    ComponentType: SearchBarSingle,
    background: SEARCHBAR_BACKGROUND.LIGHT,
    dropDownOptions: locations || [],
    data: null,
    name: 'location',
    testId: 'location',
    required: true,
    variant: 'standard',
    optionLabel: 'name',
    placeholder: 'Location',
    fullWidth: true,
    optionDisplayField: 'description',
    disabled: !positionTemplate,
    validate: isAnObject(value),
  };
};
export const departmentField = ({ positionTemplate, value }) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  dropDownOptions: positionTemplate?.departments || [],
  data: null,
  name: 'department',
  testId: 'department',
  required: true,
  variant: 'standard',
  optionLabel: 'name',
  placeholder: 'Department',
  fullWidth: true,
  optionDisplayField: 'name',
  disabled: !positionTemplate,
  validate: isAnObject(value),
});

export const daysOfWeekField = ({ shouldErrorColor }) => ({
  ComponentType: CadenceField,
  name: 'daysOfWeek',
  required: true,
  validate: doesWorkDayHaveAnyTruthy,
  testId: 'cadence',
  data: [
    {
      label: 'Sun',
      value: 'sunday',
    },
    {
      label: 'Mon',
      value: 'monday',
    },
    {
      label: 'Tue',
      value: 'tuesday',
    },
    {
      label: 'Wed',
      value: 'wednesday',
    },
    {
      label: 'Thu',
      value: 'thursday',
    },
    {
      label: 'Fri',
      value: 'friday',
    },
    {
      label: 'Sat',
      value: 'saturday',
    },
  ],
  shouldErrorColor,
});

export const dateRangeField = () => ({
  ComponentType: JobRangeForForm,
  key: 'dateRange',
  name: 'dateRange',
  testId: 'dateRange',
  validate: isValidDateRange,
});

const reformatShifts = (shifts) =>
  shifts.map((shift) => {
    if (shift?.name === customShift?.name) {
      return {
        ...shift,
        displayLabel: LanguageConverter('job.create.shift.custom'),
      };
    }

    return {
      ...shift,
      displayLabel: `${minutesFromMidnightToReadableTime(
        shift?.start
      )} - ${minutesFromMidnightToReadableTime(shift?.end)}`,
    };
  });

export const shiftField = ({ positionTemplate, value }) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  dropDownOptions: reformatShifts([...(positionTemplate?.shifts || []), customShift]),
  data: null,
  name: 'shift',
  testId: 'shift',
  required: true,
  variant: 'standard',
  optionLabel: 'displayLabel',
  placeholder: 'Shift',
  sx: {
    minWidth: '220px',
    maxWidth: '308px',
  },
  optionDisplayField: 'displayLabel',
  validate: isAnObject(value),
});

export const startTimeField2 = ({ sx, shouldErrorColor }) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  dropDownOptions: getAllValidShiftTimes(),
  data: null,
  name: 'startTime',
  testId: 'startTime',
  required: true,
  variant: 'standard',
  optionLabel: 'displayLabel',
  placeholder: 'Start',
  sx: {
    minWidth: '150px',
    ...(sx || {}),
  },
  optionDisplayField: 'displayLabel',
  EndIcon: AccessTimeIcon,
  customIconColor: BLACK[80],
  validate: (val, formVals) => shiftTimeValidation(val, formVals),
  shouldErrorColor,
  errorsToHideText: Object.values(TIME_FIELDS_ERRORS),
});

export const endTimeField2 = ({ sx, shouldErrorColor }) => ({
  ComponentType: SearchBarSingle,
  background: SEARCHBAR_BACKGROUND.LIGHT,
  dropDownOptions: getAllValidShiftTimes(),
  data: null,
  name: 'endTime',
  testId: 'endTime',
  required: true,
  variant: 'standard',
  optionLabel: 'displayLabel',
  placeholder: 'End',
  sx: {
    minWidth: '150px',
    ...(sx || {}),
  },
  optionDisplayField: 'displayLabel',
  validate: (val, formVals) => shiftTimeValidation(val, formVals),
  EndIcon: AccessTimeIcon,
  customIconColor: BLACK[80],
  shouldErrorColor,
  errorsToHideText: Object.values(TIME_FIELDS_ERRORS),
});

export const openingsVaryField = () => ({
  ComponentType: FormSwitch,
  name: 'openingsVary',
  testId: 'openingsVary',
  label: 'Num openings varies',
  xs: 2,
});

export const sameWorkersField = () => ({
  ComponentType: FormSwitch,
  name: 'sameWorkers',
  testId: 'sameWorkers',
  label: 'Same workers',
  xs: 2,
});

export const backfillField = () => ({
  ComponentType: FormRadioButtons,
  name: 'allowBackfill',
  testId: 'allowBackfill',
  label: 'Allow backfill employees',
  type: 'radio',
  labels: [
    {
      name: LanguageConverter('job.create.consistentStaff.unavail.backfill'),
      value: BACKFILL_VALUES?.ALLOW,
    },
    {
      name: LanguageConverter('job.create.consistentStaff.unavail.dontBackfill'),
      value: BACKFILL_VALUES?.DONT_ALLOW,
    },
  ],
  xs: 2,
  direction: 'column',
  validate: radioButtonHasValue,
});

export const requireApplicationsField = () => ({
  ComponentType: FormRadioButtons,
  name: 'requireEmployerApproval',
  testId: 'requireEmployerApproval',
  label: 'TR',
  type: 'radio',
  labels: [
    { name: LanguageConverter('job.create.applications.true'), value: APPROVAL_VALUES.REQUIRE },
    {
      name: LanguageConverter('job.create.applications.false'),
      value: APPROVAL_VALUES.DONT_REQUIRE,
    },
  ],
  xs: 2,
  direction: 'column',
  validate: radioButtonHasValue,
});

// only show if admin
export const overStaffOpeningsField = ({ initialValue }) => ({
  ComponentType: FormTextField,
  name: 'numOverstaff',
  testId: 'numOverstaff',
  placeholder: LanguageConverter('jobOrder.enter'),
  validate: OverstaffOpeningsValidator,
  initialValue,
});

export const numOpeningsField = () => ({
  ComponentType: FormTextField,
  name: 'numOpenings',
  testId: 'numOpenings',
  validate: composeValidators(isInteger, isGreaterThan(0)),
  required: true,
  sx: {
    maxWidth: '100px',
    paddingLeft: '1px',
  },
});

export const inviteEmployeeField = ({ isInviteModalOpen, setIsInviteModalOpen }) => ({
  ComponentType: FormEmployeeInviteSelection,
  name: 'candidates',
  validate: composeValidators(max25InvitedEmployeeValidation, isRequiredFieldEmpty),
  required: true,
  isInviteOpen: isInviteModalOpen,
  setIsInviteOpen: setIsInviteModalOpen,
});
