import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import theme from '../../../../theme';

const HeaderBoxTypography = styled(Box)(() => ({
  fontSize: `${theme.components.trainingModule.typography.header.fontSize}`,
  fontFamily: `${theme.components.trainingModule.typography.header.fontFamily}`,
  color: `${theme.components.trainingModule.palette.header}`,
}));
const DescriptionBoxTypgraphy = styled(Box)(() => ({
  fontSize: `${theme.components.trainingModule.typography.text.fontSize}`,
  fontFamily: `${theme.components.trainingModule.typography.text.fontFamily}`,
  color: `${theme.components.trainingModule.palette.text}`,
  padding: theme.spacing(2, 0),
}));

const TrainingTextDescription = ({ contentProps }) => (
  <Box>
    <HeaderBoxTypography>{get('title', contentProps)}</HeaderBoxTypography>
    <DescriptionBoxTypgraphy>{get('text', contentProps)}</DescriptionBoxTypgraphy>
  </Box>
);

TrainingTextDescription.propTypes = {
  contentProps: PropTypes.shape({}),
};
export default TrainingTextDescription;
