import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Box from '@mui/material/Box';

import asyncService from '../../../../datahub/asyncService';
import { dispatchErrors } from '../../../../datahub/datahubApi';
import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import StyledCheckbox from '../../../components/CheckBox/StyledCheckbox';
import { Attendance } from '../../../components/RosterWidget/constants';
import selectUser from '../../../store/selectors/appSelector';
import { showSnackbar } from '../../app';

const generateAttendanceSwitchesData = (calcAttendance, user) => {
  const switchesData = [];
  if (!calcAttendance) {
    return [];
  }
  const shouldDisable = user.role !== UserRole.ADMIN;
  Object.keys(calcAttendance).forEach((key) => {
    switch (key) {
      case Attendance.LATE:
        calcAttendance[key]?.generatedBySystem &&
          switchesData.push({
            id: key,
            checked: !!calcAttendance[key]?.excused?.time,
            disabled: shouldDisable,
            label: 'timekeeping.attendance.late',
          });

        break;
      case Attendance.LEFT_EARLY:
        calcAttendance[key]?.generatedBySystem &&
          switchesData.push({
            id: key,
            checked: !!calcAttendance[key]?.excused?.time,
            disabled: shouldDisable,
            label: 'timekeeping.attendance.leftEarly',
          });

        break;
      case Attendance.PRESENT:
        calcAttendance[key]?.generatedBySystem &&
          switchesData.push({
            id: key,
            checked: false,
            disabled: true, // This switch will always be disabled
            label: 'timekeeping.attendance.present',
          });

        break;
      case Attendance.NCNS:
        calcAttendance[key]?.generatedBySystem &&
          switchesData.push({
            id: key,
            checked: !!calcAttendance[key]?.excused?.time,
            disabled: shouldDisable,
            label: 'timekeeping.attendance.ncns',
          });

        break;
      default:
        break;
    }
  });

  return switchesData;
};

const AttendanceReport = ({ placement, token, dispatch }) => {
  // Get placement data for current placement according to placementId
  const user = useSelector(selectUser);

  const calcAttendance = placement?.calcAttendance;

  const [switches, setSwitches] = useState([]);

  useEffect(() => {
    setSwitches(generateAttendanceSwitchesData(calcAttendance, user));
  }, [calcAttendance, setSwitches, user]);

  const handleAttendanceSwitchOnChange = (switchId, checked) => {
    // step1 set switch to other position and disable the button until current call finish
    setSwitches((prev) =>
      prev.map((s) => (s.id === switchId ? { ...s, checked: !s.checked, disabled: true } : s))
    );
    // step2 send BE call
    const submitAPICallData = {
      httpMethod: 'patch',
      route: 'placements',
    };
    const data = {
      id: placement?._id,
      fields: {
        calcAttendance: {
          [switchId]: !checked,
        },
      },
    };

    placement?._id &&
      asyncService({
        ...submitAPICallData,
        data,
        token,
        onSuccess: () => {
          dispatch(showSnackbar({ message: 'success.attendance.update' }));
          setSwitches((prev) =>
            prev.map((s) => (s.id === switchId ? { ...s, disabled: false } : s))
          );
        },
        onError: (responsePayload) => {
          // if call failed, change the switch status back
          dispatchErrors({
            dispatch,
            responsePayload,
            customMessage: 'error.attendance.update',
          });

          // change switch positon back
          setSwitches((prev) =>
            prev.map((s) =>
              s.id === switchId ? { ...s, checked: !s.checked, disabled: false } : s
            )
          );
        },
        dispatch,
      });
  };

  return placement ? (
    calcAttendance && (
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 8 }}>
        {switches &&
          switches.map((item) => (
            <Box flex={4} key={item.id}>
              <StyledCheckbox
                checked={item.checked}
                headerLabel={LanguageConverter(item.label)}
                rowLabel={item.checked ? LanguageConverter('timekeeping.attendance.excused') : null}
                disabled={item.disabled}
                handleOnChange={() => handleAttendanceSwitchOnChange(item.id, item.checked)}
              />
            </Box>
          ))}
      </Box>
    )
  ) : (
    <Box>
      <BackdropCircularProgress />
    </Box>
  );
};

AttendanceReport.propTypes = {
  token: PropTypes.string,
  placement: PropTypes.shape({
    calcAttendance: PropTypes.shape({}),
    _id: PropTypes.string,
  }),
  dispatch: PropTypes.func,
};

export default AttendanceReport;
