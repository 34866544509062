import { get } from 'lodash/fp';

import DNRReasons from '@careerstart/wae-common/src/main/constants/DNR-reasons';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {},

  usersMetrics: {
    recruiterCount: null,
    candidateCount: null,
    adminCount: null,
    employerCount: null,
  },

  jobOrdersMetrics: {
    jobsEntered: 0,
    jobsCompleted: 0,
    fillRatio: {
      ratio: 0,
      filled: 0,
      open: 0,
    },

    timeBasedJobOrderNumbers: [],
  },

  revenueMetrics: {
    total: 0,
    missing: 0,
    extra: 0,
  },

  employeesMetrics: {
    employeesHired: 0,
    dnrs: 0,
    dnas: 0,

    overallAttendance: [],

    doNotReturnBreakdown: {
      attendance: 0,
      noCallNoShow: 0,
      insubordination: 0,
      misconduct: 0,
      breachOfCompanyPolicy: 0,
      walkedOff: 0,
      unauthorizedPossession: 0,
      failureToFollowSupervisor: 0,
      failureToFollowProcedures: 0,
    },

    employeeRetention: [
      {
        shifts: 0,
        candidates: 0,
      },
    ],
  },
  loadingUserCount: false,

  jobOrderMetricsLoadingState: {
    loadingJobsEntered: false,
    loadingJobsCompleted: false,
    loadingFillRatio: false,
    loadingTimeBasedJobOrderNumbers: false,
  },
  loadingRevenueMetrics: false,

  employeeMetricsLoadingState: {
    loadingEmployeesHired: false,
    loadingDnrs: false,
    loadingDnas: false,
    loadingOverallAttendance: false,
    loadingDoNotReturnBreakdown: false,
    loadingEmployeeRetention: false,
  },
};

export const reportingSlice = createSlice({
  name: 'reportingSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    getUserCountByRole: (state) => {
      state.loadingUserCount = true;
    },
    getUserCountByRoleProcessed: (state, action) => {
      state.loadingUserCount = false;
      state.usersMetrics = get(['payload', 'data'], action);
    },
    getUserCountByRoleError: (state) => {
      state.loadingUserCount = false;
      state.usersMetrics = initialState.usersMetrics;
    },

    getJobsEntered: (state) => {
      state.jobOrderMetricsLoadingState.loadingJobsEntered = true;
    },
    getJobsEnteredProcessed: (state, action) => {
      const count = get(['payload', 'data', 'count'], action);
      state.jobOrderMetricsLoadingState.loadingJobsEntered = false;
      state.jobOrdersMetrics.jobsEntered = count;
    },
    getJobsEnteredError: (state) => {
      state.jobOrderMetricsLoadingState.loadingJobsEntered = false;
      state.jobOrdersMetrics.jobsEntered = initialState.jobOrdersMetrics.jobsEntered;
    },
    getJobsCompleted: (state) => {
      state.jobOrderMetricsLoadingState.loadingJobsCompleted = true;
    },
    getJobsCompletedProcessed: (state, action) => {
      const count = get(['payload', 'data', 'count'], action);
      state.jobOrderMetricsLoadingState.loadingJobsCompleted = false;
      state.jobOrdersMetrics.jobsCompleted = count;
    },
    getJobsCompletedError: (state) => {
      state.jobOrderMetricsLoadingState.loadingJobsCompleted = false;
      state.jobOrdersMetrics.jobsCompleted = initialState.jobOrdersMetrics.jobsCompleted;
    },

    getFillRatio: (state) => {
      state.jobOrderMetricsLoadingState.loadingFillRatio = true;
    },
    getFillRatioProcessed: (state, action) => {
      const data = get(['payload', 'data'], action);
      state.jobOrderMetricsLoadingState.loadingFillRatio = false;
      state.jobOrdersMetrics.fillRatio = {
        ratio: get(['fillRatio'], data),
        filled: get(['filled'], data),
        open: get(['open'], data),
      };
    },
    getFillRatioError: (state) => {
      state.jobOrderMetricsLoadingState.loadingFillRatio = false;
      state.jobOrdersMetrics.fillRatio = initialState.jobOrdersMetrics.fillRatio;
    },
    getTimeBasedJobOrderNumbers: (state) => {
      state.jobOrderMetricsLoadingState.loadingTimeBasedJobOrderNumbers = true;
    },
    getTimeBasedJobOrderNumbersProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);

      state.jobOrderMetricsLoadingState.loadingTimeBasedJobOrderNumbers = false;
      state.jobOrdersMetrics.timeBasedJobOrderNumbers = documents;
    },
    getTimeBasedJobOrderNumbersError: (state) => {
      state.jobOrderMetricsLoadingState.loadingTimeBasedJobOrderNumbers = false;
      state.jobOrdersMetrics.timeBasedJobOrderNumbers =
        initialState.jobOrdersMetrics.timeBasedJobOrderNumbers;
    },

    getJobOrderRevenue: (state) => {
      state.loadingRevenueMetrics = true;
    },
    getJobOrderRevenueProcessed: (state, action) => {
      const data = get(['payload', 'data'], action);
      state.loadingRevenueMetrics = false;
      state.revenueMetrics = data;
    },
    getJobOrderRevenueError: (state) => {
      state.loadingRevenueMetrics = false;
      // state.revenueMetrics = initialState.revenueMetrics;
    },

    getEmployeesHired: (state) => {
      state.employeeMetricsLoadingState.loadingEmployeesHired = true;
    },
    getEmployeesHiredProcessed: (state, action) => {
      const data = get(['payload', 'data'], action);
      state.employeeMetricsLoadingState.loadingEmployeesHired = false;
      state.employeesMetrics.employeesHired = data;
    },
    getEmployeesHiredError: (state) => {
      state.employeeMetricsLoadingState.loadingEmployeesHired = false;
      state.employeesMetrics.employeesHired = initialState.employeesMetrics.employeesHired;
    },
    getDnrs: (state) => {
      state.employeeMetricsLoadingState.loadingDnrs = true;
    },
    getDnrsProcessed: (state, action) => {
      const data = get(['payload', 'data'], action);
      state.employeeMetricsLoadingState.loadingDnrs = false;
      state.employeesMetrics.dnrs = data;
    },
    getDnrsError: (state) => {
      state.employeeMetricsLoadingState.loadingDnrs = false;
      state.employeesMetrics.dnrs = initialState.employeesMetrics.dnrs;
    },
    getDnas: (state) => {
      state.employeeMetricsLoadingState.loadingDnas = true;
    },
    getDnasProcessed: (state, action) => {
      const data = get(['payload', 'data'], action);
      state.employeeMetricsLoadingState.loadingDnas = false;
      state.employeesMetrics.dnas = data;
    },
    getDnasError: (state) => {
      state.employeeMetricsLoadingState.loadingDnas = false;
      state.employeesMetrics.dnas = initialState.employeesMetrics.dnas;
    },
    getOverallAttendance: (state) => {
      state.employeeMetricsLoadingState.loadingOverallAttendance = true;
    },
    getOverallAttendanceProcessed: (state, action) => {
      const attendance = get(['payload', 'data', 'attendance'], action);
      const updatedAttendance = attendance.map((i) =>
        get(['_id'], i) ? i : { ...i, _id: 'Other' }
      );
      state.employeeMetricsLoadingState.loadingOverallAttendance = false;
      state.employeesMetrics.overallAttendance = updatedAttendance;
    },
    getOverallAttendanceError: (state) => {
      state.employeeMetricsLoadingState.loadingOverallAttendance = false;
      state.employeesMetrics.overallAttendance = initialState.employeesMetrics.overallAttendance;
    },
    getDoNotReturnBreakdown: (state) => {
      state.employeeMetricsLoadingState.loadingDoNotReturnBreakdown = true;
    },

    getDoNotReturnBreakdownProcessed: (state, action) => {
      const dnr = get(['payload', 'data', 'dnr'], action);
      let updatedDNR = {};
      Object.keys(dnr).forEach((i) => {
        if (i === DNRReasons.Attendance) {
          updatedDNR = { ...updatedDNR, attendance: dnr[i] };
        } else if (i === DNRReasons.No_Call_No_Show) {
          updatedDNR = { ...updatedDNR, noCallNoShow: dnr[i] };
        } else if (i === DNRReasons.Insubordination) {
          updatedDNR = { ...updatedDNR, insubordination: dnr[i] };
        } else if (i === DNRReasons.Misconduct) {
          updatedDNR = { ...updatedDNR, misconduct: dnr[i] };
        } else if (i === DNRReasons.Breach_Of_Company_Policy) {
          updatedDNR = { ...updatedDNR, breachOfCompanyPolicy: dnr[i] };
        } else if (i === DNRReasons.Walked_Off) {
          updatedDNR = { ...updatedDNR, walkedOff: dnr[i] };
        } else if (i === DNRReasons.Unauthorized_Possession_Company_Property) {
          updatedDNR = { ...updatedDNR, unauthorizedPossession: dnr[i] };
        } else if (i === DNRReasons.Failure_To_Follow_Supervisor_Instruction) {
          updatedDNR = { ...updatedDNR, failureToFollowSupervisor: dnr[i] };
        } else if (i === DNRReasons.Failure_To_Follow_Operating_Procedures) {
          updatedDNR = { ...updatedDNR, failureToFollowProcedures: dnr[i] };
        }
      });
      state.employeeMetricsLoadingState.loadingDoNotReturnBreakdown = false;
      state.employeesMetrics.doNotReturnBreakdown = {
        ...initialState.employeesMetrics.doNotReturnBreakdown,
        ...updatedDNR,
      };
    },
    getDoNotReturnBreakdownError: (state) => {
      state.employeeMetricsLoadingState.loadingDoNotReturnBreakdown = false;
      state.employeesMetrics.doNotReturnBreakdown =
        initialState.employeesMetrics.doNotReturnBreakdown;
    },
    getEmployeeRetention: (state) => {
      state.employeeMetricsLoadingState.loadingEmployeeRetention = true;
    },
    getEmployeeRetentionProcessed: (state, action) => {
      state.employeeMetricsLoadingState.loadingEmployeeRetention = false;
      state.employeesMetrics.employeeRetention = get(['payload', 'data', 'documents'], action);
    },
    getEmployeeRetentionError: (state) => {
      state.employeeMetricsLoadingState.loadingEmployeeRetention = false;
      state.employeesMetrics.employeeRetention = initialState.employeesMetrics.employeeRetention;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const {
  setFilters,
  getUserCountByRole,
  getUserCountByRoleProcessed,
  getUserCountByRoleError,
  getJobsEntered,
  getJobsEnteredProcessed,
  getJobsEnteredError,
  getJobsCompleted,
  getJobsCompletedProcessed,
  getJobsCompletedError,
  getFillRatio,
  getFillRatioProcessed,
  getFillRatioError,
  getTimeBasedJobOrderNumbers,
  getTimeBasedJobOrderNumbersProcessed,
  getTimeBasedJobOrderNumbersError,
  getTotalSales,
  getTotalSalesProcessed,
  getTotalSalesError,
  getJobOrderRevenue,
  getJobOrderRevenueProcessed,
  getJobOrderRevenueError,
  getEmployeesHired,
  getEmployeesHiredProcessed,
  getEmployeesHiredError,
  getDnrs,
  getDnrsProcessed,
  getDnrsError,
  getDnas,
  getDnasError,
  getDnasProcessed,
  getOverallAttendance,
  getOverallAttendanceError,
  getOverallAttendanceProcessed,
  getDoNotReturnBreakdown,
  getDoNotReturnBreakdownError,
  getDoNotReturnBreakdownProcessed,
  getEmployeeRetention,
  getEmployeeRetentionProcessed,
  getEmployeeRetentionError,
} = reportingSlice.actions;

export const reportingReducer = reportingSlice.reducer;
