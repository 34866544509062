import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';

import Calendar from '../../assets/icons/Calendar.svg';
import theme from '../../theme';
import { PRIMARY_PURPLE } from '../../theme/colorConstants';
import { PRIMARY_FONT } from '../../theme/fontConstants';

const DateDisplayBox = ({ data }) => (
  <Grid
    item
    container
    justifyContent="space-between"
    alignItems="center"
    sx={{
      height: '40px',
      width: 'auto',
      backgroundColor: theme?.dateRangePickerFilter?.dateDisplayBox?.bgColor,
      borderRadius: '40px',
      margin: '16px',
      marginBottom: 0,
    }}
  >
    <Typography sx={theme?.dateRangePickerFilter?.dateDisplayBox?.typography}>{data}</Typography>
    <Box
      component="img"
      sx={{
        height: 24,
        width: 24,
      }}
      alt="Calendar"
      src={Calendar}
    />
  </Grid>
);

DateDisplayBox.propTypes = {
  data: PropTypes.string,
};

const DateRangePickerFilter = ({
  disabled,
  onValueChange,
  field,
  operation,
  placeholder,
  initialValue,
  wrapperSx,
}) => {
  const [value, setValue] = useState(initialValue || [(null, null)]);
  const [anchorEl, setAnchorEl] = useState(null);
  const initalDisplayValue = {
    start: (initialValue && new Date(initialValue[0]).toLocaleDateString('en-US')) || null,
    end: (initialValue && new Date(initialValue[1]).toLocaleDateString('en-US')) || null,
  };

  const [displayValue, setDisplayValue] = useState(initalDisplayValue || [(null, null)]);

  const OneDayInEpoch = 86400000;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleChange = (dateRange) => {
    setValue(dateRange);

    const dateInEpoch = dateRange.map((date, index) =>
      index === 0 ? { start: new Date(date).getTime() } : { end: new Date(date).getTime() }
    );
    const updatedDateRange = Object.assign({}, ...dateInEpoch);
    if (!(updatedDateRange.start && !updatedDateRange.end)) {
      const newValue = [
        {
          value: updatedDateRange.start ? updatedDateRange.start : null,
          field: field.start,
          operation: operation.start,
        },
        {
          value: updatedDateRange.end ? updatedDateRange.end + OneDayInEpoch : null,
          field: field.end,
          operation: operation.end,
        },
      ];
      onValueChange(newValue);
      const readableDateRange = dateRange.map((date, index) =>
        index === 0
          ? { start: date ? new Date(date).toLocaleDateString('en-US') : null }
          : { end: date ? new Date(date).toLocaleDateString('en-US') : null }
      );
      setDisplayValue(Object.assign({}, ...readableDateRange));
    }
  };
  const handleClick = (event) => {
    const currentTarget = event?.currentTarget;
    setAnchorEl(anchorEl ? null : currentTarget);
  };

  const styleForFormControl = {
    '& .MuiInputBase-input': {
      width: '84px',
      background: displayValue.start && displayValue.end && theme?.button?.palette?.primary,
      color: displayValue.start && displayValue.end && 'white',
      height: '0px',
      borderRadius: '6px',
      padding: '12px 4px',
    },
    '& .MuiInputBase-input-MuiOutlinedInput-input': {},
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme?.button?.palette?.primary,
      fontFamily: PRIMARY_FONT['400'],
      opacity: 1,
    },
    border: `1px solid ${theme?.dateRangePickerFilter?.borderColor?.focus}`,

    borderRadius: '5px',
    justifyContent: 'center',
    height: '28px',
    width: displayValue.end ? '290px' : '140px',
  };

  return (
    <>
      <TextField
        disabled={disabled}
        autoComplete="off"
        aria-describedby={id}
        sx={{ ...styleForFormControl, ...wrapperSx }}
        value={displayValue.start || ''}
        InputProps={{
          onClick: handleClick,
          startAdornment: (
            <InputAdornment position="start">
              <CalendarTodayOutlinedIcon sx={{ color: PRIMARY_PURPLE[70], width: '14px' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <Box sx={{ display: 'flex', alignItems: 'center', direction: 'row' }}>
              {displayValue.start ? (
                <>
                  <InputAdornment position="end">
                    <ArrowForwardIcon
                      style={{
                        color: theme?.button?.palette?.primary,
                        opacity: 1,
                        width: '14px',
                        height: '14px',
                        margin: '0px 6px 0px 2px',
                      }}
                    />
                    <TextField value={displayValue.end} />
                  </InputAdornment>
                  <CloseIcon
                    onClick={() => {
                      handleChange([null, null]);
                      setAnchorEl(null);
                    }}
                    sx={{
                      cursor: 'pointer',
                      width: '12px',
                      color: theme?.button?.palette?.primary,
                      marginLeft: '12px',
                    }}
                  />
                </>
              ) : (
                <AddIcon
                  style={{
                    color: theme?.button?.palette?.primary,
                    opacity: 1,
                    width: '13px',
                    height: '13px',
                    marginLeft: '2px',
                  }}
                />
              )}
            </Box>
          ),
        }}
        placeholder={placeholder}
      />

      <Popper open={open} placement="bottom-end" anchorEl={anchorEl} id={id}>
        <ClickAwayListener onClickAway={handleClick}>
          <Paper>
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: 'Check-in' }}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                calendars={1}
                value={value}
                onChange={handleChange}
                renderInput={(startProps) => <TextField {...startProps} />}
              />
            </LocalizationProvider>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DateRangePickerFilter;

DateRangePickerFilter.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  field: PropTypes.shape(),
  operation: PropTypes.shape(),
  initialValue: PropTypes.arrayOf(PropTypes.number),
  wrapperSx: PropTypes.shape({}),
};
