import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Autocomplete, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import KeyboardArrowDownIcon from '../../../assets/icons/KeyboardArrowDownIcon.svg';
import theme from '../../../theme';

const SELECTDROPDOWN_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const SelectDropdownFilter = ({
  field,
  operation,
  options,
  disableClearable,
  placeholder,
  onValueChange,
  reformatValue,
  getOptionLabel,
  background,
  initialValue = {},
  sx,
}) => {
  let initOption = null;

  if (initialValue && initialValue.value) {
    if (typeof initialValue.value === 'object') {
      initOption = options.find(
        (option) =>
          initialValue?.value[field] ===
          (reformatValue ? reformatValue(option.value) : option)[field]
      );
    } else {
      initOption = options.find(
        (option) =>
          initialValue?.value === (reformatValue ? reformatValue(option.value) : option)[field]
      );
    }
  }

  const [value, setValue] = useState(initOption);

  const [open, setOpen] = React.useState(false);
  switch (background) {
    case SELECTDROPDOWN_BACKGROUND.DARK:
      // Dark mode style here
      break;
    case SELECTDROPDOWN_BACKGROUND.LIGHT:
      // Light mode style here
      break;
    default:
      // Default mode style here
      break;
  }

  const styleForFormControl = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },
    '&:hover': {
      backgroundColor: !value && get(['searchBoxForFilter', 'bgColor', 'hover'], theme),
    },
    '&:focus-within': {
      backgroundColor: !value && get(['searchBoxForFilter', 'bgColor', 'focus'], theme),
      border: `1px solid ${get(['searchBoxForFilter', 'borderColor', 'focus'], theme)}`,
    },
    backgroundColor: value && get(['searchBoxForFilter', 'bgColor', 'complete'], theme),

    borderRadius: '40px',
    justifyContent: 'center',
    height: '40px',
    width: '200px',
    marginLeft: '8px',
  };

  const radioSx = {
    height: 22,
    width: 22,
    color: get(['button', 'palette', 'primary'], theme),
  };

  const handleChange = (event, newValue) => {
    if (reformatValue) {
      onValueChange([
        {
          value: newValue ? reformatValue(newValue.value) : null,
          field,
          operation,
        },
      ]);
    } else {
      onValueChange([{ value: newValue ? newValue.value : null, field, operation }]);
    }

    setValue(newValue);
  };

  return (
    <FormControl sx={styleForFormControl}>
      <Autocomplete
        onChange={handleChange}
        value={value}
        disableClearable={disableClearable}
        popupIcon={
          <Box
            component="img"
            sx={{
              height: 22,
              width: 22,
            }}
            alt="Stars"
            src={KeyboardArrowDownIcon}
          />
        }
        noOptionsText="No results found. Try searching for something else."
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
            }}
            placement="auto-start"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {getOptionLabel(option)}
            {selected ? (
              <RadioButtonCheckedIcon sx={radioSx} />
            ) : (
              <RadioButtonUncheckedIcon sx={radioSx} />
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            {...params}
            sx={sx}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center', direction: 'row' }}>
                  {params.InputProps.endAdornment}
                </Box>
              ),
            }}
          />
        )}
        isOptionEqualToValue={(option, val) =>
          option.name === val.name ||
          (reformatValue && reformatValue(option.value)[field] === val[field])
        }
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={options || []}
        getOptionLabel={(option) => getOptionLabel(option) || ''}
      />
    </FormControl>
  );
};

SelectDropdownFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  field: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ value: PropTypes.shape({}) }),
  ]),
  operation: PropTypes.string,
  onValueChange: PropTypes.func,
  reformatValue: PropTypes.func,
  onSubmitApiCallData: PropTypes.shape({}),
  getOptionLabel: PropTypes.func,
  sx: PropTypes.shape({}),
  background: PropTypes.string,
  disableClearable: PropTypes.bool,
};

export { SELECTDROPDOWN_BACKGROUND, SelectDropdownFilter };
