import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  positionTemplateListRowData: [],
  isLoading: false,
  locations: [],
  isLoadingLocations: false,
  certifications: [],
  isLoadingCertifications: false,
  corporations: [],
  isLoadingCorporations: false,
  isDeleteProcessing: false,
  isUpdateProcessing: false,
};

export const formatPositionTemplate = (positionTemplateEntry = {}) => {
  const { id, _id, ...rest } = positionTemplateEntry;
  return {
    id: id || _id,
    positionInfo: {
      name: get('name', positionTemplateEntry),
      description: get('description', positionTemplateEntry),
    },
    numLocations: size(rest.locations || []),
    numShifts: size(rest.shifts || []),
    numCertifications: size(rest.certifications || []),

    totalRowCount: 0,
    ...rest,
  };
};

export const positionTemplateListSlice = createSlice({
  name: 'positionTemplateListSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getPositionTemplateList: (state) => {
      state.isLoading = true;
    },
    getPositionTemplateListProcessed: (state, action) => {
      state.isLoading = false;

      const { count, documents } = action.payload.data;

      const updatedData = map(formatPositionTemplate, documents);

      state.totalRowCount = count;
      state.positionTemplateListRowData = updatedData;
    },
    getPositionTemplateListError: (state) => {
      state.isLoading = false;
    },
    deletePositionTemplate: (state) => {
      state.isDeleteProcessing = true;
    },
    deletePositionTemplateProcessed: (state) => {
      state.isDeleteProcessing = false;
    },
    deletePositionTemplateError: (state) => {
      state.isDeleteProcessing = false;
    },
    updatePositionTemplate: (state) => {
      state.isUpdateProcessing = true;
    },
    updatePositionTemplateProcessed: (state, action) => {
      const document = formatPositionTemplate(get(['payload', 'data', 'document'], action));
      state.isUpdateProcessing = false;
      state.positionTemplateListRowData = state.positionTemplateListRowData.map(
        (positionTemplate) =>
          positionTemplate.id === document.id ? formatPositionTemplate(document) : positionTemplate
      );
    },
    updatePositionTemplateError: (state) => {
      state.isUpdateProcessing = false;
    },
    getLocationsForPositionTemplateList: (state) => {
      state.isLoadingLocations = true;
    },
    getLocationsForPositionTemplateListProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);

      const locations = map((ln) => {
        const { _id, ...rest } = ln;
        return {
          id: _id,
          name: `${get('description', ln)} - ${get('address', ln)}`,
          ...rest,
        };
      }, documents);

      state.locations = locations;
      state.isLoadingLocations = false;
    },
    getLocationsForPositionTemplateListError: (state) => {
      state.isLoadingLocations = false;
    },
    getCertificationsForPositionTemplateList: (state) => {
      state.isLoadingCertifications = true;
    },
    getCertificationsForPositionTemplateListProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);

      const certifications = map((certification) => {
        const { _id, ...rest } = certification;
        return {
          id: _id,
          ...rest,
        };
      }, documents);
      state.certifications = certifications;
      state.isLoadingCertifications = false;
    },
    getCertificationsForPositionTemplateListError: (state) => {
      state.isLoadingCertifications = false;
    },
    getCorporationsForPositionTemplateList: (state) => {
      state.isLoadingCorporations = true;
    },
    getCorporationsForPositionTemplateListProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);

      const corporations = map((corporation) => {
        const { _id, ...rest } = corporation;
        return {
          id: _id,
          ...rest,
        };
      }, documents);
      state.corporations = corporations;
      state.isLoadingCorporations = false;
    },
    getCorporationsForPositionTemplateListError: (state) => {
      state.isLoadingCorporations = false;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const positionTemplateListReducer = positionTemplateListSlice.reducer;

export const {
  getPositionTemplateList,
  getPositionTemplateListError,
  getPositionTemplateListProcessed,
  deletePositionTemplate,
  deletePositionTemplateError,
  deletePositionTemplateProcessed,
  updatePositionTemplate,
  updatePositionTemplateProcessed,
  updatePositionTemplateError,
  getLocationsForPositionTemplateList,
  getLocationsForPositionTemplateListProcessed,
  getLocationsForPositionTemplateListError,
  getCertificationsForPositionTemplateList,
  getCertificationsForPositionTemplateListProcessed,
  getCertificationsForPositionTemplateListError,
  getCorporationsForPositionTemplateList,
  getCorporationsForPositionTemplateListProcessed,
  getCorporationsForPositionTemplateListError,
} = positionTemplateListSlice.actions;
