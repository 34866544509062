import {
  apiDelete as apiDeleteCommon,
  apiGet as apiGetCommon,
  apiPatch as apiPatchCommon,
  apiPost as apiPostCommon,
  apiPut as apiPutCommon,
} from '@careerstart/wae-common/src/datahub/axios';

export const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS || 'https://dev.core.waeapp.com/';

export const apiPost = (route, data, token) => apiPostCommon(apiUrl, route, data, token);

export const apiPut = (route, data, token) => apiPutCommon(apiUrl, route, data, token);

export const apiPatch = (route, data, token) => apiPatchCommon(apiUrl, route, data, token);
export const apiGet = (route, data, token, axiosOptions) =>
  apiGetCommon(apiUrl, route, data, token, axiosOptions);

export const apiDelete = (route, data, token) => apiDeleteCommon(apiUrl, route, data, token);
