import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCorporationList = (state) => get('corporationList', state);

export const selectCorporations = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.corporations
);

export const selectIsLoading = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.isLoading
);
export const selectTotalRowCount = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.totalRowCount
);
export const selectDepartments = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.departments
);
export const selectIsLoadingDepartments = createSelector(
  selectCorporationList,
  (corporationList) => corporationList.isLoadingDepartments
);
