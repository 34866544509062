import { get } from 'lodash/fp';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invitedJob: {},
  loadingInvitedJob: false,
  skills: [],
  loadingSkills: false,
  postInviteRespondSuccessful: false,
  postingInviteRespond: false,
};
export const candidateJobInvitationSlice = createSlice({
  name: 'candidateJobInvitationSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    setJobInviteToInitState: () => initialState,
    getInvitedJobOrder: (state) => {
      state.loadingInvitedJob = true;
    },
    getInvitedJobOrderProcessed: (state, action) => {
      const job = get(['payload', 'data', 'documents'], action)[0];
      state.loadingInvitedJob = false;
      state.invitedJob = job;
    },
    getInvitedJobOrderError: (state) => {
      state.loadingInvitedJob = false;
    },
    getSkills: (state) => {
      state.loadingSkills = true;
    },
    getSkillsProcessed: (state, action) => {
      const skills = get(['payload', 'data', 'documents'], action);
      state.loadingSkills = false;
      state.skills = skills;
    },
    getSkillsError: (state) => {
      state.loadingSkills = false;
    },
    postInviteRespond: (state) => {
      state.postingInviteRespond = true;
    },
    postInviteRespondProcessed: (state) => {
      state.postingInviteRespond = false;
      state.postInviteRespondSuccessful = true;
    },
    postInviteRespondError: (state) => {
      state.postingInviteRespond = false;
    },
  },
});

export const candidateJobInvitationReducer = candidateJobInvitationSlice.reducer;

export const {
  setJobInviteToInitState,
  getInvitedJobOrder,
  getInvitedJobOrderProcessed,
  getInvitedJobOrderError,
  getSkills,
  getSkillsProcessed,
  getSkillsError,
  postInviteRespond,
  postInviteRespondProcessed,
  postInviteRespondError,
} = candidateJobInvitationSlice.actions;
