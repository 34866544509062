import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import { validTimeFormat } from '../TimeKeepingEditDrawer/FormFieldData/FormFieldValidations';

import { PunchInputGrid, styleForTextField } from './Styles/PunchPickerStyles';
import FormDateTimePickerField from './FormDateTimePickerField';

const FormPunchSetField = ({ listData, punchData, index, handlePunchChange }) => {
  const [punchError, setPunchError] = useState({ in: undefined, out: undefined });

  const punchInputOnChange = (value, punchInOut) => {
    const dateObject = value ? new Date(value.toString()) : null;
    const epochValue = dateObject ? dateObject.getTime() : null;
    const newPunchData = { ...punchData, [punchInOut]: { stamp: epochValue } };

    if (value) {
      const validTime = validTimeFormat(value);
      setPunchError((prevVal) => ({ ...prevVal, [punchInOut]: validTime }));
    }

    const transformedArray = listData.map((item, listIndex) =>
      listIndex !== index ? item : newPunchData
    );

    handlePunchChange(transformedArray);
  };

  return (
    <PunchInputGrid container spacing={1}>
      <Grid item xs={6}>
        <FormDateTimePickerField
          sx={styleForTextField}
          label="Clock In"
          onChange={(value) => punchInputOnChange(value, 'in')}
          size="small"
          value={punchData?.in?.stamp ? new Date(punchData?.in?.stamp) : null}
          error={punchError.in}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={6}>
        <FormDateTimePickerField
          sx={styleForTextField}
          label="Clock Out"
          onChange={(value) => punchInputOnChange(value, 'out')}
          size="small"
          value={punchData?.out?.stamp ? new Date(punchData?.out?.stamp) : null}
          error={punchError.out}
          variant="outlined"
        />
      </Grid>
    </PunchInputGrid>
  );
};

FormPunchSetField.propTypes = {
  punchData: PropTypes.shape({
    in: PropTypes.shape({ stamp: PropTypes.number }),
    out: PropTypes.shape({ stamp: PropTypes.number }),
  }),
  index: PropTypes.number,
  listData: PropTypes.arrayOf(PropTypes.shape({})),
  handlePunchChange: PropTypes.func,
};

export default FormPunchSetField;
