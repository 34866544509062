import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const metricsThemePath = ['views', 'reporting', 'typography', 'metricsHeader'];

const HeaderGrid = styled(Grid)(({ theme }) => ({
  height: '26px',
  lineHeight: '26px',
  fontWeight: get([...metricsThemePath, 'fontWeight'], theme),
  fontSize: get([...metricsThemePath, 'fontSize'], theme),
  fontFamily: get([...metricsThemePath, 'fontFamily'], theme),
  fontColor: get([...metricsThemePath, 'fontColor'], theme),
  paddingBottom: '24px',
}));

const MetricsHeader = ({ sx, text }) => (
  <HeaderGrid item sx={{ width: '100%', ...sx }}>
    {text}
  </HeaderGrid>
);

MetricsHeader.propTypes = {
  sx: PropTypes.shape({}),
  text: PropTypes.string,
};

export default MetricsHeader;
