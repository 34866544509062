import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectReporting = (state) => get('reporting', state);

export const selectIsLoading = createSelector(selectReporting, (reporting) => reporting.isLoading);
export const selectFilters = createSelector(selectReporting, (reporting) => reporting.filters);
export const selectUsersMetrics = createSelector(
  selectReporting,
  (reporting) => reporting.usersMetrics
);

export const selectRevenueMetrics = createSelector(
  selectReporting,
  (reporting) => reporting.revenueMetrics
);

export const selectJobOrdersMetrics = createSelector(
  selectReporting,
  (reporting) => reporting.jobOrdersMetrics
);

export const selectEmployeesMetrics = createSelector(
  selectReporting,
  (reporting) => reporting.employeesMetrics
);

export const selectLoadingUserCount = createSelector(
  selectReporting,
  (reporting) => reporting.loadingUserCount
);
export const selectJobOrderMetricsLoadingState = createSelector(
  selectReporting,
  (reporting) => reporting.jobOrderMetricsLoadingState
);
export const selectEmployeeMetricsLoadingState = createSelector(
  selectReporting,
  (reporting) => reporting.employeeMetricsLoadingState
);
export const selectLoadingRevenueMetrics = createSelector(
  selectReporting,
  (reporting) => reporting.loadingRevenueMetrics
);
