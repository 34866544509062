import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import logo from '../../../../assets/icons/KeyboardArrowDownIcon.svg';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';

const RosterBlock = ({ testId, title, rosterContent }) => (
  <Accordion defaultExpanded sx={{ boxShadow: 'none' }} data-testid={testId}>
    <AccordionSummary
      expandIcon={<img src={logo} alt="Expand icon" />}
      aria-controls="panel1-content"
      id="panel1-header"
      style={{ display: 'flex', alignItems: 'center', padding: 0 }}
    >
      <Box display="flex" alignItems="center" flexGrow={1}>
        <Typography
          style={{
            fontFamily: PRIMARY_FONT[500],
            fontWeight: 'bold',
            marginRight: '10px',
            fontSize: '16px',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontFamily: PRIMARY_FONT[400],
          alignSelf: 'center',
          fontSize: '14px',
          textTransform: 'uppercase',
          marginRight: '12px',
        }}
      >
        {LanguageConverter('button.close')}
      </Typography>
    </AccordionSummary>
    <AccordionDetails style={{ background: '#F7F7F9' }}>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}> {rosterContent} </Box>
    </AccordionDetails>
  </Accordion>
);

RosterBlock.propTypes = {
  testId: PropTypes.string,
  title: PropTypes.string,
  rosterContent: PropTypes.node,
};

export default RosterBlock;
