import React, { useCallback } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { BUTTON_TYPE } from '../../components/Button';
import Form from '../../components/Form';
import selectIsLoading from '../../store/selectors/registerSelectors';
import launchDarklyToggles from '../../utils/launchDarklyToggles';

import formFieldData from './formData/formFieldData';
import { registerCandidate, registerEmployer } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const Register = ({ flags }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);
  const intl = useIntl();
  const isRegisterEmployeeEnabledOnSignUp = launchDarklyToggles(
    flags,
    'isRegisterEmployeeEnabledOnSignUp'
  );
  const isRegisterEmployerEnabledOnSignUp = launchDarklyToggles(
    flags,
    'isRegisterEmployerEnabledOnSignUp'
  );

  const handleFormSubmit = useCallback(
    (values) => {
      (get(['userRole', 'id'], values) === UserRole.EMPLOYER &&
        dispatch(
          registerEmployer({ fields: { email: values.email, name: values.userRole.name } })
        )) ||
        (get(['userRole', 'id'], values) === UserRole.CANDIDATE &&
          dispatch(registerCandidate({ fields: { email: values.email } })));
    },
    [dispatch]
  );

  const buttonData = [
    {
      disabled: !!isLoading,
      fullWidth: true,
      showIndeterminateProgress: !!isLoading,
      text: intl.formatMessage({ id: 'page.register.button.signUp' }),
      type: BUTTON_TYPE.SUBMIT,
      xs: 12,
    },
    {
      disabled: !!isLoading,
      fullWidth: true,
      onClick: () => navigate('/'),
      text: intl.formatMessage({ id: 'page.register.button.signIn' }),
      xs: 12,
    },
  ];

  return (
    <RootGrid container>
      <FormGrid item xs={10} sm={5} md={4} lg={3}>
        <Typography variant="h3" align="center" sx={{ paddingBottom: 5 }}>
          {intl.formatMessage({ id: 'page.register.headers.signUp' })}
        </Typography>
        <Form
          buttonData={buttonData}
          formFieldData={formFieldData(
            isRegisterEmployeeEnabledOnSignUp,
            isRegisterEmployerEnabledOnSignUp
          )}
          onSubmit={handleFormSubmit}
        />
      </FormGrid>
    </RootGrid>
  );
};

Register.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(Register);
