/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import searchParamOptions from '@careerstart/wae-common/src/main/constants/searchParams';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import asyncService from '../../../../datahub/asyncService';
import Purple70Dot from '../../../assets/icons/Purple70Dot.svg';
import ConfirmationModal from '../../../components/ConfirmationModal';
import IconButton from '../../../components/IconButton';
import Label from '../../../components/Label';
import WaeMap from '../../../components/Map/WaeMap';
import TableComponent from '../../../components/TableComponent/TableComponent';
import TimekeepingEditDrawer from '../../../components/TimeKeepingEditDrawer';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectTimeCardIsUpdating,
  selectTimecardUpdateError,
} from '../../../store/selectors/timeSheetSelector';
import theme from '../../../theme';
import { BLACK, PRIMARY_PURPLE } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { epochToTimeInReadableFormat } from '../../../utils';
import { TIMEKEEPING_STATUSES } from '../constants';
import { generateStatusData, handleLabelProps } from '../helpers';
import {
  clearTimeCardError,
  postTimeCardApproval,
  postTimeCardFinalize,
  postTimeCardResolve,
  postTimeCardUnFinalize,
  updateTimeCard,
} from '../reducer';

import AttendanceReport from './AttendanceReport';
import CheckedIn from './CheckedIn';
import { isShiftCompleted } from './helperFunctions';
import PunchLocationsMap from './PunchLocationsMap';
import {
  showApproveBtn,
  showFinalizeBtn,
  showResolveBtn,
  showUnFinalizeBtn,
} from './statusUpdateButtons';
import showTimecardEditButton from './timecardEditButton';
import TimeKeepingDateSelector from './TImekeepingDateSelector';

const getWeekValues = (daysOfWeek, days) =>
  daysOfWeek.map((day) => {
    const dayObject = day?.dayObject;
    const dayFields = days?.[day?.field];
    return {
      date: dayObject.format('MM/DD'),
      day: day?.weekday,
      totalHours:
        (dayFields?.length > 0 &&
          `${dayFields
            .reduce((acc, currVal) => acc + get(['timecard', 'totalHours'], currVal), 0)
            .toFixed(2)}`) ||
        '-',
      value: dayObject.format('MM/DD/YYYY').replace(/\b0/g, ''),
    };
  });

const Overview = ({ daysOfWeek, initialData, selectedDate, setSelectedDate }) => {
  const intl = useIntl();
  const [searchParams] = useSearchParams();
  const placement = searchParams.get(searchParamOptions.PLACEMENT);
  const selectedPlacementRef = useRef();
  const timecardIsUpdating = useSelector(selectTimeCardIsUpdating);
  const timecardUpdateError = useSelector(selectTimecardUpdateError);

  const weekValues = getWeekValues(daysOfWeek, initialData.days);
  const currentDate = selectedDate || weekValues[2].value;

  useEffect(() => {
    if (selectedPlacementRef && selectedPlacementRef.current) {
      selectedPlacementRef.current.scrollIntoView();
    }
  }, [selectedPlacementRef]);

  const componentsTimecardDetailPunchCardPalette = [
    'components',
    'timecardDetailPunchCard',
    'palette',
  ];
  const componentsTimecardDetailPunchCardTypography = [
    'components',
    'timecardDetailPunchCard',
    'typography',
  ];
  const [workFlowData, setWorkFlowData] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [placements, setPlacements] = useState([]);
  const [unfinalizeConfData, setUnfinalizeConfData] = useState(null);

  const user = useSelector(selectUser);

  const userRole = user?.role;

  const token = user?.token;
  const refreshToken = user?.refreshToken;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  useEffect(() => {
    const timecardPlacements = get(['days', `${currentDate}`], initialData) || [];
    const submitAPICallData2 = {
      httpMethod: 'POST',
      route: 'placements/read',
    };
    const placementIDs = timecardPlacements.map((p) => p.placement);
    const data = {
      filters: [
        {
          operation: 'idContainedIn',
          field: '_id',
          value: placementIDs,
        },
      ],
    };
    let isSubscribed = true;

    asyncService({
      ...submitAPICallData2,
      data,
      token,
      refreshToken,
      onSuccess: (payload) => {
        const docs = payload?.data?.documents || [];
        isSubscribed && setPlacements((job) => [...job, ...docs]);
      },
      onError: () => {
        isSubscribed && setPlacements([]);
      },
      dispatch,
    });
    return () => {
      isSubscribed && (isSubscribed = false);
    };
  }, []);

  const getPlacementListToApprove = () => {
    let placementIDList = [];
    Object.keys(get('days', initialData)).forEach((key) => {
      placementIDList = [
        ...placementIDList,
        ...get('days', initialData)
          [key].filter(
            (day) =>
              day?.timecard?.status === 'pending' &&
              day?.timecard?.punches.length > 0 &&
              day?.timecard?.punches.some((p) => p?.out?.stamp) &&
              isShiftCompleted(get(['end'], day))
          )
          .map((day) => get('placement', day)),
      ];
    });
    return placementIDList;
  };
  const nameTitleSx = {
    fontSize: '30px',
    fontFamily: 'Barlow-800',
    color: `${BLACK[0]}`,
  };
  const weekSx = {
    fontSize: '18px',
    fontFamily: 'Barlow-600',
    color: `${BLACK[50]}`,
  };
  const cardTitleSx = {
    paddingLeft: '24px',
    fontSize: '14px',
    color: `${BLACK[50]}`,
    fontFamily: 'Barlow-500',
  };
  const shiftTimeSx = {
    paddingLeft: '24px',
    fontSize: '20px',
    color: `${PRIMARY_PURPLE[40]}`,
    fontFamily: 'Barlow-700',
  };
  const attendanceReportSx = {
    paddingLeft: '24px',
    fontFamily: PRIMARY_FONT[700],
    display: 'flex',
  };

  const cardShiftTitleSx = {
    paddingLeft: '24px',
    fontSize: '16px',
    color: `${BLACK[0]}`,
    fontFamily: 'Barlow-600',
  };

  const totalHoursSx = {
    fontSize: '18px',
    color: `${PRIMARY_PURPLE[40]}`,
    fontFamily: 'Barlow-600',
    paddingRight: '24px',
  };
  const HeaderGrid = styled(Grid)(() => ({
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  }));

  const SubHeaderGrid = styled(Grid)(() => ({
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  }));

  const dayandDateTypographySx = {
    fontSize: get([...componentsTimecardDetailPunchCardTypography, 'dayAndDateFontSize'], theme),
    fontFamily: get(
      [...componentsTimecardDetailPunchCardTypography, 'dayAndDateFontFamily'],
      theme
    ),
    color: get([...componentsTimecardDetailPunchCardPalette, 'dayandDateFontColor'], theme),
    padding: theme.spacing(2, 0, 0),
    lineHeight: '32px',
  };
  const buildingLocation = {
    lat: 43.1497,
    lng: -77.58832,
  };

  const generateGPSMarkers = (punches = []) => {
    const punchesGPS = [];
    punches.forEach((punch) => {
      punchesGPS.push({
        position: {
          lat: parseFloat(get(['in', 'gps', 'coordinates', 'latitude'], punch)),
          lng: parseFloat(get(['in', 'gps', 'coordinates', 'longitude'], punch)),
        },
      });
      if (get(['out'], punch)) {
        punchesGPS.push({
          position: {
            lat: parseFloat(get(['out', 'gps', 'coordinates', 'latitude'], punch)),
            lng: parseFloat(get(['out', 'gps', 'coordinates', 'longitude'], punch)),
          },
        });
      }
    });
    return punchesGPS;
  };

  const checkAnyPunchAreOoB = (punches) => {
    const checkObj = [];
    punches.forEach((punch) => {
      checkObj.push(get(['in', 'gps', 'oob'], punch));
      if ((get.out, punch)) {
        checkObj.push(get[('out', 'gps', 'oob')], punch);
      }
    });

    return checkObj.includes(true);
  };

  // Uncomment when map is ready
  const markers = (selectedPlacement) => [
    {
      position: selectedPlacement?.jobOrder?.location?.target
        ? {
            long: selectedPlacement?.jobOrder?.location?.target?.longitude,
            lat: selectedPlacement?.jobOrder?.location?.target?.latitude,
          }
        : buildingLocation,

      icon: Purple70Dot,
      circle: {
        radius: selectedPlacement?.jobOrder?.gps?.maxDistance,
        fillColor: '#7A23FF',
      },
    },
    ...generateGPSMarkers(selectedPlacement?.timecard?.current?.punches),
  ];

  const [mapOpen, setMapOpen] = useState(false);

  const toggleMap = () => {
    setMapOpen(!mapOpen);
  };
  const RootBox = styled(Box)(() => ({
    width: '100%',
  }));

  const TableBodyPaper = styled(Paper)(() => ({
    backgroundColor: `${BLACK[100]}`,
    minHeight: '418px',
    width: '100%',
    display: 'flex',
    marginBottom: '32px',
    borderRadius: '16px',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignContent: 'center',
  }));
  const StyledFixedBottomBox = styled(Box)(() => ({
    position: 'absolute',
    backgroundImage: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    fontFamily: `${theme.button.fontFamily}`,
    color: '#FFF',
    width: '100%',
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4.25vh',
    cursor: 'pointer',
  }));

  const getEditModeFullDayName = useCallback(
    (date) => {
      const filteredDayByDate = daysOfWeek.filter((day) => day.field === date);
      return get('fullWeekDayName', filteredDayByDate[0]);
    },
    [daysOfWeek]
  );

  const handleOnEdit = (shift) => () => {
    setIsDrawerOpen(true);
    setWorkFlowData(shift);
  };

  const handleTimecardEditSubmit = useCallback(
    ({ punches }) => {
      // Add JSON schema validation here. If there is a validation error, return false.
      const payload = {
        placement: workFlowData?.placement,
        punches: punches.map((punch) => ({
          in: punch?.in?.stamp,
          out: punch?.out?.stamp,
        })),
      };
      dispatch(updateTimeCard(payload));
      return true;
    },
    [dispatch, workFlowData]
  );

  const handleTimecardEditClose = useCallback(() => {
    dispatch(clearTimeCardError());
    setIsDrawerOpen(false);
  }, [dispatch]);

  const handleOnApprove = (placementId) => () =>
    dispatch(postTimeCardApproval({ placements: [placementId] }));
  const handleOnResolve = (placementId) => () =>
    dispatch(postTimeCardResolve({ placements: [placementId] }));
  const handleOnFinalize = (placementId) => () =>
    dispatch(postTimeCardFinalize({ placements: [placementId] }));
  const handleOnUnFinalize = (placementId) => () => {
    setUnfinalizeConfData(placementId);
  };
  const handleUnfinalizedOnConfirm = (placementId) => () => {
    setUnfinalizeConfData(null);
    dispatch(postTimeCardUnFinalize({ placements: [placementId] }));
  };

  /**
   * Renders the action button for the user role and status.
   * Handles the onClick action for the button which is a dispatch to post the appropriate action.
   * Handles the button text.
   *
   * @param {*} placementId
   * @param {*} status
   * @param {*} role
   * @return {*}
   */

  const filledIconsColor = get(['button', 'palette', 'whiteIcon'], theme);

  const handleRosterCardClick = (selectedPlacement) => {
    const navProps = {
      pathname: '/jobs/jobslist',
      search: `?${searchParamOptions.JOB_ORDER}=${selectedPlacement?.jobOrder?._id}&${
        searchParamOptions.ACTIVE_TAB
      }=${0}`,
    };

    navigate(navProps);
  };

  return (
    <RootBox>
      <HeaderGrid container item>
        <Box sx={nameTitleSx}>{get(['candidate', 'name'], initialData)}</Box>
        <Label {...handleLabelProps(get(['status', 'label'], generateStatusData(initialData)))} />
      </HeaderGrid>
      <SubHeaderGrid container item>
        <Box sx={weekSx}>{`This week: ${get('value', weekValues[0])} - ${get(
          'value',
          weekValues[6]
        )}`}</Box>
        <Box sx={{ ...weekSx, color: `${BLACK[0]}` }}>{`${get('totalHours', initialData).toFixed(
          2
        )} total hours`}</Box>
      </SubHeaderGrid>
      <TimeKeepingDateSelector
        dates={weekValues}
        data={initialData}
        initialSelectedDate={currentDate}
        setCurrentDate={setSelectedDate}
      />
      <Typography sx={dayandDateTypographySx}>{`${getEditModeFullDayName(
        currentDate
      )}, ${currentDate}`}</Typography>
      <Box
        sx={{
          maxHeight: '60vh',
          width: '100%',
          transform: 'translateZ(0)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          margin: theme.spacing(2, 0),
        }}
      >
        {initialData?.days?.[currentDate] &&
          initialData?.days?.[currentDate]?.map((shift) => {
            const selectedPlacement = placements.find((job) => job?._id === shift?.placement);
            return (
              get(['timecard', 'punches'], shift) && (
                <TableBodyPaper
                  key={get('placement', shift)}
                  ref={get('placement', shift) === placement ? selectedPlacementRef : null}
                >
                  <Grid container item direction="row" justifyContent="space-between">
                    <Grid>
                      <Box sx={cardTitleSx}>{get(['order'], shift)}</Box>
                      <Box sx={{ ...cardTitleSx }}>{get(['corporation'], shift)}</Box>
                    </Grid>
                    <Grid>
                      <Label
                        {...handleLabelProps(get(['timecard', 'status'], shift))}
                        sx={{
                          ...handleLabelProps(get(['timecard', 'status'], shift)).sx,
                          marginRight: '24px',
                        }}
                      />
                      {get(['timecard', 'overtimeHours'], shift) > 0 && (
                        <Label
                          nameIsShortened
                          {...handleLabelProps(TIMEKEEPING_STATUSES.OVERTIME)}
                          sx={{
                            ...handleLabelProps(TIMEKEEPING_STATUSES.OVERTIME).sx,
                            margin: theme.spacing(1, 3, 0, 0),
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container item direction="row" justifyContent="space-between">
                    <Grid>
                      <Box sx={cardShiftTitleSx}>{get(['shiftName'], shift)}</Box>
                      <Box sx={shiftTimeSx}>{`${epochToTimeInReadableFormat(
                        get('start', shift)
                      )} - ${epochToTimeInReadableFormat(get('end', shift))}`}</Box>
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: theme.spacing(4),
                        cursor: 'pointer',
                        gap: theme.spacing(2),
                      }}
                    >
                      <PunchLocationsMap
                        punches={selectedPlacement?.timecard?.current?.punches}
                        name={selectedPlacement?.candidate?.name}
                      />
                      {selectedPlacement && (
                        <IconButton
                          iconElement={
                            <PeopleOutlineOutlinedIcon
                              sx={{ color: filledIconsColor, fontSize: 30 }}
                            />
                          }
                          onClick={() => handleRosterCardClick(selectedPlacement)}
                          size="small"
                          sx={{ marginLeft: 0.5, marginTop: 0.5 }}
                          labelSx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            fontFamily: 'Barlow',
                            color: `${BLACK[0]}`,
                          }}
                          label={intl.formatMessage({ id: 'buttonText.goToRoster' })}
                        />
                      )}
                    </Box>
                  </Grid>

                  <Box sx={attendanceReportSx}>
                    <Box flex={4}>
                      <CheckedIn isChecked={selectedPlacement?.timecard?.checkIn?.stamp > 0} />
                    </Box>
                    <AttendanceReport
                      placement={selectedPlacement}
                      token={token}
                      dispatch={dispatch}
                      sx={{ flex: 8 }}
                    />
                  </Box>

                  <TableComponent
                    columnData={['In', 'Out', 'Hours']}
                    rowsData={selectedPlacement?.timecard?.current?.punches}
                    sx={{
                      paddingRight: '24px',
                      paddingLeft: '24px',
                    }}
                  />
                  <Grid container item sx={{ justifyContent: 'flex-end' }}>
                    <Box sx={totalHoursSx}>{`Total: ${get(
                      ['timecard', 'totalHours'],
                      shift
                    ).toFixed(2)}`}</Box>
                  </Grid>
                  {/* Uncomment when we have the data to show the map with pucnhes out of bounds */}
                  {checkAnyPunchAreOoB(get(['timecard', 'punches'], shift)) && (
                    <Grid
                      item
                      sx={{
                        height: mapOpen ? '35vh' : '20px',
                        padding: theme.spacing(2, 2, 6, 2),
                        margin: theme.spacing(0, 0, 2, 0),
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                        onClick={toggleMap}
                      >
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <Label
                            {...handleLabelProps(TIMEKEEPING_STATUSES.CONFLICT)}
                            sx={{
                              ...handleLabelProps(TIMEKEEPING_STATUSES.CONFLICT).sx,
                              marginRight: theme.spacing(2),
                            }}
                            collapsed
                          />
                          <Typography sx={{ color: '#FFF' }}>
                            Employee clocked in outside of geofence.
                          </Typography>
                        </Box>

                        {mapOpen ? (
                          <ExpandLessIcon sx={{ color: theme.button.palette.secondary }} />
                        ) : (
                          <ExpandMoreIcon sx={{ color: theme.button.palette.secondary }} />
                        )}
                      </Box>

                      {mapOpen && (
                        <WaeMap
                          center={buildingLocation}
                          markers={markers(selectedPlacement)}
                          zoomLevel={13}
                        />
                      )}
                    </Grid>
                  )}

                  <Grid>
                    {showTimecardEditButton(
                      handleOnEdit(shift),
                      shift?.start,
                      shift?.timecard?.status,
                      userRole
                    )}
                    {showApproveBtn(
                      shift?.timecard?.status,
                      shift?.end,
                      userRole,
                      handleOnApprove(shift?.placement)
                    )}
                    {showResolveBtn(
                      shift?.timecard?.status,
                      shift?.end,
                      userRole,
                      handleOnResolve(shift?.placement)
                    )}
                    {showFinalizeBtn(
                      shift?.timecard?.status,
                      shift?.end,
                      userRole,
                      handleOnFinalize(shift?.placement)
                    )}
                    {showUnFinalizeBtn(
                      shift?.timecard?.status,
                      shift?.end,
                      userRole,
                      handleOnUnFinalize(shift?.placement)
                    )}
                    <ConfirmationModal
                      isOpen={!!unfinalizeConfData}
                      body={LanguageConverter('generic.timecard.unfinalize.confirmation')}
                      cancelBtnText={LanguageConverter('timekeeping.noGoBack')}
                      confirmationBtnText={LanguageConverter('timekeeping.yesSend')}
                      onConfirm={handleUnfinalizedOnConfirm(unfinalizeConfData)}
                      onClose={() => setUnfinalizeConfData(null)}
                      title={LanguageConverter('timekeeping.sendForReview')}
                    />
                  </Grid>
                </TableBodyPaper>
              )
            );
          })}
        <TimekeepingEditDrawer
          isDrawerOpen={isDrawerOpen}
          shift={workFlowData}
          onSubmit={handleTimecardEditSubmit}
          onClose={handleTimecardEditClose}
          timecardIsUpdating={timecardIsUpdating}
          errorMessage={timecardUpdateError}
        />
      </Box>
      {userRole === UserRole.EMPLOYER &&
        get(['status', 'label'], initialData) === 'pending' &&
        getPlacementListToApprove().length > 0 && (
          <StyledFixedBottomBox
            onClick={() =>
              dispatch(postTimeCardApproval({ placements: getPlacementListToApprove() }))
            }
          >
            <Typography>Approve Week</Typography>
          </StyledFixedBottomBox>
        )}
    </RootBox>
  );
};

Overview.propTypes = {
  daysOfWeek: PropTypes.arrayOf(PropTypes.shape({})),
  initialData: PropTypes.shape({
    days: PropTypes.shape([]),
  }),
  flags: PropTypes.shape({}),
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
};

export default withLDConsumer()(Overview);
