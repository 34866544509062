import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

export const selectCandidateJobsList = (state) => get('candidateJobsList', state);

export const selectCertifications = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.certifications
);
export const selectIsLoadingCertifications = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingCertifications
);

export const selectIsLoadingFilteredJobsList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingFilteredJobsList
);

export const selectCorporations = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.corporations
);
export const selectCorporationById = createSelector(
  selectCorporations,
  (state, corporationId) => corporationId,
  (corporations, corporationId) => corporations.find(({ _id }) => _id === corporationId)
);

export const selectFiles = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.files
);

export const selectJobGroupsList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.jobGroupsList
);

export const selectHasResetList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.hasResetList
);

export const selectDayGroupsCount = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.dayGroupsCount
);
export const selectLongGroupsCount = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.longGroupsCount
);

export const selectMyJobCount = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.myJobCount
);

export const selectIsLoadingAllJobsList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingAllJobsList
);

export const selectIsLoadingDayJobsList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingAllJobsList
);

export const selectIsLoadingLongJobsList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingAllJobsList
);
export const selectIsLoadingPathSelectedJob = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingPathSelectedJob
);

export const selectIsLoadingPlacementList = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingPlacementList
);
export const selectIsLoadingPathSelectedPlacement = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingPlacementList
);

export const selectLocations = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.locations
);

export const selectPlacements = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.placements
);

export const selectSelectedGroup = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedGroup
);
export const selectSelectedPlacement = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedPlacement
);
export const selectIsLoadingGroupsJobs = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingSelectedGroupsJobs
);
export const selectIsProcessingCreatePlacement = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isProcessingCreatePlacement
);
export const selectGroupsJobs = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedGroupsJobs
);
export const selectCreatedPlacements = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.createdPlacements
);
export const selectCreatePlacementErrorMessage = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.createPlacementErrorMessage
);
export const selectGroupsJobsCount = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedGroupsJobsCount
);
export const selectGroupsJobsPage = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.selectedGroupsJobsPage
);

export const selectDayJobsPage = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.dayJobsPage
);
export const selectLongJobsPage = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.longJobsPage
);
export const selectMyJobsPage = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.myJobsPage
);
export const selectIsLoadingCancel = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingCancel
);
export const selectCancelledPlacement = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.cancelledPlacement
);
export const selectSkills = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.skills
);
export const selectIsLoadingSkills = createSelector(
  selectCandidateJobsList,
  (candidateJobsList) => candidateJobsList.isLoadingSkills
);
