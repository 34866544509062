import theme from '../../../theme';

export const radioButtonStyling = {
  '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
    color: theme?.components?.RadioChips?.palette?.light?.radioBorderColor,
    radius: '17px solid',
  },
  '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
    color: theme?.components?.RadioChips?.palette?.light?.radioFillColor,
  },
};

export const radioCardDescriptionStyling = {
  fontFamily: theme?.components?.RadioChips?.typography?.light?.cardDescriptionFontFamily,
  color: theme?.components?.RadioChips?.palette?.light?.cardDescriptionFontColor,
  lineHeight: '25px',
  fontSize: '20px',
  margin: theme.spacing(1, 0, 0, 0),
};
export const radioCardLabelStyling = {
  '& .MuiFormControlLabel-label': {
    fontFamily: theme?.components?.RadioChips?.typography?.light?.cardLabelFontFamily,
    color: theme?.components?.RadioChips?.palette?.light?.cardLabelFontColor,
    fontSize: '20px',
  },
  '& .MuiFormControlLabel-asterisk.Mui-error': {
    fontFamily: theme?.components?.RadioChips?.typography?.light?.cardLabelFontFamily,
  },
  '& .MuiFormControlLabel-asterisk': {
    fontFamily: theme?.components?.RadioChips?.typography?.light?.cardLabelFontFamily,
  },
};
export const radioLabelStyling = {
  '& .MuiFormControlLabel-label': {
    fontFamily: theme?.components?.RadioChips?.typography?.light?.labelFontFamily,
    color: theme?.components?.RadioChips?.palette?.light?.labelFontColor,
    fontSize: '16px',
    margin: theme.spacing(0, 0, 0, 1.5),
  },
  '& .MuiFormControlLabel-asterisk.Mui-error': {
    fontFamily: theme?.components?.RadioChips?.typography?.light?.cardLabelFontFamily,
  },
  '& .MuiFormControlLabel-asterisk': {
    fontFamily: theme?.components?.RadioChips?.typography?.light?.cardLabelFontFamily,
  },
};

export const cardWidthAndHeight = (largerThanMediumScreen) =>
  largerThanMediumScreen ? { width: 390, minHeight: 204 } : { width: 250, minHeight: 309 };

export const cardSpacing = (cardAmount, direction, largerThanMediumScreen) => {
  if (cardAmount > 1 && direction === 'row') {
    return largerThanMediumScreen
      ? { margin: theme.spacing(0, 1, 0, 0) }
      : { margin: theme.spacing(1, 0, 0, 0) };
  }
  if (cardAmount > 1 && direction === 'column') {
    return { margin: theme.spacing(1, 0, 0, 0) };
  }

  return {};
};

export const radioSpacing = (amount, direction, largerThanMediumScreen) => {
  if (amount > 1 && direction === 'row' && !largerThanMediumScreen) {
    return { margin: theme.spacing(0.5, 0, 0, 0) };
  }
  return {};
};
