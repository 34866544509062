import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Avatar, Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import theme from '../../../../theme';

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    boxShadow: 'none',
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      padding: '0',
      justifyContent: 'space-between',
      '& .header': {
        display: 'flex',
        '& .avatar': {
          marginRight: '10px',
          width: '34px',
          height: '34px',
        },
        '& .employee': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '& .name': {
            display: 'flex',
            fontSize: '16px',
            lineHeight: '16px',
            ...theme.jobOrders.detail.roster.employeeRosterCard.employeeName,
          },
          '& .checkInStatus': {
            display: 'flex',
            fontWeight: '300',
            fontSize: '14px',
            lineHeight: '16px',
            ...theme.jobOrders.detail.roster.employeeRosterCard.checkInStatus,
          },
        },
      },
    },
  },
});

const EmployeeRosterCard = ({ placement }) => {
  const classes = useStyles();

  const CheckInStatuses = {
    PRESENT: 'present',
    SENT_HOME: 'sent-home',
    NO_SHOW: 'no-show',
  };
  const checkInStatus = placement?.timecard?.checkIn?.status;
  const checkInStatusText =
    (checkInStatus === CheckInStatuses.NO_SHOW && LanguageConverter('jobOrder.markedAsNoShow')) ||
    (checkInStatus === CheckInStatuses.SENT_HOME && LanguageConverter('jobOrder.markedAsSentHome'));

  return (
    <Card className={classes.card}>
      <CardContent className="content">
        <div className="header">
          <Avatar src={placement?.profilePic} alt={placement?.candidateName} className="avatar" />
          <div className="employee">
            <Typography variant="h6" className="name">
              {placement?.candidateName}
            </Typography>
            <Typography variant="body2" className="checkInStatus">
              {checkInStatusText}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

EmployeeRosterCard.propTypes = {
  placement: PropTypes.shape({
    _id: PropTypes.string,
    checkInStatus: PropTypes.string,
    candidateName: PropTypes.string,
    timecard: PropTypes.shape({
      current: PropTypes.shape({
        punches: PropTypes.arrayOf(PropTypes.shape({})),
        totalTime: PropTypes.number,
      }),
      checkIn: PropTypes.shape({ status: PropTypes.string }),
      status: PropTypes.string,
    }),
    start: PropTypes.number,
    end: PropTypes.number,
    profilePic: PropTypes.string,
    state: PropTypes.shape({ userName: PropTypes.string }),
  }),
};

export default EmployeeRosterCard;
