import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import FormSectionDescription from '../CustomComponents/FormSectionDescription';
import FormSectionHeader from '../CustomComponents/FormSectionHeader';
import { dateRangeField } from '../FormFieldData/CreateJobFields';

const Dates = ({ wrapperSx }) => (
  <Box
    sx={{
      width: '100%',
      ...wrapperSx,
    }}
  >
    <FormSectionHeader messageKey="job.create.dates.header" />
    <FormSectionDescription
      messageKey="job.create.dates.desc"
      learnMoreUrl="/"
      learnMoreKey="button.learn"
    />
    <Box sx={{ display: 'flex' }}>{buildFormField(dateRangeField())}</Box>
  </Box>
);

Dates.propTypes = { wrapperSx: PropTypes.shape({}) };

export default Dates;
