import { combineReducers } from 'redux';

import { notificationsReducer } from '../../main-deprecated/components/NotificationCenter/reducer';
import { fileReducer } from '../../main-deprecated/views/app/fileReducer';
import { candidateDashboardReducer } from '../../main-deprecated/views/Candidate/dashboard/reducer';
import { candidateJobInvitationReducer } from '../../main-deprecated/views/Candidate/jobInvitation/reducer';
import { candidateJobsListReducer } from '../../main-deprecated/views/Candidate/jobs/reducer';
import { employeeTimeCardReducer } from '../../main-deprecated/views/Candidate/timeTracking';
import { corporationListReducer } from '../../main-deprecated/views/corporations/corporationList';
import { createCorporationReducer } from '../../main-deprecated/views/corporations/createCorporation';
import { forgotMyPasswordReducer } from '../../main-deprecated/views/forgotMyPassword';
import { createJobsReducer } from '../../main-deprecated/views/jobs/createJobs';
import { employeeInviteReducer } from '../../main-deprecated/views/jobs/jobList/detail/SubComponents/reducer';
import { loginReducer } from '../../main-deprecated/views/login';
import { passwordUpdateReducer } from '../../main-deprecated/views/passwordUpdate';
import { accountVerificationReducer } from '../../main-deprecated/views/PasswordVerificationOrReset';
import { createPositionTemplateReducer } from '../../main-deprecated/views/positionTemplate/createPositionTemplateDrawer';
import { positionTemplateListReducer } from '../../main-deprecated/views/positionTemplate/positionTemplateList';
import { registerReducer } from '../../main-deprecated/views/register/reducer';
import { reportingReducer } from '../../main-deprecated/views/reporting/reducer';
import { settingsReducer } from '../../main-deprecated/views/settings';
import { skillsAndCertsReducer } from '../../main-deprecated/views/skillsAndCerts';
import { timekeepingReducer } from '../../main-deprecated/views/timekeeping';
import { usersReducer } from '../../main-deprecated/views/users/reducer';
import { appReducer, logout } from '../views/app/reducer';
import { jobOrdersReducer } from '../views/jobOrders/jobOrdersReducer';

const allReducers = combineReducers({
  accountVerification: accountVerificationReducer,
  app: appReducer,
  corporationList: corporationListReducer,
  createJobs: createJobsReducer,
  createCorporation: createCorporationReducer,
  createPositionTemplate: createPositionTemplateReducer,
  employeeTimeCards: employeeTimeCardReducer,
  employeeInvite: employeeInviteReducer,
  candidateJobsList: candidateJobsListReducer,
  candidateJobInvitation: candidateJobInvitationReducer,
  candidateDashboard: candidateDashboardReducer,
  forgotMyPassword: forgotMyPasswordReducer,
  jobOrdersState: jobOrdersReducer,
  login: loginReducer,
  passwordUpdate: passwordUpdateReducer,
  positionTemplateList: positionTemplateListReducer,
  register: registerReducer,
  reporting: reportingReducer,
  timeSheet: timekeepingReducer,
  users: usersReducer,
  files: fileReducer,
  settings: settingsReducer,
  notifications: notificationsReducer,
  skillsAndCerts: skillsAndCertsReducer,
});

const createRootReducer = (state, action) => {
  if (action.type === logout.type) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};
export default createRootReducer;
