import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { BUTTON_TYPE } from '../../components/Button';
import Form from '../../components/Form';
import { isValidEmailBoolean } from '../../components/Form/validations';
import forgotMyPasswordIsProcessing from '../../store/selectors/forgotMyPasswordSelector';
import theme from '../../theme';
import { BLACK } from '../../theme/colorConstants';
import { showSnackbar } from '../app/reducer';

import formFieldData from './formFieldData';
import { postForgotPassword } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.palette.background.black,
}));

const FormGrid = styled(Grid)(() => ({
  justifyContent: 'center',
}));

const ForgotMyPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(forgotMyPasswordIsProcessing);

  const handleFormSubmit = useCallback(
    (values) => {
      const dispatchValue = isValidEmailBoolean(values.verificationType)
        ? { fields: { email: values.verificationType } }
        : dispatch(
            showSnackbar({
              message:
                'Mobile is currently not implemented, please enter an email address instead.', // FIXME: HB-943 Change to allow mobile when mobile feature is enabled
            })
          );

      dispatchValue && dispatch(postForgotPassword(dispatchValue));
    },
    [dispatch]
  );

  const buttonData = [
    {
      disabled: !!isLoading,
      fullWidth: true,
      showIndeterminateProgress: !!isLoading,
      text: 'Send',
      endIcon: <ArrowForwardIcon />,
      type: BUTTON_TYPE.SUBMIT,
      xs: 12,
    },
  ];

  return (
    <RootGrid container>
      <FormGrid item xs={10} sm={5} md={4} lg={3}>
        <Typography
          align="Left"
          onClick={() => navigate('/')}
          variant="h6"
          sx={{
            color: `${theme.button.palette.secondary}`,
            marginLeft: theme.spacing(1),
            cursor: 'pointer',
            fontFamily: 'Barlow-700',
            alignItems: 'center',
            display: 'flex',
            marginBottom: theme.spacing(5),
          }}
        >
          <ArrowBackIcon color={theme.button.palette.secondary} />
          Back to login
        </Typography>
        <Typography
          variant="h2"
          align="center"
          sx={{ paddingBottom: theme.spacing(1), color: '#FFF', fontFamily: 'Barlow-800' }}
        >
          Don&#39;t worry!
        </Typography>
        <Typography
          variant="h4"
          align="center"
          sx={{ paddingBottom: theme.spacing(2), color: '#FFF', fontFamily: 'Barlow-700' }}
        >
          We can help you with that.
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{ paddingBottom: theme.spacing(5), color: `${BLACK[40]}` }}
        >
          Resetting your password is easy-just type in the email address you used to register for
          WAE.
        </Typography>
        <Box sx={{ padding: theme.spacing(0, 6, 0, 6) }}>
          <Form
            buttonData={buttonData}
            formFieldData={formFieldData}
            onSubmit={handleFormSubmit}
            initialValues={{ verificationType: '' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginTop: theme.spacing(3),
            fontFamily: 'Barlow-700',
          }}
          onClick={() => navigate('/')}
        >
          <Typography variant="h6" align="center" sx={{ color: `${BLACK[40]}` }}>
            Remember your password?
          </Typography>
          <Typography
            variant="h6"
            align="center"
            sx={{
              color: `${theme.button.palette.secondary}`,
              marginLeft: theme.spacing(1),
              fontFamily: 'Barlow-700',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            Log in <ArrowForwardIcon color={theme.button.palette.secondary} />
          </Typography>
        </Box>
      </FormGrid>
    </RootGrid>
  );
};

export default ForgotMyPassword;
