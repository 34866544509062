import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employeeInviteList: [],
};
export const employeeInviteSlice = createSlice({
  name: 'employeeInviteSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    inviteEmployee: (state) => {
      state.isEmployeeInviteProcessing = true;
    },
    inviteEmployeeProcessed: (state) => {
      state.isEmployeeInviteProcessing = false;
    },
    inviteEmployeeError: (state) => {
      state.isEmployeeInviteProcessing = false;
    },
  },
});

export const employeeInviteReducer = employeeInviteSlice.reducer;

export const { inviteEmployee, inviteEmployeeProcessed, inviteEmployeeError } =
  employeeInviteSlice.actions;
