import React, { useEffect } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { selectFiles } from '../../../store/selectors/fileSelectors';
import theme, { dashboardCompanyImageSize } from '../../../theme';
import { calculateTotalPay } from '../../../utils/Candidate/payHelpers';
import { getFile } from '../../../views/app/fileReducer';
import CorporationLogo from '../../CorporationLogo';

const componentsTheme = get(['candidate', 'components'], theme);
const sxForCard = {
  padding: theme.spacing(1, 3, 1, 3),
  marginBottom: theme.spacing(1),
  backgroundColor: get(['shiftGroupCard', 'backgroundColor', 'default'], componentsTheme),
  borderRadius: theme.spacing(2),
  width: '49%',
};
const sxForTitle = {
  color: get(['shiftGroupCard', 'headers', 'fontColor'], componentsTheme),
  fontFamily: get(['shiftGroupCard', 'headers', 'font'], componentsTheme),
  fontSize: '16px',
  margin: theme.spacing(1, 0, 1, 0),
};
const sxForTotal = {
  color: get(['shiftGroupCard', 'headers', 'fontColor'], componentsTheme),
  fontFamily: get(['shiftGroupCard', 'headers', 'font'], componentsTheme),
  fontSize: '16px',
};
const sxForCorporationName = {
  color: get(['shiftGroupCard', 'headers', 'fontColor'], componentsTheme),
  fontFamily: get(['shiftGroupCard', 'subtext', 'font'], componentsTheme),
  fontSize: '12px',
};
const sxForSubtext = {
  color: get(['shiftGroupCard', 'subtext', 'fontColor'], componentsTheme),
  fontFamily: get(['shiftGroupCard', 'subtext', 'font'], componentsTheme),
  fontSize: '12px',
};
const sxForIndustry = {
  backgroundColor: get(['jobCard', 'pill', 'backgroundColor', 'default'], componentsTheme),
  fontFamily: get(['jobCard', 'pill', 'typography', 'font'], componentsTheme),
  color: get(['jobCard', 'pill', 'typography', 'color'], componentsTheme),
  padding: theme.spacing(1),
  marginLeft: '12px',
  fontSize: '12px',
  borderRadius: '9999px',
};

const SquareJobCardDetailed = ({ data, sx }) => {
  const { _id, title, corporation, payRateReg, totalPay, location, description, industry } = data;
  const corporationName = get('name', corporation);
  const corporationId = get('_id', corporation);
  const logo = get('logo', corporation);

  const city = get('city', location);
  const jobState = get('state', location);

  const dispatch = useDispatch();

  const files = useSelector(selectFiles);
  const file = get([`${logo}`], files);

  useEffect(() => {
    if (!file && !file?.isLoading && logo && logo !== null) {
      dispatch(getFile({ id: logo }));
    }
  }, [dispatch, logo, files, file]);
  return (
    <Box sx={{ ...sxForCard, ...sx }} key={_id}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CorporationLogo
          file={file}
          corporationId={corporationId}
          style={{
            height: dashboardCompanyImageSize.height,
            width: dashboardCompanyImageSize.width,
            marginRight: theme.spacing(1),
          }}
        />
        <Box>
          <Typography sx={sxForCorporationName}>{corporationName}</Typography>
          <Typography variant="subtitle2" sx={sxForSubtext}>
            {`${city}, ${jobState}`}
          </Typography>
        </Box>
      </Box>

      <Typography sx={sxForTitle}>{title}</Typography>

      <Typography sx={{ ...sxForSubtext, marginBottom: theme.spacing(1) }}>
        {description}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={sxForTotal}>{calculateTotalPay(totalPay)}</Typography>
          <Typography sx={sxForSubtext}>${payRateReg}/hr</Typography>
        </Box>
        <Box>
          <Typography sx={sxForIndustry}>
            {industry.charAt(0).toUpperCase() + industry.slice(1)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

SquareJobCardDetailed.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    positionTemplate: PropTypes.string,
    description: PropTypes.string,
    logoID: PropTypes.string,
    title: PropTypes.string,
    industry: PropTypes.string,
    corporation: PropTypes.shape({}),
    location: PropTypes.shape({}),
    start: PropTypes.number,
    startDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    end: PropTypes.number,
    payRateReg: PropTypes.number,
    imageURL: PropTypes.string,
    totalPay: PropTypes.shape({}),
  }),
  sx: PropTypes.shape({}),
};

export default SquareJobCardDetailed;
