import React, { useCallback, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Grid, Typography } from '@mui/material';

import Button, { BUTTON_VARIANT } from '../../../../../components/Button';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import Dialog from '../../../../../components/Dialog';
import theme from '../../../../../theme';
import { deactivateUser } from '../../../reducer';

const DeactivateConfirmationModal = ({ isDialogOpen, setIsDialogOpen, selectedUserID }) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const handlePositionTemplateDelete = useCallback(() => {
    dispatch(deactivateUser({ id: selectedUserID }));
    setIsDialogOpen(false);
  }, [setIsDialogOpen, dispatch, selectedUserID]);

  const checkBoxSx = {
    '& .MuiCheckbox-root': {
      color: get(['accountDeactivationAcknowledgmentCheckbox', 'checkboxColor'], theme),
    },
    '& .MuiFormControlLabel-label': {
      color: get(['accountDeactivationAcknowledgmentCheckbox', 'labels', 'fontColor'], theme),
      fontSize: get(['accountDeactivationAcknowledgmentCheckbox', 'labels', 'fontSize'], theme),
      fontFamily: get(['accountDeactivationAcknowledgmentCheckbox', 'labels', 'font'], theme),
    },
  };

  const body = (
    <Grid container>
      <Typography>{LanguageConverter('user.account.deactivation.acknowledgement.body')}</Typography>
      <CheckBox
        name="accountDeactivationCheckbox"
        label={LanguageConverter('user.account.deactivation.acknowledgement.confirmation')}
        sx={checkBoxSx}
        checked={isChecked}
        onChange={(event) => setIsChecked(event.target.checked)}
      />
    </Grid>
  );

  const deleteDialogButtons = [
    <Button
      text={LanguageConverter('generic.goBack')}
      key="handle-go-back-button"
      onClick={() => setIsDialogOpen(false)}
    />,

    <Button
      text={LanguageConverter('generic.deactivate')}
      disabled={!isChecked}
      variant={BUTTON_VARIANT.DELETE}
      key="handle-confirm-deactivate-button"
      onClick={handlePositionTemplateDelete}
    />,
  ];

  return (
    <Dialog
      open={isDialogOpen}
      dialogTitle={LanguageConverter('user.account.deactivation.acknowledgement.header')}
      dialogContentText={body}
      dialogActions={deleteDialogButtons}
      component="span"
      onBackdropClick={() => setIsDialogOpen(false)}
    />
  );
};

DeactivateConfirmationModal.propTypes = {
  selectedUserID: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
};

export default DeactivateConfirmationModal;
