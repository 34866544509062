import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Box from '@mui/material/Box';

import theme from '../../theme';
import { capitalizeFirstLetter } from '../../utils';
import { USER_STATUSES } from '../../views/users/helpers';
import Chip from '../Chip';

import { errorHandler } from './formUtils';

const activeChipColor = theme.chip.bgColor.defaultBgColor;
const chipFont = theme.chip.default.fontFamily;

const FormChip = ({ disabled, fieldErrorData, input, meta, size, required, variant }) => {
  const error = errorHandler(input, meta, fieldErrorData);

  const statusConverter = {
    [USER_STATUSES.ACTIVE]: LanguageConverter('user.status.active'),
    [USER_STATUSES.DEACTIVATED]: LanguageConverter('user.status.deactivated'),
    [USER_STATUSES.DNA]: LanguageConverter('user.status.dna'),
    [USER_STATUSES.DNR]: LanguageConverter('user.status.dnr'),
    [USER_STATUSES.INACTIVE]: LanguageConverter('user.status.inactive'),
    [USER_STATUSES.LOCKED]: LanguageConverter('user.status.locked'),
    [USER_STATUSES.ONBOARDING_INCOMPLETE]: LanguageConverter(
      'user.status.onboardingIncomplete.full'
    ),
    [USER_STATUSES.UNVERIFIED]: LanguageConverter('user.status.unverified'),
  };
  const generateChipLabel = (type) => statusConverter[type] || capitalizeFirstLetter(type);

  return (
    <Box>
      <Chip
        checked={input.value || false}
        name={input.name}
        onChange={input.onChange}
        size={size}
        required={required}
        error={error}
        disabled={disabled}
        variant={variant}
        sx={{
          backgroundColor: `${activeChipColor}`,
          marginTop: -4,
          fontFamily: `${chipFont}`,
        }}
        label={<Box>{generateChipLabel(input.value)}</Box>}
      />
    </Box>
  );
};

FormChip.propTypes = {
  disabled: PropTypes.bool,
  fieldErrorData: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape(),
  required: PropTypes.bool,
  size: PropTypes.string, // default size is medium
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
};

export default FormChip;
