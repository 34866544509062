/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import LoadingCardBase from './LoadingCardBase';

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    setIntersecting(entry.isIntersecting);
  });

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

const ListViewCard = ({ getNext, variant, skeletonBackgroundColor }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [isReadyForNext, setIsReadyForNext] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setIsReadyForNext(true);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isReadyForNext) {
      getNext();
      setIsReadyForNext(false);
    }
  }, [getNext, isReadyForNext]);

  return (
    <div ref={ref}>
      <LoadingCardBase variant={variant} skeletonBackgroundColor={skeletonBackgroundColor} />
    </div>
  );
};

ListViewCard.propTypes = {
  getNext: PropTypes.func,
  variant: PropTypes.string,
  skeletonBackgroundColor: PropTypes.string,
};

export default ListViewCard;
