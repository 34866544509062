import { createSlice } from '@reduxjs/toolkit';

const initialState = { forgotIsProcessing: false, forgotIsNavigating: false };
export const forgotMyPasswordSlice = createSlice({
  name: 'forgotMyPasswordSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    postForgotPassword: (state) => {
      state.forgotIsProcessing = true;
    },
    postForgotPasswordProcessed: (state) => {
      state.forgotIsProcessing = false;
    },
    postForgotPasswordError: (state) => {
      state.forgotIsProcessing = false;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const { postForgotPassword, postForgotPasswordProcessed, postForgotPasswordError } =
  forgotMyPasswordSlice.actions;

export const forgotMyPasswordReducer = forgotMyPasswordSlice.reducer;
