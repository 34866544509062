import React from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ACTION_BLUE } from '../../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';

const menuItemStyle = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' };

const PendingStatusMenuButton = ({ status, actions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ alignSelf: 'center' }}>
      <Typography
        fontSize="18px"
        color={ACTION_BLUE.INDIGO_BLUE}
        fontFamily={PRIMARY_FONT[500]}
        onClick={handleClick}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', cursor: 'pointer' }}>
          {status}
          <ArrowDropDownIcon />
        </Box>
      </Typography>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {actions.map(
          (action) =>
            action?.text &&
            action?.onClick && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  action?.onClick();
                }}
                sx={menuItemStyle}
              >
                <Typography>{action?.text}</Typography>
              </MenuItem>
            )
        )}
      </Menu>
    </Box>
  );
};

PendingStatusMenuButton.propTypes = {
  status: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default PendingStatusMenuButton;
