import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { sortBy } from 'lodash';
import { get } from 'lodash/fp';
import cloneDeep from 'lodash/fp/cloneDeep';
import find from 'lodash/fp/find';
import findIndex from 'lodash/fp/findIndex';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellModes,
  GridEditInputCell,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Add from '../../../assets/icons/Add.svg';
import Clean from '../../../assets/icons/Clean.svg';
import DatePickerField from '../../../components/DatePicker/DatePickerField';
import {
  composeValidators,
  isDateEqualOrGreaterThanTodayDateObject,
  isGreaterThan,
  isGreaterThanOrEqual,
  isInteger,
  isValidDateObject,
} from '../../../components/Form/validations';
import selectUser from '../../../store/selectors/appSelector';
import {
  allDataValid,
  clearJobsList,
  selectedCorporation,
  selectPositionTemplateData,
  validateList,
} from '../../../store/selectors/createJobsSelectors';
import { dateToYearMonthDayMinutes, nameAndTimeToReadable } from '../../../utils';

import JobInviteDropdownEditCell from './createJobsDataGridLSComponents/JobInviteDropdownEditCell';
import RenderJobInviteData from './createJobsDataGridLSComponents/RenderJobInviteData';
import EMPTY_JOB from './constant';
import { getPositionTemplate, resetClearJobList, resetJobList, updateValidation } from './reducer';
import rowValidator from './rowValidator';
import dataGridStyles from './styles';

/* eslint-disable react/function-component-definition */

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'red',
    color: 'white',
    // marginTop: '0!important', //FIX ME remove important later
  },
}));

const CreateJobsDataGridLS = ({ disableVirtualization }) => {
  const dispatch = useDispatch();
  const clearJobsListFlag = useSelector(clearJobsList);
  const displayValidateList = useSelector(validateList);
  const positionTemplateData = useSelector(selectPositionTemplateData);
  const isAllDataValid = useSelector(allDataValid);
  const user = useSelector(selectUser);
  const selectedCorp = useSelector(selectedCorporation);

  const isCorporationRequired = user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER;
  const isAdmin = user.role === UserRole.ADMIN;
  const apiRef = useGridApiRef();

  const [rows, setRows] = useState([{ ...EMPTY_JOB, id: uuidv4(), isNew: true }]);
  const [cellModesModel, setCellModesModel] = React.useState({});
  const [selectedPositionLocations, setSelectedPositionLocations] = React.useState([]);
  const sortedPositionTemplates = sortBy(positionTemplateData, (PT) => PT.name.toLowerCase());

  useEffect(() => {
    dispatch(
      getPositionTemplate({
        limit: 75,
        filters: [
          {
            field: 'corporation._id',
            operation: 'equalsID',
            value: isCorporationRequired
              ? get('_id', selectedCorp)
              : get(['employer', 'corporation'], user),
          },
          { field: 'approved', value: true, operation: 'equals' },
        ],
      })
    );
  }, [dispatch, selectedCorp, isCorporationRequired, user]);

  useEffect(() => {
    if (clearJobsListFlag) {
      dispatch(resetClearJobList());
      setRows([{ ...EMPTY_JOB, id: uuidv4(), isNew: true }]);
      dispatch(updateValidation(false));
    }
  }, [dispatch, setRows, clearJobsListFlag]);

  useEffect(() => {
    dispatch(resetJobList(rows));
  }, [dispatch, rows]);

  const RenderPositionData = (props) => {
    const { value } = props;
    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', height: '80%', backgroundColor: '#F2E9FF', borderRadius: '50px' }}
        className="failed-position-selection"
      >
        <Box sx={{ padding: '10px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{`${
          value.name || 'Select a Position'
        }`}</Box>
      </Grid>
    );
  };
  RenderPositionData.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ name: PropTypes.string })]),
  };

  const renderPositionCell = (params) => <RenderPositionData {...params} />;
  const renderJobInviteCell = (params) => <RenderJobInviteData {...params} />;

  const RenderLocationData = (props) => {
    const { value } = props;
    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', height: '80%', backgroundColor: '#F2E9FF', borderRadius: '50px' }}
        className="failed-position-selection"
      >
        <Box sx={{ padding: '10px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{`${
          value.name || 'Select a Location'
        }`}</Box>
      </Grid>
    );
  };
  RenderLocationData.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ name: PropTypes.string })]),
  };

  const renderLocationCell = (params) => <RenderLocationData {...params} />;

  const RenderShiftData = (props) => {
    const { id } = props;

    const selectedJob = find((job) => (job.id ? job.id === id : false), rows) || EMPTY_JOB;
    const [displayShift, setDisplayShift] = useState(selectedJob.shift.name);
    useEffect(() => {
      const curJob = find((job) => (job.id ? job.id === id : false), rows) || EMPTY_JOB;
      setDisplayShift(curJob.shift.name);
    }, [id]);

    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', height: '80%', backgroundColor: '#F2E9FF', borderRadius: '50px' }}
        className="failed-position-selection"
      >
        <Box sx={{ padding: '10px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{`${
          displayShift || 'Display Shift'
        }`}</Box>
      </Grid>
    );
  };
  RenderShiftData.propTypes = {
    id: PropTypes.string,
  };

  const renderShiftCell = (params) => <RenderShiftData {...params} />;

  const RenderOpeningsData = (props) => {
    const { value } = props;
    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', height: '80%', backgroundColor: '#F2E9FF', borderRadius: '50px' }}
      >
        <Box sx={{ padding: '10px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{`${
          value || 'Enter #'
        }`}</Box>
      </Grid>
    );
  };
  RenderOpeningsData.propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({ name: PropTypes.string }),
    ]),
  };

  const renderOpeningsCell = (params) => <RenderOpeningsData {...params} />;

  const RenderOverStaffOpeningsData = (props) => {
    const { value } = props;
    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', height: '80%', backgroundColor: '#F2E9FF', borderRadius: '50px' }}
        className="failed-position-selection"
      >
        <Box sx={{ padding: '10px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{`${
          value || 'Enter #'
        }`}</Box>
      </Grid>
    );
  };
  RenderOverStaffOpeningsData.propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({ name: PropTypes.string }),
    ]),
  };
  const renderOverStaffOpeningsCell = (params) => <RenderOverStaffOpeningsData {...params} />;

  const RenderStartInfo = (props) => {
    const { id } = props;
    const selectedJob = find((job) => (job.id ? job.id === id : false), rows) || EMPTY_JOB;
    const displayStartInfo = selectedJob.startInfo;
    let res = `${displayStartInfo.month || ''}/${displayStartInfo.day || ''}/${
      displayStartInfo.year || ''
    }`;
    res = res.length < 3 ? '' : res;
    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', height: '80%', backgroundColor: '#F2E9FF', borderRadius: '50px' }}
        className="failed-position-selection"
      >
        <Box sx={{ padding: '10px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{`${
          res || 'Date'
        }`}</Box>
      </Grid>
    );
  };

  RenderStartInfo.propTypes = {
    id: PropTypes.string,
  };

  const renderStartInfoCell = (params) => <RenderStartInfo {...params} />;

  function PositionDropdownEditCell(props) {
    const { id, value, field } = props;

    const handleChange = async (event) => {
      const selectedPositionTemplate = find(
        (pt) => pt._id === event.target.value,
        positionTemplateData
      );
      const newPosition = {
        name: get('name', selectedPositionTemplate),
        id: get('_id', selectedPositionTemplate),
      };

      await apiRef.current.setEditCellValue({
        id,
        field,
        value: newPosition,
      });

      const newRows = cloneDeep(rows);
      const locOptions = get('locations', selectedPositionTemplate).map((loc) => ({
        name: `${get('description', loc)} - ${get('address', loc)}`,
        id: loc._id,
      }));
      setSelectedPositionLocations(locOptions);

      const selectedJobsListIndex = findIndex((job) => (job.id ? job.id === id : false), newRows);
      newRows[selectedJobsListIndex].locationOptions = [...locOptions];

      const shiftOptions = selectedPositionTemplate.shifts.map((shift) => ({
        id: shift._id,
        name: nameAndTimeToReadable(shift.name, shift.start, shift.end),
        start: shift.start,
        end: shift.end,
      }));

      newRows[selectedJobsListIndex].shiftOptions = [...shiftOptions];

      // reset location and shift selection when template update
      newRows[selectedJobsListIndex].location = {};
      newRows[selectedJobsListIndex].shift = {};
      setRows(newRows);
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        sx={{ width: '100%', border: 0 }}
        // ref={handleRef}
        labelId="position-template-select"
        name="template-position-selector"
        value={value.id || ''}
        onChange={handleChange}
        autoWidth
        inputProps={{ 'data-testid': 'position-template-select' }}
        renderValue={value.id !== '' ? undefined : () => <em>Answer</em>}
      >
        {sortedPositionTemplates.map((option) => {
          const { name: optionName, approved, _id: keyId } = option;
          return (
            <MenuItem key={keyId} value={option._id} disabled={!approved}>
              {optionName}
            </MenuItem>
          );
        })}
      </Select>
    );
  }

  PositionDropdownEditCell.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ id: PropTypes.string })]),
    field: PropTypes.string,
  };

  function LocationDropdownEditCell(props) {
    const { id, value, field } = props;

    const selectedJob = find((job) => (job.id ? job.id === id : false), rows) || EMPTY_JOB;

    const options = selectedJob.locationOptions;
    const currentLocValue = find((loc) => loc.id === value.id, options);
    const [selectedValue, setSelectedValue] = useState(currentLocValue ? currentLocValue.id : '');

    const handleChange = async (event) => {
      const selectedLocation = find(
        (loc) => loc.id === event.target.value,
        selectedPositionLocations
      );

      const newLocation = {
        name: selectedLocation.name,
        id: selectedLocation.id,
      };
      setSelectedValue(newLocation.id);
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: newLocation,
      });

      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        sx={{ width: '100%' }}
        name="template-location-selector"
        labelId="template-location-selector"
        inputProps={{ 'data-testid': 'template-location-selector' }}
        value={selectedValue || ''}
        onChange={handleChange}
        autoWidth
      >
        {options.map((option) => {
          const { name: optionName, id: keyId } = option;

          return (
            <MenuItem key={keyId} value={option.id}>
              {optionName}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
  LocationDropdownEditCell.propTypes = {
    id: PropTypes.string,
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ id: PropTypes.string })]),
  };

  function ShiftDropdownEditCell(props) {
    const { id, value, field } = props;

    const selectedJob = find((job) => (job.id ? job.id === id : false), rows) || EMPTY_JOB;
    const options = selectedJob.shiftOptions;

    const currentLocValue = find((shift) => shift.id === value.id, selectedJob.shiftOptions);
    const [selectedValue, setSelectedValue] = useState(currentLocValue ? currentLocValue.id : '');

    const handleChange = async (event) => {
      const selectedShift = find(
        (shift) => shift.id === event.target.value,
        selectedJob.shiftOptions
      );
      const newShift = {
        name: selectedShift.name,
        id: selectedShift.id,
      };
      setSelectedValue(newShift.id);
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: newShift,
      });
      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <Select
        sx={{ width: '100%' }}
        name="template-shift-selector"
        value={selectedValue || ''}
        onChange={handleChange}
        autoWidth
      >
        {options.map((option) => {
          const { name: optionName, id: keyId } = option;

          return (
            <MenuItem key={keyId} value={option.id}>
              {optionName}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
  ShiftDropdownEditCell.propTypes = {
    id: PropTypes.string,
    field: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ id: PropTypes.string })]),
  };

  function DatePickerEditCell(props) {
    const { id, field, value, error, errormessage } = props;
    const selectedJob = find((job) => (job.id ? job.id === id : false), rows) || EMPTY_JOB;

    const currentDate =
      selectedJob.startInfo &&
      selectedJob.startInfo.year &&
      selectedJob.startInfo.month &&
      selectedJob.startInfo.day
        ? new Date(
            selectedJob.startInfo.year,
            selectedJob.startInfo.month - 1,
            selectedJob.startInfo.day
          )
        : null;

    const initVal = value.year ? new Date(value.year, value.month - 1, value.day) : currentDate;
    const [disVal, setDisVal] = useState(initVal);

    const handleChange = async (newValue) => {
      const SelectedStartInfo = dateToYearMonthDayMinutes(newValue, 0);
      const newStartInfo = {
        year: SelectedStartInfo.year,
        day: SelectedStartInfo.day,
        month: SelectedStartInfo.month,
      };

      setDisVal(newValue);
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: newStartInfo,
      });

      apiRef.current.stopCellEditMode({ id, field });
    };

    return (
      <StyledTooltip
        arrow
        sx={{
          '& .MuiTooltip-arrow': {
            color: 'red',
          },
        }}
        placement="left"
        open={!!error}
        title={<em>{errormessage}</em>}
      >
        <div>
          <DatePickerField
            inputFormat="MM/dd/yyyy"
            value={disVal}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </StyledTooltip>
    );
  }
  DatePickerEditCell.propTypes = {
    id: PropTypes.string,
    field: PropTypes.string,
    error: PropTypes.bool,
    errormessage: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        year: PropTypes.number,
        month: PropTypes.number,
        day: PropTypes.number,
      }),
    ]),
  };

  function OpeningsEditCell(props) {
    const { error, errormessage } = props;

    return (
      <StyledTooltip
        arrow
        sx={{
          '& .MuiTooltip-arrow': {
            color: 'red',
          },
        }}
        placement="left"
        open={!!error}
        title={<em>{errormessage}</em>}
      >
        <div>
          <GridEditInputCell {...props} />
        </div>
      </StyledTooltip>
    );
  }
  OpeningsEditCell.propTypes = {
    error: PropTypes.bool,
    errormessage: PropTypes.string,
  };

  function OverstaffOpeningsEditCell(props) {
    const { error, errormessage } = props;

    return (
      <StyledTooltip
        arrow
        sx={{
          '& .MuiTooltip-arrow': {
            color: 'red',
          },
        }}
        placement="left"
        open={!!error}
        title={<em>{errormessage}</em>}
      >
        <div>
          <GridEditInputCell {...props} />
        </div>
      </StyledTooltip>
    );
  }
  OverstaffOpeningsEditCell.propTypes = {
    error: PropTypes.bool,
    errormessage: PropTypes.string,
  };

  const renderPositionDropdownEditCell = (params) => <PositionDropdownEditCell {...params} />;

  const renderJobInviteDropdownEditCell = (params) => (
    <JobInviteDropdownEditCell params={params} dispatch={dispatch} apiRef={apiRef} />
  );

  const renderLocationDropdownEditCell = (params) => <LocationDropdownEditCell {...params} />;

  const renderShiftDropdownEditCell = (params) => <ShiftDropdownEditCell {...params} />;

  const renderDatePickerEditCell = (params) => <DatePickerEditCell {...params} />;

  const renderOpeningsEditCell = (params) => <OpeningsEditCell {...params} />;

  const handleDeleteClick = (id) => () => {
    const newRows = rows.filter((row) => row.id !== id);

    if (newRows.length === 0) {
      const newJob = { ...EMPTY_JOB, id: uuidv4(), isNew: true };
      setRows([newJob]);
    } else {
      setRows(newRows);
    }
    dispatch(updateValidation(rowValidator(newRows)));
  };

  const handleCellClick = React.useCallback(
    (params) => {
      if (
        params.cellMode === GridCellModes.View &&
        params.field !== 'actions' &&
        (params.field === 'position' || apiRef.current.getRow(params.id).position.id)
      ) {
        if ((isCorporationRequired && selectedCorp) || !isCorporationRequired) {
          setCellModesModel((prevModeStatus) => ({
            // change all cells that are not in selected rows to View mode
            ...Object.keys(prevModeStatus).reduce(
              (preRows, id) => ({
                ...preRows,
                [id]: Object.keys(prevModeStatus[id]).reduce(
                  (preFields, field) => ({
                    ...preFields,
                    [field]: { mode: GridCellModes.View },
                  }),
                  {}
                ),
              }),
              {}
            ),
            [params.id]: {
              // change other cells that is existing in selected rows to View mode
              ...Object.keys(prevModeStatus[params.id] || {}).reduce(
                (preFields, field) => ({ ...preFields, [field]: { mode: GridCellModes.View } }),
                {}
              ),
              // change selected cell to Edit mode
              [params.field]: { mode: GridCellModes.Edit },
            },
          }));
        }
      }
    },
    [apiRef, selectedCorp, isCorporationRequired]
  );

  const handleCellModesModelChange = React.useCallback(
    (newModel) => {
      setCellModesModel(() => ({
        ...Object.keys(newModel).reduce(
          (preRows, id) => ({
            ...preRows,
            [id]: Object.keys(newModel[id]).reduce(
              (preFields, field) => ({
                ...preFields,
                [field]:
                  ((apiRef.current.getRow(id) && apiRef.current.getRow(id).position.id) ||
                    field === 'position') &&
                  ((isCorporationRequired && selectedCorp) || !isCorporationRequired)
                    ? newModel[id][field]
                    : { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
      }));
    },
    [apiRef, isCorporationRequired, selectedCorp]
  );

  const handleStateChange = () => {
    if (
      rows.length === 1 &&
      rows[0] &&
      rows[0].isNew &&
      !rows[0].position.id &&
      !cellModesModel[rows[0].id] &&
      !isCorporationRequired
    ) {
      setCellModesModel({
        [rows[0].id]: {
          position: { mode: GridCellModes.Edit },
        },
      });
    }
  };

  const handleAddClick = () => {
    const newJob = { ...EMPTY_JOB, id: uuidv4(), isNew: true };
    setRows([...rows, newJob]);
    dispatch(updateValidation(rowValidator([...rows, newJob])));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
    setRows(newRows);
    dispatch(updateValidation(rowValidator(newRows)));
    return updatedRow;
  };

  const cellClassName = (params) => {
    const { field, id } = params;

    if (params.value == null) {
      return '';
    }

    if (
      field === 'position' ||
      field === 'location' ||
      field === 'shift' ||
      field === 'startInfo' ||
      field === 'numOpenings' ||
      field === 'jobInvite'
    ) {
      const selectJobValidate = find(
        (job) => (job.id ? job.id === id : false),
        displayValidateList
      );
      if (selectJobValidate && selectJobValidate[field]) {
        return clsx('create-new-jobs', {
          invalidvalue: selectJobValidate[field].isValid === false,
          validvalue: selectJobValidate[field].isValid === true,
        });
      }
    }
    return '';
  };

  const jobsColumnData = [
    {
      field: 'position',
      headerName: 'Positions',
      minWidth: 170,
      flex: 0.3,
      editable: true,
      renderEditCell: renderPositionDropdownEditCell,
      renderCell: renderPositionCell,
      cellClassName,
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 150,
      flex: 0.35,
      minWidth: 150,
      editable: true,
      renderEditCell: renderLocationDropdownEditCell,
      renderCell: renderLocationCell,
      cellClassName,
    },
    {
      field: 'startInfo',
      headerName: 'Date',
      width: 150,
      editable: true,
      renderEditCell: renderDatePickerEditCell,
      renderCell: renderStartInfoCell,
      cellClassName,
      preProcessEditCellProps: (params) => {
        const validateFunction =
          user.role === UserRole.ADMIN
            ? composeValidators(isValidDateObject)
            : composeValidators(isValidDateObject, isDateEqualOrGreaterThanTodayDateObject);
        const hasError = validateFunction(params.props.value);
        return { ...params.props, error: !!hasError, errormessage: hasError };
      },
    },
    {
      field: 'shift',
      headerName: 'Time',
      flex: 0.3,
      minWidth: 150,
      editable: true,
      renderCell: renderShiftCell,
      renderEditCell: renderShiftDropdownEditCell,
      cellClassName,
    },
    {
      field: 'numOpenings',
      headerName: 'Openings',
      width: 90,
      editable: true,
      headerAlign: 'center',
      type: 'number',
      cellClassName,
      renderCell: renderOpeningsCell,
      renderEditCell: renderOpeningsEditCell,
      preProcessEditCellProps: (params) => {
        const validateFunction = composeValidators(isInteger, isGreaterThan(0));
        const hasError = validateFunction(params.props.value);
        return { ...params.props, error: !!hasError, errormessage: hasError };
      },
    },
    isAdmin && {
      field: 'numOverstaff',
      headerName: 'Overstaff Openings',
      minWidth: 120,
      flex: 0.1,
      editable: true,
      headerAlign: 'center',
      type: 'number',
      cellClassName,
      renderCell: renderOverStaffOpeningsCell,
      renderEditCell: OverstaffOpeningsEditCell,
      preProcessEditCellProps: (params) => {
        const validateFunction = composeValidators(isInteger, isGreaterThanOrEqual(0));
        const hasError = validateFunction(params.props.value);
        return { ...params.props, error: !!hasError, errormessage: hasError };
      },
    },
    {
      field: 'jobInvite',
      headerName: 'Send Invites',
      minWidth: 183,
      flex: 0.3,
      editable: true,
      renderEditCell: renderJobInviteDropdownEditCell,
      renderCell: renderJobInviteCell,
      cellClassName,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Delete',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={
            <Box
              component="img"
              sx={{
                height: 28,
                width: 28,
              }}
              alt="Delete"
              src={Clean}
            />
          }
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <Grid item xs={12}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          sx={{
            width: '100%',
            paddingTop: '30px',
            '& .super-app-theme--cell': {
              // backgroundColor: 'rgba(224, 183, 60, 0.55)',
              // color: '#1a3e72',
            },
            '& .Mui-error': {
              color: '#FF2D01',
            },
            '& .create-new-jobs.validvalue': {
              // Should turn valid data to green?
              // backgroundColor: 'rgba(157, 255, 118, 0.49)',
            },
            '& .create-new-jobs.invalidvalue': {
              // if data is not valid change background color to red
              backgroundColor: '#FFC4B8',
            },

            '& .MuiOutlinedInput-notchedOutline': {
              // prevent double border
              border: 'none',
            },
          }}
        >
          <DataGridPro
            disableVirtualization={disableVirtualization}
            columns={jobsColumnData.filter(Boolean)}
            rows={rows}
            apiRef={apiRef}
            processRowUpdate={processRowUpdate}
            cellModesModel={cellModesModel}
            onCellModesModelChange={handleCellModesModelChange}
            onCellClick={handleCellClick}
            onStateChange={handleStateChange}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Press ADD JOB button to add new job.
                </Stack>
              ),
            }}
            autoHeight
            hideFooter
            experimentalFeatures={{ newEditingApi: true }}
            sx={dataGridStyles}
          />
        </Box>
        <Grid container sx={{ justifyContent: 'flex-end', marginTop: 1 }}>
          {!(!isAllDataValid && rows.length !== 0) && (
            <Box
              onClick={handleAddClick}
              component="img"
              sx={{
                height: 28,
                width: 28,
              }}
              alt="Add"
              src={Add}
              style={{ marginRight: 34 }}
            />
          )}
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

CreateJobsDataGridLS.propTypes = {
  disableVirtualization: PropTypes.bool,
};

export default CreateJobsDataGridLS;
