import React from 'react';
import PropTypes from 'prop-types';

import BackdropCircularProgress from '../BackdropCircularProgress';

import { getNextTraining } from './helpers';

const AppTraining = ({ user, loading }) =>
  loading ? <BackdropCircularProgress /> : getNextTraining(user);

AppTraining.propTypes = {
  user: PropTypes.shape({}),
  loading: PropTypes.bool,
};
export default AppTraining;
