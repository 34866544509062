import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { PRIMARY_FONT } from '../../theme/fontConstants';

import ExpandableCard from './SubComponents/ExpandableCard';

const FAQcomponent = ({ sx = {}, faqList = [] }) => {
  const header = 'FAQs';
  const theme = useTheme();

  return (
    <Grid
      item
      sx={{
        border: '1px solid #bbb',
        borderRadius: '5px',
        padding: theme.spacing(2),
        height: '80vh',
        overflowY: 'scroll',
        ...sx,
      }}
    >
      <Grid item>
        <Typography
          sx={{
            paddingBottom: '4px',
            fontSize: '20px',
            fontFamily: PRIMARY_FONT[700],
            paddingLeft: '8px',
            lineHeight: '25px',
          }}
        >
          {header}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {faqList.map((faqItem) => (
          <ExpandableCard faqItem={faqItem} key={faqItem?.header} />
        ))}
      </Grid>
    </Grid>
  );
};

FAQcomponent.propTypes = {
  sx: PropTypes.shape({}),
  faqList: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      bodyElements: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};
export default FAQcomponent;
