import React, { useCallback } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Drawer from '@mui/material/Drawer';

import WorkFlow from '../../../components/WorkFlow';
import {
  selectBatchApprovalError,
  selectIsProcessingBatchApproval,
} from '../../../store/selectors/timeSheetSelector';
import { clearBatchApprovalError, postTimeCardBatchApproval } from '../reducer';

import { formFieldData, readOnlyValues } from './FormFieldData/FormFieldData';

const TimeKeepingBatchApprovalDrawer = ({ isOpen, onClose, submitValue, displayValue }) => {
  const dispatch = useDispatch();
  const isProcessingBatchApproval = useSelector(selectIsProcessingBatchApproval);
  const batchApprovalError = useSelector(selectBatchApprovalError);
  const handleOnClose = useCallback(() => {
    onClose();
    dispatch(clearBatchApprovalError());
  }, [dispatch, onClose]);

  const handleSubmitValues = () => {
    // Add JSON schema validation here. If there is a validation error, return false.
    dispatch(postTimeCardBatchApproval({ placements: submitValue }));
    return true;
  };

  const adjustDisplayValues = () => {
    const readOnlyList = [];
    displayValue.forEach((display) => {
      readOnlyList.push({
        name: get(['values', 'name'], display),
        value: get(['values', 'hours'], display),
      });
    });
    return readOnlyList;
  };

  const calculateTotalHours = () => [
    {
      name: 'Total Hours',
      value: displayValue.reduce((prev, cur) => prev + get(['values', 'hours'], cur), 0),
    },
  ];

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{ sx: { width: '576px' } }}
    >
      <WorkFlow
        confirmationPageButtonText={LanguageConverter('button.return.timekeeping')}
        formSpec={formFieldData(adjustDisplayValues, calculateTotalHours)}
        onClose={handleOnClose}
        readOnlyValues={readOnlyValues}
        attemptToHandleAPICall={(values) => handleSubmitValues(values)}
        isApiCallProcessing={isProcessingBatchApproval}
        processingMessage="message.generic.processing"
        successMessage="success.timecard.batch.approval"
        errorMessage={batchApprovalError}
      />
    </Drawer>
  );
};

TimeKeepingBatchApprovalDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  submitValue: PropTypes.arrayOf(PropTypes.string),
  displayValue: PropTypes.arrayOf(PropTypes.shape({})),
};

export default TimeKeepingBatchApprovalDrawer;
