import { get } from 'lodash/fp';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isProcessingPostCorp: false,

  errors: {},
};

export const createCorporationSlice = createSlice({
  name: 'createCorporationSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    postCorporation: (state) => {
      state.isProcessingPostCorp = true;
    },
    postCorporationProcessed: (state) => {
      state.errors = {};
      state.isProcessingPostCorp = false;
    },
    postCorporationError: (state, action) => {
      state.isProcessingPostCorp = false;
      state.errors = get('payload', action);

      // Leaving this in as this was handling field level errors, and will need to be altered at a later time when they are re-implemented
      /* const { data } = action.payload;
      const errors = {};
      errors.name = data.name ? data.name : null;
      errors.endTime = data.end ? data.end : null;

      state.errors = errors; */
    },
  },
});

export const createCorporationReducer = createCorporationSlice.reducer;

export const { postCorporation, postCorporationProcessed, postCorporationError } =
  createCorporationSlice.actions;
