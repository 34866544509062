import theme from '../../../theme/theme';
// input:'Firstname Middlename1 Middlename2 Middlename3 Lastname' output:'FL'
// input:'Firstname Middlename1 Middlename2 Lastname' output:'FL'
// input:'Firstname Middlename Lastname' output:'FL'
// input:'Firstname Lastname' output:'FL'
// input:'Firstname' output:'F'

const processString = (inputString) => {
  const regex = /(\S).*\s(.)/;
  const input = inputString.trim();
  const match = input.match(regex);

  // if string contains space
  // return first letter of first part of string and first letter of last part string
  if (match && match.length === 3) {
    return match[1] + match[2];
  }

  // if string contains no space. return first letter of string.
  return input.charAt(0);
};

const stringAvatar = (user) =>
  (user.name &&
    user._id && {
      sx: {
        bgcolor: `#${user._id.slice(-6)}`,
        fontSize: '16px',
        height: '28px',
        width: '28px',
      },
      children: processString(user.name).toUpperCase(),
    }) || {
    sx: {
      bgcolor: `${theme.components.profileMenu.palette.white}`,
      height: '28px',
      width: '28px',
    },
    children: '',
  };

export default stringAvatar;
