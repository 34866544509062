import React, { useMemo, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Form as RForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import Button, { BUTTON_TYPE, BUTTON_VARIANT } from '../../../../components/Button';
import ButtonActions from '../../../../components/Form/ButtonActions';
import { buildFormField } from '../../../../components/Form/formFieldFactory';
import selectUser from '../../../../store/selectors/appSelector';
import theme from '../../../../theme';
import { USER_STATUSES } from '../../helpers';

import {
  CandidateFormFieldDataOverview,
  CommonFormFieldDataOverview,
} from './FormData/FormFieldDataOverview';
import DeactivateConfirmationModal from './subComponent/DeactivateConfirmationModal';
import DNRandDNAComponent from './subComponent/DNRandDNAComponent';
import ReadyToWorkChecks from './subComponent/ReadyToWorkChecks';
import SendAccountVerificationEmailBtn from './subComponent/SendAccountVerificationEmailBtn';

const UsersCommonFieldLayout = ({
  currUserRole,
  selectedUser,
  userEmail,
  userRole,
  userStatus,
}) => {
  const user = useSelector(selectUser);
  const selectedUserID = get('id', selectedUser);
  const [isDeactivateUserDialogOpen, setIsDeactivateUserDialogOpen] = useState(false);

  const emailField = () =>
    userRole === UserRole.CANDIDATE && currUserRole === UserRole.ADMIN ? (
      <Typography>{buildFormField(CommonFormFieldDataOverview.editEmail)}</Typography>
    ) : (
      <Typography>{buildFormField(CommonFormFieldDataOverview.email)}</Typography>
    );

  const phoneNumberField = () =>
    userRole === UserRole.CANDIDATE && currUserRole === UserRole.ADMIN ? (
      <Typography>{buildFormField(CommonFormFieldDataOverview.editPhoneNumber)}</Typography>
    ) : (
      <Typography>{buildFormField(CommonFormFieldDataOverview.phoneNumber)}</Typography>
    );

  return (
    <>
      <Grid item>
        <Typography>{buildFormField(CommonFormFieldDataOverview.name)}</Typography>
      </Grid>
      <Grid item>
        <Typography>{buildFormField(CommonFormFieldDataOverview.location)}</Typography>
      </Grid>
      <Grid item>
        <Typography>{buildFormField(CommonFormFieldDataOverview.userStatus)}</Typography>
      </Grid>
      <Grid item>
        <Typography>{buildFormField(CommonFormFieldDataOverview.status)}</Typography>
      </Grid>
      {user.role === UserRole.ADMIN && selectedUser?.role === UserRole.CANDIDATE && (
        <Grid item>
          <ReadyToWorkChecks selectedUser={selectedUser} />
        </Grid>
      )}
      <Grid item>
        <Typography>{buildFormField(CommonFormFieldDataOverview.userRole)}</Typography>
      </Grid>
      <Grid item>
        <Typography>{buildFormField(CommonFormFieldDataOverview.role)}</Typography>
      </Grid>
      <Grid item>
        <Typography>{buildFormField(CommonFormFieldDataOverview.userEmail)}</Typography>
      </Grid>
      <Grid item>{emailField()}</Grid>
      {userStatus === USER_STATUSES.UNVERIFIED && (
        <Grid item sx={{ marginTop: '-5px' }}>
          <SendAccountVerificationEmailBtn userID={selectedUserID} userEmail={userEmail} />
        </Grid>
      )}
      <Grid item>
        <Typography>{buildFormField(CommonFormFieldDataOverview.phoneNumberHeader)}</Typography>
      </Grid>
      <Grid item>{phoneNumberField(CommonFormFieldDataOverview)}</Grid>
      {user.role === UserRole.ADMIN && userStatus !== USER_STATUSES.DEACTIVATED && (
        <Grid item>
          <Button
            onClick={() => setIsDeactivateUserDialogOpen(true)}
            text={LanguageConverter('generic.deactivate.account')}
            variant={BUTTON_VARIANT.DELETE}
            sx={{
              width: '146px',
              height: '32px',
              margin: theme.spacing(2, 0),
            }}
          />
          <DeactivateConfirmationModal
            selectedUserID={selectedUserID}
            isDialogOpen={isDeactivateUserDialogOpen}
            setIsDialogOpen={setIsDeactivateUserDialogOpen}
          />
        </Grid>
      )}
    </>
  );
};

const CandidateFieldLayout = ({ DNR, DNA, userID, isLoading, dnrMessage, dnaMessage }) => (
  <>
    <Grid item>
      <Typography>{buildFormField(CandidateFormFieldDataOverview.userRating)}</Typography>
    </Grid>
    <Grid item>
      <Typography>{buildFormField(CandidateFormFieldDataOverview.rating)}</Typography>
    </Grid>
    <Grid item>
      <Typography>{buildFormField(CandidateFormFieldDataOverview.upcomingJobs)}</Typography>
    </Grid>
    <Grid item>
      <Typography>{buildFormField(CandidateFormFieldDataOverview.jobNum)}</Typography>
    </Grid>
    <Grid item>
      <Typography>{buildFormField(CandidateFormFieldDataOverview.userFlag)}</Typography>
    </Grid>
    <Grid item>
      <Typography>
        <DNRandDNAComponent
          DNRorDNA={DNR}
          userID={userID}
          isLoading={isLoading}
          message={dnrMessage}
        />
      </Typography>
    </Grid>
    <Grid item>
      <Typography>
        <DNRandDNAComponent
          DNRorDNA={DNA}
          userID={userID}
          isLoading={isLoading}
          message={dnaMessage}
        />
      </Typography>
    </Grid>
  </>
);
const Overview = ({ onUpdate, initialValues, count, DNR, DNA, isLoading }) => {
  const currUserRole = get('role', useSelector(selectUser));
  const userStatus = get('status', initialValues);
  const userRole = get('role', initialValues);
  const selectedUserID = get('id', initialValues);
  const userEmail = get('email', initialValues);
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  };
  const phoneNumber = formatPhoneNumber(`${get(['candidate', 'phoneNumber'], initialValues)}`);
  const dnrMessage =
    'A candidate will not be able to return to a job facility.They will no longer be able to see or accept jobs at an indicated facility.';
  const dnaMessage =
    'A DNA candidate will not be able to be assigned a job.They will no longer be able to see or accept jobs through the WAE system.';
  const initialValuesData = useMemo(
    () => ({
      ...initialValues,
      DNA: { DNA },
      DNR: { DNR },
      jobNum: `${count}`,
      phoneNumber,
      phoneNumberHeader: 'Phone',
      upcomingJobs: 'Upcoming Jobs',
      userEmail: 'User Email',
      userFlag: 'User Flag',
      userRating: 'Rating', // TODO:replace with general rating
      userRole: 'User Role',
      userStatus: 'User Status',
    }),
    [initialValues, DNR, DNA, count, phoneNumber]
  );

  return (
    <RForm
      onSubmit={(values) => {
        onUpdate(values);
      }}
      initialValues={initialValuesData}
      render={({ handleSubmit, valid, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={0} direction="row" sx={{ width: '100%' }}>
            <Grid container direction="column" item spacing={3}>
              <UsersCommonFieldLayout
                currUserRole={currUserRole}
                selectedUser={initialValues}
                userEmail={userEmail}
                userRole={userRole}
                userStatus={userStatus}
              />
              {userRole === UserRole.CANDIDATE && (
                <CandidateFieldLayout
                  DNR={DNR}
                  DNA={DNA}
                  dnrMessage={dnrMessage}
                  dnaMessage={dnaMessage}
                  userID={selectedUserID}
                  isLoading={isLoading}
                />
              )}
              {userRole === UserRole.CANDIDATE && currUserRole === UserRole.ADMIN && (
                <ButtonActions
                  buttonData={[
                    {
                      color: 'primary',
                      text: 'Update',
                      type: BUTTON_TYPE.SUBMIT,
                      sx: { width: '100%' },
                      disabled: !valid,
                    },
                  ]}
                  formValues={values}
                />
              )}
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

CandidateFieldLayout.propTypes = {
  DNR: PropTypes.shape({}),
  DNA: PropTypes.shape({}),
  userID: PropTypes.string,
  isLoading: PropTypes.bool,
  dnrMessage: PropTypes.string,
  dnaMessage: PropTypes.string,
};

UsersCommonFieldLayout.propTypes = {
  currUserRole: PropTypes.string,
  DNR: PropTypes.shape({}),
  DNA: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  recruiterAdminFieldData: PropTypes.shape({}),
  selectedUser: PropTypes.shape({ id: PropTypes.string, role: PropTypes.string }),
  userEmail: PropTypes.string,
  userRole: PropTypes.string,
  userStatus: PropTypes.string,
};

Overview.propTypes = {
  container: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  onUpdate: PropTypes.func,
  count: PropTypes.number,
  DNR: PropTypes.shape({}),
  DNA: PropTypes.shape({}),
  isLoading: PropTypes.bool,
};

export default Overview;
