import { get } from 'lodash/fp';

import SearchableSelectDropdownFilter, {
  SEARCHBAR_BACKGROUND,
} from '../../../components/Filters/SearchableSelectDropdownFilter';
import SelectDropdownFilter, {
  SELECTDROPDOWN_BACKGROUND,
} from '../../../components/Filters/SelectDropdownFilter';

const additionalFilters = [
  {
    customFilter: {
      background: SELECTDROPDOWN_BACKGROUND.LIGHT,
      field: 'approved',
      operation: 'equals',
      getOptionLabel: (option) => get(['name'], option),
      options: [
        { name: 'Approved', value: true },
        { name: 'Not Approved', value: false },
      ],
      placeholder: 'Status',
      type: SelectDropdownFilter,
    },
  },
  {
    customFilter: {
      background: SEARCHBAR_BACKGROUND.LIGHT,
      field: 'corporation._id',
      operation: 'equalsID',
      optionsAPICallback: {
        httpMethod: 'POST',
        route: 'corporations/read',
        generateBody: (searchTerm) => ({
          filters: [{ operation: 'icontains', field: 'name', value: searchTerm }],
        }),
      },
      getOptionLabel: (option) => get(['name'], option),
      placeholder: 'Corporation',
      type: SearchableSelectDropdownFilter,
    },
  },
];
export default additionalFilters;
