import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import Geofence from '../../../assets/icons/Geofence.svg';
import theme from '../../../theme';
import Button, { BUTTON_VARIANT } from '../../Button';
import IconButton from '../../IconButton';

const RootBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  padding: theme.spacing(2, 8),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
}));

const HeaderBox = styled(Box)(() => ({
  width: '100%',
}));

const HeaderBoxTypography = styled(Box)(() =>
  get(['candidate', 'components', 'timeCards', 'typography', 'punchAlertDrawerHeader'], theme)
);

const DescriptionBoxTypography = styled(Box)(() =>
  get(['candidate', 'components', 'timeCards', 'typography', 'punchAlertDrawerDescription'], theme)
);

const PunchAlertDrawer = ({ content, punchError, onClose }) => (
  <Drawer
    anchor="right"
    open={!!punchError}
    onClose={onClose}
    PaperProps={{ sx: { maxWidth: '576px' } }}
  >
    <HeaderBox>
      <IconButton
        iconElement={<CloseIcon />}
        onClick={() => onClose()}
        size="small"
        sx={{ marginLeft: 0.5, marginTop: 0.5 }}
      />
    </HeaderBox>
    <RootBox>
      <Box
        component="img"
        sx={{
          height: 51.52,
          width: 51.52,
        }}
        alt="Delete"
        src={Geofence}
      />
      <HeaderBoxTypography>{punchError && LanguageConverter(punchError)}</HeaderBoxTypography>
      <DescriptionBoxTypography>
        {punchError && LanguageConverter(`${punchError}.description`)}
      </DescriptionBoxTypography>
      {content}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          alignSelf: 'flex-end',
        }}
      >
        <Box>
          <Button
            onClick={onClose}
            variant={BUTTON_VARIANT.OUTLINED}
            sx={{ padding: theme.spacing(1, 2) }}
            text={LanguageConverter('buttonText.goBack')}
          />
        </Box>
      </Box>
    </RootBox>
  </Drawer>
);

PunchAlertDrawer.propTypes = {
  punchError: PropTypes.string,
  onClose: PropTypes.func,
  content: PropTypes.shape({}),
};

export default PunchAlertDrawer;
