import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme from '../../../theme';
import {
  cardSpacing,
  cardWidthAndHeight,
  radioButtonStyling,
  radioCardDescriptionStyling,
  radioCardLabelStyling,
} from '../FormRadioButtons/styling';

import { invitedWorkerAmountBackgroundGridStyling, invitedWorkerAmountStyling } from './styling';

const EmployeeInviteRadioComponent = ({
  handleWAEAutoFillOnClick,
  handleInviteWorkersRadioOnClick,
  required,
  isInviteOpen,
  invitedWorkerAmount,
}) => {
  const largerThanMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const intl = useIntl();
  return (
    <RadioGroup type="radio" row sx={{ margin: theme.spacing(0, 0, 0, 0.5) }}>
      <Grid container item direction={largerThanMediumScreen ? 'row' : 'column'}>
        <Card
          sx={{
            ...cardWidthAndHeight(largerThanMediumScreen),
            boxShadow: 3,
            borderRadius: '8px',
            ...cardSpacing(2, 'row', largerThanMediumScreen),
          }}
        >
          <CardContent>
            <FormControlLabel
              control={<Radio required={required} sx={{ ...radioButtonStyling }} />}
              key="autoFill"
              label={`${intl.formatMessage({ id: 'job.create.WAEAutoFill' })}`}
              onClick={handleWAEAutoFillOnClick}
              value="a"
              sx={radioCardLabelStyling}
            />
            <Box sx={radioCardDescriptionStyling}>{`${intl.formatMessage({
              id: 'job.create.WAEAutoFillDescription',
            })}`}</Box>
          </CardContent>
        </Card>
        <Card
          sx={{
            ...cardWidthAndHeight(largerThanMediumScreen),
            boxShadow: 3,
            borderRadius: '8px',
            ...cardSpacing(2, 'row', largerThanMediumScreen),
          }}
        >
          <CardContent>
            <Grid container item direction="row" sx={{ justifyContent: 'space-between' }}>
              <FormControlLabel
                control={<Radio required={required} sx={{ ...radioButtonStyling }} />}
                key="inviteWorkers"
                label={`${intl.formatMessage({
                  id: 'job.create.InviteWorkers',
                })}`}
                value="b"
                onClick={handleInviteWorkersRadioOnClick}
                sx={radioCardLabelStyling}
              />
              {!isInviteOpen && (
                <Grid container item sx={invitedWorkerAmountBackgroundGridStyling}>
                  <Typography sx={invitedWorkerAmountStyling}>{invitedWorkerAmount}</Typography>
                </Grid>
              )}
            </Grid>
            <Box sx={radioCardDescriptionStyling}>{`${intl.formatMessage({
              id: 'job.create.InviteWorkersDescription',
            })}`}</Box>
          </CardContent>
        </Card>
      </Grid>
    </RadioGroup>
  );
};

EmployeeInviteRadioComponent.propTypes = {
  handleWAEAutoFillOnClick: PropTypes.func,
  handleInviteWorkersRadioOnClick: PropTypes.func,
  invitedWorkerAmount: PropTypes.number,
  isInviteOpen: PropTypes.bool,
  required: PropTypes.bool,
};

export default EmployeeInviteRadioComponent;
