import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  CARD: 'card',
};

const DraggableCard = ({ children, index, date, moveCard }) => {
  const [isDragging, setIsDragging] = useState(false);
  const dragRef = useRef(null);

  const [, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      setIsDragging(true);
      return { index, date };
    },
    end: (item, monitor) => {
      setIsDragging(false);
      const didDrop = monitor.didDrop();
      if (didDrop) {
        const dropResult = monitor.getDropResult();
        if (dropResult) {
          moveCard(dropResult.date, item.index, dropResult.index);
        }
      }
    },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: () => ({ date, index }),
  });

  useEffect(() => {
    if (dragRef.current) {
      preview(dragRef);
    }
  }, [preview]);

  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {children({ drag })}
    </div>
  );
};

DraggableCard.propTypes = {
  children: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  moveCard: PropTypes.func.isRequired,
};

export default DraggableCard;
