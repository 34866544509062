import * as React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const FormDateTimePickerField = ({
  error,
  fullWidth,
  label,
  onChange,
  value,
  required,
  sx,
  variant,
}) => {
  const intl = useIntl();
  const errorMessage = error ? intl.formatMessage({ id: error }) : undefined;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} component="span">
      <MUIDateTimePicker
        disableFuture
        label={label}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errorMessage}
            fullWidth={fullWidth}
            helperText={errorMessage || null}
            required={required}
            sx={sx}
            variant={variant}
          />
        )}
        value={value}
      />
    </LocalizationProvider>
  );
};
FormDateTimePickerField.propTypes = {
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  label: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
  error: PropTypes.string,
};
export default FormDateTimePickerField;
