import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

const Filter = forwardRef(({ items, handleValueChange, column, customValueChangeHandler }, ref) => (
  <div
    ref={ref}
    style={{
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: column ? 'wrap' : 'nowrap',
      flexDirection: column ? 'column' : 'row',
    }}
  >
    {items.map((item) => (
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', width: column ? '100%' : null }}
        key={item.field}
      >
        <item.type
          field={item.field}
          operation={item.operation}
          fixedValue={item.fixedValue}
          key={item.field || item.customFilter.field}
          onValueChange={
            item.customOnChange
              ? (newVal) => {
                  item.customOnChange(newVal);
                  customValueChangeHandler(newVal);
                }
              : handleValueChange
          }
          reformatValue={item.reformatValue}
          options={item.options}
          placeholder={item.placeholder}
          getOptionLabel={item.getOptionLabel}
          background={item.background}
          sx={item.sx}
          initialValue={item.initialValue}
          allowEmptyArray={item.allowEmptyArray}
          disableClearable={item.disableClearable}
        />
      </Box>
    ))}
  </div>
));

Filter.propTypes = {
  customValueChangeHandler: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  handleValueChange: PropTypes.func,
  column: PropTypes.bool,
};

export default Filter;
