import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import FormSectionDescription from '../CustomComponents/FormSectionDescription';
import FormSectionHeader from '../CustomComponents/FormSectionHeader';
import { numOpeningsField } from '../FormFieldData/CreateJobFields';

const Openings = ({ wrapperSx }) => (
  <Box
    sx={{
      width: '100%',
      ...wrapperSx,
    }}
  >
    <FormSectionHeader messageKey="job.create.numOpen.header" />
    <FormSectionDescription messageKey="job.create.numOpen.desc" />
    <Box sx={{ display: 'flex' }}>{buildFormField(numOpeningsField())}</Box>
  </Box>
);

Openings.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    employer: PropTypes.shape({
      corporation: PropTypes.string,
    }),
  }),
  wrapperSx: PropTypes.shape({}),
};

export default Openings;
