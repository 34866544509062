import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Box, Grid } from '@mui/material';

import theme from '../../theme';
import PillChips from '../PillChips/PillChips';

import { errorHandler } from './formUtils';

const FormRadioChips = ({
  background,
  data,
  fieldErrorData,
  input,
  meta,
  multiSelect,
  size,
  sx,
}) => {
  const { onChange } = input;
  const error = errorHandler(input, meta, fieldErrorData);
  let handleOnClick;
  const allValueAsFalse = data && Object.assign(...data.map((k) => ({ [k]: false })));
  const allInitialValueAsTrue =
    get('value', input) &&
    get('value', input).length > 0 &&
    Object.assign(...get('value', input).map((k) => ({ [k]: true })));
  const [values, setValues] = useState(
    (multiSelect && { ...allValueAsFalse, ...allInitialValueAsTrue }) || {
        ...allValueAsFalse,
        [get('value', input)]: true,
      } ||
      {}
  );

  const convertAllTrueToList = (val) => {
    const labelValues = Object.keys(val);
    return labelValues.filter((label) => val[label]).map((lab) => lab);
  };
  switch (multiSelect) {
    case false:
      handleOnClick = (val) => {
        onChange(val);
        setValues({ ...allValueAsFalse, [val]: true });
      };

      break;
    case true:
      handleOnClick = (val) => {
        setValues({ ...values, [val]: !values[val] });
        onChange(convertAllTrueToList({ ...values, [val]: !values[val] }));
      };

      break;
    default:
      handleOnClick = (val) => {
        onChange(val);
        setValues({ ...allValueAsFalse, [val]: true });
      };
      break;
  }
  const { errorFontFamily, errorFontSize } = get(
    (background === 'dark' && ['components', 'RadioChips', 'typography', 'dark']) || [
      'components',
      'RadioChips',
      'typography',
      'light',
    ],
    theme
  );
  const { errorFontColor } = get(
    (background === 'dark' && ['components', 'RadioChips', 'palette', 'dark']) || [
      'components',
      'RadioChips',
      'palette',
      'light',
    ],
    theme
  );

  const errorSx = {
    color: errorFontColor,
    fontFamily: errorFontFamily,
    fontSize: errorFontSize,
    marginTop: '8px',
    marginLeft: '14px',
  };

  return (
    <Grid container item sx={sx}>
      <Box>
        {data &&
          data.map((d) => (
            <PillChips
              background={background}
              label={d}
              key={d}
              onClick={() => handleOnClick(d)}
              value={values[d]}
              size={size}
              sx={{ marginRight: '8px' }}
            />
          ))}
      </Box>
      <Box sx={errorSx}>{error}</Box>
    </Grid>
  );
};
FormRadioChips.propTypes = {
  background: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
  fieldErrorData: PropTypes.string,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  meta: PropTypes.shape(),
  multiSelect: PropTypes.bool,
  size: PropTypes.string,
  sx: PropTypes.shape({}),
};

export default FormRadioChips;
