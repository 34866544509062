import { get } from 'lodash/fp';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import theme from '../../../theme';

const viewsBatchJobCreateTypographyRequireApprovalTitle = [
  'views',
  'batchJobCreate',
  'typography',
  'requireApprovalTitle',
];

const viewsBatchJobCreateTypographyRequireApprovalDescription = [
  'views',
  'batchJobCreate',
  'typography',
  'requireApprovalDescription',
];

export const requireApprovalTitleSx = {
  color: get([...viewsBatchJobCreateTypographyRequireApprovalTitle, 'fontColor'], theme),
  fontSize: get([...viewsBatchJobCreateTypographyRequireApprovalTitle, 'fontSize'], theme),
  fontFamily: get([...viewsBatchJobCreateTypographyRequireApprovalTitle, 'fontFamily'], theme),
};

export const requireApprovalDescriptionSx = {
  color: get([...viewsBatchJobCreateTypographyRequireApprovalDescription, 'fontColor'], theme),
  fontSize: get([...viewsBatchJobCreateTypographyRequireApprovalDescription, 'fontSize'], theme),
  fontFamily: get(
    [...viewsBatchJobCreateTypographyRequireApprovalDescription, 'fontFamily'],
    theme
  ),
};

export const HeaderGrid = styled(Grid)(() => ({
  padding: theme.spacing(3, 0, 0),
}));

export const FormGrid = styled(Grid)(() => ({
  direction: 'column',
  justifyContent: 'center',
  alignContent: 'space-between',
  padding: theme.spacing(0, 0, 3),
}));
