/* eslint-disable react-hooks/rules-of-hooks */
import { useIntl } from 'react-intl';

import FormSelectDropdownField from '../../../components/Form/FormSelectDropdownField';
import FormTextField from '../../../components/Form/FormTextField';
import { composeValidators, isValidEmail } from '../../../components/Form/validations';

const formFieldData = (isRegisterEmployeeEnabledOnSignUp, isRegisterEmployerEnabledOnSignUp) => {
  const intl = useIntl();
  return [
    {
      ComponentType: FormTextField,
      fullWidth: true,
      label: intl.formatMessage({ id: 'generic.form.field.title.emailAddress' }),
      name: 'email',
      required: true,
      type: 'password',
      validate: composeValidators(isValidEmail),
      variant: 'standard',
    },
    {
      ComponentType: FormSelectDropdownField,
      fullWidth: true,
      label: intl.formatMessage({ id: 'page.registration.form.field.title.iAm' }),
      name: 'userRole',
      required: true,
      variant: 'standard',
      options: [
        {
          id: 'Employer',
          name: intl.formatMessage({ id: 'page.registration.form.field.options.employer' }),
          disabled: !isRegisterEmployerEnabledOnSignUp,
        },
        {
          id: 'Employee',
          name: intl.formatMessage({ id: 'page.registration.form.field.options.employee' }),
          disabled: !isRegisterEmployeeEnabledOnSignUp,
        },
      ],
    },
  ];
};

export default formFieldData;
