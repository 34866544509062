import React from 'react';

import Box from '@mui/material/Box';

import thumbsUp from '../../assets/thumb-up-outline.svg';

const snackBarIcons = {
  SUCCESS: (
    <Box
      component="img"
      sx={{
        height: 35,
        width: 34,
      }}
      alt="Success"
      src={thumbsUp}
    />
  ),
  FAIL: <Box />,
  NEUTRAL: <Box />,
};

export default snackBarIcons;
