import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectAppSession = (state) => get(['app', 'session'], state);
const selectApp = (state) => get(['app'], state);

const selectUser = createSelector(selectAppSession, (session) => session.user);
export const selectIsProcessingAppTraining = createSelector(
  selectApp,
  (app) => app.isProcessingAppTraining
);

export default selectUser;
