import { get, map } from 'lodash/fp';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  corporations: [],
  departments: [],
  isLoading: false,
  isLoadingDepartments: false,
};
export const corporationListSlice = createSlice({
  name: 'corporationListSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */

    getCorporationsList: (state) => {
      state.isLoading = true;
    },
    getCorporationsListProcessed: (state, action) => {
      const { count, documents } = action.payload.data;
      const mappedCorporations = map((corp) => {
        const {
          _id,
          id,
          name,
          mailingAddress,
          metadata,
          quickBooksCompanyName,
          DNR,
          favorite,
          departments,
        } = corp;
        return {
          id: id || _id,
          name,
          mailingAddress: mailingAddress
            ? `${mailingAddress.address},${mailingAddress.city},${mailingAddress.state},${mailingAddress.zipcode}`
            : '',
          externalId: get(['bullhorn', 'id'], metadata),
          quickBooksCompanyName,
          DNR,
          favorite,
          departments,
          // place holder for map
          geoLoc: {
            longitude: Math.random() * 180,
            latitude: Math.random() * 85,
          },
          otherLocations: 'Other Location Placeholder, zip',
        };
      }, documents);
      state.totalRowCount = count;
      state.corporations = mappedCorporations;
      state.isLoading = false;
    },
    getCorporationsListError: (state) => {
      state.isLoading = false;
    },

    updateFavoriteDnr: (state) => {
      state.isPostingFavoriteDnr = true;
    },
    updateFavoriteDnrProcessed: (state) => {
      state.isPostingFavoriteDnr = false;
      // update current data in redux
      // const corporation = get(['payload', 'data', 'documents'], action);
      /* eslint-disable no-param-reassign */
    },
    updateFavoriteDnrError: (state) => {
      state.isPostingFavoriteDnr = false;
    },
    getDepartments: (state) => {
      state.isLoadingDepartments = true;
    },
    getDepartmentsProcessed: (state, action) => {
      const documents = get(['payload', 'data', 'documents'], action);
      state.isLoadingDepartments = false;
      state.departments = documents;
    },
    getDepartmentsError: (state) => {
      state.isLoadingDepartments = false;
    },
    createDepartment: (state) => {
      state.isLoading = true;
    },
    createDepartmentProcessed: (state) => {
      state.isLoading = false;
    },
    createDepartmentError: (state) => {
      state.isLoading = true;
    },

    editDepartment: (state) => {
      state.isLoading = true;
    },
    editDepartmentProcessed: (state) => {
      state.isLoading = false;
    },
    editDepartmentError: (state) => {
      state.isLoading = false;
    },

    deleteDepartment: (state) => {
      state.isLoading = true;
    },
    deleteDepartmentProcessed: (state) => {
      state.isLoading = false;
    },
    deleteDepartmentError: (state) => {
      state.isLoading = false;
    },
  },
});

export const corporationListReducer = corporationListSlice.reducer;

export const {
  getCorporationsList,
  getCorporationsListProcessed,
  getCorporationsListError,
  updateFavoriteDnr,
  updateFavoriteDnrProcessed,
  updateFavoriteDnrError,
  getDepartments,
  getDepartmentsProcessed,
  getDepartmentsError,
  createDepartment,
  createDepartmentProcessed,
  createDepartmentError,
  deleteDepartment,
  deleteDepartmentProcessed,
  deleteDepartmentError,
  editDepartment,
  editDepartmentProcessed,
  editDepartmentError,
} = corporationListSlice.actions;
