import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Paper as MuiPaper, Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import theme from '../../../theme';

const borderRadius = theme.spacing(1);

const StyledPaper = styled(MuiPaper)(() => ({
  height: '100%',
  borderRadius,
  backgroundColor: get(
    ['views', 'reporting', 'palette', 'paper', 'backgroundColor', 'default'],
    theme
  ),
}));

const themePath = ['views', 'reporting', 'typography', 'card'];

const ContentLabelCard = ({ sx, data, staticData, loading }) => (
  <Grid item xs>
    <StyledPaper sx={{ backgroundColor: get(['color'], staticData), ...sx }}>
      {loading ? (
        <Skeleton height="inherit" variant="rectangular" sx={{ borderRadius }} />
      ) : (
        <Grid
          container
          height="inherit"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: get([...themePath, 'content', 'fontColor'], theme),
              fontFamily: get([...themePath, 'content', 'fontFamily'], theme),
              fontSize: get([...themePath, 'content', 'fontSize'], theme),
              fontWeight: get([...themePath, 'content', 'fontWeight'], theme),
              lineHeight: '34px',
            }}
          >
            {data}
          </Typography>
          <Typography
            sx={{
              color: get([...themePath, 'label', 'fontColor'], theme),
              fontFamily: get([...themePath, 'label', 'fontFamily'], theme),
              fontSize: get([...themePath, 'label', 'fontSize'], theme),
              fontWeight: get([...themePath, 'label', 'fontWeight'], theme),
              lineHeight: '18px',
            }}
          >
            {get(['label'], staticData)}
          </Typography>
        </Grid>
      )}
    </StyledPaper>
  </Grid>
);

ContentLabelCard.propTypes = {
  sx: PropTypes.shape({}),
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  staticData: PropTypes.shape({}),
};

export default ContentLabelCard;
