import theme from '../../theme';

import { BUTTON_VARIANT } from './buttonConstants';

export default {
  [BUTTON_VARIANT.OUTLINED]: {
    buttonBgColor: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    hoverBgColor: `${theme.button.palette.hover}`,
    outlinedButtonTextStyle: {
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    outlinedButtonHoverTextStyle: {
      color: 'white',
      WebkitBackgroundClip: 'border-box',
      WebkitTextFillColor: 'white',
    },
    outlinedOnFocusStyle: {
      border: `2px solid ${theme.button.palette.focus}`,
    },
    border: `2px solid ${theme.button.palette.primary}`,
  },

  [BUTTON_VARIANT.TEXT]: {
    buttonBgColor: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    hoverBgColor: `${theme.button.palette.primary}`,
    outlinedButtonTextStyle: {
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    outlinedButtonHoverTextStyle: {
      border: `2px solid ${theme.button.palette.primary}`,
      color: 'white',
      WebkitBackgroundClip: 'border-box',
      WebkitTextFillColor: 'white',
    },
    outlinedOnFocusStyle: {
      border: `2px solid ${theme.button.palette.focus}`,
    },
    border: `2px solid ${theme.button.palette.primary}`,
  },

  [BUTTON_VARIANT.DELETE]: {
    buttonBgColor: `${theme.button.palette.delete}`,
    hoverBgColor: `${theme.button.palette.delete}`,
    outLine: {
      outline: `2px solid ${theme.button.palette.focus}`,
    },
  },

  DEFAULT: {
    buttonBgColor: `linear-gradient(81.37deg, ${theme.button.palette.primary} 33.18%, ${theme.button.palette.secondary} 94.14%)`,
    outLine: {
      outline: `2px solid ${theme.button.palette.focus}`,
    },
    hoverBgColor: `${theme.button.palette.hover}`,
  },
};
