import { get } from 'lodash/fp';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isProcessingPostPT: false,
  postPTError: null,
};

export const createPositionTemplateSlice = createSlice({
  name: 'createPositionTemplateSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    postPositionTemplate: (state) => {
      state.isProcessingPostPT = true;
    },
    postPositionTemplateProcessed: (state) => {
      state.isProcessingPostPT = false;
    },
    postPositionTemplateError: (state, action) => {
      state.isProcessingPostPT = false;
      const messageData =
        get(['payload', 'GLOBAL', 'messageKey'], action) || 'error.generic.something.went.wrong';
      state.postPTError = messageData;
    },
    clearPostPTError: (state) => {
      state.postPTError = null;
    },
  },
});

export const createPositionTemplateReducer = createPositionTemplateSlice.reducer;

export const {
  postPositionTemplate,
  postPositionTemplateProcessed,
  postPositionTemplateError,
  clearPostPTError,
} = createPositionTemplateSlice.actions;
