import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import DNRReasons from '@careerstart/wae-common/src/main/constants/DNR-reasons';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import { FormControlLabel, RadioGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Button from '../../../../../components/Button';
import IconButton from '../../../../../components/IconButton/IconButton';
import theme from '../../../../../theme/theme';

import BlueBackgroundRadio from './BlueBackgroundRadio';
import DNRorDNAAutocomplete from './DNRorDNAAutocomplete';

const dnrReasonOptions = [];

Object.keys(DNRReasons).forEach((key) => {
  dnrReasonOptions.push({ id: key, label: DNRReasons[key] });
});

const detailViewTypographyReasonText = [
  'components',
  'detailView',
  'typography',
  'reasonTextWhite',
];
const detailViewTypographydnrTextField = ['components', 'detailView', 'typography', 'dnrTextField'];
const detailViewPaletteColor = ['components', 'detailView', 'palette'];
const detailViewTypographyRecordButton = ['components', 'detailView', 'typography', 'recordButton'];

const AddDNRorDNAComponent = ({ handleAddClose, handleAddDNAorDNR, type }) => {
  const [selectedCorp, setSelectedCorp] = useState();
  const [selectedReason, setSelectedReason] = useState();
  const [showMustSelectAReason, setShowMustSelectAReason] = useState(false);
  const handleRadioButtonChange = (event) => {
    setShowMustSelectAReason(false);
    setSelectedReason(event.target.value);
  };

  const handleButtonClick = () => {
    if ((type === 'DNR' && !selectedCorp) || !selectedReason) {
      setShowMustSelectAReason(true);
      return;
    }
    if (type === 'DNA' && !selectedReason) {
      setShowMustSelectAReason(true);
      return;
    }
    handleAddDNAorDNR(selectedReason, selectedCorp);
  };

  return (
    <Box
      sx={{
        backgroundColor: `${theme.palette.background.dark}`,
        borderRadius: '25px',
        padding: theme.spacing(1, 0, 0, 0),
        justifyContent: 'space-between',
        margin: '5px 0',
        boxShadow: 0,
        width: '100%',
      }}
    >
      <Box
        sx={{
          color: get([...detailViewPaletteColor, 'dnrTextFieldColor'], theme),
          fontFamily: get([...detailViewTypographydnrTextField, 'fontFamily'], theme),
          display: 'flex',
          justifyContent: 'space-between',
          padding: '5px 15px',
        }}
      >
        {' '}
        <Typography>New {type} Record</Typography> <ClearIcon onClick={handleAddClose} />
      </Box>
      {type === 'DNR' && (
        <Box sx={{ padding: '0 10px', marginTop: '5px' }}>
          {' '}
          <DNRorDNAAutocomplete onChange={setSelectedCorp} />
        </Box>
      )}

      <Box sx={{ columnCount: 2, columnGap: '12px', padding: '0 16px', marginTop: '10px' }}>
        <RadioGroup name="controlled-radio-buttons-group" onChange={handleRadioButtonChange}>
          {dnrReasonOptions.map((reason) => (
            <FormControlLabel
              key={reason.id}
              value={reason.label}
              control={<BlueBackgroundRadio />}
              label={reason.label}
              sx={{
                color: get([...detailViewTypographyReasonText, 'fontColor'], theme),
                '& .MuiTypography-root': {
                  fontSize: get([...detailViewTypographyReasonText, 'fontSize'], theme),
                  fontFamily: get([...detailViewTypographyReasonText, 'fontFamily'], theme),
                },
              }}
            />
          ))}
        </RadioGroup>
      </Box>

      {showMustSelectAReason && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: theme.components.detailView.palette.trashCanColor,
            borderRadius: '20px',
            marginTop: '20px',
            alignItems: 'center',
          }}
        >
          <Box sx={{ marginLeft: '15px' }}>Please make the selection to add a record.</Box>
          <Box>
            <IconButton
              iconElement={<CloseIcon />}
              onClick={() => {
                setShowMustSelectAReason(false);
              }}
              size="small"
            />
          </Box>
        </Box>
      )}

      {/* <Box sx={{ padding: '0 16px' }}>* Waiting for BE API/}
      {/*  <TextField */}
      {/*    placeholder="Note" */}
      {/*    variant="outlined" */}
      {/*    inputProps={{ style: { color: 'white' } }} */}
      {/*    size="small" */}
      {/*    sx={{ */}
      {/*      width: '100%', */}
      {/*      height: 40, */}
      {/*      display: 'flex', */}
      {/*      color: 'white', */}
      {/*      backgroundColor: theme.textField.darkBkColor, */}
      {/*      borderRadius: '40px', */}
      {/*      fontFamily: 'Barlow-500', */}
      {/*      fontSize: '16px', */}
      {/*      '& .Mui-focused': { */}
      {/*        borderRadius: '40px', */}
      {/*      }, */}
      {/*    }} */}
      {/*  /> */}
      {/* </Box> */}

      <Box
        sx={{
          marginTop: '10px',
        }}
      >
        <Button
          onClick={handleButtonClick}
          sx={{
            fontFamily: get([...detailViewTypographyRecordButton, 'fontFamily'], theme),
            fontSize: get([...detailViewTypographyRecordButton, 'fontSize'], theme),
            backgroundImage: `linear-gradient(to right, ${theme.button.palette.primary}, ${theme.button.palette.secondary})`,
            backgroundColor: `${theme.button.palette.primary}`,
            color: get([...detailViewPaletteColor, 'recordButtonColor'], theme),
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(1.25, 0),
            cursor: 'pointer',
            borderRadius: '0 0 25px 25px',
          }}
        >
          Add {type} Record
        </Button>
      </Box>
    </Box>
  );
};

AddDNRorDNAComponent.propTypes = {
  handleAddClose: PropTypes.func,
  handleAddDNAorDNR: PropTypes.func,
  type: PropTypes.string,
};
export default AddDNRorDNAComponent;
