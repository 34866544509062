import get from 'lodash/fp/get';

import theme from '../../theme/theme';

export const USER_STATUSES = {
  ACTIVE: 'active',
  DNA: 'dna',
  DNR: 'dnr',
  INACTIVE: 'inactive',
  LOCKED: 'locked',
  UNVERIFIED: 'unverified',
  DEACTIVATED: 'deactivated',
  ONBOARDING_INCOMPLETE: 'onboardingIncomplete',
};

export const handleLabelProps = (status) => {
  const MESSAGES = {
    ACTIVE: 'user.status.active',
    DNA: 'user.status.dna',
    DNR: 'user.status.dnr',
    INACTIVE: 'user.status.inactive',
    LOCKED: 'user.status.locked',
    UNVERIFIED: 'user.status.unverified',
    DEACTIVATED: 'user.status.deactivated',
    ONBOARDING_INCOMPLETE: 'user.status.onboardingIncomplete',
  };
  let backgroundColor;
  let color;
  let text;

  switch (status) {
    case USER_STATUSES.ACTIVE:
      backgroundColor = get(['label', 'users', 'bgColor', 'active'], theme);
      color = get(['label', 'users', 'color', 'active'], theme);
      text = MESSAGES.ACTIVE;
      break;
    case USER_STATUSES.UNVERIFIED:
      backgroundColor = get(['label', 'users', 'bgColor', 'unverified'], theme);
      color = get(['label', 'users', 'color', 'unverified'], theme);
      text = MESSAGES.UNVERIFIED;
      break;

    case USER_STATUSES.INACTIVE:
      backgroundColor = get(['label', 'users', 'bgColor', 'inactive'], theme);
      color = get(['label', 'users', 'color', 'inactive'], theme);
      text = MESSAGES.INACTIVE;
      break;

    case USER_STATUSES.LOCKED:
      backgroundColor = get(['label', 'users', 'bgColor', 'locked'], theme);
      color = get(['label', 'users', 'color', 'locked'], theme);
      text = MESSAGES.LOCKED;
      break;
    case USER_STATUSES.DNA:
      backgroundColor = get(['label', 'users', 'bgColor', 'dna'], theme);
      color = get(['label', 'users', 'color', 'dna'], theme);
      text = MESSAGES.DNA;
      break;
    case USER_STATUSES.DNR:
      backgroundColor = get(['label', 'users', 'bgColor', 'dnr'], theme);
      color = get(['label', 'users', 'color', 'dnr'], theme);
      text = MESSAGES.DNR;
      break;
    case USER_STATUSES.DEACTIVATED:
      backgroundColor = get(['label', 'users', 'bgColor', 'dnr'], theme);
      color = get(['label', 'users', 'color', 'dnr'], theme);
      text = MESSAGES.DEACTIVATED;
      break;
    case USER_STATUSES.ONBOARDING_INCOMPLETE:
      backgroundColor = get(['label', 'users', 'bgColor', 'dnr'], theme);
      color = get(['label', 'users', 'color', 'dnr'], theme);
      text = MESSAGES.ONBOARDING_INCOMPLETE;
      break;

    default:
      throw new Error('Valid status option required');
  }

  return {
    text,
    sx: {
      backgroundColor,
      color,
    },
    sxForText: {
      fontFamily: get(['label', 'users', 'font'], theme),
    },
  };
};
