import { gridClasses } from '@mui/x-data-grid-pro';

import theme from '../../../theme';

const dataGridStyles = {
  border: '0',
  fontFamily: theme.dataGrid.default.textFont,
  color: theme.dataGrid.default.textColor,

  [`& .${gridClasses.row}`]: {
    '&.Mui-selected': {
      backgroundColor: theme.dataGrid.bgColor.selectedRowBgColor,
      '&:hover, &.Mui-hovered': {
        backgroundColor: theme.dataGrid.bgColor.selectedRowBgColor,
      },
    },
  },

  [`& .${gridClasses.columnHeaders}`]: {
    borderBottom: '0',
    borderTop: '0',

    backgroundColor: theme.dataGrid.bgColor.columnHeaderBgColor,
    '&:focus-within': {
      outline: 'none',
    },
  },

  [`& .${gridClasses.main}`]: {
    border: `0`,
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
  },
};

export default dataGridStyles;
