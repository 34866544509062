import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import asyncService from '../../../../datahub/asyncService';
import selectUser from '../../../store/selectors/appSelector';
import theme from '../../../theme';
import { WaeButton } from '../../Button/Button';
import CandidatePickerCard from '../../CandidatPickerCard/CandidatePickerCard';
import { getUniqueFiltersV2 } from '../../Filters/helpers';
import { errorHandler } from '../formUtils';

import EmployeeInviteRadioComponent from './EmployeeInviteRadioComponent';
import EmployeeInviteSelectionFilter from './EmployeeInviteSelectionFilter';
import { generateCandidateSelectionListOnUserRead } from './helpers';
import {
  buttonStyles,
  errorTextStyling,
  rootGridWidthAndHeightSx,
  rosterFillSubTitleStyling,
  scrollBarStyles,
  selectionBoxStyling,
  selectionTextStyling,
  selectionTitleTextStyling,
} from './styling';

const FormEmployeeInviteSelection = ({
  fieldErrorData,
  input,
  isInviteOpen,
  setIsInviteOpen,
  meta,
  required,
}) => {
  const intl = useIntl();
  const [candidatesLists, setCandidatesList] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);

  const [invitedWorkerAmount, setInvitedWorkerAmount] = useState(0);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const token = user?.token;
  const refreshToken = user?.refreshToken;
  const { onChange } = input;
  const largerThanMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const error = errorHandler(input, meta, fieldErrorData);

  const shouldShowError = error && (meta.submitFailed || meta.modified || meta.touched);

  // Handles candidate cards click
  const handleCardOnClick = useCallback(
    (candidate) => {
      if (!selectedCandidates.some((val) => val._id === candidate._id)) {
        setSelectedCandidates([...selectedCandidates, candidate]);
      } else if (selectedCandidates.some((val) => val._id === candidate._id)) {
        setSelectedCandidates(selectedCandidates.filter((val) => val._id !== candidate._id));
      } else {
        setSelectedCandidates([candidate]);
      }
    },
    [selectedCandidates]
  );

  // handles filter on change
  const handleOnRightFiltersChange = useCallback((newFilters) => {
    setSearchFilters((prevVal) => ({
      ...prevVal,
      page: 0,
      rightFilters: getUniqueFiltersV2(prevVal.rightFilters, newFilters),
    }));
  }, []);

  // handle add invite button onclick
  const handleAddInviteOnClick = () => {
    onChange(selectedCandidates.map((candidate) => ({ id: candidate._id, name: candidate.name })));
    setInvitedWorkerAmount(selectedCandidates.length);
    setSearchFilters([]);
    setIsInviteOpen(false);
  };

  // handles WAE AutoFill radio button OnClick
  const handleWAEAutoFillOnClick = () => {
    onChange([]);
    setIsInviteOpen(false);
  };

  // handles invite workers radio button onclick
  const handleInviteWorkersRadioOnClick = () => {
    setIsInviteOpen(true);
  };

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      const candidateRoleFilter = {
        field: 'role',
        operation: 'equals',
        value: UserRole.CANDIDATE,
      };

      const searchData = {
        filters: [candidateRoleFilter, ...(searchFilters.rightFilters || [])], // candidate filter + any custom filters
        limit: 10,
        sortBy: [
          {
            field: 'name',
            descending: false,
          },
        ],
      };
      setCandidatesList([]);
      asyncService({
        httpMethod: 'POST',
        route: 'users/read',
        data: searchData,
        onSuccess: (d) => {
          const rawApiQueryResult = d?.data?.documents;
          const apiResultDocuments = rawApiQueryResult;

          setCandidatesList(apiResultDocuments);
        },
        onError: () => {
          setCandidatesList([]);
        },
        dispatch,
        token,
        refreshToken,
      });
    }, 750);

    return () => clearTimeout(delayedSearch);
  }, [dispatch, token, refreshToken, searchFilters]);

  return (
    <Grid
      container
      direction="column"
      sx={rootGridWidthAndHeightSx(largerThanMediumScreen)}
      onBlur={input.onBlur}
    >
      <Typography sx={selectionTitleTextStyling}>{`${intl.formatMessage({
        id: 'job.create.rosterFillTitle',
      })}`}</Typography>
      <Grid container item direction="row" sx={{ margin: theme.spacing(0, 0, 1.5, 0) }}>
        <Typography sx={rosterFillSubTitleStyling}>{`${intl.formatMessage({
          id: 'job.create.rosterFillSubTitleOne',
        })}`}</Typography>
        <Typography
          sx={{
            ...rosterFillSubTitleStyling,
            textDecoration: 'underline',
            margin: theme.spacing(0, 0, 0, 0.5),
          }}
        >{`${intl.formatMessage({ id: 'job.create.rosterFillSubTitleTwo' })}`}</Typography>
      </Grid>
      {EmployeeInviteRadioComponent({
        handleWAEAutoFillOnClick,
        handleInviteWorkersRadioOnClick,
        required,
        isInviteOpen,
        invitedWorkerAmount,
      })}

      <FormHelperText sx={errorTextStyling}>
        {shouldShowError && !isInviteOpen ? intl.formatMessage({ id: error }) : null}
      </FormHelperText>
      {isInviteOpen && (
        <Grid sx={{ margin: theme.spacing(1, 0, 0, 0) }}>
          <Typography sx={selectionTitleTextStyling}>{`
          ${intl.formatMessage({ id: 'job.create.invitesBoxTitle' })}`}</Typography>
          <EmployeeInviteSelectionFilter onRightFilterChange={handleOnRightFiltersChange} />
          <Box
            sx={{
              ...selectionBoxStyling,
              ...scrollBarStyles,
              ...rootGridWidthAndHeightSx(largerThanMediumScreen),
            }}
          >
            <Grid
              container
              item
              direction="row"
              sx={{
                justifyContent: !largerThanMediumScreen && 'center',
              }}
            >
              {selectedCandidates.map((candidate) => (
                <CandidatePickerCard
                  isSelected
                  user={{
                    name: candidate?.name,
                    favorite: candidate?.favorite,
                    _id: candidate?._id || candidate?.id,
                    reliabilityScore: candidate?.reliabilityScore,
                  }}
                  sx={{
                    margin: theme.spacing(0, 0, 1, 1.25),
                  }}
                  onClick={handleCardOnClick}
                  key={candidate?._id || candidate?.id}
                />
              ))}
              {generateCandidateSelectionListOnUserRead(selectedCandidates, candidatesLists).map(
                (candidate) => (
                  <CandidatePickerCard
                    user={{
                      name: candidate?.name,
                      favorite: candidate?.favorite,
                      _id: candidate?._id || candidate?.id,
                      reliabilityScore:
                        (candidate?.candidate?.metrics?.reliabilityScore &&
                          `${Math.round(candidate.candidate.metrics.reliabilityScore * 100)}%`) ||
                        `0%`,
                    }}
                    sx={{
                      margin: theme.spacing(0, 0, 1, 1.25),
                    }}
                    onClick={handleCardOnClick}
                    key={candidate?._id || candidate?.id}
                  />
                )
              )}
            </Grid>
            {/* )} */}
          </Box>
          <Grid
            container
            item
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
            direction="column"
          >
            <Typography sx={selectionTextStyling}>{`${
              selectedCandidates.length
            }  ${intl.formatMessage({ id: 'job.create.invitesEmployeeSelected' })}`}</Typography>
            <WaeButton
              sx={buttonStyles}
              onClick={handleAddInviteOnClick}
              text={intl.formatMessage({ id: 'job.create.addInviteButton' })}
            />
          </Grid>
        </Grid>
      )}
      <FormHelperText sx={errorTextStyling}>
        {shouldShowError && isInviteOpen ? intl.formatMessage({ id: error }) : null}
      </FormHelperText>
    </Grid>
  );
};

FormEmployeeInviteSelection.propTypes = {
  fieldErrorData: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onBlur: PropTypes.func,
  }),
  isInviteOpen: PropTypes.bool,
  setIsInviteOpen: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    modified: PropTypes.bool,
    submitFailed: PropTypes.bool,
    submitting: PropTypes.bool,
  }),
  required: PropTypes.bool,
};

export default FormEmployeeInviteSelection;
