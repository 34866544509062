import theme from '../../../theme';

export const PaperPropStyling = {
  border: `2px solid ${theme.components.profileMenu.palette.border}`,
  borderRadius: '20px',
  mt: 1.5,
  overflow: 'visible',
  '& .MuiAvatar-root': {
    width: 45,
    height: 45,
    ml: -0.5,
    mr: 1,
  },
  '& .MuiList-root': {
    padding: '0',
    '& li': {
      padding: theme.spacing(1.5, 2, 1.5, 2),
    },
    '& li:first-of-type': {
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
    '& li:last-child': {
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
    },
  },
};

export const selectionStyling = {
  fontFamily: `${theme.components.profileMenu.font.selection.fontFamily}`,
  fontSize: `${theme.components.profileMenu.font.selection.fontSize}`,
  '&:hover': { backgroundColor: `${theme.components.profileMenu.palette.hover}` },
};

export const emailStyling = {
  fontFamily: `${theme.components.profileMenu.font.email.fontFamily}`,
  fontSize: `${theme.components.profileMenu.font.email.fontSize}`,
};

export const roleStyling = {
  fontFamily: `${theme.components.profileMenu.font.role.fontFamily}`,
  fontSize: `${theme.components.profileMenu.font.role.fontSize}`,
};
