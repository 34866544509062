import React, { useState } from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, CardMedia } from '@mui/material';
import Modal from '@mui/material/Modal';

import { updateTraining } from '../../../views/app';
import Button, { BUTTON_VARIANT } from '../../Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '80vh',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
};

export const TrainingPage = ({ image, ContentType, contentProps, buttonData }) => (
  <Box sx={style}>
    <CardMedia component="img" image={image} sx={{ maxHeight: '30vh' }} />
    <Box sx={{ padding: '20px' }}>
      <ContentType contentProps={contentProps} />
    </Box>

    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
      {buttonData.map((button) => button) || null}
    </Box>
  </Box>
);

TrainingPage.propTypes = {
  ContentType: PropTypes.func,
  contentProps: PropTypes.shape({
    title: PropTypes.string,
  }),
  image: PropTypes.string,
  buttonData: PropTypes.arrayOf(PropTypes.element),
};

const TrainingModule = ({ pages, trainingName, user }) => {
  const dispatch = useDispatch();
  const [trainingStep, setTrainingStep] = useState(0);

  const handleNext = () => {
    if (trainingStep < pages.length - 1) {
      setTrainingStep(trainingStep + 1);
    }
  };

  const handleGoBack = () => {
    if (trainingStep === 0) {
      return;
    }
    setTrainingStep(trainingStep - 1);
  };

  const handleSubmit = () => {
    const userID = get(['role'], user) === UserRole.ADMIN ? { userID: get(['id'], user) } : {};
    dispatch(
      updateTraining({
        ...userID,
        trainingName,
      })
    );
  };

  const backButton = (
    <div key="back">
      <Button onClick={() => handleGoBack(trainingStep)} variant={BUTTON_VARIANT.OUTLINED}>
        {LanguageConverter('training.button.back')}
      </Button>
    </div>
  );

  const finishButton = (
    <div key="finish">
      <Button
        onClick={handleSubmit}
        sx={{ marginLeft: '5px' }}
        text={LanguageConverter('training.button.finish')}
      />
    </div>
  );

  const nextButton = (
    <div key="next">
      <Button
        onClick={() => handleNext(trainingStep)}
        sx={{
          marginLeft: '5px',
        }}
        text={LanguageConverter('training.button.next')}
      />
    </div>
  );

  const trainingPagesData = pages.map((pageData, index) => {
    const PageButtons = (pageIndex) => [
      (pageIndex === 0 && <Box key={pageIndex} />) || backButton,
      (pageIndex === pages.length - 1 && finishButton) || nextButton,
    ];

    return {
      pageData,
      buttonData: PageButtons(index),
    };
  });

  const trainingPageData = trainingPagesData[trainingStep];

  return (
    <Modal open aria-label="training-modal-title" aria-describedby="modal-modal-description">
      <div>
        <TrainingPage
          image={trainingPageData?.pageData?.image}
          ContentType={trainingPageData?.pageData?.ContentType}
          contentProps={trainingPageData?.pageData?.contentProps}
          buttonData={trainingPageData?.buttonData}
        />
      </div>
    </Modal>
  );
};

export default TrainingModule;

TrainingModule.propTypes = {
  trainingName: PropTypes.string,
  user: PropTypes.shape({}),
  pages: PropTypes.arrayOf(PropTypes.shape({})),
};
