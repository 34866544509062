export const CANDIDATE_JOB_LIST_REQ_PARAMS = {
  page: 0,
  limit: 10,
  sortBy: [{ field: 'start', descending: true }],
};

export const INDUSTRY_OPTIONS = [
  { name: 'healthcare', value: 'healthcare' },
  { name: 'hospitality', value: 'hospitality' },
  { name: 'manufacturing', value: 'manufacturing' },
];

export const DISTANCE_OPTIONS = [
  { id: '5 Miles', name: '5 Miles', value: 5 },
  { id: '10 Miles', name: '10 Miles', value: 10 },
  { id: '15 Miles', name: '15 Miles', value: 15 },
  { id: '20 Miles', name: '20 Miles', value: 20 },
  { id: '25+ Miles', name: '25+ Miles', value: 25 },
];

export const JOB_TYPE_OPTIONS = [
  { id: 'day', name: 'Single Day Jobs', value: 'day', jobType: 'day' },
  { id: 'long', name: 'Long Term commitments', value: 'long', jobType: 'long' },
];
