import React from 'react';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import theme from '../../../../theme';
import { isShiftStarted } from '../../../../utils';
import { TIMEKEEPING_STATUSES } from '../../../../views/jobOrders/jobOrderConstants';
import WaeButton, { BUTTON_VARIANT } from '../../../Button';

const showTimecardEditButton = (onClick, shiftStart, status, userRole) => {
  const isAdmin = userRole === UserRole.ADMIN;
  const isEmployer = userRole === UserRole.EMPLOYER;
  const cardTheme = theme.jobOrders.detail.roster.employeeRosterCard;
  const editButtonStyles = {
    borderRadius: '5%',
    height: '25px',
    width: '100px',
  };

  const EditButton = () => (
    <WaeButton
      dataTestId="timecard-edit-btn"
      actionColor={cardTheme.actionButton.actionColor}
      variant={BUTTON_VARIANT.OUTLINED}
      sx={editButtonStyles}
      borderColor={cardTheme.actionButton.borderColor}
      hoverColor={cardTheme.actionButton.hoverColor}
      onClick={onClick}
    >
      {LanguageConverter('buttonText.edit.time')}
    </WaeButton>
  );

  if (
    (isAdmin || isEmployer) &&
    isShiftStarted(shiftStart) &&
    (status === TIMEKEEPING_STATUSES.PENDING || status === TIMEKEEPING_STATUSES.APPROVED)
  ) {
    return <EditButton />;
  }
  if (
    isAdmin &&
    isShiftStarted(shiftStart) &&
    (status === TIMEKEEPING_STATUSES.RESOLVED || status === TIMEKEEPING_STATUSES.DISPUTED)
  ) {
    return <EditButton />;
  }
  return null;
};

export default showTimecardEditButton;
