import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import theme from '../../theme';
import { PRIMARY_FONT } from '../../theme/fontConstants';

import { errorHandler } from './formUtils';

const FormPasswordField = ({
  fieldErrorData,
  input,
  label,
  meta,
  mode,
  placeholder,
  required,
  sx,
  type,
  variant,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const error = errorHandler(input, meta, fieldErrorData);

  let textFieldBackground;
  let textFieldBackgroundHover;
  let textFieldFocusedBorder;
  let textFieldErrorBorder;
  let textFieldPrimaryLabel;
  const MODE_KEYS = {
    DARK: 'dark',
    EXTRADARK: 'extraDark',
    LIGHT: 'light', // Same as default
  };

  switch (mode) {
    case MODE_KEYS.DARK:
      textFieldBackground = theme.textField.darkBkColor;
      textFieldBackgroundHover = theme.palette.secondary.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.light;
      break;
    case MODE_KEYS.EXTRADARK:
      textFieldBackground = theme.textField.darkBkColor;
      textFieldBackgroundHover = theme.palette.secondary.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.light;
      break;
    default: // By default, use light
      textFieldBackground = theme.textField.inputLabel.primary.light;
      textFieldBackgroundHover = theme.textField.background.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.focused;
      break;
  }

  // To offset an end adormenent whilst keeping the input the full width so the autocomplete color works
  // Follow these steps and do this math:
  // Determine your offset from the right in a %
  // The width for the wrapper is 100% + your offset %
  // To calculate the width % of the input: 100 - ( offset / (offset + 100) )
  // Set width of the end icon to 0 and apply display inlineBlock to icon and input

  const styleForPasswordField = {
    display: 'inlineBlock',
    width: '80%', // See notes above
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiOutlinedInput-root': {
      backgroundColor: textFieldBackground,
      borderRadius: '40px',
      fontFamily: PRIMARY_FONT[400],
      '&:hover': {
        backgroundColor: textFieldBackgroundHover,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      outline: `1px solid ${textFieldFocusedBorder}`,
      backgroundColor: textFieldBackground,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      outline: `1px solid ${textFieldErrorBorder}`,
    },
    '& .MuiInputLabel-root': {
      top: '-8px',
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 7, 1, 2),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root.Mui-focused': {
      fontWeight: 500,
    },
    ...sx,
  };

  return (
    <div style={{ height: '100%', width: '125%' }}>
      <TextField
        error={!!error}
        helperText={error || null}
        label={label}
        aria-label={input.name}
        inputProps={{ 'data-testid': input.name }}
        name={input.name}
        onChange={input.onChange}
        placeholder={placeholder}
        required={required}
        sx={styleForPasswordField}
        type={!showPassword ? type : 'text'}
        variant={variant}
        value={input.value || ''}
      />
      <IconButton
        size="large"
        onClick={() => setShowPassword(!showPassword)}
        sx={{
          display: 'inlineBlock',
          position: 'relative',
          right: '10%',
          width: 0,
          height: '39px',
        }}
      >
        {showPassword ? (
          <VisibilityOff
            sx={{
              color: `${theme.button.palette.secondary}`,
            }}
          />
        ) : (
          <Visibility
            sx={{
              color: `${theme.button.palette.secondary}`,
            }}
          />
        )}
      </IconButton>
    </div>
  );
};

FormPasswordField.propTypes = {
  input: PropTypes.shape(),
  label: PropTypes.string,
  meta: PropTypes.shape(),
  mode: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.shape({}),
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  fieldErrorData: PropTypes.string,
};

export default FormPasswordField;
