import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Grid from '@mui/material/Grid';
import SnackbarBase from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import selectSnackbarData from '../../store/selectors/snackbarSelector';
import { hideSnackbar } from '../../views/app/reducer';

import snackBarTypes from './constants';
import snackBarIcons from './snackbarIcons';
import { backGroundStyling, textStyling } from './styling';

const Snackbar = () => {
  const dispatch = useDispatch();
  const snackbarData = useSelector(selectSnackbarData);
  const { message, isOpen, type = snackBarTypes.NEUTRAL } = snackbarData;

  return (
    <Grid>
      {message && (
        <SnackbarBase
          data-cy="snack_bar"
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          message={LanguageConverter(message)}
          open={isOpen}
          onClose={() => dispatch(hideSnackbar())}
          sx={{
            '&.MuiSnackbar-root': { width: '100%', top: '55px' },
          }}
        >
          <Grid container sx={backGroundStyling}>
            {snackBarIcons[type]}
            <Typography sx={textStyling}> {LanguageConverter(message)} </Typography>
          </Grid>
        </SnackbarBase>
      )}
    </Grid>
  );
};

export default Snackbar;
