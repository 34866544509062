import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import CheckMark from '../../../assets/CheckMark.svg';
import XMark from '../../../assets/XMark.svg';
import theme from '../../../theme';

const CheckedIn = ({ isChecked }) => {
  const checkedInStyles = {
    color: theme?.views?.checkedIn?.typography?.checkedInText?.fontColor,
    fontSize: theme?.views?.checkedIn?.typography?.checkedInText?.fontSize,
    fontFamily: theme?.views?.checkedIn?.typography?.checkedInText?.fontFamily,
    marginLeft: '5px',
  };
  const containerSx = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  };

  const intl = useIntl();
  return isChecked ? (
    <Box sx={containerSx}>
      <Box component="img" alt="check" src={CheckMark} />
      <Box sx={checkedInStyles}>{intl.formatMessage({ id: 'timecard.overview.checkIn' })}</Box>
    </Box>
  ) : (
    <Box sx={containerSx}>
      <Box component="img" alt="check" src={XMark} />
      <Box sx={checkedInStyles}>{intl.formatMessage({ id: 'timecard.overview.NotCheckIn' })}</Box>
    </Box>
  );
};

CheckedIn.propTypes = {
  isChecked: PropTypes.bool,
};

export default CheckedIn;
