import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import searchParamOptions from '@careerstart/wae-common/src/main/constants/searchParams';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import AppTraining from '../../../main-deprecated/components/AppTraining';
import selectUser, {
  selectIsProcessingAppTraining,
} from '../../../main-deprecated/store/selectors/appSelector';
import launchDarklyToggles from '../../../main-deprecated/utils/launchDarklyToggles';
import { history } from '../../../main-deprecated/utils/navigator';
import { updateUserData } from '../../../main-deprecated/views/app';
import MobileDirect from '../../../main-deprecated/views/Candidate/mobileDirect';
import CorporationList from '../../../main-deprecated/views/corporations/corporationList/CorporationList';
import CreateCorporation from '../../../main-deprecated/views/corporations/createCorporation/CreateCorporation';
import ForgotMyPassword from '../../../main-deprecated/views/forgotMyPassword';
import Login from '../../../main-deprecated/views/login';
import MaintenancePage from '../../../main-deprecated/views/maintenance';
import PasswordVerificationOrResetPage from '../../../main-deprecated/views/PasswordVerificationOrReset/PasswordVerificationOrResetPage';
import PositionTemplateList from '../../../main-deprecated/views/positionTemplate/positionTemplateList/PositionTemplateList';
import Register from '../../../main-deprecated/views/register/Register';
import Settings from '../../../main-deprecated/views/settings';
import SkillsAndCerts from '../../../main-deprecated/views/skillsAndCerts';
import TimekeepingGridView from '../../../main-deprecated/views/timekeeping/timekeepingGridView/TimekeepingGridView';
import UserList from '../../../main-deprecated/views/users/userList/UserList';
import Snackbar from '../../components/Snackbar';
import { getItemFromLocalStorage, LOCAL_STORAGE_KEYS } from '../../store/utils';
import { setLanguage } from '../../utils/internationalization';
import Dashboard from '../dashboard/Dashboard';
import JobOrderDetail from '../jobOrders/jobOrderDetail';
import JobOrders from '../jobOrders/JobOrders';
import JobOrdersCreate from '../jobOrdersCreate/JobOrdersCreate';
import Reporting from '../reporting/Reporting';

import Navigation from './Navigation';

const AppRouter = ({ flags }) => {
  const [dictData, setDictData] = useState({});
  const user = useSelector(selectUser);
  const isProcessingAppTraining = useSelector(selectIsProcessingAppTraining);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      const userData = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.userDataKey);

      if (userData) {
        dispatch(updateUserData(userData));
        // TODO: (TICKET PENDING) Longer term, we'll want just the token and we'll do a lookup to get the
        // rest of the user data if it is a valid token. That way, either the token is invalid and
        // user is forced to reauth, or the token is valid and we get fresh data for the user.
      }
    }
  }, [dispatch, user]);

  const maintenance = launchDarklyToggles(flags, 'isMaintenanceModeEnabled');
  const timesheetToggle = launchDarklyToggles(flags, 'isTimesheetEnabled');
  const isAdminMetricsPageEnabled = launchDarklyToggles(flags, 'isAdminMetricsPageEnabled');
  const isEnterpriseDashboardEnabled = launchDarklyToggles(flags, 'isEnterpriseDashboardEnabled');
  const token = get('token', user);
  const role = token && get('role', user);
  const { ADMIN, EMPLOYER, RECRUITER, CANDIDATE } = UserRole;
  const isAdmin = role === ADMIN;
  const isEmployer = role === EMPLOYER;
  const isRecruiter = role === RECRUITER;
  const isEmployee = role === CANDIDATE;

  // Set the language in the internationalization file
  useEffect(() => {
    setLanguage(setDictData);
  }, []);

  if (Object.keys(dictData).length !== 0) {
    return (
      <IntlProvider messages={dictData} locale="en" defaultLocale="en">
        <HistoryRouter history={history}>
          <Snackbar />
          {token && <AppTraining user={user} loading={isProcessingAppTraining} />}
          {maintenance ? (
            <MaintenancePage />
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route
                path="accountverification/:queryinformation"
                element={<PasswordVerificationOrResetPage type="accountVerification" />}
              />
              <Route
                path="passwordReset/:queryinformation"
                element={<PasswordVerificationOrResetPage type="passwordReset" />}
              />
              <Route path="forgotMyPassword" element={<ForgotMyPassword />} />
              {/* Admin, Employer, Recruiter View */}
              {(isAdmin || isEmployer || isRecruiter) && (
                <Route element={<Navigation />}>
                  <Route
                    path="dashboard"
                    element={
                      isEnterpriseDashboardEnabled ? (
                        <Dashboard />
                      ) : (
                        <Navigate to="/jobs/jobsList" />
                      )
                    }
                  />
                  {!isEmployer && (
                    <Route path="corporations/corporationList" element={<CorporationList />} />
                  )}
                  {isAdmin && (
                    <Route path="corporations/createCorporation" element={<CreateCorporation />} />
                  )}

                  {isAdmin && <Route path="skillsAndCerts" element={<SkillsAndCerts />} />}
                  {isAdmin && isAdminMetricsPageEnabled && (
                    <Route path="reporting" element={<Reporting />} />
                  )}
                  {!isEmployer && <Route path="users/usersList" element={<UserList />} />}

                  <Route path="settings" element={<Settings />} />
                  <Route
                    path={`jobs/jobsList/:${searchParamOptions.JOB_ORDER}?/:${searchParamOptions.START}?/:${searchParamOptions.ACTIVE_TAB}?`}
                    element={<JobOrders />}
                  />
                  <Route path="jobDetail/:id" element={<JobOrderDetail />} />
                  <Route path="jobs/longTermJobsList" element={<JobOrders />} />
                  <Route path="jobs/create" element={<JobOrdersCreate />} />
                  <Route path="jobs/positionTemplateList" element={<PositionTemplateList />} />
                  {timesheetToggle && (
                    <Route
                      path={`timekeeping/:${searchParamOptions.START}?/:${searchParamOptions.CANDIDATE}?/:${searchParamOptions.PLACEMENT}?/:${searchParamOptions.DATE}?`}
                      element={<TimekeepingGridView />}
                    />
                  )}
                </Route>
              )}
              {isEmployee && <Route path="dashboard" element={<MobileDirect />} />}
              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </HistoryRouter>
      </IntlProvider>
    );
  }
  return null;
};

AppRouter.propTypes = {
  flags: PropTypes.shape({}),
};

export default withLDConsumer()(AppRouter);
