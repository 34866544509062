import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Line, LineChart as Chart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import theme from '../../theme/theme';

const barChartTypographyPath = ['components', 'barChart', 'typography'];

const Header = styled(Grid)(() => ({
  justifyContent: 'center',
  height: '24px',
  lineHeight: '24px',
  fontWeight: get([...barChartTypographyPath, 'header', 'fontWeight'], theme),
  fontSize: get([...barChartTypographyPath, 'header', 'fontSize'], theme),
  fontFamily: get([...barChartTypographyPath, 'header', 'fontFamily'], theme),
  fontColor: get([...barChartTypographyPath, 'header', 'fontColor'], theme),
}));

const LineChart = ({
  data = [],
  titleText,
  xAxisDataKey,
  yAxisDataKey,
  xAxisTickFontSize,
  xAxisLabel,
  yAxisLabel,
}) => (
  <>
    <Header container sx={{ height: '10', width: '100%' }}>
      {titleText}
    </Header>

    <ResponsiveContainer height="85%" width="100%">
      <Chart data={data}>
        <XAxis
          dataKey={xAxisDataKey}
          label={{ value: xAxisLabel, offset: -7, position: 'bottom' }}
          tick={{ fontSize: xAxisTickFontSize }}
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          dataKey={yAxisDataKey}
          label={{ value: yAxisLabel, angle: -90, offset: 8, position: 'insideBottomLeft' }}
          axisLine={false}
          tickCount={6}
          tickLine={false}
        />
        <Tooltip />

        <Line
          // type="monotone"
          dataKey={yAxisDataKey}
          stroke={get(['views', 'reporting', 'lineChart', 'line'], theme)}
          activeDot={{ r: 8 }}
          dot={{
            stroke: get(['views', 'reporting', 'lineChart', 'dot'], theme),
            r: 4,
            fill: get(['views', 'reporting', 'lineChart', 'dot'], theme),
          }}
        />
      </Chart>
    </ResponsiveContainer>
  </>
);
LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  titleText: PropTypes.string,
  xAxisDataKey: PropTypes.string,
  yAxisDataKey: PropTypes.string,
  xAxisTickFontSize: PropTypes.number,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

export default LineChart;
