import { createSlice } from '@reduxjs/toolkit';

const initialState = { isLoading: false };

export const registerSlice = createSlice({
  name: 'registerSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    registerCandidate: (state) => {
      state.isLoading = true;
    },
    registerCandidateProcessed: (state) => {
      state.isLoading = false;
    },
    registerCandidateError: (state) => {
      state.isLoading = false;
    },
    registerEmployer: (state) => {
      state.isLoading = true;
    },
    registerEmployerProcessed: (state) => {
      state.isLoading = false;
    },
    registerEmployerError: (state) => {
      state.isLoading = false;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const {
  registerCandidate,
  registerCandidateProcessed,
  registerCandidateError,
  registerEmployer,
  registerEmployerProcessed,
  registerEmployerError,
} = registerSlice.actions;

export const registerReducer = registerSlice.reducer;
