import React from 'react';

import {
  WAE_SUPPORTED_FILTER_FIELD_TYPES,
  WAE_SUPPORTED_STRING_FILTER_OPERATORS,
} from '@careerstart/wae-common/src/main/constants/filterQueries';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

import BlueCircleCheckIcon from '../../assets/icons/BlueCircleCheck';

const certColumnData = (delFunc) => {
  const status = {
    field: 'status',
    headerClassName: 'theme-header',
    headerName: '',
    type: WAE_SUPPORTED_FILTER_FIELD_TYPES.STRING,
    width: 5,
    renderCell: () => <BlueCircleCheckIcon />,
  };

  const name = {
    field: 'name',
    cellClassName: 'info-cell',
    headerClassName: 'theme-header',
    headerName: 'Cert List',
    flex: 1,
    filterOperators: WAE_SUPPORTED_STRING_FILTER_OPERATORS,
    isSortable: true,
  };

  const action = {
    field: 'actions',
    type: 'actions',
    headerClassName: 'theme-header',
    headerName: 'Delete',
    width: 65,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<DeleteIcon color="blue" />}
        label="Delete"
        onClick={() => {
          delFunc(params.id);
        }}
      />,
    ],
  };
  return [status, name, action];
};

export default certColumnData;
