import * as React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';

const CalendarIcon = ({ width = 16, height = 18, color = '#67676A', isEventRepeat = false }) =>
  isEventRepeat ? (
    <SvgIcon sx={{ width, height, color }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 23 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3431_20339)">
          <path
            d="M14.9674 7.93229H14.493V6.90771H13.5444V7.93229H9.74963V6.90771H8.80095V7.93229H8.32661C7.80484 7.93229 7.37793 8.39335 7.37793 8.95687V16.1289C7.37793 16.6924 7.80484 17.1535 8.32661 17.1535H12.5957C12.3111 16.8973 12.0739 16.5387 11.8842 16.1289H8.32661V11.006H14.9674V13.5333C15.2994 13.5845 15.6315 13.687 15.9161 13.8919V8.95687C15.9161 8.39335 15.4892 7.93229 14.9674 7.93229Z"
            fill={color}
          />
        </g>
        <path
          d="M19.0322 16.5051C20.1883 14.4834 20.5806 12.034 19.921 9.61228C19.2877 7.28724 17.7567 5.30899 15.6649 4.11274C13.5731 2.91648 11.0917 2.6002 8.76663 3.23348C6.44159 3.86676 4.46335 5.39772 3.26709 7.48957C2.07084 9.58141 1.75456 12.0628 2.38784 14.3878C3.50114 18.4752 7.22373 21.1222 11.27 21.0677L10.6491 18.7884C7.85112 18.5853 5.35849 16.651 4.57948 13.7909C4.10452 12.0471 4.34173 10.1861 5.23892 8.61719C6.13611 7.04831 7.6198 5.90008 9.36357 5.42513C11.1074 4.95017 12.9684 5.18738 14.5373 6.08457C16.1062 6.98176 17.2544 8.46544 17.7293 10.2092C18.2248 12.0283 17.9104 13.856 17.0383 15.3649L12.5487 12.7974L14.638 20.4682L22.3088 18.3788L19.0322 16.5051Z"
          fill={color}
        />
        <defs>
          <clipPath id="clip0_3431_20339">
            <rect
              width="9.76762"
              height="10.519"
              fill={color}
              transform="translate(6.7627 6.85645)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  ) : (
    <SvgIcon sx={{ width, height, color }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 20"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 10.9993H13.5833V15.5827H9V10.9993ZM15.4167 2.74935H14.5V0.916016H12.6667V2.74935H5.33333V0.916016H3.5V2.74935H2.58333C1.575 2.74935 0.75 3.57435 0.75 4.58268V17.416C0.75 18.4243 1.575 19.2493 2.58333 19.2493H15.4167C16.425 19.2493 17.25 18.4243 17.25 17.416V4.58268C17.25 3.57435 16.425 2.74935 15.4167 2.74935ZM15.4167 4.58268V6.41602H2.58333V4.58268H15.4167ZM2.58333 17.416V8.24935H15.4167V17.416H2.58333Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );

CalendarIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  isEventRepeat: PropTypes.bool,
};

export default CalendarIcon;
