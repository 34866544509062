import * as React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';

import Banner from '../../../components/Candidate/AlertBanner';
import theme from '../../../theme';

const alertBannerTheme = get(['candidate', 'components', 'alertBanner'], theme);

const AlertBanner = ({ messageText }) => (
  <Box sx={{ width: '100%', margin: theme.spacing(2, 0) }}>
    <Banner
      LeftIcon={WarningAmberIcon}
      CloseIcon={CloseIcon}
      message={messageText}
      backgroundColor={get(['backgroundColor'], alertBannerTheme)}
      messageSx={{
        color: get(['fontColor'], alertBannerTheme),
        fontFamily: get(['font'], alertBannerTheme),
      }}
    />
  </Box>
);

AlertBanner.propTypes = {
  messageText: PropTypes.string,
};

export default AlertBanner;
