import * as React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import { ONE_WEEK_IN_MS } from '@careerstart/wae-common/src/main/constants/timeConversions';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import BackArrow from '../../../assets/icons/BackArrow.svg';
import ForwardArrow from '../../../assets/icons/ForwardArrow.svg';
import MuiIconButton from '../../../components/IconButton/IconButton';
import theme from '../../../theme';
import { getWeekDate } from '../../../utils/timeConverter';

const weeksFilter = get(
  ['candidate', 'components', 'timeCards', 'typography', 'weeksFilter'],
  theme
);

const WeekFilter = ({ handleWeekChange, startEpoch }) => (
  <Grid container sx={{ paddingTop: '24px', paddingBottom: '8px' }}>
    <MuiIconButton
      onClick={() => handleWeekChange(startEpoch - ONE_WEEK_IN_MS)}
      sx={{ height: '32px', width: '32px' }}
      iconElement={
        <Box
          component="img"
          sx={{
            height: '24px',
            width: '24px',
          }}
          alt="Back"
          src={BackArrow}
        />
      }
    />
    <Box sx={{ display: 'flex', marginTop: '3px' }}>
      <Box sx={{ ...weeksFilter, marginLeft: '8px', marginRight: '8px' }}>{`${getWeekDate(
        startEpoch
      )} - ${getWeekDate(startEpoch, true)}`}</Box>
    </Box>

    <MuiIconButton
      onClick={() => handleWeekChange(startEpoch + ONE_WEEK_IN_MS)}
      sx={{ height: '32px', width: '32px' }}
      iconElement={
        <Box
          component="img"
          sx={{
            height: '24px',
            width: '24px',
          }}
          alt="Forward"
          src={ForwardArrow}
        />
      }
    />
  </Grid>
);

WeekFilter.propTypes = {
  handleWeekChange: PropTypes.func,
  startEpoch: PropTypes.number,
};

export default WeekFilter;
