import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Box, Grid } from '@mui/material';

import WAEMap from '../Map/WaeMap';

const FormMapWrapper = (props) => {
  const { sx, input } = props;
  const value = get('value', input);

  const center = {
    lat: value.latitude,
    lng: value.longitude,
  };

  const markers = [
    {
      position: center,
    },
  ];

  return (
    <Grid item sx={sx}>
      <Box sx={sx}>
        <WAEMap center={center} markers={markers} zoomLevel={6} />
      </Box>
    </Grid>
  );
};

export default FormMapWrapper;
FormMapWrapper.propTypes = {
  sx: PropTypes.shape({}),
  input: PropTypes.shape({}),
};
