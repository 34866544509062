import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

import theme from '../../theme';

import { errorHandler } from './formUtils';

const FormTextField = ({
  disabled,
  fieldErrorData,
  focused,
  fullWidth,
  input,
  label,
  maxRows,
  meta,
  minRows,
  multiline,
  mode,
  placeholder,
  readOnly,
  required,
  rows,
  sx,
  variant,
  testId,
}) => {
  const error = errorHandler(input, meta, fieldErrorData);

  let textFieldBackground;
  let textFieldBackgroundHover;
  let textFieldFocusedBorder;
  let textFieldErrorBorder;
  let textFieldPrimaryLabel;
  const MODE_KEYS = {
    DARK: 'dark',
    EXTRADARK: 'extraDark',
    LIGHT: 'light', // Same as default
  };

  switch (mode) {
    case MODE_KEYS.DARK:
      textFieldBackground = theme.textField.darkBkColor;
      textFieldBackgroundHover = theme.palette.secondary.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.light;
      break;
    case MODE_KEYS.EXTRADARK:
      textFieldBackground = theme.textField.darkBkColor;
      textFieldBackgroundHover = theme.palette.secondary.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.light;
      break;
    default: // By default, use light
      textFieldBackground = theme.textField.inputLabel.primary.light;
      textFieldBackgroundHover = theme.textField.background.light;
      textFieldFocusedBorder = theme.textField.borderColor.focused;
      textFieldErrorBorder = theme.textField.borderColor.error;
      textFieldPrimaryLabel = theme.textField.inputLabel.primary.focused;
      break;
  }

  const styleForTextField = {
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiOutlinedInput-root': {
      backgroundColor: textFieldBackground,
      borderRadius: '40px',
      fontFamily: 'Barlow',
      '&:hover': {
        backgroundColor: textFieldBackgroundHover,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      outline: `1px solid ${textFieldFocusedBorder}`,
      backgroundColor: textFieldBackground,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      outline: `1px solid ${textFieldErrorBorder}`,
    },
    '& .MuiInputLabel-root': {
      top: '-8px',
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 7, 1, 2),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root.Mui-focused': {
      fontWeight: 500,
    },
    ...sx,
  };
  const handleTextFieldValue = (value) => {
    if (value === 0) {
      return value;
    }
    return input.value ? input.value : '';
  };

  const styleForTextFieldForMultiline = {
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiOutlinedInput-root': {
      backgroundColor: textFieldBackground,
      borderRadius: '40px',
      fontFamily: 'Barlow',
      '&:hover': {
        backgroundColor: textFieldBackgroundHover,
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      outline: `1px solid ${textFieldFocusedBorder}`,
      backgroundColor: textFieldBackground,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      outline: `1px solid ${textFieldErrorBorder}`,
    },
    '& .MuiInputLabel-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(1, 7, 1, 2),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& .MuiFormLabel-asterisk.Mui-error': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root': {
      color: textFieldPrimaryLabel,
    },
    '& .MuiInputBase-root.Mui-focused': {
      fontWeight: 500,
    },
    '& label.Mui-focused': {
      top: theme.spacing(-1),
    },
    '& .MuiInputLabel-shrink': {
      top: theme.spacing(-1),
    },

    ...sx,
  };

  return (
    <TextField
      error={!!error}
      disabled={disabled}
      focused={focused}
      fullWidth={fullWidth}
      helperText={error || null}
      InputProps={{ readOnly, 'data-testid': testId }}
      label={label}
      maxRows={maxRows}
      minRows={minRows}
      multiline={multiline}
      name={input.name}
      onBlur={input.onBlur}
      onChange={input.onChange}
      placeholder={placeholder}
      required={required}
      rows={rows}
      value={handleTextFieldValue(input.value)}
      sx={multiline ? styleForTextFieldForMultiline : styleForTextField}
      variant={variant}
    />
  );
};
FormTextField.propTypes = {
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      // FIXME: HB-944 find better way to specify the type, in most cases TextField will be accepting different type of data
      PropTypes.string,
      PropTypes.instanceOf(Date),
      PropTypes.bool,
      PropTypes.number,
    ]),
  }),
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  maxRows: PropTypes.number,
  meta: PropTypes.shape(),
  minRows: PropTypes.number,
  multiline: PropTypes.bool,
  mode: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  rows: PropTypes.number,
  sx: PropTypes.shape({}),
  variant: PropTypes.string,
  fieldErrorData: PropTypes.string,
  testId: PropTypes.string,
};

export default FormTextField;
