import React from 'react';

import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../../theme';

const JobActivityNoDataCard = () => (
  <Paper
    variant="outlined"
    sx={{
      width: '100%',
      backgroundColor: theme.components.detailView.palette.sectionBackgroundColor,
      color: theme.components.detailView.typography.detailText.fontColor,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Typography
      sx={{
        fontSize: theme.components.detailView.typography.semiBoldText.fontSize,
        margin: '25px',
        color: theme.components.detailView.palette.sectionTextColor,
        fontFamily: theme.components.detailView.typography.semiBoldText.fontFamily,
      }}
      gutterBottom
    >
      There are no jobs yet!
    </Typography>
  </Paper>
);

export default JobActivityNoDataCard;
