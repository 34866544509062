import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const FormCloseButton = ({ onClose, message }) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme?.jobOrders?.createCard?.closeButtonTypography?.fontColor,
      }}
      onClick={() => onClose()}
    >
      <ArrowCircleLeftOutlinedIcon />
      <Typography
        sx={{
          marginLeft: theme.spacing(1),
          fontSize: theme?.jobOrders?.createCard?.closeButtonTypography?.fontSize,
          fontFamily: theme?.jobOrders?.createCard?.closeButtonTypography?.font,
          fontWeight: 700,
        }}
      >
        {intl.formatMessage({ id: message || 'button.close' })}
      </Typography>
    </Box>
  );
};

FormCloseButton.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.string,
};

export default FormCloseButton;
