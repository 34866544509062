import React, { useEffect, useState } from 'react';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import PostDeptRequestSchema from '@careerstart/wae-common/schema/corporation/department/create.post.req.json';
import getDepartmentsRequestSchema from '@careerstart/wae-common/schema/corporation/department/read.post.req.json';
import UpdateDeptRequestSchema from '@careerstart/wae-common/schema/corporation/department/update.patch.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

import editIcon from '../../../../assets/icons/edit.svg';
import trashCanIcon from '../../../../assets/icons/orangeDeleteIcon.svg';
import WaeButton from '../../../../components/Button';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { validateSchema } from '../../../../components/Form/validations';
import selectUser from '../../../../store/selectors/appSelector';
import {
  selectDepartments,
  selectIsLoadingDepartments,
} from '../../../../store/selectors/corporationListSelectors';
import theme from '../../../../theme';
import { createDepartment, deleteDepartment, editDepartment, getDepartments } from '../reducer';

import DepartmentFlyOut from './Components/DepartmentFlyOut';

const Department = ({ initialValues }) => {
  const [deleteDptModalOpen, setIsDeleteDptModalOpen] = useState(false);
  const [deleteInitialValues, setDeleteInitialValues] = useState({});
  const [isCreateNewDptModalOpen, setIsCreateNewDptModalOpen] = useState(false);
  const [isEditDptModalOpen, setIsEditDptModalOpen] = useState(false);
  const [editInitialValues, setEditInitialValues] = useState({});
  const user = useSelector(selectUser);
  const departments = useSelector(selectDepartments);
  const isLoadingDepartments = useSelector(selectIsLoadingDepartments);
  const intl = useIntl();
  const dispatch = useDispatch();
  const themePath = ['components', 'detailView', 'typography'];

  const corporation = initialValues;

  const sortedDepartments = sortBy('name', departments);

  const handleCreateDepartmentOnSubmit = (values) => {
    const reconstructedValues = {
      corporation: get('id', corporation),
      department: values,
    };
    if (get('FORM_ERROR', validateSchema(reconstructedValues, PostDeptRequestSchema))) {
      return;
    }

    dispatch(createDepartment(reconstructedValues));
  };

  const handleOnEdit = (department) => {
    setEditInitialValues(department);
    setIsEditDptModalOpen(true);
  };
  const handleOnDelete = (department) => {
    setDeleteInitialValues(department);
    setIsDeleteDptModalOpen(true);
  };

  const handleEditDepartmentOnSubmit = (values) => {
    const reconstructedValues = {
      id: get('_id', editInitialValues),
      fields: {
        name: get('name', values),
        description: get('description', values),
      },
    };

    if (get('FORM_ERROR', validateSchema(reconstructedValues, UpdateDeptRequestSchema))) {
      return;
    }

    dispatch(editDepartment(reconstructedValues));
  };

  const styleForCard = {
    backgroundColor: `${theme.palette.background.black}`,
    borderRadius: '25px',
    padding: theme.spacing(2),
    margin: theme.spacing(1, 0, 0, 0),
    width: '100%',
  };

  const handleDeleteOnSubmit = (dept) => {
    const requestBody = {
      corporationId: get(['id'], corporation),
      departmentId: get(['_id'], dept),
    };
    dispatch(deleteDepartment(requestBody));
    setIsDeleteDptModalOpen(false);
  };

  useEffect(() => {
    const requestBody = {
      corporation: get(['id'], corporation),
    };
    if (get('FORM_ERROR', validateSchema(requestBody, getDepartmentsRequestSchema))) {
      return;
    }
    dispatch(getDepartments(requestBody));
  }, [dispatch, corporation]);

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-start"
      sx={{ width: '100%', height: 'fit-content', maxHeight: '500px' }}
    >
      <Grid container justifyContent="space-between">
        <Typography
          sx={{
            fontFamily: get([...themePath, 'sectionHeader', 'fontFamily'], theme),
            fontSize: get([...themePath, 'sectionHeader', 'fontSize'], theme),
            color: get([...themePath, 'sectionHeader', 'fontColor'], theme),
          }}
        >
          Departments
        </Typography>
        {user.role === UserRole.ADMIN && (
          <WaeButton
            text={LanguageConverter('buttonText.newDept')}
            onClick={() => setIsCreateNewDptModalOpen(true)}
            sx={{
              marginLeft: 1,
            }}
          />
        )}
      </Grid>
      {sortedDepartments.length ? (
        departments.map((department) => (
          <Grid item sx={styleForCard} key={get(['name'], department)}>
            <Grid container justifyContent="space-between">
              <Typography
                sx={{
                  fontSize: get([...themePath, 'departmentName', 'fontSize'], theme),
                  color: get([...themePath, 'departmentName', 'fontColor'], theme),
                  fontFamily: get([...themePath, 'departmentName', 'fontFamily'], theme),
                }}
              >
                {get(['name'], department)}
              </Typography>
              <Grid>
                {user.role === UserRole.ADMIN && (
                  <>
                    <Box
                      component="img"
                      sx={{
                        height: 22,
                        width: 22,
                      }}
                      alt="edit"
                      onClick={() => handleOnEdit(department)}
                      src={editIcon}
                    />
                    <Box
                      component="img"
                      sx={{
                        height: 22,
                        width: 22,
                        marginLeft: '8px',
                      }}
                      alt="delete"
                      onClick={() => handleOnDelete(department)}
                      src={trashCanIcon}
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Grid>
              <Typography
                sx={{
                  fontSize: get([...themePath, 'departmentDesc', 'fontSize'], theme),
                  color: get([...themePath, 'departmentDesc', 'fontColor'], theme),
                  fontFamily: get([...themePath, 'departmentDesc', 'fontFamily'], theme),
                }}
              >
                {get(['description'], department)}
              </Typography>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid item sx={{ width: '100%' }}>
          <Typography
            sx={{
              fontSize: get([...themePath, 'departmentName', 'fontSize'], theme),
              color: get([...themePath, 'departmentName', 'fontColor'], theme),
              fontFamily: get([...themePath, 'departmentName', 'fontFamily'], theme),
            }}
          >
            {isLoadingDepartments
              ? `...${intl.formatMessage({ id: 'generic.loading' })}`
              : intl.formatMessage({ id: 'error.corp.department.notFound' })}
          </Typography>
        </Grid>
      )}
      {deleteDptModalOpen && (
        <ConfirmationModal
          isOpen={deleteDptModalOpen}
          body={LanguageConverter(
            'corporation.department.deleteDepartmentConfirmation',
            deleteInitialValues?.name
          )}
          onConfirm={() => handleDeleteOnSubmit(deleteInitialValues)}
          onClose={() => setIsDeleteDptModalOpen(false)}
          title={LanguageConverter('corporation.department.deleteDepartment')}
        />
      )}
      {isCreateNewDptModalOpen && (
        <DepartmentFlyOut
          isOpen={isCreateNewDptModalOpen}
          handleOnClose={() => setIsCreateNewDptModalOpen(false)}
          title="New Department"
          handleOnSubmit={handleCreateDepartmentOnSubmit}
        />
      )}
      {isEditDptModalOpen && (
        <DepartmentFlyOut
          isOpen={isEditDptModalOpen}
          handleOnClose={() => setIsEditDptModalOpen(false)}
          title="Department Management"
          initialValues={editInitialValues}
          handleOnSubmit={handleEditDepartmentOnSubmit}
        />
      )}
    </Grid>
  );
};

export default Department;
Department.propTypes = {
  initialValues: PropTypes.shape({}),
};
