import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { Box } from '@mui/material';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import FormSectionHeader from '../CustomComponents/FormSectionHeader';
import { requireApplicationsField } from '../FormFieldData/CreateJobFields';

const Applications = ({ wrapperSx }) => {
  const formState = useFormState();
  const currentFormValues = formState.values;
  return (
    <Box
      sx={{
        width: '100%',
        ...wrapperSx,
      }}
    >
      <FormSectionHeader messageKey="job.create.applications.header" />
      <Box sx={{ display: 'flex' }}>
        {buildFormField({ ...requireApplicationsField(), values: currentFormValues })}
      </Box>
    </Box>
  );
};

Applications.propTypes = {
  wrapperSx: PropTypes.shape({}),
  currentFormValues: PropTypes.shape({}),
};

export default Applications;
