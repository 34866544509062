export const BUTTON_VARIANT = {
  DEFAULT: 'default',
  DELETE: 'delete',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

export const BUTTON_TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit',
  RESET: 'reset',
};
