/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme, { dataGridFiltersHeight, listHeaderHeight, paperElevation } from '../../theme';
import TabbedPages from '../TabbedPages';

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: theme.components.detailView.palette.backgroundColor,
}));

const StyledCloseIconButton = styled(IconButton)(() => ({
  color: theme.components.detailView.palette.closeIconColor,
}));

const DetailView = ({ close, tabData, sx }) => {
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const portalledFormSubmitSection = React.useRef(null);
  return (
    (
      <Slide direction="left" in unmountOnExit timeout={200} sx={sx}>
        {/* FIXME: height needs to be changed once filter component is created and exist above the detail view */}
        <Grid
          item
          md={6}
          xs
          sx={{ height: `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight})` }}
        >
          <StyledPaper
            elevation={mediumScreen ? paperElevation : 0}
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '16px',
              height: `100%`,
              overflowY: 'scroll',
              '&::-webkit-scrollbar': { display: 'none' },
            }}
          >
            <Grid sx={{ height: `100%`, width: `100%`, position: 'relative' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: `100%`,
                  position: 'absolute',
                  top: 0,
                }}
              >
                <StyledCloseIconButton aria-label="close" onClick={close}>
                  <CloseRoundedIcon />
                </StyledCloseIconButton>
              </Box>
              <TabbedPages data={tabData(portalledFormSubmitSection)} />
              <Box ref={portalledFormSubmitSection} />
            </Grid>
          </StyledPaper>
        </Grid>
      </Slide>
    ) || null
  );
};
DetailView.propTypes = {
  close: PropTypes.func,
  tabData: PropTypes.func,
  sx: PropTypes.shape({}),
};
export default DetailView;
