import { v4 } from 'uuid';

import { apiPost, apiUrl } from './axios';
import { logOutOn401, updateUserToken } from './helpers';

const fetchQuery = async function fetchQuery({ route, data, token, httpMethod }) {
  const response = await fetch(`${apiUrl}${route}`, {
    method: httpMethod,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      correlationId: v4(),
    },
    keepalive: true,
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: JSON.stringify(data),
  });
  return response.json();
};

const asyncServiceWithFetch = ({
  httpMethod,
  route,
  data,
  token,
  onSuccess,
  onError,
  dispatch,
  refreshToken,
}) => {
  const apiQuery = (accessToken) => fetchQuery({ route, data, token: accessToken, httpMethod });

  return apiQuery(token)
    .then((webResponse) => onSuccess(webResponse))
    .catch((err) => {
      const code = err?.response?.status;

      if (code !== 401) {
        onError(err);
        return;
      }
      apiPost('users/refresh-token', { refreshToken })
        .then((refreshResponse) => {
          const newToken = refreshResponse.data.accessToken;
          updateUserToken(dispatch, newToken);

          apiQuery(newToken)
            .then((repeatWebResponse) => onSuccess(repeatWebResponse))
            .catch((repeatErr) => {
              onError(repeatErr);
            });

          return newToken;
        })
        .catch(() => {
          logOutOn401(dispatch);
        });
    });
};

export default asyncServiceWithFetch;
