import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const DragIndicatorIcon = () => (
  <SvgIcon>
    <svg width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7C0 3.13401 3.13401 0 7 0H36V24C36 29.5228 31.5228 34 26 34H0V7Z"
        fill="#F2F2F5"
      />
      <circle cx="14.75" cy="9.875" r="1.875" fill="#4D4D70" />
      <circle cx="21" cy="9.875" r="1.875" fill="#4D4D70" />
      <circle cx="14.75" cy="16.125" r="1.875" fill="#4D4D70" />
      <circle cx="21" cy="16.125" r="1.875" fill="#4D4D70" />
      <circle cx="14.75" cy="22.375" r="1.875" fill="#4D4D70" />
      <circle cx="21" cy="22.375" r="1.875" fill="#4D4D70" />
    </svg>
  </SvgIcon>
);

export default DragIndicatorIcon;
