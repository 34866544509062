import get from 'lodash/fp/get';

import { formatToFixed } from '../numberFormatHelpers';

export const calculateTotalPay = (groupPay) => {
  if (!groupPay) {
    return 'Error';
  }

  if (groupPay?.total) {
    return `$${formatToFixed(groupPay?.total, 2)}`;
  }

  let payMessage;
  const min = get('min', groupPay, 'error');
  const max = get('max', groupPay, 'error');
  if (min === 'error' || max === 'error') {
    payMessage = 'Error';
  } else if (min === max) {
    payMessage = `$${formatToFixed(max, 2)}`;
  } else {
    payMessage = `$${formatToFixed(min, 2)}-$${formatToFixed(max, 2)}`;
  }

  return payMessage;
};

export const calculateTotalJobPay = (jobInfo) => {
  if (!jobInfo) {
    return 'Error';
  }
  const { payRateReg, start, end } = jobInfo;
  const ONE_HOUR_IN_MS = 3600000;
  const differenceInHours = (end - start) / ONE_HOUR_IN_MS;
  const pay = differenceInHours * payRateReg;

  return `$${formatToFixed(pay, 2)}`;
};
