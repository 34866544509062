const JOB_TYPE = {
  THIS_SHIFT: 'ThisShift',
  ENTIRE_SERIES: 'EntireSeries',
};

export const pendingJobKeys = {
  INV: 'INV_PENDING',
  OFFER: 'OFFER_PENDING',
  APPLICATION: 'APPLICATIONS',
};

export const pendingJobStatuses = {
  [pendingJobKeys.INV]: 'jobOrder.roster.status.invitePending',
  [pendingJobKeys.OFFER]: 'jobOrder.roster.status.offerPending',
  [pendingJobKeys.APPLICATION]: 'jobOrder.roster.status.applications',
};

export const statusActionAText = {
  [pendingJobKeys.INV]: 'jobOrder.roster.status.revokeInvite',
  [pendingJobKeys.OFFER]: 'jobOrder.roster.status.revokeOffer',
  [pendingJobKeys.APPLICATION]: 'jobOrder.roster.status.accept',
};

export const statusActionBText = {
  [pendingJobKeys.INV]: 'jobOrder.roster.status.resend',
  [pendingJobKeys.OFFER]: null,
  [pendingJobKeys.APPLICATION]: 'jobOrder.roster.status.decline',
};

export default JOB_TYPE;
