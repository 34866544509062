import * as React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import theme from '../../../theme';

const typography = get(['candidate', 'components', 'timeCards', 'typography'], theme);
const palette = get(['candidate', 'components', 'timeCards', 'palette'], theme);

const TotalsCard = ({ content, label }) => (
  <Grid item xs={4}>
    <Card
      sx={{
        borderRadius: '16px',
        backgroundColor: get(['totalsCard', `backgroundColor`], palette),
      }}
    >
      <CardContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="80px"
        >
          <Typography sx={get(['totalsContent'], typography)}>{content}</Typography>
          <Typography align="center" sx={get(['totalsLabel'], typography)}>
            {label}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

TotalsCard.propTypes = {
  content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
};

export default TotalsCard;
