/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';

import LoadingCard from './LoadingCard/LoadingCard';

const LazyList = React.forwardRef(
  (
    {
      Card,
      getNextData,
      mediumScreen,
      paperElevation,
      items,
      itemCount,
      skeletonBackgroundColor,
      skeletonVariant,
      NoDataCard,
      backgroundColor,
    },
    ref
  ) => {
    return (
      <Paper
        elevation={mediumScreen ? paperElevation : 0}
        sx={{ height: '100%', width: '100%', backgroundColor }}
        ref={ref}
      >
        {items.length ? (
          <>
            {items.map((item, index) => (
              <div key={index}>
                {index + 1 === items.length && items.length < itemCount ? (
                  <>
                    <Card item={item} index={index} />

                    <LoadingCard
                      getNext={getNextData}
                      variant={skeletonVariant}
                      skeletonBackgroundColor={skeletonBackgroundColor}
                    />
                  </>
                ) : (
                  <Card item={item} index={index} />
                )}
              </div>
            ))}
          </>
        ) : (
          <NoDataCard />
        )}
      </Paper>
    );
  }
);
LazyList.propTypes = {
  backgroundColor: PropTypes.string,
  Card: PropTypes.func,
  NoDataCard: PropTypes.func,
  getNextData: PropTypes.func,
  mediumScreen: PropTypes.bool,
  paperElevation: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  itemCount: PropTypes.number,
  skeletonBackgroundColor: PropTypes.string,
  skeletonVariant: PropTypes.string,
};

export default LazyList;
