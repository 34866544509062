export const CARD_MODE = {
  active: 'active',
  notActive: 'notActive',
  missedPunch: 'missedPunch',
};

export const NEXT_SHIFT_INDEX = {
  firstShift: 0,
  secondShift: 1,
};

export const ONE_SEC_IN_MS = 1000;
export const FIFTEEN_MIN_IN_EPOCH = 900000;
export const ONE_WEEK_IN_MS = 604800;
export const SEVEN_DAYS_IN_MS = 604800000;
export const FOURTEEN_DAYS_IN_MS = 1209600000;

export const DISPUTE_STATUSES = {
  pending: 'pending',
  disputed: 'disputed',
  approved: 'approved',
  resolved: 'resolved',
  finalized: 'finalized',
};
