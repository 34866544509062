import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';

import { FEED_BACK } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';
import { JOB_ORDER_STATUSES } from '../../../../views/jobOrders/jobOrderConstants';
import {
  getFillSuccessRate,
  getNumWorkedShifts,
  limitFillRatioTo100Percent,
} from '../../helperFunctions';

const useStyles = makeStyles({
  fillRatioWrapper: { width: '250px', display: 'flex', alignItems: 'center' },
  fillRatioInner: { minWidth: 35 },
  fillRatioText: {
    fontFamily: PRIMARY_FONT[300],
    fontSize: '18px',
    lineHeight: '16px',
    textAlign: 'left',
    marginRight: '5px',
  },
  fillRatioProgressWrap: { width: '100%' },
});

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 20,
  border: '1px solid black',
  marginLeft: '5px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: FEED_BACK.GREEN_70,
  },
}));

const CompletedJobMetrics = ({ jobOrder }) => {
  const classes = useStyles();
  const numOpenings = jobOrder?.numOpenings || 0;

  const activePlacements =
    jobOrder?.placements?.filter((p) => p?.state?.status === JOB_ORDER_STATUSES.ACTIVE) || [];

  const numWorkedShifts = getNumWorkedShifts(activePlacements);

  const fillSuccessRate = limitFillRatioTo100Percent(
    getFillSuccessRate(numWorkedShifts, numOpenings)
  );

  return (
    <>
      <Box className="block">
        <Box className="title"> {LanguageConverter('jobOrder.fillSuccessRate')} </Box>
        <Box className={classes.fillRatioWrapper}>
          <Box className={classes.fillRatioInner}>
            <Typography className={classes.fillRatioText}>{`${fillSuccessRate}%`}</Typography>
          </Box>
          <Box className={classes.fillRatioProgressWrap}>
            <BorderLinearProgress variant="determinate" value={fillSuccessRate} />
          </Box>
        </Box>
      </Box>
      <Box className="block">
        <Box className="title">
          <Box className="twoColumn">
            <Box> {LanguageConverter('roster.form.openings')} </Box>
            <Box> {LanguageConverter('roster.form.placements')} </Box>
            <Box> {LanguageConverter('roster.form.worked')} </Box>
          </Box>
        </Box>
        <Box className="data">
          <Box className="twoColumn staffing">
            <Box> {jobOrder.numOpenings} </Box>
            <Box> {jobOrder.placementsCount} </Box>
            <Box>{numWorkedShifts}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

CompletedJobMetrics.propTypes = {
  jobOrder: PropTypes.shape({
    fillRatio: PropTypes.number,
    numOpenings: PropTypes.number,
    placementsCount: PropTypes.number,
    placements: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default CompletedJobMetrics;
