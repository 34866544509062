import * as React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import theme from '../../theme/theme';

const NoResultsCard = ({ bodyMsgKey, headerMsgKey, onResetFilter, resetKey }) => (
  <Card
    sx={{
      width: '100%',
      padding: theme.spacing(4),
      maxWidth: '90vw',
      backgroundColor: `${theme.emptyCard.bgColor}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: `${theme.spacing(4)} auto auto auto`,
    }}
  >
    <CardContent>
      {headerMsgKey !== null && (
        <Typography
          sx={{
            color: `${theme?.emptyCard?.header?.typography?.color}`,
            fontFamily: `${theme?.emptyCard?.header?.typography?.font}`,
            fontSize: `${theme?.emptyCard?.header?.typography?.fontSize}`,
          }}
          align="center"
        >
          {LanguageConverter(headerMsgKey || 'noResults.header')}
        </Typography>
      )}

      {bodyMsgKey !== null && (
        <Box justifyContent="center" alignItems="center" textAlign="center">
          <Typography
            align="center"
            sx={{
              color: `${theme?.emptyCard?.body?.typography?.color}`,
              fontFamily: `${theme?.emptyCard?.body?.typography?.font}`,
              fontSize: `${theme?.emptyCard?.body?.typography?.fontSize}`,
            }}
            onClick={() => onResetFilter && onResetFilter()}
          >
            {LanguageConverter(bodyMsgKey || 'noResults.body')}{' '}
            <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>
              {LanguageConverter(resetKey || 'noResults.resetFilter')}
            </span>
          </Typography>
        </Box>
      )}
    </CardContent>
  </Card>
);

NoResultsCard.propTypes = {
  bodyMsgKey: PropTypes.string,
  headerMsgKey: PropTypes.string,
  resetKey: PropTypes.string,
  onResetFilter: PropTypes.func,
};

export default NoResultsCard;
