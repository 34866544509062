import format from 'date-fns/format';
import enUsLocale from 'date-fns/locale/en-US';
import { DateTime } from 'luxon';

import { ONE_SECOND_IN_MS } from '@careerstart/wae-common/src/main/constants/timeConversions';
/**
 * In the future we may need multiple languages we can change the displayLocale variable to change the language
 * for example: change the date text to Spanish
 * import esLocale from 'date-fns/locale/es';
 * check the current language selection.
 * If the language is Spanish, set displayLocale to esLocale. The output date string will be Spanish
 */
const displayLocale = enUsLocale;

/**
 * Converts a string to a JS Date object when the string is formatted like this "August 08, 2022"
 * @param {*} stringDate A date formatted as a string in the format: "August 08, 2022"
 * @returns Date
 */
export const convertMMMMDDYYYYToDate = (stringDate) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const removedComma = stringDate.replace(',', '');
  const [month, day, year] = removedComma.split(' ');

  return Date(parseInt(year, 10), months.indexOf(month), parseInt(day, 10));
};

// returns in this format  => "Wed Jun 08 2022 13:00:24 GMT-0400 (Eastern Daylight Time)"
export const dateToEpoch = (date) => new Date(date).valueOf() / 1000; // or Math.floor(new Date(a).getTime() / 1000.0) can be used to remove 000 at the end

export const epochToTimeInReadableFormat = (epochTime) => {
  // returns format  => "01:00 PM"
  const parsedEpochTime = parseInt(epochTime, 10);

  const date = new Date(parsedEpochTime);
  // Get the local time and time, considering daylight saving time
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const epochToDateInReadableFormat = (epochTime) => {
  const parsedEpochTime = parseInt(epochTime, 10);
  // returns in this format  => "3/29/2024"
  const d = new Date(parsedEpochTime);

  // Get the local date and time, considering daylight saving time
  return d.toLocaleDateString();
};

export const epochToDate = (epoch) => new Date(epoch);

/**
 * Strips the Hours and Minutes out of a JavaScript Date object
 * and returns the total number of minutes since midnight.
 * @param {Date} time
 * @returns int
 */
export const timeToMinutesFromMidnight = (time) => time.getHours() * 60 + time.getMinutes();

/**
 *
 * @param {int} minutes
 * @returns
 */
export const minutesFromMidnightToTime = (minutes) => {
  const hours = minutes / 60;
  const flooredHours = Math.floor(minutes / 60);
  const remainderMinutes = (hours - flooredHours) * 60;
  const roundedMinutes = Math.round(remainderMinutes);

  const newDate = new Date();

  newDate.setHours(flooredHours);
  newDate.setMinutes(roundedMinutes);
  return newDate;
};

/**
 * Function that reformat javascript Date object
 * @param    {Date} date  Javascript Date Object contains date information
 * @return   {String}     More readable date string like "February 12, 2022"
 */
export const reformatDateMMMMDDYYYY = (date) => {
  try {
    return format(new Date(date), 'MMMM dd, yyyy', {
      locale: displayLocale,
    });
  } catch (e) {
    return '';
  }
}; // TODO: HB-200 i18n needed for month names above.

export const dateObjectToAPI = (date, minutes) => {
  const newObject = {
    year: date.getYear() + 1900,
    day: date.getDate(),
    month: date.getMonth() + 1,
    minutes,
  };
  return newObject;
};

/**
 * Function that converts total minutes to hour and minute format
 * @param    {int} minute  minutes from midnight
 * @return   {String}     More readable hour and minute string like "09:30 AM"
 */
export const minutesFromMidnightToTimeOnlyHrAndMins = (minutes) => {
  const hours = minutes / 60;
  const flooredHours = Math.floor(minutes / 60);
  const remainderMinutes = (hours - flooredHours) * 60;
  const roundedMinutes = Math.round(remainderMinutes);

  let newDate = new Date();

  newDate.setHours(flooredHours);
  newDate.setMinutes(roundedMinutes);

  newDate = newDate.toLocaleTimeString('en-US', {
    // en-US can be set to 'default' to use user's browser settings
    hour: '2-digit',
    minute: '2-digit',
  });
  return newDate;
};

/**
 * Function that converts hour and minute to minutes to midnight format
 * @param    {String} time  readable hour and minute string like "03:30 AM"
 * @return   {int}   '210'
 */

export const convertHrsAndMinutesToMinutesFromMidnight = (time) => {
  const timeString = time.replace('AM', '').split(':');
  const twelveHrsInMinutes = 12 * 60;
  const hourInToMinutes = parseInt(timeString[0], 10) * 60;

  if (time.includes('PM') && !time.includes('12:')) {
    // if it is pass 12PM, add 12 hours to the calculation
    return hourInToMinutes + parseInt(timeString[1], 10) + twelveHrsInMinutes;
  }

  return hourInToMinutes + parseInt(timeString[1], 10);
};

/**
 * Function that add X amount of hours to a given date. Returns a new date.
 * @param    {int} numOfHours  Integer contains number of hours to be added to date
 * @param    {Date} date Javascript Date Object contains date information
 * @return   {Date} New Date Object with updated hour
 */
export const addHours = (numOfHours, date) => {
  const newDate = new Date(date);
  newDate.setTime(newDate.getTime() + numOfHours * 60 * 60 * 1000);

  return newDate;
};
/**
 * Function that determine if the given date is today.
 */
export const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const epochToDayOfWeek = (epoch) => {
  const dateObject = new Date(epoch);
  const dayIndex = dateObject.getDay();
  return weekDays[dayIndex];
};

export const currWeekDayDate = (dayNumber) => {
  const dt = new Date();
  const diff = dt.getDate() - dt.getDay();
  const date = new Date(dt.setDate(diff + dayNumber));
  return date.toLocaleDateString('en-US');
};

/**
 *
 * @param {Date} d
 * @returns epoch
 */
export const getSunday = (d) => {
  const day = d.getDay();
  const diff = d.getDate() - day;
  return dateToEpoch(new Date(d.setDate(diff)).setHours(0, 0, 0, 0)) * ONE_SECOND_IN_MS;
};

export const getNextMonday = (d) => {
  const day = d.getDay();
  let dayOffset = 1 - day;
  if (dayOffset <= 0) {
    dayOffset += 7;
  }
  return dateToEpoch(new Date(d.getTime() + dayOffset * 24 * 3600 * 1000).setHours(0, 0, 0, 0));
};

export const convertDateToMMDDYYYYformat = (value) => {
  const date = new Date(Math.round(Number(value)));
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const formatDate = `${month}/${day}/${date.getFullYear()}`;

  return formatDate;
};

/**
 * Function returns num of days hours and mins between given two epoch time.
 * End time should be later than start
 * ReturnFormat: 10d 13hr 44min
 */
export const getDiffBetweenTwoEpoch = (startEpochTime, endEpochTime) => {
  const isEndTimeLaterThanStart = endEpochTime >= startEpochTime;
  const diffInEpoch = isEndTimeLaterThanStart
    ? endEpochTime - startEpochTime
    : startEpochTime - endEpochTime;

  if (Number.isNaN(diffInEpoch) || typeof diffInEpoch === 'string') {
    return `Err`;
  }

  const OneDayInEpoch = 86400000;
  const numOfDaysInEpoch = diffInEpoch / OneDayInEpoch;
  const numOfDays = Math.floor(numOfDaysInEpoch);
  const numOfHoursInEpoch = numOfDays ? (numOfDaysInEpoch % numOfDays) * 24 : numOfDaysInEpoch * 24;
  const numOfHours = Math.floor(numOfHoursInEpoch);
  const numOfMinsInEpoch = numOfHours
    ? (numOfHoursInEpoch % numOfHours) * 60
    : numOfHoursInEpoch * 60;
  const numOfMins = Math.floor(numOfMinsInEpoch);
  const daysHoursMins = isEndTimeLaterThanStart
    ? `${numOfDays}d ${numOfHours}hr ${numOfMins}min`
    : `-${numOfDays}d ${numOfHours}hr ${numOfMins}min`;

  return daysHoursMins;
};

/**
 * Converts an epoch to the start or end of that week as a string
 * @param {Number} epochs
 * @param {boolean} isEnd
 * @returns a date string of either start or end of week
 */
export const getWeekDate = (epoch, isEnd) => {
  const date = new Date(Math.round(Number(isEnd ? epoch + 518400000 : epoch)));
  return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
};

/**
 * Function get all days between two datetimes and add the shift time to each.
 * End time should be later than start
 * ReturnFormat: [{start: 170494920000, end: 170495920000}, {start: 170494910000, end: 170595920000}]
 * @return {Array.<{start: Number, end: Number}>} "[{start: 170494920000, end: 170495920000},....]"
 */
export const getAllDatesBetweenAndAddShiftTimes = (start, end, daysOfWeek, shift) => {
  const startDate = DateTime.local(start.year, start.month, start.day);
  const endDate = DateTime.local(end.year, end.month, end.day);

  let dates = [startDate];
  while (dates[dates.length - 1].toMillis() < endDate.toMillis()) {
    dates.push(dates[dates.length - 1].plus({ days: 1 }));
  }

  if (daysOfWeek) {
    const excludedDays = Object.keys(daysOfWeek).filter((k) => daysOfWeek[k] === false);
    dates = dates.filter((d) => !excludedDays.some((a) => a === d.weekdayLong.toLowerCase()));
  }
  const shiftsDates = dates.map((dt) => {
    const shiftStartDate = dt.set({ hour: Math.floor(shift.start / 60), minute: shift.start % 60 });
    let shiftEndDate = dt.set({ hour: Math.floor(shift.end / 60), minute: shift.end % 60 });

    if (shift.start > shift.end) {
      shiftEndDate = shiftEndDate.plus({ days: 1 });
    }

    const shiftDetail = {
      start: shiftStartDate.toMillis(),
      end: shiftEndDate.toMillis(),
    };

    return shiftDetail;
  });

  return shiftsDates;
};

/**
 * @param {Number} epoch
 * @returns new epoch subtracted 1 week
 */
export const subtractOneWeek = (epoch) => {
  const date = new Date(epoch);
  date.setDate(date.getDate() - 7);
  const newEpoch = date.getTime();
  return newEpoch;
};

/**
 * @param {Number} epoch
 * @returns new epoch added 1 week
 */
export const addOneWeek = (epoch) => {
  const date = new Date(epoch);
  date.setDate(date.getDate() + 7);
  const newEpoch = date.getTime();
  return newEpoch;
};
