import { get } from 'lodash/fp';

import FormChip from '../../../../../components/Form/FormChip';
import FormRating from '../../../../../components/Form/FormRating';
import FormTextField from '../../../../../components/Form/FormTextField';
import FormTypographyBox from '../../../../../components/Form/FormTypographyBox';
import {
  composeValidators,
  containsASpace,
  isValidEmail,
  isValidPhoneNumber,
} from '../../../../../components/Form/validations';
import theme from '../../../../../theme';

const detailViewTypographyHeader = ['components', 'detailView', 'typography', 'header'];
const detailViewTypographySubSectionHeader = [
  'components',
  'detailView',
  'typography',
  'subSectionHeader',
];
const detailViewTypographySemiBoldHeader = [
  'components',
  'detailView',
  'typography',
  'semiBoldHeader',
];
const detailViewTypographySemiBoldText = ['components', 'detailView', 'typography', 'semiBoldText'];

const nameSx = {
  fontSize: get([...detailViewTypographyHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographyHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographyHeader, 'fontColor'], theme),
  height: '30px',
  width: '100%',
  marginTop: '-5px',
};

const locationSx = {
  fontSize: get([...detailViewTypographySemiBoldHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySemiBoldHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySemiBoldHeader, 'fontColor'], theme),
  marginTop: '-10px',
};

const userStatusSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  marginTop: '-5px',
};

const statusSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  marginTop: '10px',
};

const userRoleSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-16px',
};

const roleSx = {
  fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
  color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
  marginTop: '-5px',
};

const userEmailSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-10px',
};

const emailSx = {
  fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
  color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
  marginTop: '-10px',
};

const userInfoFieldSx = {
  marginTop: '-10px',
};

const phoneNumberHeaderSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
};

const phoneNumberSx = {
  fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
  color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
};

const userRatingSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-5px',
};

const upcomingJobsSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-10px',
};

const jobNumSx = {
  fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
  color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
  marginTop: '-10px',
};

const userFlagSx = {
  fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
  color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
  fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
  marginTop: '-15px',
};

export const CommonFormFieldDataOverview = {
  name: {
    ComponentType: FormTypographyBox,
    key: 'userName',
    name: 'name',
    sx: nameSx,
  },
  location: {
    ComponentType: FormTypographyBox,
    key: 'location',
    name: 'location',
    sx: locationSx,
  },
  userStatus: {
    ComponentType: FormTypographyBox,
    key: 'userStatus',
    name: 'userStatus',
    sx: userStatusSx,
  },
  status: {
    ComponentType: FormChip,
    label: 'Active',
    name: 'status',
    sx: statusSx,
  },
  userRole: {
    ComponentType: FormTypographyBox,
    key: 'userRole',
    name: 'userRole',
    sx: userRoleSx,
  },
  role: {
    ComponentType: FormTypographyBox,
    key: 'role',
    name: 'role',
    sx: roleSx,
  },
  userEmail: {
    ComponentType: FormTypographyBox,
    key: 'userEmail',
    name: 'userEmail',
    sx: userEmailSx,
  },
  email: {
    ComponentType: FormTypographyBox,
    key: 'email',
    name: 'email',
    sx: emailSx,
  },
  phoneNumberHeader: {
    ComponentType: FormTypographyBox,
    key: 'phoneNumberHeader',
    name: 'phoneNumberHeader',
    sx: phoneNumberHeaderSx,
  },
  phoneNumber: {
    ComponentType: FormTypographyBox,
    key: 'phoneNumber',
    name: 'phoneNumber',
    sx: phoneNumberSx,
  },

  editEmail: {
    ComponentType: FormTextField,
    mode: 'dark',
    name: 'email',
    required: true,
    validate: composeValidators(containsASpace, isValidEmail),
    sx: userInfoFieldSx,
  },
  editPhoneNumber: {
    ComponentType: FormTextField,
    mode: 'dark',
    name: 'phoneNumber',
    required: true,
    validate: composeValidators(isValidPhoneNumber),
    sx: userInfoFieldSx,
  },
};

export const CandidateFormFieldDataOverview = {
  userRating: {
    ComponentType: FormTypographyBox,
    key: 'userRating',
    name: 'userRating',
    sx: userRatingSx,
  },
  rating: {
    ComponentType: FormRating,
    key: 'rating',
    name: 'rating',
  },
  upcomingJobs: {
    ComponentType: FormTypographyBox,
    key: 'upcomingJobs',
    name: 'upcomingJobs',
    sx: upcomingJobsSx,
  },
  jobNum: {
    ComponentType: FormTypographyBox,
    key: 'jobNum',
    name: 'jobNum',
    sx: jobNumSx,
  },
  userFlag: {
    ComponentType: FormTypographyBox,
    key: 'userFlag',
    name: 'userFlag',
    sx: userFlagSx,
  },
};
