import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectPasswordUpdate = (state) => get('passwordUpdate', state);

const selectIsLoading = createSelector(
  selectPasswordUpdate,
  (passwordUpdate) => passwordUpdate.isLoading
);
export default selectIsLoading;
