import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  files: {},
};

export const fileSlice = createSlice({
  name: 'fileSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    getFile: (state, action) => {
      if (!state.loading && !state.files[action.payload.id]) {
        const newObject = { ...state.files };
        newObject[action.payload.id] = { isLoading: true };
        state.files = newObject;
      }
    },
    fileProcessed: (state, action) => {
      const newObject = {
        ...state.files,
      };
      const objectURL = URL.createObjectURL(action.payload.data);
      newObject[action.payload.headers.id] = objectURL;
      state.files = newObject;
    },
    fileError: () => {
      // eslint-disable-next-line no-console
      console.log('Error getting file');
    },
    /* eslint-disable no-param-reassign */
  },
});

export const { getFile, fileProcessed, fileError } = fileSlice.actions;

export const fileReducer = fileSlice.reducer;
