import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectSkillsAndCertsList = (state) => get('skillsAndCerts', state);

export const selectSkillsList = createSelector(
  selectSkillsAndCertsList,
  (SkillsAndCerts) => SkillsAndCerts.skillsList
);

export const selectIsSkillsLoading = createSelector(
  selectSkillsAndCertsList,
  (SkillsAndCerts) => SkillsAndCerts.isSkillsLoading
);

export const selectIsCertsLoading = createSelector(
  selectSkillsAndCertsList,
  (SkillsAndCerts) => SkillsAndCerts.isCertsLoading
);
export const selectCertsList = createSelector(
  selectSkillsAndCertsList,
  (SkillsAndCerts) => SkillsAndCerts.certsList
);

export const selectSkillTotalRowCount = createSelector(
  selectSkillsAndCertsList,
  (SkillsAndCerts) => SkillsAndCerts.totalSkillRowCount
);

export const selectCertsTotalRowCount = createSelector(
  selectSkillsAndCertsList,
  (SkillsAndCerts) => SkillsAndCerts.totalCertsRowCount
);
