import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Divider, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import selectUser from '../../../store/selectors/appSelector';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { JOB_ORDER_STATUSES } from '../../../views/jobOrders/jobOrderConstants';

import EmployeeRosterCard from './SubComponents/EmployeeRosterCard';

const useStyles = makeStyles({
  content: {
    padding: '12px',
    fontFamily: PRIMARY_FONT[300],
    overflow: 'auto',
  },
  titleText: {
    width: '100%',
    display: 'block',
    textAlign: 'left',
    fontSize: '20px',
    fontFamily: PRIMARY_FONT[700],
    lineHeight: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  corpNameText: {
    maxWidth: '170px',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: PRIMARY_FONT[300],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: '3px',
  },
  exIdText: {
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: PRIMARY_FONT[300],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  employees: {
    display: 'flex',
    flexDirection: 'column',
    '& .title': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '10px',
      lineHeight: '12px',
      fontFamily: PRIMARY_FONT[300],
      paddingTop: '12px',
      textTransform: 'uppercase',
      '& .listHeader': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
    },
    '& .list': {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    '& .reserveListTitle': {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '10px',
      lineHeight: '12px',
      fontWeight: 300,
      textTransform: 'uppercase',
      '& .listHeader': {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
    },
  },
  infoIcon: { fontSize: '14px' },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    width: '306px',
    height: '250px',
    gap: '12px',
  },
  reserveListText: {
    fontFamily: PRIMARY_FONT[700],
    textAlign: 'left',
    fontSize: '12px',
    lineHeight: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
});

const ActiveRosterView = ({
  jobOrder,
  isExpanded,
  onPlacementCheckStatusUpdate,
  clearTimeCardError,
  handleTimecardEditSubmit,
  onStatusUpdate,
  timecardUpdateError,
  timecardIsUpdating,
}) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const placements =
    jobOrder?.placements?.filter((p) => p?.state?.status === JOB_ORDER_STATUSES.ACTIVE) || [];
  const numOpenings = jobOrder?.numOpenings || 0;
  const sortedPlacements = placements?.sort(
    (a, b) => new Date(a?.metadata?.created) - new Date(b?.metadata?.created)
  );
  const activePlacements = sortedPlacements.slice(0, numOpenings);
  const reserveList = sortedPlacements.slice(numOpenings);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <Box
        className={classes.content}
        sx={{
          height: isExpanded ? '740px' : '324px',
        }}
      >
        <div className={classes.employees}>
          <Typography className={classes.titleText}> {jobOrder?.name} </Typography>
          {user.role === UserRole.ADMIN && (
            <Box sx={{ display: 'flex' }}>
              <Typography className={classes.corpNameText}>
                {jobOrder?.corporation?.name}
              </Typography>
              <Typography className={classes.exIdText}>
                {`• EXID  ${jobOrder?.metadata?.bullhorn?.id}`}
              </Typography>
            </Box>
          )}
          <div className="title">
            <div>
              {LanguageConverter('jobOrder.employees')}({activePlacements?.length})
            </div>
            <div className="title listHeader">
              <div>
                <HelpOutlineIcon className={classes.infoIcon} onClick={handleClick} />
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <div className={classes.popover}>
                    <Typography>{LanguageConverter('roster.presentInfoText')}</Typography>
                    <Typography>{LanguageConverter('roster.noShowInfoText')}</Typography>
                    <Typography>{LanguageConverter('roster.sentHomeInfoText')}</Typography>
                  </div>
                </Popover>
              </div>

              <div> {LanguageConverter('jobOrder.present')} </div>
              <div> {LanguageConverter('jobOrder.more')} </div>
            </div>
          </div>
          <div className="list">
            {activePlacements.map((placement) => (
              <EmployeeRosterCard
                corporationName={jobOrder?.corporation?.name}
                key={placement._id}
                onPlacementCheckStatusUpdate={onPlacementCheckStatusUpdate}
                clearTimeCardError={clearTimeCardError}
                placement={placement}
                shiftName={jobOrder?.name}
                handleTimecardEditSubmit={handleTimecardEditSubmit}
                onStatusUpdate={onStatusUpdate}
                timecardUpdateError={timecardUpdateError}
                timecardIsUpdating={timecardIsUpdating}
              />
            ))}

            {reserveList.length > 0 && (
              <>
                <Divider className={classes.reserveListText}>
                  {LanguageConverter('roster.reserveList')}
                </Divider>
                {reserveList.map((placement) => (
                  <EmployeeRosterCard
                    corporationName={jobOrder?.corporation?.name}
                    key={placement._id}
                    onPlacementCheckStatusUpdate={onPlacementCheckStatusUpdate}
                    clearTimeCardError={clearTimeCardError}
                    placement={placement}
                    shiftName={jobOrder?.name}
                    handleTimecardEditSubmit={handleTimecardEditSubmit}
                    onStatusUpdate={onStatusUpdate}
                    timecardUpdateError={timecardUpdateError}
                    timecardIsUpdating={timecardIsUpdating}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
};

ActiveRosterView.propTypes = {
  isExpanded: PropTypes.bool,
  jobOrder: PropTypes.shape({
    _id: PropTypes.string,
    corporation: PropTypes.shape({
      name: PropTypes.string,
    }),
    name: PropTypes.string,
    numOpenings: PropTypes.number,
    placements: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
      })
    ),
    start: PropTypes.number,
    end: PropTypes.number,
    metadata: PropTypes.shape({
      bullhorn: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  }),
  onPlacementCheckStatusUpdate: PropTypes.func,
  clearTimeCardError: PropTypes.func,
  handleTimecardEditSubmit: PropTypes.func,
  onStatusUpdate: PropTypes.func,
  timecardUpdateError: PropTypes.string,
  timecardIsUpdating: PropTypes.bool,
};

export default ActiveRosterView;
