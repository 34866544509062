import FormSelectDropdownField from '../../../components/Form/FormSelectDropdownField';
import FormSelectPillPicker from '../../../components/Form/FormSelectPillPicker';
import theme from '../../../theme';

const JobPreferenceSettingsData = {
  industryPreferences: {
    ComponentType: FormSelectPillPicker,
    key: 'industry',
    name: 'industry',
    required: true,
    data: [
      {
        label: 'Healthcare',
        value: 'healthcare',
      },
      {
        label: 'Manufacturing',
        value: 'manufacturing',
      },
      {
        label: 'Hospitality',
        value: 'hospitality',
      },
    ],
    sx: { fontSize: '18px', fontFamily: 'Barlow-600', margin: theme.spacing(2) },
  },

  distance: {
    ComponentType: FormSelectDropdownField,
    name: 'distance',
    required: true,
    options: [
      { id: '5 Miles', name: '5 Miles', value: 5 },
      { id: '10 Miles', name: '10 Miles', value: 10 },
      { id: '15 Miles', name: '15 Miles', value: 15 },
      { id: '20 Miles', name: '20 Miles', value: 20 },
      { id: '25 Miles', name: '25 Miles', value: 25 },
      { id: '30 Miles', name: '30 Miles', value: 30 },
      { id: '35 Miles', name: '35 Miles', value: 35 },
      { id: '40 Miles', name: '40 Miles', value: 40 },
      { id: '45 Miles', name: '45 Miles', value: 45 },
      { id: '50 Miles', name: '50 Miles', value: 50 },
    ],
    fullWidth: false,
    sx: { width: '120px', height: '48px' },
  },
};

export default JobPreferenceSettingsData;
