import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { v4 } from 'uuid';

import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';

import IconButton from '../../../components/IconButton';
import GpsMapModal from '../../../components/Map/GpsMapModal';
import theme from '../../../theme';
import { BLACK } from '../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../theme/fontConstants';
import { epochToTimeInReadableFormat } from '../../../utils';

const PunchLocationsMap = ({ punches = [], name }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const setCoordinatesList = () => {
    const result = [];
    punches.forEach((punch) => {
      const punchInCoords = punch?.in?.gps?.coordinates;
      const punchOutCoords = punch?.out?.gps?.coordinates;
      if (punch?.in?.stamp && punchInCoords?.latitude) {
        result.push({
          _id: v4(),
          name: epochToTimeInReadableFormat(punch?.in?.stamp),
          lat: punchInCoords?.latitude,
          lng: punchInCoords?.longitude,
          radius: punchInCoords?.accuracy,
        });
      }
      if (punch?.out?.stamp && punchOutCoords?.latitude) {
        result.push({
          _id: v4(),
          name: epochToTimeInReadableFormat(punch?.out?.stamp),
          lat: punchOutCoords?.latitude,
          lng: punchOutCoords?.longitude,
          radius: punchOutCoords?.accuracy,
        });
      }
    });
    return result;
  };

  return (
    <>
      <IconButton
        iconElement={
          <PinDropOutlinedIcon sx={{ color: theme?.button?.palette?.whiteIcon, fontSize: 30 }} />
        }
        onClick={() => setOpen(true)}
        size="small"
        sx={{ marginLeft: 0.5, marginTop: 0.5 }}
        dataTestId="open-gps-punch-location-button"
        label={intl.formatMessage({ id: 'buttonText.viewGPSMap' })}
        labelSx={{
          fontSize: '14px',
          fontWeight: '500',
          fontFamily: PRIMARY_FONT[500],
          color: `${BLACK[0]}`,
        }}
      />
      {open && (
        <GpsMapModal
          coordinatesList={setCoordinatesList()}
          title={name}
          open={open}
          handleOnClose={() => setOpen(false)}
        />
      )}
    </>
  );
};
PunchLocationsMap.propTypes = {
  punches: PropTypes.arrayOf(
    PropTypes.shape({
      punches: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),

  name: PropTypes.string,
};

export default PunchLocationsMap;
