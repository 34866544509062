import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

const FormTypographyDisplayList = ({ input, sx, value, xs }) => {
  const content = get('value', input) || [];
  return (
    <Grid container item xs={xs} sx={sx}>
      <Box value={value}>
        <Box>
          {content.map((c) => (
            <Typography key={c.id}>{c.label}</Typography>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};
FormTypographyDisplayList.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  sx: PropTypes.shape({}),
  value: PropTypes.string,
  xs: PropTypes.number,
};

export default FormTypographyDisplayList;
