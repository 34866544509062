import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Popover } from '@mui/material';

import Button, { BUTTON_VARIANT } from '../../../components/Button';
import theme from '../../../theme/theme';

import Filter from './Filter';

const ModelFilter = ({ customValueChangeHandler, items, handleValueChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    if (open) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.target);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const paperSx = {
    padding: theme.spacing(3),
  };
  return (
    <>
      <Button
        onClick={handleClick}
        variant={BUTTON_VARIANT.OUTLINED}
        text={LanguageConverter('buttonText.filters')}
      />
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        placement="bottom-end"
        PaperProps={{ sx: paperSx }}
      >
        <Filter
          items={items}
          handleValueChange={handleValueChange}
          column
          customValueChangeHandler={customValueChangeHandler}
        />
      </Popover>
    </>
  );
};

ModelFilter.propTypes = {
  customValueChangeHandler: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  handleValueChange: PropTypes.func,
};

export default ModelFilter;
