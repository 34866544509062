import { get } from 'lodash/fp';

import commonDatahubMiddleware from '@careerstart/wae-common/src/datahub/datahubMiddleware';

import { apiDelete, apiGet, apiPatch, apiPost, apiPut } from './axios';
import API from './datahubApi';
import { logOutOn401, updateUserToken } from './helpers';

const parseTokenFromStore = (store) => get(['app', 'session', 'user', 'token'], store.getState());
const parseRefreshTokenFromStore = (store) =>
  get(['app', 'session', 'user', 'refreshToken'], store.getState());

const datahubMiddleware = (storeAPI) =>
  commonDatahubMiddleware(
    storeAPI,
    API,
    apiDelete,
    apiGet,
    apiPatch,
    apiPost,
    apiPut,
    logOutOn401,
    updateUserToken,
    parseTokenFromStore,
    parseRefreshTokenFromStore
  );

export default datahubMiddleware;
