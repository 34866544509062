import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import { inviteEmployeeField } from '../FormFieldData/CreateJobFields';

const InviteEmployees = ({ isInviteModalOpen, setIsInviteModalOpen, wrapperSx }) => (
  <Box
    sx={{
      width: '100%',
      ...wrapperSx,
    }}
  >
    {buildFormField(inviteEmployeeField({ isInviteModalOpen, setIsInviteModalOpen }))}
  </Box>
);

InviteEmployees.propTypes = {
  isInviteModalOpen: PropTypes.bool,
  setIsInviteModalOpen: PropTypes.func,
  wrapperSx: PropTypes.shape({}),
};

export default InviteEmployees;
