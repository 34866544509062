import React, { memo, useCallback, useEffect, useState } from 'react';
import get from 'lodash/fp/get';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoginRequestSchema from '@careerstart/wae-common/schema/users/login.post.req.json';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { validateSchema } from '../../components/Form/validations';
import selectUser from '../../store/selectors/appSelector';
import selectIsLoading from '../../store/selectors/loginSelectors';
import theme from '../../theme';

import LoginForm from './LoginForm';
import { login } from './reducer';

const RootGrid = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.palette.background.black,
}));

const CenteredGrid = styled(Grid)(() => ({
  justifyContent: 'center',
  padding: theme.spacing(2),
  overflow: 'hidden',
}));

const Login = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [attemptedEmails, setAttemptedEmails] = useState({});

  const isLoading = useSelector(selectIsLoading);
  const user = useSelector(selectUser);
  const token = get('token', user);
  useEffect(() => {
    if (token) {
      navigate('dashboard');
    }
  }, [navigate, token]);

  const handleOnSubmitLogIn = useCallback(
    (values) => {
      const schemaErrors = validateSchema(values, LoginRequestSchema);
      if (Object.keys(schemaErrors).length === 0) {
        dispatch(login(values));

        /* 
         We track front-end how many times we've failed to log in to an email so that
         we can display a warning to the user when they are at risk of having
         accidentally locked their account.
        */
        const attemptedEmail = get('email', values);
        setAttemptedEmails({
          ...attemptedEmails,
          [values.email]:
            (attemptedEmail &&
              attemptedEmail in attemptedEmails &&
              get(values.email, attemptedEmails) + 1) ||
            1,
        });
      }
    },
    [attemptedEmails, dispatch]
  );

  return (
    <RootGrid container>
      <CenteredGrid item>
        <LoginForm
          displayLockedAccountWarning={Object.values(attemptedEmails).some(
            (numAttempts) => Number.isInteger(numAttempts) && numAttempts >= 5
          )}
          handleOnSubmitLogIn={handleOnSubmitLogIn}
          isLoading={isLoading}
          navigate={navigate}
        />
      </CenteredGrid>
    </RootGrid>
  );
});

export default Login;
