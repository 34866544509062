import React, { useState } from 'react';
import getOr from 'lodash/fp/getOr';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import theme from '../../theme/theme';
import PillChips from '../PillChips/PillChips';

const FormSelectPillPicker = ({ background, data, input, size, sx, testId }) => {
  const { onChange } = input;

  const [value, setValue] = useState(input.value || {});
  const handleOnClick = (val) => {
    setValue({ ...value, [val]: !value[val] });
    onChange({ ...value, [val]: !value[val] });
  };

  return (
    <Grid container item spacing={2} sx={{ marginBottom: theme.spacing(5) }} data-testid={testId}>
      {data &&
        data.map((d) => (
          <PillChips
            background={background}
            label={d.label}
            key={d.label}
            onClick={() => handleOnClick(d.value)}
            value={getOr(false, d.value, input.value)}
            size={size}
            sx={{ ...sx, marginRight: '8px' }}
          />
        ))}
    </Grid>
  );
};
FormSelectPillPicker.propTypes = {
  background: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.shape({}),
  }),

  size: PropTypes.string,
  sx: PropTypes.shape({}),
  testId: PropTypes.string,
};

export default FormSelectPillPicker;
