import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import adminPostJobReqSch from '@careerstart/wae-common/schema/extension-group/admin.egroup.post.req.json';
import employerPostJobReqSch from '@careerstart/wae-common/schema/extension-group/employer.egroup.post.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import BackdropCircularProgress from '../../components/BackdropCircularProgress';
import JobCreationFAQ from '../../components/FAQcomponent';
import FormCloseButton from '../../components/Form/FormCloseButton';
import { validateSchema } from '../../components/Form/validations';
import selectUser from '../../store/selectors/appSelector';
import {
  selectErrorPostGroupOrders,
  selectIsProcessingPostGroupOrders,
  selectPostGroupOrdersResponse,
} from '../../store/selectors/jobOrdersSelector';
import { showSnackbar } from '../app';
import {
  clearGroupError,
  clearPostGroupOrdersResponse,
  postGroupOrders,
} from '../jobOrders/jobOrdersReducer';

import CreateJobFormComponent from './CreateJobCard/CreateJobFormComponent';
import { getExtensionGroupPayload } from './helpers';

const JobOrdersCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const role = user?.role;
  const theme = useTheme();

  const isProcessingPostGroupOrders = useSelector(selectIsProcessingPostGroupOrders);
  const error = useSelector(selectErrorPostGroupOrders);
  const createResponse = useSelector(selectPostGroupOrdersResponse);

  const [jobName, setJobName] = useState(null);
  const faqList = [
    {
      header: LanguageConverter('job.create.faq.changeJobEndDate.header'),
      bodyElements: [LanguageConverter('job.create.faq.changeJobEndDate.body1')],
    },
    {
      header: LanguageConverter('job.create.faq.howManyWorkersAfterJobPost.header'),
      bodyElements: [
        LanguageConverter('job.create.faq.howManyWorkersAfterJobPost.body1'),
        LanguageConverter('job.create.faq.howManyWorkersAfterJobPost.body2'),
      ],
    },
    {
      header: LanguageConverter('job.create.faq.whatIsWaeAutofill.header'),
      bodyElements: [LanguageConverter('job.create.faq.whatIsWaeAutofill.body1')],
    },
    {
      header: LanguageConverter('job.create.faq.canIRemoveWorkersFromRoster.header'),
      bodyElements: [
        LanguageConverter('job.create.faq.canIRemoveWorkersFromRoster.body1'),
        LanguageConverter('job.create.faq.canIRemoveWorkersFromRoster.body2'),
      ],
    },
    {
      header: LanguageConverter('job.create.faq.sameWorkersOnEveryShift.header'),
      bodyElements: [LanguageConverter('job.create.faq.sameWorkersOnEveryShift.body1')],
    },
    {
      header: LanguageConverter('job.create.faq.alreadyAnExistingShift.header'),
      bodyElements: [LanguageConverter('job.create.faq.alreadyAnExistingShift.body')],
    },
  ];

  const submitFunc = (pa) => {
    const createPayload = getExtensionGroupPayload({ formValues: pa, user });
    if (
      (!get('FORM_ERROR'),
      validateSchema(
        createPayload,
        role === UserRole.EMPLOYER ? employerPostJobReqSch : adminPostJobReqSch
      ))
    ) {
      setJobName(pa?.positionTemplate?.name);
      dispatch(postGroupOrders(createPayload));
    }
  };

  const closeAndClear = useCallback(
    (message) => {
      navigate('/jobs/jobsList');
      dispatch(clearPostGroupOrdersResponse());
      dispatch(showSnackbar({ message }));
    },
    [dispatch, navigate]
  );

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const mainWrapperSx = {
    display: 'flex',
    padding: theme.spacing(2),
    flexDirection: smallScreen ? 'column' : 'row',
    gap: theme.spacing(2),
  };

  const message = LanguageConverter('job.create.success', jobName);

  useEffect(() => {
    if (createResponse?.data?.documents) {
      closeAndClear(message);
    }
  }, [closeAndClear, createResponse, dispatch, jobName, message]);

  useEffect(() => {
    if (error) {
      dispatch(showSnackbar({ message: error }));
      dispatch(clearGroupError());
    }
  }, [dispatch, error]);

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 10,
        width: '100vw',
        padding: theme.spacing(2),
      }}
    >
      {isProcessingPostGroupOrders && <BackdropCircularProgress />}
      <FormCloseButton onClose={closeAndClear} message="jobs.backToList" />
      {createResponse === null && (
        <Box sx={mainWrapperSx}>
          <Box
            sx={{
              flex: 5,
            }}
          >
            <Typography
              sx={{
                color: theme?.jobOrders?.createPage?.addJobHeaderTypography?.fontColor,
                fontSize: theme?.jobOrders?.createPage?.addJobHeaderTypography?.fontSize,
                fontFamily: theme?.jobOrders?.createPage?.addJobHeaderTypography?.font,
                marginBottom: theme.spacing(1),
              }}
            >
              {LanguageConverter('job.create')}
            </Typography>
            <CreateJobFormComponent
              user={user}
              onSubmit={submitFunc}
              onClose={closeAndClear}
              isProcessing={isProcessingPostGroupOrders}
            />
          </Box>

          <Box sx={{ flex: 2 }}>
            <JobCreationFAQ faqList={faqList} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default JobOrdersCreate;
