import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCreateCorporation = (state) => get('createCorporation', state);

export const selectIsProcessingPostCorp = createSelector(
  selectCreateCorporation,
  (createCorporation) => createCorporation.isProcessingPostCorp
);
export const selectErrors = createSelector(
  selectCreateCorporation,
  (createCorporation) => createCorporation.errors
);
