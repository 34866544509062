/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import theme from '../../../../theme';
import { weekDays } from '../../../../utils/timeConverter';
import Calendar from '../../assets/calendar.svg';

const messageSx = {
  color: theme.candidate.components.dashboard.headers.fontColor,
  fontFamily: theme.candidate.components.dashboard.headers.font,
  fontSize: theme.candidate.components.dashboard.headers.fontSize,
};
const timeSx = {
  color: theme.candidate.components.dashboard.headers.fontColor,
  fontFamily: theme.candidate.components.dashboard.headers.timeFontWeight,
  fontSize: theme.candidate.components.dashboard.headers.timeFontSize,
  marginLeft: '5px',
  minWidth: '250px',
};

const getDisplayTime = () => {
  const d = new Date();
  const readableTime = d.toLocaleString();
  const dayName = weekDays[d.getDay()];
  return `${dayName},${readableTime}`;
};

const TopBanner = ({ userName }) => {
  const [displayTime, SetDisplayTime] = useState(getDisplayTime);

  useEffect(() => {
    const t = setInterval(() => {
      SetDisplayTime(getDisplayTime);
    }, 1000);
    return () => {
      clearTimeout(t);
    };
  }, []);

  return (
    <Box
      sx={{
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography sx={messageSx}>Welcome to WAE, {userName}</Typography>
      <Box sx={{ display: 'flex' }}>
        <Box component="img" sx={{}} alt="Calendar" src={Calendar} />
        <Box sx={timeSx}>{displayTime}</Box>
      </Box>
    </Box>
  );
};

TopBanner.propTypes = {
  userName: PropTypes.string,
};

export default TopBanner;
