import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';

import { Grid, styled, Typography as Typo } from '@mui/material';

import theme from '../../theme';

// focused, input, label, meta, sx

const FormReadOnlyListField = ({ data, mode }) => {
  const Typography = styled(Typo)(() => ({
    fontFamily: get(['components', 'readOnlyListField', 'typography', 'fontFamily'], theme),
    fontSize: get(['components', 'readOnlyListField', 'typography', 'fontSize'], theme),
    color:
      (mode === 'dark' &&
        get(['components', 'readOnlyListField', 'typography', 'darkFontColor'], theme)) ||
      get(['components', 'readOnlyListField', 'typography', 'fontColor'], theme),
    lineHeight: get(['components', 'readOnlyListField', 'typography', 'lineHeight'], theme),
  }));

  const KeyTypography = styled(Typo)(() => ({
    fontFamily: 'Barlow',
    fontSize: get(['components', 'readOnlyListField', 'typography', 'fontSize'], theme),
    color:
      (mode === 'dark' &&
        get(['components', 'readOnlyListField', 'typography', 'darkFontColor'], theme)) ||
      get(['components', 'readOnlyListField', 'typography', 'fontColor'], theme),
    lineHeight: get(['components', 'readOnlyListField', 'typography', 'lineHeight'], theme),
  }));
  return (
    <Grid container>
      {data.map((item) => (
        // eslint-disable-next-line react/no-array-index-key

        <Grid item key={item.name} container justifyContent="space-between" direction="row">
          <KeyTypography
            sx={{
              fontWeight: get(
                ['components', 'readOnlyListField', 'typography', 'fontWeightOfKey'],

                theme
              ),
            }}
          >
            {item.name}
          </KeyTypography>

          <Typography
            sx={{
              fontWeight: get(
                ['components', 'readOnlyListField', 'typography', 'fontWeightOfValue'],

                theme
              ),
            }}
          >
            {item.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
FormReadOnlyListField.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  mode: PropTypes.string,
};

export default FormReadOnlyListField;
