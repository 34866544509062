import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';

import theme from '../../theme/theme';
import { showSnackbar } from '../../views/app';
import { BUTTON_VARIANT, WaeButton } from '../Button/Button';

import {
  backButtonSx,
  backgroundSx,
  bodyTextSx,
  buttonGridSx,
  forwardButtonSx,
  subTextSx,
  titleSx,
} from './ModalComponentStyle';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const arrowType = {
  FORWARD: 'forward',
  BACK: 'back',
};

const ModalComponent = ({
  bodyText,
  subText,
  cancelText,
  handleOnClose,
  isError,
  isOpen,
  onSubmit,
  onSubmitIsErrorMessage,
  submitButtonArrowType, // Submit button arrows, value can only be 'forward' or 'back'. If empty, no arrows will appear.
  submitText,
  sx,
  titleText,
}) => {
  const dispatch = useDispatch();

  const modalArrowProp =
    (submitButtonArrowType === arrowType.FORWARD && {
      endIcon: <ArrowForwardIcon sx={{ color: get(['button', 'palette', 'text'], theme) }} />,
    }) ||
    (submitButtonArrowType === arrowType.BACK && {
      startIcon: <ArrowBackIcon sx={{ color: get(['button', 'palette', 'text'], theme) }} />,
    }) ||
    {};

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isWindowWidthLessThanModalWidth, setIsWindowWidthLessThanModalWidth] = useState(false);
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (get('width', windowDimensions) < 517) {
      setIsWindowWidthLessThanModalWidth(true);
    } else {
      setIsWindowWidthLessThanModalWidth(false);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  const handleOnSubmit = () => {
    if (isError) {
      dispatch(showSnackbar({ message: onSubmitIsErrorMessage || 'error.modal.invalid.submit' }));
      return;
    }
    onSubmit();
  };

  return (
    <Modal open={isOpen} onClose={handleOnClose}>
      <Grid
        container
        justifyContent="space-between"
        direction="column"
        sx={{ ...sx, ...backgroundSx(isWindowWidthLessThanModalWidth) }}
      >
        <Typography sx={titleSx}>{titleText}</Typography>
        <Typography sx={bodyTextSx}>{bodyText}</Typography>
        <Typography sx={subTextSx}>{subText}</Typography>
        <Grid container item justifyContent="flex-end" sx={buttonGridSx}>
          <WaeButton
            onClick={handleOnClose}
            variant={BUTTON_VARIANT.OUTLINED}
            sx={backButtonSx}
            startIcon={
              <ArrowBackIcon sx={{ color: get(['button', 'palette', 'secondary'], theme) }} />
            }
          >
            {cancelText}
          </WaeButton>
          <WaeButton
            data-testid="modalSubmitButton"
            {...modalArrowProp}
            sx={forwardButtonSx}
            onClick={handleOnSubmit}
          >
            {submitText}
          </WaeButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

ModalComponent.propTypes = {
  bodyText: PropTypes.string,
  subText: PropTypes.string,
  cancelText: PropTypes.string,
  handleOnClose: PropTypes.func,
  isError: PropTypes.bool,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSubmitIsErrorMessage: PropTypes.string,
  submitButtonArrowType: PropTypes.string,
  submitText: PropTypes.string,
  sx: PropTypes.shape({}),
  titleText: PropTypes.string,
};

export default ModalComponent;
