import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '../../Button';
import { hasAnyFieldErrors } from '../formUtils';

import FormConfirmationModalStyle from './styles';

const FormConfirmationModal = ({ data, errorData, isOpen, onConfirm, setIsOpen, submitting }) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (hasAnyFieldErrors(errorData)) {
      setIsOpen(false);
    }
  }, [errorData, setIsOpen]);

  return (
    <Modal
      sx={{ overflow: 'scroll' }}
      open={isOpen}
      onClose={() => !submitting && setIsOpen(false)}
    >
      <Grid
        container
        direction="column"
        sx={{
          ...FormConfirmationModalStyle,
          width: mediumScreen ? '50%' : '90%',
        }}
        data-testid="confirmation-modal-data"
      >
        <Typography variant="h4" align="left">
          Confirm order?
        </Typography>
        <List dense sx={{ maxWidth: '100%' }}>
          {data.map((d) => (
            <ListItem disablePadding key={d}>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    sx={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
                  >
                    {d}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Button
            disabled={submitting}
            onClick={() => {
              setIsOpen(false);
            }}
            text={LanguageConverter('button.cancel')}
          />
          <Button
            disabled={submitting}
            showIndeterminateProgress={submitting}
            onClick={onConfirm}
            text={LanguageConverter('button.confirm')}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

FormConfirmationModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  errorData: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  setIsOpen: PropTypes.func,
  submitting: PropTypes.bool,
};

export default FormConfirmationModal;
