import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import WaeButton, { BUTTON_VARIANT } from '../../../../components/Button';

const FormSectionDescription = ({
  messageKey,
  messageValue,
  customText,
  learnMoreOnClick,
  learnMoreKey,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex ' }}>
      <Typography
        sx={{
          color: theme?.jobOrders?.createPage?.sectionDescription?.fontColor,
          fontSize: theme?.jobOrders?.createPage?.sectionDescription?.fontSize,
          fontFamily: theme?.jobOrders?.createPage?.sectionDescription?.font,
          marginBottom: theme.spacing(1),
        }}
      >
        {messageKey && LanguageConverter(messageKey, messageValue)}
        {customText}
        {learnMoreKey && (
          <WaeButton
            text={LanguageConverter(learnMoreKey)}
            onClick={learnMoreOnClick}
            variant={BUTTON_VARIANT.TEXT}
            sx={{ padding: 0, margin: theme.spacing(0, 0, 0, 1) }}
          />
        )}
      </Typography>
    </Box>
  );
};

FormSectionDescription.propTypes = {
  messageKey: PropTypes.string,
  messageValue: PropTypes.string,
  customText: PropTypes.string,
  learnMoreOnClick: PropTypes.func,
  learnMoreKey: PropTypes.string,
};

export default FormSectionDescription;
