import FormTextField from '../../../../components/Form/FormTextField';
import { isInteger } from '../../../../components/Form/validations';

const formFieldData = [
  {
    ComponentType: FormTextField,
    label: `Corporation's Bullhorn ID`,
    name: 'BullhornID',
    required: true,
    variant: 'standard',
    fullWidth: true,
    placeholder: `Enter Corporation's Bullhorn ID`,
    multiline: true,
    maxRows: 5,
    validate: isInteger,
  },
];

export default formFieldData;
