import { get, getOr } from 'lodash/fp';

import ApprovalCirclePill from '../../assets/icons/ApprovedCirclePill.svg';
import ConflictCirclePill from '../../assets/icons/ConflictCirclePill.svg';
import PendingCirclePill from '../../assets/icons/PendingCirclePill.svg';
import theme from '../../theme/theme';

import { TIMEKEEPING_STATUSES } from './constants';

export const SHORT_MESSAGES = {
  APPROVED: 'timekeeping.status.short.approved',
  CONFLICT: 'timekeeping.status.short.conflict',
  PENDING: 'timekeeping.status.short.pending',
  DISPUTED: 'timekeeping.status.short.disputed',
  RESOLVED: 'timekeeping.status.short.resolved',
  FINALIZED: 'timekeeping.status.short.finalized',
  OVERTIME: 'timekeeping.status.short.overtime',
};

const MESSAGES = {
  APPROVED: 'timekeeping.status.approved',
  CONFLICT: 'timekeeping.status.conflictFlag',
  PENDING: 'timekeeping.status.pending',
  DISPUTED: 'timekeeping.status.disputed',
  RESOLVED: 'timekeeping.status.resolved',
  FINALIZED: 'timekeeping.status.finalized',
  OVERTIME: 'timekeeping.status.overtime',
};

export const handleLabelProps = (status, nameIsShortened) => {
  let backgroundColor;
  let color;
  let text;
  let Icon;

  switch (status) {
    case TIMEKEEPING_STATUSES.APPROVED:
      backgroundColor = get(['label', 'timekeeping', 'bgColor', 'approved'], theme);
      color = get(['label', 'timekeeping', 'color', 'approved'], theme);
      text = !nameIsShortened ? MESSAGES.APPROVED : SHORT_MESSAGES.APPROVED;
      Icon = ApprovalCirclePill;
      break;
    case TIMEKEEPING_STATUSES.CONFLICT:
      backgroundColor = get(['label', 'timekeeping', 'bgColor', 'conflict'], theme);
      color = get(['label', 'timekeeping', 'color', 'conflict'], theme);
      text = !nameIsShortened ? MESSAGES.CONFLICT : SHORT_MESSAGES.CONFLICT;
      Icon = ConflictCirclePill;
      break;
    case TIMEKEEPING_STATUSES.PENDING:
      backgroundColor = get(['label', 'timekeeping', 'bgColor', 'pending'], theme);
      color = get(['label', 'timekeeping', 'color', 'pending'], theme);
      text = !nameIsShortened ? MESSAGES.PENDING : SHORT_MESSAGES.PENDING;
      Icon = PendingCirclePill;
      break;
    case TIMEKEEPING_STATUSES.DISPUTED:
      backgroundColor = get(['label', 'timekeeping', 'bgColor', 'disputed'], theme);
      color = get(['label', 'timekeeping', 'color', 'disputed'], theme);
      text = !nameIsShortened ? MESSAGES.DISPUTED : SHORT_MESSAGES.DISPUTED;
      Icon = ConflictCirclePill;
      break;
    case TIMEKEEPING_STATUSES.FINALIZED:
      backgroundColor = get(['label', 'timekeeping', 'bgColor', 'finalized'], theme);
      color = get(['label', 'timekeeping', 'color', 'finalized'], theme);
      text = !nameIsShortened ? MESSAGES.FINALIZED : SHORT_MESSAGES.FINALIZED;
      Icon = ApprovalCirclePill;
      break;
    case TIMEKEEPING_STATUSES.RESOLVED:
      backgroundColor = get(['label', 'timekeeping', 'bgColor', 'resolved'], theme);
      color = get(['label', 'timekeeping', 'color', 'resolved'], theme);
      text = !nameIsShortened ? MESSAGES.RESOLVED : SHORT_MESSAGES.RESOLVED;
      Icon = ApprovalCirclePill;
      break;
    case TIMEKEEPING_STATUSES.OVERTIME:
      backgroundColor = get(['label', 'timekeeping', 'bgColor', 'overtime'], theme);
      color = get(['label', 'timekeeping', 'color', 'overtime'], theme);
      text = !nameIsShortened ? MESSAGES.OVERTIME : SHORT_MESSAGES.OVERTIME;
      Icon = ConflictCirclePill;
      break;

    default:
      throw new Error('Valid status option required');
  }

  return {
    sx: {
      backgroundColor,
      color,
    },
    sxForText: {
      fontFamily: get(['label', 'job_orders', 'font'], theme),
    },
    Icon,
    text,
  };
};

export const sumDayTotalTime = (day) => {
  const value = day.reduce(
    (accumulator, curr) => accumulator + parseFloat(getOr(0, ['timecard', 'totalHours'], curr), 10),
    0
  );
  return value;
};

export const generateStatusData = (data, selectedTimeCard) => {
  const daysData = data?.days;

  const days = Object.keys(daysData);
  const returnObj = {};
  const approveList = [];
  days.forEach((day) => {
    returnObj[day] = sumDayTotalTime(daysData[day]);

    daysData[day].forEach((time) => {
      approveList.push(get(['timecard', 'status'], time));
    });
  });
  returnObj.status = {
    label:
      (approveList.includes(TIMEKEEPING_STATUSES.DISPUTED) && TIMEKEEPING_STATUSES.DISPUTED) ||
      (approveList.includes(TIMEKEEPING_STATUSES.PENDING) && TIMEKEEPING_STATUSES.PENDING) ||
      (approveList.includes(TIMEKEEPING_STATUSES.APPROVED) && TIMEKEEPING_STATUSES.APPROVED) ||
      (approveList.includes(TIMEKEEPING_STATUSES.RESOLVED) && TIMEKEEPING_STATUSES.RESOLVED) ||
      TIMEKEEPING_STATUSES.FINALIZED,
    detailViewStatus: !!selectedTimeCard,
  };

  return returnObj;
};

export const handleExport = ({ csv, fileName }) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const LABEL_PRIORITY_ORDER = [
  TIMEKEEPING_STATUSES.DISPUTED,
  TIMEKEEPING_STATUSES.PENDING,
  TIMEKEEPING_STATUSES.APPROVED,
  TIMEKEEPING_STATUSES.RESOLVED,
  TIMEKEEPING_STATUSES.FINALIZED,
];

export const generateCandidateHoursData = (timecard, selectedTimeCard) => {
  // A hashmap, key is date as string (ex. 11/26/2023) - value is array of timecards
  const daysData = timecard?.days;

  const days = Object.keys(daysData);
  const returnObj = {};
  // For every day in the week
  days.forEach((day) => {
    // If the day has any data (timecards), get the total numver of hours for that day. If no timecards exist, display a '-'
    returnObj[day] = daysData[day].length > 0 ? sumDayTotalTime(daysData[day]) : '-';
  });

  returnObj.status = {
    label: LABEL_PRIORITY_ORDER.find((label) =>
      Object.values(daysData)
        .flat(Infinity)
        .some((timecardEntry) => timecardEntry?.timecard?.status === label)
    ),
    detailViewStatus: !!selectedTimeCard,
  };

  return returnObj;
};

/**
 * Converts a list of timecards into an array of placments and can return an array of candidates with the hours they worked
 * @param {Array} timecards
 * @param {Boolean} shouldReturnCandidateHours
 * @returns object with properties placement (array of IDs) & nameAndHours (array of objects with candidate Name & hours worked)
 */
export const generateBatchApprovalData = (timecards, shouldReturnCandidateHours) => {
  const placementNameAndTime = [];
  const pendingTimecardsWithValidPunchSets = timecards
    .map((card) => {
      const entriesForCurrentUser = Object.values(card?.days)
        .flat(Infinity)
        .filter(
          (dayTimecardEntry) =>
            dayTimecardEntry?.timecard?.status === 'pending' &&
            dayTimecardEntry?.timecard?.punches.length > 0 &&
            dayTimecardEntry?.timecard?.punches.some((p) => p?.out?.stamp)
        );

      if (shouldReturnCandidateHours && entriesForCurrentUser.length > 0) {
        placementNameAndTime.push({
          id: card?.candidate?.id,
          values: {
            name: card?.candidate?.name,
            hours: entriesForCurrentUser.reduce(
              (accumulator, item) => accumulator + item.timecard.totalHours,
              0
            ),
          },
        });
      }
      return entriesForCurrentUser;
    })
    .flat(Infinity);

  const returnObject = {
    placements: pendingTimecardsWithValidPunchSets.map((p) => p?.placement),
    nameAndHours: placementNameAndTime,
  };

  return returnObject;
};
