import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCreateUser = (state) => get('users', state);

export const selectIsProcessingPostUser = createSelector(
  selectCreateUser,
  (users) => users.isProcessingPostUser
);
export const selectPostUserError = createSelector(selectCreateUser, (users) => users.postUserError);
