import BarlowBold700 from '../assets/fonts/Barlow-Bold.ttf';
import BarlowExtraBold from '../assets/fonts/Barlow-ExtraBold.ttf';
import BarlowMedium from '../assets/fonts/Barlow-Medium.ttf';
import BarlowRegular from '../assets/fonts/Barlow-Regular.ttf';
import BarlowSemiBold from '../assets/fonts/Barlow-SemiBold.ttf';
import IBMPlexSansRegular from '../assets/fonts/IBMPlexSans-Regular.ttf';
import Roboto100 from '../assets/fonts/Roboto-100.ttf';
import Roboto300 from '../assets/fonts/Roboto-300.ttf';
import Roboto400 from '../assets/fonts/Roboto-400.ttf';
import Roboto500 from '../assets/fonts/Roboto-500.ttf';
import Roboto700 from '../assets/fonts/Roboto-700.ttf';
import Roboto900 from '../assets/fonts/Roboto-900.ttf';
import TrainOneRegular from '../assets/fonts/TrainOne-Regular.ttf';

const fonts = [
  `
  @font-face {
    font-family: 'Train One';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Train One'), local('Train One-Regular'), url(${TrainOneRegular}) format('truetype');
    unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  }
`,
  `
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('IBM Plex Sans'), local('IBM Plex Sans-Regular'), url(${IBMPlexSansRegular}) format('truetype');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
`,
  `
@font-face {
font-family: 'Barlow';
font-style: normal;
font-display: swap;
font-weight: 400;
src: local('Barlow'), local('Barlow-Regular'), url(${BarlowRegular}) format('truetype');
unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
`,
  `
@font-face {
font-family: 'Barlow-700';
font-style: normal;
font-display: swap;
font-weight: 700;
src: local('Barlow'), local('Barlow-Bold'), url(${BarlowBold700}) format('truetype');
unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
`,
  `
@font-face {
font-family: 'Barlow-800';
font-style: normal;
font-display: swap;
font-weight: 800;
src: local('Barlow'), local('Barlow-ExtraBold'), url(${BarlowExtraBold}) format('truetype');
unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
`,

  `
@font-face {
  font-family: 'Barlow-600';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Barlow'), local('Barlow-SemiBold'), url(${BarlowSemiBold}) format('truetype');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
  `,
  `
@font-face {
  font-family: 'Barlow-500';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Barlow'), local('Barlow-Medium'), url(${BarlowMedium}) format('truetype');
  unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
`,
  `
@font-face {
  font-family: 'Roboto-100';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(${Roboto100}) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`,
  `
@font-face {
  font-family: 'Roboto-400';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${Roboto400}) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`,
  `
@font-face {
font-family: 'Roboto-300';
  font-style: normal;
  font-display: swap;
  src: url(${Roboto300}) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`,
  `
@font-face {
font-family: 'Roboto-500';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(${Roboto500}) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

}
`,
  `
@font-face {
font-family: 'Roboto-700';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(${Roboto700}) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`,
  `
@font-face {
font-family: 'Roboto-900';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url(${Roboto900}) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
`,
];

export default fonts;
