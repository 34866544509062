import React, { useState } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Paper, Popper } from '@mui/material';

import theme from '../../theme/theme';
import Button, { BUTTON_VARIANT } from '../Button';

import Filters from './Filters';

const PopOverFilter = ({
  leftFilter, // TODO: remove if possibel
  filterDefinitions,
  onFilterChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const paperSx = {
    padding: theme.spacing(3),
    display: 'flex',
    backgroundColor: get(['popoverFilter', 'bgColor'], theme),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const fullWidthSx = {
    width: '100%',
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Button onClick={handleClick} variant={BUTTON_VARIANT.OUTLINED}>
          {LanguageConverter('buttonText.filters')}
        </Button>
        <Popper anchorEl={anchorEl} keepMounted open={open} placement="bottom-end">
          <Paper sx={paperSx}>
            {leftFilter}
            <Filters
              filterDefinitions={filterDefinitions}
              onFilterChange={onFilterChange}
              wrapperSx={fullWidthSx}
            />
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

PopOverFilter.propTypes = {
  leftFilter: PropTypes.element,
  filterDefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  onFilterChange: PropTypes.func,
};

export default PopOverFilter;
