import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectNotificationsState = (state) => get('notifications', state);

export const selectNotifications = createSelector(
  selectNotificationsState,
  (notifications) => notifications.notifications
);
export const selectHasUnreadNotifications = createSelector(
  selectNotificationsState,
  (notifications) => notifications.hasUnreadNotifications
);
export const selectIsLoadingNotifications = createSelector(
  selectNotificationsState,
  (notifications) => notifications.isLoadingNotifications
);
