const PRIMARY_PURPLE = {
  10: '#F8F4FF',
  20: '#F2E9FF',
  25: '#F5EEFF',
  30: '#E4D3FF',
  40: '#D7BDFF',
  50: '#AF7BFF',
  60: '#934DFF',
  70: '#7A23FF',
  80: '#621CCC',
  90: '#491599',
  100: '#310E66',
};
const PRIMARY_COLOR = {
  ...PRIMARY_PURPLE,
};
const ACTION_BLUE = {
  ACTION_BLUE: '#0073E5',
  ACTION_BLUE_REST: '#44A1FF',
  ACTION_CONTRAST_BLUE: '#44A1FF',
  ACTION_CONTRAST_REST: '#8AC4FF',
  ACTION_BLUE_HOVER: '#0073E5',
  DARK_BLUE: '#7772C8',
  INDIGO_BLUE: '#6C63FF',
};

const FEED_BACK = {
  GREEN_10: '#AAFFBD',
  GREEN_50: '#24D14A',
  GREEN_70: '#1DA83B',
  GREEN_80: '#098023',
  GREEN_90: '#EBFFEF',
  GREEN_100: '#005210',
  YELLOW_50: '#F9D247',
  YELLOW_80: '#B39733',
  YELLOW_100: '#66561D',
  ORANGE: '#FF9A48',
  ORANGE_90: '#FFF1E6',
  RED_10: '#FBB8A9',
  RED_50: '#F64E29',
  RED_100: '#9A2207',
};

const BLACK = {
  0: '#FFFFFF',
  10: '#F7F7F9',
  20: '#F2F2F5',
  30: '#E6E6EB',
  40: '#CCCCD6',
  50: '#B3B3C2',
  60: '#9999AD',
  70: '#4D4D70',
  80: '#333357',
  90: '#1A1A47',
  100: '#000033',
};

const RED = {
  50: '#E75353',
};

export { ACTION_BLUE, BLACK, FEED_BACK, PRIMARY_COLOR, PRIMARY_PURPLE, RED };
