import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Drawer from '@mui/material/Drawer';

import {
  formFieldData,
  readOnlyValues,
} from '../../../main-deprecated/components/TimeKeepingEditDrawer';
import { epochToTimeInReadableFormat } from '../../utils';
import WorkFlow from '../WorkFlow-deprecated';

const TimekeepingEditDrawer = ({
  isDrawerOpen,
  initialData,
  onSubmit,
  onClose,
  timecardIsUpdating,
  errorMessage,
}) => {
  const formValues = {
    ...readOnlyValues,
    ...initialData,
    shiftTime: `${epochToTimeInReadableFormat(initialData?.start)} - ${epochToTimeInReadableFormat(
      initialData?.end
    )}`,
    punches:
      initialData?.punches?.length > 0
        ? initialData.punches
        : [{ in: { stamp: initialData?.start }, out: { stamp: initialData?.end } }],
  };

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '576px',
        },
      }}
    >
      <WorkFlow
        confirmationPageButtonText={LanguageConverter('button.return.timekeeping')}
        formSpec={formFieldData}
        attemptToHandleAPICall={onSubmit}
        onClose={onClose}
        readOnlyValues={formValues}
        isApiCallProcessing={timecardIsUpdating}
        processingMessage="message.generic.updating"
        successMessage="success.timecard.edit"
        errorMessage={errorMessage}
      />
    </Drawer>
  );
};

TimekeepingEditDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool,
  initialData: PropTypes.shape({
    corporation: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    punches: PropTypes.arrayOf(PropTypes.shape({})),
    corporationName: PropTypes.string,
    shiftName: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  timecardIsUpdating: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default TimekeepingEditDrawer;
