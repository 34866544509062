import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import { errorHandler } from './formUtils';

const FormRadioButtons = ({
  fieldErrorData,
  initialValue,
  input,
  labels,
  meta,
  required,
  size,
  sx,
  title,
  values,
  variant,
}) => {
  const error = errorHandler(input, meta, fieldErrorData);

  return (
    <FormControl error={!!error} sx={sx} variant={variant}>
      <FormLabel>{title && title}</FormLabel>
      <RadioGroup
        defaultValue={initialValue}
        name={input.name}
        onChange={input.onChange}
        value={input.value}
        type="radio"
      >
        {labels &&
          labels.map((label, index) => {
            const linkValue = values[index];
            const name = get(['name'], label);
            const disabled = get(['disabled'], label);
            return (
              <FormControlLabel
                control={<Radio required={required} />}
                key={name} // need to change key to another value
                label={name}
                size={size}
                value={linkValue}
                disabled={disabled}
              />
            );
          })}
      </RadioGroup>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

FormRadioButtons.propTypes = {
  fieldErrorData: PropTypes.string,
  initialValue: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  labels: PropTypes.arrayOf(PropTypes.shape({})),
  meta: PropTypes.shape(),
  required: PropTypes.bool,
  size: PropTypes.string, // default medium
  sx: PropTypes.shape({}),
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.string,
};

export default FormRadioButtons;
