import { get } from 'lodash/fp';

import FormPhoneField from '../../../../../components/Form/FormPhoneField';
import FormRadioChips from '../../../../../components/Form/FormRadioChips';
import FormSelectDropdownField from '../../../../../components/Form/FormSelectDropdownField';
import FormTextField from '../../../../../components/Form/FormTextField';
import FormTypographyBox from '../../../../../components/Form/FormTypographyBox';
import {
  isValidEmail,
  isValidPersonName,
  isValidPhoneNumber,
} from '../../../../../components/Form/validations';
import SearchBox, { SEARCHBAR_BACKGROUND } from '../../../../../components/SearchBar';
import theme from '../../../../../theme';
import { PRIMARY_FONT } from '../../../../../theme/fontConstants';

const componentWorkFlowFont = ['components', 'workflow', 'typography'];
const workFlowHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'defaultTitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  color: get([...componentWorkFlowFont, 'defaultLastPageFontColor', 'default'], theme),
};
const workFlowSubHeaderStyling = {
  fontSize: get([...componentWorkFlowFont, 'typography', 'defaultSubtitleFontSize'], theme),
  fontFamily: get([...componentWorkFlowFont, 'typography', 'defaultFont'], theme),
  color: '#333357',
  margin: theme.spacing(-1, 0, 0),
};

const FormHeaderStyle = {
  fontSize: '18px',
  fontFamily: PRIMARY_FONT[700],
  color: '#000033',
};
const formSubHeaderStyle = {
  fontSize: '16px',
  fontFamily: PRIMARY_FONT[400],
  color: '#333357',
  margin: theme.spacing(-1, 0, 0),
};

export const isRadioChipEmpty = (value) =>
  value && value.length > 0 ? undefined : 'One industry must be selected.';

export const formFieldDataForAdmin = () => [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'adminWorkFlowHeader',
      name: 'adminWorkFlowHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'adminWorkFlowSubHeader',
      name: 'adminWorkFlowSubHeader',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'fullNameHeader',
      name: 'fullNameHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'fullNameSubHeader',
      name: 'fullNameSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormTextField,
      label: `Full name`,
      name: 'name',
      required: true,
      fullWidth: true,
      placeholder: `Enter Full Name`,
      multiline: false,
      validate: isValidPersonName,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'emailAddressHeader',
      name: 'emailAddressHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'emailAddressSubHeader',
      name: 'emailAddressSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormTextField,
      label: `Email Address`,
      name: 'email',
      required: true,
      fullWidth: true,
      placeholder: `Enter Email Address`,
      multiline: false,
      validate: isValidEmail,
    },
    // FIXME: Phone number to be re-added once user schema supports phone number
    // {
    //   ComponentType: FormTypographyBox,
    //   key: 'phoneNumberHeader',
    //   name: 'phoneNumberHeader',
    //   sx: {
    //     fontSize: '18px',
    //     fontFamily: PRIMARY_FONT[700],
    //     color: '#000033',
    //   },
    // },
    // {
    //   ComponentType: FormTypographyBox,
    //   key: 'phoneNumberSubHeader',
    //   name: 'phoneNumberSubHeader',
    //   sx: {
    //     fontSize: '16px',
    //     fontFamily: PRIMARY_FONT[400],
    //     color: '#333357',
    //     marginTop: '-20px',
    //     marginBottom: '10px',
    //   },
    // },
    // {
    //   ComponentType: FormTextField,
    //   label: `Phone Number`,
    //   name: 'number',
    //   required: true,
    //   fullWidth: true,
    //   placeholder: `Enter Phone Number`,
    //   multiline: false,
    //   validate: isValidPhoneNumber,
    // },
  ],
];

export const formFieldDataForRecruiter = () => [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'recruiterWorkFlowHeader',
      name: 'recruiterWorkFlowHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'recruiterWorkFlowSubHeader',
      name: 'recruiterWorkFlowSubHeader',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'fullNameHeader',
      name: 'fullNameHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'fullNameSubHeader',
      name: 'fullNameSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormTextField,
      label: `Full name`,
      name: 'name',
      required: true,
      fullWidth: true,
      placeholder: `Enter Full Name`,
      multiline: false,
      validate: isValidPersonName,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'emailAddressHeader',
      name: 'emailAddressHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'emailAddressSubHeader',
      name: 'emailAddressSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormTextField,
      label: `Email Address`,
      name: 'email',
      required: true,
      fullWidth: true,
      placeholder: `Enter Email Address`,
      multiline: false,
      validate: isValidEmail,
    },
    // FIXME: Phone number to be re-added once user schema supports phone number
    // {
    //   ComponentType: FormTypographyBox,
    //   key: 'phoneNumberHeader',
    //   name: 'phoneNumberHeader',
    //   sx: {
    //     fontSize: '18px',
    //     fontFamily: PRIMARY_FONT[700],
    //     color: '#000033',
    //   },
    // },
    // {
    //   ComponentType: FormTypographyBox,
    //   key: 'phoneNumberSubHeader',
    //   name: 'phoneNumberSubHeader',
    //   sx: {
    //     fontSize: '16px',
    //     fontFamily: PRIMARY_FONT[400],
    //     color: '#333357',
    //     marginTop: '-20px',
    //     marginBottom: '10px',
    //   },
    // },
    // {
    //   ComponentType: FormTextField,
    //   label: `Phone Number`,
    //   name: 'number',
    //   required: true,
    //   fullWidth: true,
    //   placeholder: `Enter Phone Number`,
    //   multiline: false,
    //   validate: isValidPhoneNumber,
    // },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationHeader',
      name: 'corporationHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationSubHeader',
      name: 'corporationSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      preDefinedOptions: [],
      data: null,
      key: 'id',
      name: 'corporations',
      multiSelect: true,
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Enter Corporations',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'corporations/read',
      },
      optionDisplayField: 'name',
    },
  ],
];
export const formFieldDataForEmployer = () => [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'employerWorkFlowHeader',
      name: 'employerWorkFlowHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'employerWorkFlowSubHeader',
      name: 'employerWorkFlowSubHeader',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'fullNameHeader',
      name: 'fullNameHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'fullNameSubHeader',
      name: 'fullNameSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormTextField,
      label: `Full name`,
      name: 'name',
      required: true,
      fullWidth: true,
      placeholder: `Enter Full Name`,
      multiline: false,
      validate: isValidPersonName,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'emailAddressHeader',
      name: 'emailAddressHeader',
      sx: {
        fontSize: '18px',
        fontFamily: 'Barlow-700',
        color: '#000033',
        marginTop: '20px',
      },
    },
    {
      ComponentType: FormTypographyBox,
      key: 'emailAddressSubHeader',
      name: 'emailAddressSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormTextField,
      label: `Email Address`,
      name: 'email',
      required: true,
      fullWidth: true,
      placeholder: `Enter Email Address`,
      multiline: false,
      validate: isValidEmail,
    },
    // FIXME: Phone number to be re-added once user schema supports phone number
    // {
    //   ComponentType: FormTypographyBox,
    //   key: 'phoneNumberHeader',
    //   name: 'phoneNumberHeader',
    //   sx: {
    //     fontSize: '18px',
    //     fontFamily: PRIMARY_FONT[700],
    //     color: '#000033',
    //   },
    // },
    // {
    //   ComponentType: FormTypographyBox,
    //   key: 'phoneNumberSubHeader',
    //   name: 'phoneNumberSubHeader',
    //   sx: {
    //     fontSize: '16px',
    //     fontFamily: PRIMARY_FONT[400],
    //     color: '#333357',
    //     marginTop: '-20px',
    //     marginBottom: '10px',
    //   },
    // },
    // {
    //   ComponentType: FormTextField,
    //   label: `Phone Number`,
    //   name: 'number',
    //   required: true,
    //   fullWidth: true,
    //   placeholder: `Enter Phone Number`,
    //   multiline: false,
    //   validate: isValidPhoneNumber,
    // },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationHeader',
      name: 'corporationHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'corporationSubHeader',
      name: 'corporationSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: SearchBox,
      background: SEARCHBAR_BACKGROUND.LIGHT,
      preDefinedOptions: [],
      data: null,
      key: 'id',
      name: 'corporations',
      multiSelect: false,
      required: true,
      variant: 'standard',
      optionLabel: 'name',
      placeholder: 'Enter Corporations',
      fullWidth: true,
      onSubmitApiCallData: {
        httpMethod: 'POST',
        route: 'corporations/read',
      },
      optionDisplayField: 'name',
    },
  ],
];

export const formFieldDataForCandidates = [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'candidateWorkFlowHeader',
      name: 'candidateWorkFlowHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'candidateWorkFlowSubHeader',
      name: 'candidateWorkFlowSubHeader',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'fullNameHeader',
      name: 'fullNameHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'fullNameSubHeader',
      name: 'fullNameSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormTextField,
      label: `Full name`,
      name: 'name',
      required: true,
      fullWidth: true,
      placeholder: `Enter Full Name`,
      multiline: false,
      validate: isValidPersonName,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'emailAddressHeader',
      name: 'emailAddressHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'emailAddressSubHeader',
      name: 'emailAddressSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormTextField,
      label: `Email Address`,
      name: 'email',
      required: true,
      fullWidth: true,
      placeholder: `Enter Email Address`,
      multiline: false,
      validate: isValidEmail,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'phoneNumberHeader',
      name: 'phoneNumberHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'phoneNumberSubHeader',
      name: 'phoneNumberSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormPhoneField,
      label: `Phone Number`,
      name: 'phoneNumber',
      required: true,
      fullWidth: true,
      placeholder: `Enter Phone Number`,
      multiline: false,
      validate: isValidPhoneNumber,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'industryHeader',
      name: 'industryHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'industrySubHeader',
      name: 'industrySubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormRadioChips,
      name: 'industry',
      multiSelect: true,
      data: ['healthcare', 'manufacturing', 'hospitality'],
      validate: isRadioChipEmpty,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'distanceHeader',
      name: 'distanceHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'distanceSubHeader',
      name: 'distanceSubHeader',
      sx: formSubHeaderStyle,
    },
    {
      ComponentType: FormSelectDropdownField,
      name: 'distance',
      required: true,
      readOnlyFormat: (value) => get('name', value),
      sx: { width: '120px', height: '48px' },
      options: [
        { id: '5 Miles', name: '5 Miles' },
        { id: '10 Miles', name: '10 Miles' },
        { id: '15 Miles', name: '15 Miles' },
        { id: '20 Miles', name: '20 Miles' },
        { id: '25 Miles', name: '25 Miles' },
        { id: '30 Miles', name: '30 Miles' },
        { id: '35 Miles', name: '35 Miles' },
        { id: '40 Miles', name: '40 Miles' },
        { id: '45 Miles', name: '45 Miles' },
        { id: '50 Miles', name: '50 Miles' },
      ],
      fullWidth: true,
    },
  ],
];

export const formFieldDataForBullHorn = [
  [
    {
      ComponentType: FormTypographyBox,
      key: 'bullhornWorkFlowHeader',
      name: 'bullhornWorkFlowHeader',
      sx: workFlowHeaderStyling,
    },
    {
      ComponentType: FormTypographyBox,
      key: 'bullhornWorkFlowSubHeader',
      name: 'bullhornWorkFlowSubHeader',
      sx: workFlowSubHeaderStyling,
    },

    {
      ComponentType: FormTypographyBox,
      key: 'bullhornIDHeader',
      name: 'bullhornIDHeader',
      sx: FormHeaderStyle,
    },
    {
      ComponentType: FormTextField,
      label: `Bullhorn ID`,
      name: 'bullhornID',
      required: true,
      fullWidth: true,
      placeholder: `Enter Bullhorn ID`,
      multiline: false,
    },
  ],
];

export const readOnlyValues = {
  adminWorkFlowHeader: 'New Admin',
  adminWorkFlowSubHeader: 'Complete the fields below to create a new admin account.',
  bullhornWorkFlowHeader: 'Import a user from Bullhorn?',
  bullhornWorkFlowSubHeader:
    'To import a user into the WAE system, please enter an existing user’s Bullhorn ID below.',
  bullhornIDHeader: 'Bullhorn ID',
  candidateWorkFlowHeader: 'New Employee',
  candidateWorkFlowSubHeader: 'Complete the fields below to create a new employee account.',
  corporationHeader: 'Corporation(s)',
  corporationSubHeader:
    'This will determine which corporation(s) an employer will see when logged in to WAE.',
  distanceHeader: 'What is your preferred job distance?',
  distanceSubHeader: 'How to set a users’ preferred distance from job.',
  emailAddressHeader: 'Email Address',
  emailAddressSubHeader: 'How a user will log in to WAE.',
  employerWorkFlowHeader: 'New Employer',
  employerWorkFlowSubHeader: 'Complete the fields below to create a new employer account.',
  fullNameHeader: 'Full Name',
  fullNameSubHeader: 'Full name of the user.',
  industryHeader: 'Select the industries that apply',
  industrySubHeader: 'How to set a users’ industry preference when it comes to jobs.',
  phoneNumberHeader: 'Phone Number',
  phoneNumberSubHeader: 'Primary contact method for a user.',
  recruiterWorkFlowHeader: 'New Recruiter',
  recruiterWorkFlowSubHeader: 'Complete the fields below to create a new recruiter account.',
};
