import React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { BUTTON_TYPE, BUTTON_VARIANT } from '../../../components/Button';
import FormPasswordField from '../../../components/Form/FormPasswordField';
import FormTextField from '../../../components/Form/FormTextField';
import {
  composeValidators,
  containsASpace,
  isValidEmail,
} from '../../../components/Form/validations';
import theme from '../../../theme';

const buildButtonData = (isLoading, navigate) => [
  {
    disabled: !!isLoading,
    fullWidth: true,
    showIndeterminateProgress: !!isLoading,
    text: 'Log in',
    type: BUTTON_TYPE.SUBMIT,
    endIcon: <ArrowForwardIcon />,
    xs: 12,
  },
  {
    disabled: !!isLoading,
    fullWidth: true,
    onClick: () => navigate('forgotMyPassword'),
    sx: {
      color: theme.button.palette.secondary,
    },
    text: 'Forgot Password',
    variant: BUTTON_VARIANT.TEXT,
    xs: 12,
  },

  {
    disabled: true, // ALWAYS disabled, but kept the content for future use
    fullWidth: true,
    onClick: () => navigate('register'),
    sx: {
      color: theme.button.palette.secondary,
    },
    text: 'Create an Account',
    variant: BUTTON_VARIANT.TEXT,
    xs: 12,
  },
];

const formFieldData = {
  email: {
    ComponentType: FormTextField,
    fullWidth: true,
    label: 'Email',
    name: 'email',
    required: true,
    mode: 'extraDark',
    validate: composeValidators(containsASpace, isValidEmail),
  },
  password: {
    ComponentType: FormPasswordField,
    fullWidth: true,
    label: 'Password',
    name: 'password',
    required: true,
    type: 'password',
    mode: 'extraDark',
  },
};

export { buildButtonData, formFieldData };
