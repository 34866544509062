import React from 'react';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import searchParamOptions from '@careerstart/wae-common/src/main/constants/searchParams';

import { buildSearchTerm } from '../../../utils';
import WaeButton from '../../Button';

const NotificationButton = ({ notification, closeNotificationCenter }) => {
  const notificationType = get('notificationType', notification);
  const navigate = useNavigate();

  let pathname = '';
  let searchParams = [];
  let buttonText = '';

  switch (notificationType) {
    case 'positionTemplateApproval':
      buttonText = 'View Position Template';
      pathname = 'jobs/positionTemplateList';
      searchParams = [
        {
          paramOption: searchParamOptions.POSITION,
          paramValue: notification?.info?.positionTemplate,
        },
      ];
      break;

    case 'jobPostSuccess':
      buttonText = 'View Job';
      pathname = 'jobs/jobsList';
      searchParams = [
        {
          paramOption: searchParamOptions.JOB_ORDER,
          paramValue: notification?.info?.jobOrder,
        },
      ];
      break;
    case 'rosterFilled':
      buttonText = 'View Job';
      pathname = 'jobs/jobsList';
      searchParams = [
        {
          paramOption: searchParamOptions.JOB_ORDER,
          paramValue: notification?.info?.jobOrder,
        },
      ];
      break;
    case 'jobStarted':
      buttonText = 'View Job';
      pathname = 'jobs/jobsList';
      searchParams = [
        {
          paramOption: searchParamOptions.JOB_ORDER,
          paramValue: notification?.info?.jobOrder,
        },
      ];
      break;
    case 'timeSheetReady':
      buttonText = 'View Timesheet';
      pathname = 'timekeeping';
      searchParams = [
        {
          paramOption: searchParamOptions.DATE,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.START,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.CANDIDATE,
          paramValue: notification?.info?.user,
        },
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;
    case 'employeeDispute':
      buttonText = 'View Timesheet';
      pathname = 'timekeeping';
      searchParams = [
        {
          paramOption: searchParamOptions.DATE,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.START,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.CANDIDATE,
          paramValue: notification?.info?.user,
        },
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;
    case 'billSubmitted':
      buttonText = 'View Timesheet';
      pathname = 'timekeeping';
      searchParams = [
        {
          paramOption: searchParamOptions.DATE,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.START,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.CANDIDATE,
          paramValue: notification?.info?.user,
        },
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;

    // admin
    case 'positionTemplateNeedsReview':
      buttonText = 'View Position Template';
      pathname = 'jobs/positionTemplateList';
      searchParams = [
        {
          paramOption: searchParamOptions.POSITION,
          paramValue: notification?.info?.positionTemplate,
        },
      ];
      break;

    case 'timecardDisputed':
      buttonText = 'View Timesheet';
      pathname = 'timekeeping';
      searchParams = [
        {
          paramOption: searchParamOptions.DATE,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.START,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.CANDIDATE,
          paramValue: notification?.info?.user,
        },
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;

    case 'jobFilled':
      buttonText = 'View Job';
      pathname = 'jobs/jobsList';
      searchParams = [
        {
          paramOption: searchParamOptions.JOB_ORDER,
          paramValue: notification?.info?.jobOrder,
        },
      ];
      break;

    case 'retroactivePlacementRequest':
      buttonText = 'View Request';
      pathname = 'jobs/jobsList';
      searchParams = [
        {
          paramOption: searchParamOptions.JOB_ORDER,
          paramValue: notification?.info?.jobOrder,
        },
      ];
      break;

    // employee
    case 'jobOrderInvite':
      if (notification?.info?.inviteStatus === 'expired') {
        buttonText = null;
        pathname = null;
        searchParams = [];
        break;
      }
      buttonText = 'View Job';
      pathname = 'jobs/jobsList';
      searchParams = [
        {
          paramOption: searchParamOptions.JOB_ORDER,
          paramValue: notification?.info?.jobOrder,
        },
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
        {
          paramOption: searchParamOptions.INVITED,
          paramValue: 'true',
        },
      ];
      break;

    case 'newJobAssigned':
      buttonText = 'View Job';
      pathname = 'jobs/myJobs';
      searchParams = [
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;

    case 'preClockInRemind':
      buttonText = 'Go to Dashboard';
      pathname = 'dashboard';
      searchParams = [];
      break;

    case 'clockInRemind':
      buttonText = 'Go to Dashboard';
      pathname = 'dashboard';
      searchParams = [];
      break;

    case 'clockOutRemind':
      buttonText = 'Go to Dashboard';
      pathname = 'dashboard';
      searchParams = [];
      break;

    case 'timecardEdited':
      buttonText = 'View Timecard';
      pathname = 'timekeeping';
      searchParams = [
        {
          paramOption: searchParamOptions.START,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;

    case 'timeCardApproved':
      buttonText = 'View Timecard';
      pathname = 'timekeeping';
      searchParams = [
        {
          paramOption: searchParamOptions.START,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;

    case 'timeCardDisputeSubmitted':
      buttonText = 'View Timecard';
      pathname = 'timekeeping';
      searchParams = [
        {
          paramOption: searchParamOptions.START,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;

    case 'timeCardDisputeResolved':
      buttonText = 'View Timecard';
      pathname = 'timekeeping';
      searchParams = [
        {
          paramOption: searchParamOptions.START,
          paramValue: notification?.info?.jobOrderStart,
        },
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;

    case 'shiftCancellation':
      return null;

    case 'placementApplicationApproved':
      buttonText = 'View Job';
      pathname = 'jobs/myJobs';
      searchParams = [
        {
          paramOption: searchParamOptions.PLACEMENT,
          paramValue: notification?.info?.placement,
        },
      ];
      break;

    case 'placementApplicationCreated':
      buttonText = 'View Job';
      pathname = 'jobs/jobsList';
      searchParams = [
        {
          paramOption: searchParamOptions.JOB_ORDER,
          paramValue: notification?.info?.jobOrder,
        },
      ];
      break;

    case 'placementCancelled':
      return null;
    case 'jobOrderCancelled':
      buttonText = 'View Job';
      pathname = 'jobs/jobsList';
      searchParams = [
        {
          paramOption: searchParamOptions.JOB_ORDER,
          paramValue: notification?.info?.jobOrder,
        },
      ];
      break;

    default:
      return null;
  }

  const onClick = () => {
    navigate({
      pathname,
      search: buildSearchTerm(searchParams),
    });
    closeNotificationCenter();
  };

  return buttonText && pathname && <WaeButton onClick={() => onClick()}>{buttonText}</WaeButton>;
};

NotificationButton.propTypes = {
  notification: PropTypes.shape({
    info: PropTypes.shape({
      placement: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
      jobOrder: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
      jobOrderStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      positionTemplate: PropTypes.string,
      candidateName: PropTypes.string,
      inviteStatus: PropTypes.string,
      user: PropTypes.string,
    }),
  }),
  closeNotificationCenter: PropTypes.func,
};

export default NotificationButton;
