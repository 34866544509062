export const errorHandler = (input, meta, fieldErrorData) =>
  meta.error ||
  (meta.submitError && !meta.modifiedSinceLastSubmit && meta.submitError) ||
  (!meta.modifiedSinceLastSubmit && fieldErrorData);

export const hasFieldError = (meta, input) => meta.initial !== input.value && meta.error;
export const hasServerError = (meta, fieldErrorData) =>
  !meta.modifiedSinceLastSubmit && fieldErrorData;
export const hasFormSubmitError = (meta) =>
  meta.submitError && !meta.modifiedSinceLastSubmit && meta.submitError;

export const hasAnyFieldErrors = (errorData = {}) =>
  !!Object.keys(errorData).filter((key) => key !== 'GLOBAL').length;
