import { get } from 'lodash/fp';

import FormMapDisplayWrapper from '../../../../../components/Form/FormMapWrapper';
import FormTypographyBox from '../../../../../components/Form/FormTypographyBox';
import theme from '../../../../../theme';

const detailViewTypographyHeader = ['components', 'detailView', 'typography', 'header'];
const detailViewTypographySectionHeader = [
  'components',
  'detailView',
  'typography',
  'sectionHeader',
];
const detailViewTypographyLocationText = ['components', 'detailView', 'typography', 'locationText'];

const detailViewTypographySemiBoldText = ['components', 'detailView', 'typography', 'semiBoldText'];

const detailViewTypographySubSectionHeader = [
  'components',
  'detailView',
  'typography',
  'subSectionHeader',
];

const FormFieldDataOverview = () => [
  {
    ComponentType: FormTypographyBox,
    key: 'CompanyName',
    name: 'name',
    sx: {
      fontSize: get([...detailViewTypographyHeader, 'fontSize'], theme),
      fontFamily: get([...detailViewTypographyHeader, 'fontFamily'], theme),
      color: get([...detailViewTypographyHeader, 'fontColor'], theme),
    },
  },
  {
    ComponentType: FormTypographyBox,
    key: 'companyLocation',
    name: 'mailingAddress',
    sx: {
      fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
      color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
      fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
    },
  },

  {
    ComponentType: FormMapDisplayWrapper,
    key: 'companyMap',
    name: 'geoLoc',
    sx: {
      fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
      color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
      fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
      width: '100%',

      borderRadius: '15px',
      height: '400px',
    },
  },
  {
    ComponentType: FormTypographyBox,
    key: 'otherLocationsHeader',
    name: 'otherLocationsHeader',
    sx: {
      fontSize: get([...detailViewTypographySectionHeader, 'fontSize'], theme),
      fontFamily: get([...detailViewTypographySectionHeader, 'fontFamily'], theme),
      color: get([...detailViewTypographySectionHeader, 'fontColor'], theme),
    },
  },
  {
    ComponentType: FormTypographyBox,
    key: 'otherLocations',
    name: 'otherLocations',
    sx: {
      fontSize: get([...detailViewTypographyLocationText, 'fontSize'], theme),
      fontFamily: get([...detailViewTypographyLocationText, 'fontFamily'], theme),
      color: get([...detailViewTypographyLocationText, 'fontColor'], theme),
      backgroundColor: 'black',
      borderRadius: '16px',
    },
  },
  {
    ComponentType: FormTypographyBox,
    key: 'additionalDetailsHeader',
    name: 'additionalDetailsHeader',
    sx: {
      fontSize: get([...detailViewTypographySectionHeader, 'fontSize'], theme),
      fontFamily: get([...detailViewTypographySectionHeader, 'fontFamily'], theme),
      color: get([...detailViewTypographySectionHeader, 'fontColor'], theme),
    },
  },
  {
    ComponentType: FormTypographyBox,
    key: 'externalIdHeader',
    name: 'externalIdHeader',
    sx: {
      fontSize: get([...detailViewTypographySubSectionHeader, 'fontSize'], theme),
      fontFamily: get([...detailViewTypographySubSectionHeader, 'fontFamily'], theme),
      color: get([...detailViewTypographySubSectionHeader, 'fontColor'], theme),
    },
  },
  {
    ComponentType: FormTypographyBox,
    key: 'externalId',
    name: 'externalId',
    sx: {
      fontSize: get([...detailViewTypographySemiBoldText, 'fontSize'], theme),
      fontFamily: get([...detailViewTypographySemiBoldText, 'fontFamily'], theme),
      color: get([...detailViewTypographySemiBoldText, 'fontColor'], theme),
    },
  },
];
export default FormFieldDataOverview;
