import * as React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import theme from '../../../theme';

const CandidateJobTypeTab = ({ initVal }) => {
  const [value, setValue] = React.useState(initVal);
  const allJobsUrl = '/jobs/jobsList';
  const myJobsUrl = '/jobs/myJobs';
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 'allJobs') {
      navigate(allJobsUrl);
    }
    if (newValue === 'myJobs') {
      navigate(myJobsUrl);
    }
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      TabIndicatorProps={{ style: { background: theme.dataGrid.bgColor.tabIndicatorColor } }}
    >
      <Tab
        value="allJobs"
        label="All Jobs"
        style={{ textTransform: 'none' }}
        sx={{
          '&.Mui-selected': {
            color: `${theme.palette.text.primary}`,
          },
          color: `${theme.palette.subtext.dark}`,
          fontFamily: `${theme.tab.default.boldFontFamily}`,
          fontSize: `${theme.tab.default.boldFontSize}`,
        }}
      />
      <Tab
        style={{ textTransform: 'none' }}
        value="myJobs"
        label="My Jobs"
        sx={{
          '&.Mui-selected': {
            color: `${theme.palette.text.primary}`,
          },
          color: `${theme.palette.subtext.dark}`,
          fontFamily: `${theme.tab.default.boldFontFamily}`,
          fontSize: `${theme.tab.default.boldFontSize}`,
        }}
      />
    </Tabs>
  );
};

CandidateJobTypeTab.propTypes = {
  initVal: PropTypes.string,
};

export default CandidateJobTypeTab;
