import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@mui/material';

import theme from '../../../theme';
import Filters from '../../Filters/Filter';
import FreeTextSearchFilter from '../../FreeTextSearchFilter';

const rightFilterDefinitions = [
  {
    customFilter: {
      field: 'name',
      operation: 'icontains',
      placeholder: 'Name',
      type: FreeTextSearchFilter,
    },
  },
];

const EmployeeInviteSelectionFilter = ({ onRightFilterChange }) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: theme.dataGridFiltersHeight,
      padding: theme.spacing(1, 0, 1),
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Grid
        container
        justifyContent="flex-end"
        sx={{
          minWidth: 'fit-content',
          maxWidth: 'max-content',

          height: theme.dataGridFiltersHeight,
        }}
      >
        <Filters filterDefinitions={rightFilterDefinitions} onFilterChange={onRightFilterChange} />
      </Grid>
    </div>
  </Box>
);
EmployeeInviteSelectionFilter.propTypes = {
  onRightFilterChange: PropTypes.func,
};

export default EmployeeInviteSelectionFilter;
