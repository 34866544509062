import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';

import firstTraining from '../../../../assets/firstTraining.svg';
import TrainingTextDescription from '../trainingComponents/TrainingTextDescription';

const FirstTimeEnterpriseTraining = () => [
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.enterprise.firstTraining.welcome.title'),
      text: LanguageConverter('training.enterprise.firstTraining.welcome.content'),
    },
    ContentType: TrainingTextDescription,
  },
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.enterprise.firstTraining.postJob.title'),
      text: LanguageConverter('training.enterprise.firstTraining.postJob.content'),
    },
    ContentType: TrainingTextDescription,
  },
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.enterprise.firstTraining.reviewRoster.title'),
      text: LanguageConverter('training.enterprise.firstTraining.reviewRoster.content'),
    },
    ContentType: TrainingTextDescription,
  },
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.enterprise.firstTraining.approveTimecards.title'),
      text: LanguageConverter('training.enterprise.firstTraining.approveTimecards.content'),
    },
    ContentType: TrainingTextDescription,
  },
  {
    image: firstTraining,
    contentProps: {
      title: LanguageConverter('training.firstTraining.support.title'),
      text: LanguageConverter('training.firstTraining.support.content'),
    },
    ContentType: TrainingTextDescription,
  },
];

export default FirstTimeEnterpriseTraining;
