import FormPasswordField from '../../../components/Form/FormPasswordField';
import {
  composeValidators,
  containAtLeastOneCapitalLetter,
  containAtMinimumTwoLowerCaseLetters,
  containAtMinimumTwoNumbers,
  containsAtMinimumOneSpecialCharacter,
  isAtMinimumEightCharactersLong,
} from '../../../components/Form/validations';

const formFieldData = [
  {
    ComponentType: FormPasswordField,
    fullWidth: true,
    label: 'New Password',
    name: 'newPassword',
    required: true,
    type: 'password',
    variant: 'standard',
    validate: composeValidators(
      isAtMinimumEightCharactersLong,
      containAtLeastOneCapitalLetter,
      containAtMinimumTwoNumbers,
      containsAtMinimumOneSpecialCharacter,
      containAtMinimumTwoLowerCaseLetters
    ),
  },
  {
    ComponentType: FormPasswordField,
    fullWidth: true,
    label: 'Confirm Password',
    name: 'confirmPassword',
    required: true,
    type: 'password',
    variant: 'standard',
  },
];

export default formFieldData;
