import React from 'react';
import PropTypes from 'prop-types';

import { Box, Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import theme from '../../../theme';

const LoadingCardBase = ({ variant, skeletonBackgroundColor }) => (
  <Paper
    square
    sx={{
      padding: theme.spacing(5),
      margin: theme.spacing(3),
      backgroundColor: skeletonBackgroundColor,
    }}
  >
    <Box width="100%">
      <Skeleton variant={variant} />
      <Skeleton variant={variant} />
      <Skeleton variant={variant} />
    </Box>
  </Paper>
);

LoadingCardBase.propTypes = {
  variant: PropTypes.string,
  skeletonBackgroundColor: PropTypes.string,
};

export default LoadingCardBase;
