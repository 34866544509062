import React from 'react';
import { get } from 'lodash/fp';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import TrainingModule from './TrainingModule/TrainingModule';
import AutomatedAttendanceEnterpriseTraining from './TrainingModule/trainings/AutomatedAttendanceEnterpriseTraining';
import FirstTimeEmployeeTraining from './TrainingModule/trainings/FirstTimeEmployeeTraining';
import FirstTimeEnterpriseTraining from './TrainingModule/trainings/FirstTimeEnterpriseTraining';
import { TRAINING_NAME } from './constants';
import TermsAndConditions from './TermsAndConditions';

const getTermsAndConditions = (user) =>
  get(['training', `${TRAINING_NAME.TERMS_AND_CONDITIONS}`], user);
const getEnterpriseFirstLogIn = (user) =>
  get(['training', `${TRAINING_NAME.ENTERPRISE_FIRST_LOGIN}`], user);
const getEnterpriseAutomatedAttendance = (user) =>
  get(['training', `${TRAINING_NAME.ENTERPRISE_AUTOMATED_ATTENDANCE}`], user);
const getEmployeeFirstLogIn = (user) =>
  get(['training', `${TRAINING_NAME.EMPLOYEE_FIRST_LOGIN}`], user);

export const isAppTrainingCompleted = (user) => {
  if (
    user.role === UserRole.CANDIDATE &&
    getTermsAndConditions(user) &&
    getEmployeeFirstLogIn(user)
  ) {
    return true;
  }
  if (
    user.role === UserRole.ADMIN &&
    getTermsAndConditions(user) &&
    getEnterpriseFirstLogIn(user) &&
    getEnterpriseAutomatedAttendance(user)
  ) {
    return true;
  }
  if (
    user.role === UserRole.EMPLOYER &&
    getTermsAndConditions(user) &&
    getEnterpriseFirstLogIn(user) &&
    getEnterpriseAutomatedAttendance(user)
  ) {
    return true;
  }
  if (
    user.role === UserRole.RECRUITER &&
    getTermsAndConditions(user) &&
    getEnterpriseFirstLogIn(user) &&
    getEnterpriseAutomatedAttendance(user)
  ) {
    return true;
  }
  return false;
};

export const getNextTraining = (user) => {
  const termsAndConditions = <TermsAndConditions user={user} />;
  const employeeFirstLogIn = (
    <TrainingModule
      pages={FirstTimeEmployeeTraining()}
      trainingName={TRAINING_NAME.EMPLOYEE_FIRST_LOGIN}
      user={user}
    />
  );
  const enterpriseFirstLogIn = (
    <TrainingModule
      pages={FirstTimeEnterpriseTraining()}
      trainingName={TRAINING_NAME.ENTERPRISE_FIRST_LOGIN}
      user={user}
    />
  );
  const enterpriseAutomatedAttendance = (
    <TrainingModule
      pages={AutomatedAttendanceEnterpriseTraining()}
      trainingName={TRAINING_NAME.ENTERPRISE_AUTOMATED_ATTENDANCE}
      user={user}
    />
  );

  const trainingsForCandidate = !getEmployeeFirstLogIn(user) && employeeFirstLogIn;
  const trainingsForAdmin =
    (!getEnterpriseFirstLogIn(user) && enterpriseFirstLogIn) ||
    (!getEnterpriseAutomatedAttendance(user) && enterpriseAutomatedAttendance);
  const trainingsForEmployer =
    (!getEnterpriseFirstLogIn(user) && enterpriseFirstLogIn) ||
    (!getEnterpriseAutomatedAttendance(user) && enterpriseAutomatedAttendance);
  const trainingsForRecruiter =
    (!getEnterpriseFirstLogIn(user) && enterpriseFirstLogIn) ||
    (!getEnterpriseAutomatedAttendance(user) && enterpriseAutomatedAttendance);

  return (
    (!getTermsAndConditions(user) && termsAndConditions) ||
    (get(['role'], user) === UserRole.CANDIDATE && trainingsForCandidate) ||
    (get(['role'], user) === UserRole.ADMIN && trainingsForAdmin) ||
    (get(['role'], user) === UserRole.EMPLOYER && trainingsForEmployer) ||
    (get(['role'], user) === UserRole.RECRUITER && trainingsForRecruiter)
  );
};
