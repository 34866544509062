import React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

import theme from '../../theme';
import { setFirstLetterOfStringToCapital } from '../../utils';

const CHIP_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const PillChips = ({ background, label, onClick, value, sx, withCheckMark }) => {
  let chipBgColor;

  let selectedChipBgColor;
  let unSelectedFontColor;
  let selectedFontColor;
  const fontSize = get(['components', 'PillChips', 'typography', 'fontSize'], theme);
  const iconSize = get(['chip', 'default', 'iconSize'], theme);
  const PillChipsPaletteUnselectedBackgroundLight = get(
    ['components', 'PillChips', 'palette', 'unselected', 'background', 'light'],
    theme
  );
  const PillChipsPaletteSelectedBackgroundLight = get(
    ['components', 'PillChips', 'palette', 'selected', 'background', 'light'],
    theme
  );
  const PillChipsPaletteUnselectedFontLight = get(
    ['components', 'PillChips', 'palette', 'unselected', 'font', 'light'],
    theme
  );
  const PillChipsPaletteSelectedFontLight = get(
    ['components', 'PillChips', 'palette', 'selected', 'font', 'light'],
    theme
  );

  switch (background) {
    case CHIP_BACKGROUND.DARK:
      chipBgColor = PillChipsPaletteUnselectedBackgroundLight;
      selectedChipBgColor = PillChipsPaletteSelectedBackgroundLight;
      unSelectedFontColor = PillChipsPaletteUnselectedFontLight;
      selectedFontColor = PillChipsPaletteSelectedFontLight;
      break;
    case CHIP_BACKGROUND.LIGHT:
      chipBgColor = PillChipsPaletteUnselectedBackgroundLight;
      selectedChipBgColor = PillChipsPaletteSelectedBackgroundLight;
      unSelectedFontColor = PillChipsPaletteUnselectedFontLight;
      selectedFontColor = PillChipsPaletteSelectedFontLight;
      break;
    default:
      chipBgColor = PillChipsPaletteUnselectedBackgroundLight;
      selectedChipBgColor = PillChipsPaletteSelectedBackgroundLight;
      unSelectedFontColor = PillChipsPaletteUnselectedFontLight;
      selectedFontColor = PillChipsPaletteSelectedFontLight;
  }
  const CustomizedChip = styled(Chip)(() =>
    value
      ? `
    color: ${selectedFontColor};
    background-color:  ${selectedChipBgColor};
    `
      : `
      color: ${unSelectedFontColor};
      background-color: ${chipBgColor};    
  `
  );
  const handleOnClick = () => {
    onClick();
  };
  return (
    <CustomizedChip
      onClick={handleOnClick}
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize,
          }}
        >
          {setFirstLetterOfStringToCapital(label)}
          {value && withCheckMark ? (
            <CheckIcon fontSize={fontSize} sx={{ marginLeft: '3px' }} />
          ) : (
            ' '
          )}
          {value ? <ClearIcon fontSize={iconSize} /> : <AddIcon fontSize={iconSize} />}
        </Box>
      }
      sx={{ ...sx }}
    />
  );
};

PillChips.propTypes = {
  background: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  withCheckMark: PropTypes.bool,
  sx: PropTypes.shape({}),
};

export default PillChips;
