import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Grid, Typography } from '@mui/material';

import Button, { BUTTON_VARIANT } from '../../../../../components/Button';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import Dialog from '../../../../../components/Dialog';
import theme from '../../../../../theme';
import { updateUser } from '../../../reducer';

const ReadyToWorkChecks = ({ selectedUser }) => {
  const dispatch = useDispatch();
  const onboardingDoc = selectedUser?.candidate?.onboardingDoc;
  const eVerify = selectedUser?.candidate?.eVerify;
  const selectedUserId = selectedUser?.id;

  const [valueForModal, setValueForModal] = useState(null);
  const handleOnChange = useCallback(
    (val) =>
      onboardingDoc && eVerify
        ? setValueForModal(val)
        : dispatch(updateUser({ id: selectedUserId, fields: { candidate: val } })),
    [dispatch, onboardingDoc, selectedUserId, eVerify]
  );

  const handleOnUnConfirm = useCallback(() => {
    dispatch(updateUser({ id: selectedUserId, fields: { candidate: valueForModal } }));
    setValueForModal(null);
  }, [setValueForModal, dispatch, selectedUserId, valueForModal]);

  const getEVerifiedByName = (val) => val?.verifiedByName || '--';

  const body = (
    <Grid container>
      <Typography
        sx={{
          color: theme?.candidate?.components?.readyToWorkUnConfirmModal?.typography?.body?.color,
        }}
      >
        {LanguageConverter('user.onboardingDocs.unconfirm.body')}
      </Typography>
    </Grid>
  );

  const dialogButtons = [
    <Button
      text={LanguageConverter('generic.goBack')}
      key="handle-go-back-button"
      onClick={() => setValueForModal(null)}
    />,

    <Button
      text={LanguageConverter('generic.unconfirm')}
      variant={BUTTON_VARIANT.DELETE}
      key="handle-unConfirm-button"
      onClick={handleOnUnConfirm}
    />,
  ];

  return (
    <>
      <div>
        <CheckBox
          onChange={(event) => handleOnChange({ onboardingDoc: event.target.checked })}
          checked={!!onboardingDoc}
          label={`Onboarding Documentation Complete: ${getEVerifiedByName(onboardingDoc)}`}
          name="onboardingDoc"
          variant="standard"
          fullWidth
        />
      </div>
      <div>
        <CheckBox
          onChange={(event) => handleOnChange({ eVerify: event.target.checked })}
          checked={!!eVerify}
          label={`E-Verified: ${getEVerifiedByName(eVerify)}`}
          name="eVerified"
          variant="standard"
          fullWidth
        />
      </div>
      <Dialog
        open={!!valueForModal}
        dialogTitle={LanguageConverter('user.onboardingDocs.unconfirm.title')}
        dialogContentText={body}
        dialogActions={dialogButtons}
        onBackdropClick={() => setValueForModal(null)}
      />
    </>
  );
};
ReadyToWorkChecks.propTypes = {
  selectedUser: PropTypes.shape({
    id: PropTypes.string,
    candidate: PropTypes.shape({
      onboardingDoc: PropTypes.shape({}),
      eVerify: PropTypes.shape({}),
    }),
  }),
};

export default ReadyToWorkChecks;
