/* eslint-disable import/prefer-default-export */
import React from 'react';

export const termsAndConditionsText = (
  <div>
    <table>
      <tbody>
        <tr>
          <td>
            <p>
              <strong>TERMS OF USE</strong>
            </p>
            <p>
              <strong>
                BY ACCEPTING, I HEREBY ACKNOWLEDGE THAT I HAVE READ AND UNDERSTAND THE FOREGOING
                TERMS OF SERVICE AND PRIVACY POLICY AND AGREE THAT MY USE OF THE SERVICE IS AN
                ACKNOWLEDGMENT OF MY AGREEMENT TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
                AGREEMENT
              </strong>
            </p>
            <p />
          </td>
        </tr>
      </tbody>
    </table>
    <p />
    <p>
      <strong>Terms of Service for Customers</strong>
    </p>

    <p>
      These Terms of Service govern the use and access of the electronic services made available
      through the native and web-enabled applications ***. Such services and application, including
      mobile application, together are hereinafter collectively referred to as the ***.
    </p>

    <p>The *** is offered on behalf of the *** legal entities:</p>
    <p>
      its parent company, subsidiaries, and related companies, and all of their respective
      employees, agents, officers, directors, shareholders, successors, and assigns (collectively,
      the &ldquo;Company&rdquo;) or ***). The *** is provided by and licensed by the Company from
      *** (or &ldquo;Service Provider&rdquo;).
    </p>

    <p>TERMS OF USE</p>

    <p>
      <strong>1. Acceptance</strong>
    </p>
    <p>
      As a Customer who accesses ***, if applicable, on behalf of the organization on whose behalf
      you are accessing or using the *** (collectively, &ldquo;Customer&rdquo; or
      &ldquo;you&rdquo;), your use of the *** Platform constitutes your acceptance of, and agreement
      to, all of the terms and conditions in these Terms of Service, Terms and Conditions, and ***
      Privacy Policy (collectively, the &ldquo;Agreement&rdquo;) *** Terms and Conditions and
      Privacy Policy are available for your review at ***. By accessing the ***, you certify that
      you are authorized by your organization (the Customer) to accept this Agreement and to incur,
      on behalf of the Customer, the obligation to pay for any work performed *** associates (or
      &ldquo;Associates&rdquo;, as defined below) provided by the Company as a result of using the
      *** Platform. To the extent that there is a conflict between these Terms of Service and
      Conditions or *** Privacy Policy, the provisions in these Terms of Service shall be
      controlling.
    </p>

    <p>
      Customer must be 18 years of age or over to use the ***. &nbsp; &nbsp;By accessing the *** you
      certify that you are 18 years of age or suspect that you are not at least 18, that you have
      knowingly provided false information, or that your organization (the Customer) has not
      authorized you to utilize the *** on its behalf, the Company reserves the right to suspend or
      terminate your use of the *** Platform immediately and without notice. If you object to
      anything in this Agreement, you are not permitted to use the *** Platform. If you do not agree
      to this Agreement in its entirety, stop using the *** Platform immediately.
    </p>

    <p>
      <strong>2. &nbsp;About *** Platform</strong>
    </p>
    <p>
      *** Platform Only Provides a Method to Match Work; Service Provider Relationship. The ***
      Platform is a platform for enabling connections between Individual Associates who use the ***
      Platform and Customers for the fulfillment of work. Neither the Company nor the Service
      Provider are responsible for the performance or non-performance of any Associate who performs
      work for the Customer, nor does the Company or the Service Provider have control over the
      quality, timing, legality, failure to provide, or any other aspect whatsoever of an
      Associate&rsquo;s labor, nor of the integrity, responsibility, or any of the actions or
      omissions whatsoever of any Associate. Neither the Company nor the Service Provider make any
      representations or warranties about the suitability, reliability, fitness, timeliness, or
      accuracy of the labor requested or provided by Associates or Customers identified through the
      whether in public, private, or offline interactions.
    </p>

    <p>
      The Service Provider developed the technology supporting the *** and licenses the platform to
      the Company. The relationship between the Service Provider and the Company is that of
      independent contractors, and neither will be deemed to be an employee, employer, agent,
      partner, or legal representative of the other for any purpose. Other than providing the
      technology supporting the *** Platform, the Service Provider has no employment or other
      relationship with the Associates.
    </p>

    <p>
      <strong>Amendments</strong>
    </p>
    <p>
      The Company reserves the right, in its sole and absolute discretion, to change, modify,
      update, add, or remove portions of these Terms of Service at any time. If the Company makes
      any such changes to these Terms of Service, it will provide notice to you via the ***
      &nbsp;when the changes are posted. The new or modified terms will take effect upon posting on
      ***. Your continued use of the *** after notification signifies that you have read,
      understood, and agreed to those revised Terms of Service. You should review these Terms of ***
      &nbsp;text is cut off here. ***Terms of Service. You should review these Terms of Service
      every time you use the *** Platform. If you do not agree to the amended terms, you must stop
      using the *** Platform immediately.
    </p>

    <p>
      <strong>Registration and Verification</strong>
    </p>
    <p>
      Customers may be subject to a vetting process before they can access the *** Platform in full,
      including but not limited to a verification of the Customer&rsquo;s identity and Employment
      Identification Number (&ldquo;EIN&rdquo;). Although the Company may perform background checks
      of Users, it cannot confirm that each User is who they claim to be and, therefore, the Company
      cannot and does not assume any responsibility for the accuracy or reliability of the identity
      or background check information or any information provided through the ***.
    </p>

    <p>
      <strong>Interaction with Associates through Platform</strong>
    </p>
    <p>
      When interacting with Associates, you should exercise caution and common sense to protect your
      personal safety and property, just as you would when interacting with other persons whom you
      do not know. NEITHER THE COMPANY, THE SERVICE PROVIDER, NOR THEIR RESPECTIVE AFFILIATES OR
      LICENSORS, ARE RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY ASSOCIATE OF THE
      *** AND YOU HEREBY RELEASE THE COMPANY, THE SERVICE PROVIDER, AND THEIR RESPECTIVE EMPLOYEES,
      AGENTS, OFFICERS, DIRECTORS, SHAREHOLDERS, SUCCESSORS, AND ASSIGNS FROM ANY LIABILITY RELATED
      TO YOUR USE OF THE *** PLATFORM. THE COMPANY, THE SERVICE PROVIDER, AND THEIR RESPECTIVE
      AFFILIATES AND LICENSORS WILL NOT BE LIABLE FOR ANY CLAIM, INJURY, OR DAMAGE ARISING OUT OF OR
      IN CONNECTION WITH YOUR USE OF THE *** PLATFORM.
    </p>

    <p>
      <strong>Account, Password, and Security</strong>
    </p>
    <p>
      All Customers must register with the Company and create an account to use the ***. Each
      Customer, including its authorized representatives, is the sole authorized user of its
      account, and each is responsible for maintaining the confidentiality of any password and
      account number provided by you, the Company, or the Service Provider for accessing the You are
      solely and fully responsible for all activities that occur under your password or account,
      even where your password or account are used by someone else. The Company has no control over
      the use of any Customer&rsquo;s account and expressly disclaims any liability derived
      therefrom. Should you suspect that any unauthorized party may be using your password or
      account, or if you suspect any other breach of security, you will contact the Company
      immediately using the method specified in Section 9 of these Terms of Service. Customer
      remains liable for all charges incurred through any unauthorized use of Customer&rsquo;s
      account.
    </p>

    <p>
      <strong>Termination and Suspension of the Platforms</strong>
    </p>
    <p>
      The Company may, at any time and for no reason, in its sole discretion: (A) change, suspend,
      or terminate, temporarily or permanently, the *** Platform or any part of it; or (B) restrict,
      suspend, or terminate, in whole or in part, your permission to access or use the *** Platform;
      all without any notice or liability to you or any other person. If this Agreement or your
      permission to access or use the *** Platform is terminated, then: (A) this Agreement and all
      other then-existing agreements between you and the Company will continue to apply and be
      binding regarding your prior access to and use of the *** Platform, and anything connected
      with, related to, or arising therefrom; and (B) the Company may continue to use and disclose
      your personal information in accordance with this Agreement, as amended from time to time. If
      you breach any provision of this Agreement, you may no longer use the *** Platform.
    </p>

    <p>
      You may terminate this Agreement at any time by ceasing all use of *** Platform. All sections
      which, by their nature, should survive the expiration or termination of this Agreement shall
      continue in full force and effect subsequent to and notwithstanding the expiration or
      termination of this Agreement, including, without limitation, Sections 2 (*** a Method to
      Match Work; Service Provider Relationship), 3 (Terms Related to Work Performed by Associates),
      4 (Public Areas; Acceptable Use and Restrictions), 6 (Intellectual Property), 6 (Confidential
      Information), 7 (Limitations on Liability and Disclaimers for the *** Platform, No Incidental,
      Consequential, or Certain Other Damages, and Indemnification) and any other indemnity
      hereunder, 8 (Governing Law and Dispute Resolution), and 9 (Miscellaneous).
    </p>

    <p>
      <strong>3. &nbsp;Terms Related to Work Performed by Company Associates</strong>
    </p>
    <p>
      This section applies to any orders you place using the *** Platform. You agree that Company is
      a supplier of temporary workers and you have requested that Company provide Associate(s) to
      assist you in the completion of your business activities. Please read this section before you
      send any orders to Company using the *** Platform.
    </p>

    <p>
      <strong>Payment of Wages</strong>
    </p>
    <p>
      The Company shall be responsible for the payment of all wages, the withholding and payment of
      all required payroll taxes, and the maintenance of Associates&rsquo; Workers&rsquo;
      Compensation Insurance as required by state law. Customer shall accurately record all hours
      worked by Associates. You agree to comply with and enforce all applicable laws, including,
      without limitation, employment and labor laws related to breaks and meal periods, hiring and
      discharge of employees, equal employment opportunities, and the maintenance of fair labor
      practices. Customer shall provide a workplace free from discrimination and unfair labor
      practices.
    </p>

    <p>
      Customer shall defend, indemnify, and hold harmless the Company, Service Provider, and their
      respective affiliates, subsidiaries, agents, employees, officers, directors, parents ***,
      contractors, and advisors (collectively, &ldquo;Indemnified Parties&rdquo;), and the supplied
      Associates for any and all claims or damages arising out of Customer&rsquo;s violation of
      employment or labor laws, including, without limitation, Title VII, FLSA, NLRA, or failure to
      accurately report hours worked. Overtime bill rates are 1.5 times regular bill rates, or the
      applicable legal multiplier under state and federal law.
    </p>

    <p>
      <strong>Bill Rates</strong>
    </p>
    <p>
      Customer acknowledges that Associates&rsquo; pay rates have been established pursuant to
      negotiation between you and the Company. You agree to notify the Company when a supplied
      Associate is performing work subject to the Service Contract Act, Davis-Bacon Act, or other
      similar local, state, or federal law related to prevailing or living wages. You agree to
      defend, indemnify, and hold harmless all Indemnified Parties for any claim for wages,
      penalties, fines, attorneys&rsquo; fees, and reasonable profit in the event that you fail to
      properly identify such prevailing wage work. If the Work Comp Code indicated refers to a WRAP
      code, referring to a controlled insurance program, the Bill Rate does not include any charges
      related to workers&rsquo; compensation insurance. You shall inform the Company of any changes
      in work assignment.
    </p>

    <p>
      <strong>Working Conditions</strong>
    </p>
    <p>
      Customer agrees that Associates are under Customer&rsquo;s supervision, direction, and
      control. You must determine that an Associate meets any skill competency, license,
      certification, or other requirements necessary to complete special jobs to your own
      satisfaction during the first two hours of work. Customer shall not change Associate&rsquo;s
      job assignment from the position set forth in the initial job order unless provided for in an
      additional statement of work agreed to and signed by the duly authorized representative of
      both Company and Customer.
    </p>

    <p>
      The Company is a provider of unskilled and skilled labor and is not a licensed general
      contractor or subcontractor. You are responsible for providing sufficient supervision to
      ensure quality and timeliness requirements are met.
    </p>

    <p>
      Without the prior written consent of the Company, Customer will not utilize Associates to
      operate machinery; to operate heavy equipment, motor vehicles or vehicles for excavation more
      than four feet in depth; for any work on ladders or scaffolding over 6 feet in height; or as
      the member of the crew of any vessel or maritime work upon the navigable waters of the United
      States which might be subject to the US LHWCA or the Jones Act. Without the prior written
      consent of the Company, Customer will not entrust Company Associates with the care of
      unattended premises, cash, checks, credit cards, negotiables, or other valuables. Customer
      shall remain solely responsible for any background check obligations related to the worksite.
      Customer agrees that Company will not supervise its Associates and that Customer shall be
      responsible to provide Company Associates adequate supervision, direction and control.
      Customer shall comply with all applicable laws relating to health and safety and shall include
      Associates in Customer&rsquo;s safety and health program, while they are in the care, custody,
      and control of the Customer, and shall provide personal protective equipment
      (&ldquo;PPE&rsquo;&rsquo;) necessary or required for any work to be performed. If the Customer
      provides any site-specific PPE, you shall instruct the supplied Associates in proper use and
      care of that equipment. You agree to provide site-specific safety instructions, training, and
      equipment to all supplied Associates prior to the start of work which includes, but is not
      limited to: review of the Customer&rsquo;s total safety program; on-the-job review of the
      practices necessary to perform the job assignments in a safe manner; the use and care of
      required PPE; identification of hazardous materials involved; instructions on the safe use and
      emergency action (MSDS); the proper actions to take in event of emergencies, including exit
      routes; how to report unsafe conditions and practices; how and when to report injuries; the
      location of MSDS&rsquo;s; and the location of first aid facilities. You agree to indemnify,
      defend, and hold harmless all Indemnified Parties for claims, damages, or penalties arising
      out of violations of the Occupational Safety and Health Act of 1970, or any other similar
      state law with respect to workplaces or equipment owned, leased, or supervised by Customer and
      to which Associates are assigned. You agree to record Associates&rsquo; work-related injuries
      on your OSHA Log. Customer shall not allow Associates to engage in any unsafe practice.
    </p>

    <p>
      <strong>Indemnity and Insurance</strong>
    </p>
    <p>
      Except as set forth below regarding the operation of motor vehicles, to the fullest extent
      permitted by law, Customer agrees to defend, indemnify, and hold the Company harmless from and
      against claims, demands, and liabilities arising out of or in connection with Customer&rsquo;s
      negligence or breach of contract, or any acts, omissions or negligence of any Associates on
      assignment with Customer. Without limiting the foregoing, you specifically assume all
      responsibility and liability for, and agree to defend, indemnify, and hold harmless the
      Indemnified Parties from any claims for bodily injury (including death) or property damages
      made or caused by Customer or Customer&rsquo;s employees. You agree to waive any immunity
      provided by Worker&rsquo;s Compensation or other industrial insurance laws. The Company and
      the Service Provider assume no responsibility for the criminal acts of Associates. In the
      event any action to enforce the Agreement of these Terms, the prevailing party will be
      entitled to reasonable attorney&rsquo;s fees and costs.
    </p>

    <p>
      If Customer shall request or permit any Associate to operate any motor vehicle owned or
      controlled by Customer, then Customer shall maintain the following policies providing coverage
      for Customer&rsquo;s business operations and for the motor vehicles operated by Associates:
    </p>

    <ol>
      <li>commercial general liability insurance coverage of not less than $1,000,000;</li>
      <li>
        business automobile liability insurance of not less than $1,000,000 for all owned, non-owned
        and hired vehicles and equipment; and
      </li>
      <li>excess liability insurance of not less than $5,000,000.</li>
    </ol>

    <p>
      The commercial general liability and business automobile insurance shall be primary and
      non&shy;contributory with insurance provided by Company, Customer shall furnish to Company
      certificates of insurance and additional insured endorsement naming Company and its Associates
      as additional insureds under each such policy. Insurance shall be purchased from companies
      admitted and licensed to do business in the states in which the Customer&rsquo;s vehicles are
      to be operated and with an AM Best rating of A-VIII or greater. Customer shall also indemnify,
      defend, and hold Company and its employees, including Associates, harmless from any claims and
      expenses (including attorneys&rsquo; fees and other expense of litigation) for bodily injury,
      property damage or environmental pollution asserted by the operation or use of any such
      vehicles for Customer.
    </p>

    <p>
      Customer agrees that no Associates will be responsible for or requested to handle cash,
      checks, securities, credit cards, other negotiable instruments, keys, vouchers or other
      valuables without Company&rsquo;s prior written consent. If Customer shall permit Associate to
      perform services, which may include the handling of any cash, checks, securities, credit
      cards, other negotiable instruments, keys, vouchers or other valuables, Company shall not be
      responsible for the loss, theft, mishandling or mysterious disappearance of any such item of
      value. It is further agreed that Customer shall indemnify and save Company harmless from any
      claims and expenses (including the expense of litigation) for damages asserted by Customer,
      its employees, agents, third parties, or the owner of any such item of value in connection
      with any such loss.
    </p>

    <p>
      <strong>Limitation of Liability</strong>
    </p>
    <p>
      IN NO EVENT SHALL EITHER PARTY OR ITS RELATED COMPANIES, OR THEIR OFFICERS, AGENTS, EMPLOYEES,
      OR ASSOCIATES BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT FOR LOST PROFITS, OR FOR
      ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES, REGARDLESS OF
      WHETHER SUCH LIABILITY ARISES OUT OF OR IS RELATED TO BREACH OF CONTRACT, TORT (INCLUDING
      NEGLIGENCE) OR OTHERWISE.
    </p>

    <p>
      <strong>Invoices and Payment</strong>
    </p>
    <p>
      Customer will be responsible for paying the invoice for each job ticket or job assignment
      completed via the *** (&ldquo;Invoice&rdquo;), which will include the pricing terms of the
      labor agreed to between Customer and the Company.
    </p>

    <p>
      Customer agrees to the terms of NET 7 DAYS FROM DATE OF INVOICE and understands that unpaid
      accounts will be considered in default after thirty (30) days, after which a default charge
      will be imposed at 1&frac12; % per month on unpaid balances (Annual Percentage Rate of 18%) or
      the maximum legal interest rate, whichever is lower. You agree to pay the default charge
      together with reasonable attorneys&rsquo; fees and/or collection fees incurred in the effort
      to collect your debt to the Company. You agree to pay the Company a four (4) hour minimum
      charge each day per associate. Your failure to dispute, in writing, the charges on any invoice
      within fourteen (14) days of receipt shall constitute irrevocable acceptance of such charges
      and terms and a waiver of the right to later dispute or reject any charges stated on the
      invoice.
    </p>

    <p>
      4.&nbsp;
      <strong>Acceptable Uses and Restrictions for the *** Platforms</strong>
    </p>
    <p>
      <u>Public Areas</u>
    </p>
    <p>
      The *** may contain profiles, email systems, blogs, message boards, applications, job
      postings, chat areas, news groups, forums, communities, and/or other message or communication
      facilities (&ldquo;Public Areas&rdquo;) that allow you to communicate with Customers and
      Associates. You may only use such Public Areas to send and receive messages and materials that
      are relevant and proper to the applicable forum.
    </p>

    <p>
      Customer understands that all submissions made to Public Areas will be public and that you
      will be publicly identified by your name or login identification when communicating in Public
      Areas, and the Company will not be responsible for the action of any Associates or Customers
      with respect to any information or materials posted in Public Areas.
    </p>

    <p>Without limitation, while using the *** you may not, and may not attempt to:</p>
    <ul>
      <li>Harass or encourage harassment of another person;</li>
      <li>
        Perform any activities that violate, intentionally or unintentionally, any applicable state,
        local, federal, or international laws or regulations;
      </li>
      <li>
        infringe any patent, trademark, trade secret, copyright, or other intellectual property or
        violate other rights (including privacy or publicity) of any other person;
      </li>
      <li>
        Perform any activities that are misleading, malicious, discriminatory, infringing, or could
        expose the Company, its subsidiaries, or users of the *** to harm or liability;
      </li>
      <li>
        Transmit any material or act that is hateful, threatening, abusive, tortious, defamatory,
        inflammatory, false, fraudulent, misleading, libelous, vulgar, obscene, pornographic (or
        that contains nudity), graphically or gratuitously violent, that violates anyone&rsquo;s
        rights including intellectual property rights or proprietary rights, or is otherwise
        objectionable in the Company&rsquo;s sole judgment;
      </li>
      <li>
        Provide resources to or otherwise support any organization(s) designated by the United
        States government as a foreign terrorist organization under section 219 of the Immigration
        and Nationality Act;
      </li>
      <li>Impersonate any person or misrepresent in any way your affiliation with a person;</li>
      <li>
        Transmit unsolicited mass mailings, &ldquo;spam,&rdquo; or advertising or promotional
        materials (other than job listings);
      </li>
      <li>Conduct contests, sweepstakes, or lotteries;</li>
      <li>Collect or store any information about other users</li>
      <li>
        Collect or store any information about other users of the *** Platform, other than in the
        normal course of using the *** Platform its intended purpose;
      </li>
      <li>
        Transmit any virus, worm, defect, Trojan horse, or any other automated means, information,
        or computer code that interrupts, destroys, or limits the functionality of any computer or
        telecommunications equipment or similar destructive or harmful item;
      </li>
      <li>
        Use in any manner that could damage, disable, overburden, disrupt, or impair the ***
        Platform, or the network(s) connected to the Company&rsquo;s website or mobile app, or
        interfere with any other party&rsquo;s use of the *** Platform;
      </li>
      <li>
        Disobey any applicable policies or regulations of networks connected to the *** Platform;
      </li>
      <li>
        Modify, adapt, translate, reverse engineer, decompile, or disassemble the *** or otherwise
        attempt to discover the source code or object code of the ***;
      </li>
      <li>
        Use any robot, spider, site search/retrieval application, or other device to retrieve or
        index any portion of the ***;
      </li>
      <li>
        Use an iFrame or offer web search functionality on any website associated with the ***;
      </li>
      <li>
        Frame, reformat, rent, lease, lend, sell, sublicense, or otherwise make the *** available to
        third parties;
      </li>
      <li>Create user accounts using any automated means or under false pretenses;</li>
      <li>Prepare derivative works based on the ***; or</li>
      <li>
        Remove any proprietary notices, labels, or other identifying marks on content provided
        through the *** Platform.
      </li>
    </ul>
    <p>
      <strong>5. Company Information Practices and Communications with you</strong>
    </p>
    <p>
      <u>Contact, Mobile Phone Use, and Texting</u>
    </p>
    <p>
      By providing your contact information and using the *** Platform, Customer hereby
      affirmatively consents to the Company&rsquo;s use of your contact information for calls and
      texts in order to perform and improve upon the *** by providing your telephone or mobile phone
      number through the *** Platform, you give your express prior consent, or prior express written
      consent, for the *** to include calling and text messaging (SMS) services (&ldquo;Calling and
      Texting Services&rdquo;), including as noted in this section. Calling and Texting Services may
      include calling or sending text messages from the Company, its affiliates, subsidiaries,
      agents, parents (including *** Inc.), the Service Provider, or any of their respective service
      providers.
    </p>

    <p>
      Customer acknowledges and agrees that your consent texts may be sent by the Company, its
      affiliates, subsidiaries, agents, parents (including *** Inc.), the Service Provider, or their
      respective service providers using an automatic telephone dialing system or an artificial or
      pre-recorded voice, including Calling and Texting Services.
    </p>

    <p>
      Please note that message rates, voice, and data charges from your cellular or telephonic
      service provider may apply in connection with Calling and Texting Services. You are solely
      responsible for such message, voice, and data charges.
    </p>

    <p>
      You may terminate the Texting Services at any time, for any reason, by texting
      &ldquo;STOP&rdquo; to short code ***, &nbsp;in which case you may receive an auto-reply text
      message confirming such termination. You may also terminate the Texting Services by notifying
      your local branch or contacting the Company ***.
    </p>

    <p>
      If, at any time, you cancel or change your mobile telephone number, you agree to promptly
      notify the Company of that change so that it may update its records to avoid sending text
      messages to an individual that may later be assigned your telephone number. You agree to
      indemnify, defend, and hold harmless the Indemnified Parties for any and all liability,
      including any legal fees and expenses that the Company may incur as a result of your failure
      to notify Company may incur, as a result of your failure to notify it of your cancellation or
      change in mobile telephone number.
    </p>

    <p>
      <strong>
        Tracking and Use of Personal and Location-Based Information (&ldquo;LBI&rdquo;)
      </strong>
    </p>
    <p>
      BY LOGGING-IN TO AND USING THE *** PLATFORM, YOU HEREBY CONSENT TO THE COLLECTION OF
      LOCATION-BASED DATA AND GRANT COMPANY A PERPETUAL, IRREVOCABLE, AND UNLIMITED RIGHT AND
      LICENSE TO USE AND SHARE SUCH LOCATION-BASED DATA COLLECTED BY THE COMPANY IN CONNECTION WITH
      YOUR USE OF THE PLATFORM, INCLUDING YOUR LOCATION AND PERSONAL INFORMATION. WITHOUT
      LIMITATION, THE COMPANY WILL USE SUCH LOCATION-BASED DATA AND PERSONAL INFORMATION AS
      DESCRIBED IN THE AGREEMENT TO PROVIDE THE *** PLATFORM AND CONNECT YOU WITH ASSOCIATES OR JOBS
      BASED ON YOUR LOCATION. WITHOUT LIMITATION, THE COMPANY MAY SHARE SUCH LOCATION-BASED DATA AND
      PERSONAL INFORMATION WITH OTHER INDIVIDUALS AND ENTITIES, INCLUDING, WITHOUT LIMITATION, THE
      SERVICE PROVIDER, THE COMPANY, ITS AFFILIATES, SUBSIDIARIES, AND PARENTS (INCLUDING *** INC.).
      AND THE COMPANY&rsquo;S CUSTOMERS. YOU MAY AND THE COMPANY&rsquo;S CUSTOMERS. YOU MAY OPT OUT
      OF SUCH COLLECTION, USE, AND SHARING AT ANY TIME BY LOGGING-OUT OF ***, &nbsp; UNINSTALLING
      THE APPLICATION, AND CEASING THE USE OF THE *** PLATFORM.
    </p>

    <p>
      The Company may automatically collect certain information about the devices (including mobile
      devices) you use to access the *** Platform, and about your use of the *** Platform,
      including:
    </p>
    <ul>
      <li>
        Usage information: Details of your use of the *** Platform, including traffic data, location
        data, logs and other communication data, and the resources that you access and use on or
        through the *** Platform.
      </li>
      <li>
        Computer and device information: Information about your Internet connection and mobile
        device, including your IP address, operating systems, platforms, browser type, other
        browsing information (connection, speed, connection type, etc.), device type, device&rsquo;s
        unique device identifier, mobile network information, and the device&rsquo;s telephone
        number.
      </li>
      <li>
        LBI: When you access the *** via mobile application, the Company may collect and process
        information about your mobile device&rsquo;s process information about your mobile
        device&rsquo;s GPS location (including the latitude, longitude, and/or altitude of. your
        mobile device) and the time the location information is recorded to provide location-based
        information and features. Some features of the *** require your personal data in order to
        work, and the Company may associate location data with your device ID and other information
        (including personal information) it holds about you.
      </li>
      <li>
        Mobile device IDs: Your mobile device identifier (e.g. IDFA or other device IDs on Apple
        devices like the iPhone and iPad), is the unique identifier assigned to a device by the
        manufacturer. If you are using the *** from a mobile device, the Company may use mobile
        device IDs or other persistent identifiers, instead of cookies, to recognize you. It may do
        this to store your preferences and track your use of the *** Platform. Unlike cookies,
        mobile device IDs cannot be deleted. Advertising companies may use device IDs to track your
        use of our applications, track the number of advertisements displayed, measure advertising
        performance, and display advertisements that are more relevant to you. Analytics companies
        may use mobile device IDs to track your usage of the Company&rsquo;s applications.
      </li>
      <li>Your preferences: Your preferences and settings such as time zone and language.</li>
      <li>
        Your activity on the *** Platform: Information about your activity on the Platform, such as
        your search queries, comments, domain names, search results selected, number of clicks,
        pages viewed and the order of those pages, how long you visited our *** Platform, the date
        and time you used the *** Platform, error logs, and other similar information.
      </li>
      <li>
        For more information about how the Company collects, uses, and shares personal information
        and LBI, see PeopleReady&rsquo;s Privacy Policy.
      </li>
    </ul>
    <p>
      <strong>Opt-Out of LBI Tracking:</strong>
    </p>
    <ul>
      <li>
        At any time, and for any reason, you may immediately cancel the Company&rsquo;s ability to
        obtain LBI by ceasing use of the *** and deleting the internet history and website data
        within your device.
      </li>
      <li>
        You can turn the location feature off within the settings of your device. NOTE THAT THIS
        ACTION MAY DISABLE OR REDUCE THE FUNCTIONALITY OF THE *** PLATFORM.
      </li>
    </ul>
    <p>
      <strong>Your Information and Likeness&nbsp;</strong>
    </p>
    <p>
      &ldquo;Your Information&rdquo; is defined as any information and materials you or your
      organization (the Customer) provide in connection with your registration for and use of the
      *** Platform, including, without limitation, any image, personally identifying information,
      text, graphics, editorial data, formatting, designs, videotape, film, record, photograph,
      voice, or ail related instrumental, musical, or other sound effects that you post or transmit
      using the *** Platform. You are solely responsible for Your Information, and the Company acts
      as a passive conduit for your online distribution and publication of Your Information. The ***
      Platform also hosts content relating to reviews of specific Users and Customers. Such reviews
      are the opinion of the party generating the content and not the opinion of the Company, and it
      has not verified or approved any such reviews. You agree that this Company is not liable for
      any reviews generated by other Users and Customers. The information and materials described in
      this Section, as provided by a user of the Platform, are collectively referred to herein as
      &ldquo;User Generated Content.&rdquo;
    </p>

    <p>
      Customer hereby represents and warrants to the Company that Your information and any User
      Generated Content: (A) will not be false, inaccurate, incomplete, or misleading: (B) will not
      be fraudulent or incomplete, or misleading; (B) will not be fraudulent or involve the
      dissemination of counterfeit or stolen items; (C) will not infringe any third party&rsquo;s
      copyright, patent, trademark, trade secret, or other proprietary right or rights of publicity
      or privacy; (D) will not violate any law, statute, ordinance, or regulation; (E) will not be
      defamatory, libelous, unlawfully threatening, or unlawfully harassing; (F) will not be
      obscene, or contain child pornography, or be harmful to minors; (G) will not contain any
      viruses, Trojan Horses, worms, time bombs, cancelbots, or other computer programming routines
      that are intended to damage, detrimentally interfere with, surreptitiously intercept, or
      expropriate any system, data, or personal information; and (H) will not create liability for
      the Company or cause it to lose (in whole or in part) the services of its ISPs or other
      partners or suppliers.
    </p>

    <p>
      By providing Your information or User Generated Content in connection with the *** Platform,
      you hereby irrevocably grant to the Company the non&shy;exclusive, fully-paid, royalty-free,
      transferable, sub&shy;licensable, worldwide, unrestricted, and perpetual right to use,
      reproduce, copy, modify, distribute, publicly display, or sell Your Information and User
      Generated Content.
    </p>

    <p>
      <strong>Other Websites</strong>
    </p>
    <p>
      The *** Platform may include links to other websites, resources, and businesses operated by
      independent providers or other persons (collectively, &ldquo;Other Sites&rdquo;). Other Sites
      are independent from the Company, may have different or inconsistent privacy policies or terms
      of use. The Company has no responsibility or liability for or control over Other Sites, their
      business, goods, services, or content Links to Other Sites are provided solely for your
      convenience. The Company does not sponsor or endorse any Other Sites, their content, or any
      goods or services available through Other Sites. Your use of Other Sites and your dealings
      with the owners or operators of Other Sites is at your own risk, and you shall not make any
      claim against the Company arising out of your use of any Other Sites or your dealings with the
      owners or operators of any Other Sites.
    </p>

    <p>
      <strong>6. Ownership and Licenses for ***</strong>
    </p>
    <p>
      <u>Intellectual Property</u>
    </p>
    <p>
      The Company, the Service Provider, the Customers, and their respective licensors retain full
      ownership,, intellectual property rights, and protections in all material contained in the ***
      Platform including, but not limited to, all digital images, software, source code and other
      code, or business methods. Subject to your compliance with these Terms of Service, the ***
      Platform for lawful informational related purposes only. Access and use of any content,
      information, and related materials that may be made available through the *** Platform is made
      available for your personal use. Except as otherwise expressly provided in this Agreement, you
      may not copy, distribute, transmit, display, perform, reproduce, publish, license, rewrite,
      modify, create derivative works from, download (other than the page caching), transfer, or
      sell any material contained within the *** Platform, or use the *** Platform other than for
      its intended purpose without the prior consent of the Company or the respective owner.
    </p>

    <p>
      The *** Platform may only be used in the manner described expressly in this Agreement. In
      particular, except as expressly stated otherwise in this Agreement, you may not copy, imitate,
      reproduce, republish, link to, upload, post, transmit, modify, index, catalogue, mirror, or
      distribute in any way, in whole or in part, the *** Platform, nor cause or launch any programs
      or scripts for the purpose of scraping, indexing, surveying, or otherwise data mine any
      portion of the *** Platform or unduly burden or hinder the operation and/or functionality of
      any aspect of the *** Platform without the express prior written consent of the Company.
    </p>

    <p>
      <strong>Trademark Information</strong>
    </p>
    <p>
      *** and other marks and logos appearing within the *** Platform are registered or unregistered
      trademarks, trade names, or service marks owned or licensed by the Company. Other product and
      company names and logos appearing within the *** Platform may be registered or unregistered
      trade names, trademarks, or service marks of their respective owners. Any use of the trade
      names, trademarks, service marks, or logos (collectively &ldquo;Marks&rdquo;) displayed on the
      *** Platform is strictly prohibited, except as provided for below. Nothing appearing within
      the *** Platform or elsewhere shall be construed as granting, by implication, estoppel, or
      otherwise, any license or right to use any Marks displayed within the *** Platform.
    </p>

    <p>
      Any rights not expressly granted herein are reserved by the Company, the Service Provider, the
      Company&rsquo;s Customers, and their respective licensors.
    </p>

    <p>
      <strong>*** Notice</strong>
    </p>
    <p>
      If you believe any material in the *** Platform infringes on a copyright you own or control,
      you may file a notification of infringement by calling ***, &nbsp;by emailing *** or by
      mailing such notice to ***. 17 U.S.C. 512(c)(3) sets forth the requirements for proper
      notification. However, if you knowingly misrepresent that the material or activity is
      infringing, you may be liable for any and all damages incurred by us as a result of our
      relying upon such misrepresentation. Also, in accordance with *** the Company has adopted a
      policy of terminating, in its sole discretion, *** Platform users who are deemed to be repeat
      infringers, or the Company may limit access to the *** Platforms and/or immediately terminate
      the accounts of *** Platform users who infringe any intellectual property rights of others.
    </p>

    <p>
      <strong>Confidential Information</strong>
    </p>
    <p>
      Customer shall use best efforts to prevent unauthorized disclosure, transfer, or use of any
      and all of the Company&rsquo;s or Service Provider&rsquo;s trade secrets, confidential and
      proprietary information, and all other information and data of the Company that is not
      generally known to the public or other third parties who could derive value, economic or
      otherwise, from its use or disclosure (collectively, &ldquo;Confidential Information&rdquo;).
      Confidential Information shall be deemed to include, without limitation, technical data,
      know-how, research, product plans, products, services, customers, markets, software,
      developments, inventions, processes, formulas, technology, designs, drawings, engineering,
      hardware configuration information, marketing, finances, or other business information
      disclosed directly or indirectly in writing, orally or by drawings or observation. You
      acknowledge that Confidential Information is a valuable, special, and unique asset of the
      Company and the Service Provider, and you agree that you will not disclose, transfer, use (or
      seek to induce others to disclose, transfer, or use) any Confidential Information for any
      purpose other than disclosure to your authorized employees and agents who are bound to
      maintain the confidentiality of Confidential Information. You shall promptly notify the
      Company in writing of any circumstances which may constitute unauthorized disclosure,
      transfer, or use of Confidential Information.
    </p>

    <p>
      <strong>
        7. Limitations on Liability and Disclaimers of Warranties for the *** Platform
      </strong>
    </p>
    <p>
      YOUR USE OF THE *** PLATFORM IS AT YOUR OWN RISK. THE *** PLATFORM IS PROVIDED ON AN &ldquo;AS
      IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, AND WITHOUT ANY REPRESENTATIONS, WARRANTIES,
      OR CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AND INCLUDING, WITHOUT LIMITATION,
      REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY,
      FITNESS FOR A PARTICULAR PURPOSE, PRIVACY, SECURITY, PERFORMANCE, DURABILITY, AVAILABILITY,
      TIMELINESS, ACCURACY, OR COMPLETENESS, ALL OF WHICH ARE HEREBY DISCLAIMED BY THE COMPANY AND
      THE SERVICE PROVIDER TO THE FULLEST EXTENT PERMITTED BY LAW. THERE WILL NOT BE ANY
      REPRESENTATIONS, WARRANTIES, OR CONDITIONS CREATED BY A COURSE OF DEALING, COURSE OF
      PERFORMANCE, OR TRADE USAGE. YOU ARE SOLELY RESPONSIBLE FOR OBTAINING, CONFIGURING, AND
      MAINTAINING ALL COMPUTER HARDWARE, SOFTWARE, TELEPHONE SERVICES, AND OTHER EQUIPMENT AND
      SERVICE NECESSARY FOR YOU TO USE THE *** PLATFORM.
    </p>

    <p>
      THE INTERNET IS NOT A SECURE MEDIUM, AND MAY BE SUBJECT TO INTERRUPTION, DISRUPTION, OR
      INADVERTENT OR DELIBERATE BREACHES OF SECURITY OR PRIVACY. Customer is responsible for using
      appropriate software to meet your particular requirements for anti-virus protection and
      accuracy of data and output, as well as for backing up your data independent from the ***. The
      Company will not be liable for any loss or damage caused by a breach of security or privacy,
      of for any virus, malicious code, hack, or cyber-attack that affects the or your computer,
      that affects the *** Platform or your computer, device, programs, or data, due to your use of
      the Platform.
    </p>

    <p>
      <strong>Limitation of Liability and Exclusive Remedy</strong>
    </p>
    <p>
      Without limiting the foregoing, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, SUBSIDIARIES,
      AND PARENTS (INCLUDING *** INC.), OR THE SERVICE PROVIDER&rsquo;S TOTAL LIABILITY TO YOU OR
      ANY OTHER PERSON, FOR ANY CLAIMS PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES, OR
      COSTS, WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, RELATED TO THIS AGREEMENT, OR
      UNDER ANY OTHER THEORY OF LAW OR EQUITY, AND REGARDLESS OF ANY NEGLIGENCE OR OTHER FAULT OR
      WRONGDOING BY COMPANY OR ANY AFFILIATE OR SERVICE PROVIDER OR ANY PERSON FOR WHOM THE COMPANY
      OR ANY AFFILIATE OR PROVIDER IS RESPONSIBLE, EXCEED TEN US DOLLARS ($10), OR ACTUAL DAMAGES
      SUSTAINED BY YOU FROM THE USE OF THE *** PLATFORM, WHICHEVER IS GREATER. Customer agrees that
      the damage exclusions in this agreement and this limitation of liability and exclusive remedy
      shall apply even if any remedy fails of its essential purpose. Some states and jurisdictions
      do not allow the exclusion of liability for certain damages, so some of the limitations above
      certain damages; so some of the limitations above may not apply to you, in which case the
      Company and the Service Provider&rsquo;s liability shall be limited to the maximum extent
      permitted by applicable law.
    </p>

    <p>
      THE COMPANY AND THE SERVICE PROVIDER WILL NOT, UNDER ANY CIRCUMSTANCES, BE LIABLE TO YOU OR
      ANY OTHER PERSON FOR ANY LOSS OF USE, LOSS OF PRODUCTION, LOSS OF INCOME OR PROFITS OR
      OPPORTUNITY (ANTICIPATED OR OTHERWISE), LOSS OF MARKETS, ECONOMIC LOSS, SPECIAL, INCIDENTAL,
      INDIRECT, OR CONSEQUENTIAL LOSS OR DAMAGE OR EXEMPLARY OR PUNITIVE DAMAGES, WHETHER IN
      CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR UNDER ANY OTHER THEORY OF LAW OR EQUITY,
      ARISING FROM, CONNECTED WITH, OR RELATING TO THE USE OF THE *** &nbsp;PLATFORM BY YOU OR ANY
      OTHER PERSON, AND REGARDLESS OF ANY NEGLIGENCE OR OTHER FAULT OR WRONGDOING BY THE COMPANY,
      THE SERVICE PROVIDER, OR ANY AFFILIATE OR PROVIDER, OR ANY PERSON FOR WHOM THE COMPANY, THE
      SERVICE PROVIDER, OR ANY PROVIDER MAY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR
      DAMAGES BEING INCURRED BY YOU OR ANY OTHER PERSON.
    </p>

    <p>
      The Company and the Service Provider will not be liable to you or any other person for any
      loss or damages suffered by you or any other person as a result of any failure or refusal by
      the Company to give effect to, or for any failure or delay by the Company in receiving,
      accessing, processing, or accepting, any communication sent to the Company by means of the ***
      Platform or other form of communication, or for any loss or damages suffered as a result of
      the operational failure, malfunction, interruption, change, amendment, or withdrawal of the
      *** Platform.
    </p>

    <p>
      <strong>Indemnification</strong>
    </p>
    <p>
      Customer shall indemnify, defend, and hold harmless,
      <strong>&nbsp;</strong>
      each of the Indemnified Parties from and against any and all liabilities, damages, fines,
      penalties, judgments, awards, losses, expenses, and costs, including without limitation
      attorneys&rsquo; fees, in connection with any claim or demand arising out of, related to, or
      connected with your use of the *** Platform or your breach of this Agreement. Without limiting
      the foregoing, the Indemnified Parties shall have the right to select their own counsel at
      your cost if they believe that there is an actual or potential conflict of interest. You may
      not enter into any settlement on behalf of any of the Indemnified Parties without the
      Indemnified Parties&rsquo; written consent. You will assist and cooperate as fully and
      reasonably required by the Indemnified Parties in the defense of any claim or demand.
    </p>

    <p>
      <strong>8. Governing Law and Dispute Resolution</strong>
    </p>
    <p>
      The *** is controlled by the Company, from ***, from United States of America. This Agreement,
      your use of the *** Platform, and all related matters are governed solely by the laws of the
      State of ***, United States of America, and applicable federal laws of the United States of
      America, excluding any rules of private international law or the conflict of laws that would
      lead to the application of any other laws.
    </p>

    <p>
      Any controversy or claim arising out of or relating to this Agreement, or the breach thereof,
      shall be settled exclusively by arbitration administered by the American Arbitration
      Association under its Commercial Arbitration Rules which may be found at&nbsp;
      <a href="https://www.adr.org/sites/default/files/commercial_rule%20s.pdf">
        https://www.adr.org/sites/default/files/commercial_rule s.pdf
      </a>
      &nbsp;and judgment on the award rendered by the arbitrator may be entered in any court having
      jurisdiction thereof. The arbitration will be before a single arbitrator. The place of
      arbitration will be ***, United States of America. Under this Arbitration provision, both
      parties acknowledge that they hereby relinquish, forfeit, and give up, the right to resolve
      any controversy or claim arising out of or relating to this Agreement by a judge and/or a
      jury.
    </p>

    <p>
      Customer agrees that arbitration in no way limits the relief that any party may seek in the
      jurisdiction in which arbitration has been filed. EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY
      IN ITS INDIVIDUAL CAPACITY, AND NOT AS A REPRESENTATIVE OR PUTATIVE CLASS PLAINTIFF OR CLASS
      MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, OR REPRESENTATIVE PROCEEDING.
      Further, unless both the Company and you agree otherwise, the arbitrator may not consolidate
      more than one person&rsquo;s claims, and may not otherwise preside over any form of a
      representative or class proceeding.
    </p>

    <p>
      Notwithstanding any provision in this Section to the contrary, the Company may seek injunctive
      relief in any court having jurisdiction over the parties to enjoin or prevent any action you
      take or threaten to take in violation of this Agreement.
    </p>

    <p>
      To the extent permitted by law, any claim or cause of action Customer may have arising from,
      connected with, or related to your use of the *** Platform, this Agreement, or any related
      matters must be commenced within one (1) year after the claim or cause of action arises, after
      which time the claim or cause of action is forever barred, regardless of any statute or law to
      the contrary.
    </p>

    <p>
      <strong>9. Miscellaneous</strong>
    </p>
    <p>
      If any provision of this Agreement is found to be unlawful, void, or for any reason
      unenforceable, such determination will not affect the validity of any other provision and the
      provision in question shall be reformed to reflect the intent of the provision to the greatest
      extent permissible.
    </p>

    <p>
      Notwithstanding anything herein to the contrary, all provisions necessary for their
      interpretation or enforcement, will survive indefinitely after the termination of this
      Agreement and remain in full force and effect and be binding upon the parties as applicable.
      The provisions of this Agreement will inure to the benefit of and be binding upon the Company,
      the Service Provider, and each of their respective successors and assigns and related persons,
      and you and your heirs, executors, administrators, successors, and personal representatives,
      and all persons you represent and their respective successors, assigns, and related persons.
      You and the persons you represent may not assign this Agreement or the rights and obligations
      under this Agreement without the express prior written consent of the Company, which may be
      withheld in its discretion. The Company may assign this Agreement, and its rights and
      obligations under this Agreement, without your consent or the consent of any persons you
      represent.
    </p>

    <p>
      No consent or waiver by any party to, or of, any breach or default, by any other party, in its
      performance of its obligations under this Agreement will be deemed or construed to be a
      consent to or waiver of a continuing breach or default or any other breach or default of those
      or any other obligations of that party. No consent or waiver will be effective unless in
      writing and signed by all parties. Any rights not expressly granted by this Agreement are
      reserved to the Company. The Company has the right to inform law enforcement of any illegal
      activity or material it suspects or discovers on, through, or otherwise related to the ***
      Platform and provide law enforcement officials with alt requested information about the user
      account from which the illegal activity or material originated.
    </p>

    <p>
      <strong>Contact Us</strong>
    </p>
    <p>
      Please feel free to contact us with any comments or questions regarding the *** Platform. You
      may contact us at: _________________________________________________.
    </p>

    <p>
      The Company has the right to inform law enforcement of any illegal activity or material it
      suspects or discovers on, through, or otherwise related to the *** Platform and provide law
      enforcement officials with all requested information about the user account from which the
      illegal activity or material originated.
    </p>

    <p>
      <strong>Contact Us</strong>
    </p>
    <p>
      Please feel free to contact us with any comments or questions regarding the ***. You may
      contact us at: ______________________________________________________.
    </p>

    <p>
      BY ACCEPTING, YOU HEREBY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THE FOREGOING TERMS OF
      SERVICE AND PRIVACY POLICY, AND YOU AGREE THAT YOUR USE OF THE *** PLATFORM IS AN
      ACKNOWLEDGMENT OF YOUR AGREEMENT TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT
    </p>

    <p>Last updated: March 14, 2018</p>
  </div>
);
