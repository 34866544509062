import get from 'lodash/fp/get';

// eslint-disable-next-line import/prefer-default-export
export const mapFormValuesToStringRepresentation = (form, formFieldData) =>
  Object.keys(form.getState().values).map((fieldName) => {
    const fieldData = formFieldData.find((x) => x.name === fieldName);
    const fieldValue = form.getState().values[fieldName];
    const fieldLabel = get('label', fieldData);
    const formatRule = get('readOnlyFormat', fieldData) || get('format', fieldData);

    return `${fieldLabel}: ${(formatRule && formatRule(fieldValue)) || fieldValue}`;
  });
