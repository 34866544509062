import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DraggableCard from '../DraggableCard/DraggableCard';
import HeaderBar from '../ShiftCard/HeaderBar/HeaderBar';

const useStyles = makeStyles({
  card: {
    perspective: '1000px',
    width: '312px',
    height: '410px',
  },
  cardContent: {
    padding: '12px 12px 0 12px !important',
  },
  cardInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    transition: 'transform 0.4s',
    transformStyle: 'preserve-3d',
  },
  cardFlipped: {
    transform: 'rotateY(180deg)',
  },
  cardFrontBack: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    top: 0,
    left: 0,
  },
  cardFront: {
    zIndex: 2,
    transform: 'rotateY(0deg)',
  },
  cardBack: {
    transform: 'rotateY(180deg)',
  },
  expandBox: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '35px',
    backgroundColor: '#F3F3F3',
    position: 'fixed',
    bottom: '0px',
    right: '0px',
  },
});

const FlipCard = ({
  frontContent,
  backContent,
  isExpanded,
  setIsExpanded,
  index,
  date,
  moveCard,
  isFlipped,
  handleFlipClick,
  isCancelled,
  timeStatus,
}) => {
  const classes = useStyles();

  return (
    <DraggableCard index={index} date={date} moveCard={moveCard}>
      {({ drag }) => (
        <Box
          sx={{
            perspective: '1000px',
            width: '312px',
            height: isExpanded && isFlipped ? '820px' : '410px',
          }}
        >
          <Box className={`${classes.cardInner} ${isFlipped && classes.cardFlipped}`}>
            <Card
              className={`${classes.cardFrontBack} ${
                isFlipped ? classes.cardBack : classes.cardFront
              }`}
              style={{
                overflow: 'auto',
                borderRadius: '8px',
                boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.25)',
              }}
            >
              <HeaderBar
                onFlipClick={handleFlipClick}
                isFlipped={isFlipped}
                onDrag={drag}
                timeStatus={timeStatus}
                isCancelled={isCancelled}
              />
              <CardContent className={classes.cardContent}>
                {isFlipped ? backContent : frontContent}
              </CardContent>
              {isFlipped && (
                <Grid
                  className={classes.expandBox}
                  container
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  <Typography sx={{ textTransform: 'uppercase' }}>
                    {isExpanded
                      ? LanguageConverter('roster.shrink')
                      : LanguageConverter('roster.expand')}
                  </Typography>
                  {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Grid>
              )}
            </Card>
          </Box>
        </Box>
      )}
    </DraggableCard>
  );
};

FlipCard.propTypes = {
  frontContent: PropTypes.node,
  backContent: PropTypes.node,
  index: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  moveCard: PropTypes.func.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  handleFlipClick: PropTypes.func.isRequired,
  isCancelled: PropTypes.bool.isRequired,
  timeStatus: PropTypes.string.isRequired,
};

export default FlipCard;
