import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import datahubMiddleware from '../../datahub/datahubMiddleware';

import createRootReducer from './reducers';

const configureStore = (preloadedState) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        datahubMiddleware
        // ... other middlewares ...
      )
    )
  );

  return store;
};

const store = configureStore({});

export default store;
