import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

export const selectEmployeeTimeCards = (state) => get('employeeTimeCards', state);

export const selectIsLoadingTimeCard = createSelector(
  selectEmployeeTimeCards,
  (employeeTimeCards) => employeeTimeCards.isLoadingTimeCard
);
export const selectTimeCard = createSelector(
  selectEmployeeTimeCards,
  (employeeTimeCards) => employeeTimeCards.timeCard
);
export const selectNextShifts = createSelector(
  selectEmployeeTimeCards,
  (employeeTimeCards) => employeeTimeCards.nextShifts
);
export const selectTotalsData = createSelector(
  selectEmployeeTimeCards,
  (employeeTimeCards) => employeeTimeCards.totalsData
);
export const selectPunchError = createSelector(
  selectEmployeeTimeCards,
  (employeeTimeCards) => employeeTimeCards.punchError
);
export const selectIsProcessingPunch = createSelector(
  selectEmployeeTimeCards,
  (employeeTimeCards) => employeeTimeCards.isProcessingPunch
);
export const selectIsLoadingNextShift = createSelector(
  selectEmployeeTimeCards,
  (employeeTimeCards) => employeeTimeCards.isLoadingNextShift
);
export const selectIsProcessingDispute = createSelector(
  selectEmployeeTimeCards,
  (employeeTimeCards) => employeeTimeCards.isProcessingDispute
);
