import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import WhenFieldChanges from '../../../../components/Form/WhenFieldChanges';
import FormSectionHeader from '../CustomComponents/FormSectionHeader';
import {
  corporationField,
  departmentField,
  locationField,
  positionTemplateField,
} from '../FormFieldData/CreateJobFields';

const LetsGetStarted = ({ user, wrapperSx }) => {
  const formState = useFormState();
  const theme = useTheme();

  const currentFormValues = formState.values;

  const corporationId =
    user?.role === UserRole.EMPLOYER
      ? user?.employer?.corporation
      : currentFormValues?.corporation?._id;

  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const boxSx = { flex: 1, minWidth: smallScreen && '40%' };

  return (
    <Box
      sx={{
        width: '100%',
        ...wrapperSx,
      }}
    >
      <>
        {(user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER) && (
          <WhenFieldChanges
            field="corporation"
            changeShouldOccur
            set="positionTemplate"
            to={undefined}
          />
        )}
        <WhenFieldChanges
          field="positionTemplate"
          changeShouldOccur
          set="location"
          to={undefined}
        />
        <WhenFieldChanges
          field="positionTemplate"
          changeShouldOccur
          set="department"
          to={undefined}
        />
        <WhenFieldChanges field="positionTemplate" changeShouldOccur set="shift" to={undefined} />
      </>

      <FormSectionHeader messageKey="job.create.letsGet" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: smallScreen ? theme.spacing(0.5) : theme.spacing(2),
          flexWrap: 'wrap',
        }}
      >
        {(user.role === UserRole.ADMIN || user.role === UserRole.RECRUITER) && (
          <Box sx={boxSx}>{buildFormField(corporationField())}</Box>
        )}

        <Box sx={boxSx}>{buildFormField(positionTemplateField({ corporationId }))}</Box>
        <Box sx={boxSx}>
          {buildFormField(locationField({ positionTemplate: currentFormValues?.positionTemplate }))}
        </Box>
        <Box sx={boxSx}>
          {buildFormField(
            departmentField({ positionTemplate: currentFormValues?.positionTemplate })
          )}
        </Box>
      </Box>
    </Box>
  );
};

LetsGetStarted.propTypes = {
  user: PropTypes.shape({
    role: PropTypes.string,
    employer: PropTypes.shape({
      corporation: PropTypes.string,
    }),
  }),
  wrapperSx: PropTypes.shape({}),
};

export default LetsGetStarted;
