import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const BlueCircleCheckIcon = () => (
  <SvgIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.4443 3.6853C8.08879 2.58649 10.0222 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7363 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 13.9778 21.4135 15.9112 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8079C8.10929 21.422 6.32746 20.4696 4.92894 19.0711C3.53041 17.6725 2.578 15.8907 2.19215 13.9509C1.8063 12.0111 2.00433 10.0004 2.76121 8.17317C3.51809 6.3459 4.79981 4.78412 6.4443 3.6853Z"
        fill="url(#paint0_linear_8894_21958)"
      />
      <path
        d="M10.4301 13.0901L14.7201 8.79006C14.9074 8.60381 15.1609 8.49927 15.4251 8.49927C15.6892 8.49927 15.9427 8.60381 16.1301 8.79006C16.2238 8.88302 16.2982 8.99362 16.3489 9.11548C16.3997 9.23734 16.4258 9.36805 16.4258 9.50006C16.4258 9.63207 16.3997 9.76278 16.3489 9.88464C16.2982 10.0065 16.2238 10.1171 16.1301 10.2101L11.1301 15.2101C10.9438 15.3948 10.6924 15.499 10.4301 15.5001C10.2984 15.5008 10.168 15.4756 10.0461 15.4258C9.92431 15.3761 9.81349 15.3027 9.72005 15.2101L7.37005 12.8501C7.26537 12.7604 7.18035 12.6501 7.12033 12.526C7.0603 12.402 7.02657 12.2668 7.02125 12.1291C7.01593 11.9914 7.03914 11.8541 7.08941 11.7257C7.13969 11.5974 7.21595 11.4809 7.3134 11.3834C7.41086 11.286 7.5274 11.2097 7.65573 11.1594C7.78405 11.1091 7.92139 11.0859 8.0591 11.0913C8.19682 11.0966 8.33196 11.1303 8.45602 11.1903C8.58009 11.2504 8.6904 11.3354 8.78005 11.4401L10.4301 13.0901Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8894_21958"
          x1="10.0562"
          y1="22"
          x2="13.9438"
          y2="2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0073E5" />
          <stop offset="1" stopColor="#44A1FF" />
        </linearGradient>
      </defs>
    </svg>
  </SvgIcon>
);

export default BlueCircleCheckIcon;
