import React, { memo } from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, TextField } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import theme from '../../theme';
import {
  addHours,
  minutesFromMidnightToTimeOnlyHrAndMins,
  timeToMinutesFromMidnight,
} from '../../utils';
import AlertComponent from '../AlertComponent/AlertComponent';
import Button from '../Button';
import IconButton from '../IconButton';
import TimePickerField from '../TimePicker/TimePickerField';

import { errorHandler, hasFormSubmitError, hasServerError } from './formUtils';
import shiftValidation from './shiftValidations';

const ShiftInputGrid = styled(Grid)(() => ({
  justifyContent: 'flex-start',
  width: '100%',
}));

const ShiftsTitleBox = styled(Box)(() => ({
  fontFamily: get(['shiftPicker', 'typography', 'boldFont'], theme),
  fontSize: get(['shiftPicker', 'typography', 'headerFontSize'], theme),
  color: get(['shiftPicker', 'typography', 'headerFontColor'], theme),
  marginBottom: '5px',
}));

const StyledList = styled(List)(() => ({
  display: 'flex',
  backgroundColor: get(['shiftPicker', 'palette', 'assignedShifts', 'backgroundColor'], theme),
  borderRadius: '16px',
  padding: '5px',
}));

const StyledAddedShiftBox = styled(Box)(() => ({
  fontFamily: get(['shiftPicker', 'typography', 'assignedShifts'], theme),
  fontSize: get(['shiftPicker', 'typography', 'shiftTimeFont'], theme),
  color: get(['shiftPicker', 'palette', 'assignedShifts', 'textColor'], theme),
  marginBottom: '5px',
}));

const StyledAddedShiftNameBox = styled(Box)(() => ({
  fontFamily: get(['shiftPicker', 'typography', 'assignedShifts'], theme),
  fontSize: get(['shiftPicker', 'typography', 'shiftNameHeader'], theme),
  color: get(['shiftPicker', 'palette', 'assignedShifts', 'shiftNameHeaderColor'], theme),
  marginBottom: '5px',
}));

const FormShiftPicker = memo(
  ({
    additiveElements,
    shiftTitle,
    fieldErrorData,
    initialValue,
    input,
    label,
    mode,
    required,
    listViewGridSpacing,
    meta,
  }) => {
    const { onChange } = input;

    const [listData, setListData] = React.useState([]);
    const [errors, setErrors] = React.useState(null);
    const defaultShiftStartTime = new Date(new Date().setHours(0, 0, 0, 0));
    const defaultShiftEndTime = addHours(8, defaultShiftStartTime);
    const [shiftInputData, setShiftInputData] = React.useState({
      name: '',
      start: defaultShiftStartTime,
      end: defaultShiftEndTime,
    });

    const error = errorHandler(input, meta, fieldErrorData);
    const shiftInputDataName = get('name', shiftInputData);
    const shiftInputDataStart = get('start', shiftInputData);
    const shiftInputDataEnd = get('end', shiftInputData);

    // Styling
    let textFieldBackground;
    let textFieldBackgroundHover;
    let textFieldFocusedBorder;
    let textFieldErrorBorder;
    let textFieldPrimaryLabel;
    switch (mode) {
      case 'dark':
        // FIXME: Replace when dark mode colors are out

        textFieldBackground = get(['textField', 'darkBkColor'], theme);
        textFieldBackgroundHover = get(['palette', 'secondary', 'light'], theme);
        textFieldFocusedBorder = get(['textField', 'borderColor', 'focused'], theme);
        textFieldErrorBorder = get(['textField', 'borderColor', 'error'], theme);
        textFieldPrimaryLabel = get(['textField', 'inputLabel', 'primary', 'light'], theme);

        break;
      default:
        textFieldBackground = get(['textField', 'inputLabel', 'primary', 'light'], theme);
        textFieldBackgroundHover = get(['textField', 'background', 'light'], theme);
        textFieldFocusedBorder = get(['textField', 'borderColor', 'focused'], theme);
        textFieldErrorBorder = get(['textField', 'borderColor', 'error'], theme);
        textFieldPrimaryLabel = get(['textField', 'inputLabel', 'primary', 'focused'], theme);

        break;
    }

    const styleForTextField = {
      '& legend': { display: 'none' },
      '& fieldset': { top: 0 },
      '& .MuiOutlinedInput-root': {
        backgroundColor: textFieldBackground,
        borderRadius: '40px',
        fontFamily: 'Barlow',
        '&:hover': {
          backgroundColor: textFieldBackgroundHover,
        },
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        outline: `1px solid ${textFieldFocusedBorder}`,
        backgroundColor: textFieldBackground,
      },
      '& .MuiOutlinedInput-root.Mui-error': {
        outline: `1px solid ${textFieldErrorBorder}`,
      },
      '& .MuiInputLabel-root': {
        top: '-2px',
        color: textFieldPrimaryLabel,
      },
      '& .MuiInputLabel-root.Mui-focused': {
        top: '-8px',
        color: textFieldPrimaryLabel,
      },
      '& .MuiInputLabel-root.Mui-error': {
        color: textFieldPrimaryLabel,
      },
      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(1, 7, 1, 2),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
      '& .MuiFormLabel-asterisk.Mui-error': {
        color: textFieldPrimaryLabel,
      },
      '& .MuiInputBase-root': {
        color: textFieldPrimaryLabel,
      },
      '& .MuiInputBase-root.Mui-focused': {
        fontWeight: 500,
      },
    };

    const descriptionStyle = {
      color: '#4D4D70',
      fontSize: '14px',
      fontFamily: 'Barlow',
      marginTop: '6px',
    };

    // End of Styling
    // Shift Dialog Components
    const addShiftOnClick = (setRFFValue) => {
      const foundError = shiftValidation(
        shiftInputDataName,
        shiftInputDataStart,
        listData,
        shiftInputDataEnd
      );

      if (foundError) {
        setErrors(foundError);
        return;
      }

      setListData([
        ...listData,
        {
          ...shiftInputData,
          start: timeToMinutesFromMidnight(shiftInputDataStart),
          end: timeToMinutesFromMidnight(shiftInputDataEnd),
        },
      ]);

      setShiftInputData({
        name: '',
        start: defaultShiftStartTime,
        end: defaultShiftEndTime,
      });

      setRFFValue([
        ...listData,
        {
          ...shiftInputData,
          start: timeToMinutesFromMidnight(shiftInputDataStart),
          end: timeToMinutesFromMidnight(shiftInputDataEnd),
        },
      ]);
    };

    const nameShiftInputOnchange = (event) => {
      setShiftInputData({ ...shiftInputData, name: event.target.value });
      setErrors(null);
    };

    const startTimeShiftInputOnChange = (value) => {
      value &&
        setShiftInputData({
          ...shiftInputData,
          start: new Date(value.toString()),
        });
      setErrors(null);
    };
    const endTimeShiftInputOnChange = (value) => {
      value && setShiftInputData({ ...shiftInputData, end: new Date(value.toString()) });
      setErrors(null);
    };

    const ShiftInputComponent = (
      <ShiftInputGrid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            inputProps={{ 'aria-label': 'shiftDialogTextInput' }}
            error={errors && errors.name}
            fullWidth
            label="Shift Name"
            onChange={nameShiftInputOnchange}
            placeholder="Morning Shift"
            size="small"
            value={shiftInputDataName}
            sx={styleForTextField}
          />
          <Typography sx={descriptionStyle}>
            A quick description of the shift to identify it while selecting throughout the system.{' '}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TimePickerField
            label="Start time"
            onChange={startTimeShiftInputOnChange}
            size="small"
            value={shiftInputDataStart}
            error={
              (errors && errors.start) || Number.isNaN(Date.parse(shiftInputDataStart))
                ? 'Incorrect time format'
                : undefined
            }
          />
          <Typography sx={descriptionStyle}>When the shift begins. </Typography>
        </Grid>
        <Grid item xs={6}>
          <TimePickerField
            label="End time"
            onChange={endTimeShiftInputOnChange}
            size="small"
            value={shiftInputDataEnd}
            error={
              (errors && errors.end) || Number.isNaN(Date.parse(shiftInputDataEnd))
                ? 'Incorrect time format'
                : undefined
            }
          />
          <Typography sx={descriptionStyle}>When the shift ends. </Typography>
        </Grid>
        {/* <Grid item xs={5}> */}
        {/*  FIXME:HB-951/HB-952 Paid Break needs to be implemented once express has added it to the */}
        {/*  schema, currently checkbox is disabled and does not pass value */}
        {/*  <FormGroup> */}
        {/*    <FormControlLabel control={<Checkbox disabled />} label="Paid Break" /> */}
        {/*  </FormGroup> */}
        {/* </Grid> */}
        <Grid item xs={12} sx={{ marginBottom: '10px' }}>
          <AlertComponent
            isOpen={errors}
            severity="error"
            message={(errors && Object.values(errors)[0]) || null}
          />
        </Grid>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button
              onClick={() => {
                addShiftOnClick(onChange);
              }}
              onChange={onChange}
              text={LanguageConverter('buttonText.addShift')}
            />
          </Grid>
        </Grid>
      </ShiftInputGrid>
    );

    // end of shift dialog component

    const shiftTitleCheckIfRequired = required ? `${shiftTitle}*` : shiftTitle;
    const shiftDeleteOnClick = (rowName, setRFFValue) => {
      setListData(listData.filter((row) => row.name !== rowName));
      setRFFValue(listData.filter((row) => row.name !== rowName));
    };
    return (
      <Grid container spacing={listViewGridSpacing}>
        <ShiftsTitleBox sx={{ marginBottom: theme.spacing(2) }}>Shifts</ShiftsTitleBox>
        {ShiftInputComponent}
        <Grid item xs={12} required={required}>
          <ShiftsTitleBox> {shiftTitle && shiftTitleCheckIfRequired}</ShiftsTitleBox>
          <FormHelperText
            sx={{
              color:
                hasFormSubmitError(meta) || hasServerError(meta, fieldErrorData) ? 'red' : null,
            }}
          >
            {error}
          </FormHelperText>
        </Grid>
        <Grid item xs>
          <StyledList name={input.name} label={label} values={input.values || initialValue}>
            <Box sx={{ width: '100%' }}>
              <TransitionGroup>
                {(listData.length > 0 &&
                  listData.map((rowData) => (
                    <Collapse key={`${rowData.name}`}>
                      <ListItem>
                        <Grid container flexDirection="column">
                          <StyledAddedShiftNameBox component="span" key={`${rowData.name}`}>
                            {rowData.name}
                          </StyledAddedShiftNameBox>

                          <StyledAddedShiftBox
                            component="span"
                            color="text.secondary"
                            key={`${rowData.start}`}
                          >
                            {`${minutesFromMidnightToTimeOnlyHrAndMins(
                              rowData.start
                            )}-${minutesFromMidnightToTimeOnlyHrAndMins(rowData.end)}`}
                          </StyledAddedShiftBox>
                        </Grid>
                        <IconButton
                          iconElement={<DeleteIcon />}
                          onClick={() => shiftDeleteOnClick(rowData.name, onChange)}
                        />
                      </ListItem>
                    </Collapse>
                  ))) || (
                  <Collapse>
                    <Box
                      sx={{
                        fontFamily: get(['shiftPicker', 'typography', 'assignedShifts'], theme),
                        color: get(
                          ['shiftPicker', 'palette', 'assignedShifts', 'emptyTextColor'],
                          theme
                        ),
                        fontSize: get(['shiftPicker', 'typography', 'defaultFontSize'], theme),
                        height: '72px',
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      Add a shift above to see it here.
                    </Box>
                  </Collapse>
                )}
              </TransitionGroup>
            </Box>
          </StyledList>
        </Grid>
        {additiveElements}
      </Grid>
    );
  }
);
FormShiftPicker.propTypes = {
  additiveElements: PropTypes.element,
  shiftTitle: PropTypes.string,
  fieldErrorData: PropTypes.string,
  initialValue: PropTypes.arrayOf(PropTypes.shape({})),
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    values: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  label: PropTypes.string,
  mode: PropTypes.string,
  required: PropTypes.bool,
  listViewGridSpacing: PropTypes.number,
  meta: PropTypes.shape(),
};
export default FormShiftPicker;
