import * as React from 'react';
import PropTypes from 'prop-types';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { BLACK, PRIMARY_PURPLE } from '../../theme/colorConstants';

const FavoriteHeartIcon = ({
  width = 16,
  height = 18,
  isFavorite = true,
  activeColor = PRIMARY_PURPLE[50],
  inactiveColor = BLACK[100],
}) =>
  isFavorite ? (
    <FavoriteIcon sx={{ color: activeColor, width, height }} />
  ) : (
    <FavoriteBorderIcon sx={{ color: inactiveColor, width, height }} />
  );

FavoriteHeartIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  isFavorite: PropTypes.bool,
};

export default FavoriteHeartIcon;
