import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import WaePaginatedDataGrid from '../../../components/DataGrid';
import DetailView from '../../../components/DetailView/DetailView2';
import ListHeader from '../../../components/ListHeader';
import selectUser from '../../../store/selectors/appSelector';
import {
  selectCorporations,
  selectIsLoading,
  selectTotalRowCount,
} from '../../../store/selectors/corporationListSelectors';
import theme, { dataGridFiltersHeight, listHeaderHeight, topBarHeight } from '../../../theme';
import { navigator } from '../../../utils/navigator';

import Department from './detail/Department';
import corporationListColumnData from './corporationListColumnData';
import { DNR, Favorite, Overview } from './detail';
import { getCorporationsList } from './reducer';

const RootGrid = styled(Grid)(() => ({
  height: `calc(100vh - ${topBarHeight})`,
  padding: theme.spacing(2),
}));

const CorporationList = () => {
  const dispatch = useDispatch();
  const muiTheme = useTheme();

  const isLoading = useSelector(selectIsLoading);
  const corporations = useSelector(selectCorporations);
  const mediumScreen = useMediaQuery(muiTheme.breakpoints.up('md'));
  const [selectedCorporation, setSelectedCorporation] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(10);
  const totalRowCount = useSelector(selectTotalRowCount);
  const apiPath = 'corporations/read';

  const corporationsListPaginationQuery = useCallback(
    (params) => dispatch(getCorporationsList(params)),
    [dispatch]
  );

  const user = useSelector(selectUser);

  const onSelectionModelChange = (selectedCorporationObject) => {
    setSelectedCorporation(selectedCorporationObject || null);
  };
  useEffect(() => {
    setSelectedCorporation(null);
  }, [corporations]);

  const tabData = (container) => [
    {
      tabContent: <Overview container={container} initialValues={selectedCorporation} />,
      tabLabel: 'Overview',
    },
    {
      tabContent: <Favorite initialValues={selectedCorporation} />,
      tabLabel: 'Favorite',
    },
    {
      tabContent: <DNR initialValues={selectedCorporation} />,
      tabLabel: 'DNR',
    },
    {
      tabContent: <Department initialValues={selectedCorporation} />,
      tabLabel: 'DEPT',
    },
  ];

  return (
    <RootGrid container>
      <Slide
        direction="right"
        unmountOnExit
        in={!(!mediumScreen && selectedCorporation)}
        timeout={10}
        easing={{ enter: 'step-end', exit: 'step-start' }}
      >
        <Grid
          item
          container
          md
          xs
          sm
          sx={{
            height: `calc(100% - ${listHeaderHeight} - ${dataGridFiltersHeight})`,
            marginRight: selectedCorporation && 2,
          }}
        >
          <ListHeader
            headerText="Corporations"
            buttonText={user.role === UserRole.ADMIN ? LanguageConverter('button.corp.add') : null}
            handleOnClick={() => navigator('/corporations/createCorporation')}
          />
          <Grid
            container
            sx={{
              height: '100%', // switch to this if/when filters are added: height: `calc(100% - ${listHeaderHeight})`,
              width: '100%',
            }}
          >
            {corporations && (
              <>
                <WaePaginatedDataGrid
                  apiPath={apiPath}
                  columnData={corporationListColumnData}
                  disableMultipleSelection
                  disableVirtualization
                  loading={isLoading}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onSelectionModelChange={onSelectionModelChange}
                  pageSize={pageSize}
                  page={page}
                  pagination
                  paginatedData={corporations}
                  paginationQuery={corporationsListPaginationQuery}
                  setPageSize={setPageSize}
                  setPage={setPage}
                  setRowCount={setRowCount}
                  rowCount={rowCount}
                  totalRowCount={totalRowCount}
                  sx={{
                    border: 'none',
                    height: '100%',
                    width: '100%',
                  }}
                />
                {selectedCorporation && (
                  <DetailView
                    close={() => setSelectedCorporation(null)}
                    tabData={tabData}
                    sx={{ marginLeft: theme.spacing(1.5) }}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Slide>

      {selectedCorporation && !mediumScreen && (
        <DetailView close={() => setSelectedCorporation(null)} tabData={tabData} />
      )}
    </RootGrid>
  );
};

export default CorporationList;
