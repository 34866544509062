import format from 'date-fns/format';
import enUsLocale from 'date-fns/locale/en-US';

/**
 * In the future we may need multiple languages we can change the displayLocale variable to change the language
 * for example: change the date text to Spanish
 * import esLocale from 'date-fns/locale/es';
 * check the current language selection.
 * If the language is Spanish, set displayLocale to esLocale. The output date string will be Spanish
 */
const displayLocale = enUsLocale;

export const epochToTimeInReadableFormat = (epochTime) => {
  // returns format  => "01:00 PM"
  const parsedEpochTime = parseInt(epochTime, 10);

  const date = new Date(parsedEpochTime);
  // Get the local time and time, considering daylight saving time
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const epochToDateInReadableFormat = (epochTime) => {
  const parsedEpochTime = parseInt(epochTime, 10);
  // returns in this format  => "3/29/2024"
  const d = new Date(parsedEpochTime);

  // Get the local date and time, considering daylight saving time
  return d.toLocaleDateString();
};

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const epochToDayOfWeek = (epoch) => {
  const dateObject = new Date(epoch);
  const dayIndex = dateObject.getDay();
  return weekDays[dayIndex];
};

/**
 * Strips the Hours and Minutes out of a JavaScript Date object
 * and returns the total number of minutes since midnight.
 * @param {Date} time
 * @returns int
 */
export const timeToMinutesFromMidnight = (time) => time.getHours() * 60 + time.getMinutes();

/**
 * Function that reformat javascript Date object
 * @param    {Date} date  Javascript Date Object contains date information
 * @return   {String}     More readable date string like "February 12, 2022"
 */
export const reformatDateMMMMDDYYYY = (date) => {
  try {
    return format(new Date(date), 'MMMM dd, yyyy', {
      locale: displayLocale,
    });
  } catch (e) {
    return '';
  }
};

export const minutesFromMidnightToTime = (minutes) => {
  const hours = minutes / 60;
  const flooredHours = Math.floor(minutes / 60);
  const remainderMinutes = (hours - flooredHours) * 60;
  const roundedMinutes = Math.round(remainderMinutes);

  const newDate = new Date();

  newDate.setHours(flooredHours);
  newDate.setMinutes(roundedMinutes);
  return newDate;
};

export const minutesFromMidnightToReadableTime = (minutes) => {
  const hours = minutes / 60;
  let flooredHours = Math.floor(minutes / 60);

  const remainderMinutes = (hours - flooredHours) * 60;

  // Determine AM or PM
  const ampm = flooredHours >= 12 ? 'pm' : 'am';

  const minutesToDisplay =
    remainderMinutes <= 9 ? `0${Math.round(remainderMinutes)}` : Math.round(remainderMinutes);

  if (flooredHours > 12) {
    flooredHours -= 12;
  }
  // Combine everything into the final string
  return `${flooredHours === 0 ? 12 : flooredHours}:${minutesToDisplay} ${ampm}`;
};

// returned min values is Minute
export const combineDateAndTime = (date, time) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes());

/**
 * Function that reformat javascript Date object
 * @param    {Date} date  Javascript Date Object Sat Sep 14 2024 00:00:00 GMT-0400 (Eastern Daylight Time)
 * @return   {String}     More readable date string like "02/12/2022"
 */

export const reformatDateToDDMMYYY = (date) => {
  if (date instanceof Date && !Number.isNaN(date.getTime())) {
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return `${month?.toString()?.padStart(2, '0')}/${day?.toString()?.padStart(2, '0')}/${year}`;
  }
  return 'N/A';
};

export const capitalizeToShortWeekday = (abbr) => {
  if (typeof abbr === 'string') {
    const weekdayMapping = {
      monday: 'Mon',
      tuesday: 'Tue',
      wednesday: 'Wed',
      thursday: 'Thu',
      friday: 'Fri',
      saturday: 'Sat',
      sunday: 'Sun',
    };
    // Convert the abbreviation to the full Capitalized Abbreviated weekday name
    return weekdayMapping[abbr.toLowerCase()] || 'N/A';
  }
  return 'N/A';
};
