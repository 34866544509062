import { createSlice } from '@reduxjs/toolkit';

const initialState = { verifyingIsProcessing: false, sendVerificationEmailButtonDisabled: false };

export const accountVerification = createSlice({
  name: 'accountVerificationSlice',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    verifyAccount: (state) => {
      state.verifyingIsProcessing = true;
    },
    reqVerifyAccount: (state) => {
      state.verifyingIsProcessing = true;
    },
    verifyAccountProcessed: (state) => {
      state.verifyingIsProcessing = false;
    },
    verifyAccountError: (state) => {
      state.verifyingIsProcessing = false;
    },
    passwordReset: (state) => {
      state.verifyingIsProcessing = true;
    },
    passwordResetProcessed: (state) => {
      state.verifyingIsProcessing = false;
    },
    passwordResetError: (state) => {
      state.verifyingIsProcessing = false;
    },
    disableSendVerificationEmailButton: (state, action) => {
      state.sendVerificationEmailButtonDisabled = action.payload;
    },
    /* eslint-disable no-param-reassign */
  },
});

export const {
  verifyAccount,
  reqVerifyAccount,
  verifyAccountProcessed,
  verifyAccountError,
  passwordReset,
  passwordResetError,
  passwordResetProcessed,
  disableSendVerificationEmailButton,
} = accountVerification.actions;

export const accountVerificationReducer = accountVerification.reducer;
