import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';

import BackdropCircularProgress from '../../../components/BackdropCircularProgress';
import selectUser from '../../../store/selectors/appSelector';

import formFieldData from './formData/formFieldData';
import CreateCorporationForm from './CreateCorporationForm';
import { postCorporation } from './reducer';

const CreateCorporation = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const onSubmit = useCallback(
    (values) => {
      dispatch(
        postCorporation({
          bullhornID: `${values.BullhornID}`,
        })
      );
    },
    [dispatch]
  );

  return (
    UserRole.ADMIN === user.role &&
    (<CreateCorporationForm formData={formFieldData} onSubmit={onSubmit} /> || (
      <BackdropCircularProgress />
    ))
  );
};

export default CreateCorporation;
