import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Chip, Grid } from '@mui/material';

const RenderJobInviteData = (props) => {
  const { value = [] } = props;
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '80%', backgroundColor: '#F2E9FF', borderRadius: '50px' }}
      className="failed-position-selection"
    >
      <Grid
        container
        sx={{
          padding: '10px',
        }}
        justifyContent="space-between"
      >
        <Grid
          xs
          item
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >{`${value[0]?.name || LanguageConverter('generic.search.employees')}`}</Grid>
        <Grid>
          {value.length > 1 ? (
            <Chip
              label={`+${value.length - 1}`}
              sx={{
                height: '22px',
                width: 'auto',
                backgroundColor: '#D7BDFF',
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
RenderJobInviteData.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
};
export default RenderJobInviteData;
