import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';

import { BLACK, FEED_BACK } from '../../../../theme/colorConstants';
import { PRIMARY_FONT } from '../../../../theme/fontConstants';
import { limitFillRatioTo100Percent } from '../../helperFunctions';

const useStyles = makeStyles({
  fillRatioWrapper: {
    width: '180px',
    display: 'flex',
    alignItems: 'center',
  },
  fillRatioInner: { minWidth: 35 },
  fillRatioText: {
    fontFamily: PRIMARY_FONT[300],
    fontSize: '18px',
    lineHeight: '16px',
    textAlign: 'left',
    marginRight: '5px',
  },
  fillRatioProgressWrap: { width: '60%' },
  activeBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
  },
  blockLabel: {
    color: BLACK[60],
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '8px',
  },
  blockData: {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'left',
  },
});

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 20,
  border: '1px solid black',
  marginLeft: '5px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: FEED_BACK.GREEN_70,
  },
}));

const ActiveAndUpcomingJobMetrics = ({ jobOrder }) => {
  const classes = useStyles();

  const numOfPendingInvites =
    jobOrder.placements.filter((e) => e.state.status === 'pendingEmployeeAcceptance').length +
    (jobOrder?.totalOfferedCandidatesCount || 0);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography sx={{ color: BLACK[60], textTransform: 'uppercase', fontSize: '12px' }}>
            {LanguageConverter('jobOrder.fillRate')}
          </Typography>
          <Box className={classes.fillRatioWrapper}>
            <Box className={classes.fillRatioInner}>
              <Typography className={classes.fillRatioText}>{`${limitFillRatioTo100Percent(
                jobOrder?.fillRatio
              )}%`}</Typography>
            </Box>
            <Box className={classes.fillRatioProgressWrap}>
              <BorderLinearProgress
                variant="determinate"
                value={limitFillRatioTo100Percent(jobOrder?.fillRatio)}
              />
            </Box>
          </Box>
        </Box>
        {jobOrder?.averageRosterReliability && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
              sx={{
                color: BLACK[60],
                fontSize: '12px',
                whiteSpace: 'nowrap',
              }}
            >
              {LanguageConverter('jobOrder.rosterReliability')}
            </Typography>
            <Typography sx={{ fontSize: '16px', lineHeight: '16px', textAlign: 'left' }}>
              {(jobOrder.averageRosterReliability * 100).toFixed(0)}%
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box className={classes.activeBlock}>
          <Typography className={classes.blockLabel}>
            {LanguageConverter('roster.form.openings')}
          </Typography>
          <Typography className={classes.blockData}>{jobOrder.numOpenings}</Typography>
        </Box>
        <Box className={classes.activeBlock}>
          <Typography className={classes.blockLabel}>
            {LanguageConverter('roster.form.placements')}
          </Typography>
          <Typography className={classes.blockData}>{jobOrder.placementsCount}</Typography>
        </Box>
        <Box className={classes.activeBlock}>
          <Typography className={classes.blockLabel}>
            {LanguageConverter('roster.form.pendingInvites')}
          </Typography>
          <Typography className={classes.blockData} data-testid="numOfPendingInvites-test">
            {numOfPendingInvites}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

ActiveAndUpcomingJobMetrics.propTypes = {
  jobOrder: PropTypes.shape({
    averageRosterReliability: PropTypes.number,
    fillRatio: PropTypes.number,
    numOpenings: PropTypes.number,
    placementsCount: PropTypes.number,
    placements: PropTypes.arrayOf(PropTypes.shape({})),
    totalOfferedCandidatesCount: PropTypes.number,
  }),
};

export default ActiveAndUpcomingJobMetrics;
