import get from 'lodash/fp/get';
import { createSelector } from 'reselect';

const selectCreatePositionTemplate = (state) => get('createPositionTemplate', state);

export const selectIsProcessingPostPT = createSelector(
  selectCreatePositionTemplate,
  (createPositionTemplate) => createPositionTemplate.isProcessingPostPT
);

export const selectPostPTError = createSelector(
  selectCreatePositionTemplate,
  (createPositionTemplate) => createPositionTemplate.postPTError
);
