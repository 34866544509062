import React from 'react';
import PropTypes from 'prop-types';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import theme from '../../../../theme';
import CheckBox from '../../../CheckBox/CheckBox';
import { checkAllCheckboxID } from '../constants';

const MapFiltersSidebar = ({ title, list = [], onChange, onClose }) => {
  const gpsMapMarkersFilterSidebar = theme?.components?.gpsMapMarkersFilterSidebar;
  const checkBoxSx = {
    '& .MuiCheckbox-root': {
      color: gpsMapMarkersFilterSidebar?.checkbox?.color,
    },
    '& .MuiFormControlLabel-label': {
      color: gpsMapMarkersFilterSidebar?.checkbox?.fontColor,
      fontSize: gpsMapMarkersFilterSidebar?.checkbox?.fontSize,
      fontFamily: gpsMapMarkersFilterSidebar?.checkbox?.fontFamily,
    },
  };
  const scrollBarStyles = {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      background: theme?.components?.scrollBar?.trackColor,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme?.components?.scrollBar?.thumbColor,
      borderRadius: '50px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme?.components?.scrollBar?.thumbHover,
    },
  };

  return (
    <Grid
      container
      direction="column"
      sx={{ height: '100%', width: '300px', backgroundColor: gpsMapMarkersFilterSidebar?.bgColor }}
      data-testid="mocked-map-filters-sidebar"
    >
      <Grid
        container
        justifyContent="flex-end"
        sx={{
          width: `100%`,
          top: 0,
        }}
      >
        <IconButton
          sx={{ color: gpsMapMarkersFilterSidebar?.iconButtonColor }}
          aria-label="close"
          onClick={onClose}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Grid>
      <Grid
        item
        container
        xs
        sx={{ padding: theme.spacing(0, 4, 3, 4) }}
        justifyContent="center"
        overflow="auto"
        direction="column"
      >
        <Grid container overflow="auto" direction="row" sx={scrollBarStyles}>
          {title && (
            <Typography
              sx={{
                width: '100%',
                marginBottom: '16px',
                color: gpsMapMarkersFilterSidebar?.title.fontColor,
                fontSize: gpsMapMarkersFilterSidebar?.title.fontSize,
                fontFamily: gpsMapMarkersFilterSidebar?.title.fontFamily,
              }}
            >
              {title}
            </Typography>
          )}
          {list.length > 1 && (
            <Grid container>
              <CheckBox
                checked={!list.find((i) => i.checked === false)}
                sx={checkBoxSx}
                label="All"
                name={checkAllCheckboxID}
                onChange={onChange}
              />
            </Grid>
          )}
          {list.length === 0 && (
            <Typography
              sx={{
                color: gpsMapMarkersFilterSidebar?.noDataText.fontColor,
                fontSize: gpsMapMarkersFilterSidebar?.noDataText.fontSize,
                fontFamily: gpsMapMarkersFilterSidebar?.noDataText.fontFamily,
              }}
            >
              {LanguageConverter('map.noCoordinates')}
            </Typography>
          )}
          {list?.map((item) => (
            <Grid container key={item?._id}>
              <CheckBox
                checked={item?.checked}
                sx={checkBoxSx}
                label={item.name}
                name={item?._id}
                onChange={onChange}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

MapFiltersSidebar.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      radius: PropTypes.number,
    })
  ),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default MapFiltersSidebar;
