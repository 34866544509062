import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import theme from '../../theme';

import { BUTTON_TYPE, BUTTON_VARIANT } from './buttonConstants';
import buttonStylesByVariant from './buttonStylesByVariant';

const WaeButton = ({
  actionColor,
  borderColor,
  hoverColor,
  children,
  color,
  dataTestId,
  disabled,
  form,
  fullWidth,
  onClick,
  showIndeterminateProgress,
  startIcon,
  endIcon,
  sx,
  text,
  type,
  variant,
}) => {
  const border = borderColor
    ? `2px solid ${borderColor}`
    : buttonStylesByVariant[variant]?.border || buttonStylesByVariant?.DEFAULT?.border;

  const buttonBgColor =
    actionColor ||
    buttonStylesByVariant[variant]?.buttonBgColor ||
    buttonStylesByVariant?.DEFAULT?.hoverBgColor;

  const hoverBgColor =
    hoverColor ||
    buttonStylesByVariant[variant]?.hoverBgColor ||
    buttonStylesByVariant?.DEFAULT?.hoverBgColor;

  const outLine =
    actionColor ||
    buttonStylesByVariant[variant]?.outLine ||
    buttonStylesByVariant?.DEFAULT?.outLine;

  const outlinedButtonTextStyle =
    buttonStylesByVariant[variant]?.outlinedButtonTextStyle ||
    buttonStylesByVariant?.DEFAULT?.outlinedButtonTextStyle;

  const outlinedButtonHoverTextStyle =
    buttonStylesByVariant[variant]?.outlinedButtonHoverTextStyle ||
    buttonStylesByVariant?.DEFAULT?.outlinedButtonHoverTextStyle;

  const outlinedOnFocusStyle =
    buttonStylesByVariant[variant]?.outlinedOnFocusStyle ||
    buttonStylesByVariant?.DEFAULT?.outlinedOnFocusStyle;

  const waeStyle =
    variant !== BUTTON_VARIANT.TEXT
      ? {
          background: `${buttonBgColor}`,
          border,
          boxShadow: 'none',
          color: `${theme.button.palette.text}`,
          borderRadius: `${theme.button.defaultBorderRadius}`,
          ...outlinedButtonTextStyle,
          transition: 'none',
          '&:hover, &.Mui-hovered': {
            background: `${hoverBgColor}`,
            boxShadow: 'none',
            ...outlinedButtonHoverTextStyle,
            '& .MuiSvgIcon-root': {
              color: 'white',
            },
          },
          '&:focus': {
            boxShadow: 'none',
            ...outLine,
            ...outlinedOnFocusStyle,
          },
          '&.Mui-disabled': {
            color: `${theme.button.palette.text}`,
            opacity: '0.45',
          },
        }
      : {};

  return (
    <Button
      data-testid={dataTestId}
      disabled={disabled}
      color={color}
      form={form}
      fullWidth={fullWidth}
      onClick={onClick}
      size="small"
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        fontFamily: `${theme.button.fontFamily}`,
        fontSize: `${theme.button.fontSize}`,
        padding: theme.spacing(0.67, 2),
        height: '37.7px',
        minWidth: 'max-content',
        cursor: 'pointer',
        ...waeStyle,
        ...sx,
      }}
      style={{ textTransform: 'none' }}
      type={type}
      disableFocusRipple
    >
      {text} {children}
      {showIndeterminateProgress && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
          }}
        />
      )}
    </Button>
  );
};

WaeButton.propTypes = {
  actionColor: PropTypes.string,
  borderColor: PropTypes.string,
  hoverColor: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.element,
  form: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  showIndeterminateProgress: PropTypes.bool,
  startIcon: PropTypes.element,
  sx: PropTypes.shape({}),
  text: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

export { BUTTON_TYPE, BUTTON_VARIANT, WaeButton };
