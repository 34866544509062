import * as React from 'react';
import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Button, { BUTTON_VARIANT } from '../../../../../components/Button';
import { selectCreatePlacementErrorMessage } from '../../../../../store/selectors/candidateJobsListSelectors';
import theme from '../../../../../theme/theme';

const ErrorDrawer = ({ onClose }) => {
  const errorMessage = useSelector(selectCreatePlacementErrorMessage);
  const intl = useIntl();
  const componentWorkFlowFont = ['components', 'workflow', 'typography'];

  const ConfirmationBodyBox = styled(Box)(() => ({
    minHeight: '50vh',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  }));

  const BodyBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(16, 8, 5),
    height: '100%',
    minWidth: '100%',
    backgroundColor: get(
      [
        'components',
        'workflow',
        'palette',
        'background',
        'default',
        'transitionPageBackgroundColor',
      ],
      theme
    ),
  }));

  const headerSx = {
    fontSize: get(['components', 'workflow', 'typography', 'defaultTitleFontSize'], theme),
    color: get(
      ['components', 'workflow', 'palette', 'font', 'default', 'transitionPageFontColor'],
      theme
    ),

    fontFamily: get([...componentWorkFlowFont, 'boldFont'], theme),
  };

  const messageSx = {
    fontSize: get(['components', 'workflow', 'typography', 'defaultFontSize'], theme),
    color: get(
      ['components', 'workflow', 'palette', 'font', 'default', 'transitionPageFontColor'],
      theme
    ),

    fontFamily: get([...componentWorkFlowFont, 'defaultFont'], theme),
  };

  const ButtonSx = {
    width: '30%',
  };

  return (
    <BodyBox>
      <ConfirmationBodyBox>
        <Typography sx={headerSx}>We&apos;re Sorry.</Typography>
        <Typography sx={headerSx}>You were not signed up to work!</Typography>
        <Typography sx={messageSx}>{intl.formatMessage({ id: errorMessage })}</Typography>

        <Button
          sx={ButtonSx}
          variant={BUTTON_VARIANT.OUTLINED}
          onClick={() => onClose()}
          text={LanguageConverter('buttonText.goBack')}
        />
      </ConfirmationBodyBox>
    </BodyBox>
  );
};

export default ErrorDrawer;

ErrorDrawer.propTypes = {
  onClose: PropTypes.func,
};
