import FormTypographyBox from '../../../components/Form/FormTypographyBox';
import {
  fieldHeaderStyling,
  fieldTextStyling,
  sectionHeaderStyling,
  sectionSubTextStyling,
} from '../styling';

const ProfileSettingsData = () => [
  {
    ComponentType: FormTypographyBox,
    key: 'profileSettingsHeader',
    name: 'profileSettingsHeader',
    sx: sectionHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'profileSettingsSubText',
    name: 'profileSettingsSubText',
    sx: sectionSubTextStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'profileSettingsFullName',
    name: 'profileSettingsFullName',
    sx: fieldHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'profileSettingsFullNameValue',
    name: 'profileSettingsFullNameValue',
    sx: fieldTextStyling,
  },
];

export default ProfileSettingsData;
