/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getOr } from 'lodash/fp';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// TODO: Use search params instead of the redux to pick groups
import { useSearchParams } from 'react-router-dom';

import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, Grid, Typography } from '@mui/material';

import BackdropCircularProgress from '../../../../components/BackdropCircularProgress';
import MuiIconButton from '../../../../components/IconButton/IconButton';
import LazyList from '../../../../components/LazyList/LazyList';
import selectUser from '../../../../store/selectors/appSelector';
import {
  selectCreatedPlacements,
  selectCreatePlacementErrorMessage,
  selectGroupsJobs,
  selectGroupsJobsCount,
  selectGroupsJobsPage,
  selectIsLoadingGroupsJobs,
  selectIsProcessingCreatePlacement,
  selectSelectedGroup,
} from '../../../../store/selectors/candidateJobsListSelectors';
import theme from '../../../../theme';
import { calculateTotalPay } from '../../../../utils/Candidate/payHelpers';
import {
  getGroupsJobOrders,
  removeErrorMessage,
  resetCandidateJobsList,
  setPlacementsInitState,
} from '../reducer';

import AvailableShiftCard from './SubComponents/AvailableShiftCard';
import ErrorDrawer from './SubComponents/ErrorDrawer';
import NoShiftsCard from './SubComponents/NoShiftsCard';
import RequiredSection from './SubComponents/RequiredSection';
import SignUpForShifts from './SubComponents/SignUpForShifts';
import SuccessDrawer from './SubComponents/SuccessDrawer';
import {
  signUpTextSx,
  sxForAddress,
  sxForContentContainer,
  sxForDescription,
  sxForLabels,
  sxForMainContainer,
  sxForMainHeader,
  sxForSection,
  sxForSignUpButton,
  sxForValue,
  sxForWrapper,
} from './DetailSx';

const AllJobsDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const selectedGroup = useSelector(selectSelectedGroup);
  const user = useSelector(selectUser);
  const isLoadingGroupsJobs = useSelector(selectIsLoadingGroupsJobs);
  const groupsJobs = useSelector(selectGroupsJobs);
  const jobsCount = useSelector(selectGroupsJobsCount);
  const jobsPage = useSelector(selectGroupsJobsPage);
  const selectedPlacements = useSelector(selectCreatedPlacements);

  const isEmployerApprovalRequired = getOr(false, 'requireEmployerApproval', get(0, groupsJobs));
  const errorMessage = useSelector(selectCreatePlacementErrorMessage);

  const [filters, setFilters] = useState([]);
  const [certsHeld, setCertsHeld] = useState([]);
  const [certsNotHeld, setCertsNotHeld] = useState([]);
  const [skillsHeld, setSkillsHeld] = useState([]);
  const [skillsNotHeld, setSkillsNotHeld] = useState([]);

  const [selectedJobs, setSelectedJobs] = useState([]);

  const isProcessingCreatePlacement = useSelector(selectIsProcessingCreatePlacement);

  // TODO: Use search params instead of the redux to pick groups
  // const positionTemplateId = searchParams.get('positionTemplateId');
  // const locationId = searchParams.get('locationId');
  // const shiftGroup = searchParams.get('shiftGroup');

  // useEffect(() => {
  //   console.log(positionTemplateId, locationId, shiftGroup);
  // }, []);

  useEffect(() => {
    // TODO: Use search params instead of the redux to pick groups
    // setSearchParams({
    //   positionTemplateId: selectedGroup.positionTemplate,
    //   locationId: selectedGroup.location._id,
    //   shiftGroup: selectedGroup.multipleShiftCommitment
    //     ? `${selecstedGroup.multipleShiftCommitment}`
    //     : null,
    // });

    const groupFilters = [
      {
        field: 'positionTemplate',
        operation: 'equalsID',
        value: `${selectedGroup.positionTemplate}`,
      },
      {
        field: 'location',
        operation: 'equalsID',
        value: `${selectedGroup.location._id}`,
      },
      {
        field: 'shiftGroup',
        operation: selectedGroup.multipleShiftCommitment ? 'equalsID' : 'equals',
        value: selectedGroup.multipleShiftCommitment
          ? `${selectedGroup.multipleShiftCommitment}`
          : null,
      },
      {
        operation: 'equals',
        field: 'cancelled',
        value: false,
      },
      {
        operation: 'after',
        field: 'end',
        value: +new Date(),
      },
      {
        operation: 'equals',
        field: 'full',
        value: false,
      },
      {
        operation: 'equals',
        field: 'isConflicting',
        value: false,
      },
      {
        operation: 'equals',
        field: 'isOverTimeMultiCorp',
        value: false,
      },
    ];

    const certsUserHas = [];
    const certsUserDoesNotHave = [];

    const usersCerts = user.candidate.certifications;

    // eslint-disable-next-line array-callback-return
    selectedGroup.certifications.map((cert) => {
      if (usersCerts.includes(cert._id)) {
        certsUserHas.push(cert);
      } else {
        certsUserDoesNotHave.push(cert);
      }
    });
    const skillsUserHas = [];
    const skillsUserDoesNotHave = [];

    const usersSkills = user.candidate.skills?.map((sk) => sk.skill);

    selectedGroup.skills &&
      selectedGroup.skills.forEach((skill) => {
        if (usersSkills.includes(skill._id)) {
          skillsUserHas.push(skill);
        } else {
          skillsUserDoesNotHave.push(skill);
        }
      });

    setCertsHeld(certsUserHas);
    setCertsNotHeld(certsUserDoesNotHave);
    setSkillsHeld(skillsUserHas);
    setSkillsNotHeld(skillsUserDoesNotHave);
    setFilters(groupFilters);
    setSelectedJobs([]);
  }, [selectedGroup]);

  const getNextData = () => {
    if (!isLoadingGroupsJobs && filters.length > 0) {
      const reqParams = {
        page: jobsPage,
        limit: 5,
        sortBy: [{ field: 'start', descending: false }],
        filters,
      };
      dispatch(getGroupsJobOrders(reqParams));
    }
  };

  useEffect(() => {
    if (filters.length > 0) {
      getNextData();
    }
  }, [filters]);

  const processShiftSelectionClick = (jobId) => {
    const findResult = selectedJobs.find((job) => job._id === jobId);
    if (findResult) {
      const newSelectedArray = selectedJobs.filter((job) => job._id !== jobId);
      setSelectedJobs(newSelectedArray);
    } else {
      const jobOrder = groupsJobs.find((job) => job._id === jobId);
      const newSelectedArray = [...selectedJobs, jobOrder];
      setSelectedJobs(newSelectedArray);
    }
  };

  const getSignUpMessage = () => {
    let message = '';
    if (!selectedGroup.multipleShiftCommitment) {
      message =
        selectedJobs.length === 1
          ? `Sign up for ${selectedJobs.length} shift`
          : `Sign up for ${selectedJobs.length} shifts`;
    } else if (groupsJobs.length < jobsCount) {
      message = `View all shifts before signing up!`;
    } else {
      message = `Sign up for ${groupsJobs.length} shifts`;
    }
    return message;
  };

  const AvailableShiftCardWithData = ({ item }) => (
    <AvailableShiftCard
      isSelectable={!selectedGroup.multipleShiftCommitment && certsNotHeld.length === 0}
      start={item.start}
      end={item.end}
      payRate={item.payRateReg}
      onClick={processShiftSelectionClick}
      id={item._id}
      selected={!!selectedJobs.find((job) => job._id === item._id)}
    />
  );

  AvailableShiftCardWithData.propTypes = {
    item: PropTypes.shape({
      _id: PropTypes.string,
      start: PropTypes.number,
      end: PropTypes.number,
      payRateReg: PropTypes.number,
    }),
  };

  const shouldShowSignUpMessage =
    (!!selectedGroup.multipleShiftCommitment || selectedJobs.length > 0) &&
    certsNotHeld.length === 0;

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    if (selectedPlacements && isOpen) {
      setSelectedJobs([]);
      dispatch(setPlacementsInitState());
    }
    if (errorMessage && isOpen) {
      dispatch(removeErrorMessage());
    }
    setIsOpen(!isOpen);
  };

  const handleSuccessDrawerOnClose = () => {
    toggleOpen();
    dispatch(resetCandidateJobsList());
    if (searchParams) {
      setSearchParams({});
    }
  };

  return (
    <Grid item container sx={sxForMainContainer}>
      <Grid item sx={sxForContentContainer}>
        <Box
          sx={{
            width: '100%',
            paddingBottom: shouldShowSignUpMessage ? '1vh' : 0,
          }}
        >
          <Box>
            <Typography sx={sxForLabels}>{selectedGroup.corporation.name}</Typography>
            <Typography sx={sxForMainHeader}> {selectedGroup.title} </Typography>
            <Box>
              <Typography sx={sxForAddress}> {selectedGroup.location.address} </Typography>
              <Typography sx={sxForAddress}>
                {selectedGroup.location.city}, {selectedGroup.location.state}
                {selectedGroup.location.zipcode}
              </Typography>
            </Box>
          </Box>

          <Box sx={sxForSection}>
            <Box sx={sxForWrapper}>
              <Box sx={{ marginRight: theme.spacing(4) }}>
                <Typography sx={sxForValue}>
                  {calculateTotalPay(get('totalPay', selectedGroup))}
                </Typography>
                <Typography sx={sxForLabels}>Est. total earnings</Typography>
              </Box>
              <Box>
                <Typography sx={sxForValue}>${get('payRateReg', selectedGroup)}/hr</Typography>
                <Typography sx={sxForLabels}>Hourly Rate</Typography>
              </Box>
            </Box>
            <Box>
              <Typography sx={sxForValue}>
                {get(['location', 'description'], selectedGroup)}
              </Typography>
              <Typography sx={sxForLabels}>Location name</Typography>
            </Box>
          </Box>

          <Box sx={sxForSection}>
            <Typography sx={sxForDescription}>{get('description', selectedGroup)}</Typography>
          </Box>

          <Box sx={sxForSection}>
            <Typography sx={{ ...sxForValue, marginBottom: theme.spacing(1) }}>
              Required Certifications
            </Typography>
            {selectedGroup.certifications && selectedGroup.certifications.length > 0 ? (
              <RequiredSection certsOrSkillsHeld={certsHeld} certsOrSkillsNotHeld={certsNotHeld} />
            ) : (
              <Typography sx={{ ...sxForLabels, marginBottom: theme.spacing(1) }}>
                There are no required certifications for this job
              </Typography>
            )}
          </Box>
          <Box sx={sxForSection}>
            <Typography sx={{ ...sxForValue, marginBottom: theme.spacing(1) }}>
              Required Skills
            </Typography>
            {selectedGroup.skills && selectedGroup.skills.length > 0 ? (
              <RequiredSection
                certsOrSkillsHeld={skillsHeld}
                certsOrSkillsNotHeld={skillsNotHeld}
              />
            ) : (
              <Typography sx={{ ...sxForLabels, marginBottom: theme.spacing(1) }}>
                There are no required Skills for this job
              </Typography>
            )}
          </Box>

          <Box sx={sxForSection}>
            <Typography sx={{ ...sxForValue, marginBottom: theme.spacing(1) }}>
              Available Shifts
            </Typography>

            <LazyList
              backgroundColor={get(
                ['components', 'detailView', 'palette', 'backgroundColor'],
                theme
              )}
              Card={AvailableShiftCardWithData}
              getNextData={getNextData}
              items={groupsJobs || []}
              itemCount={jobsCount}
              skeletonVariant="Text"
              skeletonBackgroundColor={theme.components.detailView.palette.lightLazyLoadColor}
              NoDataCard={NoShiftsCard}
            />
          </Box>
        </Box>
      </Grid>

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleOpen}
        PaperProps={{ sx: { width: '500px', maxWidth: '100vw' } }}
      >
        {!selectedPlacements && !errorMessage && (
          <>
            <Box sx={{ width: '100%' }}>
              <MuiIconButton
                iconElement={<CloseIcon />}
                onClick={toggleOpen}
                size="small"
                sx={{ marginLeft: 0.5, marginTop: 0.5 }}
              />
            </Box>
            <SignUpForShifts
              selectedJobs={selectedGroup.multipleShiftCommitment ? groupsJobs : selectedJobs}
              multipleShiftCommitment={selectedGroup.multipleShiftCommitment}
            />
          </>
        )}
        {isProcessingCreatePlacement && <BackdropCircularProgress />}
        {selectedPlacements && (
          <SuccessDrawer
            onClose={handleSuccessDrawerOnClose}
            header={LanguageConverter(
              isEmployerApprovalRequired
                ? 'candidate.signUp.employerApprovalRequired.header'
                : 'candidate.signUp.confirmation.header',
              selectedPlacements.length
            )}
            message={LanguageConverter(
              isEmployerApprovalRequired
                ? 'candidate.signUp.employerApprovalRequired.message'
                : 'candidate.signUp.confirmation.message',
              selectedPlacements.length
            )}
          />
        )}
        {errorMessage && <ErrorDrawer onClose={toggleOpen} />}
      </Drawer>

      {shouldShowSignUpMessage && (
        <Box
          sx={sxForSignUpButton}
          data-testid="signUpForShift"
          onClick={
            groupsJobs.length < jobsCount && selectedGroup.multipleShiftCommitment
              ? null
              : toggleOpen
          }
        >
          <Typography sx={signUpTextSx}>{getSignUpMessage()}</Typography>
        </Box>
      )}
    </Grid>
  );
};

export default AllJobsDetail;
