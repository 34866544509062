import { isRequiredFieldEmptyBoolean } from '../validations';

/**
 * @description builds error helper text for textfield component
 * @param {String} value input value of textfield
 * @param {Boolean} required boolean for if textfield is required
 * @returns {String} a string to display error message
 */

export const getRadioButtonFieldError = ({ value, required }) => {
  if (isRequiredFieldEmptyBoolean(value, required)) {
    return 'error.field.required';
  }

  return undefined;
};

export default getRadioButtonFieldError;
