import FormTypographyBox from '../../../components/Form/FormTypographyBox';
import {
  fieldHeaderStyling,
  fieldTextStyling,
  mainHeaderStyling,
  sectionHeaderStyling,
  sectionSubTextStyling,
} from '../styling';

const AccountSettingsData = () => [
  {
    ComponentType: FormTypographyBox,
    key: 'settingsHeader',
    name: 'settingsHeader',
    sx: mainHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'accountSettingsHeader',
    name: 'accountSettingsHeader',
    sx: sectionHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'accountSettingsSubText',
    name: 'accountSettingsSubText',
    sx: sectionSubTextStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'accountSettingsEmail',
    name: 'accountSettingsEmail',
    sx: fieldHeaderStyling,
  },
  {
    ComponentType: FormTypographyBox,
    key: 'accountSettingsEmailValue',
    name: 'accountSettingsEmailValue',
    sx: fieldTextStyling,
  },
];

export default AccountSettingsData;
