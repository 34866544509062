import React from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';

import { ACTION_BLUE } from '../../../../../theme/colorConstants';

const LinearExpandMore = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={0} y2={1}>
        <stop offset={0} stopColor={`${ACTION_BLUE.ACTION_CONTRAST_REST}`} />
        <stop offset={1} stopColor={`${ACTION_BLUE.ACTION_BLUE_REST}`} />
      </linearGradient>
    </svg>
    <ExpandMore sx={{ fill: 'url(#linearColors)' }} />
  </>
);
export default LinearExpandMore;
