import React, { useEffect, useState } from 'react';
import { getOr } from 'lodash/fp';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import { batch, useDispatch, useSelector } from 'react-redux';

import candidateUpdateUserSchema from '@careerstart/wae-common/schema/users/patch/candidate.req.json';
import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import WaeButton from '../../components/Button';
import Form from '../../components/Form';
import { validateSchema } from '../../components/Form/validations';
import Zendesk from '../../components/Zendesk/Zendesk';
import selectUser from '../../store/selectors/appSelector';
import { selectSkills } from '../../store/selectors/settingsSelector';
import theme, { navigationBarHeight } from '../../theme';
import { BLACK } from '../../theme/colorConstants';
import { updateUserData } from '../app/reducer';
import PasswordUpdate from '../passwordUpdate/PasswordUpdate';
import { updateUser } from '../users/reducer';

import AccountSettingsData from './FormData/AccountSettingsData';
import ProfileSettingsData from './FormData/ProfileSettingsData';
import JobPreferenceSettingForm from './JobPreferenceSettingForm';
import NotificationSettingForm from './NotificationSettingForm';
import { getCertificationsForSettings, getUserInfo, updateSkillsForSettings } from './reducer';
import SkillsAndCertsSettingForm from './SkillsAndCertsSettingForm';
import { fieldBlockStyling } from './styling';

const RootGrid = styled(Grid)(() => ({
  flexDirection: 'column',
  top: navigationBarHeight,
  width: '42vw',

  padding: theme.spacing(0, 2, 2, 2),
}));

const Settings = () => {
  const dispatch = useDispatch();
  const [passwordOpen, setPasswordOpen] = useState(false);
  const userSkills = useSelector(selectSkills);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(getCertificationsForSettings());
    dispatch(getUserInfo());
  }, [dispatch]);

  const { REACT_APP_EMPLOYER_ZENDESK_KEY: EMPLOYER_ZENDESK_KEY } = process.env;

  const toggleChangePasswordOpen = () => {
    setPasswordOpen(!passwordOpen);
  };

  const handleNotificationSettingSubmit = (formData) => {
    const fieldToUpdate = {
      candidate: {
        phoneNumber: formData.notificationSettingsPhoneValue,
      },
    };

    const candidateObject = {
      candidate: {
        ...user.candidate,
        phoneNumber: formData.notificationSettingsPhoneValue,
      },
    };

    batch(() => {
      if (
        Object.keys(
          validateSchema({ id: user.id, fields: fieldToUpdate }, candidateUpdateUserSchema)
        )
      ) {
        dispatch(updateUser({ id: user.id, fields: fieldToUpdate }));
      }
      dispatch(
        updateUserData({
          ...user,
          ...candidateObject,
        })
      );
    });
  };

  const handleCandidateJobPreferenceOnSubmit = (formData) => {
    const fieldToUpdate = {
      candidate: {
        industry: Object.keys(formData.industry).filter((value) => formData.industry[value]),
        distance: formData.distance.value,
      },
    };

    const candidateObject = {
      candidate: {
        ...user.candidate,
        industry: Object.keys(formData.industry).filter((value) => formData.industry[value]),
        distance: formData.distance.value,
      },
    };

    batch(() => {
      dispatch(updateUser({ id: user.id, fields: fieldToUpdate }));
      dispatch(updateUserData({ ...user, ...candidateObject }));
    });
  };

  const handleCandidateSkillsAndCertsOnSubmit = (data) => {
    const skillData = getOr([], 'skills', data);
    dispatch(
      updateSkillsForSettings({
        candidates: [user.id],
        skills: skillData.map((s) => s.id || s._id),
      })
    );
  };
  return (
    <RootGrid container>
      <Zendesk active zendeskKey={EMPLOYER_ZENDESK_KEY} horizontal="left" />
      <Grid item>
        <Form
          formFieldData={AccountSettingsData({})}
          initialValues={{
            settingsHeader: 'Settings',
            accountSettingsHeader: 'Account Settings',
            accountSettingsSubText: `
            Your email, phone number, and password control your access to WAE on any device.
            Never give out your credentials to anyone!`,
            accountSettingsEmail: 'Email',
            accountSettingsEmailValue: get('email', user),
          }}
          onSubmit={() => {}}
        />
        <Box sx={fieldBlockStyling}>
          {passwordOpen ? (
            <PasswordUpdate cancelFunction={toggleChangePasswordOpen} />
          ) : (
            <WaeButton
              sx={{ color: BLACK[0], padding: theme.spacing(1, 3, 1, 3) }}
              onClick={() => toggleChangePasswordOpen()}
              text="Change Password"
            />
          )}
        </Box>
      </Grid>
      <Grid item>
        <Form
          formFieldData={ProfileSettingsData({})}
          initialValues={{
            profileSettingsHeader: 'Profile Settings',
            profileSettingsSubText: 'Manage your avatar and username.',
            profileSettingsFullName: 'Full Name',
            profileSettingsFullNameValue: get('name', user),
          }}
          onSubmit={() => {}}
        />
      </Grid>
      <Grid sx={{ margin: theme.spacing(5, 0, 0, 0) }} item>
        <NotificationSettingForm
          handleOnSubmitNotificationSetting={handleNotificationSettingSubmit}
          user={user}
        />
      </Grid>
      {user.role === UserRole.CANDIDATE && (
        <Grid sx={{ margin: theme.spacing(5, 0, 0, 0) }} item>
          <JobPreferenceSettingForm
            handleJobPreferenceOnSubmit={handleCandidateJobPreferenceOnSubmit}
            user={user}
          />
        </Grid>
      )}
      {user.role === UserRole.CANDIDATE && (
        <Grid sx={{ margin: theme.spacing(5, 0, 0, 0) }} item>
          <SkillsAndCertsSettingForm
            handleSkillsAndCertsOnSubmit={handleCandidateSkillsAndCertsOnSubmit}
            user={user}
            skills={userSkills}
          />
        </Grid>
      )}
    </RootGrid>
  );
};
Settings.propTypes = {
  flags: PropTypes.shape({}),
};
export default Settings;
