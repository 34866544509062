import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import UserRole from '@careerstart/wae-common/src/main/constants/user-role';
import LanguageConverter from '@careerstart/wae-common/src/main/helperFunction/LanguageConverter';
import { Box, CircularProgress, Typography } from '@mui/material';

import CorporationIcon from '../../assets/icons/CorporationIcon.svg';
import PercentIcon from '../../assets/icons/PercentIcon.svg';
import StatusIcon from '../../assets/icons/StatusIconInFilters.svg';
import WaeButton, { BUTTON_VARIANT } from '../../components/Button';
import DateRangePickerFilter from '../../components/DateRangePickerFilter/DateRangePickerFilter';
import FreeTextSearchFilter, { SEARCHBAR_BACKGROUND } from '../../components/FreeTextSearchFilter';
import MultipleSelectDropdownChip from '../../components/MultipleSelectDropdownChip/MultipleSelectDropdownChip';
import NoResultsCard from '../../components/NoResultsCard/NoResultsCard';
import { SearchableSelectDropdownFilter } from '../../components/SearchableSelectDropdownFilter/SearchableSelectDropdownFilter';
import { SelectDropdownFilter } from '../../components/SelectDropdownFilter/SelectDropdownFilter';
import ShiftCard from '../../components/ShiftCard/ShiftCard';
import selectUser from '../../store/selectors/appSelector';
import {
  selectIsJobOrdersLoading,
  selectJobOrders,
  selectJobOrdersGroupedByStart,
  selectJobOrdersTotalRowCount,
  selectTimeCardIsUpdating,
  selectTimecardUpdateError,
} from '../../store/selectors/jobOrdersSelector';
import { PRIMARY_COLOR } from '../../theme/colorConstants';
import { getTimeZone } from '../../utils';

import FILTERS from './filterConstants';
import {
  FIVE_AM_IN_MIN,
  INITIAL_FILTERS,
  JOB_ORDER_STATUSES,
  NINE_THIRTY_PM_IN_MIN,
  ONE_THIRTY_PM_IN_MIN,
  SORT_VALUES,
  STATUS_UPDATE_ACTION,
} from './jobOrderConstants';
import { getNewShiftFilters, getTimeStatus } from './jobOrderHelpers';
import {
  clearTimeCardError,
  getJobOrders,
  postPlacementApproval,
  postPlacementFinalize,
  postPlacementResolve,
  postPlacementUnFinalize,
  updateJobOrderCards,
  updatePlacementCheckInStatus,
  updateTimeCard,
} from './jobOrdersReducer';

const corporationsAPICallback = {
  httpMethod: 'POST',
  route: 'corporations/read',
  generateBody: (searchTerm) => ({
    filters: [{ operation: 'icontains', field: 'name', value: searchTerm }],
  }),
};

const statusOptions = [
  { value: false, name: 'Active' },
  { value: true, name: 'Cancelled' },
];

const fillRateOptions = [
  { value: true, name: 'Full Shifts' },
  { value: false, name: 'Unfilled Shifts' },
];

const shiftOptionsPayload = {
  '05:00 AM - 01:30 PM': {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: FIVE_AM_IN_MIN, end: ONE_THIRTY_PM_IN_MIN, zone: getTimeZone() },
  },
  '01:30 PM - 09:30 PM': {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: ONE_THIRTY_PM_IN_MIN, end: NINE_THIRTY_PM_IN_MIN, zone: getTimeZone() },
  },
  '09:30 PM - 05:00 AM': {
    field: 'shift',
    operation: 'betweenClockTimes',
    value: { start: NINE_THIRTY_PM_IN_MIN, end: FIVE_AM_IN_MIN, zone: getTimeZone() },
  },
};

const shiftOptions = Object.keys(shiftOptionsPayload);

const JobOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const initialRender = useRef(true);
  const sentinelRef = useRef(null);
  const isLoading = useSelector(selectIsJobOrdersLoading);
  const jobOrders = useSelector(selectJobOrders);
  const jobOrdersGroupedByStart = useSelector(selectJobOrdersGroupedByStart);
  const timecardIsUpdating = useSelector(selectTimeCardIsUpdating);
  const timecardUpdateError = useSelector(selectTimecardUpdateError);

  const [flippedStates, setFlippedStates] = useState({});
  const totalRowCount = useSelector(selectJobOrdersTotalRowCount);
  const [filterAndSortData, setFilterAndSortData] = useState(INITIAL_FILTERS);

  const isUserAdminOrRecruiter = user?.role === UserRole.ADMIN || user?.role === UserRole.RECRUITER;

  const [isResettingFilters, setIsResettingFilters] = useState(false);

  useEffect(() => {
    if (isResettingFilters) {
      setIsResettingFilters(false);
      setFilterAndSortData(INITIAL_FILTERS);
    }
  }, [isResettingFilters]);

  const handleFlipClick = useCallback((date, cardId) => {
    setFlippedStates((prev) => ({
      ...prev,
      [`${date}_${cardId}`]: !prev[`${date}_${cardId}`],
    }));
  }, []);

  const handleCorporationChange = useCallback(
    (corporationFilter) => {
      const hasValue = corporationFilter.find((corp) => corp.value !== null);
      const removedCorporationFilters = filterAndSortData.filters.filter(
        (f) => f.field !== 'corporation'
      );
      setFilterAndSortData((prev) => ({
        ...prev,
        page: 0,
        filters: hasValue
          ? [...removedCorporationFilters, ...corporationFilter]
          : [...removedCorporationFilters],
      }));
    },
    [filterAndSortData]
  );

  const handlePositionSearchChange = debounce((newPositionFilter) => {
    setFilterAndSortData((prev) => {
      const hasValue = newPositionFilter.find((p) => p.value.length > 0);
      const removedPositionFilters = prev.filters.filter((f) => f.field !== 'name');
      const newFilters = hasValue
        ? [...removedPositionFilters, ...newPositionFilter]
        : [...removedPositionFilters];

      if (JSON.stringify(prev.filters) !== JSON.stringify(newFilters)) {
        return {
          ...prev,
          page: 0,
          filters: newFilters,
        };
      }
      return prev;
    });
  }, 300);

  const handleDateRangeChange = useCallback(
    (newDateRange) => {
      const hasValue = newDateRange.find((date) => date.value !== null);
      const removedDateFilters = filterAndSortData.filters.filter(
        (f) => f.field !== 'start' && f.field !== 'end'
      );
      setFilterAndSortData((prev) => ({
        ...prev,
        page: 0,
        filters: hasValue
          ? [...removedDateFilters, ...newDateRange]
          : [...removedDateFilters, ...FILTERS.UPCOMING_JOBS],
      }));
    },
    [filterAndSortData]
  );

  const handleStatusChange = useCallback(
    (newStatusFilter) => {
      const hasValue = newStatusFilter.find((status) => status.value !== null);
      const removedStatusFilters = filterAndSortData.filters.filter((f) => f.field !== 'cancelled');
      setFilterAndSortData((prev) => ({
        ...prev,
        page: 0,
        filters: hasValue
          ? [...removedStatusFilters, ...newStatusFilter]
          : [...removedStatusFilters],
      }));
    },
    [filterAndSortData]
  );

  const handleFillRateChange = useCallback(
    (newFillRateFilter) => {
      const hasValue = newFillRateFilter.find((fillRate) => fillRate.value !== null);
      const removedFillRateFilters = filterAndSortData.filters.filter((f) => f.field !== 'full');
      setFilterAndSortData((prev) => ({
        ...prev,
        page: 0,
        filters: hasValue
          ? [...removedFillRateFilters, ...newFillRateFilter]
          : [...removedFillRateFilters],
      }));
    },
    [filterAndSortData]
  );
  const handleShiftOptionChange = useCallback(
    (selectedShifts) => {
      const removedShiftFilters = filterAndSortData.filters.filter((f) => f.field !== 'shift');
      const selectedFilters = selectedShifts.map((shift) => shiftOptionsPayload[shift]);

      setFilterAndSortData((prev) => ({
        ...prev,
        page: 0,
        filters:
          selectedShifts.length > 0
            ? [...removedShiftFilters, ...getNewShiftFilters(selectedFilters)]
            : [...removedShiftFilters],
      }));
    },
    [filterAndSortData]
  );

  const onClearTimeCardError = useCallback(() => {
    dispatch(clearTimeCardError());
  }, [dispatch]);

  const handleTimecardEditSubmit = useCallback(
    (data) => {
      dispatch(updateTimeCard(data));
    },
    [dispatch]
  );

  useEffect(() => {
    const payload = {
      filters: filterAndSortData.filters,
      limit: filterAndSortData.pageSize,
      page: filterAndSortData.page,
      sortBy: filterAndSortData.sort.map((item) => ({
        field: item.field,
        descending: item.sort === SORT_VALUES.DESC,
      })),
    };
    if (initialRender.current) {
      initialRender.current = false;
    }
    dispatch(getJobOrders(payload));
  }, [dispatch, filterAndSortData]);

  const moveCard = useCallback(
    (date, fromIndex, toIndex) => {
      if (fromIndex !== undefined && toIndex !== undefined) {
        const updatedGroups = { ...jobOrdersGroupedByStart };
        const cards = [...updatedGroups[date].cards];

        // Remove the card from the original position
        const [movedCard] = cards.splice(fromIndex, 1);

        // Add the card to the new position
        cards.splice(toIndex, 0, movedCard);

        updatedGroups[date] = {
          ...updatedGroups[date],
          cards,
        };

        dispatch(updateJobOrderCards(updatedGroups));
      }
    },
    [dispatch, jobOrdersGroupedByStart]
  );

  const handleIntersection = useCallback(
    (entries) => {
      if (entries?.[0].isIntersecting && totalRowCount > jobOrders?.length && !isLoading) {
        setFilterAndSortData((prevVal) => ({
          ...prevVal,
          page: prevVal.page + 1,
        }));
      }
    },
    [jobOrders, totalRowCount, isLoading]
  );

  const onPlacementCheckStatusUpdate = useCallback(
    (data) => {
      dispatch(updatePlacementCheckInStatus(data));
    },
    [dispatch]
  );

  const onStatusUpdate = (data) => {
    const status = data?.status;
    const placementId = data?.placementId;
    if (status === STATUS_UPDATE_ACTION.APPROVE) {
      dispatch(postPlacementApproval({ placements: [placementId] }));
    }
    if (status === STATUS_UPDATE_ACTION.RESOLVE) {
      dispatch(postPlacementResolve({ placements: [placementId] }));
    }
    if (status === STATUS_UPDATE_ACTION.FINALIZE) {
      dispatch(postPlacementFinalize({ placements: [placementId] }));
    }
    if (status === STATUS_UPDATE_ACTION.UNFINALIZE) {
      dispatch(postPlacementUnFinalize({ placements: [placementId] }));
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    const currentSentinel = sentinelRef.current;

    if (currentSentinel) {
      observer.observe(currentSentinel);
    }

    return () => {
      if (currentSentinel) {
        observer.unobserve(currentSentinel);
      }
    };
  }, [handleIntersection]);

  const MainListContent = () =>
    Object.keys(jobOrdersGroupedByStart).map((date) => {
      const { weekDay, cards } = jobOrdersGroupedByStart[date];
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }} key={date}>
          <Typography
            sx={{ fontFamily: 'Barlow', fontSize: '20px', fontWeight: 400, lineHeight: '42px' }}
          >
            {weekDay} <span style={{ fontWeight: 700 }}> {date} </span>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '25px',
            }}
          >
            {cards.map((card, index) => (
              <ShiftCard
                key={`${date}_${card._id}`}
                index={index}
                date={date}
                moveCard={moveCard}
                jobOrder={card}
                isFlipped={flippedStates[`${date}_${card._id}`] || false}
                handleFlipClick={() => handleFlipClick(date, card._id)}
                onPlacementCheckStatusUpdate={onPlacementCheckStatusUpdate}
                clearTimeCardError={onClearTimeCardError}
                handleTimecardEditSubmit={handleTimecardEditSubmit}
                onStatusUpdate={onStatusUpdate}
                timecardIsUpdating={timecardIsUpdating}
                timecardUpdateError={timecardUpdateError}
                isCancelled={card?.status === JOB_ORDER_STATUSES.CANCELLED}
                timeStatus={getTimeStatus(card?.start, card?.end)}
              />
            ))}
          </Box>
        </Box>
      );
    });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px', gap: '24px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
        }}
      >
        <WaeButton
          variant={BUTTON_VARIANT.DEFAULT}
          actionColor={PRIMARY_COLOR[70]}
          onClick={() => navigate('/jobs/create')}
        >
          {LanguageConverter('job.create')}
        </WaeButton>
        {!isResettingFilters && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              {LanguageConverter('buttonText.filters')}
            </Typography>
            {isUserAdminOrRecruiter && (
              <SearchableSelectDropdownFilter
                disabled={isLoading}
                placeholder="Corporation"
                onValueChange={handleCorporationChange}
                field="corporation"
                operation="equalsID"
                optionsAPICallback={corporationsAPICallback}
                getOptionLabel={(option) => option.name}
                background={SEARCHBAR_BACKGROUND.DEFAULT}
              />
            )}
            <SelectDropdownFilter
              disabled={isLoading}
              placeholder="Fill Rate"
              onValueChange={handleFillRateChange}
              field="full"
              operation="equals"
              options={fillRateOptions}
              getOptionLabel={(option) => option.name}
              startAdornmentIcon={
                <Box
                  component="img"
                  sx={{
                    height: 13,
                    width: 12,
                  }}
                  alt="Status"
                  src={PercentIcon}
                />
              }
              wrapperSx={{ width: '180px', padding: '0px 6px' }}
            />
            <DateRangePickerFilter
              disabled={isLoading}
              placeholder="Dates"
              onValueChange={handleDateRangeChange}
              field={{ start: 'start', end: 'end' }}
              operation={{ start: 'onOrAfter', end: 'onOrBefore' }}
            />
            <SelectDropdownFilter
              disabled={isLoading}
              placeholder="Status"
              onValueChange={handleStatusChange}
              field="cancelled"
              operation="equals"
              options={statusOptions}
              getOptionLabel={(option) => option.name}
              startAdornmentIcon={
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 16,
                  }}
                  alt="Status"
                  src={StatusIcon}
                />
              }
            />
            <MultipleSelectDropdownChip
              disabled={isLoading}
              options={shiftOptions}
              placeholder="Shifts"
              startAdornmentIcon={
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                  }}
                  alt="Status"
                  src={CorporationIcon}
                />
              }
              allOptionsLabel="All Shifts"
              onValueChange={handleShiftOptionChange}
            />
            <FreeTextSearchFilter
              disabled={isLoading}
              placeholder="Position Name"
              onValueChange={handlePositionSearchChange}
              field="name"
              operation="icontains"
              background={SEARCHBAR_BACKGROUND.DEFAULT}
            />
          </Box>
        )}
      </Box>
      {Object.keys(jobOrdersGroupedByStart)?.length === 0 && !isLoading ? (
        <NoResultsCard
          headerMsgKey={null}
          bodyMsgKey="jobOrder.search.noResult"
          onResetFilter={() => {
            setIsResettingFilters(true);
          }}
        />
      ) : (
        <>
          <MainListContent />
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </>
      )}

      <div ref={sentinelRef} />
    </Box>
  );
};

export default JobOrders;
