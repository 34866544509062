import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Modal } from '@mui/material';

import GpsMap from './SubComponents/GpsMap';
import MapFiltersSidebar from './SubComponents/MapFiltersSidebar';
import { checkAllCheckboxID } from './constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid #000',
  borderRadius: '20px',
  overflow: 'hidden',
  height: '70%',
  width: '70%',
  outline: 'none',
};

const GpsMapModal = ({ coordinatesList, title, open, handleOnClose }) => {
  const coordsWithCheckState = coordinatesList.map((cord) => ({ ...cord, checked: true }));
  const [markers, setMarkers] = useState(coordsWithCheckState);

  const handleOnFilterChange = useCallback((event) => {
    setMarkers((prevVal) =>
      prevVal.map(
        (val) =>
          (event.target.name === val?._id && { ...val, checked: event.target.checked }) ||
          (event.target.name === checkAllCheckboxID && {
            ...val,
            checked: event.target.checked,
          }) ||
          val
      )
    );
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid="map-modal"
    >
      <Grid container item xs sx={style}>
        <Grid container item xs sx={{ height: '100%' }}>
          <GpsMap markers={markers} />
        </Grid>
        <MapFiltersSidebar
          onClose={handleOnClose}
          title={title}
          list={markers}
          onChange={handleOnFilterChange}
        />
      </Grid>
    </Modal>
  );
};

GpsMapModal.propTypes = {
  coordinatesList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
      name: PropTypes.string,
      radius: PropTypes.number,
    })
  ),
  open: PropTypes.bool,
  handleOnClose: PropTypes.func,
  title: PropTypes.string,
};

export default GpsMapModal;
